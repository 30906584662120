import React, { useEffect, useState } from 'react';
import { GridColDef, GridDensityTypes } from '@mui/x-data-grid-pro';
import { WarrantyPanalProps } from './WarrantyPanel.props';
import { StyledTableWrapper } from './WarrantyPanel.styles';
import { DataGrid } from '../../atoms/DataGrid';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { formatDate } from '../../../utils/common';
import { Chip } from '../../atoms/Chip';
import { ColorProps, ScreenSize } from '../../../@types';
import { WarrantyPanelStatuses } from '../../../@types/warrantySummary.type';
import { Accordian } from '../../atoms/Accordian';
import { Grid } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';

export const WarrantyPanal: React.FC<WarrantyPanalProps> = ({
  warrantyPanalData,
  firstInstalled
}) => {

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);
  const [expanded, setExpanded] = useState(!isMobileView);

  useEffect(() => {
    setExpanded(!isMobileView);
  }, [isMobileView]);

  const columns: GridColDef[] = [
    {
      field: 'dateApplied',
      headerName: 'Date Applied',
      minWidth: 250,
      renderCell: (params) => <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.dateApplied)}</Typography>
    },
    {
      field: 'warrantyName',
      headerName: 'Warranty Name',
      minWidth: 200,
    },
    {
      field: 'start',
      headerName: 'Start',
      minWidth: 175,
      renderCell: (params) => <Typography variant={TypographyVariantProps.Body2}>{params.row.startDate}</Typography>
    },
    {
      field: 'end',
      headerName: 'End',
      minWidth: 175,
      renderCell: (params) => <Typography variant={TypographyVariantProps.Body2}>{params.row.endDate}</Typography>
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 175,
      renderCell: (params) => <Typography variant={TypographyVariantProps.Body2}>{params.row.isExpired ? <Chip label={WarrantyPanelStatuses.Expired} color={ColorProps.Error} /> : WarrantyPanelStatuses.Active}</Typography>
    },
  ];

  return (
    <>
      <StyledTableWrapper>
        <Accordian title="Warranty Summary" expanded={expanded} onChange={() => setExpanded(!expanded)}>
          <Grid mb={1}>
            <Typography variant={TypographyVariantProps.Body1}>First Installed On: {formatDate(firstInstalled || '')}</Typography>
          </Grid>
          <DataGrid
            density={GridDensityTypes.Standard}
            columns={columns}
            rows={warrantyPanalData}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              }
            }}
            getRowClassName={(params) => `super-app-theme--${params.row.logTypeCode}`}
            autoHeight={true}
          />
        </Accordian>
      </StyledTableWrapper>
    </>
  );
};