import React from 'react';
import Stack from '@mui/material/Stack';
import { ComponentMenuItem } from '../../atoms/SelectMenu';
import { Chip } from '../../atoms/Chip';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { PrioritySelectMenuProps } from './PrioritySelectMenu.props';
import { StyledSelectMenu } from './PrioritySelectMenu.styles';

export const PrioritySelectMenu: React.FC<PrioritySelectMenuProps> = ({
  id,
  label = 'PrioritySelectMenu',
  items,
  value,
  disabled,
  onChange,
  isFocused,
  isCompact = false,
  minWidth,
  validate,
  optionalLabelEnabled
}) => {
  return (
    <StyledSelectMenu
      id={id}
      label={label}
      minWidth={minWidth}
      required={true}
      validate={validate}
      optionalLabelEnabled={optionalLabelEnabled}
      disabled={disabled}
      items={items.map((item) => {
        const componentItem: ComponentMenuItem = {
          value: item.value,
          disabled: item.disabled,
          label: (
            <Stack direction="row" spacing={1}>
              <Chip label={item.label} backgroundColor={item.color} borderColor={item.color} textColor="#ffffff" />
              <Typography variant={TypographyVariantProps.Body1}>{item.description}</Typography>
            </Stack>
          )
        };
        return componentItem;
      })}
      selectedValue={value}
      onChange={(val) => onChange(val)}
      isFocused={isFocused}
      isCompact={isCompact}
    />
  );
};