import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const ModelGridContainer = styled(Grid)`
  padding: 10px 5px 5px 5px;
`;

export const SwitchGrid = styled(Grid)`
  margin-top: -15px;
  justify-content: center;
  align-items: center;
`;
