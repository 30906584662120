import { Attachment } from '../@types';
import { ReportShareTabs, SavedReportTypeList, ScheduleTime, ScheduleType } from '../configs/enums';

export const generateReportURL= (path: string, reportCode: string) => {
  return `${path}?savedReportCode=${reportCode}`;
};

export const generateListPageReportURL= (page: string, reportCode: string) => {
  switch (page) {
    case SavedReportTypeList.StructureList: {
      return `/structure/search?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.ServiceList: {
      return `/services?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.PartList: {
      return `/parts/search?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.UserList: {
      return `/configure/users?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.ClientContractList: {
      return `/configure/clients?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.PartTypeList: {
      return `/configure/partTypes?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.RoleList: {
      return `/configure/users/roles?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.ServiceTypeList: {
      return `/configure/services/types?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.AttendanceReportList: {
      return `/reports/attendance?savedReportCode=${reportCode}`;
    }
    case SavedReportTypeList.PartMovementList: {
      return `/parts/movements?savedReportCode=${reportCode}`;
    }
    default:
      return '/reports/myreports';
  }
};

export const getScheduleTimeUsingType = (type: string) => {
  switch (type) {
    case ScheduleType.Daily: {
      return ScheduleTime.Daily;
    }
    case ScheduleType.Weekly: {
      return ScheduleTime.Weekly;
    }
    case ScheduleType.Monthly: {
      return ScheduleTime.Monthly;
    }
    case ScheduleType.Yearly: {
      return ScheduleTime.Yearly;
    }
    case ScheduleType.Custom: {
      return ScheduleTime.Custom;
    }
    default:
      return '';
  }
};

export const getScheduleTypeUsingTime = (time: string) => {
  switch (time) {
    case ScheduleTime.Daily: {
      return ScheduleType.Daily;
    }
    case ScheduleTime.Weekly: {
      return ScheduleType.Weekly;
    }
    case ScheduleTime.Monthly: {
      return ScheduleType.Monthly;
    }
    case ScheduleTime.Yearly: {
      return ScheduleType.Yearly;
    }
    default:
      return ScheduleType.Custom;
  }
};

export const getAttachmentTypes = (attachments: Array<Attachment>) => {
  return attachments.map((attachment: Attachment) => {
    return { value: attachment.id, label: attachment.name };
  });
};

export const getTabsByPermission = (isHaveSharedPermission: boolean, isHaveScheduledPermission: boolean) => {
  if (isHaveSharedPermission && isHaveScheduledPermission) {
    return [ReportShareTabs.DirectShare, ReportShareTabs.ScheduleEmail];
  } else if (isHaveSharedPermission) {
    return [ReportShareTabs.DirectShare];
  }
  
  return [ReportShareTabs.ScheduleEmail];

};