import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { DoneOutlineOutlined } from '@mui/icons-material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../LocationSearchItem';
import { ServiceAllocationSelectorProps } from './ServiceAllocationSelector.props';
import { 
  ArrowUpwardIcon, 
  IconContainer, 
  SecondaryAllocationIcon,
  SubmitButton,
  LiveSearchWrapper,
  StyledBox,
} from './ServiceAllocationSelector.styles';
import { useSearchAllocationNodes } from '../../../queries/live-search-query';
import { useGetCurrentUser } from '../../../queries/user-query';
import { ServiceNodeAllocation } from '../../../@types/allocation.type';
import { ActionPermissions, LocationNodeData } from '../../../@types';
import { Link } from '../../atoms/Link';
import { getAllocationNodeAccess } from '../../../utils/services';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetAllocationNodeParentData } from '../../../queries/structure-query';

export const ServiceAllocationSelector: React.FC<ServiceAllocationSelectorProps> = ({
  allocation,
  defaultAllocationSuggetions,
  multiline= true,
  onSubmit,
  onClose,
  permissions
}) => {
  const [userDepotAllocation, setUserDepotAllocation] = useState<ServiceNodeAllocation>();
  const searchAllocationNodes = useSearchAllocationNodes();
  const getCurrentUserQuery = useGetCurrentUser();
  const getAllocationNodeParentData = useGetAllocationNodeParentData(userDepotAllocation?.node?.id);

  useEffect(() => {
    allocation && setUserDepotAllocation(allocation);
  }, [allocation]);

  useEffect(() => {
    userDepotAllocation?.node?.id && userDepotAllocation?.node?.liveSearch && getAllocationNodeParentData.refetch();
  }, [userDepotAllocation?.node]);

  useEffect(() => {
    getAllocationNodeParentData.data && setUserDepotAllocation({
      ...userDepotAllocation,
      parentNode: {
        id: getAllocationNodeParentData.data.id || 0,
        name: getAllocationNodeParentData.data.name || '',
        code: getAllocationNodeParentData.data.code || '',
        nodeTypeCode: getAllocationNodeParentData.data.nodeTypeCode || '',
        liveSearch: true
      }
    });
  }, [getAllocationNodeParentData.data]);

  const handleSubmit = () => {
    userDepotAllocation?.node?.id && userDepotAllocation?.allocationType && onSubmit({
      nodeId: userDepotAllocation.node.id,
      allocationTypeCode: userDepotAllocation.allocationType
    });
  };

  const handleParentNode = () => {
    userDepotAllocation?.parentNode?.id && userDepotAllocation?.allocationType && onSubmit({
      nodeId: userDepotAllocation.parentNode.id,
      allocationTypeCode: userDepotAllocation.allocationType
    });
  };

  const handleTake = () => {
    getCurrentUserQuery?.data?.siblingNodeId && userDepotAllocation?.allocationType && onSubmit({
      nodeId: getCurrentUserQuery.data.siblingNodeId,
      allocationTypeCode: userDepotAllocation.allocationType
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={4}>
        <Stack direction="row" width="100%">
          <IconContainer backgroundColor="transparent"><SecondaryAllocationIcon /></IconContainer>
          <LiveSearchWrapper>
            <LiveSearchBox
              title="Allocation"
              multiline={multiline}
              timeOffset={400}
              value={userDepotAllocation?.node?.name || ''}
              defaultSuggetions={defaultAllocationSuggetions}
              onClearValue={() => setUserDepotAllocation(allocation)}
              onChange={async (obj: LocationNodeData) => {
                if (obj.id && obj.name) {
                  setUserDepotAllocation({
                    ...userDepotAllocation,
                    node: {
                      id: obj.id,
                      name: obj.name,
                      code: obj.code,
                      nodeTypeCode: obj.nodeTypeCode,
                      liveSearch: true
                    }
                  });
                }
              }}
              onApiInvoke={async (name) => {
                return await searchAllocationNodes.mutateAsync(name);
              }}
              renderItem={(props: any, option: any) => {
                return (
                  <StyledBox heighlightBackground={option.isSuggestedNode}>
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  </StyledBox>
                );
              }}
            />
          </LiveSearchWrapper>
          <SubmitButton variant={ButtonVariantProps.Secondary} onClick={handleSubmit}>
            <DoneOutlineOutlined fontSize="large" color="success" />
          </SubmitButton>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} alignItems="center" justifyContent={{ xs: 'flex-end', md: 'flex-start' }} display="flex" flexWrap="wrap" gap="10px">
        {userDepotAllocation?.parentNode?.id && userDepotAllocation?.parentNode?.name && userDepotAllocation?.parentNode?.id === allocation?.parentNode?.id ?
          <Button
            color={ButtonColorProps.Warning}
            variant={ButtonVariantProps.Secondary}
            startIcon={<ArrowUpwardIcon />}
            onClick={handleParentNode}
          >
            {userDepotAllocation?.parentNode?.name}
          </Button>
          : 
          null
        }
        <Stack direction="row" gap="10px">
          {isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Send_Up_To, permissions) && 
            <Button
              color={ButtonColorProps.Warning}
              variant={ButtonVariantProps.Secondary}
              onClick={handleTake}
            >
                Take
            </Button>
          }
          <Button
            color={ButtonColorProps.Error}
            variant={ButtonVariantProps.Secondary}
            onClick={onClose}
          >
              Exit
          </Button>
        </Stack>
        <Stack direction="row" gap="30px" ml={5}>
          {permissions && (userDepotAllocation?.node?.liveSearch || userDepotAllocation?.node?.isVisible) && getAllocationNodeAccess(permissions, userDepotAllocation, false).hasAccess &&
            <Link href={getAllocationNodeAccess(permissions, userDepotAllocation, false).url}>{userDepotAllocation?.node?.name}</Link> 
          }
          {permissions && (userDepotAllocation?.parentNode?.liveSearch || userDepotAllocation?.parentNode?.isVisible) && getAllocationNodeAccess(permissions, userDepotAllocation, true).hasAccess &&
            <Link href={getAllocationNodeAccess(permissions, userDepotAllocation, true).url}>{userDepotAllocation?.parentNode?.name}</Link> 
          }
        </Stack>
      </Grid>
    </Grid>
  );
};