import React from 'react';
import { 
  GridColDef,
  useGridApiContext,
  gridVisibleColumnFieldsSelector,
  gridVisibleColumnDefinitionsSelector,
  gridFilterModelSelector,
  gridSortModelSelector
} from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import { IColumn } from 'json-as-xlsx';
import { ExcelExportMenuItemProps } from './ExcelExportMenuItem.props';
import { useExportFile } from '../../../queries/uploadedfiles-query';
import { downloadFile } from '../../../utils/file-utils';
import { getSelectedColumnNames } from '../../../utils/columnSelectionOperations';
import { ListType } from '../../../@types';

export const ExcelExportMenuItem: React.FC<ExcelExportMenuItemProps> = ({
  label,
  fileName,
  fileType,
  structureTypeCategory,
  columnSelectionData,
  sortModel,
  startDate,
  endDate,
  serialSearch,
  locationId,
  includeChilds,
  setExportStatus,
  rowCount,
  defaultExcludedColumns,
  serialPartsManifestData,
  nonSerialPartsManifestData
}) => {
  const apiRef = useGridApiContext();
  const exportFile = useExportFile();

  const getExcludedColumns = () => {
    if (fileName === ListType.StructureList) {
      return getSelectedColumnNames(columnSelectionData, []);
    } else if (fileName === ListType.ClientContractList) {
      return getSelectedColumnNames(columnSelectionData, defaultExcludedColumns);
    } else if (fileName === ListType.PartList) {
      return getSelectedColumnNames(columnSelectionData);
    }
  };

  const downloadExcelFile = async () => {
    const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);
    const visibleColumns = gridVisibleColumnDefinitionsSelector(apiRef);
    const filter = gridFilterModelSelector(apiRef);
    const sorting = gridSortModelSelector(apiRef);

    const columns = visibleColumns.map((obj: GridColDef) => {
      return { label: obj.headerName, value: obj.field } as IColumn;
    });
    
    setExportStatus('Export Inprogress!');
    try {
      const data: any = {
        selectedColumns: columns, 
        filter: filter,
        fileName: fileName,
        visibleColumnsField: visibleColumnsField,
        fileType: fileType,
        structureTypeCategory: structureTypeCategory,
        columnSelectionData: columnSelectionData,
        sorting: sorting.length ? sorting : sortModel,
        startDate,
        endDate,
        serialSearch,
        locationId,
        includeChilds,
        rowCount,
        serialPartsManifestData,
        nonSerialPartsManifestData
      };
      if (fileName === ListType.StructureList || fileName === ListType.ClientContractList || fileName === ListType.PartList) {
        data.excludedColumns = getExcludedColumns();
      }
      const exportFileLink: any = await exportFile.mutateAsync(data);
      if (exportFileLink?.sendEmail) {
        setExportStatus(exportFileLink?.message);
        return;
      }
      downloadFile(exportFileLink.url, fileName);
      setExportStatus('Export Successful!');
    } catch (error) {
      setExportStatus('Export Failed!');
    }
  };

  return (
    <MenuItem
      onClick={downloadExcelFile}
    >
      {label}
    </MenuItem>
  );
};
