import { styled } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

export const ChipStyled = styled(Chip)((props: ChipProps & { bgColor?: string; isSystem: boolean }) => `
  background-color: ${props.isSystem ? props.bgColor : 'transparent'};
		box-shadow: inset 0 0 0 10em rgb(255 255 255 / ${props.isSystem ? '80%' : '0'});
  color: ${props.bgColor};
  border: ${props.isSystem ? '1px solid' : '1px solid ' + props.bgColor};
  &&:hover, &&:focus {
    background-color: ${props.bgColor};
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.6);
  };
  & .MuiChip-deleteIcon {
    color: ${props.bgColor};
    opacity: 0.6;
  };`
);

export const Accordion = styled(MuiAccordion)((props: AccordionProps & { index: number }) => `
  background-color: transparent;
  color: white;
  border: none;
  padding-top: ${props.index === 0 ? '15px' : '10px'};
`);

export const AccordionSummary = styled(MuiAccordionSummary)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  height: 20px;

  &.MuiAccordionSummary-content {
    margin: 0;
    background-color: transparent;
  }

  &.MuiButtonBase-root.MuiAccordionSummary-root {
    min-height: 10px !important;
    padding-right: 0;
  }
`;