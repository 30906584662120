import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createTag, getAllUserTags } from '../api/tags';
import { Tag } from '../@types';
import { TagsKey } from '../configs/tags';

export const useAddTags = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await createTag(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TagsKey]);
    },
  });
};

export const getAllTags = (id: number): UseQueryResult<[Tag]> => {
  return useQuery([TagsKey, id], () => getAllUserTags(id));
};