import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { isUserHasPermission } from '../../../configs/permissions';
import { ActionPermissions, CustomPermission, ModelDataObject } from '../../../@types';
import { NodeTypeCode } from '../../../configs/enums';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { useGetServiceActionProcessTypes } from '../../../queries/service-query';
import { useGetRegionTypes } from '../../../queries/regionTypes-query';
import { useGetServiceStatuses } from '../../../queries/service-status-query';
import { useGetAllShipmentTypes, useGetAllShipmentStatus } from '../../../queries/shipment-query';
import { useGetAllNodesByNodeType } from '../../../queries/structure-query';
import { useGetNotificationTemplates } from '../../../queries/notification-config-query';
import { NotificationRulesConfigModelFragment } from './NotificationRuleConfigModelFragment/NotificationRuleConfigModelFragment';
import { NotificationRuleListFragment } from './NotificationRuleListFragment/NotificationRuleListFragment';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const NotificationRules: React.FC = () => {
  const activeOnly = true;
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [modelData, setModelData] = useState<ModelDataObject>();
  const [openModel, setOpenModel] = useState(false);
  const [refetchData, setRefetchData] = useState(false);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const regionTypesQuery = useGetRegionTypes();
  const getServiceStatuses = useGetServiceStatuses();
  const getAllShipmentTypes = useGetAllShipmentTypes();
  const getAllNodesByNodeType = useGetAllNodesByNodeType(NodeTypeCode.ShippingCompany);
  const getAllShipmentStatus = useGetAllShipmentStatus();
  const getNotificationTemplates = useGetNotificationTemplates();

  TabHandler(`Notifications Rules | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (
      serviceActionProcessTypesQuery.data || 
      regionTypesQuery.data || 
      getServiceStatuses.data ||
      getAllShipmentTypes.data ||
      getAllNodesByNodeType.data ||
      getAllShipmentStatus.data ||
      getNotificationTemplates.data
    ) {
      setModelData({
        serviceActionProcessTypes: serviceActionProcessTypesQuery.data || modelData?.serviceActionProcessTypes,
        regionTypes: regionTypesQuery.data || modelData?.regionTypes,
        serviceStatuses: getServiceStatuses.data || modelData?.serviceStatuses,
        shipmentTypes: getAllShipmentTypes.data || modelData?.shipmentTypes,
        couriers: getAllNodesByNodeType.data?.filter(value => value.isActive) || modelData?.couriers,
        shipmentStatuses: getAllShipmentStatus.data || modelData?.shipmentStatuses,
        notificationTemplates: getNotificationTemplates.data || modelData?.notificationTemplates
      });
    }
  }, [serviceActionProcessTypesQuery.data, regionTypesQuery.data, getServiceStatuses.data, getAllShipmentTypes.data, getAllNodesByNodeType.data, getAllShipmentStatus.data, getNotificationTemplates.data]);

  const addNewConfig = () => {
    setOpenModel(true);
  };

  const closeModel = () => {
    setRefetchData(!refetchData);
    setOpenModel(false);
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Notifications Rules
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Notifications_Rules_Create, permissions) && (
            <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addNewConfig()}>
                ADD NEW CONFIG
            </Button>
          )}
        </InlineGroup>
        <NotificationRulesConfigModelFragment open={openModel} closeModel={closeModel} modelData={modelData}/>
        {
          isUserHasPermission(ActionPermissions.Configure_Notifications_Rules_View, permissions) &&
          <NotificationRuleListFragment editPermission={isUserHasPermission(ActionPermissions.Configure_Notifications_Rules_Edit, permissions)} modelData={modelData} refetchData={refetchData}/>
        }
      </Wrapper>
    </PageContainer>
  );
};