import { Grid, styled } from '@mui/material';
import { TextField } from '../../atoms/TextField';

export const ContainerGrid = styled(Grid)((props: { borderColor: string }) => `
  position: relative;
  min-height: 100px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  background-color: white;
  border: solid 1px ${props.borderColor};
  cursor: pointer;
`);

export const CloseButtonWrapper = styled('div')`
  position: absolute;
  top: -15px;
  right: -15px; 
  background-color: #ffeeed; 
  width: 30px; 
  height: 30px; 
  border-radius: 15px;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
  min-width: 400px;
`;