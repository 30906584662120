import React from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Clear, Settings } from '@mui/icons-material';
import { ColorProps } from '../../../@types';
import {
  RoleBox,
  ServiceImage,
  StyledTableContainer
} from './MiniServiceList.styles';
import { MiniServiceListDataType, MiniServiceListProps } from './MiniServiceList.props';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { defaultColors } from '../../../configs/colors';
import { Link } from '../../atoms/Link';

export const MiniServiceList: React.FC<MiniServiceListProps> = ({
  openGridItems,
  closeGridItems,
  handleOpenGridItemsDelete,
  handleCloseGridItemsDelete
}) => {
  return (
    <RoleBox>
      {openGridItems && openGridItems?.length > 0 && <Grid>
        <Typography variant={TypographyVariantProps.Body1}>Open</Typography>
      </Grid>}
      <StyledTableContainer>
        <Table size="small">
          <TableBody>
            {openGridItems?.map((row: MiniServiceListDataType, index: number) => {
              return <TableRow
                key={index}
              >
                <TableCell width="100px" align="center">
                  <Link href={`/services/${row.id}`}>{row.id}</Link>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.image ? <ServiceImage src={row.image}/> : <Grid mt={0.6}><Settings fontSize="small" /></Grid>}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  <IconButton aria-label="pdf" color="error" onClick={(e) => handleOpenGridItemsDelete && row.id && handleOpenGridItemsDelete(e, row.id)}>
                    <Clear fontSize="small"/> 
                  </IconButton>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {closeGridItems && closeGridItems?.length > 0 && <Grid mt={2}>
        <Typography variant={TypographyVariantProps.Body1} textColor={openGridItems && openGridItems?.length > 0 ? defaultColors[1] : ''}>Closed</Typography>
      </Grid>}
      <StyledTableContainer>
        <Table size="small">
          <TableBody>
            {closeGridItems?.map((row: MiniServiceListDataType, index: number) => {
              return <TableRow
                key={index}
              >
                <TableCell width="100px" align="center">
                  <Link
                    href={`/services/${row.id}`} 
                    color={openGridItems && openGridItems?.length > 0 ? ColorProps.Error : ColorProps.Primary}
                  >
                    {row.id}
                  </Link>
                </TableCell>
                <TableCell align="left" component="th" scope="row" >
                  {row.image ? <ServiceImage src={row.image}/> : <Grid mt={0.6}><Settings fontSize="small" /></Grid>}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  <IconButton aria-label="pdf" color="error" onClick={(e) => handleCloseGridItemsDelete && row.id && handleCloseGridItemsDelete(e, row.id)}>
                    <Clear fontSize="small"/> 
                  </IconButton>
                </TableCell>
              </TableRow>;
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </RoleBox>
  );
};