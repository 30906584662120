import React from 'react';
import { Grid } from '@mui/material';
import { Dialog } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { CheckItemGroup, CheckItemProps } from '../../organisms/CheckItemGroup';
import { ColumnSelectionProps, ColumnSelectionItem } from './ColumnSelection.props';

export const ColumnSelection: React.FC<ColumnSelectionProps> = ({
  open,
  title,
  value,
  onChange,
  onClose
}) => {
  const handleOnChange = (item: ColumnSelectionItem, val: Array<CheckItemProps>) => {
    const modifiedValues = value.map(obj => obj.title === item.title ? { 
      ...item, value: val 
    } : obj);
    onChange(modifiedValues);
  };
  
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Grid container spacing={1} padding={3}>
        <Grid item sm={12}>
          <Typography variant={TypographyVariantProps.H5}>{title}</Typography>
        </Grid>
        {value.map((item: ColumnSelectionItem, index: number) => (
          <Grid key={index} item sm={4}>
            <CheckItemGroup title={item.title} value={item.value} onChange={(val) => handleOnChange(item, val)} />
          </Grid>
        ))}
      </Grid>
    </Dialog>
  );
};
