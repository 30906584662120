import React from 'react';
import { WarrantyPanal } from '../../../../components/molecules/WarrantyPanel';
import { WarrantySummaryFragmentProps } from './WarrantySummaryFragment.props';

export const WarrantySummaryFragment: React.FC<WarrantySummaryFragmentProps> = ({
  warrantySummarydata,
  firstInstalled
}) => {
  return (
    <>
      <WarrantyPanal
        warrantyPanalData={warrantySummarydata}
        firstInstalled={firstInstalled}
      />
    </>
  );
};