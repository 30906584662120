import Grid from '@mui/material/Grid';
import React from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ColorProps } from '../../../@types';
import { FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { Switch } from '../../atoms/Switch';
import { TextField } from '../../atoms/TextField';
import { TypographyVariantProps } from '../../atoms/Typography';
import { Tags } from '../Tags';
import { ContentWrapper, EnabledFormControlLabel, ImageBox, Topic } from './AddEditClientContract.styles';
import { UploadButton } from '../../molecules/UploadButton';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { AddEditClientContractProps } from './AddEditClientContract.props';
import { CLIENT_CONTRACT_LOGO_MIME_TYPES } from '../../../configs/envs';

export const AddEditClientContract: React.FC<AddEditClientContractProps> = ({
  value,
  isNew,
  disableToggle,
  onChange,
  onClickPicture,
}) => {
  return (
    <>
      {isNew ||
        <Grid container justifyContent="space-between" >
          <Grid item>
            <Topic variant={TypographyVariantProps.H6}>General</Topic>
          </Grid>
          <ContentWrapper>
            <EnabledFormControlLabel
              control={
                <Switch
                  color={ColorProps.Success}
                  onChange={(event) => onChange({
                    ...value,
                    enabled: event.target.checked
                  })}
                  checked={value.enabled}
                  disabled={disableToggle}
                />
              }
              label="Enabled"
              labelPlacement={FormControlLabelPlacementProps.End}
            />
          </ContentWrapper>
        </Grid>
      }
      <ContentWrapper>
        <TextField
          required
          fullWidth
          error={!!value.nameHelperText}
          helperText={value.nameHelperText}
          label="Name"
          value={value.name}
          onChange={(input) => onChange({
            ...value,
            name: input,
            nameHelperText: undefined
          })}
        />
      </ContentWrapper>
      <ContentWrapper>
        <TextField
          required
          fullWidth
          error={!!value.shortNameHelperText}
          helperText={value.shortNameHelperText}
          label="Short Name"
          value={value.shortName}
          onChange={(input) => onChange({
            ...value,
            shortName: input,
            shortNameHelperText: undefined
          })}
        />
      </ContentWrapper>
      <ContentWrapper>
        <Tags
          selectedTags={value.tags.selectedTags}
          newTags={value.tags.newTags}
          setSeletedTags={value.tags.setSeletedTags}
          setNewTags={value.tags.setNewTags}
          allTags={value.tags.allTags}
        />
      </ContentWrapper>
      <Grid>
        <Grid mt={GRID_SPACING}>
          <UploadButton fileTypes={CLIENT_CONTRACT_LOGO_MIME_TYPES.split(',')} maxFileSize={30} setFileDetails={onClickPicture}
            label="Upload Logo Picture" icon={<AddPhotoAlternateIcon fontSize="small" />} />
        </Grid>
        <Grid mt={GRID_SPACING}>
          <ImageBox src={value.logoPic} />
        </Grid>
      </Grid>
    </>
  );
};
