import React, { useState } from 'react';
import { Chip, Grid, Stack } from '@mui/material';
import { RotateLeft, Refresh } from '@mui/icons-material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';
import { Accordian } from '../../atoms/Accordian';
import { SLAContactRow } from '../../organisms/SLAContactRow';
import { SLAReviewMitigation } from '../../organisms/SLAReviewMitigation';
import { SLADetailsProps } from './SLADetails.props';
import { useMediaQuery } from '@material-ui/core';
import { ScreenSize } from '../../../@types/display.types';
import { SAMPLE_SLA_DETAILS_HELPER_TEXT } from '../../../constants/sla';
import { OutcomeTypeCode, SLADetailsHelperText } from '../../../@types';
import { isDropDownEmpty, isEmptyString, nameValidation } from '../../../utils/common';

export const SLADetails: React.FC<SLADetailsProps> = ({
  isReview,
  isMitigation,
  reviewOptions,
  mitigationOptions,
  slaDetailsValue,
  outcomeTypeCode,
  onSLADetailsChange,
  onSave,
  onTabChange,
  handleSLADateChange,
  handleSLARest,
  handleSLARefresh
}) => {
  const [helperText, setHelperText] = useState<SLADetailsHelperText>(SAMPLE_SLA_DETAILS_HELPER_TEXT);
  const [validate, setValidate] = useState(false);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const handleSave = () => {
    const isReviewed = outcomeTypeCode === OutcomeTypeCode.Reviewed;
    const isMitigated = outcomeTypeCode === OutcomeTypeCode.Mitigated;

    if (isReviewed && (isEmptyString(slaDetailsValue.reviewMitigationData.reviewNote) || isDropDownEmpty(slaDetailsValue.reviewMitigationData.reviewValue))) {
      setHelperText({
        ...helperText,
        reviewNoteHelperText: nameValidation(slaDetailsValue.reviewMitigationData.reviewNote, 'Notes'),
      });
      setValidate(true);

      return;
    }

    if (isMitigated && (isEmptyString(slaDetailsValue.reviewMitigationData.mitigationNote) || isDropDownEmpty(slaDetailsValue.reviewMitigationData.mitigationValue))) {
      setHelperText({
        ...helperText,
        mitigationNoteHelperText: nameValidation(slaDetailsValue.reviewMitigationData.mitigationNote, 'Notes')
      });
      setValidate(true);
      return;
    }

    onSave(slaDetailsValue);
  };

  return (
    <Accordian title={
      <Grid container alignItems="center">
        <Grid item mr={2}>
          <Typography>SLA Details</Typography>
        </Grid>
        <Grid item mr={2}>
          <Chip
            variant="outlined"
            label="Reset"
            icon={<RotateLeft />}
            onClick={(event) => {
              event.stopPropagation();
              handleSLARest();
            }}
          />
        </Grid>
        <Grid item mr={2}>
          <Chip
            variant="outlined"
            label="Refresh"
            icon={<Refresh />}
            onClick={(event) => {
              event.stopPropagation();
              handleSLARefresh && handleSLARefresh();
            }}
          />
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <Grid container xs={12}>
        <Grid item xs={12} md={6} container>
          <Stack direction="column" spacing={0} sx={{ width: '100%' }}>
            {slaDetailsValue.contactRowData?.map((obj, index) => (
              <SLAContactRow
                key={index}
                slaDetailsData={obj}
                onChange={(val) => {
                  const contactRowData = slaDetailsValue.contactRowData.map((obj, i) => i === index ? val : obj);
                  onSLADetailsChange({ ...slaDetailsValue, contactRowData });
                }}
                handleSLADateChange={(value) => handleSLADateChange(value)}
              />
            ))}
          </Stack>
        </Grid>
        {slaDetailsValue.contactRowData.some(sla => sla.isBreached) && (
          <Grid item xs={12} md={6} container>
            <Grid item xs={12} paddingTop={1} paddingBottom={1} paddingLeft={isMobileView ? 0 : 2} >
              <SLAReviewMitigation
                isReview={isReview}
                isMitigation={isMitigation}
                reviewOptions={reviewOptions}
                mitigationOptions={mitigationOptions}
                value={slaDetailsValue.reviewMitigationData}
                onChange={(val) => onSLADetailsChange({ ...slaDetailsValue, reviewMitigationData: val })}
                onTabChange={onTabChange}
                helperText={helperText}
                setHelperText={setHelperText}
                validate={validate}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button variant={ButtonVariantProps.Secondary} onClick={handleSave}>Save</Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Accordian>
  );
};