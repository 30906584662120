import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';

export const ContentWrapperBox = styled(Box)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 20px;
`;

export const StyledMultiSelectCheckmarksMenu = styled(MultiSelectCheckmarksMenu)`
  width: 100%;
`;