import React from 'react';
import { ChipProps, ChipVariantProps } from './Chip.props';
import { StyledChip } from './Chip.styles';
import { ColorProps, SizeProps } from '../../../@types';

export const Chip: React.FC<ChipProps> = ({
  label,
  variant = ChipVariantProps.Filled,
  color = ColorProps.Default,
  size = SizeProps.Small,
  borderColor,
  backgroundColor,
  textColor,
  icon,
  onClick,
}) => {
  return (
    <StyledChip
      label={label}
      variant={variant}
      color={color}
      size={size}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      icon={icon}
      onClick={onClick}
    />
  );
};