import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { TLCTemplateActionTypeKey, TLCTemplateKey, TlcTemplateDetailsKey } from '../configs/tlc';
import { createTLCTemplate, getTemplateActionTypes, getTLCTemplateById, updateTLCTemplate, getTlcTemplates, getTlcTemplateDetails, executeTlcTransactions } from '../api/tlc';
import { TemplateActionType, TemplateResponse } from '../@types/tlc.type';
import { QueryOption } from '../@types';
import { TlcTemplatesListResponse, TLCTemplateDetailsDataType } from '../@types/tlc.type';

export const useGetAllTlcTemplates = (queryOption: QueryOption,): UseQueryResult<TlcTemplatesListResponse> => {
  return useQuery([TLCTemplateKey], () => getTlcTemplates(queryOption));
};

export const useAddTLCTemplate = (): UseMutationResult<TemplateResponse> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await createTLCTemplate(data);
    return response;
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([TLCTemplateKey, response.id]);
      await queryClient.invalidateQueries([TLCTemplateKey]);
    },
  });
};

export const useUpdateTLCTemplate = (id?: number): UseMutationResult<TemplateResponse> => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateTLCTemplate(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TLCTemplateKey]);
      await queryClient.invalidateQueries([TLCTemplateKey, id]);
    },
  }); 
};

export const useGetTLCTemplateById = (id: number): UseQueryResult<TemplateResponse> => {
  return useQuery([TLCTemplateKey, id], () => id && getTLCTemplateById(id), {
    enabled: false,
    cacheTime: 0
  });
};

export const useGetActionTemplateTypes = (): UseQueryResult<[TemplateActionType]> => {
  return useQuery([TLCTemplateActionTypeKey], () => getTemplateActionTypes());
};
  
export const useGetTlcTemplateDetails = (): UseQueryResult<TLCTemplateDetailsDataType[]> => {
  return useQuery([TlcTemplateDetailsKey], () => getTlcTemplateDetails());
};

export const useExecuteTlcTransactions = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    return await executeTlcTransactions(data);
  });
};
