import React from 'react';
import { Remove, Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Button, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { TextField, TextFieldTypeProps } from '../../../../../components/atoms/TextField';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../../components/molecules/LocationSearchItem';
import { 
  ACTION_TRIGGER_FORM_ACTION_TYPE_OPTIONS, 
  ACTION_TRIGGER_FORM_ACTION_VALUE_OPTIONS 
} from '../../../../../constants/form-builder';
import { PART_ACTION_ITEMS } from '../../../../../constants/part';
import { TASK_ITEMS } from '../../../../../constants/task';
import { useSearchPartsByName } from '../../../../../queries/live-search-query';
import { ActionTriggerFormActionType } from '../../../../../@types';
import { TaskType } from '../../../../../@types/task.type';
import { adjustTime } from '../../../../../utils/services';
import { ActionTriggerFormBuilderFieldFragmentProps } from './ActionTriggerFormBuilderFieldFragment.props';
import { StyledStack } from './ActionTriggerFormBuilderFieldFragment.styles';
import { getPrePopulatedPartActionOptions } from '../../../../../utils/form-builder';

export const ActionTriggerFormBuilderFieldFragment: React.FC<ActionTriggerFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const searchPartsByNameQuery = useSearchPartsByName();

  return (
    <StyledStack spacing={2}>
      <SelectMenu
        id="form-builder-action-triggers-select-type"
        label="Select Type"
        items={ACTION_TRIGGER_FORM_ACTION_TYPE_OPTIONS}
        selectedValue={preSelectedData?.actionType}
        onChange={(val) => onPreSelectedData({ 
          actionType: val, 
          actionValue: preSelectedData?.actionValue || '',
          value: ''
        })}
        validate={showErrors}
      />
      <SelectMenu
        id="form-builder-action-triggers-select-action-task"
        label={preSelectedData?.actionType === ActionTriggerFormActionType.Task ? 'Select Task' : 'Select Action'}
        items={preSelectedData?.actionType === ActionTriggerFormActionType.Task ? TASK_ITEMS : PART_ACTION_ITEMS}
        selectedValue={preSelectedData?.actionValue}
        onChange={(val) => onPreSelectedData({ 
          actionValue: val, 
          actionType: preSelectedData?.actionType || ActionTriggerFormActionType.PartAction,
          value: ''
        })}
        validate={showErrors}
      />
      {preSelectedData?.actionType === ActionTriggerFormActionType.PartAction &&
        <LiveSearchBox
          title="Part Type"
          timeOffset={400}
          value={preSelectedData?.value}
          onChange={(val) => {
            onPreSelectedData({
              value: val.name,
              valueId: val.id,
              actionType: preSelectedData?.actionType || ActionTriggerFormActionType.PartAction,
              actionValue: preSelectedData?.actionValue || ''
            });
          }}
          onClearValue={() => onPreSelectedData({
            value: '',
            actionType: preSelectedData?.actionType || ActionTriggerFormActionType.PartAction,
            actionValue: preSelectedData?.actionValue || ''
          })}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onApiInvoke={async (name) => {
            return await searchPartsByNameQuery.mutateAsync(name);
          }} 
        />
      }
      {preSelectedData?.actionType === ActionTriggerFormActionType.Task && 
        (preSelectedData.actionValue === TaskType.LABOUR_TIME || 
          preSelectedData.actionValue === TaskType.LOADING_TIME ||
          preSelectedData.actionValue === TaskType.STAGING_TIME ||
          preSelectedData.actionValue === TaskType.TRAVEL_TIME
        ) &&
        <Stack direction="row" spacing={1}>
          <Button variant={ButtonVariantProps.Icon} onClick={() => {
            const adjustTimeValue = adjustTime(preSelectedData?.value || '00:00', -15);
            onPreSelectedData({
              value: adjustTimeValue,
              actionType: preSelectedData?.actionType || ActionTriggerFormActionType.Task,
              actionValue: preSelectedData?.actionValue || ''
            });
          }}>
            <Remove />
          </Button>
          <TextField
            fullWidth
            label="Task Value (Time Duration)"
            value={preSelectedData?.value}
            error={showErrors}
            onChange={(val) => onPreSelectedData({
              value: val,
              actionType: preSelectedData?.actionType || ActionTriggerFormActionType.Task,
              actionValue: preSelectedData?.actionValue || ''
            })}
            placeholder="hh:mm"
          />
          <Button variant={ButtonVariantProps.Icon} onClick={() => {
            const adjustTimeValue = adjustTime(preSelectedData?.value || '00:00', 15);
            onPreSelectedData({
              value: adjustTimeValue,
              actionType: preSelectedData?.actionType || ActionTriggerFormActionType.Task,
              actionValue: preSelectedData?.actionValue || ''
            });
          }}>
            <Add />
          </Button>
        </Stack>
      }
      {preSelectedData?.actionType === ActionTriggerFormActionType.Task && preSelectedData.actionValue === TaskType.TRAVEL_DISTANCE && 
        <TextField
          label="Task Value (Distance in KM)"
          type={TextFieldTypeProps.Number}
          inputProps={{ min: 0 }}
          value={preSelectedData?.value}
          onChange={(val) => onPreSelectedData({
            value: val,
            actionType: preSelectedData?.actionType || ActionTriggerFormActionType.Task,
            actionValue: preSelectedData?.actionValue || ''
          })}
        />
      }
      {preSelectedData?.actionType === ActionTriggerFormActionType.Task && 
        (preSelectedData.actionValue === TaskType.QUOTE_VALUE || 
          preSelectedData.actionValue === TaskType.RESOURCE_UTILISED
        ) && 
        <TextField
          label="Task Value (Number)"
          type={TextFieldTypeProps.Number}
          inputProps={{ min: 0 }}
          value={preSelectedData?.value}
          onChange={(val) => onPreSelectedData({
            value: val,
            actionType: preSelectedData?.actionType || ActionTriggerFormActionType.Task,
            actionValue: preSelectedData?.actionValue || ''
          })}
        />
      }
      {preSelectedData?.actionType === ActionTriggerFormActionType.PrePopulatedPartActions &&
        <SelectMenu
          id="form-builder-action-triggers-select-option"
          label="Option"
          items={getPrePopulatedPartActionOptions(ACTION_TRIGGER_FORM_ACTION_VALUE_OPTIONS,preSelectedData?.actionValue)}
          selectedValue={preSelectedData?.value}
          onChange={(val) => onPreSelectedData({ 
            value: val, 
            actionType: preSelectedData?.actionType || ActionTriggerFormActionType.PrePopulatedPartActions,
            actionValue: preSelectedData?.actionValue || ''
          })}
          validate={showErrors}
        />
      }
    </StyledStack>
  );
};
