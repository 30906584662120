import { styled } from '@mui/material/styles';
import { Box } from '../../../../../components/atoms/Box';
import { Button } from '../../../../../components/atoms/Button';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;

export const ContentWrapperBox = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  height: 65vh;
`;