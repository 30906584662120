import { useQuery, UseQueryResult, UseMutationResult, useQueryClient, useMutation } from '@tanstack/react-query';
import { FreightPackageTypeResponse, FreightOrderByShipment } from '../@types/shipment.type';
import { FreightPackageTypesKey, FreightOrderKey } from '../configs/freight';
import { getFreightPackageTypes, createFreightOrder, archiveFreightOrder, returnFreightOrder, quoteFreightOrder, freightOrderByShipment } from '../api/freight';
import { ShipmentsKey } from '../configs/shipment';

export const useGetFreightPackageTypes = (): UseQueryResult<[FreightPackageTypeResponse]> => {
  return useQuery([FreightPackageTypesKey], () => getFreightPackageTypes(), { staleTime: 60 * 60 * 1000 });
};

export const useCreateFreightOrder= (shipmentId?: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createFreightOrder(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([FreightOrderKey, shipmentId]);
      await queryClient.invalidateQueries([ShipmentsKey, shipmentId]);
    },
  });
};

export const useArchiveFreightOrder= (shipmentId?: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await archiveFreightOrder(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([FreightOrderKey, shipmentId]);
    },
  });
};

export const useReturnFreightOrder= (shipmentId?: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await returnFreightOrder(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([FreightOrderKey, shipmentId]);
    },
  });
};

export const useQuoteFreightOrder= (): UseMutationResult => {
  return useMutation(async (data: any) => {
    return await quoteFreightOrder(data);
  });
};

export const useGetFreightOrdersByShipment = (shipmentId: number): UseQueryResult<[FreightOrderByShipment]> => {
  return useQuery([FreightOrderKey, shipmentId], () => freightOrderByShipment(shipmentId));
};