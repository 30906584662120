import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { LocationSearchItemProps } from './LocationSearchItem.props';
import { StyledStack } from './LocationSearchItem.styles';

export const LocationSearchItem: React.FC<LocationSearchItemProps> = ({
  data,
  isDispatchDestinations,
  isAmLidSearch,
  isAmPidOrSHashSearch
}) => {
  if (isAmLidSearch) {
    return (
      <StyledStack direction="column">
        <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.name}</Typography>
        <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.code}</Typography>
        {data.nodeType && <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{data.nodeType.name}</Typography>}
      </StyledStack>
    );
  }

  if (isAmPidOrSHashSearch) {
    return (
      <StyledStack direction="column">
        <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.name}</Typography>
        <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.serial1}</Typography>
      </StyledStack>
    );
  }

  return (
    <StyledStack direction="column">
      {!isDispatchDestinations ? 
        <>
          <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.name}</Typography>
          {data.code && <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.code}</Typography>}
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.isPartSearch ? '': data.nodeTypeName}</Typography>
        </>
        :
        <>
          <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.name}</Typography>
          {data.code && <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.code}</Typography>}
          {data.nodeTypeName && <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{data.nodeTypeName}</Typography>}
          {data.serviceAddress && <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.serviceAddress}</Typography>}
          {data.logisticsAddress && <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.logisticsAddress}</Typography>}
          {data.mainAddress && <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.mainAddress}</Typography>}
        </>
      }
    </StyledStack>
  );
};