import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { EstimatedTimeofArrivalFormProps } from './EstimatedTimeofArrivalForm.props';
import { DateTimePicker } from '../../atoms/DateTimePicker';

export const EstimatedTimeofArrivalForm: React.FC<EstimatedTimeofArrivalFormProps> = ({
  value,
  onChange,
  isError
}) => {
  const [dateTimeValue, setDateTimeValue] = useState(value);

  useEffect(() => {
    if (value) {
      setDateTimeValue(value);
      onChange(value);
    }
  }, [value]);

  return (
    <Grid container spacing={1} marginTop={1} marginBottom={1}>
      <Grid item xs={12}>
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
          Estimated Time of Arrival
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DateTimePicker 
          value={dateTimeValue} 
          onChange={(val: string) => {
            onChange(val);
            setDateTimeValue(val);
          }}
          error={isError && !dateTimeValue}
        />
      </Grid>
    </Grid>
  );
};