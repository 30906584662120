import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useSearchReturnPageDestination, useSearchReturnPageSource } from '../../../queries/live-search-query';
import { LocationNodeData, SizeProps } from '../../../@types';
import { ReturnManifest, ReturnSuggestion } from '../../../@types/shipment.type';
import { ReturnSuggestionsFragment } from './ReturnSuggestionsFragment';
import { useCreateShipment, useGetOneShipment, useGetReturnPageDestinationSuggestions, useGetReturnPageSourceSuggestions, useGetReturnShipmentSuggestions } from '../../../queries/shipment-query';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { RETURN_MANIFEST } from '../../../constants/return';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { getUniqueLocationsForReturn } from '../../../utils/shipment';
import { TextField } from '../../../components/atoms/TextField';

export const Return: React.FC = () => {
  const { id }: any = useParams();
  const isNew = id === 'create';
  const navigate = useNavigate();
  
  const [returnSuggestions, setReturnSuggestions] = useState<Array<ReturnSuggestion>>([]);
  const [manifest, setManifest] = useState<ReturnManifest>(RETURN_MANIFEST);

  const createShipment = useCreateShipment();
  const getOneShipment = useGetOneShipment(id === 'create' ? 0 : id);
  const searchReturnPageSource = useSearchReturnPageSource();
  const searchReturnPageDestination = useSearchReturnPageDestination();
  const getReturnShipmentSuggestions = useGetReturnShipmentSuggestions(manifest.sourceNodeId || 0, manifest.destinationNodeId || 0);
  const getReturnPageSourceSuggestions = useGetReturnPageSourceSuggestions();
  const getReturnPageDestinationSuggestions = useGetReturnPageDestinationSuggestions();

  TabHandler(isNew ? `Return | ${PLATFORM_NAME}` : `Return: ${id} | Return | ${PLATFORM_NAME}`);

  useEffect(() => {
    if (!isNew && getOneShipment.data) {
      setManifest({ ...getOneShipment.data, sourceNodeId: NaN, destinationNodeId: NaN });
      setReturnSuggestions([]);
    } else if (isNew) {
      setManifest(RETURN_MANIFEST);
      setReturnSuggestions([]);
    }
  }, [isNew, getOneShipment.data]);

  useEffect(() => {
    isNew && manifest.sourceNodeId && manifest.destinationNodeId && getReturnShipmentSuggestions.refetch();
  }, [isNew, manifest.sourceNodeId, manifest.destinationNodeId]);

  useEffect(() => {
    isNew && getReturnPageSourceSuggestions.refetch();
    isNew && getReturnPageDestinationSuggestions.refetch();
  }, []);

  useEffect(() => {
    isNew && getReturnShipmentSuggestions.data && setReturnSuggestions(getReturnShipmentSuggestions.data);
  }, [getReturnShipmentSuggestions.data, isNew]);

  const onClickSubmit = async () => {
    const returnShipment: any = await createShipment.mutateAsync(manifest);
    navigate(`/shipments/return/${returnShipment.id}`);
  };

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        Return
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={4} mt={1} mb={4}>
        <Grid item xs={12} md={4}>
          {isNew ?
            <LiveSearchBox
              disabled={!isNew}
              title="Source"
              timeOffset={400}
              value={manifest.source || ''}
              defaultSuggetions={getReturnPageSourceSuggestions.data}
              onClearValue={() => {
                setManifest({ 
                  ...manifest, 
                  sourceNodeId: undefined,
                  source: ''
                });
              }}
              renderItem={(props: any, option: any) => { 
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setManifest({ 
                  ...manifest, 
                  sourceNodeId: obj.id,
                  source: obj.name
                });
              }}
              onApiInvoke={async (name: string) => {
                const searchData = await searchReturnPageSource.mutateAsync(name);

                return getUniqueLocationsForReturn(searchData, getReturnPageSourceSuggestions.data || []); 
              }}
            />
            :
            <TextField
              label="Source"
              disabled
              fullWidth
              value={manifest.source}
            />
          }
        </Grid>
        <Grid item xs={12} md={4}>
          {isNew ?
            <LiveSearchBox
              disabled={!isNew}
              title="Destination"
              timeOffset={400}
              value={manifest.destination || ''}
              defaultSuggetions={getReturnPageDestinationSuggestions.data}
              onClearValue={() => {
                setManifest({ 
                  ...manifest, 
                  destinationNodeId: undefined,
                  destination: ''
                });
              }}
              renderItem={(props: any, option: any) => { 
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setManifest({ 
                  ...manifest, 
                  destinationNodeId: obj.id,
                  destination: obj.name
                });
              }}
              onApiInvoke={async (name: string) => {
                const searchData = await searchReturnPageDestination.mutateAsync(name);

                return getUniqueLocationsForReturn(searchData, getReturnPageDestinationSuggestions.data || []); 
              }}
            />
            :
            <TextField
              label="Destination"
              disabled
              fullWidth
              value={manifest.destination}
            />
          }
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            disabled={!isNew}
            size={SizeProps.Small}
            label="Notes"
            multiline
            value={manifest.note}
            onChange={(note) => setManifest({ ...manifest, note })}
          />
        </Grid>
      </Grid>
      <ReturnSuggestionsFragment returnSuggestions={returnSuggestions} setManifest={setManifest} manifest={manifest} isNew={isNew} />
      {isNew &&
        <Stack alignItems="flex-end" mt={4}>
          <Button variant={ButtonVariantProps.Primary} onClick={onClickSubmit}>Submit</Button>
        </Stack>
      }
    </PageContainer>
  );
};