import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { ReplenishmentConfig, ReplenishmentConfigListResponse, ReplenishmentReportFilters, ReplenishmentReportListResponse } from '../@types/replenishmentConfig.type';
import { createReplenishmentConfig, getAllReplenishmentConfigs, getAllReplenishmentReports, getReplenishmentConfigById, updateReplenishmentConfig } from '../api/replenishmentConfig';
import { ReplenishmentConfigsKey, ReplenishmentReportsKey } from '../configs/replenishmentConfigs';
import { PaginationSettings, OrderSettings, QueryOption } from '../@types';

export const useGetReplenishmentConfigs = (queryOptions: QueryOption): UseQueryResult<ReplenishmentConfigListResponse> => {
  return useQuery([ReplenishmentConfigsKey, queryOptions.page, queryOptions.pageSize], () => getAllReplenishmentConfigs(queryOptions));
};

export const useCreateReplenishmentConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await createReplenishmentConfig(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ReplenishmentConfigsKey]);
    },
  });
};

export const useGetReplenishmentConfigById = (id: number): UseQueryResult<ReplenishmentConfig> => {
  return useQuery([ReplenishmentConfigsKey, id], () => getReplenishmentConfigById(id), { enabled: false });
};

export const useUpdateReplenishmentConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    await updateReplenishmentConfig(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ReplenishmentConfigsKey]);
    },
  });
};

export const useGetAllReplenishmentReports = (pageSettings: PaginationSettings, orderBy?: OrderSettings, customFilters?: ReplenishmentReportFilters): UseQueryResult<ReplenishmentReportListResponse> => {
  return useQuery([ReplenishmentReportsKey], () => getAllReplenishmentReports(
    pageSettings.limit,
    pageSettings.currentPage,
    orderBy,
    customFilters
  ), { 
    enabled: false 
  });
};