import React, { useState, useRef } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Menu, MenuItem } from '@mui/material';
import { DataGridMenuProps } from './DataGridMenu.props';
import { ColorProps } from '../../../@types';
import { GridMenuOption } from '../../../constants/report';
import { StyledReportIcon } from './DataGridMenu.styles';

export const DataGridMenu: React.FC<DataGridMenuProps> = ({
  id,
  title,
  options,
  onSelectOption,
  isDefault,
  owner
}) => {
  const [reportOptionsOpen, setReportOptionsOpen] = useState(false);
  const reportOptionsOpenAnchorEl = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        id={`${id}-data-grid-menu-button`}
        ref={reportOptionsOpenAnchorEl}
        onClick={() => setReportOptionsOpen(true)}
      >
        <StyledReportIcon color={ColorProps.Primary}/>
        {title}
      </Button>
      <Menu
        id={`${id}-data-grid-menu`}
        anchorEl={reportOptionsOpenAnchorEl?.current}
        open={reportOptionsOpen}
        onClose={() => setReportOptionsOpen(false)}
      >
        {options.map((option, index) => (
          <MenuItem 
            key={index} 
            onClick={() => onSelectOption(option)}
          >
            {option}
            {option === GridMenuOption.SetAsDefault && isDefault && owner &&
            <CheckCircleIcon color={ColorProps.Success}/>
            }
          </MenuItem>))
        }
      </Menu>
    </>
  );
};
