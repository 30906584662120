import { styled } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';

export const StyledChip = styled(Chip)((props: ChipProps & { bgColor: string; isSystem?: boolean }) =>`
  background-color: ${props.isSystem ? props.bgColor : 'transparent'};
		box-shadow: inset 0 0 0 10em rgb(255 255 255 / ${props.isSystem ? '80%': '0'});
  color: ${props.bgColor};
  border: ${props.isSystem ? '1px solid' : '1px solid ' + props.bgColor};
  &&:hover, &&:focus {
    background-color: ${props.isSystem ? props.bgColor : 'transparent'};
  };
  & .MuiChip-deleteIcon {
    color: ${props.bgColor};
    opacity: 0.6;
  };`
);