import React from 'react';
import { Divider } from '@mui/material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TypographyVariantProps } from '../../atoms/Typography';
import { PopupDialogProps } from './PopupDialog.props';
import { Container, Footer, TitleLabel, ContentBox } from './PopupDialog.styles';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';

export const PopupDialog: React.FC<PopupDialogProps> = ({
  title,
  width = '80%',
  maxWidth = '800px',
  children,
  isOpen,
  isClear,
  isEdit,
  onSave,
  onClose,
  onClear,
  actionLabel = 'Save',
  cancelButtonLable = 'Close'
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={(e) => onClose(e)}
      aria-labelledby={`${title}-modal-title`}
      aria-describedby={`${title}-modal-description`}
    >
      <Container style={{ width, maxWidth }}>
        <CloseIcon handleClose={(e, reason) => onClose(reason)} />
        <TitleLabel variant={TypographyVariantProps.H5}>{title}</TitleLabel>
        <Divider />
        <ContentBox>
          {children}
        </ContentBox>
        <Divider />
        <Footer direction="row-reverse" spacing={1}>
          <Button variant={ButtonVariantProps.Primary} onClick={onSave}>{isEdit ? 'Update' : actionLabel}</Button>
          <Button variant={ButtonVariantProps.Secondary} onClick={onClose}>{cancelButtonLable}</Button>
          {isClear && (<Button variant={ButtonVariantProps.Secondary} onClick={onClear}>Clear</Button>)}
        </Footer>
      </Container>
    </Modal>
  );
};
