import moment from 'moment';

export const getDateTime = (date?: Date | string) => {
  const now = date? new Date(date) : new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  const day = now.getDate();
  const hour = now.getHours() > 12 ? now.getHours() - 12 : (now.getHours() == 0 ? 12 : now.getHours());
  const minute = now.getMinutes();
  const meridiemType = now.getHours() >= 12 ? 'pm': 'am';
  return `${month}/${day}/${year} ${hour}:${minute} ${meridiemType}`;
};

export const formatDateTime = (date: Date | string, format: string) => {
  return moment(date).format(format);
};

export const getServiceActivityMonitorTime = (date?: Date | string) => {
  const now = date ? new Date(date) : new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};