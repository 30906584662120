import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, ListItemText, FormHelperText, Grid } from '@mui/material';
import { MultiSelectCheckmarksMenuProps } from './MultiSelectCheckmarksMenu.props';
import { Checkbox } from '../Checkbox';
import { SizeProps } from '../../../@types';
import { ALL_KEYWORD } from '../../../constants/common';

export const MultiSelectCheckmarksMenu: React.FC<MultiSelectCheckmarksMenuProps> = ({
  className,
  size = SizeProps.Small,
  id,
  label,
  items,
  required,
  error,
  selectedValue,
  wildCard,
  disabled,
  helperText,
  onChange
}) => {
  const [itemLabel, setItemLabel] = React.useState<string[]>(selectedValue? selectedValue : []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: SelectChangeEvent<typeof itemLabel>) => {
    let {
      target: { value },
    } = event;

    setItemLabel(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if(value.indexOf(ALL_KEYWORD) === items.length -1) {
      value = [];
    }
    onChange(typeof value === 'string' ? value : value.join(','));   
  };

  useEffect(() => {
    if(selectedValue && selectedValue !== itemLabel){
      setItemLabel(selectedValue.filter((filteredData) => filteredData !== ALL_KEYWORD));
    }
  }, [selectedValue]);

  return (
    <FormControl fullWidth size={size} error={error} className={className}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        required={required}
        id={id}
        multiple
        disabled={disabled}
        value={itemLabel}
        onChange={handleChange}
        label={label}
        renderValue={(value) => {
          return (
            <Grid container sx={{ whiteSpace: 'normal' }}>
              {items.filter(item => value.includes(item.value)).map(item => item.label).join(', ')}
            </Grid>
          );
        }}
        MenuProps={MenuProps}
      >
        {wildCard && 
          <MenuItem key="all" value="all">
            <Checkbox checked={itemLabel.length === items.length -1} />
            <ListItemText primary="All"/>
          </MenuItem>
        }
        {items && items.filter((filteredData) => filteredData.value !== ALL_KEYWORD).map(({ value, label, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            <Checkbox checked={itemLabel.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};