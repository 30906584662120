import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Root, PermissionTitle } from './PermissionItem.styles';
import { PermissionItemProps } from './PermissionItem.props';
import { TypographyVariantProps } from '../../atoms/Typography';
import { VerticalAlignProps } from '../../atoms/InlineGroup';
import { Button, ButtonVariantProps, ButtonSizeProps } from '../../atoms/Button';
import { ColorProps, FontSizeProps } from '../../../@types';

export const PermissionItem: React.FC<PermissionItemProps> = ({
  permission,
  onRemovePermission
}) => {
  return (
    <Root childGap={8} vertical={VerticalAlignProps.Center}>
      <PermissionTitle {...permission} variant={TypographyVariantProps.Subtitle2}> 
        {permission.name}
      </PermissionTitle>
      <Button variant={ButtonVariantProps.Icon} size={ButtonSizeProps.Medium}>
        <ClearIcon fontSize={FontSizeProps.Small} color={ColorProps.Primary} onClick={() => onRemovePermission(permission)} />
      </Button>
    </Root>
  );
};
