import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Checkbox, useMediaQuery } from '@mui/material';
import { AllocatedServicesProps } from './AllocatedServices.props';
import { GridContainer, SmallLabel, SmallMobileLabel, StyledCheckbox } from './AllocatedServices.styles';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { MobileDataGridItem } from '../../organisms/MobileDataGridItem';
import { ScreenSize, SizeProps } from '../../../@types';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';

export const AllocatedServices: React.FC<AllocatedServicesProps> = ({
  onChange,
  allocatedServiceData,
  allocatedServicesLoading
}) => {
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const allocatedServicesColumns: GridColDef[] = [
    { 
      field: 'id',
      hide: true,
      flex: 0.2,
    },
    {
      field: 'isUserCheckedIn',
      headerName: '',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <Checkbox defaultChecked={params.row.isUserCheckedIn} onChange={(event: any) => onChange(params.row, event.target.checked)}/>
    },
    {
      field: 'serviceId',
      headerName: 'Service ID',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.serviceId}</SmallLabel>
    },
    {
      field: 'serviceActionProcessTypes',
      headerName: 'Service/Action/Process Types',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.serviceActionProcessTypes}</SmallLabel>
    },
    {
      field: 'nodeName',
      headerName: 'Node Name',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.nodeName}</SmallLabel>
    },
    {
      field: 'serviceStatusName',
      headerName: 'Service Status Name',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.serviceStatusName}</SmallLabel>
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem
            data={[
              {
                title: '',
                value: 
                  <StyledCheckbox 
                    defaultChecked={row.isUserCheckedIn}
                    size={SizeProps.Small} 
                    onChange={(event: any) => onChange(row, event.target.checked)}
                  />
              },
              {
                title: 'Service ID',
                value: <SmallMobileLabel>{row.serviceId}</SmallMobileLabel>
              },
              {
                title: 'Service/Action/Process Types',
                value: <SmallMobileLabel>{row.serviceActionProcessTypes}</SmallMobileLabel>
              },
              {
                title: 'Node Name',
                value: <SmallMobileLabel>{row.nodeName}</SmallMobileLabel>
              },
              {
                title: 'Service Status Name',
                value: <SmallMobileLabel>{row.serviceStatusName}</SmallMobileLabel>
              }
            ]}
          />
        );
      },
    }
  ];

  return (
    <GridContainer isMobile={isMobileView}>
      <CustomDataGrid
        columns={allocatedServicesColumns}
        rows={allocatedServiceData}
        isMobile={isMobileView}
        getRowId={(row) => row?.id}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: false,
            hideFooter: true
          }
        }} 
        enableCSVExport={false}
        exportFileName={''}
        loading={allocatedServicesLoading}
        getRowHeight={() => 'auto'}
      />
    </GridContainer>
  );
};
