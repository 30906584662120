import { styled } from '@mui/material/styles';
import { ListItem, Divider, Box } from '@mui/material';

export const FooterWrapper = styled('div')(({ theme }) => `
	width: 100%;
	position: relative;
  margin-top: auto;
	justify-content: center;
	justify-items: center;
	align-items: center;
	margin-bottom: 10px;
  color: ${theme.palette.common.white};
`);

export const ListItemStyled = styled(ListItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
`;

export const DividerStyled = styled(Divider)`
  width: 100%;
`;

export const ProfileContainerBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const ProfileImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

export const ProfileWrapper = styled('div')`
  padding-left: 10px;
  min-width: 100px;
`;

export const LogoutImageWrapper = styled('div')`
  height: 25px;
  width: 25px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;