import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SerializedStyledTableCell = styled(TableCell)`
  height: 15px;
  padding: 10px;
  text-align: center;
`;

export const SerializedStyledPartTypeTableCell = styled(TableCell)`
  height: 15px;
  padding: 10px;
  min-width: 200px;
`;

export const SerializedStyledLocationTableCell = styled(TableCell)`
  height: 15px;
  padding: 10px;
  text-align: center;
  min-width: 300px;
`;