import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ListItemButtonProps } from './ListItemButton.props';
import { ListItemButtonStyled } from './ListItemButton.styles';
import { selectCurrentSubMenuByPath } from './ListItemButton.utils';

export const ListItemButton: React.FC<ListItemButtonProps> = ({
  url,
  setHeaderIsExpanded,
  id,
  children,
  onClick
}) => {
  const [selected, setSelected] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setSelected(selectCurrentSubMenuByPath(url));
  }, [location]);

  useEffect(() => {
    if (selected && setHeaderIsExpanded) {
      setHeaderIsExpanded(true);
    }
  }, [selected]);

  return (
    <ListItemButtonStyled 
      id={id} 
      url={url} 
      selected={selected}
      onClick={onClick}
    >
      {children}
    </ListItemButtonStyled>
  );
};
