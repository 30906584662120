import React, { useState, useEffect } from 'react';
import { Snackbar } from '../../../../../../components/atoms/Snackbar';
import { GetGeoLocationFormFragmentProps } from './GetGeoLocationFormFragment.props';
import { saveServiceGeoLocationData } from './../../../../../../utils/common';

export const GetGeoLocationFormFragment: React.FC<GetGeoLocationFormFragmentProps> = ({
  service
}) => {
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const serviceBasedPositionData = {
            serviceId: service.id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy || 0,
            altitude: position.coords.altitude || 0,
            altitudeAccuracy: position.coords.altitudeAccuracy || 0,
            heading: position.coords.heading || 0,
            speed: position.coords.speed || 0
          };
          return saveServiceGeoLocationData(serviceBasedPositionData);
        },
        (error) => {
          setMessage(error.message);
          setOpenToast(true);
          return;
        }
      );
    } else {
      setMessage('Geo-Location data not retrieved');
      setOpenToast(true);
      return;
    }
  }, [navigator.geolocation]);

  return (
    <Snackbar
      open={openToast}
      autoHideDuration={2000}
      message={message}
      onClose={() => setOpenToast(false)}
    />
  );
};