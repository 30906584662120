import { DropdownItem } from '../components/atoms/SelectMenu';

// for mapping client contract hierarchy for single client and multiple contracts
export const getClientHierarchy = (clients: DropdownItem[], contracts: DropdownItem[], selectedClient: string, selectedContracts: string[]) => {
  const clientString = clients.find((client) => client.value === selectedClient)?.label;

  const contractString = contracts.reduce((accumulator: string[], currentValue: DropdownItem) => {
    if (selectedContracts.includes(currentValue.value)) {
      return [...accumulator, currentValue.label];
    }
    return accumulator;
  }, []).join(', ');

  return `${clientString} -> ${contractString}`;
};

export const leafNodeCreater = (selectedClient: string, selectedContracts: string[]) => {
  const leafNode: string[] = selectedContracts.includes('any') ? [selectedClient] : selectedContracts.filter((selectedContract: string) => selectedContract !== '');

  return leafNode.map(Number);
};

export const leafNodeCreaterForLiveSearch = (selectedClient: string, selectedContracts: string[]) => {
  let leafNode: string[] = [];
  if (!selectedContracts.includes('any')) {
    const filterSelectedContracts = selectedContracts.filter((selectedContract: string) => selectedContract !== '');
    leafNode = leafNode.concat(filterSelectedContracts);
  }
  
  leafNode.push(selectedClient); 

  return leafNode.map(Number);
};