import { QueryOption } from '../@types';
import { isNotArrayEmpty } from '../utils/array-utils';
import { httpClient, HttpMethod } from './http-client';

export const getRoles = (queryOptions: QueryOption) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/roles?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && isNotArrayEmpty(sortModel, 0)) {
    url += `&order_by=${sortModel[0]?.field}&order_direction=${sortModel[0]?.sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getActiveRoles = (): any => {
  return httpClient('/roles/activeroles', HttpMethod.Get, null);
};

export const getRoleById = (id: number) => {
  return httpClient(`/roles/${id}`, HttpMethod.Get, null);
};

export const createRole = (role: any) => {
  return httpClient('/roles', HttpMethod.Post, role);
};

export const updateRole = (id: number, role: any) => {
  return httpClient(`/roles/${id}`, HttpMethod.Put, role);
};

export const getRolePermissionForRoleEdit = (id: any) => {
  return httpClient(`/roles/${id}/permTree/updateRole`, HttpMethod.Get, null);
};

export const getRolePermissionForUserEdit = (id: any) => {
  return httpClient(`/roles/${id}/permTree/updateUser`, HttpMethod.Get, null);
};

export const createRolePermission = (id: number, addedPermissions: any) => {
  return httpClient(`/roles/${id}/permissions`, HttpMethod.Post, addedPermissions);
};

export const updateRolePermission = (id: number, addedPermissions: any) => {
  return httpClient(`/roles/${id}/updatePermissions`, HttpMethod.Post, addedPermissions);
};

export const getPermission = () => {
  return httpClient('/system/permTree', HttpMethod.Get, null);
};