import { styled } from '@mui/material/styles';
import { SelectMenu } from '../../atoms/SelectMenu';
import { Divider, DividerProps } from '@mui/material';

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;

export const Div = styled('div')`
  width: 100%;
`;
interface VerticalDividerProps extends DividerProps {
  vertical?: boolean;
}

export const VerticalDivider = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'vertical',
})<VerticalDividerProps>(({ theme, vertical }) => ({
  borderStyle: 'dashed',
  borderColor: theme.palette.divider,
  ...(vertical ? {
    width: '1px',
    borderRightWidth: '3px',
    height: '100%'
  } : {
    width: '100%',
    borderBottomWidth: '3px',
    height: '1px'
  }),
}));


export const StyledLabel = styled('div')`
  min-width: 100px;
`;