import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../../../components/atoms/PageContainer';
import { IncomingShipmentListFragment } from './IncomingShipmentListFragment';
import { IncomingShipmentDetailsFragmentProps } from './IncomingShipmentDetailsFragment.props';
import { StyledBox } from './IncomingShipmentDetailsFragment.styles';
import { LiveSearchListItem } from '../../../../../components/atoms/LiveSearchBox';
import { ShipmentSearchItem } from '../../../../../components/molecules/ShipmentSearchItem';
import { ShipmentSearchData } from '../../../../../@types';
import { useGetSearchShipment } from '../../../../../queries/live-search-query';
import { LiveSearchBoxWithId } from '../../../../../components/atoms/LiveSearchBoxWithId';
import { IncomingShipmentType, ShipmentStatusCodes } from '../../../../../@types/shipment.type';
import { useGetIncomingShipments } from '../../../../../queries/shipment-query';

export const IncomingShipmentDetailsFragment: React.FC<IncomingShipmentDetailsFragmentProps> = ({
  shipmentId
}) => {
  const [selectedShipmentId, setSelectedShipmentId] = useState<number>();
  const [incomingShipments, setIncomingShipments] = useState<IncomingShipmentType[]>([]);
  const searchShipmentQuery = useGetSearchShipment();
  const getIncomingShipments = useGetIncomingShipments([ShipmentStatusCodes.InTransit, ShipmentStatusCodes.Partial, ShipmentStatusCodes.Pending]);

  useEffect(() => {
    getIncomingShipments.data && setIncomingShipments(getIncomingShipments.data);
  }, [getIncomingShipments.data]);

  useEffect(() => {
    selectedShipmentId && shipmentId(selectedShipmentId);
  }, [selectedShipmentId]);

  return (
    <PageContainer>
      <IncomingShipmentListFragment 
        incomingShipments={incomingShipments} 
        selectedShipmentId={(selctedValue) => setSelectedShipmentId(selctedValue)}
      />
      <StyledBox>
        <LiveSearchBoxWithId
          title="Shipment Search"
          timeOffset={400}
          value={selectedShipmentId?.toString() || ''}
          onClearValue={() => {
            setSelectedShipmentId(undefined);
          }}
          renderItem={(props: any, option: any) => {
            return (
              <LiveSearchListItem {...props}>
                <ShipmentSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onChange={async (obj: ShipmentSearchData) => {
            setSelectedShipmentId(obj.id);
          }}
          onApiInvoke={async (searchQuery: string) => {
            return await searchShipmentQuery.mutateAsync(searchQuery);
          }}
        />
      </StyledBox>
    </PageContainer>
  );
};