import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import SimpleImageSlider from 'react-simple-image-slider';
import { ImagePopupProps } from './ImagePopup.props';
import {
  ThumbImage, 
  CloseButton 
} from './ImagePopup.styles';

export const ImagePopup: React.FC<ImagePopupProps> = ({
  title,
  images
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ThumbImage
        src={images[0]?.url}
        onClick={() => setOpen(true)}
      />
      <Dialog
        fullScreen
        title={title}
        open={open}
        onClose={() => setOpen(false)}
      >
        <SimpleImageSlider
          width="100%"
          height="100%"
          images={images}
          showBullets={true}
          showNavs={true}
        />
        <CloseButton onClick={() => setOpen(false)} />
      </Dialog>
    </>
  );
};
