import { HttpMethod, httpClient } from './http-client';
import { InteractionRuleObject } from '../@types/interaction.type';

export const createInteractionRule = (interactionRule: InteractionRuleObject) => {
  return httpClient('/interactionRules', HttpMethod.Post, interactionRule);
};
  
export const updateInteractionRule = (id: number, interactionRule: InteractionRuleObject) => {
  return httpClient(`/interactionRules/${id}`, HttpMethod.Put, interactionRule);
};

export const getAllInteractionTemplates = () => {
  return httpClient('/interactionTemplates', HttpMethod.Get, null);
};

export const getAllTriggerPointTypes = () => {
  return httpClient('/triggerPointTypes', HttpMethod.Get, null);
};

export const getAllEventTypes = () => {
  return httpClient('/eventType', HttpMethod.Get, null);
};