import React from 'react';
import { Stack } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { StructureMergeLocationSearchItemProps } from './StructureMergeLocationSearchItem.props';
import { MicroText, StyledStack } from './StructureMergeLocationSearchItem.styles';

export const StructureMergeLocationSearchItem: React.FC<StructureMergeLocationSearchItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="column">
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data?.structureName}</Typography>
        <img src={data?.logoPic} />
      </Stack>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data?.nodeTypeCode}</Typography>
      <MicroText>{data?.mainAddress}</MicroText>
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{data?.reference1}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data?.name}</Typography>
    </StyledStack>
  );
};