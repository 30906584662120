import { styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { TextField } from '../../atoms/TextField';

export const StyledTypography = styled(Typography)((props: { color: string; fontSize: string }) =>`
  color: ${props.color};
  font-size: ${props.fontSize};
  padding: 3px !important;
`);

export const StyledTextField = styled(TextField)`
  input {
    padding: 2px 6px !important;
  }
`;