export enum DateTypeNames {
  Now = 'now',
  Today = 'today',
  Yesterday = 'yesterday',
  First_Day_Of_This_Week = 'first-day-of-this-week',
  First_Day_Of_This_Month = 'first-day-of-this-month',
  First_Day_Of_This_Year= 'first-day-of-this-year',
  Last_Day_Of_This_Week = 'last-day-of-this-week',
  Last_Day_Of_This_Month = 'last-day-of-this-month',
  Last_Day_Of_This_Year = 'last-day-of-this-year',
  First_Day_Of_Last_Week = 'first-day-of-last-week',
  First_Day_Of_Last_Month = 'first-day-of-last-month',
  First_Day_Of_Last_Year = 'first-day-of-last-year',
  Last_Day_Of_Last_Week = 'last-day-of-last-week',
  Last_Day_Of_Last_Month = 'last-day-of-last-month',
  Last_Day_Of_Last_Year = 'last-day-of-last-year',
}