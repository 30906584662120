import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { CountriesKey, Address, StatesKey } from '../@types/address.type';
import { getCountries, getStatesByCountryId } from '../api/address';

export const useGetCountries = (): UseQueryResult<Address[]> => {
  return useQuery([CountriesKey], () => getCountries(), { staleTime: 5 * 60 * 60 * 1000 });
};

export const useGetStatesByCountryId = (countryId: number): UseQueryResult<Address[]> => {
  return useQuery([StatesKey, countryId], () => getStatesByCountryId(countryId), { enabled: false, staleTime: 5 * 60 * 60 * 1000 });
};
