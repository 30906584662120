import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { CloseIconProps } from './CloseIcon.props';
import { CloseWrapper } from './CloseIcon.styles';
import { COLORS } from '../../../configs/colors';

export const CloseIcon: React.FC<CloseIconProps> = ({
  handleClose
}) => {
  return (
    <CloseWrapper onClick={handleClose}>
      <CancelOutlinedIcon sx={{ color: `${COLORS.Red}` }} />
    </CloseWrapper>
  );
};