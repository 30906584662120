import React, { useState, useEffect } from 'react';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Button,
  ButtonColorProps,
  ButtonSizeProps,
  ButtonVariantProps
} from '../../atoms/Button';
import { EditDropdownFieldProps } from './EditDropdownField.props';
import { 
  StyledTypography,
  StyledSelectMenu
} from './EditDropdownField.styles';
import { Grid } from '@mui/material';
import { isDropDownEmpty } from '../../../utils/common';

export const EditDropdownField: React.FC<EditDropdownFieldProps> = ({
  isEditable,
  textColor = '',
  textFontSize = '',
  options,
  value,
  validate = false,
  optionalLabel,
  optionalLabelEnabled = false,
  errorMessageEnabled = true,
  onChange
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (value) {
      setTextValue(value);
    }
  }, [value]);

  const handleOnSubmit = () => {
    if (validate && isDropDownEmpty(textValue)) {
      return;
    }

    onChange(textValue);
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setTextValue(value);
    setIsEdit(false);
    setIsShown(false);
  };

  return (
    <Grid
      container 
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
      alignItems="center"
      display="flex"
      width="100%"
    >
      {isEdit ?
        <Grid item xs={12} md={8}>
          <StyledSelectMenu
            id="edit-dropdown-field-select" 
            items={options}
            selectedValue={textValue} 
            onChange={(val) => setTextValue(val)}
            validate={validate && isDropDownEmpty(textValue)}
            optionalLabelEnabled={optionalLabelEnabled}
            optionalLabel={optionalLabel}
            helperTextEnabled={errorMessageEnabled}
          />
        </Grid>
        :
        <Grid item>
          <StyledTypography
            color={textColor}
            fontSize={textFontSize}
          >
            {options?.find((obj) => obj.value === value)?.label || '-'}
          </StyledTypography>
        </Grid>
      }
      {isEdit &&
        <Grid item xs={12} md={4}>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </Grid>
      }
      {isShown && !isEdit &&
        <Grid item>
          <Button
            variant={ButtonVariantProps.Icon}
            size={ButtonSizeProps.Small}
            onClick={() => setIsEdit(true)}
          >
            <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
          </Button>
        </Grid>
      }
    </Grid>
  );
};
