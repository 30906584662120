import { DeleteRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const CapacityDelete = styled(DeleteRounded)`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`;

export const SwitchGrid = styled(Grid)`
  margin-top: -15px;
  justify-content: center;
  align-items: center;
`;
