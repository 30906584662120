import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LiveSearchListItem = styled('li')`
  border-bottom: 1px solid lightgray;
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    max-height: 30vh;
    overflow: auto !important;
  }
`;