import moment from 'moment';
import qz from 'qz-tray';
import { DEFAULT_PRINTER_NAME, REACT_APP_QZ_CERTIFICATE } from '../configs/envs';
import { PrintLabelEntityTypes } from '../@types/print-label.type';
import { getSignedMessage } from '../api/printLabel';

//type of data differs based on the different templates
export const replaceDynamicValuesInTemplate = (data: any, template: string) => {
  //In the template all the properties follow the format {{property_name}}
  const filtertedPropeties = template.match(/{{(.*?)}}/g);

  if (filtertedPropeties) {
    //get all the property names
    const propertyListFromTemplate: string[] = filtertedPropeties.map((val: any) => {
      return val.replace(/{|}/g, '');
    });

    //replace property names with real values
    propertyListFromTemplate.forEach(item => {
      const value = data[item] || '';
      const propertyName = `{{${item}}}`;
      template = template.replace(propertyName, value);
    });
  }

  return template;
};

export const printLabel = (labelText: string, entityType?: string) => {
  const printerName = DEFAULT_PRINTER_NAME;

  qz.websocket.connect().then(() => {
    return qz.printers.find();
  }).then((printers: any) => {
    if (!printers?.length || !printers.some((printer: string) => printer.toLocaleLowerCase() === printerName.toLocaleLowerCase())) {
      alert(`Printer with name ${printerName} not found!`);
      return Promise.resolve();
    }

    const config = qz.configs.create(printerName);

    if(entityType === PrintLabelEntityTypes.FreightOrderLabel && labelText) {
      const printData = [
        {
          type: 'pixel',
          format: 'pdf',
          flavor: 'base64',
          data: labelText
        }];

      return qz.print(config, printData);
    }

    const textToPrint = replaceDynamicValuesInTemplate({ currentDateTime: moment().format('YYYY-MM-DD HH:mm') }, labelText);
    return qz.print(config, [textToPrint]);
  }).then(() => {
    return qz.websocket.disconnect();
  }).catch(() => {
    qz.websocket.disconnect();
  });
};

export const initializeQZTrayCertificate = () => {
  qz.security.setCertificatePromise((resolve: any) => {
    resolve(REACT_APP_QZ_CERTIFICATE);
  });

  qz.security.setSignatureAlgorithm('SHA512');
  qz.security.setSignaturePromise((toSign: any) => {
    return (resolve: any, reject: any) => { 
      getSignedMessage(toSign).then((data: string) => {
        resolve(data);
      }).catch(() => reject()); 
    };
  });
};