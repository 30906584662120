import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalContent = styled(Box)`
  margin-top: 20px;
  width: 100%;
`;

export const ModalFooter = styled(Box)`
  width: 100%;
`;

export const ModalAddressBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  max-width: 1050px;
  min-height: 300px;
  max-height: 90%;
  overflow-y: auto;
`;

export const AddressContactGrid = styled(Grid)`
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 1ch;
  width: 100%;
`;