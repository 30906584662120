import { styled } from '@mui/material/styles';
import { Avatar, Grid, Stack } from '@mui/material';
import { Box } from '../../atoms/Box';

export const SLAContactBox = styled(Box)`
  width: 100%;
  border: 0px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 0px;
  padding-top: 2px;
`;

export const ContactGridContainer = styled(Grid)`
  margin-bottom: 10px;
`;

export const LetterAvatar = styled(Avatar)`
  background-color: #4CAF50;
  color: white;
  border-style: solid;
  border-width: 2px;
  height: 20px;
  width: 20px;
  margin-top: 3px;
  margin-left: 5px;
`;

export const ReviewedLetterAvatar = styled(Avatar)`
  background-color: red;
  color: white;
  border-style: solid;
  border-width: 2px;
  height: 20px;
  width: 20px;
  margin-top: 3px;
  margin-left: 5px;
`;

export const GridInnerWrapper = styled(Stack)`
  margin-left: 8px;
  padding-top: 2px;
  padding-right: 10px;
`;