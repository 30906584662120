import React from 'react';
import { PartActionShipmentReceiveProps } from './PartActionShipmentReceive.props';
import { Grid, Stack } from '@material-ui/core';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
export const PartActionShipmentReceive: React.FC<PartActionShipmentReceiveProps> = ({
  shipmentManifestSummery
}) => {
  return (
    <Stack>
      <Typography variant={TypographyVariantProps.Caption}>{`This part ${shipmentManifestSummery?.serial} is part of shipment ${shipmentManifestSummery?.shipmentId} inbound to ${shipmentManifestSummery?.destinationNode}.  Please confirm receipt of the shipment before proceeding.`}</Typography>
      <Grid container rowSpacing={0} mt={1}>
        {shipmentManifestSummery?.manifestSummery.map(manifest => (
          <Grid key={manifest.partTypeId} item md={12}>
            <Typography key={manifest.partTypeId} variant={TypographyVariantProps.Caption}>{`${manifest.partTypeName} - ${manifest.qty}`}</Typography>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};