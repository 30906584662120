import { TAGS } from './tag.constant';

export const SAMPLEROLE = {
  activeUserCount: 0,
  id: 0,
  isActive: true,
  name: '',
  notes: '',
  visibilityRuleId: 0,
  tags: [TAGS],
};