import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const columnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Part',
    value: [
      {
        title: 'Timestamp',
        field: 'createdAt',
        value: true
      },
      {
        title: 'User',
        field: 'nodeName',
        value: true
      },
      {
        title: 'User Role',
        field: 'createdByRole',
        value: true
      },
      {
        title: 'Part History Type',
        field: 'historyTypeName',
        value: true
      },
      {
        title: 'Asset ID',
        field: 'partId',
        value: true
      },
      {
        title: 'Serial 1',
        field: 'serial1',
        value: true
      },
      {
        title: 'Serial 2',
        field: 'serial2',
        value: false
      },
      {
        title: 'Serial 3',
        field: 'serial3',
        value: false
      },
      {
        title: 'Condition',
        field: 'conditionName',
        value: true
      },
      {
        title: 'QTY',
        field: 'quantity',
        value: true
      },
      {
        title: 'Notes',
        field: 'notes',
        value: true
      },
      {
        title: 'Software Ver',
        field: 'softwareVersion',
        value: true
      },
      {
        title: 'Firmware Ver',
        field: 'firmwareVersion',
        value: true
      },
      {
        title: 'Hardware Rev',
        field: 'hardwareVersion',
        value: true
      },
      {
        title: 'Fleet Tag',
        field: 'fleetTagName',
        value: true
      },
      {
        title: 'Shipment ID',
        field: 'shipmentId',
        value: false
      }
    ]
  },
  {
    title: 'Part Type',
    value: [
      {
        title: 'Part Type ID',
        field: 'partTypeId',
        value: true
      },
      {
        title: 'Part Type Name',
        field: 'partTypeName',
        value: true
      },
      {
        title: 'Serialised',
        field: 'serialised',
        value: true
      },
      {
        title: 'Part Type Category',
        field: 'partTypeCategoryCode',
        value: false
      },
      {
        title: 'Part Type Active',
        field: 'partTypeIsActive',
        value: false
      }
    ]
  },
  {
    title: 'Service',
    value: [
      {
        title: 'Service ID',
        field: 'serviceId',
        value: true
      },
      {
        title: 'Service Type',
        field: 'serviceTypeName',
        value: true
      },
      {
        title: 'Service Status',
        field: 'serviceStatusName',
        value: true
      },
      {
        title: 'Service Customer ID 1',
        field: 'customerId1',
        value: true
      },
      {
        title: 'Service Customer ID 2',
        field: 'customerId2',
        value: true
      },
      {
        title: 'Service Customer ID 3',
        field: 'customerId3',
        value: true
      },
      {
        title: 'Client/Contract/Brand',
        field: 'clientHierarchyName',
        value: true
      },
    ]
  },
  {
    title: 'Structure',
    value: [
      {
        title: 'Source Node',
        field: 'sourceNodeName',
        value: true
      },
      {
        title: 'Source Location',
        field: 'sourceLocation',
        value: true
      },
      {
        title: 'Destination Node',
        field: 'destinationNodeName',
        value: true
      },
      {
        title: 'Destination Location',
        field: 'destinationLocation',
        value: true
      }
    ]
  }
];