import React from 'react';
import { Switch as MaterialSwitch } from '@mui/material';
import { SwitchProps } from './Switch.props';
import { ColorProps, SizeProps } from '../../../@types/common.type';

export const Switch: React.FC<SwitchProps> = ({
  defaultChecked = false,
  checked = false,
  color = ColorProps.Default,
  size= SizeProps.Small,
  onChange,
  onClick,
  disabled
}) => {
  return (
    <MaterialSwitch 
      defaultChecked={defaultChecked} 
      checked={checked} 
      color={color}
      size={size}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled} 
    />);
};