import { PropertyType } from '../configs/enums';
import { PreDefinedStructureType } from '../pages/StructureTypes/AddEditStructureType/AddEditStructureType.props';

export const SAMPLE_STRUCTURE_TYPE = {
  name: '',
  code: '',
  isActive: false,
  isCodeExist: false
};

export const SAMPLE_STRUCTURE_TYPE_HELPER = {
  nameHelperText: '',
  codeHelperText: ''
};

export const GLOBAL_RETURNS_LOCATION = 'Global Returns Location';

export const StructureTypeFields: Array<PreDefinedStructureType> = [
  { key: PropertyType.Service, value: 'Can have Services', min: 0, max: null },
  { key: PropertyType.Parts, value: 'Can have Parts', min: 0, max: null },
  { key: PropertyType.Contact, value: 'Can have Contacts', min: 1, max: null },
  { key: PropertyType.Address, value: 'Can have Addresses', min: 1, max: null },
  { key: PropertyType.TradingHours, value: 'Can have Trading Hours', min: 1, max: null },
  { key: PropertyType.ServicesAssignment, value: 'Can accept Services Assignment', min: 1, max: null },
  { key: PropertyType.SiteSchema, value: 'Use Site Schema', min: 0, max: null },
  { key: PropertyType.ReferenceNumbers, value: 'Use Reference Numbers', min: 0, max: null },
  { key: PropertyType.Allocations, value: 'Can use Allocations', min: 0, max: null },
  { key: PropertyType.GlobalReturns, value: 'Can use Global Returns', min: 0, max: null }
];

export const CODE_NOT_EXIST_HELPER_TEXT = 'Code already exist in other service type';