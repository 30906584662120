import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Modal } from '../../../../../components/atoms/Modal';
import { TextField } from '../../../../../components/atoms/TextField';
import { TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { Tabs, TextColorProps } from '../../../../../components/molecules/Tabs';
import { COLORS } from '../../../../../configs/colors';
import { ShareAccessWithFragment } from '../ShareAccessWithFragment';
import { CloseWrapper, ContentWrapperBox, HeadingWrapper } from './ReportEditFragment.styles';
import { ReportEditFragmentProps } from './ReportEditFragment.props';
import { useGetAttachmentTypes, useGetShareAccessDataByReportId } from '../../../../../queries/report-query';
import { Snackbar } from '../../../../../components/atoms/Snackbar';
import { ScheduleEmailFragment } from '../ScheduleEmail/ScheduleEmailFragment';
import { ActionPermissions, CustomPermission } from '../../../../../@types';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';
import { getTabsByPermission } from '../../../../../utils/report';
import { ReportShareTabs } from '../../../../../configs/enums';

export const ReportEditFragment: React.FC<ReportEditFragmentProps> = ({
  open,
  closeModel,
  rowData,
  refetchReports
}) => {
  const [openModel, setOpenModel] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const attachmentTypesQueryData = useGetAttachmentTypes(); 
  const getShareAccessDataQuery = useGetShareAccessDataByReportId(rowData?.id.toString() || '');

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (open) {
      getShareAccessDataQuery.refetch(); 
      setOpenModel(true);
    }
  }, [open]);

  useEffect(() => {
    if (refetch) {
      setOpenToast(true);
      refetchReports(true);
      setRefetch(false); 
    }
  }, [refetch]);

  return (
    <>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ContentWrapperBox>
          <HeadingWrapper variant={TypographyVariantProps.H6}>
            Report Edit
          </HeadingWrapper>
          <CloseWrapper onClick={() => {
            setOpenModel(false);
            closeModel();
          }}>
            <CloseRounded htmlColor={COLORS.Red} />
          </CloseWrapper>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                label="Report Name"
                fullWidth 
                value={rowData?.name}
                disabled
              />
            </Grid>
          </Grid>
          {!isUserHasPermission(ActionPermissions.Report_My_Report_Sharing, permissions) && !isUserHasPermission(ActionPermissions.Report_My_Report_Schedule, permissions) ? 
            <></>
            :
            <Grid container spacing={2} marginTop={1}>
              <Tabs
                name="report-share-method-tabs"
                tabTitles={getTabsByPermission(isUserHasPermission(ActionPermissions.Report_My_Report_Sharing, permissions), isUserHasPermission(ActionPermissions.Report_My_Report_Schedule, permissions))}
                textColor={TextColorProps.Inherit}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: COLORS.Black
                  }
                }}
                isCustomColor={false}
                onTabChange={(tabType: string) => tabType === ReportShareTabs.DirectShare && getShareAccessDataQuery.refetch()}
              >
                {isUserHasPermission(ActionPermissions.Report_My_Report_Sharing, permissions) &&
                  <ShareAccessWithFragment 
                    rowData={rowData} 
                    savedData={getShareAccessDataQuery.data || { data: [] }} 
                    handleRefetch={(refetch: boolean) => setRefetch(refetch)}
                  />
                }
                {isUserHasPermission(ActionPermissions.Report_My_Report_Schedule, permissions) &&
                  <ScheduleEmailFragment
                    attachmentTypes={attachmentTypesQueryData.data?.data || []}
                    reportId={rowData?.id}
                    handleRefetch={(refetch: boolean) => setRefetch(refetch)}
                  />
                }
              </Tabs>
            </Grid>
          }
        </ContentWrapperBox>
      </Modal>
      <Snackbar
        open={openToast} 
        autoHideDuration={4000} 
        message={'Successfully Saved'}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
};