import { ServiceStatusResponse, ServiceTargetResponse } from '../@types';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { ALL_KEYWORD, ALL_KEYWORD_CAPITAL } from '../constants/common';

export const getServiceTargets = (serviceTargets: ServiceTargetResponse[], code: string) => {
  const serviceTargetOptionsItems: DropdownItem[] = [];
  
  serviceTargets.map((item: ServiceTargetResponse) => {
    if(item.code !== code) {
      const target = {
        value: item.code,
        label: item.name
      };
      serviceTargetOptionsItems.push(target);
    } 
  });

  return serviceTargetOptionsItems;
};

export const getAvailabeServiceStatuses = (serviceStatuses: ServiceStatusResponse[]) => {
  const availableStatusItems: DropdownItem[] = [];
  const wildCard = {
    value: ALL_KEYWORD, 
    label: ALL_KEYWORD_CAPITAL
  };

  availableStatusItems.push(wildCard);

  serviceStatuses.map((item: ServiceStatusResponse) => {
    if(item.code !== 'new') {
      const status = {
        value: item.code,
        label: item.name,
        disabled: !item.isActive
      };
      availableStatusItems.push(status);
    }
  });

  return availableStatusItems;
};

export const isError = (isShortCodeExist: boolean, validateName: string, validateShortName: string, targetOptions: any) => {
  return isShortCodeExist || validateName || validateShortName || targetOptions.length < 1;
};

export const hasAllKeyword = (data: string) => {
  const hasAllString = data.split(',').some((serviceType) => serviceType === ALL_KEYWORD);
  return data === ALL_KEYWORD || hasAllString;
};