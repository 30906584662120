import { FormFieldOption } from '../@types';
import { CheckedData, DropDownItems, IsRequiredFields } from '../@types/tlc.type';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { Behaviour, NotePromptBehaviour } from '../configs/enums';

export const PART_ACTION_ROW_DATA = {
  SELECTED_ROW_ACTION: 'selectedRowAction',
  ROW_NOTE: 'rowNote'
};

export const BEHAVIOUR_OPTIONS: Array<FormFieldOption> = [
  {
    id: 1,
    title: 'Batch',
    value: Behaviour.Batch,
    isActive: true
  },
  {
    id: 2,
    title: 'Immediate',
    value: Behaviour.Immediate,
    isActive: true
  }
];

export const NOTE_PROMPT_BEHAVIOUR_OPTIONS: Array<FormFieldOption> = [
  {
    id: 1,
    title: 'Individual',
    value: NotePromptBehaviour.Individual,
    isActive: true
  },
  {
    id: 2,
    title: 'Batch',
    value: NotePromptBehaviour.Batch,
    isActive: true
  }
];

export const CONDITIONS: DropdownItem[] = [
  {
    value: 'good',
    label: 'Good'
  },
  {
    value: 'faulty',
    label: 'Faulty'
  },
  {
    value: 'repair',
    label: 'Repair'
  },
  {
    value: 'doa',
    label: 'DOA'
  },
  {
    value: 'quarantine',
    label: 'Quarantine'
  },
  {
    value: 'missing',
    label: 'Missing'
  },
  {
    value: 'ber',
    label: 'BER'
  },
  {
    value: 'destroyed',
    label: 'Destroyed'
  },
  {
    value: 'reserved',
    label: 'Reserved'
  },
  {
    value: 'installed',
    label: 'Installed'
  },
  {
    value: 'staging',
    label: 'Staging'
  },
  {
    value: 'in-transit',
    label: 'In Transit'
  }
];

export const TLC_TYPES: DropdownItem[] = [
  {
    value: 'serialized-part-update',
    label:'Serialized Part Update'
  }
];

export const PART_ACTION_TYPES: DropdownItem[] = [
  {
    value: 'clean',
    label:'Clean'
  },
  {
    value: 'test',
    label:'Test'
  }
];

export const SW_HW_FW_DROP_DOWN_ITEMS: DropDownItems = {
  sw: [],
  hw: [],
  fw: []
};

export const CHECKED_DATA: CheckedData = {
  isCheckCondition: false,
  isCheckLocationSearch: false,
  isCheckNote: false,
  isCheckPartAction: false,
  isCheckPartTypeSearch: true,
  isCheckSWVersions: false,
  isCheckFWVersions: false,
  isCheckHWRevision: false,
  isCheckTags: false,
  isCheckLocationPrompt: false,
  isCheckNotePrompt: false
};

export const TLC_SAMPLE_TEMPLATE_LIST = [
  {
    id: 1,
    name: 'TLC template 1',
    description: 'Test TLC template 1',
    behavior: 'Batch',
    templateActionTypeCode: 'serialized-part-update',
    data: {
      restrictions: {
        partType: [
          6
        ],
        location: {
          includeChild: true,
          id: [
            1
          ]
        },
        conditions: [
          'good',
          'faulty'
        ],
        hardwareRevisions: [
          25,
          28
        ],
        softwareVersions: [
          23,
          26
        ],
        firmwareVersions: [
          24,
          27
        ],
        fleetTags: [
          1
        ],
        partTypeNames: [
          'Test PartType '
        ],
        locationNames: [
          'Test User'
        ],
        fleetTagNames: [
          'dtcc1ft1'
        ],
        swVersionNames: [
          'v1.0',
          'v1.1'
        ],
        fwVersionNames: [
          'v1.0',
          'v1.1'
        ],
        hwRevisionNames: [
          'v1.0',
          'v1.1'
        ]
      },
      templateAction: {
        type: 'serialized-part-update',
        partType: 5,
        condition: 'good',
        location: 3,
        locationPrompt: true,
        hardwareRevision: 7,
        softwareVersion: 5,
        firmwareVersion: 6,
        partActions: [
          {
            selectedRowAction: 'remove',
            rowNote: 'two partitions, one for OS and one for data'
          },
          {
            selectedRowAction: 'install',
            rowNote: 'please install windows 10'
          }
        ],
        note: 'this is a note',
        notePrompt: {
          prompt: true,
          behavior: 'batch'
        },
        templateActionTypeName: 'Serialized Part Update',
        partTypeName: 'Fiserv Ingenico MOVE 5000 3G',
        locationName: 'IBM',
        hardwareRevisionName: 'hw-FPA920',
        softwareVersionName: 'sw-FPA920',
        firmwareVersionName: 'fw-FPA920',
        tagNames: [
          'Key-Injected'
        ]
      }
    },
    isActive: true
  },
  {
    id: 2,
    name: 'TLC template 2',
    description: 'Test TLC template 2',
    behavior: 'Immediate',
    templateActionTypeCode: 'serialized-part-update',
    data: {
      restrictions: {
        partType: [
          6
        ],
        location: {
          includeChild: true,
          id: [
            1
          ]
        },
        conditions: [
          'good',
          'faulty'
        ],
        hardwareRevisions: [
          25,
          28
        ],
        softwareVersions: [
          23,
          26
        ],
        firmwareVersions: [
          24,
          27
        ],
        fleetTags: [
          1
        ],
        partTypeNames: [
          'Test PartType '
        ],
        locationNames: [
          'Test User'
        ],
        fleetTagNames: [
          'dtcc1ft1'
        ],
        swVersionNames: [
          'v1.0',
          'v1.1'
        ],
        fwVersionNames: [
          'v1.0',
          'v1.1'
        ],
        hwRevisionNames: [
          'v1.0',
          'v1.1'
        ]
      },
      templateAction: {
        type: 'serialized-part-update',
        partType: 5,
        condition: 'good',
        location: 3,
        locationPrompt: true,
        hardwareRevision: 7,
        softwareVersion: 5,
        firmwareVersion: 6,
        partActions: [
          {
            selectedRowAction: 'remove',
            rowNote: 'two partitions, one for OS and one for data'
          },
          {
            selectedRowAction: 'install',
            rowNote: 'please install windows 10'
          }
        ],
        note: 'this is a note',
        notePrompt: {
          prompt: true,
          behavior: 'individual'
        },
        templateActionTypeName: 'Serialized Part Update',
        partTypeName: 'Fiserv Ingenico MOVE 5000 3G',
        locationName: 'IBM',
        hardwareRevisionName: 'hw-FPA920',
        softwareVersionName: 'sw-FPA920',
        firmwareVersionName: 'fw-FPA920',
        tagNames: [
          'Key-Injected'
        ]
      }
    },
    isActive: true
  },
  {
    id: 3,
    name: 'TLC template 3',
    description: 'Test TLC template 3',
    behavior: 'Batch',
    templateActionTypeCode: 'serialized-part-update',
    data: {
      restrictions: {
        partType: [
          6
        ],
        location: {
          includeChild: true,
          id: [
            1
          ]
        },
        conditions: [
          'good',
          'faulty'
        ],
        hardwareRevisions: [
          25,
          28
        ],
        softwareVersions: [
          23,
          26
        ],
        firmwareVersions: [
          24,
          27
        ],
        fleetTags: [
          1
        ],
        partTypeNames: [
          'Test PartType '
        ],
        locationNames: [
          'Test User'
        ],
        fleetTagNames: [
          'dtcc1ft1'
        ],
        swVersionNames: [
          'v1.0',
          'v1.1'
        ],
        fwVersionNames: [
          'v1.0',
          'v1.1'
        ],
        hwRevisionNames: [
          'v1.0',
          'v1.1'
        ]
      },
      templateAction: {
        type: 'serialized-part-update',
        partType: 5,
        condition: 'good',
        location: 3,
        locationPrompt: true,
        hardwareRevision: 7,
        softwareVersion: 5,
        firmwareVersion: 6,
        partActions: [
          {
            selectedRowAction: 'remove',
            rowNote: 'two partitions, one for OS and one for data'
          },
          {
            selectedRowAction: 'install',
            rowNote: 'please install windows 10'
          }
        ],
        note: 'this is a note',
        notePrompt: {
          prompt: true,
          behavior: 'individual'
        },
        templateActionTypeName: 'Serialized Part Update',
        partTypeName: 'Fiserv Ingenico MOVE 5000 3G',
        locationName: 'IBM',
        hardwareRevisionName: 'hw-FPA920',
        softwareVersionName: 'sw-FPA920',
        firmwareVersionName: 'fw-FPA920',
        tagNames: [
          'Key-Injected'
        ]
      }
    },
    isActive: true
  }
];

export const SAMPLE_TLC_MANIFEST_DETAIL = [
  {
    id: 1,
    partTypeId: 2,
    partTypeName: 'Aldi Key board',
    partTypeNo: '478',
    serial: 'X12345',
    condition: 'Good',
    location: {
      id: 20,
      name: 'Melbourne',
      type: 'node'
    },
    newLocation: {
      id: 0,
      name: '',
      type: ''
    },
    softwareVersion: 'V1.2',
    hardwareRevision: 'V1.4',
    firmwareVersion: 'V1.9',
    fleetTag: 'Fleet Tag 2',
    note:'test note'
  },
  {
    id: 2,
    partTypeId: 3,
    partTypeName: 'Dell Key board',
    partTypeNo: '500',
    serial: 'ABE123',
    condition: 'Faulty',
    location: {
      id: 35,
      name: 'SAZDF3214',
      type: 'part'
    },
    newLocation: {
      id: 0,
      name: '',
      type: ''
    },
    softwareVersion: 'V2.2',
    hardwareRevision: 'V2.4',
    firmwareVersion: 'V2.9',
    fleetTag: 'Fleet Tag 5',
    note:'',
    error: 'Duplicated record'
  },
  {
    id: 3,
    partTypeId: 4,
    partTypeName: 'Asus Key board',
    partTypeNo: '533',
    serial: 'ABE1BDS32123',
    condition: 'Faulty',
    location: {
      id: 12,
      name: 'Sydney',
      type: 'node'
    },
    newLocation: {
      id: 0,
      name: '',
      type: ''
    },
    softwareVersion: 'V2.2',
    hardwareRevision: 'V2.4',
    firmwareVersion: 'V2.9',
    fleetTag: 'Fleet Tag 5',
    note:'test note 2'
  }
];

export const RESTRICTION_DATA = {
  fleetTags: [],
  locationSearchData: [],
  isIncludeChilds: false,
  selectedSearchPartTypes: [],
  conditions: CONDITIONS,
  selectedConditions: [],
  dropDownItems: {
    sw: [],
    hw: [],
    fw: []
  },
  versions: {
    sw: [],
    hw: [],
    fw: []
  },
  selectedFleetTags: []
};

export const TEMPLATE_ACTION_DATA = { 
  actionCondition: '',
  actionVersions: {
    sw: '',
    hw: '',
    fw: ''
  },
  partActionArray: [],
  actionNote: '',
  actionBehaviour: NotePromptBehaviour.Batch,
  options: {
    conditions: CONDITIONS,
    dropDownItems: SW_HW_FW_DROP_DOWN_ITEMS,
    behaviour: NOTE_PROMPT_BEHAVIOUR_OPTIONS
  },
  selectedTags: [],
  allTags: [],
  newTags: []
};

export const IS_REQUIRED_FIELDS: IsRequiredFields = {
  condition: false,
  partType: false,
  location: false
};
