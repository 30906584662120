import { ManifestData } from '../../../../@types/shipment.type';

export interface DispatchManifestFragmentProps {
  onChangeManifestData: (manifest: ManifestData[]) => void;
  applicableNodeId: string;
  sourceLocationId: number;
  sourceLocationName: string;
  shipmentId?: number;
  isNew: boolean;
  manifestData?: any[];
}

export interface SelectedData {
  id: number;
  name: string;
  number?: string;
  category?: string;
}

export const SELECTED_DATA = {
  id: 0,
  name: ''
};

export const CONDITION_ORDER = [ 'Good', 'Staging', 'Quarantine', 'Faulty', 'Repair', 'DOA', 'BER', 'Destroyed', 'Missing', 'In Transit', 'Installed'];
