import { Box, Grid, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)`
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const StyledGridContainer = styled(Grid)`
  padding: 10px;
`;

export const StyledTableCell = styled(TableCell)`
  border: 0;
  padding-right: 0;
`;

export const StyledTotalTableCell = styled(TableCell)`
  border: 0;
  padding-right: 35px;
`;