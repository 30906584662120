import { PartExistance, NSPartExistance, BulkUpdatePartDataset, PartHistoryMovementListFilterOptions, UpdatePartLocationObject } from '../@types/part.type';
import { DuplicatePartsPanelProps } from '../components/molecules/DuplicatePartsPanel/DuplicatePartsPanel.props';
import { HttpMethod, httpClient } from './http-client';

export const getPartById = (id: number) => {
  return httpClient(`/parts/${id}`, HttpMethod.Get, null);
};

export const getPartByIdForService = (id: number) => {
  return httpClient(`/parts/${id}/forService`, HttpMethod.Get, null);
};

export const getPartConditions = () => {
  return httpClient('/partConditions', HttpMethod.Get, null);
};

export const getPartConditionsForService = () => {
  return httpClient('/partConditionsForService', HttpMethod.Get, null);
};

export const updatePart = (id: number, part: any) => {
  return httpClient(`/parts/${id}`, HttpMethod.Put, part);
};

export const updatePartForService = (id: number, part: any) => {
  return httpClient(`/parts/${id}/forService`, HttpMethod.Put, part);
};

export const getAllWarrantySummary = (partId: number) => {
  return httpClient(`/warranties/getByPart/${partId}`, HttpMethod.Get, null);
};

export const getPartLocationDataById = (id: number) => {
  return httpClient(`/parts/locationData/${id}`, HttpMethod.Get, null);
};

export const getPartLocationDataByPartId = (id: number, locationData: UpdatePartLocationObject) => {
  const { locationId, locationTypeCode, locationHierarchy } = locationData;

  return httpClient(`/parts/locationData/${id}/updatePart?locationId=${locationId}&locationTypeCode=${locationTypeCode}&locationHierarchy${locationHierarchy}`, HttpMethod.Get, null);
};

export const getPartLocationDataByIdForService = (id: number) => {
  return httpClient(`/parts/locationData/${id}/forService`, HttpMethod.Get, null);
};

export const getPartLocationDataByServiceId = (partTypeId: number, serviceId: number) => {
  return httpClient(`/parts/${partTypeId}/byServiceId/${serviceId}`, HttpMethod.Get, null);
};

export const updatePartLocation = (id: number, part: any) => {
  return httpClient(`/parts/${id}/location`, HttpMethod.Put, part);
};

export const getPartHistoryByPartId = (id: number) => {
  return httpClient(`/parts/${id}/history`, HttpMethod.Get, null);
};

export const getPartExistanceBySerials = (data: PartExistance) => {
  return httpClient('/bulkSearchParts', HttpMethod.Post, data);
};

export const getNSPartExistanceBySerials = (data: NSPartExistance) => {
  return httpClient('/checkNSPart', HttpMethod.Post, data);
};

export const updateBulkPart = (data: BulkUpdatePartDataset) => {
  return httpClient('/bulkUpdateParts', HttpMethod.Put, data);
};

export const getPartMovementHistory = (queryOptions: PartHistoryMovementListFilterOptions) => {
  const { filterModel, page, pageSize, sortModel, startDate, endDate, partId } = queryOptions;
  let url = `/partMovementHistory?limit=${pageSize}&page=${page + 1}&startDate=${startDate}&endDate=${endDate}`;

  if (partId) {
    url += `&partId=${partId}`;
  }

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getPartRepairHistory = (partId: number) => {
  return httpClient(`/partHistory/${partId}/repairHistory`, HttpMethod.Get, null);
};

export const getLatestPartMomentHistory = (partId: number) => {
  const url = `/partHistory/latestPartMovement/${partId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getPartHistoryByServiceIdAndHistoryTypeCodes = (serviceId: number, historyTypes: string[]) => {
  return httpClient(`/partHistory/services/${serviceId}?historyTypes=${JSON.stringify(historyTypes)}`, HttpMethod.Get, null);
};

export const getDuplicatePartsBySerial1AndPartType = (mainPartId: number, serial1: string, partTypeId: number) => {
  return httpClient(`/getDuplicateParts/?mainPartId=${mainPartId}&serial1=${serial1}&partTypeId=${partTypeId}`, HttpMethod.Get, null);
};

export const mergeDuplicateParts = (data: DuplicatePartsPanelProps) => {
  return httpClient('/mergeDuplicateParts', HttpMethod.Post, data);
};

export const createExceptionalPart = (part: any) => {
  return httpClient('/parts/exception', HttpMethod.Post, part);
};

export const updateExceptionalPart = (id: number, data: any) => {
  return httpClient(`/parts/${id}/exception`, HttpMethod.Put, data);
};

export const processPartBulkUpdateFile = (fileList: any) => {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return httpClient('/parts/bulkUpdate/fileProcess', HttpMethod.Post, fileList, headers);
};