import { styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { SelectMenu } from '../../atoms/SelectMenu';

export const StyledTypography = styled(Typography)((props: { color: string; fontSize: string }) =>`
  color: ${props.color};
  font-size: ${props.fontSize};
  padding: 3px !important;
`);

export const StyledSelectMenu = styled(SelectMenu)`
  div {
    padding: 1px 2px !important;
  }
  width: 100%;
`;
