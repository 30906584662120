import { Grid, MenuItem, Select, styled } from '@mui/material';
import { TextField } from '../../atoms/TextField';
import { LABEL_STYLES } from '../../../constants/interaction.constant';

export const StyledGrid = styled(Grid)`
  background-color: whitesmoke;
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
  min-width: 400px;
`;

export const LabelStyledSelect = styled(Select)((props: { labelStyle: string }) =>`
  .MuiSelect-select {
    background-color: ${LABEL_STYLES.find((style) => style.value === props.labelStyle)?.bgColor};
  };`
);

export const LabelStyledMenuItem = styled(MenuItem)((props: { bgColor: string }) =>`
  background-color: ${props.bgColor};
  &.Mui-selected {
    background-color: ${props.bgColor};
  };`
);