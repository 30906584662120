import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { ReportedInformation, ReportedInformationEditPermissions } from '../../../../components/organisms/ReportedInformation';
import { ReportedInformationFragmentProps } from './ReportedInformationFragment.props';
import { INIT_REPORTED_INFORMATION_EDIT_PERMISSIONS } from '../../../../constants/service.constant';
import { ReportedInformationData, LinkedServiceData, ActionPermissions } from '../../../../@types';
import { useGetLinkedServices, useUpdateReportedInformation } from '../../../../queries/service-query';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { ContentDispositionType, NodeAttributeType, ServiceAttachmentUploadFeature, ServiceCreationRuleTypes } from '../../../../configs/enums';
import { useGetFilteredNodeAttributesByCustomRange } from '../../../../queries/structure-query';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { FileTypes, FileUploadActionType, Folders, UploadedFile } from '../../../../@types/uploaded-files.type';
import { useGetUploadedFilePresignedUrlById, useGetUploadedFiles } from '../../../../queries/uploadedfiles-query';
import { deleteUploadedFile, uploadFiles, } from '../../../../api/uploadedFiles';
import { useGetServiceLogsByServiceId } from '../../../../queries/service-log-query';
import { Modal } from '../../../../components/atoms/Modal';
import { StyledImage } from '../../../../components/organisms/MultipleImageUpload/MultipleImageUpload.styles';

export const ReportedInformationFragment: React.FC<ReportedInformationFragmentProps> = ({
  data,
  onDispatch,
  expandOnMobile
}) => {
  const [id, setId] = useState(0);
  const [parentId, setParentId] = useState('');
  const [nodeAttributeTypes] = useState<NodeAttributeType[]>([
    NodeAttributeType.Models,
    NodeAttributeType.FaultCategory,
    NodeAttributeType.FaultCode
  ]);
  const [serviceTypeCode, setServiceTypeCode] = useState('');
  const [reportedInformation, setReportedInformation] = useState<ReportedInformationData>(data);
  const [linkedServicesDetails, setLinkedServicesDetails] = useState<LinkedServiceData>();
  const [reportedInformationEditPermissions, setReportedInformationEditPermissions] = useState<ReportedInformationEditPermissions>(INIT_REPORTED_INFORMATION_EDIT_PERMISSIONS);
  const [reportedModel, setReportedModel] = useState<any>();
  const [reportedFault, setReportedFault] = useState<any>();
  const [requiredFields, setRequiredFields] = useState<any>(Array);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedFileActionData, setSelectedFileActionData] = useState({ id: 0, contentDispositionType: '', mimeType: '' });
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [uploadedSpecialServiceAttachments, setUploadedSpecialServiceAttachments] = useState<UploadedFile[]>([]);
  const [fullImage, setFullImage] = useState('');
  const [open, setOpen] = useState(false);

  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();
  const uploadedFileDataByKey = useGetUploadedFilePresignedUrlById(selectedFileActionData.id, selectedFileActionData.contentDispositionType);
  const getServiceLogsByServiceId = useGetServiceLogsByServiceId(data.id);

  useEffect(() => {
    if (data) {
      setReportedInformation(data);
      setParentId(data.parentId);
      setServiceTypeCode(data.serviceTypeCode);
      setId(data.id);
    }
  }, [data]);

  const updateReportedInformation = useUpdateReportedInformation();
  const getNodeAttributesByCustomRange = useGetFilteredNodeAttributesByCustomRange(parentId, nodeAttributeTypes, serviceTypeCode);
  const getLinkedServicesQuery = useGetLinkedServices(id);
  const uploadedFilesQuery = useGetUploadedFiles(id, Folders.ServiceAttachments, true);
  const uploadedSpecialFileAttachmentQuery = useGetUploadedFiles(id, Folders.ServiceSpecialFileAttachments, true);

  useEffect(() => {
    if (getLinkedServicesQuery.data) {
      setLinkedServicesDetails(getLinkedServicesQuery.data);
    }
  }, [getLinkedServicesQuery.data]);

  useEffect(() => {
    if (data.selectedServiceCreationTypeValue) {
      const isReference1Required = data.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Reference1);
      const isReference2Required = data.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Reference2);
      const isReference3Required = data.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Reference3);
      const isSerialNumberRequired = data.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.SerialNumber);
      const isNoteRequired = data.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Notes);

      setRequiredFields({
        ...requiredFields,
        isReference1Required: isReference1Required,
        reference1RequiredMessage: isReference1Required ? 'Reference 1' : '',
        isReference2Required: isReference2Required,
        reference2RequiredMessage: isReference2Required ? 'Reference 2' : '',
        isReference3Required: isReference3Required,
        reference3RequiredMessage: isReference3Required ? 'Reference 2' : '',
        isSerialNumberRequired: isSerialNumberRequired,
        serialNumberRequiredMessage: isSerialNumberRequired ? 'Serial Number' : '',
        noteRequiredMessage: isNoteRequired ? 'Note' : '',
      });
    }
  }, [data]);

  useEffect(() => {
    const permissions = getUserSystemPermissionsQuery.data;
    if (permissions) {
      const isGeneralPermission = isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_General, permissions);
      const isReportedInformationPermission = isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_Reported_Information, permissions);

      setReportedInformationEditPermissions({
        ...reportedInformationEditPermissions,
        isReferenceNo1: isGeneralPermission,
        isReferenceNo2: isGeneralPermission,
        isReferenceNo3: isGeneralPermission,
        isReportedSerial: isReportedInformationPermission,
        isReportedFault: isReportedInformationPermission,
        isReportedModel: isReportedInformationPermission,
      });
    }
  }, [getUserSystemPermissionsQuery.data, data]);

  useEffect(() => {
    if (selectedFileActionData.id) {
      uploadedFileDataByKey.refetch();
    }
  }, [selectedFileActionData.id, selectedFileActionData.contentDispositionType]);

  useEffect(() => {
    if (uploadedFileDataByKey.data && selectedFileActionData.mimeType.split('/').includes('image'), open) {
      if (uploadedFileDataByKey.data?.url) {
        setFullImage(uploadedFileDataByKey.data?.url);
        setOpen(true);
      }
    }

    if (uploadedFileDataByKey.data && !selectedFileActionData.mimeType.split('/').includes('image')) {
      const link = document.createElement('a');
      link.href = uploadedFileDataByKey.data?.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSelectedFileActionData({ id: 0, contentDispositionType: '', mimeType: '' });
    }

  }, [uploadedFileDataByKey.data, selectedFileActionData.mimeType, open]);

  useEffect(() => {
    if (parentId && nodeAttributeTypes && serviceTypeCode) {
      getNodeAttributesByCustomRange.refetch();
    }
  }, [parentId, nodeAttributeTypes, serviceTypeCode]);

  useEffect(() => {
    if (getNodeAttributesByCustomRange.data) {
      setReportedModel(getNodeAttributesByCustomRange.data.filter((nodeAttribute: any) => nodeAttribute.propertyTypeCode === NodeAttributeType.Models));
      setReportedFault(getNodeAttributesByCustomRange.data.filter((nodeAttribute: any) => nodeAttribute.propertyTypeCode === NodeAttributeType.FaultCategory || nodeAttribute.propertyTypeCode === NodeAttributeType.FaultCode));
    }
  }, [getNodeAttributesByCustomRange.data]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleReportedInformationyChange = async (val: ReportedInformationData) => {
    if (requiredFields.isReference1Required && !val.referenceNo1) {
      setOpenSnackbar(true);
      setSnackbarMessage(requiredFields.reference1RequiredMessage);
      return;
    }
    if (requiredFields.isReference2Required && !val.referenceNo2) {
      setOpenSnackbar(true);
      setSnackbarMessage(requiredFields.reference2RequiredMessage);
      return;
    }
    if (requiredFields.isReference3Required && !val.referenceNo3) {
      setOpenSnackbar(true);
      setSnackbarMessage(requiredFields.reference3RequiredMessage);
      return;
    }
    if (requiredFields.isSerialNumberRequired && !val.reportedSerialNumber) {
      setOpenSnackbar(true);
      setSnackbarMessage(requiredFields.serialNumberRequiredMessage);
      return;
    }

    await updateReportedInformation.mutateAsync({
      id: data.id,
      referenceNo1: val.referenceNo1,
      referenceNo2: val.referenceNo2,
      referenceNo3: val.referenceNo3,
      reportedSerialNumber: val.reportedSerialNumberPartId,
      reportedFaultAndDescriptionId: val.reportedFaultAndDescriptionId,
      reportedModelId: val.reportedModelId
    });

    setReportedInformation(val);
  };

  useEffect(() => {
    if (uploadedFilesQuery.data) {
      setUploadedFiles(uploadedFilesQuery.data);
    }
  }, [uploadedFilesQuery.data]);

  useEffect(() => {
    if (uploadedSpecialFileAttachmentQuery.data) {
      setUploadedSpecialServiceAttachments(uploadedSpecialFileAttachmentQuery.data);
    }
  }, [uploadedSpecialFileAttachmentQuery.data]);

  const handleUploadedFiles = async (id: number, actionType: FileUploadActionType, mimeType = '') => {
    if (mimeType.split('/').includes('image')) {
      setOpen(true);
    }

    if (actionType === FileUploadActionType.Archive) {
      await deleteUploadedFile(id);

      if (uploadedFiles.some(file => file.id === id)) {
        const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
        if (refetchUploadedFilesQuery.data) {
          setUploadedFiles(refetchUploadedFilesQuery.data);
        }
      }
      
      if(uploadedSpecialServiceAttachments.some(file => file.id === id)) {
        const refreshUploadedSpecialFileAttachmentQuery = await uploadedSpecialFileAttachmentQuery.refetch();
        if(refreshUploadedSpecialFileAttachmentQuery.data) {
          setUploadedSpecialServiceAttachments(refreshUploadedSpecialFileAttachmentQuery.data);
        }
      }
      return;
    }

    setSelectedFileActionData(
      {
        id: id,
        contentDispositionType: actionType === FileUploadActionType.Download || mimeType.startsWith(FileTypes.MSExcel) || mimeType.startsWith(FileTypes.OpenXml) ? ContentDispositionType.Attachment : ContentDispositionType.Inline,
        mimeType
      });
  };

  const setFileDetails = async (fileList: FileList) => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const files = [...fileList];
      files.forEach((item) => {
        formData.append('fileList', item);
      });
      await uploadFiles(data.id, formData, Folders.ServiceAttachments, ServiceAttachmentUploadFeature.ReportedInfo);
      await getServiceLogsByServiceId.refetch();
      const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
      if (refetchUploadedFilesQuery.data) {
        setUploadedFiles(refetchUploadedFilesQuery.data);
      }
    }
  };

  const handleImageClose = () => {
    setOpen(false);
    setFullImage('');
  };

  return (
    <>
      <ReportedInformation
        value={reportedInformation}
        permissions={reportedInformationEditPermissions}
        options={{
          reportedFaultOptions: reportedFault,
          reportedModelOptions: reportedModel
        }}
        linkedServiceValue={linkedServicesDetails}
        onChange={handleReportedInformationyChange}
        onClick={handleUploadedFiles}
        attachmentRows={[...uploadedFiles, ...uploadedSpecialServiceAttachments].sort((a,b) => b.createdAt.localeCompare(a.createdAt)) }
        setFileDetails={setFileDetails}
        onDispatch={onDispatch}
        expandOnMobile={expandOnMobile}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose}>{snackbarMessage + ' cannot be empty'}</Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onMouseDown={handleImageClose}>
          {fullImage && <StyledImage src={fullImage} />}
        </Grid>
      </Modal>
    </>
  );
};