import { styled } from '@mui/material/styles';
import { TableContainer } from '@mui/material';
import { Box } from '../../atoms/Box';

export const RoleBox = styled(Box)`
  width: 100%;
  height: 100%;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: auto;
  width: 100%;
  height: 45%;
`;

export const ServiceImage = styled('img')`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`;