import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { LocationMergeLiveSearchProps } from './LocationMergeLiveSearch.props';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { useSearchNodeInStructureMerge } from '../../../queries/live-search-query';
import { ColorProps, FontSizeProps, StructureMergeLocationNodeData } from '../../../@types';
import { StructureMergeLocationSearchItem } from '../StructureMergeLocationSearchItem';
import { STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA } from '../../../constants/structure';

export const LocationMergeLiveSearch: React.FC<LocationMergeLiveSearchProps> = ({
  mergeNodeId,
  nodeTypeCode,
  isCancel,
  multiline = true,
  onChange,
  setCancel
}) => {
  const [isEdit, setIsEdit] = useState(true);
  const [location, setLocation] = useState<StructureMergeLocationNodeData>(STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA);

  const searchNodeInStructureMerge = useSearchNodeInStructureMerge();

  useEffect(() => {
    isCancel && setIsEdit(true);
  }, [isCancel]);

  return (
    <Stack spacing={1} mb={5} mt={5}>
      {isEdit || !location?.name?
        <Grid width={300} height={40}>
          <LiveSearchBox
            title="Location Search"
            multiline={multiline}
            timeOffset={400}
            value={location?.name}
            onClearValue={() => {
              setLocation(STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA);
            }}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem {...props}>
                  <StructureMergeLocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: StructureMergeLocationNodeData) => {
              setLocation(obj);
              onChange(obj);
              setIsEdit(false);
              setCancel(false);
            }}
            onApiInvoke={async (name: string) => {
              return await searchNodeInStructureMerge.mutateAsync({
                name,
                nodeTypeCode,
                mergeNodeId
              });
            }}
          />
        </Grid>
        :
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" mt={2}>
          <Grid width={50}>
            <WestIcon fontSize={FontSizeProps.Large} color={ColorProps.Error} />
          </Grid>
          <Grid width={300}>
            <StructureMergeLocationSearchItem data={location} />
          </Grid>
        </Stack>
      }
    </Stack>
  );
};