import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { PopupDialog } from '../../../components/molecules/PopupDialog';
import { ReplenishmentConfig, ReplenishmentConfigFields, ReplenishmentConfigHelperText, ReplenishmentConfigType } from '../../../@types/replenishmentConfig.type';
import { REPLENISHMENT_CONFIG_HELPER_TEXT, SAMPLE_REPLENISHMENT_CONFIG, TYPE_DROPDOWN_ITEMS } from '../../../constants/replenishmentConfig';
import { AddEditReplenishmentConfigFragmentProps } from './AddEditReplenishmentConfigFragment.props';
import { Typography } from '../../../components/atoms/Typography';
import { Switch } from '../../../components/atoms/Switch';
import { ColorProps, LocationNodeData, NodeType } from '../../../@types';
import { LocationSearchBox, PartTypeSKUSearchBox, StyledNumberField, StyledSelectMenu, StyledTextField } from './AddEditReplenishmentConfigFragment.styles';
import { BrandSelector } from '../../../components/molecules/BrandSelector';
import { useGetNodeChildrenByNodeType, useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment } from '../../../queries/structure-query';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { StructureTypeCategory } from '../../../configs/enums';
import { SelectionButton } from '../../../components/molecules/SelectionButton';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useReplenishmentPartTypeSearch, useReplenishmentSearchNodesSearchNodes, useReplenishmentSkuSearch } from '../../../queries/live-search-query';
import { hasErrorInReplenishmentConfig, minMaxValidation, nameValidation } from '../../../utils/replenishment';
import { useCreateReplenishmentConfig, useGetReplenishmentConfigById, useUpdateReplenishmentConfig } from '../../../queries/replenishmentConfig-query';
import { ANY_ITEM, UNDEFINED_TREE, UNDEFINED_TREE_FIRST_ITEM } from '../../../constants/common';

export const AddEditReplenishmentConfigFragment: React.FC<AddEditReplenishmentConfigFragmentProps> = ({
  isOpen,
  isEdit,
  replenishmentConfigId,
  onClose,
}) => {
  const [replenishmentConfig, setReplenishmentConfig] = useState<ReplenishmentConfig>(SAMPLE_REPLENISHMENT_CONFIG);
  const [helperText, setHelperText] = useState<ReplenishmentConfigHelperText>(REPLENISHMENT_CONFIG_HELPER_TEXT);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState(isEdit ? NodeType.Contract : NodeType.Client);
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [validate, setValidate] = useState(false);

  const hasError = hasErrorInReplenishmentConfig(
    replenishmentConfig.name,
    replenishmentConfig.clientContract,
    replenishmentConfig.location,
    replenishmentConfig.type,
    replenishmentConfig.partTypeOrSkuName,
    replenishmentConfig.min,
    replenishmentConfig.max
  );

  const getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment = useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const replenishmentSearchNodesSearchNodes = useReplenishmentSearchNodesSearchNodes();
  const replenishmentSkuSearch = useReplenishmentSkuSearch();
  const replenishmentPartTypeSearch = useReplenishmentPartTypeSearch();
  const createReplenishmentConfig = useCreateReplenishmentConfig();
  const getReplenishmentConfigById = useGetReplenishmentConfigById(replenishmentConfigId || 0);
  const updateReplenishmentConfig = useUpdateReplenishmentConfig();

  useEffect(() => {
    isEdit && getReplenishmentConfigById.refetch();
  }, [isEdit]);

  useEffect(() => {
    getReplenishmentConfigById.data && setReplenishmentConfig(getReplenishmentConfigById.data);
    getReplenishmentConfigById.data?.clientId && setSelectedParentId(getReplenishmentConfigById.data.clientId.toString());
  }, [getReplenishmentConfigById.data]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data) {
      const clientsList = getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data.map(item => ({ 
        value: item.id.toString(), 
        label: item.name,
        disabled: !item.isActive
      }));

      setClients(clientsList);
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({ 
          value: item.id.toString(), 
          label: item.name,
          disabled: !item.isActive
        }));
        const contractMenuListWithAny = ANY_ITEM.concat(contractMenuList);
        setContracts(contractMenuListWithAny);
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);

    replenishmentConfig && setReplenishmentConfig({
      ...replenishmentConfig,
      nodeId: parseInt(parentId),
      typeId: 0
    });
  };

  const handleSave = async () => {
    setHelperText({ 
      ...helperText, 
      configNameHelperText: nameValidation(replenishmentConfig.name, ReplenishmentConfigFields.ConfigNameField),
      clientContractHelperText: nameValidation(replenishmentConfig.clientContract, ReplenishmentConfigFields.ClientContractField),
      locationHelperText: nameValidation(replenishmentConfig.location, ReplenishmentConfigFields.LocationSearchField),
      partTypeSkuSearchHelperText: nameValidation(replenishmentConfig.partTypeOrSkuName, ReplenishmentConfigFields.PartTypeSkuField)
    });
    
    if (hasError) {
      setValidate(true);
      return;
    }

    if (isEdit) {
      await updateReplenishmentConfig.mutateAsync(replenishmentConfig).catch(async (error) => {
        if (error) return;
      });
    } else {
      await createReplenishmentConfig.mutateAsync(replenishmentConfig).catch(async (error) => {
        if (error) return;
      });
    }

    onClose();
  };

  const onClear = () => {
    setReplenishmentConfig(SAMPLE_REPLENISHMENT_CONFIG);
    getNodeChildrenByNodeType.refetch();
  };

  return (
    <PopupDialog 
      maxWidth="1200px"
      width="80%"
      title={`${isEdit ? 'Edit' : 'Add'} Config`} 
      isOpen={isOpen} 
      isClear={true}
      isEdit={isEdit}
      onClose={onClose} 
      onSave={handleSave}
      onClear={onClear}
    >
      <Stack direction="row" spacing={2}>
        <StyledTextField
          required={true}
          error={!!helperText.configNameHelperText}
          helperText={helperText.configNameHelperText}
          label="Config Name"
          value={replenishmentConfig?.name || ''}
          onChange={(val) => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              name: val 
            });
            setHelperText({ ...helperText, configNameHelperText: nameValidation(val, ReplenishmentConfigFields.ConfigNameField) });
          }}
        />
        <Stack width="100%">
          <SelectionButton
            label="Client Selection*"
            error={!!helperText.clientContractHelperText}
            value={replenishmentConfig?.clientContract === UNDEFINED_TREE || replenishmentConfig?.clientContract === UNDEFINED_TREE_FIRST_ITEM ? '' : replenishmentConfig?.clientContract || ''}
            onClick={() => {
              setOpenParentSelector(true);
            }}
          />
        </Stack>
        <LocationSearchBox>
          <LiveSearchBox
            title="Location Live Search*"
            isRequired={true}
            helperText={helperText.locationHelperText}
            timeOffset={400}
            value={replenishmentConfig.location || ''}
            onClearValue={() => {
              replenishmentConfig && setReplenishmentConfig({
                ...replenishmentConfig,
                location: '',
                locationId: NaN
              });
            }}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} isAmLidSearch={true} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: LocationNodeData) => {
              replenishmentConfig && setReplenishmentConfig({
                ...replenishmentConfig,
                location: obj.code,
                locationId: obj.id
              });
              setHelperText({ ...helperText, locationHelperText: nameValidation(obj.code, ReplenishmentConfigFields.LocationSearchField) });
            }}
            onApiInvoke={async (name: string) => {
              return await replenishmentSearchNodesSearchNodes.mutateAsync({
                name: name,
              });
            }}
          />
        </LocationSearchBox>
      </Stack>
      <Stack direction="row" spacing={2} mt={2}>
        <StyledSelectMenu
          required={true}
          id="part-type-or-sku-category"
          labelId="part-type-or-sku-category"
          label="Part Type / SKU *"
          selectedValue={replenishmentConfig?.type}
          onChange={async (val) => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              type: val,
              partTypeOrSkuName: '',
            });
          }}
          items={TYPE_DROPDOWN_ITEMS}
          validate={validate}
          optionalLabelEnabled={true}
        />
        <PartTypeSKUSearchBox>
          <LiveSearchBox
            title={replenishmentConfig?.type === ReplenishmentConfigType.Sku ? 'SKU Live Search*' : 'Part Type Live Search*'}
            isRequired={true}
            helperText={helperText.partTypeSkuSearchHelperText}
            key={replenishmentConfig?.type}
            disabled={replenishmentConfig?.type === '-1'}
            timeOffset={400}
            value={replenishmentConfig.partTypeOrSkuName || ''}
            onClearValue={() => {
              replenishmentConfig && setReplenishmentConfig({
                ...replenishmentConfig,
                partTypeOrSkuName: '',
                typeId: NaN,
              });
            }}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: LocationNodeData) => {
              replenishmentConfig && setReplenishmentConfig({
                ...replenishmentConfig,
                partTypeOrSkuName: obj.name,
                typeId: obj.id,
                clientId: obj.clientId || 0,
                contractId: obj.contractId || 0,
                nodeId: obj.contractId ? obj.contractId : obj.clientId || 0,
                clientContract: obj.clientContractName || ''
              });
              setQueryNodeType(NodeType.Contract);
              setSelectedParentId(obj.clientId ? obj.clientId.toString() : '0');
              setHelperText({ 
                ...helperText, 
                partTypeSkuSearchHelperText: nameValidation(obj.name, ReplenishmentConfigFields.PartTypeSkuField),
                clientContractHelperText: obj.clientContractName ? '' : nameValidation(replenishmentConfig.clientContract, ReplenishmentConfigFields.ClientContractField)
              });
            }}
            onApiInvoke={async (name: string) => {
              if (replenishmentConfig?.type === ReplenishmentConfigType.Sku) {
                return await replenishmentSkuSearch.mutateAsync({
                  name: name,
                  nodeId: replenishmentConfig.nodeId
                });
              } else {
                return await replenishmentPartTypeSearch.mutateAsync({
                  name: name,
                  nodeId: replenishmentConfig.nodeId
                });
              }
            }}
          />
        </PartTypeSKUSearchBox>
      </Stack>
      <Stack direction="row" spacing={2} mt={2}>
        <StyledNumberField
          required={true}
          error={!!helperText.minHelperText}
          helperText={helperText.minHelperText}
          label="Min"
          value={replenishmentConfig?.min || 0}
          inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={(val) => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              min: parseInt(val)
            });
            setHelperText({ ...helperText, minHelperText: minMaxValidation(parseInt(val), replenishmentConfig.max, ReplenishmentConfigFields.Min, ReplenishmentConfigFields.Max) });
          }}
        />
        <StyledNumberField
          required={true}
          label="Max"
          value={replenishmentConfig?.max || 0}
          inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={(val) => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              max: parseInt(val)
            });
            setHelperText({ ...helperText, minHelperText: minMaxValidation(replenishmentConfig.min, parseInt(val), ReplenishmentConfigFields.Min, ReplenishmentConfigFields.Max) });
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2} mt={2}>
        <Typography>Include Child Nodes</Typography>
        <Switch
          color={ColorProps.Success}
          checked={replenishmentConfig?.includeChildNode}
          onChange={() => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              includeChildNode: !replenishmentConfig.includeChildNode 
            });
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2} mt={2}>
        <Typography>Active</Typography>
        <Switch
          color={ColorProps.Success}
          checked={replenishmentConfig?.isActive}
          onChange={() => {
            replenishmentConfig && setReplenishmentConfig({
              ...replenishmentConfig,
              isActive: !replenishmentConfig.isActive 
            });
          }}
        />
      </Stack>
      <BrandSelector
        open={openParentSelector}
        handleClose={() => {
          setOpenParentSelector(false);
        }}
        onChangeNodeType={onChangeNodeType}
        handleParentSelection={(selectedBrandId: string, value: string) => {
          setOpenParentSelector(false);
          replenishmentConfig && setReplenishmentConfig({
            ...replenishmentConfig,
            clientContract: value,
            partTypeOrSkuName: ''
          });
          setHelperText({ ...helperText, clientContractHelperText: nameValidation(value, ReplenishmentConfigFields.ClientContractField) });
        }}
        handleClear={() => {
          replenishmentConfig && setReplenishmentConfig({
            ...replenishmentConfig,
            clientId: NaN,
            contractId: NaN,
            clientContract: ''
          });
        }}
        clients={clients}
        contracts={contracts}
        brands={[]}
        clientId={replenishmentConfig.clientId}
        contractId={replenishmentConfig?.contractId}
        allFieldsRequired={false}
        isBrandEnabled={false}
      />
    </PopupDialog>
  );
};