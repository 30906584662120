export const GetServiceKey = 'get-one-service';
export const AppServicesKey = 'app-services';
export const AppLinkedServicesKey = 'app-linked-services';
export const AppServicesAttributeKey = 'app-services-attribute';
export const AppServiceTypesKey = 'app-service-types';
export const AppActionTypesKey = 'app-action-types';
export const AppProcessTypesKey = 'app-process-types';
export const AppServiceActionProcessTypesKey = 'app-service-action-process-types';
export const ServiceLogAllKey = 'service-log-all';
export const ServiceLogOneKey = 'service-log-one';
export const ServiceBulkUpdateKey = 'service-bulk-update';
export const ServiceTemplateKey = 'service-template';
export const OtherServicesKey = 'other-services';
export const ServiceCheckInKey = 'service-checkin';
export const ServiceAttendanceKey = 'service-attendance';
export const ServiceLinkKey = 'service-link';
export const TechniciansKey = 'technician';
export const CheckInKey = 'checkin';
export const CurrentUserAllocatedServicesKey = 'current-user-allocated-services';
export const BulkCheckInKey = 'bulk-checkin';
export const ServiceAccordianKey = 'services-accordian';
export const GetEstimatedTimeOfArrivalKey = 'estimated-time-of-arrival';
export const ServicesNodesAttributesKey = 'service-nodes-attributes';
export const ServiceBillingTypesKey = 'service-billing-types';
export const ServiceBillingInfoKey = 'service-billing-info';
export const ServiceActivityMonitorKey = 'service-activity-monitor';