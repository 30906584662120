import React from 'react';
import { Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { FormBuilderCondition } from '../../molecules/FormBuilderCondition';
import { FormBuilderField } from '../../organisms/FormBuilderField';
import { FormBuilderContainerProps } from './FormBuilderContainer.props';
import { ContainerGrid, CloseButtonWrapper, StyledTextField } from './FormBuilderContainer.styles';
import { isEmptyString } from '../../../utils/common';

export const FormBuilderContainer: React.FC<FormBuilderContainerProps> = ({
  subFieldTypes,
  isSelected,
  showErrors,
  value,
  onChange,
  onSelectContainer,
  onRemove
}) => {
  return (
    <ContainerGrid 
      container 
      spacing={2} 
      borderColor={isSelected ? 'red' : 'black'} 
      onClick={(event) => {
        event.stopPropagation();
        onSelectContainer(value.id);
      }}
    >
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          {(value.conditions && value.conditions.length > 0) &&
            <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>
              -- Conditions --
            </Typography>
          }
          <CloseButtonWrapper>
            <Button
              variant={ButtonVariantProps.Icon}
              onClick={() => onRemove(value.id)}
            >
              <CloseRounded fontSize="small" color="error" />
            </Button>
          </CloseButtonWrapper>
        </Stack>
      </Grid>
      {value.conditions.map((condition) => (
        <Grid key={condition.id} item xs={12}>
          <FormBuilderCondition 
            value={condition}
            onChange={(val) => {
              const modifiedConditions = value.conditions.map((obj) => {
                return (obj.id === val.id) ? val : obj;
              });

              onChange({ ...value, conditions: modifiedConditions });
            }}
            onRemove={(id) => {
              const modifiedConditions = value.conditions.filter((obj) => obj.id !== id);
              onChange({ ...value, conditions: modifiedConditions });
            }}
          />
        </Grid>
      ))}
      {value.isDecisionPrompt && (
        <>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>
            -- Decision Prompt --
            </Typography>
          </Grid>
          <Grid item xs={12} marginRight="15px">
            <StyledTextField
              fullWidth
              label="Question text"
              required={true}
              error={showErrors && isEmptyString(value.questionData?.questionText)}
              value={value.questionData?.questionText}
              onChange={(val) => onChange({
                ...value,
                questionData: {
                  id: value.id,
                  questionText: val,
                  isYes: false
                }
              })} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>
          -- Fields --
        </Typography>
      </Grid>
      {value.fields.map((field) => (
        <Grid key={field.id} item xs={12}>
          <FormBuilderField
            subFieldTypes={subFieldTypes}
            showErrors={showErrors}
            value={field}
            onChange={(val) => {
              const modifiedFields = value.fields.map((obj) => {
                return (obj.id === val.id) ? val : obj;
              });

              onChange({ ...value, fields: modifiedFields });
            }}
            onRemove={(id) => {
              const modifiedFields = value.fields.filter((obj) => obj.id !== id);
              onChange({ ...value, fields: modifiedFields });
            }}
          />
        </Grid>
      ))}
    </ContainerGrid>
  );
};
