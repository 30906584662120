import React from 'react';
import { Stack } from '@mui/material';
import { BulkUpdateInputItemsProps } from './BulkUpdateInputItems.props';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { BulkUpdateInputItemType } from '../../../@types/bulkUpdateInputItem.type';
import { SelectMenu } from '../../atoms/SelectMenu';
import { StyledDivWrapper, StyledTextField } from './BulkUpdateInputItems.styles';

export const BulkUpdateInputItems: React.FC<BulkUpdateInputItemsProps> = ({
  isChecked,
  disabled,
  label,
  minimumValue = 0,
  itemInputValue,
  inputItemType,
  renderNotes = true,
  rows,
  error,
  id,
  required,
  dropdownItems,
  minWidth,
  onChangeCheckbox,
  onChangeItemInputValue
}) => {
  return (
    <Stack direction="row" spacing={0} alignItems="center">
      <Checkbox
        color={CheckboxColorProps.Primary}
        checked={isChecked}
        disabled={disabled}
        onChange={(event) => onChangeCheckbox(event.target.checked)}
      />
      {BulkUpdateInputItemType.NumberInput === inputItemType && <TextField
        fullWidth
        disabled={!isChecked}
        type={TextFieldTypeProps.Number}
        label={label}
        value={itemInputValue}
        error={error}
        inputProps={{ min: minimumValue }}
        onChange={(value) => onChangeItemInputValue(value)}
      />}
      {BulkUpdateInputItemType.TextInput === inputItemType && <StyledTextField
        fullWidth
        disabled={!isChecked}
        label={label}
        value={itemInputValue}
        error={error}
        onChange={(value) => onChangeItemInputValue(value)}
      />}
      {BulkUpdateInputItemType.TextArea === inputItemType && renderNotes && <TextField
        fullWidth
        multiline
        rows={rows}
        disabled={!isChecked}
        label={label}
        value={itemInputValue}
        error={error}
        onChange={(value) => onChangeItemInputValue(value)}
      />}
      {BulkUpdateInputItemType.SingleSelectDropdown === inputItemType && <StyledDivWrapper><SelectMenu
        id={id || ''}
        minWidth={minWidth}
        required={required}
        disabled={!isChecked}
        label={label}
        items={dropdownItems || []}
        selectedValue={itemInputValue}
        onChange={(value) => onChangeItemInputValue(value)}
        optionalLabelEnabled={true}
      /></StyledDivWrapper>}
    </Stack>
  );
};
