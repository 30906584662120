import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { Switch } from '../../atoms/Switch';
import { ManualAddress } from '../../molecules/ManualAddress/ManualAddress';
import { MapSearch } from '../../molecules/MapSearch';
import { ColorProps } from '../../../@types/common.type';
import { AddressDataType, StructureAddressProps } from './StructureAddress.props';
import { SelectMenu } from '../../atoms/SelectMenu';
import { ManualAddressData, OptionType } from '../../molecules/ManualAddress';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { useGetCountries, useGetStatesByCountryId } from '../../../queries/address-query';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';

export const StructureAddress: React.FC<StructureAddressProps> = ({
  switchLabel,
  addressData,
  onChange,
  addressTypeData,
  disableTypeSelection = false,
  enableContactNumber,
  isService
}) => {
  const [isActive, setIsActive] = useState(addressData? addressData.isManual : false);
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [states, setStates] = useState<OptionType[]>([]);
  const [countryId, setCountryId] = useState<number>(0);

  const getStatesByCountryIdQuery = useGetStatesByCountryId(countryId);
  const getCountriesQuery = useGetCountries();

  useEffect(() => {
    getCountriesQuery.data && setCountries(getCountriesQuery.data);
  }, [getCountriesQuery.data]);
  
  useEffect(() => {
    getStatesByCountryIdQuery.data && setStates(getStatesByCountryIdQuery.data);
  }, [getStatesByCountryIdQuery.data]);

  useEffect(() => {
    countries && addressData?.countryName && setCountryId(countries?.find(item => item.name === addressData.countryName)?.id ?? 0);
  }, [addressData?.countryName, countries]);
  
  useEffect(() => {
    states && addressData?.stateName && onChange({
      ...addressData,
      stateId: states?.find(item => item.name === addressData.stateName)?.id ?? 0,
    });
  }, [addressData?.stateName, states]);

  useEffect(() => {    
    countryId !== 0 && getStatesByCountryIdQuery.refetch();
    addressData && onChange({
      ...addressData,
      countryId: countryId,
    });
  }, [countryId]);

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
    addressData && onChange({
      ...addressData,
      isManual: event.target.checked,
    });
  };

  return (
    <Stack direction="row" mb={1} alignItems="center" justifyContent="center">
      <Grid container spacing={GRID_SPACING} pt="2px">
        {isActive ? (
          <Grid item xs={11.7}>
            <ManualAddress
              manualAddressData={addressData as ManualAddressData}
              onChange={(data) => onChange(data as AddressDataType)}
              addressTypeData={addressTypeData}
              disableTypeSelection={disableTypeSelection}
              countries={countries}
              states={states}
              enableContactNumber={enableContactNumber}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={isService? 11.7 : 8.7}>
              <MapSearch 
                label="Google Address" 
                addressData={addressData as AddressDataType} 
                selectedValue={addressData?.googleAddress} 
                onChange={(data) => onChange(data as AddressDataType)}
              />
            </Grid>
            {!isService &&
              <Grid item xs={3}>
                <SelectMenu
                  label="Type"
                  id="address-type"
                  items={addressTypeData}
                  disabled={disableTypeSelection}
                  selectedValue={addressData?.addressType}
                  onChange={(text) => {
                    onChange({
                      ...addressData,
                      addressType: text
                    });
                  }}
                />
              </Grid>
            }
          </>
        )}
      </Grid>
      <Grid ml={2}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              onChange={handleIsActiveChange}
              checked={addressData?.isManual}
            />
          }
          label={switchLabel}
        />
      </Grid>
      {isService &&
        <FormControlLabel
          value="start"
          control={
            <Checkbox
              checked={addressData?.isKeep}
              onChange={() => addressData && onChange({ ...addressData, isKeep: !addressData.isKeep })}
              color={CheckboxColorProps.Success}
            />
          }
          label="Keep"
          labelPlacement={FormControlLabelPlacementProps.Start}
        />
      }
    </Stack>
  );
};