export enum TaskType {
  LABOUR_TIME = 'labour-time',
  TRAVEL_TIME = 'travel-time',
  TRAVEL_DISTANCE = 'travel-distance',
  STAGING_TIME = 'staging-time',
  RESOURCE_UTILISED = 'resource-utilised',
  LOADING_TIME = 'loading-time',
  QUOTE_VALUE = 'quote-value',
  SPECIAL_FILE_UPLOAD = 'special-file-upload',
  WORK_ORDER_REQUEST = 'work-order-request'
}

export enum TaskTypeCategory {
  Service = 'services',
  ServiceSpecial = 'services-special'
}

export interface TaskData {
  valueCollection?: number;
  issuedTo?: string;
  contactEmail?: string;
  isApproved?: boolean;
  notes?: string;
  quantity?: number;
  duration?: string;
  adjustValue?: number;
  fileId?: number;
  fileName?: string;
  label?: string;
}

export interface TaskCard { 
  id: number;
  taskTypeCode: string;
  taskTypeName: string;
  taskTypeCategory: TaskTypeCategory;
  additionalData?: string;
  serviceId: number;
  taskData?: TaskData;
  notes: string;
  isNew?: boolean;
}

export interface TaskTypes {
  code: string;
  name: string;
  category: TaskTypeCategory;
  isActive: boolean;
  additionalData?: string;
}