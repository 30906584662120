import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const SKU_CONFIG_CONSTANTS = {
  CREATE_MESSAGE: 'SKU Config successfully created.',
  UPDATE_MESSAGE: 'SKU Config successfully updated.',
  NAME_EXIST_ERROR_MESSAGE: 'Name is already exist. Name should be unique.',
  NAME_ERROR_MESSAGE: 'Name should not be empty',
  SERIALISATION_ERROR_MESSAGE: 'Serialisation Type should not be empty',
  CLIENT_CONTRACT_ERROR_MESSAGE: 'Client Contract should not be empty',
  PART_TYPE_ERROR_MESSAGE: 'Part Type should not be empty',
  SOFTWARE_VERSION_ERROR_MESSAGE: 'Software version should not be empty',
  FIRMWARE_VERSION_ERROR_MESSAGE: 'Firmware version should not be empty',
  HARDWARE_REVISION_VERSION_ERROR_MESSAGE: 'Hardware revision version should not be empty',
  FLEET_TAG_ERROR_MESSAGE: 'Fleet tag should not be empty',
  PART_TYPE_CONFIG_ERROR_MESSAGE: 'SKU must have at least one part type config for saving',
};

export const SAMPLE_SKU_CONFIG = {
  isActive: false,
  name: '',
  serializationType: '',
  nodeId: 0
};

export const SAMPLE_CLIENT_CONTRACT_DATA = {
  clientId: '',
  contractId: '',
  clientContractHierarchy: ''
};

export const SAMPLE_SKU_CONFIG_DATA = {
  softwareVersionOptions: [],
  firmwareVersionOptions: [],
  hardwareRevisionOptions: [],
  fleetTagOptions: []
};

export const SKU_NON_EDITABEL_DATA = {
  serialisation: '',
  clientContract: ''
};

export const SERIALISATION_TYPE_DROPDOWN_ITEMS = [
  { value: 'serialised', label: 'Serialised' },
  { value: 'non-serialised', label: 'Non Serialised' }
];

export const columnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'SKU Config',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Name',
        field: 'name',
        value: true
      },
      {
        title: 'Client/Contract',
        field: 'clientContract',
        value: true
      },
      {
        title: 'Serialisation Type',
        field: 'serializationType',
        value: true
      },
      {
        title: 'Is Active',
        field: 'isActive',
        value: true
      },
      {
        title: 'Created At',
        field: 'createdAt',
        value: false
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        value: false
      }
    ]
  },
];