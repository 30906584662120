export const StructureKey = 'structure';
export const StructureChildrenKey = 'structure-children';
export const NodeAttributesByCustomRangeKey = 'node-attributes-by-custom-range';
export const FilteredNodeAttributesByCustomRangeKey = 'filtered-node-attributes-by-custom-range';
export const NodeByName = 'node-by-name';
export const StructureSubTreeKey = 'structure-sub-tree';
export const ParentAttributesByPropertyTypeKey = 'parent-attributes-by-property-type';
export const PrioritySelectionObjectTypeKey = 'priority-selection-object-type';
export const StructureSiteSchemaKey = 'structure-site-schema';
export const PartsForStructuresKey = 'parts-for-structures';
export const SiteSchemaAllKey = 'site-schema-all';
export const SiteSchemaOneKey = 'site-schema-one';
export const LocationNodeDataKey = 'location-node-data';
export const StatesKey = 'states';
export const SiblingParentDetailsKey = 'sibling-parent-details';
export const ShipmentsForStructuresKey = 'shipments-for-structures';
export const NodeAttributesByPropertyTypeAndNodeIdsKey = 'node-attributes-by-property-type-and-node-ids';
export const NodeAttributesByNodeIdKey = 'node-attributes-by-node-id';
export const NodeByTypeKey ='node-by-type';
export const NodesAllocationByNode = 'nodes-allocation-by-node';
export const NodesAllocationByService = 'nodes-allocation-by-service';
export const ShipmentDestinationNodesKey = 'shipment-destination-nodes';
export const NodeAttributeByIdKey = 'node-attributes-by-id';
export const NodeAllocationParentByIdKey = 'node-allocation-parent-by-id';