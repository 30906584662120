import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { NotificationTemplateList } from './NotificationTemplateList';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { AddEditNotificationTemplateFragment } from './AddEditNotificationTemplateFragment';
import { Modal } from '../../../components/atoms/Modal';
import { CloseIcon } from '../../../components/atoms/CloseIcon';
import { useGetSendGridTemplates } from '../../../queries/notification-template-query';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';

export const NotificationTemplates: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [sendGridTemplates, setSendGridTemplates] = useState<DropdownItem[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getSendGridTemplates = useGetSendGridTemplates();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getSendGridTemplates.data) {
      const sendGridTemplateList = getSendGridTemplates.data.map(sendGridData => ({
        value: sendGridData.id.toString(), 
        label: sendGridData.name,
      }));
      
      setSendGridTemplates(sendGridTemplateList);
    }
  }, [getSendGridTemplates.data]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const handleClose = (event?: any) => {
    if (event?.target?.className.includes('backdrop')) {
      event?.target?.className.includes('backdrop') && setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleAdd = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };
  
  TabHandler(`Notifications Templates | ${PLATFORM_NAME}`);
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Notifications Templates
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Notifications_Templates_Create, permissions) && 
            <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={handleAdd}>
              Add Notifications Template
            </Button>
          }
        </InlineGroup>
        {
          isUserHasPermission(ActionPermissions.Configure_Notifications_Templates_View, permissions) && 
            <NotificationTemplateList editPermission={isUserHasPermission(ActionPermissions.Configure_Notifications_Templates_Edit, permissions)} sendGridTemplates={sendGridTemplates} />
        }
      </Wrapper>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <CloseIcon handleClose={handleClose} />
          <AddEditNotificationTemplateFragment 
            isOpen={open}
            isEdit={false}
            onClose={handleClose}
            sendGridTemplates={sendGridTemplates}
          />
        </>
      </Modal>
    </PageContainer>
  );
};