export const NOTIFICATION_EVENTS = [
  {
    value:'any',
    label:'Any'
  },
  {
    value:'service-create',
    label:'Service Created'
  },
  {
    value:'service-update',
    label:'Service Updated'
  },
  {
    value:'service-checkin',
    label:'Service Checked In'
  },
  {
    value:'service-status-change',
    label:'Service Status Change'
  },
  {
    value:'service-scheduled-date-change',
    label:'Service Scheduled Date Change'
  },
  {
    value:'service-allocation',
    label:'Service Allocation'
  },
  {
    value:'shipment-create',
    label:'Shipment Created'
  },
  {
    value:'shipment-receive',
    label:'Shipment Received'
  }
];

export const NOTIFICATION_MESSAGE_TYPES = [
  {
    value:'sms',
    label:'SMS'
  },
  {
    value:'email',
    label:'Email'
  }
];

export const NOTIFICATION_CONTACT_RECORD_TYPES = [
  {
    value:'services_via_notification_type',
    label:'Services via notification type'
  },
  {
    value:'services_via_customer_main_type',
    label:'Services via customer main type'
  },
  {
    value:'notification_type_preferred_with_fallback',
    label:'Notification type preferred with fallback'
  },
  {
    value:'client_contract_notification_type_only',
    label:'Client contract notification type only'
  },
  {
    value:'client_contract_notification_type_preferred_with_fallback',
    label:'Client contract notification type preferred with fallback'
  }
];

export const SAMPLE_CONFIG_OBJECT = {
  id: 0,
  name: '',
  clientContractHierarchy: '',
  client:'',
  contract: [],
  category: '-1',
  serviceType: [],
  actionType: [],
  processType: [],
  priority: [],
  priorityCodes: [],
  region: [],
  status: [],
  shipmentType: [],
  courier: [],
  shipmentStatus: [],
  event: [],
  messageType: '-1',
  contactRecordType: '-1',
  considerTradingHours: false,
  addServiceLog: true,
  emailAddresses: '',
  phoneNumbers: '',
  template: -1
};

export const SAMPLE_PRIORITY_OBJECT = {
  client: [],
  contract: [],
  brand: [],
  serviceTypes: []
};