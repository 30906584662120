import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Grid, IconButton, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { WarrantyData } from '../../../@types/partType.type';
import { Accordian } from '../../atoms/Accordian';
import { Warranty } from '../../molecules/Warranty';
import { WarrantyPanelProps } from './WarrantyPanel.props';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { StyledGridContainer, StyledTableCell } from './WarrantyPanel.styles';

export const WarrantyPanel: React.FC<WarrantyPanelProps> = ({
  warrantyData,
  onChange,
  onSave
}) => {
  const [warrantyFormData, setWarrantyFormData] = useState<WarrantyData[]>([]);
  const [warrantyListData, setWarrantyListData] = useState<WarrantyData[]>([]);

  useEffect(() => {
    warrantyData.length > 0 && setWarrantyListData(warrantyData);
  }, [warrantyData]);

  const addNewForm = (event: SyntheticEvent) => {
    event.stopPropagation();
    setWarrantyFormData([...warrantyFormData, { 
      name: '',
      term: {
        duration: '',
        type: '-1'
      },
      autoTrigger: true,
      triggerTypeCode: 'creation',
      isActive: true 
    }]);
  };

  const handleSave = (warranty: WarrantyData) => {
    onSave(warranty);
  };

  const handleFormSave = async (warranty: WarrantyData, index: number) => {
    const response: any = await onSave(warranty);

    if (response !== 'error') {
      const newData = warrantyFormData.filter((warrantyFormItem, i) => i !== index);
      setWarrantyFormData(newData);
    }
  };

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Part Warranties</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={(e) => addNewForm(e)}>
            Add
          </Button>              
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <StyledGridContainer container>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {warrantyListData?.map((data, index) => 
                <TableRow key={index}>
                  <StyledTableCell>
                    <Warranty
                      value={data}
                      onChange={(val: WarrantyData) => {
                        const newData = warrantyListData.map((obj, i) => index === i ? val : obj);
                        setWarrantyListData(newData);
                        onChange(newData);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => handleSave(data)} >
                      Save
                    </Button>
                  </StyledTableCell>
                </TableRow>
              )}
              {warrantyFormData?.map((data, index) => 
                <TableRow key={index}>
                  <StyledTableCell>
                    <Warranty
                      value={data}
                      onChange={(val: WarrantyData) => {
                        const newData = warrantyFormData.map((obj, i) => index === i ? val : obj);
                        setWarrantyFormData(newData);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => handleFormSave(data, index)} >
                      Save
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {!data?.id && (
                      <IconButton color="error" onClick={() => {
                        const newData = warrantyFormData.filter((warrantyFormItem, i) => i !== index);
                        setWarrantyFormData(newData);
                      }}>
                        <CloseRounded />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGridContainer>
    </Accordian>
  );
};