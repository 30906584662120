import { styled } from '@mui/material/styles';
import { TableCell } from '@mui/material';
import TableHead, { TableHeadProps } from '@mui/material/TableHead';

export const StyledTableHead = styled(TableHead)((props: TableHeadProps & { isParent?: boolean }) =>`
  background-color: ${props.isParent ? '#f4f4f4' : 'transparent'};
  position: sticky;
  top: 0;
`);

export const HeadingCell = styled(TableCell)`
  font-weight: 600;
  white-space: nowrap;
`;