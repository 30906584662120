import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, TableContainer, Table, TableBody, TableRow, IconButton, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { SalePricingTableProps } from './SalePricingPanel.props';
import { StyledGridContainer, StyledTableCell } from './SalePricingPanel.styles';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { Switch } from '../../atoms/Switch';
import { DropdownItem, SelectMenu } from '../../atoms/SelectMenu';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { ColorProps, SalePricingObject } from '../../../@types';
import { ALL_KEYWORD, ALL_KEYWORD_CAPITAL, ANY_KEYWORD } from '../../../constants/common';
import { useGetRegionTypes } from '../../../queries/regionTypes-query';

export const SalePricingPanel: React.FC<SalePricingTableProps> = ({
  partTypeContract,
  partTypeName,
  salesPricingData,
  contractOptions,
  onSave,
  onChange
}) => {
  const [salesFormData, setSalesFormData] = useState<SalePricingObject[]>([]);
  const [newSaleFormDataId, setNewSaleFormDataId] = useState(-1);
  const [regionTypes, setRegionTypes] = useState<Array<DropdownItem>>([]);

  const getRegionTypes = useGetRegionTypes();

  useEffect(() => {
    if (getRegionTypes.data) {
      const regionTypesData = getRegionTypes.data.map((regionType) => ({
        label: regionType.name,
        value: regionType.code
      }));

      setRegionTypes([{ label: ALL_KEYWORD_CAPITAL, value: ALL_KEYWORD }, ...regionTypesData]);
    }
  }, [getRegionTypes.data]);
  
  const handleAddNew = (event: SyntheticEvent) => {
    event.stopPropagation();

    setSalesFormData([...salesFormData, {
      id: newSaleFormDataId,
      nodeIds: partTypeContract,
      regionType: ALL_KEYWORD,
      name: partTypeName,
      price: 0,
      isDefault: false,
      isActive: true
    }]);

    setNewSaleFormDataId(newSaleFormDataId - 1);
  };

  const handleOnChange = (id: number, key: string, SaleData: string | string[] | boolean) => {
    if (key === 'isDefault') {
      salesPricingData.map((salesPricingItem) => {
        if (salesPricingItem.id !== id && salesPricingItem.isDefault) {
          salesPricingItem.isDefault = false;
        }
      });
    }
    const newData = salesPricingData.map((salesPricingItem) => salesPricingItem.id === id ? { ...salesPricingItem, [key]: SaleData } : salesPricingItem);
    onChange(newData);
  };

  const handleOnChangeForm = (id: number, key: string, SaleData: string | string[] | boolean) => {
    const newData = salesFormData.map((saleFormItem) => saleFormItem.id === id ? { ...saleFormItem, [key]: SaleData } : saleFormItem);
    setSalesFormData(newData);
  };
  
  return (
    <StyledGridContainer container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Sale Pricing</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={handleAddNew}>
            Add
          </Button>              
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {salesPricingData?.map((salesPricingItem, index) => 
              <TableRow key={index}>
                <StyledTableCell width="20%">
                  <MultiSelectCheckmarksMenu
                    label="Contract" 
                    items={salesPricingItem.nodeIds.split(',')?.includes(ANY_KEYWORD) ? contractOptions.filter((contractOption) => contractOption.value === ANY_KEYWORD) : contractOptions}
                    selectedValue={salesPricingItem.nodeIds.split(',')}
                    onChange={(contact) => {
                      const contractIds = contact?.split(',');

                      if (contractIds.includes(ANY_KEYWORD))
                        handleOnChange(salesPricingItem.id, 'nodeIds', ANY_KEYWORD);
                      else
                        handleOnChange(salesPricingItem.id, 'nodeIds', contact && contact === '' ? '' : contractIds.filter(contractId => contractId).toString());
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell width="20%">
                  <SelectMenu
                    id="sales-pricing-region-type-options"
                    label="Region Type *"
                    items={regionTypes}
                    selectedValue={salesPricingItem.regionType}
                    onChange={(regionType) => handleOnChange(salesPricingItem.id, 'regionType', regionType)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    label="Friendly Name"
                    fullWidth
                    type={TextFieldTypeProps.String}
                    placeholder="Friendly Name"
                    value={salesPricingItem.name}
                    onChange={(name) => handleOnChange(salesPricingItem.id, 'name', name)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    label="Price"
                    fullWidth
                    required
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 0 }}
                    placeholder="Price"
                    value={`${salesPricingItem.price}`}
                    onChange={(price) => handleOnChange(salesPricingItem.id, 'price', price)}
                  />
                </StyledTableCell>
                <StyledTableCell width="80px">
                  <Stack alignItems="center">
                    <Typography>Is Default</Typography>
                    <Switch
                      color={ColorProps.Success}
                      checked={salesPricingItem.isDefault}
                      onChange={() => handleOnChange(salesPricingItem.id, 'isDefault', !salesPricingItem.isDefault)}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell width="70px">
                  <Stack alignItems="center">
                    <Typography>Is Active</Typography>
                    <Switch
                      color={ColorProps.Success}
                      checked={salesPricingItem.isActive}
                      onChange={() => handleOnChange(salesPricingItem.id, 'isActive', !salesPricingItem.isActive)}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => onSave(salesPricingItem, false)} >
                      Save
                  </Button>
                </StyledTableCell>
              </TableRow>
            )}
            {salesFormData?.map((salesFormItem, index) => 
              <TableRow key={index}>
                <StyledTableCell width="20%">
                  <MultiSelectCheckmarksMenu
                    label="Contract" 
                    items={salesFormItem.nodeIds.split(',')?.includes(ANY_KEYWORD) ? contractOptions.filter((contractOption) => contractOption.value === ANY_KEYWORD) : contractOptions}
                    selectedValue={salesFormItem.nodeIds.split(',')} 
                    onChange={(nodeIds) => {
                      const contractIds = nodeIds?.split(',');

                      if (contractIds.includes(ANY_KEYWORD))
                        handleOnChangeForm(salesFormItem.id, 'nodeIds', ANY_KEYWORD);
                      else
                        handleOnChangeForm(salesFormItem.id, 'nodeIds', nodeIds);
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell width="20%">
                  <SelectMenu
                    id="sales-pricing-region-type-options"
                    label="Region Type *"
                    items={regionTypes}
                    selectedValue={salesFormItem.regionType}
                    onChange={(regionType) => handleOnChangeForm(salesFormItem.id, 'regionType', regionType)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    type={TextFieldTypeProps.String}
                    label="Friendly Name"
                    value={salesFormItem.name}
                    onChange={(name) => handleOnChangeForm(salesFormItem.id, 'name', name)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    required
                    label="Price"
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 0 }}
                    placeholder="Price"
                    value={`${salesFormItem.price}`}
                    onChange={(price) => handleOnChangeForm(salesFormItem.id, 'price', price)}
                  />
                </StyledTableCell>
                <StyledTableCell width="80px">
                  <Stack alignItems="center">
                    <Typography>Is Default</Typography>
                    <Switch
                      color={ColorProps.Success}
                      checked={salesFormItem.isDefault}
                      onChange={() => handleOnChangeForm(salesFormItem.id, 'isDefault', !salesFormItem.isDefault)}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell width="80px">
                  <Stack alignItems="center">
                    <Typography>Is Active</Typography>
                    <Switch
                      color={ColorProps.Success}
                      checked={salesFormItem.isActive}
                      onChange={() => handleOnChangeForm(salesFormItem.id, 'isActive', !salesFormItem.isActive)}
                    />
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button 
                    variant={ButtonVariantProps.Secondary} 
                    color={ButtonColorProps.Success} 
                    onClick={async () => {
                      const response: any = await onSave(salesFormItem, true);

                      if (response !== 'error') {
                        const newData = salesFormData.filter((salesFormItem, i) => i !== index);
                        setSalesFormData(newData);
                      }
                    }} 
                  >
                      Save
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton color="error" onClick={() => {
                    const newData = salesFormData.filter((formItem, i) => i !== index);
                    setSalesFormData(newData);
                  }}>
                    <CloseRounded /> 
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGridContainer>
  );
};