import React from 'react';
import { Button } from '@mui/material';
import { Menu } from '@material-ui/core';
import { CustomDataGridMenuProps } from './CustomDataGridMenu.props';

export const CustomDataGridMenu: React.FC<CustomDataGridMenuProps> = ({
  menuButtonIcon,
  title,
  children
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const MenuIcon = menuButtonIcon;

  return (
    <>
      <Button
        size="small"
        id={'data-grid-menu-density-button'}
        startIcon={<MenuIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        {title}
      </Button>

      <Menu
        id={'data-grid-menu-density'}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}>
        {children}
      </Menu>
    </>
  );
};

