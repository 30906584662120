import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { getSLAConfigMetaData, getSLAsByServiceId, refreshSLAsByServiceId, resetSLAById, resetSLAsByServiceId, updateSLATimeoutAt } from '../api/sla';
import { SLAContactRowData } from '../components/organisms/SLAContactRow';
import { SLAConfigMetaDataKey, SLAsByServiceKey, SLATimeoutAtKey } from '../configs/sla';
import { SLAConfigMetaDataResponse } from '../@types';

export const useGetSLAsByServiceId = (serviceId: number): UseQueryResult<SLAContactRowData[]> => {
  return useQuery([SLAsByServiceKey, serviceId], () => getSLAsByServiceId(serviceId));
};

export const useUpdateSLATimeoutAt = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateSLATimeoutAt(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLATimeoutAtKey]);
    },
  });
};

export const useResetSLAsByServiceId = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async () => {
    return await resetSLAsByServiceId(serviceId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLAsByServiceKey, serviceId]);
    },
  });
};

export const useResetSLAById = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await resetSLAById(data.slaId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLAsByServiceKey, serviceId]);
    },
  });
};

export const useRefreshSLAsByServiceId = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async () => {
    return await refreshSLAsByServiceId(serviceId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLAsByServiceKey, serviceId]);
    },
  });
};

export const useGetSLAConfigMetaData = (): UseQueryResult<SLAConfigMetaDataResponse> => {
  return useQuery([SLAConfigMetaDataKey], () => getSLAConfigMetaData(), { staleTime: 5 * 60 * 60 * 1000 });
};