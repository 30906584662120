import React from 'react';
import { DuplicatePartsFragmentProps } from '.';
import { DuplicatePartsPanel } from '../../../../components/molecules/DuplicatePartsPanel';

export const DuplicatePartsFragment: React.FC<DuplicatePartsFragmentProps> = ({
  duplicatePartsData,
  mainPartId
}) => {
  return (
    <DuplicatePartsPanel duplicatePartsData={duplicatePartsData} mainPartId={mainPartId} />
  );
};