import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material';
import { EditNodePartLiveSearchProps } from './EditNodePartLiveSearch.props';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { useSearchNodesParts, useSearchParts } from '../../../queries/live-search-query';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { LocationNodeData, NodeType } from '../../../@types';
import { Button, ButtonColorProps, ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Link } from '../../atoms/Link';
import { Node, PartLocationType, SearchCode } from '../../../@types/part.type';
import { SAMPLE_PART_LOCATION } from '../../../constants/part';

export const EditNodePartLiveSearch: React.FC<EditNodePartLiveSearchProps> = ({
  isLocation,
  isClientHierarchy,
  isEditable,
  isSerial = false,
  partLocation = SAMPLE_PART_LOCATION,
  serial = '',
  title = 'Part/Node Search',
  handlePartLocationChange,
  onChange,
  searchLimit
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [locationName, setLocationName] = useState(partLocation.name || '');
  const [locationHierarchy, setLocationHierarchy] = useState('');
  const [locationTypeCode, setLocationTypeCode] = useState('');
  const [textValue, setTextValue] = useState('');
  const [id, setId] = useState(0);

  const searchNodeParts = useSearchNodesParts();
  const searchParts = useSearchParts(searchLimit);

  useEffect(() => {
    serial !== textValue && setTextValue(serial);
  }, [serial]);

  const handleOnSubmit = () => {
    onChange && onChange(textValue, id);
    handlePartLocationChange && handlePartLocationChange({ 
      ...partLocation, 
      locationId: locationId === 0 ? partLocation.locationId : locationId,
      locationHierarchy: locationHierarchy === '' ? partLocation.locationHierarchy : locationHierarchy,
      locationTypeCode: locationTypeCode === '' ? partLocation.locationTypeCode : locationTypeCode
    });
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setTextValue(serial);
    setIsEdit(false);
    setIsShown(false);
  };
  
  return (
    <Grid
      container
      display="flex"
      width="100%"
      alignItems="center"
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
    >
      {isEdit ?
        <Grid item xs={12} md={8}>
          <LiveSearchBox
            title={title}
            timeOffset={400}
            value={locationName}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: LocationNodeData) => {
              setLocationId(obj.id);

              if (obj.name.includes(SearchCode.AM_PID)){
                setLocationHierarchy(obj.locationHierarchy || '');
                setLocationName(obj.serial1 || '');
                setLocationTypeCode('part');
                setTextValue(obj.name);
              } else if (obj.name.includes(SearchCode.AM_LID)) {
                setLocationHierarchy(obj.parentTree || '');
                setLocationName(obj.code || '');
                setLocationTypeCode('node');
              } else {
                setLocationHierarchy(obj.parentTree || '');
                setLocationName(obj.name || '');
                setLocationTypeCode('node');
                setTextValue(obj.name);
                setId(obj.id);
              }
            }}
            onApiInvoke={async (name: string) => {
              if (name.length < 3 || (name.toLowerCase().includes('am-') && name.length < 8)) {
                return [];
              }

              if (isSerial) {
                return await searchParts.mutateAsync({ id: partLocation.id, name });
              }

              return await searchNodeParts.mutateAsync({
                id: partLocation.id,
                name: name.replace('#', '%23'),
                nodeIdTree: partLocation.locationHierarchy,
                includeSiblings: true
              });
            }}
          />
        </Grid>
        :
        <Grid item>
          {isSerial ? (
            <Typography>{serial ? serial : '-'}</Typography>
          ) : (
            <>
              <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">
                {partLocation.nodeList.map((node: Node, index: number) => 
                  <>
                    {node.nodeTypeCode === NodeType.Client || node.nodeTypeCode === NodeType.Contract || node.nodeTypeCode === NodeType.Brand ? (
                      <>
                        {isClientHierarchy ? 
                          <>
                            <Link href={`/configure/clients/${node.nodeTypeCode}/${node.id}`}>{node.name}</Link>&nbsp;/&nbsp;
                          </>
                          : 
                          <>
                            {node.name}&nbsp;/&nbsp;
                          </> 
                        }
                      </>
                    ) : node.nodeTypeCode === PartLocationType.Part ? (
                      <>
                        <Link href={`/parts/${node.id}`}>{node.name}</Link>&nbsp;{index !== partLocation.nodeList.length -1 ? '/' : ''}&nbsp;
                      </>
                    ) : (
                      <>
                        {isLocation ? 
                          <>
                            <Link href={`/structure/${node.id}`}>{node.name}</Link>&nbsp;{index !== partLocation.nodeList.length -1 ? '/' : ''}&nbsp;
                          </>
                          : 
                          <>
                            {node.name}&nbsp;/&nbsp;
                          </> 
                        }
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Typography variant={TypographyVariantProps.Subtitle2}>
                <b>{partLocation.nodeTypeName}</b> &nbsp;&nbsp;{partLocation.name}
              </Typography>
            </>
          )}
        </Grid>
      }
      {isEdit &&
        <Grid item xs={12} md={4}>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </Grid>
      }
      {isShown && !isEdit &&
        <Grid item>
          <Button
            variant={ButtonVariantProps.Icon}
            size={ButtonSizeProps.Small}
            onClick={() => setIsEdit(true)}
          >
            <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
          </Button>
        </Grid>
      }
    </Grid>
  );
};