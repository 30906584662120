import React from 'react';
import { InputLabel, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { SerialNumberMenuProps } from './SerialNumberMenu.props';
import { 
  StyledFormControl,
  StyledMenuItem
} from './SerialNumberMenu.styles';
import { SearchCode } from '../../../@types/part.type';

export const SerialNumberMenu: React.FC<SerialNumberMenuProps> = ({
  options,
  disabled,
  error,
  value,
  onChange,
}) => {
  return (
    <StyledFormControl error={error && !value?.serialData?.serial}>
      <InputLabel id="serial-number-menu-select-label">Serial Number</InputLabel>
      <Select
        labelId="serial-number-menu-select-label"
        id="serial-number-menu-select"
        label="Serial Number"
        value={value?.serialData?.serial}
        disabled={disabled}
        onChange={(event: SelectChangeEvent) => onChange && onChange(event.target.value)}
      >
        <StyledMenuItem 
          value=""
          divider
        >
          <em>Not Selected</em>
        </StyledMenuItem>
        {options?.map((option, index) => (
          <StyledMenuItem
            key={index} 
            value={option.serial}
            disabled={disabled}
            divider
          >
            <Stack>
              <Typography 
                variant={TypographyVariantProps.Body1}
                fontWeight={TypographyFontWeightProps.Bold}
              >
                {option.serial}
              </Typography>
              <Typography 
                variant={TypographyVariantProps.Subtitle1}
              >
                {option.partTypeName}
              </Typography>
              <Typography 
                variant={TypographyVariantProps.Subtitle1}
              >
                {SearchCode.AM_PID}-{option.partId}
              </Typography>
            </Stack>
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};