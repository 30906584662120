import React, { SyntheticEvent, useState } from 'react';
import { Grid, TableContainer, Table, TableBody, TableRow, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { RepairPricingPanelProps } from './RepairPricingPanel.props';
import { StyledGridContainer, StyledTableCell } from './RepairPricingPanel.styles';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { SalesRepairSupplierData } from '../../../@types';
import { RepairPricing } from '../../molecules/RepairPricing';

export const RepairPricingPanel: React.FC<RepairPricingPanelProps> = ({
  repairPricingData,
  onRepairPricingChange,
  onRepairPricingSave,
  recallSearchNodeQueryForRepairPricing,
}) => {
  const [repairformData, setRepairFormData] = useState<SalesRepairSupplierData[]>([]);
  const [newrepairFormDataId, setNewRepairFormDataId] = useState(-1);
  
  const addNewFormForRepairPricing = (event: SyntheticEvent) => {
    event.stopPropagation();

    setRepairFormData([...repairformData, {
      description: '',
      nodeId: 0,
      nodeName: '',
      price: '',
      cost: '',
      quantity: '',
      isDefault: false,
      isActive: true,
      isNew: true
    }]);

    setNewRepairFormDataId(newrepairFormDataId - 1);
  };
  
  return (
    <StyledGridContainer container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Repair Pricing</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={(e) => addNewFormForRepairPricing(e)}>
            Add
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {repairPricingData?.map((repairPricingDataItem, index) =>
              <TableRow key={index}>
                <StyledTableCell>
                  <RepairPricing
                    value={repairPricingDataItem}
                    onChange={(repairData: SalesRepairSupplierData) => {
                      if (repairData.isDefault) {
                        repairPricingData.map((repairPricingItem) => {
                          if (repairPricingItem.id !== repairData.id && repairPricingItem.isDefault) {
                            repairPricingItem.isDefault = false;
                          }
                        });
                      }
                      const newData = repairPricingData.map((repairPricing) => repairPricing.id === repairData.id ? repairData : repairPricing);
                      onRepairPricingChange(newData);
                    }}
                    renderNotes={true}
                    recallSearchNodeQuery={(val: string) => recallSearchNodeQueryForRepairPricing(val)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => onRepairPricingSave(repairPricingDataItem, false)} >
                    Save
                  </Button>
                </StyledTableCell>
              </TableRow>
            )}
            {repairformData?.map((repairformDataItem, index) =>
              <TableRow key={index}>
                <StyledTableCell>
                  <RepairPricing
                    value={repairformDataItem}
                    onChange={(salesRepairSupplierData: SalesRepairSupplierData) => {
                      const newData = repairformData.map((repairformItem, i) => index === i ? salesRepairSupplierData : repairformItem);
                      setRepairFormData(newData);
                    }}
                    renderNotes={true}
                    recallSearchNodeQuery={(name: string) => recallSearchNodeQueryForRepairPricing(name)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={async () => {
                    const response: any = await onRepairPricingSave(repairformDataItem, true);

                    if (response !== 'error') {
                      const newData = repairformData.filter((repairformItem, i) => i !== index);
                      setRepairFormData(newData);
                    }
                  }} >
                    Save
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {repairformDataItem?.isNew && (
                    <IconButton color="error" onClick={() => {
                      const newData = repairformData.filter((repairformItem, i) => i !== index);
                      setRepairFormData(newData);
                    }}>
                      <CloseRounded />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGridContainer>
  );
};