import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';
import { Box } from '../../../components/atoms/Box';

export const RoleTypography = styled(Typography)`
  padding-bottom: 10px;
`;

export const RoleBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const ModalBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  max-width: 400px;
  background-color: white;
  border-radius: 1ch;
  box-shadow: 0 0 10px 5px #363636;
  padding: 1ch;
`;

export const ModalContent = styled(Box)`
  margin-top: 10px;
  padding: 10px 20px;
`;

export const ModalFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px;
  margin-right: 10px;
`;

export const LocationSearchBox = styled(Box)`
  width: 50%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 25%;
`;