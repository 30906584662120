import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { TLCTemplateActionProps } from './TLCTemplateAction.props';
import { ActionBox, StyledSelectMenu } from './TLCTemplateAction.styles';
import { TextField } from '../../atoms/TextField';
import { RadioGroup } from '../../atoms/RadioGroup';
import { ActionPermissions, CustomPermission, PartType, Tag } from '../../../@types';
import { ActionVersions, TlcTemplatesPartAction, VersionCheckData } from '../../../@types/tlc.type';
import { TLCPartAction } from '../../organisms/TLCPartAction/TLCPartAction';
import { AutocompleteTagType, Tags } from '../../organisms/Tags';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetSearchTLCPartTypeLocation, useGetSearchTLCPartTypes } from '../../../queries/part-type-query';
import { PartTypeSearchItem } from '../../molecules/PartTypeSearchItem';
import { Node } from '../../../@types/part.type';
import { Behaviour } from '../../../configs/enums';
import { SwHwFwSingleSelector } from '../../molecules/SwHwFwSingleSelector';

export const TLCTemplateAction: React.FC<TLCTemplateActionProps> = ({
  checkedData,
  templateData,
  newTag,
  onChangeChecked,
  onChangeData
}) => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchPartTypesQuery = useGetSearchTLCPartTypes();
  const searchLocationQuery = useGetSearchTLCPartTypeLocation();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const onAddPartAction = () => {
    const partActionList = [...templateData.partActionArray];
    const actionItem = {
      id: templateData.partActionArray.length,
      selectedRowAction: '',
      rowNote: ''
    };
    partActionList.push(actionItem);
    onChangeData({
      ...templateData,
      partActionArray: partActionList
    });
  };

  const onClearRow = (row: TlcTemplatesPartAction) => {
    const partActionList = [...templateData.partActionArray];
    const updatedArray = partActionList.filter((action) => action.id !== row.id);
    const arrayWithUpdatedIds = updatedArray.map((action, index) => {
      return { ...action, id: index };
    });
    onChangeData({
      ...templateData,
      partActionArray: arrayWithUpdatedIds
    });
  };

  const onChangeRow = (id: number, value: TlcTemplatesPartAction) => {
    const partActionList = [...templateData.partActionArray];
    const updatedRowIndex = templateData.partActionArray.findIndex((row) => row.id === id);
    partActionList[updatedRowIndex].rowNote = value.rowNote;
    partActionList[updatedRowIndex].selectedRowAction = value.selectedRowAction;
    onChangeData({
      ...templateData,
      partActionArray: partActionList
    });
  };

  return (
    <ActionBox>
      <Grid container mt={3} spacing={GRID_SPACING}>
        {isUserHasPermission(ActionPermissions.Part_Bulk_Update_Part_Type_Client_Lock_Bypass, permissions) &&
      <>
        <Checkbox
          disabled
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckPartTypeSearch}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckPartTypeSearch: event.target.checked })}
        />
        <Grid xs={3} mr={3} ml={1}>
          <LiveSearchBox
            title="Part Type Search"
            disabled
            timeOffset={400}
            value={ templateData.restrictionPartTypes && templateData.restrictionPartTypes?.length === 1 ? templateData.restrictionPartTypes[0]?.name : ''}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <PartTypeSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={async (liveSearchResponse: PartType) => onChangeData({ ...templateData, partTypeSearch: liveSearchResponse })}
            onApiInvoke={async (searchQuery: string) => {
              return await searchPartTypesQuery.mutateAsync(searchQuery);
            }}
          />
        </Grid>
      </>
        }
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckCondition}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckCondition: event.target.checked })}
        />
        <Grid xs={3}>
          <StyledSelectMenu
            id="condition-menu"
            disabled={!checkedData.isCheckCondition}
            label="Select Condition"
            minWidth={240}
            required
            selectedValue={templateData.actionCondition}
            onChange={(condition: string) => onChangeData({ ...templateData, actionCondition: condition })}
            items={templateData.options.conditions}
          />
        </Grid>
      </Grid>
      <Grid container mt={3} spacing={GRID_SPACING}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckLocationSearch}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckLocationSearch: event.target.checked, isCheckLocationPrompt: false })}
        />
        <Grid xs={3} mr={3} ml={1}>
          <LiveSearchBox
            title="Location Search"
            disabled={!checkedData.isCheckLocationSearch}
            timeOffset={400}
            value={templateData.locationSearch?.name || ''}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={async (liveSearchResponse: Node) => onChangeData({ ...templateData, locationSearch: liveSearchResponse })}
            onApiInvoke={async (searchQuery: string) => {
              const requestData = {
                isIncludeChild: false,
                searchData: searchQuery
              };
              return await searchLocationQuery.mutateAsync(requestData);
            }}
          />
        </Grid>
        {templateData.mainBehaviour === Behaviour.Batch &&
        <>
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={checkedData.isCheckLocationPrompt}
            onChange={(event) => onChangeChecked({ ...checkedData, isCheckLocationSearch: false, isCheckLocationPrompt: event.target.checked })}
          /> 
          <Grid item xs={4}>
            <Typography>
      Structure Location Prompt
            </Typography>
          </Grid>
        </>
        }
      </Grid>
      {templateData.restrictionPartTypes?.length === 1 &&
        <SwHwFwSingleSelector
          items={templateData.options.dropDownItems}
          swhwfwData={templateData.actionVersions}
          onChange={(version: ActionVersions) => onChangeData({ ...templateData, actionVersions: version })}
          onChangeCheck={(checkedIems: VersionCheckData) => onChangeChecked({ 
            ...checkedData, 
            isCheckHWRevision: checkedIems.isCheckHWRevision,
            isCheckFWVersions: checkedIems.isCheckFWVersions,
            isCheckSWVersions: checkedIems.isCheckSWVersions
          })}
          verionCheckedData={{ isCheckFWVersions: checkedData.isCheckFWVersions, isCheckHWRevision: checkedData.isCheckHWRevision, isCheckSWVersions: checkedData.isCheckSWVersions }}
        />
      }
      <Grid container mt={3} spacing={GRID_SPACING}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckPartAction}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckPartAction: event.target.checked })
          }
        />
        <TLCPartAction
          partActionArray={templateData.partActionArray}
          disabled={!checkedData.isCheckPartAction}
          onAddPartAction={onAddPartAction}
          onChangeRow={onChangeRow}
          onClearRow={onClearRow}
        />
      </Grid>        
      <Grid container mt={3} spacing={GRID_SPACING}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckNote}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckNote: event.target.checked, isCheckNotePrompt: false })}
        />
        <Grid xs={3} ml={1} mr={3}>
          <TextField
            fullWidth
            label="Note"
            disabled={!checkedData.isCheckNote}
            multiline
            rows={4}
            value={templateData.actionNote}
            onChange={(note) => onChangeData({ ...templateData, actionNote: note })}
          />
        </Grid>
        {templateData.mainBehaviour === Behaviour.Batch &&
        <Grid item xs={4}>
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={checkedData.isCheckNotePrompt}
            onChange={(event) => onChangeChecked({ ...checkedData, isCheckNote: false, isCheckNotePrompt: event.target.checked })}
          />
          <RadioGroup
            id={`${1}`}
            disabled={!checkedData.isCheckNotePrompt}
            title="Note Prompt"
            options={templateData.options.behaviour}
            value={templateData.actionBehaviour}
            onChange={(behaviour) => onChangeData({ ...templateData, actionBehaviour: behaviour })}
          />
        </Grid>
        }
      </Grid>
      <Grid container mt={3} spacing={GRID_SPACING}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={checkedData.isCheckTags}
          onChange={(event) => onChangeChecked({ ...checkedData, isCheckTags: event.target.checked })
          }
        />
        <Grid item xs={4}>
          <Tags
            disabled={!checkedData.isCheckTags}
            allTags={templateData.allTags || []}
            selectedTags={templateData.selectedTags || []}
            newTags={newTag}
            setNewTags={(newValue: Tag[]) => onChangeData(templateData, newValue)}
            setSeletedTags={(selectedValue: (string | AutocompleteTagType)[]) => onChangeData({ ...templateData, selectedTags: selectedValue })}
            label="Search Tags"
            placeHolder="Enter Tags"
          />
        </Grid>
      </Grid>   
    </ActionBox>
  );
};