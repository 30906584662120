import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HorizontalDevider = styled(Divider)`
  border-bottom-width: 3px;
  border-style: dashed;
`;

export const StyledContainer = styled('div')`
  width: 100%;
`;