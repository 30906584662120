export interface ReplenishmentConfig {
  id: number;
  name: string;
  nodeId: number;
  clientId: number;
  contractId: number;
  clientContract: string;
  locationId: number;
  location: string;
  locationName: string;
  type: string;
  typeId: number;
  partTypeOrSkuName: string;
  min: number;
  max: number;
  includeChildNode: boolean;
  isActive: boolean;
}

export interface ReplenishmentConfigListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [ReplenishmentConfig];
}

export interface ReplenishmentConfigHelperText {
  configNameHelperText: string;
  clientContractHelperText: string;
  locationHelperText: string;
  partTypeSkuSearchHelperText: string;
  minHelperText: string;
}

export enum ReplenishmentConfigFields {
  ConfigNameField = 'Config Name field',
  ClientContractField = 'Client/Contract field',
  LocationSearchField = 'Location Live Search field',
  PartTypeSkuField = 'Part-Type / SKU Live Search field',
  Min = 'Min',
  Max = 'Max'
}

export enum ReplenishmentConfigType {
  Sku = 'sku',
  PartType = 'part-type'
}

export interface ReplenishmentReport {
  id: number;
  name: string;
  nodeId: number;
  clientId: number;
  contractId: number;
  clientContract: string;
  locationId: number;
  location: string;
  type: string;
  typeId: number;
  partTypeOrSkuName: string;
  min: number;
  max: number;
  includeChildNode: boolean;
  isActive: boolean;
  averageFreightLeadTimeOneMonth: number;
  averageFreightLeadTimeThreeMonth: number;
  averageUsageOneMonth: number;
  averageUsageThreeMonth: number;
  state: string;
  condition: string;
  stockOnHand: number;
  inTransit: number;
  servicesInTrain: number;
  replenishmentRequired: string;
  distributionCentreMap: string;
}

export interface ReplenishmentReportListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [ReplenishmentReport];
}

export interface ReplenishmentReportFilters {
  structureType: string;
  structureName: string;
  structureId: number;
  structureLocationStateId: number;
  structureLocationState: string;
  clientId: number;
  contractId: number;
  nodeId: number;
  clientContract: string;
  type: string;
  typeId: number;
  skuPartType: string;
  condition: string;
  inTransit: boolean;
  servicesInTrain: boolean;
  distributionCentreId: number;
  distributionCentre: string;
  showAllResults: boolean;
}