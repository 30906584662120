import { OrderSettings, QueryOption } from '../@types';
import { ReplenishmentConfig, ReplenishmentReportFilters } from '../@types/replenishmentConfig.type';
import { isNotArrayEmpty } from '../utils/array-utils';
import { httpClient, HttpMethod } from './http-client';

export const getAllReplenishmentConfigs = (queryOptions: QueryOption) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/replenishmentConfig?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && isNotArrayEmpty(sortModel, 0)) {
    url += `&order_by=${sortModel[0]?.field}&order_direction=${sortModel[0]?.sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const createReplenishmentConfig = (replenishmentConfig: ReplenishmentConfig) => {
  return httpClient('/replenishmentConfig', HttpMethod.Post, replenishmentConfig);
};

export const getReplenishmentConfigById = (id: number) => {
  return httpClient(`/replenishmentConfig/${id}`, HttpMethod.Get, null);
};

export const updateReplenishmentConfig = (id: number, replenishmentConfig: ReplenishmentConfig) => {
  return httpClient(`/replenishmentConfig/${id}`, HttpMethod.Put, replenishmentConfig);
};

// when isReport=true (get all replenishment report data)
export const getAllReplenishmentReports = (limit: number, page: number, orderBy?: OrderSettings, customFilters?: ReplenishmentReportFilters) => {
  const replenishmentConfig = {
    limit: limit,
    page: page + 1,
    order_by: orderBy,
    customFilters: customFilters
  };
  return httpClient('/replenishmentReport', HttpMethod.Post, replenishmentConfig);
};