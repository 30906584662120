import { MouseEvent } from 'react';
import { DropdownItem } from '../../atoms/SelectMenu';
import { ContactData } from '../../molecules/Contact';
import { AddressDataType } from '../../organisms/StructureAddress';

export interface ContactDataType {
  id?: number;
  contactType?: string;
  contactName?: string;
  organization?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  notes?: string;
  isActive?: boolean;
}

export interface StructureAddressContactLayoutProps {
  heading: string;
  isAddress: boolean;
  isOneAddress?: boolean;
  renderNotes: boolean;
  contactData?: ContactData;
  contactType: DropdownItem[];
  addressDataset: AddressDataType[];
  contactDataset?: ContactDataType[];
  contactFormData: ContactData[];
  addressFormData: AddressDataType[];
  editContactFormData?: ContactDataType;
  editAddressFormData?: AddressDataType;
  enableContactNumber?: boolean;
  addressForm?: any[];
  contactForm?: any[];
  setAddressForm?: (value: any[]) => void;
  setContactForm?: (value: any[]) => void;
  onClickContactEdit?: (event: MouseEvent<HTMLButtonElement>, index: number) => void;
  onClickContactDelete?: (event: MouseEvent<HTMLButtonElement>, index: number) => void;
  onClickAddressEdit?: (event: MouseEvent<HTMLButtonElement>, index: number) => void;
  onClickAddressDelete?: (event: MouseEvent<HTMLButtonElement>, index: number) => void;
  onStructureAddressChange: (value: AddressDataType[]) => void;
  onContactChange?: (value: ContactData[]) => void;
  handleAddressCreate?: (event: any) => void;
  handleContactCreate?: (index: number) => void;
  addFormEnabledByDefault?: boolean;
}

export const EmptyMainAddress = {
  addressType: 'Main',
  name: '',
  traditionalPlaceName: '',
  line1: '',
  line2: '',
  suburb: '',
  city: '',
  stateName: '',
  postCode: '',
  countryName: '',
  isActive: true
};

export const EmptyMainContact = {
  contactType: 'Main', 
  contactName: '', 
  organization: '', 
  phone1: '', 
  phone2: '', 
  email: '', 
  notes: '',
  isActive: true
};