import React, { forwardRef, useState, useEffect } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import { DrawerContentProps } from './DrawerContent.props';
import { DrawerSubContent, getIconByMenuName } from '../DrawerSubContent';
import { Wrapper, MenuImageWrapper } from '../Drawer.styles';
import { Typography } from '../../../atoms/Typography';

const ListItemButtonStyled = styled((buttonProps: ListItemButtonProps & { url: string }) => {
  const linkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <Link ref={ref} {...props} to={buttonProps.url} />);
  linkRef.displayName = 'LinkRef';
  const listItemProps = { component: linkRef };

  return (
    <MuiListItemButton {...listItemProps} {...buttonProps} />
  );
})(({ theme }) => `
  padding-left: 0;
  cursor: pointer;
  font-size: 16px;
  color: ${theme.palette.common.white}
`);

const ListItemStyled = styled((props: ListItemProps) => (
  <ListItem {...props} />
))(({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  min-height: 10px;
  height: 20px;
  cursor: pointer;
  color: ${theme.palette.common.white}
`);

export const DrawerContent: React.FC<DrawerContentProps> = ({
  menuData,
  handleMenuItemClick
}) => {
  const [expandedMenuName, setExpandedMenuName] = useState<string | false>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setExpandedMenuName(location.pathname.replaceAll('/', '-'));
  }, []);

  const onChangeExpandedMenuName = (menuName: string | false) => {
    setExpandedMenuName(menuName);
  };
  
  const handleOnClick = async (currentUrl: string) => {
    handleMenuItemClick(true);

    const previousUrl = window.location.pathname;

    if (previousUrl === currentUrl) {
      setTimeout(() => {
        navigate('/loader');
      }, 1);
    }
  };

  return (
    <>
      {menuData.map((menuItem, index) => (
        <div key={index}>
          {
            (!menuItem.subMenuItems || menuItem.subMenuItems.length == 0) &&
            <ListItemStyled>
              {menuItem.url && 
                <ListItemButtonStyled url={menuItem.url} onClick={() => menuItem.id === 'home' && handleOnClick(menuItem.url || '')}>
                  <MenuImageWrapper>{getIconByMenuName(menuItem.name)}</MenuImageWrapper>
                  <Typography>{menuItem.name}</Typography>
                </ListItemButtonStyled>
              }
            </ListItemStyled>
          }
          {
            (menuItem.subMenuItems && menuItem.subMenuItems.length) &&
            <Wrapper key={index}>
              <DrawerSubContent key={index} id={menuItem.id} menuName={menuItem.name} subMenuData={menuItem.subMenuItems} expandedMenuName={expandedMenuName} setExpandedMenuName={onChangeExpandedMenuName} handleMenuItemClick={handleMenuItemClick} />
            </Wrapper>
          }
        </div>
      ))}
    </>
  );
};