import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { permanantDeleteUploadedFile } from '../../../../api/uploadedFiles';
import { useGetUploadedImages, useUpdateUploadedFileAditionalData, useUploadFiles } from '../../../../queries/uploadedfiles-query';
import { MultipleImageUpload, ProfilePicProps } from '../../../../components/organisms/MultipleImageUpload';
import { MultipleImageUploadFragmentProps } from './MultipleImageUploadFragment.props';
import { Loader, LoaderColorProps } from '../../../../components/atoms/Loader';
import { Folders } from '../../../../@types/uploaded-files.type';

export const MultipleImageUploadFragment: React.FC<MultipleImageUploadFragmentProps> = ({
  entityId
}) => {
  const [multipleImages, setMultipleImages] = useState<ProfilePicProps[]>([]);
  const [mainImage, setMainImage] = useState<ProfilePicProps>();
  const [id, setId] = useState();
  const [mainId, setMainId] = useState(0);
  const [newMainId, setNewMainId] = useState(0);

  const uploadedImagesQuery = useGetUploadedImages(entityId, Folders.PartTypesImages);
  const updateUploadedFileAditionalData = useUpdateUploadedFileAditionalData(entityId);
  const uploadFiles = useUploadFiles(entityId, Folders.PartTypesImages);

  useEffect(() => {
    uploadedImagesQuery.refetch();
  }, []);

  useEffect(() => {
    if (uploadedImagesQuery.data) {
      const retirevedImages: any = uploadedImagesQuery.data.sort((a, b) => a?.id - b?.id);
      const isMainExist = retirevedImages.some((retirevedImage: any) => retirevedImage.data?.isMain == true);

      retirevedImages.map((retirevedImage: any, index: any) => {
        if (!isMainExist && index == 0) {
          if (newMainId == 0) {
            setMainImage({
              url: retirevedImage?.url,
              id: retirevedImage?.id
            });

            updateUploadedFileAditionalData.mutateAsync({ id: retirevedImage?.id, isMain: true });
          }
          setMainId(0);
        } else if (isMainExist && retirevedImage.data?.isMain == true) {
          if (mainId == 0) {
            setMainImage({
              url: retirevedImage?.url,
              id: retirevedImage?.id
            });
          }
          setMainId(0);
        }
        else {
          multipleImages.push({
            url: retirevedImage?.url,
            id: retirevedImage?.id,
          });
        }
      });

      const uniqueItems = multipleImages
        .filter((image: any, index: any) => index === multipleImages
          .findIndex((element: any) => element.id === image.id));

      if (id) {
        const filteredImageList = uniqueItems.filter((uniqueItem: any) => uniqueItem.id != id);
        setMultipleImages([...filteredImageList]);
      } else {
        const filteredImageList = uniqueItems.filter((uniqueItem: any) => uniqueItem.id != mainId);
        setMultipleImages([...filteredImageList]);
      }
    }
  }, [uploadedImagesQuery.data]);

  const onClickPicture = async (fileList: any) => {
    const upload = [...fileList];
    const formData = new FormData();

    upload.forEach((item) => {
      formData.append('fileList', item);
    });

    await uploadFiles.mutateAsync({ entityId, formData, entityType: Folders.PartTypesImages }).then(() => uploadedImagesQuery.refetch());
  };

  const onExchangePicture = async (image: any) => {
    const subImageId = image?.id;

    setMainImage({
      url: image?.url,
      id: subImageId
    });

    const index = multipleImages.indexOf(image);

    if (index > -1 && mainImage?.url) {
      multipleImages[index].url = mainImage.url;
      multipleImages[index].id = mainImage?.id;
      await updateUploadedFileAditionalData.mutateAsync({ id: mainImage.id, isMain: false });
    } else if (index > -1 && !mainImage?.url) {
      multipleImages.splice(index, 1);
      setId(subImageId);
    }

    setMainId(subImageId);
    await updateUploadedFileAditionalData.mutateAsync({ id: subImageId, isMain: true }).then(() => uploadedImagesQuery.refetch());
  };

  const onDeleteMainImage = async (id: any) => {
    setMainImage({
      url: '',
      id: 0
    });

    setMainId(id);
    setNewMainId(id);
    await permanantDeleteUploadedFile(id).then(() => uploadedImagesQuery.refetch());
  };

  const onDeleteSubImage = async (image: any, fileInfo: any) => {
    const index = multipleImages.indexOf(image);

    if (index > -1) {
      // remove the item
      const removedImage: any = multipleImages.splice(index, 1);
      setId(removedImage[0].id);
    }

    await permanantDeleteUploadedFile(fileInfo).then(() => uploadedImagesQuery.refetch());
  };

  if (uploadedImagesQuery.isLoading) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }

  return (
    <MultipleImageUpload
      defaultImage={mainImage}
      subImages={multipleImages}
      onClickPicture={onClickPicture}
      onExchangePicture={onExchangePicture}
      onDeleteMainImage={onDeleteMainImage}
      onDeleteSubImage={onDeleteSubImage}
    />
  );
};