import React, { useEffect, useState } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { GridFeatureModeConstant, GridFilterModel, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useGetAllReplenishmentReports } from '../../../queries/replenishmentConfig-query';
import { ReplenishmentReportFilters, ReplenishmentReportListResponse } from '../../../@types/replenishmentConfig.type';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { HorizontalAlignProps, PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { InlineGroup } from '../../../components/atoms/InlineGroup';
import { REPLENISHMENT_REPORT_FILTERS } from '../../../constants/replenishmentConfig';
import { Button } from '../../../components/atoms/Button';
import { ReplenishmentFilterFragment } from './ReplenishmentFilterFragment';
import { Chip } from '../../../components/atoms/Chip';
import { ColorProps } from '../../../@types';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const ReplenishmentReportList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [replenishmentReports, setReplenishmentReports] = useState<ReplenishmentReportListResponse>();
  const [replenishmentFilters, setReplenishmentFilters] = useState<ReplenishmentReportFilters>(REPLENISHMENT_REPORT_FILTERS);

  TabHandler(`Replenishment | ${PLATFORM_NAME}`);

  const getAllReplenishmentReports = useGetAllReplenishmentReports(
    {
      currentPage: page, 
      limit: pageSize,
      total: 0
    },
    {
      order_by: '',
      order_direction: 'asc'
    },
    replenishmentFilters
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'clientContract',
      headerName: 'Client/Contract',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.clientContract
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.location
    },
    {
      field: 'partTypeOrSkuName', 
      headerName: 'partType/SkuName', 
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.partTypeOrSkuName
    },
    {
      field: 'min', 
      headerName: 'Min', 
      valueGetter: (params: GridValueGetterParams) => params.row.min
    },
    {
      field: 'max', 
      headerName: 'Max',   
      valueGetter: (params: GridValueGetterParams) => params.row.max
    },
    {
      field: 'includeChildNode', 
      headerName: 'includeChildNode',
      valueGetter: (params: GridValueGetterParams) => params.row.includeChildNode ? 'Yes' : 'No'
    },
    {
      field: 'isActive', 
      headerName: 'isActive', 
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Yes' : 'No'
    },
    {
      field: 'averageFreightLeadTimeOneMonth', 
      headerName: 'Average Freight Lead Time One Month',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.averageFreightLeadTimeOneMonth
    },
    {
      field: 'averageFreightLeadTimeThreeMonth', 
      headerName: 'Average Freight Lead Time Three Month',
      width: 300, 
      valueGetter: (params: GridValueGetterParams) => params.row.averageFreightLeadTimeThreeMonth
    },
    {
      field: 'averageUsageOneMonth', 
      headerName: 'Average Usage One Month',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.averageUsageOneMonth
    },
    {
      field: 'averageUsageThreeMonth', 
      headerName: 'Average Usage Three Month',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.averageUsageThreeMonth
    },
    {
      field: 'state', 
      headerName: 'State',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.state
    },
    {
      field: 'condition', 
      headerName: 'Condition',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.condition
    },
    {
      field: 'stockOnHand', 
      headerName: 'Stock On Hand',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.stockOnHand
    },
    {
      field: 'inTransit', 
      headerName: 'In Transit',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.inTransit
    },
    {
      field: 'servicesInTrain', 
      headerName: 'Services In Train',
      width: 150, 
      valueGetter: (params: GridValueGetterParams) => params.row.servicesInTrain
    },
    {
      field: 'replenishmentRequired', 
      headerName: 'Replenishment Required',
      width: 200,
      renderCell: (params: GridRenderCellParams) => 
        params.row.replenishmentRequired.includes('Yes') ? 
          <Chip label={params.row.replenishmentRequired} color={ColorProps.Error} /> : 
          <Chip label={params.row.replenishmentRequired} color={ColorProps.Success} />,
      valueGetter: (params: GridValueGetterParams) => params.row.replenishmentRequired
    },
    {
      field: 'distributionCentreMap', 
      headerName: 'Distribution Centre Map',
      width: 300,
      minWidth: 500,
      renderCell: (params: GridRenderCellParams) =>
        <ul className="flex">
          {params.row.distributionCentreMap.map((distributionItem: string, index: number) =>
            <li key={index}>
              {distributionItem}
            </li>
          )}
        </ul>
      ,
      valueGetter: (params: GridValueGetterParams) => params.row.distributionCentreMap
    },
  ];

  useEffect(() => {
    getAllReplenishmentReports.data && setReplenishmentReports(getAllReplenishmentReports.data);
  }, [getAllReplenishmentReports.data]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Replenishment Config',
      value: [
        {
          field: 'id',
          title: 'ID',
          value: false
        },
        {
          field: 'name',
          title: 'Name',
          value: true
        },
        {
          field: 'clientContract',
          title: 'Client/Contract',
          value: true
        },
        {
          field: 'location',
          title: 'Location',
          value: true
        },
        {
          field: 'partTypeOrSkuName', 
          title: 'partType/SkuName', 
          value: true
        },
        {
          field: 'min', 
          title: 'Min', 
          value: true
        },
        {
          field: 'max', 
          title: 'Max',   
          value: true
        },
        {
          field: 'includeChildNode', 
          title: 'includeChildNode',
          value: true
        },
        {
          field: 'averageFreightLeadTimeOneMonth', 
          title: 'Average Freight Lead Time One Month', 
          value: true
        },
        {
          field: 'averageFreightLeadTimeThreeMonth', 
          title: 'Average Freight Lead Time Three Month', 
          value: true
        },
        {
          field: 'averageUsageOneMonth', 
          title: 'Average Usage One Month', 
          value: true
        },
        {
          field: 'averageUsageThreeMonth', 
          title: 'Average Usage Three Month', 
          value: true
        },
        {
          field: 'state', 
          title: 'State', 
          value: true
        },
        {
          field: 'condition', 
          title: 'Condition', 
          value: true
        },
        {
          field: 'stockOnHand', 
          title: 'Stock On Hand', 
          value: true
        },
        {
          field: 'inTransit', 
          title: 'In Transit', 
          value: true
        },
        {
          field: 'servicesInTrain', 
          title: 'Services In Train', 
          value: true
        },
        {
          field: 'replenishmentRequired', 
          title: 'Replenishment Required', 
          value: true
        },
        {
          field: 'distributionCentreMap', 
          title: 'Distribution Centre Map', 
          value: false
        },
      ]
    }
  ];

  const onSearch = () => {
    getAllReplenishmentReports.refetch();
  };

  const onReset = () => {
    setReplenishmentFilters(REPLENISHMENT_REPORT_FILTERS);
    getAllReplenishmentReports.refetch();
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Replenishment
          </Typography>
        </InlineGroup>
        <ReplenishmentFilterFragment replenishmentFilterData={replenishmentFilters} onChange={setReplenishmentFilters}/>
        <InlineGroup horizontal={HorizontalAlignProps.End} childGap={10}>
          <Button onClick={onReset}>Reset</Button>
          <Button onClick={onSearch}>Search</Button>
        </InlineGroup>
        <GridContainer>
          <CustomDataGrid
            columns={columns}
            loading={getAllReplenishmentReports.isRefetching}
            rows={replenishmentReports?.data || []}
            rowCount={replenishmentReports?.total}
            columnSelectionItems={columnSelectionItems}
            filterMode={GridFeatureModeConstant.client}
            enableCSVExport={false}
            exportFileName=""
            onFilterModelChange={(filterModel: GridFilterModel) => {
              filterModel;
            }}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  parentNodeId: false,
                  referenceNumbers: false,
                  status: false,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true }
              }
            }}
            enableReplenishmentReportFilters={true}
            disableColumnFilter={true}
            replenishmentFilterData={replenishmentFilters}
            handleReplenishmentReportFilters={setReplenishmentFilters}
            getRowHeight={() => 'auto'}
          />
        </GridContainer>
      </Wrapper>
    </PageContainer>
  );
};