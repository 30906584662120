import { ReactNode } from 'react';

export enum TextColorProps {
  Primary = 'primary',
  Secondary = 'secondary',
  Inherit = 'inherit'
}
export interface TabsProps {
  name: string;
  tabTitles: Array<string>;
  children: Array<ReactNode>;
  textColor?: TextColorProps;
  TabIndicatorProps?: object;
  isCustomColor?: boolean;
  onTabChange?: (tabType: string) => void;
}