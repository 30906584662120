import { Grid, styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';

export const TitleLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: gray;
`;

export const GridAlignContainer = styled(Grid)`
  display: flex;
  align-items: center;
  * {
    margin-right: 5px;
  }
`;