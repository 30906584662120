import React from 'react';
import { CircularProgress } from '@mui/material';
import { LoaderProps } from './Loader.props';

export const Loader: React.FC<LoaderProps> = ({
  color
}) => {
  return (
    <CircularProgress color={color}/>
  );
};
