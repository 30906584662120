import React from 'react';
import { Stack } from '@mui/material';
import { ColorProps } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { Switch } from '../../atoms/Switch';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { TradingHours } from '../../molecules/TradingHours';
import { CoverageProps } from './Coverage.props';
import { MultiSelectMenu, SwitchGrid } from './Coverage.styles';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { configureSelectedDropdownOptions, getDropdownOptionsWithHandlingAnyKey } from '../../../utils/structure';

export const Coverage: React.FC<CoverageProps> = ({
  serviceTypeItems,
  prioritiesItems,
  regionTypeItems,
  onChange,
  values,
  onDelete,
}) => {
  return (
    <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <MultiSelectMenu 
        id="service-types"
        label="Service Types"
        selectedValue={values.selectedServiceTypes}
        onChange={(value) => onChange && onChange({ ...values, selectedServiceTypes: configureSelectedDropdownOptions(value) })}
        items={getDropdownOptionsWithHandlingAnyKey(values.selectedServiceTypes || [], serviceTypeItems)} 
      />
      <MultiSelectMenu 
        id="priorities"
        label="Priorities"
        selectedValue={values.selectedServiceTypes.length === 0 ? [] : values.selectedPriorities}
        disabled={values.selectedServiceTypes.length === 0}
        onChange={(value: any) => onChange && onChange({ ...values, selectedPriorities: configureSelectedDropdownOptions(value) })}
        items={getDropdownOptionsWithHandlingAnyKey(values.selectedPriorities || [], prioritiesItems)} 
      />
      <MultiSelectMenu 
        id="region-types"
        label="Region Types"
        selectedValue={values.selectedRegionTypes}
        onChange={(value) => onChange && onChange({ ...values, selectedRegionTypes: configureSelectedDropdownOptions(value) })}
        items={getDropdownOptionsWithHandlingAnyKey(values.selectedRegionTypes || [], regionTypeItems)} 
      />
      <TradingHours 
        value={values.tradingHours}
        onChange={(value) => onChange && onChange({ ...values, tradingHours: value })}
        onDelete={() => onDelete}
        disableElement={true}
      />
      <SwitchGrid>
        <FormControlLabel
          control={
            <Switch 
              color={ColorProps.Success}
              onChange={(event) => onChange && onChange({ ...values, checked: event.target.checked })}
              checked={values.checked}
            />
          }
          label="Enabled"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Stack>
  );
};