import { Stack, styled } from '@mui/material';
import { SelectMenu } from '../../../../../../components/atoms/SelectMenu';
import { LiveSearchBox } from '../../../../../../components/atoms/LiveSearchBox';
import { DateTimePicker } from '../../../../../../components/atoms/DateTimePicker';
import { Tags } from '../../../../../../components/organisms/Tags';

export const StyledStack = styled(Stack)`
  min-width: 300px;
  max-width: 300px;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  min-width: 300px;
`;

export const StyledLiveSearchBox = styled(LiveSearchBox)`
  min-width: 300px;
`;

export const StyledDateTimePicker= styled(DateTimePicker)`
  min-width: 300px;
`;

export const StyledTags = styled(Tags)`
  min-width: 300px;
`;
