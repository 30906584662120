import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { REACT_APP_AUTH0_AUDIENCE, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } from '../../configs/envs';

export const Auth0ProviderWithNavigate = ({ children }: { children: any }) => {

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    const URL = appState?.returnTo;

    navigate(URL || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={REACT_APP_AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};