import React from 'react';
import { Grid } from '@material-ui/core';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { TLCPartActionProps } from './TLCPartAction.props';
import { PartActionRow } from '../../molecules/PartActionRow/PartActionRow';

export const TLCPartAction: React.FC<TLCPartActionProps> = ({
  partActionArray,
  disabled,
  onAddPartAction,
  onChangeRow,
  onClearRow
}) => {
  return (
    <>
      <Grid item xs={6} mr={3}>
        <Button disabled={disabled} variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={onAddPartAction}>
            + Part Action
        </Button>
      </Grid>
      <Grid ml={6} xs={8}>
        {partActionArray.map((row) => 
          <PartActionRow
            key={row.id}
            disabled={disabled}
            rowData={row}
            onChangeRow={onChangeRow}
            onClearRow={onClearRow}
          />
        )}
      </Grid>
    </>
  );
};