import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';
import { Grid } from '@mui/material';
import { Box } from '../../../components/atoms/Box';

export const RoleTypography = styled(Typography)`
  padding-bottom: 10px;
`;

export const RoleBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const PageActionTypography = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  margin-inline: 22.5px;
  display: flex;
  justify-content: space-between;
`;

export const ActionGrid = styled(Grid)`
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  width: 100%;
  padding: 0px;
  padding-bottom: 10px;
`;
