import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useGetUserSystemPermissions } from '../../queries/user-query';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { InlineGroup, HorizontalAlignProps } from '../../components/atoms/InlineGroup';
import { PageContainer } from '../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { Wrapper } from '../../styled/common.styles';
import { StructureTypeList } from './StructureTypeList';
import { ActionPermissions, CustomPermission } from '../../@types';
import { isUserHasPermission } from '../../configs/permissions';
import { Link } from '../../components/atoms/Link';
import { StructureTypeEnum } from './AddEditStructureType/AddEditStructureType.props';
import { RoutePath } from '../../@types/route.type';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';

export const StructureTypes: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  TabHandler(`Structure Types | ${PLATFORM_NAME}`);
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            {StructureTypeEnum.ListStructureTypes}
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Structure_Type_Create, permissions) && (
            <Link href={RoutePath.AddStructureType}>
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
                {StructureTypeEnum.AddStructureType}
              </Button>
            </Link>
          )}
        </InlineGroup>
        {isUserHasPermission(ActionPermissions.Configure_Structure_Type_Search_View, permissions) && (
          <StructureTypeList/>
        )}
      </Wrapper>
    </PageContainer>
  );
};