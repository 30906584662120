import { styled } from '@mui/material/styles';
import MaterialButton from '@mui/material/Button';
import MaterialTypography from '@mui/material/Typography';
import { defaultColors } from '../../../configs/colors';

export const StyledButton = styled(MaterialButton)`
  text-transform: uppercase;
  border-radius: 8px;
`;

export const StyledText = styled(MaterialTypography)`
  font-family: 'Inter';
  font-size: smaller;
  font-weight: 500;
  cursor: pointer;
  color: ${defaultColors[0]};
`;