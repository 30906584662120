import { LabelValue } from '../../../@types';
import { BulkEditServiceDataType } from '../../../@types/service.type';

export enum LinkedFieldType {
  ActionType = 'actionType',
  ProcessType = 'processType',
  ReferenceNo1 = 'referenceNo1',
  ReferenceNo2 = 'referenceNo2',
  ReferenceNo3 = 'referenceNo3',
  UserDepotAllocation = 'userDepotAllocation',
  SecondaryUserDepotAllocation = 'secondaryUserDepotAllocation',
  ScheduledDateTime = 'scheduledDateTime',
  FollowUpDateTime = 'followUpDateTime',
  Notes = 'notes',
  Status = 'status',
  ParentServiceID = 'parentServiceID'
}

export interface BulkEditServiceDropdownsData {
  actionTypeItems: LabelValue[];
  processTypeItems: LabelValue[];
  statusItems: LabelValue[];
  linkedFieldItems?: LabelValue[];
}

export interface SelectedServiceListDataType {
  id: number;
  isClosed?: boolean;
  image?: string;
}

export interface BulkServiceEditorProps {
  open: boolean;
  BulkEditServiceDropDownsDataset?: BulkEditServiceDropdownsData;
  selectedServices?: SelectedServiceListDataType[];
  toastMessage?: string;
  parentServiceId?: string;
  handleClose: () => void;
  handleSave: (BulkServiceUpdateData: BulkEditServiceDataType) => void;
  handleImportedData?: (ids: object[]) => void;
}