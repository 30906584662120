import { Stack, TableCell, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledStackProps } from './TLCManifestRowItem.props';
import { COLORS } from '../../../configs/colors';

export const StyledStackContainer = styled(Stack)<StyledStackProps>`
  background-color: ${(props) => (props.error ? COLORS.Red : COLORS.White)};
  border: 1px solid #000;
`;

export const StyledStatusStack = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px;
`;

export const StyledTableCell = styled(TableCell)`
  border: 0;
  height: 30px;
`;

export const SerializedStyledTableCell = styled(TableCell)`
  border: 0;
  height: 15px;
  padding-inline: 10px;
  text-align: center;
`;

export const StyledSubHeading = styled(Typography)`
  font-size: 15px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
`;

export const StyledDeleteContainer = styled(Stack)`
  background-color: ${COLORS.White};
  justify-content: center;
  border: 1px solid #000;
  width: 45px;
`;