import React from 'react';
import { DrawerSubContentProps } from './DrawerSubContent.props';
import { Typography } from '../../../atoms/Typography';
import { ListItemButton } from '../../../atoms/ListItemButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HomeOutlined, Lan, MiscellaneousServices, CorporateFare } from '@mui/icons-material';
import { AccordionInnerWrapper, Wrapper, AccordionDetailsStyled, MenuImageWrapper } from '../Drawer.styles';
import { caseIndependentCompare } from '../../../../utils/common';
import { Accordion, AccordionSummary } from './DrawerSubContent.styles';
import { useNavigate } from 'react-router-dom';

export const getIconByMenuName = (menuName: string) => {
  const iconStyle = {
    height: '24px',
    width: '24px'
  };

  switch (menuName) {
    case 'Configure': {
      return <CorporateFare sx={iconStyle} />;
    }
    case 'Services': {
      return <MiscellaneousServices sx={iconStyle} />;
    }
    case 'Structure': {
      return <Lan sx={iconStyle} />;
    }
    default:
      return <HomeOutlined sx={iconStyle} />;
  }
};

export const DrawerSubContent: React.FC<DrawerSubContentProps> = ({
  id,
  menuName,
  subMenuData,
  expandedMenuName,
  setExpandedMenuName,
  handleMenuItemClick
}) => {
  const navigate = useNavigate();

  const handleChange = (panel: string, immediateParent?: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedMenuName(isExpanded ? panel : immediateParent ? immediateParent : false);
  };

  const handleOnClick = async (currentUrl: string) => {
    handleMenuItemClick(true);

    const previousUrl = window.location.pathname;

    if (previousUrl === currentUrl) {
      setTimeout(() => {
        navigate('/loader');
      }, 1);
    }
  };

  const accordion = (id: string, menuName: string, subMenuData: { id: string; name: string; url: string; subMenuItems?: { id: string; name: string; url: string }[] }[], index = 0, immediateParent?: string) => {
    return (
      <Accordion disableGutters elevation={0} square expanded={caseIndependentCompare(expandedMenuName.toString(), id)} onChange={handleChange(id, immediateParent)} index={index}>
        <AccordionSummary
          id={id}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content">
          <MenuImageWrapper>
            {index == 0 && getIconByMenuName(menuName)}
          </MenuImageWrapper>
          <Typography>{menuName}</Typography>
        </AccordionSummary>
        <AccordionDetailsStyled>
          {subMenuData.map((item, index) => (
            <Wrapper key={index}>
              {!item.subMenuItems &&
                <ListItemButton id={item.id} url={item.url} setHeaderIsExpanded={() => setExpandedMenuName(id)} onClick={() => handleOnClick(item.url)}>{item.name}</ListItemButton>
              }
              {item.subMenuItems && item.subMenuItems.length > 0 &&
                <AccordionInnerWrapper>
                  {accordion(item.id, item.name, item.subMenuItems, index + 1, id)}
                </AccordionInnerWrapper>
              }
            </Wrapper>
          ))}
        </AccordionDetailsStyled>
      </Accordion>
    );
  };

  return (
    <Wrapper>
      {accordion(id, menuName, subMenuData)}
    </Wrapper>
  );
};