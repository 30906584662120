import React, { useCallback, useEffect, useState } from 'react';
import { CustomPermission } from '../../../../@types/permission.type';
import TreeModel from 'tree-model';
import { PermissionsWrapper, Root, SaveButton, TreeView, Permissions, PermissionTreeView } from './PermissionView.styles';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { useParams } from 'react-router-dom';
import { PermissionViewProps } from './PermissionView.props';
import { flatten } from '../../../../utils/tree';
import { ROOT_NODE } from '../../../../configs/permissions';
import { ButtonVariantProps } from '../../../../components/atoms/Button';
import { useAddUserPermissions } from '../../../../queries/user-query';

export const PermissionView: React.FC<PermissionViewProps> = ({
  rolePermissions,
  updatedRolePermissions
}) => {
  const tree = new TreeModel();
  const { id: userId } = useParams();
  const [loading, setLoading] = useState(false);
  const [permissionData] = useState(updatedRolePermissions);
  const [customPermissions, setCustomPermissions] = useState<CustomPermission[]>([]);
  const updatedRoot = tree.parse({ ...ROOT_NODE, children: permissionData });

  const addUserPermissionsQuery = useAddUserPermissions();

  const onRemovePermission = useCallback((updatedItem: CustomPermission) => {
    // Update the tree
    updatedRoot.walk((node) => {
      if (node.model.id === updatedItem.permissionId) {
        node.model.isGranted = !updatedItem.isGranted;
      }
      return true;
    });

    // Remove item from customPermissions array
    setCustomPermissions(arr =>
      arr.filter(permission => permission.permissionId !== updatedItem.permissionId)
    );
  }, [updatedRoot, setCustomPermissions]);

  //Recuresivly construct permission names in the tree for given node
  const getFullPermissionPathNames = (permissionLeavelNames: string[], parentNode: any): string[] => {
    //Once reached root node or no more parents return
    if (!parentNode || parentNode?.model?.name === ROOT_NODE.name) {
      return permissionLeavelNames;
    }
    return getFullPermissionPathNames([parentNode?.model?.name, ...permissionLeavelNames], parentNode.parent);
  };

  const getPermissionPath = useCallback((id: number): string => {
    const node = updatedRoot.first((node) => node.model.id === id);
    const nodeNames = getFullPermissionPathNames([node?.model.name], node?.parent);
    return nodeNames.join(' > ');
  }, [updatedRoot]);

  const onSavePermissions = useCallback(async () => {
    setLoading(true);
    await addUserPermissionsQuery.mutateAsync({ id: userId, customPermissions: customPermissions });
    setLoading(false);
  }, [userId, customPermissions]);

  // Compare initial tree with updated tree to get the custom permissions
  const compareTrees = () => {
    const updatedNodes = flatten(permissionData);
    const initialNodes = flatten(rolePermissions);

    const nodeDiff = updatedNodes.filter(node => !initialNodes.some(({ isGranted, id }) =>
      node.isGranted === isGranted && node.id === id
    ));

    const customPermissions = nodeDiff.map(node => ({
      id: node.id,
      permissionId: node.id,
      code: node.code,
      name: getPermissionPath(node.id),
      isGranted: node.isGranted,
    }));

    setCustomPermissions(customPermissions);
  };

  useEffect(() => {
    compareTrees();
  }, [permissionData]);

  return (
    <Root>
      <TreeView>
        <Typography variant={TypographyVariantProps.H6}>Assigned Permissions</Typography>
        {permissionData.length > 0 && <PermissionTreeView
          permissions={permissionData}
          handlePermissionChange={compareTrees}
        />}
      </TreeView>
      <PermissionsWrapper>
        <Typography variant={TypographyVariantProps.H6}>Custom Permissions</Typography>
        {customPermissions.length > 0 && !loading &&
          <Permissions
            updatedItems={customPermissions}
            onRemovePermission={onRemovePermission}
          />
        }
        <SaveButton variant={ButtonVariantProps.Secondary} onClick={onSavePermissions}>
          Save
        </SaveButton>
      </PermissionsWrapper>
    </Root>
  );
};