import { NodeAttributesReponse } from '../@types';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { ANY_ITEM_ARRAY, ANY_KEYWORD } from '../constants/common';

export const setModelDropdownMenu = (modelData: NodeAttributesReponse[]) => {
  return ANY_ITEM_ARRAY.concat(modelData.map(model => ({ value: model.id.toString(), label: model.value.clientModelName })));
};

export const setDropDownList = (selectedListOptions: string[], menuItems: DropdownItem[]) => {
  if (selectedListOptions.some((type: string) => type === ANY_KEYWORD)){
    return menuItems.filter((type) => type.value === ANY_KEYWORD);
  }
  return menuItems;
};