import React from 'react';
import { Root, Children } from './PageContainer.styles';
import { PageContainerProps } from './PageContainer.props';

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  align,
  className
}) => {
  return (
    <Root className={className} align={align}>
      <Children className={className} align={align}>
        {children}
      </Children>
    </Root>
  );
};
