import React from 'react';
import { Grid, Stack } from '@mui/material';
import { TypographyVariantProps } from '../../atoms/Typography';
import { SelectMenu } from '../../atoms/SelectMenu';
import { ContractMultiSelectorProps } from './ContractMultiSelector.props';
import { ContentWrapperBox, HeadingWrapper, StyledMultiSelectCheckmarksMenu } from './ContractMultiSelector.styles';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { ANY_KEYWORD } from '../../../constants/common';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';

export const ContractMultiSelector: React.FC<ContractMultiSelectorProps> = ({
  open,
  client = '',
  contract,
  clients,
  contracts,
  onChange,
  onSelect,
  onClose
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={onClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Client Contract Select
        </HeadingWrapper>
        <Stack direction="row" spacing={1} width="100%">
          <Grid width="100%">
            <SelectMenu
              id="contract-multi-selector-client"
              labelId="contract-multi-selector-client-label"
              items={clients}
              selectedValue={client.toString()}
              onChange={(clientId) => onChange(clientId, contract || [])}
              label="Select Client"
            />
          </Grid>
          <Grid width="100%">
            <StyledMultiSelectCheckmarksMenu
              id="contract-multi-selector-contracts"
              label="Select Contract"
              items={contract?.includes(ANY_KEYWORD) ? contracts.filter((contract) => contract.value === ANY_KEYWORD) : contracts}
              selectedValue={client ? contract : []}
              onChange={(contractIdText) => {          
                const contractIds = contractIdText.split(',');

                if (contractIds.includes(ANY_KEYWORD))
                  onChange(client, [ANY_KEYWORD]);
                else
                  onChange(client, contractIdText && contractIdText === '' ? [] : contractIds);
              }}          
            />
          </Grid>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={onClose}>Cancel</Button>
          <Button variant={ButtonVariantProps.Primary} onClick={onSelect}>Select</Button>
        </Stack>
      </ContentWrapperBox>
    </Modal>
  );
};