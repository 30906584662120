import { AttachmentTypesResponse, BulkReportResponse, NewReportDataType, OverwriteReportDataType, ReportAccessData, ReportScheduleEmailType, ReportTestEmailType } from '../@types';
import { CustomFilterSettings, QueryOption } from '../@types/common.type';
import { httpClient, HttpMethod } from './http-client';

export const getAttendanceReports = (queryOptions: QueryOption, customFilter?: CustomFilterSettings) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/attendance?page=${page + 1}&limit=${pageSize}&includeChilds=${customFilter?.includeChilds}&startDate=${customFilter?.startDate}&endDate=${customFilter?.endDate}`;

  if (customFilter?.locationId) {
    url += `&locationId=${customFilter?.locationId}`;
  }

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getSavedReportsByCreatedId = (queryOptions: QueryOption,) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/savedReport?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }
  
  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const createReport = (report: NewReportDataType) => {
  return httpClient('/savedReport', HttpMethod.Post, report);
};

export const getReportByCode = (code: string) => {
  return httpClient(`/savedReport/${code}`, HttpMethod.Get, null);
};

export const updateReportByCode = (code: string, report: OverwriteReportDataType) => {
  return httpClient(`/savedReport/${code}`, HttpMethod.Put, report);
};

export const createSavedReportAccess = (id: number, reportAccessData: ReportAccessData) => {
  return httpClient(`/savedReport/${id}/shareAccess`, HttpMethod.Post, reportAccessData);
};

export const updateReportAccessDataById = (Id: string, reportAccessData: ReportAccessData) => {
  return httpClient(`/savedReportAccess/${Id}`, HttpMethod.Put, reportAccessData);
};

export const getSavedReportAccessByReportId = (reportId: string) => {
  return httpClient(`/savedReportAccess/${reportId}`, HttpMethod.Get, null);
};

export const getReportAccessByReportCode = (code: string) => {
  return httpClient(`/savedReport/${code}/viewAccess`, HttpMethod.Get, null);
};

export const getAttachmentTypes = (): AttachmentTypesResponse => {
  return httpClient('/attachmentType', HttpMethod.Get, null);
};

export const createScheduleReport = (reportData: ReportScheduleEmailType) => {
  return httpClient('/scheduleReport', HttpMethod.Post, reportData);
};

export const getReportScheduleDataByReportId = (reportId: string) => {
  return httpClient(`/scheduleReport/getByReportId/${reportId}`, HttpMethod.Get, null);
};

export const updateReportScheduleEmailByReprtId = (reportId: string, updatedScheduleEmailData: ReportScheduleEmailType) => {
  return httpClient(`/scheduleReport/${reportId}`, HttpMethod.Put, updatedScheduleEmailData);
};

export const getDeafultReportByReportType = (reportTypeName: string) => {
  return httpClient(`/savedReport/${reportTypeName}/isDefault`, HttpMethod.Get, null);
};

export const updateBulkReports = (reports: BulkReportResponse[]) => {
  return httpClient('/defaultReport', HttpMethod.Patch, reports);
};

export const deleteReportById = (id: number) => {
  return httpClient(`/savedReport/${id}`, HttpMethod.Delete, null);
};

export const sendSavedReportTestEmail = (testEmailData: ReportTestEmailType) => {
  return httpClient('/savedReport/testEmail', HttpMethod.Post, testEmailData);
};