import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, IconButton } from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import {
  RoleBox,
  StructureAddressAddGrid,
  StyledTableRow,
  StyledTypography,
  TableGridContainer
} from './StructureAddressContactLayout.styles';
import { StructureAddressContactLayoutProps } from './StructureAddressContactLayout.props';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { StructureAddress } from '../../organisms/StructureAddress';
import { Contact } from '../../molecules/Contact';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { isEmptyString } from '../../../utils/common';
import { ADDRESS_CONTACT_TYPES } from '../../../constants/structure';
import { IconButtonColor } from '../../../configs/enums';

export const StructureAddressContactLayout: React.FC<StructureAddressContactLayoutProps> = ({
  heading,
  isAddress,
  isOneAddress,
  contactType,
  contactFormData,
  addressFormData,
  editContactFormData,
  editAddressFormData,
  addressDataset,
  contactDataset,
  enableContactNumber,
  addressForm = [],
  contactForm = [],
  setAddressForm = () => [],
  setContactForm = () => [],
  onClickContactEdit,
  onClickContactDelete,
  onClickAddressEdit,
  onClickAddressDelete,
  onStructureAddressChange,
  handleAddressCreate,
  handleContactCreate,
  onContactChange,
  addFormEnabledByDefault
}) => {
  const [isEnabledAdd, setIsEnabledAdd] = useState<boolean>(true);

  const addressRequiredFields = ['line1', 'suburb', 'stateName', 'postCode', 'countryName'];

  useEffect(() => {
    if (contactForm.length) {
      const contactList = contactForm.map(data => data.value);
      onContactChange && onContactChange([...contactList]);
    }

    if (addressForm.length) {
      const addressList = addressForm.map(data => data.value);
      onStructureAddressChange && onStructureAddressChange([...addressList]);
    }

    if (isAddress && addFormEnabledByDefault) {
      isOneAddress && setIsEnabledAdd(false);
      !addressForm.length && addNewForm();
    }
  }, [contactForm, addressForm]);

  useEffect(() => {
    editContactFormData && editContactForm();
  }, [editContactFormData]);
  
  const editContactForm = () => {
    const updateData = {
      id: editContactFormData?.id,
      contactName: editContactFormData?.contactName,
      organization: editContactFormData?.organization,
      phone1: editContactFormData?.phone1,
      phone2: editContactFormData?.phone2,
      email: editContactFormData?.email,
      notes: editContactFormData?.notes,
      contactType: editContactFormData?.contactType,
      isActive: editContactFormData?.isActive
    };

    setContactForm([...contactForm, {
      prevValue: updateData,
      value: updateData
    }]);

  };

  useEffect(() => {
    editAddressFormData && editAddressForm();
  }, [editAddressFormData]);

  const editAddressForm = () => {
    const updateData = {
      id: editAddressFormData?.id,
      placeName: editAddressFormData?.placeName,
      line1: editAddressFormData?.line1,
      line2: editAddressFormData?.line2,
      suburb: editAddressFormData?.suburb,
      googleAddress: editAddressFormData?.googleAddress,
      googleResponse: editAddressFormData?.googleResponse,
      city: editAddressFormData?.city,
      postCode: editAddressFormData?.postCode,
      addressType: editAddressFormData?.addressType,
      stateId: editAddressFormData?.stateId,
      countryId: editAddressFormData?.countryId,
      stateName: editAddressFormData?.stateName,
      countryName: editAddressFormData?.countryName,
      name: editAddressFormData?.name,
      contactNumber: editAddressFormData?.contactNumber,
      isManual: editAddressFormData?.isManual,
      isActive: editAddressFormData?.isActive
    };
    setAddressForm([...addressForm, {
      prevValue: updateData,
      value: updateData
    }]);
  };

  const addNewForm = () => {
    let data;
    if(isAddress) {
      data = {
        placeName: '',
        line1: '',
        line2: '',
        suburb: '',
        googleAddress: '',
        googleResponse: '',
        city: '',
        postCode: '',
        addressType: 'Main',
        stateName: '',
        countryName: '',
        contactNumber: '',
        isManual: false,
        isActive: true
      };
      setAddressForm([...addressForm, {
        prevValue: null,
        value: data
      }]);

      isOneAddress && setIsEnabledAdd(false);
    }
    else {
      data = {
        contactName: '',
        organization: '',
        phone1: '',
        phone2: '',
        email: '',
        notes: '',
        contactType: contactType && contactType.length > 0 ? contactType[0].value : '',
        isActive: true
      };
      setContactForm([...contactForm, {
        prevValue: null,
        value: data
      }]);
    }
    isOneAddress && setIsEnabledAdd(false);
  };

  const handleAddressCancel = (index: number) => {
    if(addressForm[index].prevValue) {
      addressFormData[index] = addressForm[index].prevValue;
      onStructureAddressChange && onStructureAddressChange([...addressFormData]);
      handleAddressCreate && handleAddressCreate(index);
    }
    setIsEnabledAdd(true);
    addressFormData.splice(index, 1);
    addressForm.splice(index, 1);
    setAddressForm([...addressForm]);
  };

  const handleContactCancel = (index: number) => {
    if(contactForm[index].prevValue) {
      contactFormData[index] = contactForm[index].prevValue;
      onContactChange && onContactChange([...contactFormData]);
      handleContactCreate && handleContactCreate(index);
    }
    contactFormData.splice(index, 1);
    contactForm.splice(index, 1);
    setContactForm([...contactForm]);
  };

  const addressRequiredCheck = (index: number) => {
    let isValid;
    if(addressForm[index].value.isManual){
      const filteredFields = addressRequiredFields.filter(field => 
        isEmptyString(addressForm[index].value[field])
      );
      isValid = filteredFields.length === 0;
    } else {
      isValid = !isEmptyString(addressForm[index].value['googleAddress']);
    }
    return isValid;
  };

  const [addressTypeData] = useState(ADDRESS_CONTACT_TYPES);

  return (
    <RoleBox>
      <Grid container>
        <Grid container justifyContent="space-between" mx={1}>
          <Grid>
            <StyledTypography variant={TypographyVariantProps.H6}>{heading}</StyledTypography>
          </Grid>
          {isEnabledAdd &&
            <StructureAddressAddGrid>
              <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} onClick={() => {addNewForm();}}>
                Add
              </Button>
            </StructureAddressAddGrid>
          }
        </Grid>
        <TableGridContainer>
          <Table size="small">
            {isAddress ? (
              <TableBody>
                {addressDataset?.filter(data=>data?.isActive)?.map((data, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="left">
                      <Typography variant={TypographyVariantProps.H6}>{data?.addressType}</Typography>
                      {`${data?.line1 || ''}, ${data?.line2 ? data?.line2.concat(',') : ''} ${data?.suburb || ''}, ${data?.city ? data?.city.concat(',') : ''} ${data?.postCode || ''}, ${data?.stateName || ''}, ${data?.countryName || ''} ${data?.contactNumber ? ', '.concat(data?.contactNumber) : ''}`}    
                    </TableCell>
                    <TableCell align="right" width="5%">
                      <IconButton aria-label="pdf" color={IconButtonColor.Primary} onClick={(e) => onClickAddressEdit && onClickAddressEdit(e, index)}>
                        <Edit/>
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" width="10%">
                      <IconButton aria-label="pdf" color={IconButtonColor.Error} onClick={(e) => {
                        onClickAddressDelete && onClickAddressDelete(e, index); 
                        setIsEnabledAdd(true);
                      }}
                      >
                        <Delete/>
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {contactDataset?.filter(data=>data?.isActive)?.map((data, index) => (
                  <StyledTableRow key={index}>
                    <TableCell align="left">
                      <b>{data?.contactType}</b>&nbsp;      
                      {[
                        data?.contactName,
                        data?.organization,
                        data?.phone1,
                        data?.email
                      ].filter(Boolean).join(', ')}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      <IconButton aria-label="pdf" color={IconButtonColor.Primary} onClick={(e) => onClickContactEdit && onClickContactEdit(e, index)}>
                        <Edit/>
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" width="10%">
                      <IconButton aria-label="pdf" color={IconButtonColor.Error} onClick={(e) => onClickContactDelete && onClickContactDelete(e, index)}>
                        <Delete/> 
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableGridContainer>
        {isAddress && addressForm?.map((data,index) => (
          <TableGridContainer key={index} pl={1} pr={1} mb={1}>
            <StructureAddress
              switchLabel="Manual"
              addressData={data.value}
              onChange={(updatedData) => { 
                addressFormData[index] = updatedData;
                addressForm[index].value = updatedData;
                setAddressForm([...addressForm]);
                onStructureAddressChange && onStructureAddressChange([...addressFormData]);
              }}
              addressTypeData={addressTypeData}
              enableContactNumber={enableContactNumber}
            />
            <Grid container justifyContent="flex-end" spacing={2} mr={3}>
              <Grid item ml="16px">
                <Button variant={ButtonVariantProps.Secondary} onClick={() => handleAddressCancel(index)}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button variant={ButtonVariantProps.Primary} onClick={() => {
                  if(addressRequiredCheck(index)){
                    handleAddressCreate && handleAddressCreate(index);
                    const reducedArr = [...addressForm];   
                    reducedArr.splice(index, 1);
                    setAddressForm([...reducedArr]);
                  }
                                     
                }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </TableGridContainer>
        ))}
        {isAddress || contactForm?.map((data,index) => (
          <TableGridContainer key={index} pl={1} pr={1} mb={1}>
            <Contact
              contactData={data.value}
              contactType={contactType}
              onChange={(updatedData) => { 
                contactFormData[index] = updatedData;
                contactForm[index].value = updatedData;
                setContactForm([...contactForm]);
                onContactChange && onContactChange([...contactFormData]);
              }} 
              renderNotes={true} />
            <Grid container justifyContent="flex-end" spacing={2} mr={3}>
              <Grid item ml="16px">
                <Button variant={ButtonVariantProps.Secondary} onClick={() => handleContactCancel(index)}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button variant={ButtonVariantProps.Primary} onClick={() => {
                  handleContactCreate && handleContactCreate(index);
                  const reducedArr = [...contactForm];   
                  reducedArr.splice(index, 1);
                  setContactForm([...reducedArr]);                 
                }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </TableGridContainer>
        ))}
      </Grid>
    </RoleBox>
  );
};