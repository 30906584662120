import React, { SyntheticEvent, useState } from 'react';
import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { CloseRounded, Delete } from '@mui/icons-material';
import { BillOfMaterialsProps } from './BillOfMaterials.props';
import { StyledGreyTextField, StyledGridContainer, StyledTableCell } from './BillOfMaterials.styles';
import { Accordian } from '../../atoms/Accordian';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { BillOfMaterialType, PartTypeSearchData } from '../../../@types';
import { useSearchChildPartTypesByClientAndContract } from '../../../queries/live-search-query';
import { getSerialised } from '../../../utils/part-type';

export const BillOfMaterials: React.FC<BillOfMaterialsProps> = ({
  billOfMaterialsData,
  parentPartTypeApplicableNodeIds,
  parentPartTypeId,
  isSerialised,
  onSave,
  onChange,
  onDelete
}) => {
  const [formData, setFormData] = useState<BillOfMaterialType[]>([]);
  const [textBoxClick, setTextBoxClick] = useState<boolean>(false);

  const searchChildPartTypesByClientAndContract = useSearchChildPartTypesByClientAndContract(isSerialised);
  
  const addNewForm = (event: SyntheticEvent) => {
    event.stopPropagation();
    setFormData([...formData, { 
      childPartTypeName: '',
      serialised: '',
      quantity: 0
    }
    ]);
  };
  
  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Bill of Materials</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={(e) => addNewForm(e)}>
            Add
          </Button>              
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <StyledGridContainer container>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Child Part Type</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billOfMaterialsData?.map((data, index) => 
                <TableRow key={index}>
                  <StyledTableCell width="40%">
                    <StyledGreyTextField
                      fullWidth
                      disabled
                      value={data.childPartTypeName}
                    />
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    {data.serialised}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      required
                      type={TextFieldTypeProps.Number}
                      placeholder="Quantity *"
                      value={data.quantity}
                      inputProps={{ min: 1 }}
                      onChange={(quantity) => {
                        const newData = billOfMaterialsData.map((billOfMaterial, i) => index === i ? { ...billOfMaterial, quantity: parseInt(quantity) } : billOfMaterial);
                        onChange(newData);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => onSave(data, false)} >
                      Save
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton aria-label="pdf" color="error" onClick={() => {
                      const newData = billOfMaterialsData.find((obj) => data.childPartTypeId === obj.childPartTypeId);
                      newData && onDelete(newData);
                    }}>
                      <Delete/> 
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              )}
              {formData?.map((data, index) => 
                <TableRow key={index}>
                  <StyledTableCell width="40%">
                    <LiveSearchBox
                      title="Child Part Type *"
                      timeOffset={400}
                      value={data.childPartTypeName || ''}
                      onClearValue={() => {
                        const newData = formData.map((item, i) => index === i ? { ...item, childPartTypeId: NaN, childPartTypeName: '' } : item);
                        setFormData(newData);
                      }}
                      renderItem={(props: any, option: any) => { 
                        return (
                          <LiveSearchListItem {...props}>
                            <LocationSearchItem data={option} />
                          </LiveSearchListItem>
                        );
                      }}
                      onClick={() => setTextBoxClick(true)}
                      onChange={(partTypeSearchData: PartTypeSearchData) => {
                        const newData = formData.map((item, i) => index === i ? { ...item, childPartTypeId: partTypeSearchData.id, childPartTypeName: partTypeSearchData.name, serialised: getSerialised(partTypeSearchData.categoryCode) } : item);
                        setFormData(newData);
                      }}
                      onApiInvoke={async (name: string) => {
                        if (textBoxClick && name) {
                          const childPartTypes = await searchChildPartTypesByClientAndContract.mutateAsync({
                            name: name,
                            applicableNodeIds: parentPartTypeApplicableNodeIds
                          });

                          return childPartTypes.filter((childPartType) => childPartType.id !== parentPartTypeId);
                        } else {
                          return [];
                        }
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell width="10%">
                    {data.serialised}
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      required
                      type={TextFieldTypeProps.Number}
                      placeholder="Quantity *"
                      value={data.quantity}
                      inputProps={{ min: 1 }}
                      onChange={(quantity) => {
                        const newData = formData.map((formDataItem, i) => index === i ? { ...formDataItem, quantity: parseInt(quantity) } : formDataItem);
                        setFormData(newData);
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={async () => {
                      const response: any = await onSave(data, true);

                      if (response !== 'error'){
                        const newData = formData.filter((obj, i) => index !== i);
                        setFormData(newData);
                      }
                    }}>
                      Save
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton color="error" onClick={() => {
                      const newData = formData.filter((obj, i) => index !== i);
                      setFormData(newData);
                    }}>
                      <CloseRounded /> 
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGridContainer>
    </Accordian>
  );
};
