import React from 'react';
import { ServiceCreationFormBuilderMenu } from '../../../../../components/molecules/ServiceCreationFormBuilderMenu';
import { useGetServiceTemplates } from '../../../../../queries/service-template-query';
import { ServiceCreationFormBuilderFieldFragmentProps } from './ServiceCreationFormBuilderFieldFragment.props';

export const ServiceCreationFormBuilderFieldFragment: React.FC<ServiceCreationFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const getServiceTemplatesQuery = useGetServiceTemplates();

  return (
    <ServiceCreationFormBuilderMenu
      error={showErrors}
      options={getServiceTemplatesQuery.data || []}
      value={preSelectedData}
      onChange={onPreSelectedData}
    />
  );
};
