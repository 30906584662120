import React from 'react';
import { IconButton } from '@mui/material';
import { PictureAsPdfOutlined, CloudDownloadOutlined, ArchiveOutlined, ImageOutlined, InsertDriveFileOutlined, AttachFile } from '@mui/icons-material';
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AttachmentListProps, FileUploadActionType } from './Attachments.props';
import { SmallLabel, BoldLabel, GridContainer, GridHeader, StackContainer, SmallAttachmentLabel, StyledIconButton } from './Attachments.styles';
import { UploadButton } from '../../molecules/UploadButton';
import { formatDate } from '../../../utils/common';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { MobileDataGridItem } from '../MobileDataGridItem';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';

export const Attachments: React.FC<AttachmentListProps> = ({
  rows,
  maxFileSize,
  fileTypes,
  isMobile,
  onClick,
  setFileDetails,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'fileName',
      headerName: 'Filename',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.fileName}</SmallLabel>
    },
    {
      field: 'createdBy',
      headerName: 'User',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{params.row.createdBy}</SmallLabel >
    },
    { 
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <SmallLabel>{formatDate(params.row.createdAt)}</SmallLabel>
    },
    {
      field: 'view',
      headerName: 'View',
      align: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          size="small"
          aria-label="view"
          color="primary"
          onClick={() =>
            onClick(params.row.id, FileUploadActionType.View, params.row.mimeType)
          }
        >
          {params.row.mimeType === 'application/pdf' && <PictureAsPdfOutlined />}
          {params.row.mimeType.startsWith('image/') && <ImageOutlined />}
          {params.row.mimeType.startsWith('video/') && <VideoFileOutlinedIcon />}
          {params.row.mimeType.startsWith('application/vnd.ms-excel') || (params.row.mimeType.startsWith('application/vnd.openxmlformats') && (<InsertDriveFileOutlined fontSize="small" />))}
        </IconButton>
      ),
    },
    {
      field: 'download',
      headerName: 'Download',
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          size="small"
          aria-label="download"
          color="primary"
          onClick={() => onClick(params.row.id, FileUploadActionType.Download, '')}
        >
          <CloudDownloadOutlined fontSize="small" />
        </IconButton>
      ),
    },
    {
      field: 'archive',
      headerName: 'Archive',
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          size="small"
          aria-label="archived"
          color="error"
          onClick={() => onClick(params.row.id, FileUploadActionType.Archive, '')}
        >
          <ArchiveOutlined fontSize="small" />
        </IconButton>
      ),
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem
            data={[
              {
                title: 'Filename',
                value: <SmallAttachmentLabel>{row.fileName}</SmallAttachmentLabel>
              },
              {
                title: 'View',
                value: 
                  <StyledIconButton
                    size="small"
                    aria-label="view"
                    color="primary"
                    onClick={() =>
                      onClick(row.id, FileUploadActionType.View, row.mimeType)
                    }
                  >
                    {row.mimeType === 'application/pdf' && <PictureAsPdfOutlined />}
                    {row.mimeType.startsWith('image/') && <ImageOutlined />}
                    {row.mimeType.startsWith('video/') && <VideoFileOutlinedIcon />}
                    {row.mimeType.startsWith('application/vnd.ms-excel') || (row.mimeType.startsWith('application/vnd.openxmlformats') && (<InsertDriveFileOutlined fontSize="small" />))}
                  </StyledIconButton>
              },
              {
                title: 'User',
                value: <SmallAttachmentLabel>{row.createdBy}</SmallAttachmentLabel>
              },
              {
                title: 'Download',
                value: 
                  <StyledIconButton
                    size="small"
                    aria-label="download"
                    color="primary"
                    onClick={() => onClick(row.id, FileUploadActionType.Download, '')}
                  >
                    <CloudDownloadOutlined fontSize="small" />
                  </StyledIconButton>
              },
              {
                title: 'Date',
                value: <SmallAttachmentLabel>{formatDate(row.createdAt)}</SmallAttachmentLabel>
              },
              {
                title: 'Archive',
                value:  
                  <StyledIconButton
                    size="small"
                    aria-label="archived"
                    color="error"
                    onClick={() => onClick(row.id, FileUploadActionType.Archive, '')}
                  >
                    <ArchiveOutlined fontSize="small" />
                  </StyledIconButton>
              }
            ]}
          />
        );
      },
      sortable: false,
      filterable: false
    }
  ];

  return (
    <>
      <GridHeader>
        <BoldLabel>{`Attachments [${rows.length}]`}</BoldLabel>
        <StackContainer>
          <UploadButton
            maxFileSize={maxFileSize}
            fileTypes={fileTypes}
            setFileDetails={setFileDetails}
            label="Upload Files"
            icon={<AttachFile fontSize="small" />}
          />
        </StackContainer>
      </GridHeader>
      <GridContainer isMobile={isMobile} rowLength={rows.length}>
        <CustomDataGrid
          columns={columns}
          rows={rows}
          getRowId={(row) => row?.id}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false,
              hideFooter: true,
            },
          }}
          isMobile={isMobile}
          enableCSVExport={false}
          exportFileName={''}
          getRowHeight={() => 'auto'}
        />
      </GridContainer>
    </>
  );
};