export interface WarrantyPanalType {
  id: number;
  dateApplied: Date;
  warrantyName: string;
  startDate: string;
  endDate: string;
  isExpired: boolean;
}

export enum WarrantyPanelStatuses {
  Active = 'Active',
  Expired = 'Expired'
}