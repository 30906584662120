import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import {
  ContactLayoutBox,
  StructureAddressGridContainer,
  StyledTypography,
  TableGridContainer
} from './ServiceContactLayout.styles';
import { ServiceContactLayoutProps } from './ServiceContactLayout.props';
import { TypographyVariantProps } from '../../atoms/Typography';
import { Contact } from '../../molecules/Contact';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { IconButtonColor } from '../../../configs/enums';
import { StyledTableRow } from '../StructureAddressContactLayout/StructureAddressContactLayout.styles';

export const ServiceContactLayout: React.FC<ServiceContactLayoutProps> = ({
  contactDataset,
  contactFormData,
  editContactFormData,
  isService,
  handleContactCreate,
  onContactChange,
  onClickContactEdit,
  onClickContactDelete
}) => {
  const [contactForm, setContactForm] = useState<any[]>([]);

  useEffect(() => {
    const contactList = contactForm.map(data => data.value);
    onContactChange && onContactChange([...contactList]);
  }, [contactForm]);

  useEffect(() => {
    editContactFormData && editContactForm();
  }, [editContactFormData]);
  
  const editContactForm = () => {
    const updateData = {
      id: editContactFormData?.id,
      contactName: editContactFormData?.contactName,
      organization: editContactFormData?.organization,
      phone1: editContactFormData?.phone1,
      phone2: editContactFormData?.phone2,
      email: editContactFormData?.email,
      notes: editContactFormData?.notes,
      contactType: editContactFormData?.contactType,
      isActive: editContactFormData?.isActive
    };

    setContactForm([...contactForm, {
      prevValue: updateData,
      value: updateData
    }]);
  };

  const addNewForm = () => {
    const data = {
      contactName: '',
      organization: '',
      phone1: '',
      phone2: '',
      email: '',
      notes: '',
      contactType: 'Service',
      isActive: true
    };
    setContactForm([...contactForm, {
      prevValue: null,
      value: data
    }]);

  };

  const handleContactCancel = (index: number) => {
    if(contactForm[index].prevValue) {
      contactFormData[index] = contactForm[index].prevValue;
      onContactChange && onContactChange([...contactFormData]);
      handleContactCreate && handleContactCreate(index);
    }
    contactFormData.splice(index, 1);
    contactForm.splice(index, 1);
    setContactForm([...contactForm]);
  };
  
  return (
    <ContactLayoutBox>
      <StructureAddressGridContainer container>
        <Grid container justifyContent="space-between" mx={1}>
          <Grid>
            <StyledTypography variant={TypographyVariantProps.Body1}>{isService ? 'Service Contacts' : 'Contacts'}</StyledTypography>
          </Grid> 
          <Grid>
            <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} onClick={() => {addNewForm();}}>
              Add
            </Button>
          </Grid>
        </Grid>
        <TableGridContainer>
          <Table size="small">
            <TableBody>
              {contactDataset?.filter(data => data?.isActive)?.map((data, index) => (
                <StyledTableRow
                  key={index}
                >
                  <TableCell align="left">
                    <b>{data?.contactType}</b>&nbsp;      
                    {[
                      data?.contactName,
                      data?.organization,
                      data?.phone1,
                      data?.email
                    ].filter(Boolean).join(', ')}
                  </TableCell>
                  <TableCell align="center" width="10%">
                    <IconButton aria-label="pdf" color={IconButtonColor.Primary} onClick={(e) => onClickContactEdit && onClickContactEdit(e, index)}>
                      <Edit/>
                    </IconButton>
                  </TableCell>
                  {!data?.id &&
                    <TableCell align="center" width="10%">
                      <IconButton aria-label="pdf" color={IconButtonColor.Error} onClick={(e) => onClickContactDelete && onClickContactDelete(e, index)}>
                        <Delete/> 
                      </IconButton>
                    </TableCell>
                  }
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableGridContainer>
        {contactForm?.map((data,index) => {
          return <TableGridContainer key={index} p={1}>
            <Contact
              hideContactType={true}
              contactData={data.value}
              onChange={(updatedData) => { 
                contactFormData[index] = updatedData;
                contactForm[index].value = updatedData;
                setContactForm([...contactForm]);
                onContactChange && onContactChange([...contactFormData]);
              }} 
              renderNotes={true} />
            <Grid container justifyContent="flex-end" spacing={2} mr={3}>
              <Grid item ml="16px">
                <Button variant={ButtonVariantProps.Secondary} onClick={() => handleContactCancel(index)}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button variant={ButtonVariantProps.Primary} onClick={() => {
                  handleContactCreate && handleContactCreate(index);
                  const reducedArr = [...contactForm];   
                  reducedArr.splice(index, 1);
                  setContactForm([...reducedArr]);                 
                }}>
                  {data.prevValue ? 'Save Contact' : 'Add Contact'}
                </Button>
              </Grid>
            </Grid>
          </TableGridContainer>;
        })}
      </StructureAddressGridContainer>
    </ContactLayoutBox>
  );
};