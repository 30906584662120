import React, { useState, useEffect } from 'react';
import { SiteSchemaItemRowFragmentProps } from './SiteSchemaItemRowFragment.props';
import { SiteSchemaItemRow } from '../../../../../components/organisms/SiteSchemaItemRow';
import { DropdownItem } from '../../../../../components/atoms/SelectMenu';
import { useGetSoftwareFirmwareHardwareOptions } from '../../../../../queries/part-type-query';
import { useSearchClientContractPartTypes } from '../../../../../queries/live-search-query';
import { useGetParentAttributesByPropertyType } from '../../../../../queries/structure-query';
import { NodeAttributeType } from '../../../../../configs/enums';
import { getFleetTagOptions } from '../../../../../utils/structure';
import { useGetServiceTypes } from '../../../../../queries/service-type-query';

export const SiteSchemaItemRowFragment: React.FC<SiteSchemaItemRowFragmentProps> = ({
  isNew,
  nodeId,
  value,
  onChange,
  onCancel
}) => {
  const anyItem: Array<DropdownItem> = [{ label: 'Any', value: 'any' }];
  const [serviceTypeOptions, setServiceTypeOptions] = useState<Array<DropdownItem>>([]);
  const [softwareVersionOptions, setSoftwareVersionOptions] = useState<Array<DropdownItem>>([]);
  const [firmwareVersionOptions, setFirmwareVersionOptions] = useState<Array<DropdownItem>>([]);
  const [hardwareVersionOptions, sethHardwareVersionOptions] = useState<Array<DropdownItem>>([]);
  const [fleetTags, setFleetTags] = useState<Array<DropdownItem>>([]);

  const searchClientContractPartTypes = useSearchClientContractPartTypes();
  const getServiceTypes = useGetServiceTypes();
  const getSoftwareFirmwareHardwareOptions = useGetSoftwareFirmwareHardwareOptions(value.partTypeId);
  const getFleetTags = useGetParentAttributesByPropertyType(nodeId.toString(), NodeAttributeType.FleetTag);

  useEffect(() => {
    value.partTypeId > 0 && getSoftwareFirmwareHardwareOptions.refetch();
  }, [value.partTypeId]);

  useEffect(() => {
    nodeId && getFleetTags.refetch();
  }, [nodeId]);

  useEffect(() => {
    if (getServiceTypes.data) {
      const serviceOptions = getServiceTypes.data.map((ServiceType) => {
        const option: DropdownItem = { label: ServiceType.name, value: ServiceType.code, disabled: !ServiceType.isActive };
        return option;
      });
      setServiceTypeOptions(serviceOptions);
    }
  }, [getServiceTypes.data]);

  useEffect(() => {
    if (getSoftwareFirmwareHardwareOptions.data) {
      const softwaresOptions = getSoftwareFirmwareHardwareOptions.data.softwares.map((obj) => {
        const option: DropdownItem = { label: obj.version, value: obj.version };
        return option;
      });
      setSoftwareVersionOptions(anyItem.concat(softwaresOptions));

      const firmwareOptions = getSoftwareFirmwareHardwareOptions.data.firmwares.map((obj) => {
        const option: DropdownItem = { label: obj.version, value: obj.version };
        return option;
      });
      setFirmwareVersionOptions(anyItem.concat(firmwareOptions));

      const hardwareOptions = getSoftwareFirmwareHardwareOptions.data.hardwares.map((obj) => {
        const option: DropdownItem = { label: obj.version, value: obj.version };
        return option;
      });
      sethHardwareVersionOptions(anyItem.concat(hardwareOptions));
    }
  }, [getSoftwareFirmwareHardwareOptions.data]);

  useEffect(() => {
    if(getFleetTags.data) {
      const fleetTagOptions = getFleetTagOptions(getFleetTags.data);
      setFleetTags(anyItem.concat(fleetTagOptions));
    }
  },[getFleetTags.data]);

  return (
    <SiteSchemaItemRow 
      isNew={isNew}
      value={value} 
      serviceTypeOptions={serviceTypeOptions} 
      softwareVersionOptions={softwareVersionOptions} 
      firmwareVersionOptions={firmwareVersionOptions} 
      hardwareVersionOptions={hardwareVersionOptions} 
      fleetTagOptions={fleetTags} 
      onChange={onChange} 
      onPartTypeSearchApiInvoke={async (obj) => {
        const response = await searchClientContractPartTypes.mutateAsync({
          name: obj,
          applicableNodeIds: `${nodeId}`
        });
        return response;
      }}
      onCancel={onCancel}
    />
  );
};
