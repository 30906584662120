import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { SerialNumberPermissionGrantFragmentProps } from './SerialNumberPermissionGrantFragment.props';
import { BulkUpdateInputItems } from '../../../../components/molecules/BulkUpdateInputItems';
import { BulkUpdateInputItemType } from '../../../../@types/bulkUpdateInputItem.type';
import { SAMPLE_IS_CHECKED_VALUES_DATA, SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA } from '../../../../constants/part';
import { IsCheckedValuesType } from '../SerialisedPartsUpdateSelection.props';
import { LinkedListProperties } from '../../../../@types/bulkPartUpdate.type';
import { SerialisedPartsUpdateSelectionDataType } from '../../../../@types/part.type';

export const SerialNumberPermissionGrantFragment: React.FC<SerialNumberPermissionGrantFragmentProps> = ({
  linkedFieldValue,
  serialNumberPermissionUpdateValues
}) => {
  const [isCheckedValues, setIsCheckedValues] = useState<IsCheckedValuesType>(SAMPLE_IS_CHECKED_VALUES_DATA);
  const [serialNumberPermissionGrantValues, setSerialNumberPermissionGrantValues] = useState<SerialisedPartsUpdateSelectionDataType>(SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA);

  useEffect(() => {
    serialNumberPermissionUpdateValues(serialNumberPermissionGrantValues);
  }, [serialNumberPermissionGrantValues]);

  useEffect(() => {
    switch(linkedFieldValue) {
      case LinkedListProperties.Serial1:
        setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial1: '' });
        setIsCheckedValues({ ...isCheckedValues, isCheckedSerial1: false });
        break;
      case LinkedListProperties.Serial2:
        setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial2: '' });
        setIsCheckedValues({ ...isCheckedValues, isCheckedSerial2: false });
        break;
      case LinkedListProperties.Serial3:
        setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial3: '' });
        setIsCheckedValues({ ...isCheckedValues, isCheckedSerial3: false });
        break;
    }
  }, [linkedFieldValue]);

  return (
    <Stack>
      <Stack direction="row" mb={3} spacing={1.4}>
        <BulkUpdateInputItems
          isChecked={isCheckedValues.isCheckedSerial1}
          label="Serial 1"
          itemInputValue={serialNumberPermissionGrantValues?.serial1 || ''}
          inputItemType={BulkUpdateInputItemType.TextInput}
          disabled={linkedFieldValue === LinkedListProperties.Serial1}
          onChangeCheckbox={(value) => {
            setIsCheckedValues({ ...isCheckedValues, isCheckedSerial1: value });
            setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial1: '' });
          }}
          onChangeItemInputValue={(value) => setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial1: value })}
        />
        <BulkUpdateInputItems
          isChecked={isCheckedValues.isCheckedSerial2}
          label="Serial 2"
          itemInputValue={serialNumberPermissionGrantValues?.serial2 || ''}
          inputItemType={BulkUpdateInputItemType.TextInput}
          disabled={linkedFieldValue === LinkedListProperties.Serial2}
          onChangeCheckbox={(value) => {
            setIsCheckedValues({ ...isCheckedValues, isCheckedSerial2: value });
            setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial2: '' });
          }}
          onChangeItemInputValue={(value) => setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial2: value })}
        />
        <BulkUpdateInputItems
          isChecked={isCheckedValues.isCheckedSerial3}
          label="Serial 3"
          itemInputValue={serialNumberPermissionGrantValues?.serial3 || ''}
          inputItemType={BulkUpdateInputItemType.TextInput}
          disabled={linkedFieldValue === LinkedListProperties.Serial3}
          onChangeCheckbox={(value) => {
            setIsCheckedValues({ ...isCheckedValues, isCheckedSerial3: value });
            setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial3: '' });
          }}
          onChangeItemInputValue={(value) => setSerialNumberPermissionGrantValues({ ...serialNumberPermissionGrantValues, serial3: value })}
        />
      </Stack>
    </Stack>
  );
};