import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import { DispatchNSInputPopupProps } from '.';
import { Modal } from '../../atoms/Modal';
import { HeadingWrapper, ModalContent, ModalFooter, StyledBox, StyledModalBox, StyledStack } from './DispatchNSInputPopup.styles';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { LocationNodeData } from '../../../@types';
import { getHasCodeForNodeSearch } from '../../../utils/services';
import { useSearchNodeByParentId } from '../../../queries/live-search-query';
import { Typography } from '../../atoms/Typography';
import { CloseIcon } from '../../atoms/CloseIcon';

export const DispatchNSInputPopup: React.FC<DispatchNSInputPopupProps> = ({
  sourceLocation,
  partTypeName,
  locationData,
  isOpen,
  sourceLocationName,
  onChange,
  onClick,
  onCancel,
  onClose
}) => {
  const [locationName, setLocationName] = useState('');

  useEffect(() => {
    setLocationName(sourceLocationName);
  }, [sourceLocationName]);

  const searchNodeByParentId = useSearchNodeByParentId();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <StyledModalBox>
        <CloseIcon handleClose={onClose} />
        <ModalContent>
          <StyledBox>
            <LiveSearchBox
              title="Scan source location"
              timeOffset={400}
              value={locationName}
              onClearValue={() => { 
                onChange({ id: sourceLocation });
                setLocationName('');
              }}
              renderItem={(props: any, option: any) => { 
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setLocationName(obj.name);
                onChange(obj);
              }}
              onApiInvoke={async (name: string) => {
                const hasCode = getHasCodeForNodeSearch(name);
                return await searchNodeByParentId.mutateAsync({ selectedParentId: sourceLocation, name, hasCode: hasCode, includeParentNode: true, includeSiblings: true });
              }}
            />
          </StyledBox>
          <HeadingWrapper>Move <b>{partTypeName}</b> from: </HeadingWrapper>
          {locationData.map((item, index) => 
            <StyledStack direction="row" width="auto" justifyContent="space-between" key={index} onClick={() => onClick(item)}>
              <Stack direction="column">
                <Typography>{item.locationName}</Typography>
                <Typography>{item.condition} | Qty: {item.quantity}</Typography>
              </Stack>
              <Stack alignSelf="flex-end">
                <Typography>{item.fleetTagName}</Typography>
              </Stack>
            </StyledStack>
          )}
        </ModalContent>
        <ModalFooter>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Error} onClick={onCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </StyledModalBox>
    </Modal>
  );
};