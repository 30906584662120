import { TableCell, styled } from '@mui/material';
import { Box } from '../../../../../components/atoms/Box';
import { Typography } from '../../../../../components/atoms/Typography';

export const StyledTableCell = styled(TableCell)`
  border: 0;
  vertical-align: middle;
`;

export const ContentWrapperBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 80%;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;

export const HeadingWrapper = styled(Typography)`
  display: flex;
  padding-bottom: 30px;
  justify-content: center;
`;

export const BoldLabel = styled(Typography)`
  text-align: center;
  padding: 0;
  font-weight: bold;
`;

export const Footer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
`;