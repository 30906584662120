import { SerialisationType, NodeAttributesReponse, DispatchDestinationNodeType, LocationNodeData } from '../@types';
import { SearchCode } from '../@types/part.type';
import { ManifestData, ShipmentStatus, CourierData, ShipmentManifestSuppliers, ManifestSerialObject, ShipmentBatchReceiveData, IndividualShipmentReceiveData } from '../@types/shipment.type';
import { AddStockManifestRowItemValueType } from '../components/organisms/AddStockManifestRowItem';
import { DispatchManifestRowItemValueType } from '../components/organisms/DispatchManifestRowItem';
import { DispatchManifestDataType } from '../components/templates/DispatchManifestList';
import { SerialisedType } from '../configs/enums';
import { formatDate, isEmptyString } from './common';
import { SelectedShipmentPackage } from '../components/molecules/ShipmentPackagesList/ShipmentPackagesList.props';
import { FreightPackageCriteria } from '../configs/enums';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { AddressDataType } from '../components/organisms/StructureAddress/StructureAddress.props';

export const hasErrorInAddStockDetails = (source: string, destination: string, clientHierarchyName: string) => {
  return isEmptyString(source) || isEmptyString(destination) || isEmptyString(clientHierarchyName);
};

export const getShipmentStatusList = (shipmentStatusList: Array<ShipmentStatus>) => {
  return shipmentStatusList.map((shipmentStatus: ShipmentStatus) => {
    return { value: shipmentStatus.code, label: shipmentStatus.name };
  });
};

export const commaSeparator = (value: string): string[] => {
  const optimizedValue = value.includes(',') ? value.split(/\s*,\s*/) : value.split('\n');
  return optimizedValue;
};

export const onChangeDataMapper = (formData: Array<AddStockManifestRowItemValueType>, value: AddStockManifestRowItemValueType): AddStockManifestRowItemValueType[] => {
  return formData?.map((addStockManifestFormDataItem: AddStockManifestRowItemValueType) => addStockManifestFormDataItem.id === value.id ? value : addStockManifestFormDataItem);
};

export const onChangeSerialisedDataMapper = (formData: Array<AddStockManifestRowItemValueType>, value: ManifestSerialObject) => {
  return formData?.map((addStockManifestFormDataItem: AddStockManifestRowItemValueType) => addStockManifestFormDataItem.id === value.id ? { ...addStockManifestFormDataItem, serials: value, total: value?.serialsDataSet?.length } : addStockManifestFormDataItem);
};

export const onChangeNonSerialisedDataMapper = (formData: Array<AddStockManifestRowItemValueType>, value: AddStockManifestRowItemValueType) => {
  return formData?.map((addStockManifestFormDataItem: AddStockManifestRowItemValueType) => addStockManifestFormDataItem.id === value.id ? { ...addStockManifestFormDataItem, attributes: value.attributes, quantity: value.quantity, additionalData: value.additionalData } : addStockManifestFormDataItem);
};

export const onCancelDataFilter = (formData: Array<AddStockManifestRowItemValueType>, selectedRowFormDataItemId?: number): AddStockManifestRowItemValueType[] => {
  return formData?.filter((filteredRowFormDataItem) => filteredRowFormDataItem.id !== selectedRowFormDataItemId || 0);
};

export const rowSerialisedDataMapper = (rowFormData: Array<AddStockManifestRowItemValueType>): AddStockManifestRowItemValueType[] => {
  return rowFormData?.filter((rowFormDataFilterItem: AddStockManifestRowItemValueType) =>
    rowFormDataFilterItem.serialisation === SerialisationType.Serialised
  );
};

export const rowNonSerialisedDataMapper = (rowFormData: Array<AddStockManifestRowItemValueType>): AddStockManifestRowItemValueType[] => {
  return rowFormData?.filter((rowFormDataFilterItem: AddStockManifestRowItemValueType) =>
    rowFormDataFilterItem.serialisation === SerialisationType.Consumable
  );
};

export const hasErrorInDispatchDetails = (source: string, destination: string) => {
  return isEmptyString(source) || isEmptyString(destination);
};

export const isEmptyManifests = (serializedArray: AddStockManifestRowItemValueType[], nonSerializedArray: AddStockManifestRowItemValueType[]) => {
  return serializedArray.length === 0 && nonSerializedArray.length === 0;
};

export const getCourierIds = (courierList: CourierData[]) => {
  const couriersArray = [];
  for (const courier of courierList) {
    couriersArray.push(courier.id);
  }
  return `[${couriersArray}]`;
};

export const getFreightIntegrationEnabledCouriers = (couriers: CourierData[], nodeAttributeData: NodeAttributesReponse[]) => {
  return couriers.reduce((accumulator: CourierData[], currentValue: CourierData) => {
    if (nodeAttributeData.filter((item) => item.value.freightIntegrationEnabled).find((value) => value.nodeId === currentValue.id)) {
      return [...accumulator, currentValue];
    }
    return accumulator;
  }, []);
};

export const getShipmentPackageCriteriaIndex = (selectedPackages: SelectedShipmentPackage[], id: number) => {
  const packageList = [...selectedPackages];
  const updatedRowIndex = packageList.findIndex((item) => item.id === id);
  return updatedRowIndex;
};

export const getUpdatedShipmentPackageCriteriaList = (selectedPackages: SelectedShipmentPackage[], packageFeature: string, id: number, value: number) => {
  const packageList = [...selectedPackages];
  const updatedRowIndex = getShipmentPackageCriteriaIndex(selectedPackages, id);
  switch (packageFeature) {
    case FreightPackageCriteria.Length:
      packageList[updatedRowIndex].length = value;
      break;
    case FreightPackageCriteria.Width:
      packageList[updatedRowIndex].width = value;
      break;
    case FreightPackageCriteria.Height:
      packageList[updatedRowIndex].height = value;
      break;
    case FreightPackageCriteria.Quantity:
      packageList[updatedRowIndex].quantity = value;
      break;
    case FreightPackageCriteria.Weight:
      packageList[updatedRowIndex].weight = value;
      break;
  }
  return packageList;
};

export const getHasCodeForPartSearch = (name: string) => {
  return name.toUpperCase().startsWith(SearchCode.AM_PID);
};

export const getHasCodeForPartTypeSearch = (name: string) => {
  return name.toUpperCase().startsWith(SearchCode.AM_PTID);
};

export const getHasCodeForSHashSearch = (name: string) => {
  return name.toUpperCase().startsWith(SearchCode.S_HASH);
};


export const manifestDataMapper = (value: DispatchManifestDataType) => {
  const serialisedData = value.serialisedManifestData;
  const nonSerializedData = value.nonSerializedManifestData;
  const manifest: ManifestData[] = [];
  serialisedData?.forEach((serialisedItem) => {
    if (serialisedItem.serialisation !== SerialisedType.Unknown)
      serialisedItem.items?.forEach((item) => {
        const data = {
          partId: item.partId,
          conditionCode: item.conditionCode,
          fleetTags: item.fleetTag,
          fleetTagId: item.fleetTagId,
          softwareVersion: item.softwareVersionId,
          hardwareRevision: item.hardwareVersionId,
          firmwareVersion: item.firmwareVersionId,
          locationId: item.locationId,
          selected: item.selected
        };
        manifest.push(data);
      });
  });
  nonSerializedData?.forEach((nonSerialisedItem) => {
    nonSerialisedItem.items?.forEach((item) => {
      const data = {
        ns_part_id: item.ns_part_id,
        partTypeId: nonSerialisedItem.partTypeId,
        conditionCode: item.conditionCode,
        fleetTags: item.fleetTag,
        fleetTagId: item.fleetTagId,
        qty: nonSerialisedItem.quantity || 0,
        selected: item.selected,
        locationId: item.locationId,
        partTypeCategoryCode: nonSerialisedItem.partTypeCategoryCode
      };
      manifest.push(data);
    });
  });
  return manifest;
};

export const onChangeDispatchDataMapper = (formData: Array<DispatchManifestRowItemValueType>, value: DispatchManifestRowItemValueType, index: number): DispatchManifestRowItemValueType[] => {
  return formData?.map((dispatchManifestItem: DispatchManifestRowItemValueType, id: number) => id === index ? value : dispatchManifestItem);
};

// generate url for dispatch page redirection from service edit shipment panel
export const genarateNewLinkShipmentURL = (
  serviceId: number,
  locationName?: string,
  locationId?: number,
  isFromServiceEdit?: boolean
) => {
  let url = `/shipments/dispatch/create?serviceId=${serviceId}&locationId=${locationId}&locationName=${locationName}`;
  if (isFromServiceEdit) {
    url += '&isFromServiceEdit=true';
  }
  return url;
};

export const getShipmentSupplierPricings = (supplierPricings: Array<ShipmentManifestSuppliers>, partTypeId: number) => {
  const supplierPricingsDropdown: DropdownItem[] = [
    {
      value: 'addNewPricing',
      label: 'Add New Pricing',
      navigateLink: `/configure/partTypes/${partTypeId}#supplierPricing`
    }
  ];

  supplierPricings.map((supplierPricing: ShipmentManifestSuppliers) => {
    supplierPricingsDropdown.push({
      value: supplierPricing.id.toString(),
      label: '',
      supplierLabel: {
        id: supplierPricing.id,
        supplierName: supplierPricing.supplierName,
        pricePerPackage: supplierPricing.pricePerPackage,
        supplierPartTypeName: supplierPricing.supplierPartTypeName,
        supplierPartTypeNumber: supplierPricing.supplierPartTypeNumber,
        lastUpdated: formatDate(supplierPricing.lastUpdated),
        itemPrice: supplierPricing.itemPrice,
      }
    });
  });

  return supplierPricingsDropdown;
};

export const updatePutawayLocation = (batchReceiveData: ShipmentBatchReceiveData, putawayLocationId: number, putawayLocationName: string) => {
  const serializedShipmentManifestItems = batchReceiveData?.serializedShipmentManifestItem;
  serializedShipmentManifestItems?.forEach(item => {
    item.putAwayLocationId = putawayLocationId;
    item.putAwayLocationName = putawayLocationName;
  });

  const nonSerializedShipmentManifestItems = batchReceiveData?.nonSerializedShipmentManifestItem;
  nonSerializedShipmentManifestItems?.forEach(item => {
    item.putAwayLocationId = putawayLocationId;
    item.putAwayLocationName = putawayLocationName;
  });

  return { 
    ...batchReceiveData,
    serializedShipmentManifestItem: serializedShipmentManifestItems,
    nonSerializedShipmentManifestItem: nonSerializedShipmentManifestItems
  };
};

export const updatePutawayLocationForIndividualShipment = (batchReceiveData: IndividualShipmentReceiveData, putawayLocationId: number, putawayLocationName: string) => {
  const serializedShipmentManifestItems = batchReceiveData?.serializedShipmentManifestItem;
  serializedShipmentManifestItems?.forEach(item => {
    item.putAwayLocationId = putawayLocationId;
    item.putAwayLocationName = putawayLocationName;
  });

  const nonSerializedShipmentManifestItems = batchReceiveData?.nonSerializedShipmentManifestItem;
  nonSerializedShipmentManifestItems?.forEach(item => {
    item.putAwayLocationId = putawayLocationId;
    item.putAwayLocationName = putawayLocationName;
  });

  return { 
    ...batchReceiveData,
    serializedShipmentManifestItem: serializedShipmentManifestItems,
    nonSerializedShipmentManifestItem: nonSerializedShipmentManifestItems,
    isBulkPutAway: true
  };
};

export const getUniqueLocationsForDispatchDestination = (searchString: string, searchData: LocationNodeData[], suggestedDestinations:DispatchDestinationNodeType[]) => {
  return searchData.filter((node) => node.name?.toLowerCase().includes(searchString.toLowerCase()) && suggestedDestinations.every((suggestedNode) => suggestedNode.id !== node.id));
};

export const getUniqueLocationsForReturn = (searchData: LocationNodeData[], suggestedLocations: LocationNodeData[]) => {
  return searchData.filter((node) => !suggestedLocations.some((suggestedLocation) => suggestedLocation.id === node.id));
};

export const checkIntegratedFreightErrors = (type: string, destinationAddress: AddressDataType, serviceType?: string, phoneNumber?: any, stateCode?: string) => {
  let missingFields = '';
  if (type === 'saveOrder') {
    if (!serviceType) missingFields += 'service type, ';
    if (!destinationAddress?.name && !destinationAddress?.placeName) missingFields += 'destination name, ';
    if (!destinationAddress?.contactNumber && !phoneNumber) missingFields += 'destination phone number, ';
    if (!destinationAddress?.line1 && !destinationAddress?.line2) missingFields += 'destination street(line1 or line2), ';
    if (!destinationAddress?.suburb) missingFields += 'destination suburb, ';
    if (!stateCode) missingFields += 'destination stateCode, ';
    if (!destinationAddress?.postCode) missingFields += 'destination postcode, ';
    if (!destinationAddress?.countryName) missingFields += 'destination country, ';
    if (missingFields !== '') return `${missingFields.slice(0,-2)} unavailable to save order`;
  } else if (type === 'quote') {
    if (!destinationAddress?.line1 && !destinationAddress?.line2) missingFields += 'destination street(line1 or line2), ';
    if (!destinationAddress?.suburb) missingFields += 'destination suburb, ';
    if (!destinationAddress?.city) missingFields += 'destination city, ';
    if (!stateCode) missingFields += 'destination stateCode, ';
    if (!destinationAddress?.postCode) missingFields += 'destination postcode, ';
    if (missingFields !== '') return `${missingFields.slice(0,-2)} unavailable to get order quote`;
  }
  return '';
};