export const MaxFileSize = 2000;
export const UploadedFilesKey = 'uploaded-files';
export const UploadMultipleImagesKey = 'upload-multiple-images';
export const UploadedImagesKey = 'uploaded-files';
export const UploadedImageEmptyKey = 'uploaded-file-empty';
export const ExportFilesKey = 'export-files';

export const ServiceAttachmentMimeTypes = {
  InteractionFileUpload: 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv',
  InteractionPhotoUpload: 'image/*',
  ReportedInfo: 'video/mp4,application/pdf,image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PartActionPartCreation: 'image/*'
};