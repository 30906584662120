import { AddStockManifestRowItemValueType } from '../components/organisms/AddStockManifestRowItem';
import { ShipmentBatchReceiveData } from '../@types/shipment.type';
import { ShipmentServiceAllocationData } from '../@types/shipment.type';
import { httpClient, HttpMethod } from './http-client';

export const createShipment = (shipment: any) => {
  return httpClient('/shipment', HttpMethod.Post, shipment);
};

export const updateShipment = (id: number, shipment: any) => {
  return httpClient(`/shipment/${id}`, HttpMethod.Put, shipment);
};

export const getShipments = (queryOptions: any, excludedColumns?: string) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/shipment?page=${page + 1}&limit=${pageSize}&excludedColumns=${excludedColumns}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getShipmentById = (id: number) => {
  return httpClient(`/shipment/${id}`, HttpMethod.Get, null);
};

export const getAllShipmentStatus = (): any => {
  return httpClient('/shipmentStatus', HttpMethod.Get, null);
};

export const getShipmentBatchReceive = (queryOptions: any) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/shipment/batch/receive?page=${page + 1}&limit=${pageSize}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getReturnShipmentSuggestions = (sourceId: number, destinationId: number) => {
  return httpClient(`/returnShipmentSuggestions/source/${sourceId}/destination/${destinationId}`, HttpMethod.Get, null);
};

export const getAllShipmentTypes = () => {
  return httpClient('/shipmentTypes', HttpMethod.Get, null);
};

export const getAllShipmentManifestsByShipmentId = (shipmentId: number) => {
  return httpClient(`/shipmentManifestList/${shipmentId}/manifestGroup`, HttpMethod.Get, null);
};

export const deleteSerializedManifests = (manifest: AddStockManifestRowItemValueType) => {
  return httpClient('/shipmentDeleteManifest', HttpMethod.Put, manifest);
};

export const deleteNonSerializedManifests = (shipmentId: number, manifestId: number) => {
  return httpClient(`/shipment/${shipmentId}/manifest/nonSerialized/${manifestId}`, HttpMethod.Delete, null);
};

export const createAddStockManifestShipment = (shipmentId: number, addStockManifest: any) => {
  return httpClient(`/shipment/${shipmentId}/addstock/manifest/bulk`, HttpMethod.Post, addStockManifest);
};

export const createFreshStockManifestShipment = (addStockManifest: any) => {
  return httpClient('/createFreshAddStockManifest', HttpMethod.Post, addStockManifest);
};

export const createNonSerializedManifestShipment = (shipmentId: number, nonSerializedManifest: any) => {
  return httpClient(`/shipment/${shipmentId}/nonSerialized/manifest/bulk`, HttpMethod.Post, nonSerializedManifest);
};

export const getSerialValues = (serialisedManifestDataItemValue: any) => {
  return httpClient('/getSerialValues', HttpMethod.Post, serialisedManifestDataItemValue);
};

export const deleteSerializedManifestById = (id: number) => {
  return httpClient(`/shipment/manifest/serialized/${id}`, HttpMethod.Delete, null);
};

export const getAllShipmentManifest = (partTypeId: number, nodeId: number) => {
  return httpClient(`/shipments/manifest/partTypes/${partTypeId}/node/${nodeId}`, HttpMethod.Get, null);
};

export const getShipmentBatchReceiveById = (batchId: number) => {
  return httpClient(`/shipments/batch/${batchId}/receive`, HttpMethod.Get, null);
};

export const createShipmentBatchReceive = (batchReceive: ShipmentBatchReceiveData) => {
  return httpClient('/shipments/batch/receive', HttpMethod.Post, batchReceive);
};

export const getAllManifestByShipment = (shipmentId: number) => {
  return httpClient(`/shipments/${shipmentId}/manifest`, HttpMethod.Get, null);
};

export const createShipmentIndividualReceive = (shipmentId: number, receive: ShipmentBatchReceiveData) => {
  return httpClient(`/shipments/${shipmentId}/individual/receive`, HttpMethod.Post, receive);
};

export const bulkCreateShipmentIndividualReceive = (shipmentId: number, receive: ShipmentBatchReceiveData) => {
  return httpClient(`/shipments/${shipmentId}/individual/receive/bulk`, HttpMethod.Post, receive);
};

export const getShipmentServiceAllocationList = (serviceId: number) => {
  return httpClient(`/shipmentServiceAllocation/service/${serviceId}`, HttpMethod.Get, null);
};

export const createShipmentServiceAllocation = (shipmentServiceAllocation: ShipmentServiceAllocationData) => {
  return httpClient('/shipmentServiceAllocation', HttpMethod.Post, shipmentServiceAllocation);
};

export const deleteShipmentServiceAllocation = (id: number) => {
  return httpClient(`/shipmentServiceAllocation/${id}`, HttpMethod.Delete, null);
};

export const getIncomingShipments = (shipmentStatus: string[]) => {
  return httpClient(`/shipmentsByShipmentStatus?shipmentStatus=${shipmentStatus}`, HttpMethod.Get, null);
};

export const getShipmentServiceAllocationsByShipmentId = (shipmentId: number) => {
  return httpClient(`/shipmentServiceAllocation/shipment/${shipmentId}`, HttpMethod.Get, null);
};

export const getAddStockShipmentManifestPricing = (partTypeId: number) => {
  return httpClient(`/shipmentManifest/${partTypeId}/supplierPricing`, HttpMethod.Get, null);
};

export const getShipmentDetailsPartIncludes = (partId: number, serviceId: number) => {
  return httpClient(`/getShipmentDetailsPartIncludesForPartAction/service/${serviceId}/part/${partId}`, HttpMethod.Get, null); 
};


export const getLatestShipmentByPartId = (partId: number) => {
  return httpClient(`/latestShipmentsByPartId/${partId}`, HttpMethod.Get, null);
};
