import React from 'react';
import { Grid } from '@mui/material';
import { Accordian } from '../../atoms/Accordian';
import { Typography } from '../../atoms/Typography';
import { SalesRepairSupplierPanelProps } from './SalesRepairSupplierPricingPanel.props';
import { RepairPricingPanel } from '../../organisms/RepairPricingPanel';
import { SupplierPricingPanel } from '../../organisms/SupplierPricingPanel';
import { SalePricingPanel } from '../../organisms/SalePricingTable';

export const SalesRepairSupplierPanel: React.FC<SalesRepairSupplierPanelProps> = ({
  header,
  repairPricingData,
  supplierPricingData,
  salesPricingData,
  partTypeContract,
  partTypeName,
  contractOptions,
  onSalePricingSave,
  onSalePricingChange,
  onRepairPricingChange,
  onRepairPricingSave,
  recallSearchNodeQueryForRepairPricing,
  onSupplierPricingChange,
  onSupplierPricingSave,
  recallSearchNodeQueryForSupplierPricing
}) => {
  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>{header}</Typography>
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <Grid mb={5}>
        <SalePricingPanel
          partTypeContract={partTypeContract.toString()}
          partTypeName={partTypeName}
          salesPricingData={salesPricingData}
          contractOptions={contractOptions}
          onSave={onSalePricingSave}
          onChange={(salePricing) => onSalePricingChange(salePricing)}
        />
      </Grid>
      <Grid mb={5}>
        <RepairPricingPanel 
          repairPricingData={repairPricingData}
          onRepairPricingChange={onRepairPricingChange}
          onRepairPricingSave={onRepairPricingSave}
          recallSearchNodeQueryForRepairPricing={recallSearchNodeQueryForRepairPricing}
        />
      </Grid>
      <Grid>
        <SupplierPricingPanel 
          supplierPricingData={supplierPricingData}
          onSupplierPricingChange={onSupplierPricingChange}
          onSupplierPricingSave={onSupplierPricingSave}
          recallSearchNodeQueryForSupplierPricing={recallSearchNodeQueryForSupplierPricing}
        />
      </Grid>
    </Accordian>
  );
};