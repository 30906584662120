import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { ArrowLeft, ArrowRight, CloseOutlined, DoneOutlineOutlined } from '@mui/icons-material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { TypographyVariantProps } from '../../atoms/Typography';
import { SelectMenu } from '../../atoms/SelectMenu';
import { EditDateTimePicker } from '../../molecules/EditDateTimePicker';
import { ServiceCheckOutProps } from './ServiceCheckOut.props';
import { 
  ContentPaper, 
  NotesText, 
  SubmitButton,
  OffsiteTimeText, 
  StyledDivider
} from './ServiceCheckOut.styles';
import { ActionPermissions, CustomPermission, FontSizeProps, ServiceStatusCode } from '../../../@types';
import { statusOptionItems } from '../../../utils/services';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isDropDownEmpty } from '../../../utils/common';

export const ServiceCheckOut: React.FC<ServiceCheckOutProps> = ({
  isChangeState,
  noteText,
  statusOptions,
  suggestedOptions,
  statusValue,
  offsiteTime,
  onChangeStatusValue,
  onNoteText,
  onOffsiteTime,
  onSubmit,
  onClose,
  interactionsAvailable
}) => {
  const [isState, setIsState] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [validate, setValidate] = useState(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const handleOnState = () => {
    setIsComplete(false);
    setIsState(true);
    onChangeStatusValue('-1');
  };

  const handleOnComplete = () => {
    setIsState(false);
    setIsComplete(true);
    onChangeStatusValue(ServiceStatusCode.Complete);
  };

  const handleSubmit = () => {
    if (isDropDownEmpty(statusValue)) {
      setValidate(true);
      return;
    }

    onSubmit(true);
    onClose();
  };

  return (
    <ContentPaper mb={2}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Stack direction="row" alignItems="flex-start" spacing={1}>
            {isChangeState &&
              <Button
                fullWidth
                variant={ButtonVariantProps.Secondary} 
                disabled={isState}
                color={ButtonColorProps.Warning}
                onClick={handleOnState}
              >
                Select State
                <ArrowLeft fontSize={FontSizeProps.Large} />
              </Button>
            }
            {statusOptions.some((status) => status.code === ServiceStatusCode.Complete) &&
              <Button
                fullWidth
                variant={ButtonVariantProps.Secondary} 
                disabled={isComplete}
                color={ButtonColorProps.Success}
                onClick={handleOnComplete}
              >
                <ArrowRight fontSize={FontSizeProps.Large} />
              Complete&nbsp;&nbsp;&nbsp;
              </Button>
            }
          </Stack>
        </Grid>
        {isState &&
          <Grid item xs={12} md={4}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={12} md={6}>
                <SelectMenu
                  id="service-check-out-status-menu"
                  label="Select Status"
                  items={statusOptionItems(statusOptions)}
                  selectedValue={statusValue}
                  onChange={(val) => onChangeStatusValue(val)}
                  disabled={!isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Status_Change, permissions)}
                  validate={validate}
                  optionalLabelEnabled={true}
                  optionalLabel="Select Status"
                />
              </Grid>
              {suggestedOptions?.map((obj) => {
                return (
                  <Grid item key={obj.code}>
                    <Button
                      fullWidth
                      color={ButtonColorProps.Warning}
                      onClick={() => onChangeStatusValue(obj.code)}
                    >
                      {obj.name}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        }
        <Grid item xs={12} md={isState ? 4 : 8}>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={0}>
              <OffsiteTimeText variant={TypographyVariantProps.Body2}>Off Site:</OffsiteTimeText>
              <EditDateTimePicker
                isEditable={true}
                disableFuture={true}
                value={offsiteTime}
                textFontSize="0.875rem"
                onChange={onOffsiteTime}
              />
            </Stack>
            <NotesText
              fullWidth
              label="Notes"
              multiline
              rows={2}
              value={noteText}
              onChange={onNoteText}
            />
          </Stack>
        </Grid>
      </Grid>
      <StyledDivider />
      <Stack direction="row-reverse">
        <SubmitButton
          variant={ButtonVariantProps.Secondary}
          onClick={onClose}
        >
          <CloseOutlined fontSize={FontSizeProps.Large} color="disabled" />
        </SubmitButton>
        {!interactionsAvailable &&
          <SubmitButton
            variant={ButtonVariantProps.Secondary}
            onClick={handleSubmit}
          >
            <DoneOutlineOutlined fontSize={FontSizeProps.Large} color="success" />
          </SubmitButton>
        }
      </Stack>
    </ContentPaper>
  );
};