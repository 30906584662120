import { FileMimeTypes } from '../@types/uploaded-files.type';
import { ContentDispositionType } from '../configs/enums';

export const downloadFile =(fileUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', fileUrl);
  link.setAttribute('download', fileName);    
  link.style.display = 'none';
  
  document.body.appendChild(link);   
  link.click();      
  document.body.removeChild(link);
};

export const getFileMimeTypeByExtension = (extension: string) => {
  switch (extension) {
    case 'png':
      return FileMimeTypes.PNG;
    case 'jpg':
      return FileMimeTypes.JPG;
    case 'jpeg':
      return FileMimeTypes.JPEG;
    case 'pdf':
      return FileMimeTypes.PDF;
    case 'xlsx':
      return FileMimeTypes.OpenXmlExtend;
    case 'xls':
      return FileMimeTypes.MSExcel;
    default:
      return '*';
  }
};

export const getContentDispositionTypeByExtension = (extension: string) => {
  switch (extension) {
    case 'png':
      return ContentDispositionType.Inline;
    case 'jpg':
      return ContentDispositionType.Inline;
    case 'jpeg':
      return ContentDispositionType.Inline;
    case 'pdf':
      return ContentDispositionType.Attachment;
    case 'xlsx':
      return ContentDispositionType.Attachment;
    case 'xls':
      return ContentDispositionType.Attachment;
    default:
      return ContentDispositionType.Attachment;
  }
};