import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';
import { Typography } from '../../../components/atoms/Typography';

export const GridContainer = styled('div')`
  height: 92vh;
  width: 100%;
  & .super-app-theme--even {
    background-color: #E5E4E2
  };
  & .super-app-theme--odd {
    background-color: #FFFFFF
  };
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

export const StyledModalBox = styled(Box)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 35%;
  width: 640px;
  background-color: white;
  border-radius: 1ch;
  box-shadow: 0 0 10px 5px #363636;
`;

export const ModalContent = styled(Box)`
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2ch;
`;

export const ModalFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 20px;
`;