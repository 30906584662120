import { ReactNode } from 'react';

export enum VerticalAlignProps {
  Center = 'center',
  Start = 'start',
  End = 'end',
  Baseline = 'baseline',
  Stretch = 'stretch'
}

export enum HorizontalAlignProps {
  Center = 'center',
  Start = 'start',
  End = 'end',
  Around = 'around',
  Between = 'between',
  Evenly = 'evenly'
}

export interface InlineGroupProps {
  children?: ReactNode;
  vertical?: VerticalAlignProps;
  horizontal?: HorizontalAlignProps;
  childGap?: number;
  fullWidth?: boolean;
  className?: string;
}
