import React from 'react';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { GET_PARAMETER_OPTIONS } from '../../../../../constants/form-builder';
import { GetParameterFormBuilderFieldFragmentProps } from './GetParameterFormBuilderFieldFragment.props';
import { StyledStack, StyledTextField } from './GetParameterFormBuilderFieldFragment.styles';
import { isEmptyString } from '../../../../../utils/common';

export const GetParameterFormBuilderFieldFragment: React.FC<GetParameterFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {

  return (
    <StyledStack spacing={2}>
      <StyledTextField
        label="Field Title"
        error={showErrors && (!preSelectedData?.fieldTitle || isEmptyString(preSelectedData?.fieldTitle))}
        value={preSelectedData?.fieldTitle}
        onChange={(title) => onPreSelectedData({
          fieldTitle: title,
          selectedParameter: preSelectedData?.selectedParameter || ''
        })}
      />
      <SelectMenu
        id="form-builder-get-parameter-parameter-options"
        label="Select Parameter"
        items={GET_PARAMETER_OPTIONS}
        selectedValue={preSelectedData?.selectedParameter}
        onChange={(selectedValue) => onPreSelectedData({ 
          fieldTitle: preSelectedData?.fieldTitle || '',
          selectedParameter: selectedValue
        })}
        validate={showErrors && !preSelectedData?.selectedParameter}
      />
    </StyledStack>
  );
};