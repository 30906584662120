import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TypographyVariantProps } from '../../atoms/Typography';
import { BrandSelectorProps } from './BrandSelector.props';
import { ContentWrapperBox, HeadingWrapper, SelectBox, SelectionWrapper, Footer, StyledButton } from './BrandSelector.styles';
import { SelectMenu } from '../../atoms/SelectMenu';
import { ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { NodeType } from '../../../@types';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';

export const BrandSelector: React.FC<BrandSelectorProps> = ({
  open,
  clients,
  contracts,
  brands,
  allFieldsRequired = true,
  isBrandEnabled = true,
  clientId = 0,
  contractId = 0,
  brandId,
  pageType,
  isTemplate,
  isActiveClear = true,
  setIsTemplate,
  handleClose,
  handleParentSelection,
  onChangeNodeType,
  onChange,
  handleClear
}) => {
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedContract, setSelectedContract] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');

  useEffect(() => {
    if (!isBrandEnabled && clientId >= 0 && contractId >= 0) {
      setSelectedClient(clientId.toString());
      setSelectedContract(contractId.toString() || '');
    }
    if (pageType === 'add-service' && clientId >= 0) {
      setSelectedClient(clientId.toString());
      !Number.isNaN(contractId) && setSelectedContract(contractId?.toString() || '');
      !Number.isNaN(brandId) && setSelectedBrand(brandId?.toString() || '');
    }
  }, [isBrandEnabled, clientId , contractId, brandId, pageType]);

  useEffect(() => {
    if (isBrandEnabled) {
      if (clients && clients.length === 1) {
        if (selectedClient === '0' || selectedClient === '') {
          setSelectedClient(clients[0].value);
          onChangeNodeType(NodeType.Contract, clients[0].value);
        } else {
          onChangeNodeType(NodeType.Contract, selectedClient);
        }
      }

      if (contracts && contracts.length === 1) {
        if (selectedContract === '0' || selectedContract === '') {
          setSelectedContract(contracts[0].value);
          onChangeNodeType(NodeType.Brand, contracts[0].value);
        } else {
          onChangeNodeType(NodeType.Brand, selectedContract);
        }
      }

      // services related only implementation
      if (contracts && contracts.length === 2 && contracts[0].value === '0') {
        setSelectedContract(contracts[1].value);
        onChangeNodeType(NodeType.Brand, contracts[1].value);
      }
    } else { 
      // execute when brand dropdown is hidden
      if (clients && clients.length === 1) {
        if (selectedClient === '0' || selectedClient === '') {
          setSelectedClient(clients[0].value);
          onChangeNodeType(NodeType.Contract, clients[0].value);
        } else {
          onChangeNodeType(NodeType.Contract, selectedClient);
        }
      }

      if (contracts && contracts.length === 1) {
        if (selectedContract === '0' || selectedContract === '') {
          setSelectedContract(contracts[0].value);
        }
      }
    }
  }, [clients, contracts, isBrandEnabled]);

  useEffect(() => {
    if (isBrandEnabled && selectedContract) {
      if (brands && brands.length === 1 && selectedContract !== '0') {
        setSelectedBrand(brands[0].value);
      }
      if (brands && brands.length === 2 && brands[0].value === '0' && selectedContract !== '0') {
        setSelectedBrand(brands[1].value);

        if (!Number.isNaN(brandId) && isTemplate) {
          setSelectedBrand(brandId?.toString() || '');
          setIsTemplate && setIsTemplate(false);
        }
      }
      if (brands && brands.length > 2 && brands[0].value === '0' && selectedContract !== '0') {
        setSelectedBrand(brands[0].value);

        if (!Number.isNaN(brandId) && isTemplate) {
          setSelectedBrand(brandId?.toString() || '');
          setIsTemplate && setIsTemplate(false);
        }
      }
    }
  }, [brands, selectedContract, isBrandEnabled, brandId]);

  const onCancel = () => {
    handleClose();
  };

  const onClear = () => {
    setSelectedClient('');
    setSelectedContract('');
    setSelectedBrand('');
    handleClear && handleClear();
  };

  const onSelectParent = () => {
    handleParentSelection(selectedBrand, getParentHierarchy());
    onChange && onChange(selectedClient, selectedContract, selectedBrand, getParentHierarchy());
    handleClose();
  };

  const getParentHierarchy = () => {
    let hierarchyText = clients.find(item => item.value === selectedClient)?.label;
    hierarchyText += `/${contracts.find(item => item.value === selectedContract)?.label || ''}`;
    
    // execute when brand dropdown is visible
    if (isBrandEnabled) hierarchyText += `/${brands.find(item => item.value === selectedBrand)?.label || ''}`;

    return hierarchyText || '';
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ContentWrapperBox>
        <CloseIcon handleClose={handleClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Select Parent
        </HeadingWrapper>
        <SelectionWrapper>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4} justifyContent={'center'}>
              <SelectBox>
                <SelectMenu
                  id="page-size-menu"
                  labelId="demo-select-small"
                  items={clients}
                  selectedValue={selectedClient}
                  onChange={(value) => {
                    setSelectedClient(value);
                    setSelectedContract('0');
                    setSelectedBrand('0');
                    onChangeNodeType(NodeType.Contract, value);
                  }}
                  label="Select Client"
                />
              </SelectBox>
            </Grid>
            <Grid item xs={12} md={4} justifyContent={'center'}>
              <SelectBox>
                <SelectMenu
                  id="page-size-menu"
                  labelId="demo-select-small"
                  items={contracts}
                  disabled={!selectedClient || selectedClient === '0'}
                  selectedValue={selectedContract}
                  onChange={(value) => {
                    setSelectedContract(value);
                    setSelectedBrand('0');
                    if (value !== '0') {
                      onChangeNodeType(NodeType.Brand, value);
                    } else {
                      onChangeNodeType(NodeType.Client, selectedClient);
                    }
                  }}
                  label="Select Contract"
                />
              </SelectBox>
            </Grid>
            {isBrandEnabled && (
              <Grid item xs={12} md={4} justifyContent={'center'}>
                <SelectBox>
                  <SelectMenu
                    id="page-size-menu"
                    labelId="demo-select-small"
                    disabled={!selectedContract}
                    items={brands}
                    selectedValue={selectedBrand}
                    onChange={(value) => setSelectedBrand(value)}
                    label="Select Brand"
                  />
                </SelectBox>
              </Grid>
            )}
          </Grid>
        </SelectionWrapper>
        <Footer>
          <StyledButton variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Error} onClick={onCancel}>Cancel</StyledButton>
          {isActiveClear && <StyledButton variant={ButtonVariantProps.Secondary} onClick={onClear}>Clear</StyledButton>}
          <StyledButton disabled={(selectedBrand === '' || selectedBrand === '0') && allFieldsRequired} variant={ButtonVariantProps.Primary} onClick={onSelectParent}>Select</StyledButton>
        </Footer>
      </ContentWrapperBox>
    </Modal>
  );
};