import React, { useEffect, useState } from 'react';
import { BulkPutawayProps } from './BulkPutaway.props';
import { Stack } from '@mui/material';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../LocationSearchItem';
import { Close } from '@mui/icons-material';
import { ColorProps, CustomPermission, ActionPermissions } from '../../../@types';
import { BulkPutawayButton, IconWrapper, LiveSearchBoxWrapper } from './BulkPutaway.styles';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';

export const BulkPutaway: React.FC<BulkPutawayProps> = ({
  onChange,
  onApiInvoke
}) => {
  const [showPutawayLocation, setShowPutawayLocation] = useState(false);
  const [putawayLocationName, setPutawayLocationName] = useState('');
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  return (
    <Stack>
      {!showPutawayLocation ?
        <>
          {isUserHasPermission(ActionPermissions.Shipment_Bulk_Put_way, permissions) &&
            <BulkPutawayButton onClick={() => setShowPutawayLocation(true)}>Bulk Putaway</BulkPutawayButton>
          }
        </> :
        <Stack direction="row">
          <LiveSearchBoxWrapper>
            <LiveSearchBox
              title="Select a Location"
              timeOffset={400}
              value={putawayLocationName || ''}
              onClearValue={() => ''}
              onChange={(value) => {
                if (value) {
                  setPutawayLocationName(value.name);
                  onChange(value.id, value.name);
                }
              }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onApiInvoke={onApiInvoke}
              isOptionEqualToValue={(option, value) => {
                value = option;
                return option.id === value.id;
              }}
            />
          </LiveSearchBoxWrapper>
          <IconWrapper onClick={() => setShowPutawayLocation(false)}>
            <Close color={ColorProps.Error}></Close>
          </IconWrapper>
        </Stack>
      }
    </Stack>
  );
};