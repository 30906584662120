import { httpClient, HttpMethod } from './http-client';

export const createUploadedFiles = (fileList: any) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  return httpClient('/uploadedFiles', HttpMethod.Post, fileList, headers);
};

export const exportFiles = (exportInfo: any) => {
  let url = `/export?fileType=${exportInfo?.fileType}`;

  if (exportInfo?.rowCount) {
    url += `&rowCount=${exportInfo?.rowCount}`;
  }

  if (exportInfo?.structureTypeCategory) {
    url += `&structureTypeCategory=${exportInfo?.structureTypeCategory}`;
  }

  exportInfo?.filter.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  if (exportInfo?.startDate) {
    url += `&startDate=${exportInfo?.startDate}`;
  }

  if (exportInfo?.endDate) {
    url += `&endDate=${exportInfo?.endDate}`;
  }

  if (exportInfo?.locationId !== undefined) {
    url += `&locationId=${exportInfo?.locationId}`;
  }

  if (exportInfo?.includeChilds !== undefined) {
    url += `&includeChilds=${exportInfo?.includeChilds}`;
  }

  if (exportInfo?.excludedColumns) {
    url += `&excludedColumns=${exportInfo?.excludedColumns}`;
  }

  return httpClient(url, HttpMethod.Post, exportInfo);
};

export const deleteUploadedFile = (id: number | string) => {
  return httpClient(`/uploadedFiles/delete/${id}`, HttpMethod.Put, null);
};

export const uploadFiles = (entityId: number, fileList: any, entityType: string, featureType?: string) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  let url = `/uploadedFiles/${entityId}/entityType/${entityType}`;
  if (featureType) {
    url += `?featureType=${featureType}`;
  }
  return httpClient(url, HttpMethod.Post, fileList, headers);
};

export const getUploadedFiles = (entityId: number, entityType: string, notIncludeUrlsByDefault?: boolean): any => {
  return httpClient(`/uploadedFiles/${entityId}/entityType/${entityType}?notIncludeUrlsByDefault=${notIncludeUrlsByDefault}`, HttpMethod.Get, null);
};

export const permanantDeleteUploadedFile = (id: number) => {
  return httpClient(`/uploadedFiles/${id}`, HttpMethod.Delete, null);
};

export const updateUploadedFileData = (id: number, isMain: boolean) => {
  return httpClient(`/uploadedFile/data/${id}`, HttpMethod.Put, { isMain });
};

export const getUploadedCsvFile = (key: string) => {
  return httpClient(`/uploadedFiles/key/${encodeURIComponent(key)}`, HttpMethod.Get, null);
};

export const getUploadedFilePresignedUrlById = (id: number, contentDispositionType?: string) => {
  return httpClient(`/uploadedFiles/presignedUrl/${id}?contentDispositionType=${contentDispositionType}`, HttpMethod.Get, null);
};

export const getUploadedFilePresignedUrlByKey = (key: string, mimeType?: string, contentDispositionType?: string) => {
  return httpClient(`/uploadedFiles/presignedUrlKey/${encodeURIComponent(key)}?mimeType=${mimeType}&contentDispositionType=${contentDispositionType}`, HttpMethod.Get, null);
};

export const exportDispatchManifest = (shipmentId: number) => {
  return httpClient(`/manifestExport/${shipmentId}`, HttpMethod.Post, {});
};

export const uploadUserProfileImage = (entityId: number, fileList: any) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  return httpClient(`/uploadedFiles/user/${entityId}/profile?entityType=userProfiles`, HttpMethod.Post, fileList, headers);
};

export const initializeUploadedFilesMultipart = (fileName: string, entityId: number, entityType: string) => {
  return httpClient('/initializeUploadedFilesMultipart', HttpMethod.Post, { fileName, entityId, entityType });
};

export const getUploadedFilesMultipartUrl = (fileName: string, partNumber: number, uploadId: string) => {
  return httpClient('/getUploadedFilesMultipartUrl', HttpMethod.Post, { fileName, partNumber, uploadId });
};

export const completeUploadedFilesMultipart = (key: string, uploadId: string, parts: any[], originalFileName: string, entityId: number, entityType: string, mimeType: string) => {
  return httpClient('/completeUploadedFilesMultipart', HttpMethod.Post, { key, uploadId, parts, originalFileName, entityId, entityType, mimeType });
};

export const abortUploadedFilesMultipartUpload = (key: string, uploadId: string) => {
  return httpClient('/abortUploadedFilesMultipartUpload', HttpMethod.Post, { key, uploadId });
};