import { ParameterUIDataFormPartAttributes, PartTypeLiveSearchItem, SoftwareFirmwareHardwareData, SoftwareFirmwareHardwareOptions } from '../@types';
import { InteractionsPartRequestFormInitialPartType } from '../@types/interactions.type';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { PartTypeCategory } from '../configs/enums';

export const getPartSFHDropdownData = (partSFHData: Array<SoftwareFirmwareHardwareData>) => {
  const dropdownData: Array<DropdownItem> = partSFHData.map((SFHItem) => ({
    label: SFHItem.version,
    value: (SFHItem.id as number).toString()
  }));
  return dropdownData;
};

export const getPartSFHversions = (partSFHData: SoftwareFirmwareHardwareOptions, partAttribute: string) => {
  switch(partAttribute) {
    case ParameterUIDataFormPartAttributes.PartSoftware:
      return getPartSFHDropdownData(partSFHData.softwares);
    case ParameterUIDataFormPartAttributes.PartHardware:
      return getPartSFHDropdownData(partSFHData.hardwares);
    case ParameterUIDataFormPartAttributes.PartFirmware:
      return getPartSFHDropdownData(partSFHData.firmwares);
    default:
      return [];
  }
};

export const isSerializedPartType = (partType: PartTypeLiveSearchItem) => {
  return partType.categoryCode === PartTypeCategory.SerialisedComponent || partType.categoryCode === PartTypeCategory.SerialisedDevice;
};

export const getUniquePartTypesFromLiveSearch = (searchString: string, clientContractPartTypes: Array<PartTypeLiveSearchItem>, installedPartTypeOptions:Array<InteractionsPartRequestFormInitialPartType>) => {
  return clientContractPartTypes.filter((partType) =>
    partType.name?.toLowerCase()?.includes(searchString.toLowerCase()) && installedPartTypeOptions.every((installedPartType) => installedPartType.id !== partType.id));
};