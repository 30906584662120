import React, { ChangeEvent, useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import { UploadButtonProps, FileUploadError } from './UploadButton.props';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { NameSpan, Span } from './UploadButton.styles';

export const UploadButton: React.FC<UploadButtonProps> = ({
  maxFileSize,
  fileTypes,
  setFileDetails,
  icon,
  label = 'Upload',
  isText = true,
  value,
  onChangeFileData,
  showName = false
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileError, setIsFileError] = useState<FileUploadError>({
    isError: false,
    msg: '',
  });
  const [totalFileSize, setTotalFileSize] = useState<number>(0);
  const [uploadedFileName, setUploadedFileName] = useState<string>();
  let fileRef: any;

  useEffect(() => {
    let fileSizeError = false;
    let errorMessage = '';
    if (totalFileSize > maxFileSize) {
      fileSizeError = true;
      errorMessage = `Max Limit: ${maxFileSize.toFixed(2)} MB`;
    }
    if (isFileError.isError) {
      errorMessage = isFileError.msg;
    }
    if (fileSizeError || isFileError.isError) {
      setIsFileError({ isError: true, msg: errorMessage });
    } else {
      setIsFileError({ isError: false, msg: isText ? `Total File Size: ${totalFileSize.toFixed(2)} MB` : '' });
    }
  }, [totalFileSize, maxFileSize]);

  useEffect(() => {
    if (value?.fileSize) {
      setIsFileError({ isError: false, msg: isText ? `Total File Size: ${value?.fileSize.toFixed(2)} MB` : '' });
    }
  }, [value?.fileSize]);

  const uploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const selectedFiles = e.target.files;
    if (selectedFiles == null) {
      setIsLoading(false);
      return;
    }
    let tempTotalFileSize = 0;
    let fileName;
    let hasWrongFileType = false;
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i];
      fileName = selectedFile.name;
      tempTotalFileSize += selectedFile.size / 1024 / 1024;

      if (
        !fileTypes.includes(selectedFile.type) &&
        !fileTypes.includes(`${selectedFile.type.split('/')[0]}/*`)
      ) {
        hasWrongFileType = true;
        setIsFileError({ isError: true, msg: 'Wrong file type' });
        setIsLoading(false);
      }
    }
    if (!hasWrongFileType) {
      if(selectedFiles.length < 2){
        setUploadedFileName(fileName);
      }
      setTotalFileSize(tempTotalFileSize);
      onChangeFileData && fileName && onChangeFileData({ fileName: fileName, fileSize: tempTotalFileSize });
      setFileDetails && setFileDetails(selectedFiles)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
      // Reset the file input field
      if (fileRef) {
        fileRef.value = '';
      }
    }
  };


  return (
    <>
      <Button
        variant={ButtonVariantProps.Primary}
        disabled={isLoading}
        onClick={() => {
          fileRef.click();
          setIsFileError({ isError: false, msg: '' });
        }}
      >
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <span>
            <Stack direction="row" alignItems={ToogleButtonTextAlignment.Center}><>{icon}{label}</></Stack>
          </span>
        )}
      </Button>

      {isFileError.msg
        ? (<Span>{isFileError.msg}</Span>)
        : (<Span>{isText ? `Total File Size: ${totalFileSize.toFixed(2)} MB` : ''}</Span>)}

      {showName && uploadedFileName && (<NameSpan>{uploadedFileName && `${uploadedFileName}`}</NameSpan>)}
      {showName && !uploadedFileName && value?.fileName && (<NameSpan>{value?.fileName && `${value?.fileName}`}</NameSpan>)}

      <input
        type="file"
        name="upload-file"
        onChange={uploadFile}
        accept={fileTypes.join(',')}
        ref={(refParam) => (fileRef = refParam)}
        hidden
        multiple
      />
    </>
  );
};