import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { SiteSchemaFragmentProps } from './SiteSchemaFragment.props';
import { Accordian } from '../../../../components/atoms/Accordian';
import { SiteSchemaTable } from '../../../../components/organisms/SiteSchemaTable';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { Modal } from '../../../../components/atoms/Modal';
import { SiteSchemaPopupFragment } from '../SiteSchemaPopupFragment';
import { Add } from '@mui/icons-material';
import { useGetAllSiteSchemas } from '../../../../queries/structure-query';
import { SiteSchemaData } from '../../../../@types';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';
import { SAMPLE_SITE_SCHEMA } from '../../../../constants/structure';

export const SiteSchemaFragment: React.FC<SiteSchemaFragmentProps> = ({
  nodeId,
  hasEditPermissions,
  hasCreatePermissions
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [siteSchemaData, setSiteSchemaData] = useState<Array<SiteSchemaData>>([]);
  const [selectedSiteSchemaData, setSelectedSiteSchemaData] = useState<SiteSchemaData>(SAMPLE_SITE_SCHEMA);

  const getAllSiteSchemasQuery = useGetAllSiteSchemas(nodeId);

  useEffect(() => {
    nodeId && setSelectedSiteSchemaData({
      ...selectedSiteSchemaData,
      nodeId
    });
  }, [nodeId]);

  useEffect(() => {
    getAllSiteSchemasQuery.data && setSiteSchemaData(getAllSiteSchemasQuery.data);
  }, [getAllSiteSchemasQuery.data]);

  const handleAdd = (event: any) => {
    event.stopPropagation();
    setSelectedSiteSchemaData({
      ...SAMPLE_SITE_SCHEMA,
      nodeId
    });
    setIsEdit(false);
    setOpen(true);
    setExpanded(true);
  };

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') setOpen(false);
  };

  const handleEdit = (id: number) => {
    const findSelected = siteSchemaData.find((siteSchema) => siteSchema.id === id);
    findSelected && setSelectedSiteSchemaData(findSelected);
    setIsEdit(true);
    setOpen(true);
  };

  return (
    <>
      <Accordian 
        title={
          <Grid container justifyContent="space-between">
            <Grid item mr={2}>
              <Typography>Site Schema {siteSchemaData?.length ? `[${siteSchemaData.length}]` : '[0]'}</Typography>
            </Grid>
            <Grid item mr={2}>
              {hasCreatePermissions && 
              <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} onClick={handleAdd}>
                Add
              </Button>
              }
            </Grid>
          </Grid>
        }
        onChange={(_, newExpanded: boolean) => setExpanded(newExpanded)}
        expanded={expanded} 
      >
        <SiteSchemaTable 
          hasEditPermissions={hasEditPermissions}
          siteSchemaTableData={siteSchemaData} 
          onEditClick={handleEdit} 
        />
      </Accordian>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <CloseIcon handleClose={handleClose} />
          <SiteSchemaPopupFragment 
            isOpen={open}
            isEdit={isEdit}
            siteSchemaData={selectedSiteSchemaData}
            nodeId={nodeId}
            onClose={handleClose}
          />
        </>
      </Modal>
    </>
  );
};