import React, { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link as MaterialLink, LinkProps as MaterialLinkProps } from '@mui/material';

export const LinkStyled = styled((customLinkProps: MaterialLinkProps) => {
  const linkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <Link ref={ref} {...props} to={customLinkProps.href || '#'} />);
  linkRef.displayName = 'LinkRef';
  const linkRefProps = { component: linkRef };

  return (
    <MaterialLink {...linkRefProps} {...customLinkProps} />
  );
})(() => `
`);