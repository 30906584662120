import React from 'react';
import { ColorDotProps } from './ColorDot.props';
import { StyledCircle } from './ColorDot.styles';

export const ColorDot: React.FC<ColorDotProps> = ({
  color,
  size = '8px'
}) => {
  return (
    <StyledCircle 
      color={color} 
      size={size} 
    />
  );
};
