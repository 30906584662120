import React, { useState } from 'react';
import { EditPartTypeLiveSearchProps } from './EditPartTypeLiveSearch.props';
import { MainBox, StyledStack } from './EditPartTypeLiveSearch.styles';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Link, UnderlineTypeProps } from '../../atoms/Link';
import { useSearchManufacturerNodesByClientAndContract } from '../../../queries/live-search-query';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { LocationNodeData } from '../../../@types';
import { Grid } from '@mui/material';
import { Button, ButtonColorProps, ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@mui/icons-material';

export const EditPartTypeLiveSearch: React.FC<EditPartTypeLiveSearchProps> = ({
  isEditable,
  partData,
  partTypeData,
  handlePartTypeChange
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [partTypeId, setPartTypeId] = useState(0);

  const searchManufacturerNodesByClientAndContract = useSearchManufacturerNodesByClientAndContract();

  const handleOnSubmit = () => {
    handlePartTypeChange({ 
      ...partData, 
      partTypeId: partTypeId === 0 ? partData.partTypeId : partTypeId
    });
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsShown(false);
  };
  
  return (
    <div
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
    >
      <StyledStack direction="row" alignItems="center" spacing={1}>
        {isEdit ?
          <Grid width={200}>
            <LiveSearchBox
              title="Part Type Search"
              timeOffset={400}
              value={partTypeData.name || ''}
              onClearValue={() => ''}
              renderItem={(props: any, option: any) => { 
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => setPartTypeId(obj.id)}
              onApiInvoke={async (name: string) => {
                return await searchManufacturerNodesByClientAndContract.mutateAsync({
                  name: name,
                  applicableNodeIds: partTypeData.applicableNodeIds
                });
              }}
            />
          </Grid>
          :
          <MainBox>
            <Typography variant={TypographyVariantProps.H5}>
              <Link href={`/configure/partTypes/${partTypeData.id}`} underline={UnderlineTypeProps.None}>{partTypeData.name}</Link>
            </Typography>
            <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">P/N: {partTypeData.number}</Typography>
            <Typography variant={TypographyVariantProps.Subtitle2} textColor="grey">Client: {partTypeData.selectedParentHierarchy}</Typography>
          </MainBox>
        }
        {isEdit &&
        <>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </>
        }
        {isShown && !isEdit &&
        <Button
          variant={ButtonVariantProps.Icon}
          size={ButtonSizeProps.Small}
          onClick={() => setIsEdit(true)}
        >
          <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
        </Button>
        }
      </StyledStack>
    </div>
  );
};