import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';

export const RestrictionBox = styled(Box)`
  width: 95%;
  padding: 20px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top:15px;
  margin-left:15px;
  margin-right:15px;
`;
