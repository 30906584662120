import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import { LiveSearchBoxProps } from './LiveSearchBox.props';
import { isEmptyString } from '../../../utils/common';
import { SizeProps } from '../../../@types';
import { StyledAutoComplete } from './LiveSearchBox.styles';

export const LiveSearchBox: React.FC<LiveSearchBoxProps> = ({
  title,
  timeOffset,
  isError,
  value,
  inputRef,
  isRequired,
  helperText,
  defaultSuggetions,
  disabled,
  feature,
  multiline = value ? true : false,
  onClearValue,
  onChange,
  onApiInvoke,
  renderItem,
  onKeyDown,
  isOptionEqualToValue,
  size = SizeProps.Small,
  groupBy,
  renderGroup,
  freeSolo,
  onClick,
  disableDelayedInput
}) => {
  const [options, setOptions] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState('');
  const [helperTextValue, setHelperTextValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [delayedInput, setDelayedInput] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (defaultSuggetions && defaultSuggetions.length) {
      setOptions(defaultSuggetions);
    }
  }, [defaultSuggetions]);

  useEffect(() => {
    if(delayedInput) {
      if(inputValue && (!defaultSuggetions || defaultSuggetions?.length == 0) && options.length === 1) {
        onChange(options[0]);
        setIsOpen(false);
        setIsLoading(false);
      }

      setDelayedInput(false);
    }
  }, [options]);

  useEffect(() => {
    if (helperText)
      setHelperTextValue(helperText);
  }, [helperText]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleInputChange = (event: any, textValue: string) => {
    if (event?.nativeEvent?.inputType === 'insertFromPaste') {
      textValue = textValue.trim();
    }
    setInputValue(textValue);
    clearInterval(timerRef.current);
    delayedInput && setDelayedInput(false);

    if (textValue?.length > 0) {
      setHelperTextValue('');
      setIsLoading(true);
      setOptions([]);
      timerRef.current = setTimeout(async () => {
        const result = await onApiInvoke(textValue);
        setIsLoading(false);
        setOptions(defaultSuggetions && defaultSuggetions.length ? [...defaultSuggetions, ...result] : result);
      }, timeOffset);
    } else if (isRequired) {
      setHelperTextValue(helperText || '');
      setOptions(defaultSuggetions && defaultSuggetions.length ? defaultSuggetions : []);
      onClearValue();
    }
    else {
      setOptions(defaultSuggetions && defaultSuggetions.length ? defaultSuggetions : []);
      onClearValue();
    }
  };

  return (
    <StyledAutoComplete
      title={title}
      disabled={disabled}
      open={isOpen}
      freeSolo={freeSolo}
      renderOption={(props, option, state) => renderItem(props, option, state)}
      renderInput={(params) => (
        <TextField
          {...params}
          multiline={multiline}
          label={title}
          disabled={disabled}
          helperText={helperTextValue}
          inputRef={inputRef}
          onClick={onClick}
          InputProps={{
            ...params.InputProps
          }}
          error={isError || !isEmptyString(helperTextValue)}
        />
      )}
      onChange={(event: React.SyntheticEvent, value: any, reason: string, details: any) => onChange(details?.option)}
      defaultValue={value}
      inputValue={inputValue}
      value={value}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={(obj: any) => (feature === 'part-action' ? obj.serial1 || obj.name : obj.name) || value}
      filterOptions={(items) => items}
      loading={isLoading}
      size={size}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if(event.key === 'Enter' && !disableDelayedInput) {
          setDelayedInput(true);
        }
        onKeyDown && onKeyDown(event);
      }}
      isOptionEqualToValue={isOptionEqualToValue}
      autoHighlight={!defaultSuggetions || defaultSuggetions?.length == 0}
      onBlur={() => { 
        setIsLoading(false);
        setDelayedInput(false);
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      groupBy={groupBy}
      renderGroup={renderGroup}
    />
  );
};