import { DeleteRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import { TextField } from '../../atoms/TextField';

export const TradingHoursDelete = styled(DeleteRounded)`
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
`;

export const NoteTextField = styled(TextField)`
  min-width: 150px;
  width: 100%;
`;

export const DateTimePickerWrapper = styled(Box)`
  min-width: 220px;
`;

export const TimePickerWrapper = styled(Box)`
  min-width: 130px;
`;