// App constants
export const AUTH_URL = '';
export const USER_TOKEN_KEY = 'USER_TOKEN_KEY';
export const USER_OBJECT_KEY = 'USER_OBJECT_KEY';
export const IS_TOKEN_EXPIRED = 'IS_TOKEN_EXPIRED';
export const IS_ADMIN_PANEL_ON_TOP = 'IS_ADMIN_PANEL_ON_TOP';
export const IMPORT_SESSION_FILE_DATA = 'IMPORT_SESSION_FILE_DATA';

// Environment constants
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
export const REACT_APP_API_CONNECTION_NAME = process.env.REACT_APP_API_CONNECTION_NAME ?? '';
export const REACT_APP_API_PASSWORD = process.env.REACT_APP_API_PASSWORD ?? '';
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';
export const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ?? '';
export const REACT_APP_STRUCTURE_IMPORT_EXCEL_KEY = process.env.REACT_APP_STRUCTURE_IMPORT_EXCEL_KEY ?? '';
export const REACT_APP_SERVICE_IMPORT_EXCEL_KEY = process.env.REACT_APP_SERVICE_IMPORT_EXCEL_KEY ?? '';
export const DEFAULT_PRINTER_NAME = process.env.REACT_APP_DEFAULT_PRINTER_NAME || '';
export const REACT_APP_QZ_CERTIFICATE = process.env.REACT_APP_QZ_CERTIFICATE?.replace(/\\n/gm, '\n') || '';

// File upload mime types
export const IMPORT_FILE_MIME_TYPES = process.env.REACT_APP_IMPORT_FILE_MIME_TYPES || '';
export const USER_PROFILE_MIME_TYPES = process.env.REACT_APP_USER_PROFILE_MIME_TYPES || '';
export const CLIENT_CONTRACT_LOGO_MIME_TYPES = process.env.REACT_APP_CLIENT_CONTRACT_LOGO_MIME_TYPES || '';
export const PART_TYPES_IMAGES_MIME_TYPES = process.env.REACT_APP_PART_TYPES_IMAGES_MIME_TYPES || '';
export const PART_TYPES_ATTACHMENTS_MIME_TYPES = process.env.REACT_APP_PART_TYPES_ATTACHMENTS_MIME_TYPES || '';
export const SERVICE_ATTACHMENTS_MIME_TYPES = process.env.REACT_APP_SERVICE_ATTACHMENTS_MIME_TYPES || '';
export const PART_IMAGES_MIME_TYPES = process.env.REACT_APP_PART_IMAGES_MIME_TYPES || '';
export const ADD_STOCK_ATTACHMENTS_MIME_TYPES = process.env.REACT_APP_ADD_STOCK_ATTACHMENTS_MIME_TYPES || '';

// CVTS node IDs
export const REACT_APP_CVTS_CLIENT_NODE_ID = process.env.REACT_APP_CVTS_CLIENT_NODE_ID || '';
export const REACT_APP_CVTS_CONTRACT_NODE_ID = process.env.REACT_APP_CVTS_CONTRACT_NODE_ID || '';