import React, { useEffect } from 'react';
import { Loader } from '../../components/atoms/Loader';
import { useNavigate } from 'react-router-dom';

export const NavigatorSupport: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(-1);
  }, []);

  return (
    <Loader />
  );
};