import { styled } from '@mui/material/styles';
import { Stack, Box, IconButton } from '@mui/material';
import { Typography } from '../../atoms/Typography';

export const MainBox = styled(Box)`
  height: 200px;
  width: auto;
`;

export const SmallLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const BoldLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-weight: bold;
`;

export const GridHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 10px;
`;

export const StackContainer = styled(Stack)`
  transform: scale(0.8);
`;

export const GridContainer = styled('div')((props: { isMobile?: boolean; rowLength: number }) =>`
    margin-top: ${!props.isMobile && '10px'};
    height: ${props.rowLength === 1 ? '200px' : props.rowLength === 2 ? '300px' : props.rowLength > 2 ? '350px' : '100px'};
    width: 100%;
    margin-top: ${props.isMobile && '10px'};
    .MuiDataGrid-main {
      margin-top: ${props.isMobile && '-30px'};
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-viewport,
    .MuiDataGrid-row,
    .MuiDataGrid-renderingZone {
      max-height: fit-content !important;
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-cell {
      overflow: auto;
      white-space: initial !important;
      line-height: 16px !important;
      display: flex !important;
      align-items: center;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      border: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaderTitle {
      display: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaders {
      visibility: ${props.isMobile && 'hidden !important'};
    }
    .MuiDataGrid-toolbarContainer {
      visibility: hidden !important;
    }
    .MuiDataGrid-root {
      border: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
  };`
);

export const SmallAttachmentLabel = styled(Typography)`
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 1px;
`;