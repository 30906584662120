import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { GRID_SPACING } from '../../../../configs/ui-constants';
import { ToogleButtonTextAlignment } from '../../../../components/atoms/ToogleButtonGroup';
import { RichTextEditor } from '../../../../components/atoms/RichTextEditor';
import { useAddServiceLogsForService } from '../../../../queries/service-log-query';
import { ServiceLogNoteFragmentProps } from './ServiceLogNoteFragment.props';

export const ServiceLogNoteFragment: React.FC<ServiceLogNoteFragmentProps> = ({ data }) => {
  const [expanded, setExpanded] = useState(true);
  const [notes, setNotes] = useState('');
  const [id, setId] = useState(0);
  const addServiceLogsQuery = useAddServiceLogsForService(data.id);

  useEffect(() => {
    data && setId(data.id);
  }, [data]);

  const handleAdd = async (event: any) => {
    event.stopPropagation();
    await addServiceLogsQuery.mutateAsync({ id, notes });
    setNotes('');
  };

  return (
    <Accordian
      title={
        <Grid container justifyContent="space-between">
          <Grid item mr={2}>
            <Typography>Add Notes</Typography>
          </Grid>
        </Grid>
      }
      onChange={(_, newExpanded: boolean) => setExpanded(newExpanded)}
      expanded={expanded}
    >
      <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Start}>
        <Grid item xs={12}>
          <RichTextEditor
            value={notes}
            onValueChange={(value: string) => {
              setNotes(value);
            }}
          />
        </Grid>
        <Grid item xs={12} textAlign="right" mt={2}>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Success} onClick={handleAdd}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Accordian>
  );
};