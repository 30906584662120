import React from 'react';
import { Grid } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { SolutionActionMenu } from '../../molecules/SolutionActionMenu';
import { SolutionActionFormProps } from './SolutionActionForm.props';

export const SolutionActionForm: React.FC<SolutionActionFormProps> = ({
  options,
  value,
  onChange,
  isError
}) => {
  return (
    <Grid container spacing={1} marginTop={1} marginBottom={1}>
      <Grid item xs={12}>
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
          Solution Action
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SolutionActionMenu
          options={options}
          value={value}
          onChange={onChange}
          error={isError}
        />
      </Grid>
    </Grid>
  );
};