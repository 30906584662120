import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { ShippingMethodFragmentProps, ShippingMethods } from '.';
import { ShipmentTypes, StructureTypeCategory, NodeAttributeType } from '../../../../configs/enums';
import { Tabs, TextColorProps } from '../../../../components/molecules/Tabs';
import { ManualShipping, ManualShippingData } from '../../../../components/organisms/ManualShipping';
import { IntegratedFreightShipping } from './IntegratedFreightShippingFragment/IntegratedFreightShippingFragment';
import { COLORS } from '../../../../configs/colors';
import { useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment, useGetNodeAttributesByNodeId } from '../../../../queries/structure-query';
import { NodeType } from '../../../../@types';
import { CourierData } from '../../../../@types/shipment.type';

export const ShippingMethodFragment: React.FC<ShippingMethodFragmentProps> = ({
  shipment,
  onSaveManual
}) => {
  const [isManual, setIsManual] = useState(true);
  const [freightIntegrationEnabled, setFreightIntegrationEnabled] = useState(false);
  const [couriers, setCouriers] = useState<Array<CourierData>>([]);

  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment(StructureTypeCategory.Operational, NodeType.ShippingCompany);
  const getNodeAttributesByNodeId = useGetNodeAttributesByNodeId(shipment.sourceNodeId);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      const courierList: Array<CourierData> = getStructuresByTypeCategoryCodeAndTypeCode.data?.map(item => ({ 
        id: item.id, 
        name: item.name 
      }));
      setCouriers(courierList);
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeAttributesByNodeId.data) {
      const freightIntegrationEnabled = getNodeAttributesByNodeId.data.some((item) => 
        item.propertyTypeCode === NodeAttributeType.FreightIntegrationEnabled && item.value.freightIntegrationEnabled
      );
      setFreightIntegrationEnabled(freightIntegrationEnabled);
    }
  }, [getNodeAttributesByNodeId.data]);

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Shipping Method</Typography>
        </Grid>
      </Grid>
    } defaultExpanded={shipment.shipmentTypeCode === ShipmentTypes.Dispatch ? true : false}>
      <Tabs
        name="shipping-method-tabs"
        tabTitles={freightIntegrationEnabled ? Object.values(ShippingMethods) : [ShippingMethods.Manual]}
        textColor={TextColorProps.Inherit}
        TabIndicatorProps={{
          style: {
            backgroundColor: COLORS.Black
          }
        }}
        isCustomColor={true}
        onTabChange={(tabType) => {
          setIsManual(tabType === ShippingMethods.Manual);
        }}
      >
        {freightIntegrationEnabled && <IntegratedFreightShipping shipment={shipment} couriers={couriers}/>}
        {isManual &&
            <ManualShipping
              couriers={couriers}
              onSave={(manualShippingData: ManualShippingData) => onSaveManual && onSaveManual(manualShippingData)} />
        }
      </Tabs>
    </Accordian>
  );
};