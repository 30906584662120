import { httpClient, HttpMethod } from './http-client';
import { ServiceStatusResponse } from '../@types/service.type';

export const createServiceStatus = (nodeType: ServiceStatusResponse) => {
  return httpClient('/serviceStatuses', HttpMethod.Post, nodeType);
};

export const updateServiceStatus = (code: string, nodeType: ServiceStatusResponse) => {
  return httpClient(`/serviceStatuses/${code}`, HttpMethod.Put, nodeType);
};

export const getServiceStatusByCode = (code: string) => {
  return httpClient(`/serviceStatuses/${code}`, HttpMethod.Get, null);
};

export const getAllServiceStatus = (): any => {
  return httpClient('/serviceStatuses', HttpMethod.Get, null);
};

export const getPossibleStatusesByServiceTypes = (code: string, serviceTypes: string[]) => {
  return httpClient(`/serviceStatuses/${code}/getByServiceTypes?serviceTypes=${JSON.stringify(serviceTypes)}`, HttpMethod.Get, null);
};