import { useMutation, useQueryClient, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { createPartAction, deletePartActionById, getAllPartActionTypes, getPartActionByServiceId, updatePartActionById } from '../api/partAction';
import { PartActionKey, PartActionTypesKey } from '../configs/partAction';
import { PartActionResponse, PartActionTypes } from '../@types/partAction.type';
import { ServiceLogAllKey } from '../configs/services';

export const useAddPartAction = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createPartAction(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartActionKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
    },
  });
};

export const useGetPartActionByServiceId = (id: number): UseQueryResult<PartActionResponse[]> => {
  return useQuery([PartActionKey, id], () => getPartActionByServiceId(id), { enabled: false });
};

export const useUpdatePartAction = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    await updatePartActionById(data.id, data.data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartActionKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
    },
  });
};

export const useDeletePartActionById = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await deletePartActionById(data.id);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartActionKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
    },
  });
};

export const useGetPartActionTypes = (): UseQueryResult<PartActionTypes[]> => {
  return useQuery([PartActionTypesKey], () => getAllPartActionTypes(), { enabled: false });
};