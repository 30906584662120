import { 
  OutcomeReason, 
  OutcomeReview, 
  SLAConfigData, 
  SLARuleType, 
  SLAScope, 
  SLATargetType, 
  SLATimerType 
} from '../@types/sla.type';
import { SLAContactRowData } from '../components/organisms/SLAContactRow';
import { httpClient, HttpMethod } from './http-client';

export const getAllSLAScopes = (): Array<SLAScope> => {
  return httpClient('/scopes', HttpMethod.Get, null);
};

export const getAllSLATimerTypes = (): Array<SLATimerType> => {
  return httpClient('/timerTypes', HttpMethod.Get, null);
};

export const getAllSLAConfigs = (): Array<SLAConfigData> => {
  return httpClient('/slaConfigs', HttpMethod.Get, null);
};

export const createSLAConfig = (slaTemplate: SLARuleType) => {
  return httpClient('/configs', HttpMethod.Post, slaTemplate);
};

export const updateSLAConfig = (id: number, slaTemplate: SLARuleType) => {
  return httpClient(`/configs/${id}`, HttpMethod.Put, slaTemplate);
};

export const getAllSLATargetTypes = (): Array<SLATargetType> => {
  return httpClient('/targetTypes', HttpMethod.Get, null);
};

export const getSLAsByServiceId = (serviceId: number): SLAContactRowData => {
  return httpClient(`/services/${serviceId}/slas`, HttpMethod.Get, null);
};

export const getAllOutcomeReasons = (): OutcomeReason => {
  return httpClient('/outcomeReason', HttpMethod.Get, null);
};

export const createOutcomeReview = (serviceId: any, outcomeReview: OutcomeReview) => {
  return httpClient(`/outcomeReview/${serviceId}`, HttpMethod.Post, outcomeReview);
};

export const updateSLATimeoutAt = (id: number, data: any) => {
  return httpClient(`/sla/date/${id}`, HttpMethod.Put, data);
};

export const resetSLAsByServiceId = (serviceId: number) => {
  return httpClient(`/services/${serviceId}/slas`, HttpMethod.Delete, null);
};

export const resetSLAById = (slaId: number) => {
  return httpClient(`/slas/${slaId}`, HttpMethod.Delete, null);
};

export const refreshSLAsByServiceId = (serviceId: number) => {
  return httpClient(`/slas/refresh/service/${serviceId}`, HttpMethod.Put, null);
};

export const getSLAConfigMetaData = () => {
  return httpClient('/slaConfigMetaData', HttpMethod.Get, null);
};
