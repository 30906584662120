import { PartConditionOptions } from '../@types/part.type';
import { PartActionResponse, CustomisePartActionCard, PartActionType, PartActionWarningDependancies, PartActionValidateType, PartActionTypes } from '../@types/partAction.type';
import { useSubLocationsForCreateServices } from '../queries/live-search-query';

export const arrayComparison = (obj:PartActionResponse, array:CustomisePartActionCard[]) => {
  return array.some((item: CustomisePartActionCard) => (item.value.actionId === obj.id) && item.value.type == obj.actionTypeCode);
};

export const filterActionCard = (card: CustomisePartActionCard, cardSet: CustomisePartActionCard[]) => {
  return cardSet.filter((partActionCardData) => partActionCardData.value.id !== card.value.id);
};

export const triggerWarning = (partAction: PartActionType, warningDependancies: PartActionWarningDependancies) => {
  switch (partAction) {
    case PartActionType.REPLACE: 
      return `Warning: In ${warningDependancies.in.serial1 ? `serial ${warningDependancies.in.serial1}` : ''} Part Type ${warningDependancies.in.partTypeName} is different to Out ${warningDependancies.out.serial1 ? `serial ${warningDependancies.out.serial1}` : ''} Part Type ${warningDependancies.out.partTypeName}.  A replace action is intended to be a like-for-like swap. Ensure the utility of the inbound part matches expectations.`;
  }
};

export const partActionConditionValidation = (cardType: string, condition: string, serial: string) => {
  switch (cardType) {
    case PartActionValidateType.INSTALL:
    case PartActionValidateType.REPLACEIN: 
      if (condition.toLowerCase() !== PartConditionOptions.GOOD && condition.toLowerCase() !== PartConditionOptions.RESERVED) {
        return `Warning: Part ${serial} is in Condition ${condition} and may not be suitable for usage.`;
      }
      break;
    case PartActionValidateType.REMOVE:
    case PartActionValidateType.DOAIN:
    case PartActionValidateType.REPLACEOUT: 
      if (condition.toLowerCase() !== PartConditionOptions.INSTALLED && condition.toLowerCase() !== PartConditionOptions.RESERVED) {
        return `Warning: Part ${serial} is in Condition ${condition} and may not be suitable for usage.`;
      }
      break;
    case PartActionValidateType.DOAOUT: 
      if (condition.toLowerCase() !== PartConditionOptions.RESERVED) {
        return `Warning: Part ${serial} is in Condition ${condition} and may not be suitable for usage.`;
      }
      break;
    default:
      return '';
  }
};

export const partActionInValidations = (condition: string, serial: string, nodeName: string) => {
  if (!condition) {
    return '';
  } else if (condition === PartConditionOptions.GOOD) {
    return `Warning: ${serial} is in an unexpected location; please confirm correct part has been selected prior to proceeding.`;
  } else {
    return `Warning: ${serial} is in an unexpected location ${nodeName}, condition ${condition}. You may proceed, however you are recommended to seek assistance.`;
  }
};

export const partActionOutValidations = async (validationType: string, serial: string, serviceParentNode: string, serviceBaseNode: string, partBaseNode: string, partBaseNodeId: number, partTypeName: string) => {
  if (validationType === 'site-hierarchy') {
    const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();
    
    const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(partBaseNodeId);
    const main = subLocationsResult?.find(subLocation => subLocation.isMain);

    const partParentNode = main ? `${main} /` : '';
    serviceBaseNode = serviceBaseNode ? `/ ${serviceBaseNode}` : '';
    
    return `Warning: ${serial} is not at ${serviceParentNode} ${serviceBaseNode}, but at ${partParentNode} ${partBaseNode}. This is an unexpected part location; please confirm the correct part has been selected prior to proceeding. installed in ${partTypeName}: ${serial}`;
  } else {
    const baseOrParentnode = serviceBaseNode ? serviceBaseNode : serviceParentNode;

    return `Warning: ${serial} is not at ${baseOrParentnode}, but at ${partBaseNode}.  Please confirm the correct part has been selected prior to proceeding.`;
  }
};

export const getPartActionTypesDropdownData = (partActionData: PartActionTypes[]) => {
  return partActionData.map(partAction => ({
    value: partAction.code,
    label: partAction.name
  }));
};