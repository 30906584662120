import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { AddStockManifestFragmentProps } from './AddStockManifestFragment.props';
import { AddStockManifestRowItemListFragment } from './AddStockManifestRowItemListFragment';
import { AddStockManifestRowItemValueType } from '../../../../components/organisms/AddStockManifestRowItem';
import { LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { PartTypeLiveSearchData } from '../../../../@types/part-type.type';
import { useAdvanceBulkSearchPartTypes, useAdvanceSearchPartTypes } from '../../../../queries/live-search-query';
import { GRID_SPACING } from '../../../../configs/ui-constants';
import { useDeleteNonSerializedManifests, useDeleteSerializedManifests, useGetAllShipmentManifestsByShipmentId } from '../../../../queries/shipment-query';
import { useGetPartTypeManifestById } from '../../../../queries/part-type-query';
import { PartConditionOptions } from '../../../../@types/part.type';
import { ManifestSerialObject } from '../../../../@types/shipment.type';
import { SAMPLE_MANIFEST_SERIAL_DETAIL, SAMPLE_NON_SERIALISED_ATTRIBUTES } from '../../../../constants/shipment';
import { AdvanceLiveSearchBox } from '../../../../components/atoms/AdvanceLiveSearchBox';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { parseExcelData } from '../../../../utils/common';
import { LocationNodeData, SerialisationType, NodeType } from '../../../../@types';
import { useGetNodeSubTree } from '../../../../queries/structure-query';

export const AddStockManifestFragment: React.FC<AddStockManifestFragmentProps> = ({
  shipmantId,
  applicableNodeId,
  setSerializedManifestValues,
  serializedManifestValues,
  setNonSerializedManifestValues,
  nonSerializedManifestValues,
  isPending,
  setAddStockDetailClientData
}) => {
  const [partTypeId, setPartTypeId] = useState<number>(0);
  const [manifestedData, setManifestedData] = useState<ManifestSerialObject>(SAMPLE_MANIFEST_SERIAL_DETAIL);
  const [isPaste, setIsPaste] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [nodeIdToCheckClient, setNodeIdToCheckClient] = useState<number>(0);

  const advanceSearchPartTypes = useAdvanceSearchPartTypes();
  const advanceBulkSearchPartTypes = useAdvanceBulkSearchPartTypes();
  const getAllShipmentManifestsByShipmentId = useGetAllShipmentManifestsByShipmentId(shipmantId || 0);
  const deleteSerializedManifests = useDeleteSerializedManifests();
  const deleteNonSerializedManifests = useDeleteNonSerializedManifests(shipmantId || 0);
  const getPartTypeManifestById = useGetPartTypeManifestById(partTypeId);
  const getNodeSubTreeQuery = useGetNodeSubTree(nodeIdToCheckClient.toString());

  useEffect(() => {
    if(getAllShipmentManifestsByShipmentId.data) {
      const manifestData = getAllShipmentManifestsByShipmentId.data;

      setNonSerializedManifestValues(manifestData.nonSerializedManifestData || []);
      setSerializedManifestValues(manifestData.serialisedManifestData || []);
    }

  }, [getAllShipmentManifestsByShipmentId.data]);

  useEffect(() => {
    if (getPartTypeManifestById.data) {
      setManifestedData({
        ...getPartTypeManifestById.data,
        condition: PartConditionOptions.GOOD
      });
    }
  }, [getPartTypeManifestById.data]);

  useEffect(() => {
    getPartTypeManifestById.refetch();
  }, [partTypeId]);

  const handleAddSerialisedNew = (partTypeLiveSearchData: PartTypeLiveSearchData, index: number) => {
    const rowData = {
      id: -1 - index,
      partTypeId: partTypeLiveSearchData.id,
      partTypeName: partTypeLiveSearchData.name,
      partTypeNo: partTypeLiveSearchData.number,
      serials: SAMPLE_MANIFEST_SERIAL_DETAIL,
      price: 0,
      quantity: 0,
      serialisation: partTypeLiveSearchData.serialisation
    };

    if(isPaste) {
      return rowData;
    }

    setSerializedManifestValues([...serializedManifestValues, rowData].map((item, index) => ({ ...item, id: index })));
    updateClientData(partTypeLiveSearchData);
    setManifestedData((prevManifestData) => ({ ...prevManifestData, smartInput: '' }));
  };

  const handleAddNonSerialisedNew = (partTypeLiveSearchData: PartTypeLiveSearchData, index: number) => {
    const rowData = {
      id: -1 - index,
      partTypeId: partTypeLiveSearchData.id,
      partTypeName: partTypeLiveSearchData.name,
      partTypeNo: partTypeLiveSearchData.number,
      attributes: SAMPLE_NON_SERIALISED_ATTRIBUTES,
      price: 0,
      quantity: 0,
      serialisation: partTypeLiveSearchData.serialisation
    };

    if(isPaste) {
      return rowData;
    }
    setNonSerializedManifestValues([...nonSerializedManifestValues, rowData].map((item, index) => ({ ...item, id: index })));
    updateClientData(partTypeLiveSearchData);
    setManifestedData((prevManifestData) => ({ ...prevManifestData, smartInput: '' }));
  };

  const updateClientData = (partTypeLiveSearchData: PartTypeLiveSearchData) => {
    if (nonSerializedManifestValues.length === 0 && serializedManifestValues.length === 0 && !applicableNodeId) {
      const partTypeApplicableNodeIds = partTypeLiveSearchData.applicableNodeIds;

      if(partTypeApplicableNodeIds) {
        const applicableNodeIdArray = JSON.parse(partTypeApplicableNodeIds);
        applicableNodeIdArray && applicableNodeIdArray.length && setNodeIdToCheckClient(applicableNodeIdArray[0]);
      }
    }
  };

  useEffect(() => {
    getNodeSubTreeQuery.refetch();
  }, [nodeIdToCheckClient]);

  useEffect(() => {
    if(getNodeSubTreeQuery.data) {
      const clientNode = getNodeSubTreeQuery.data.find(node => {
        return node.nodeTypeCode === NodeType.Client;
      });

      if(clientNode) {
        setAddStockDetailClientData && setAddStockDetailClientData(clientNode.id.toString(), `[${clientNode.id}]`, ['any'], `${clientNode.name} -> Any`);
      }
    }
  }, [getNodeSubTreeQuery.data]);

  useEffect(() => {
    if (advanceBulkSearchPartTypes.data) {
      const serializedPartTypes: AddStockManifestRowItemValueType[] = [];
      const nonSerializedPartTypes: AddStockManifestRowItemValueType[] = [];

      advanceBulkSearchPartTypes.data.map((partTypeLiveSearchResult: PartTypeLiveSearchData, index: number) => {
        if (partTypeLiveSearchResult.serialisation === SerialisationType.Serialised) {
          const newItem = handleAddSerialisedNew(partTypeLiveSearchResult, index);
          if(newItem) {
            serializedPartTypes.push(newItem);
          }
        } else {
          const newItem = handleAddNonSerialisedNew(partTypeLiveSearchResult, index);
          if(newItem) {
            nonSerializedPartTypes.push(newItem);
          }
        }     
      });

      if(serializedPartTypes.length) {
        setSerializedManifestValues([...serializedManifestValues, ...serializedPartTypes].map((item, index) => ({ ...item, id: index })));
        setManifestedData((prevManifestData) => ({ ...prevManifestData, smartInput: '' }));
      }

      if(nonSerializedPartTypes.length) {
        setNonSerializedManifestValues([...nonSerializedManifestValues, ...nonSerializedPartTypes].map((item, index) => ({ ...item, id: index })));
        setManifestedData((prevManifestData) => ({ ...prevManifestData, smartInput: '' }));
      }
    }
  }, [advanceBulkSearchPartTypes.data]);

  const deleteSavedManifests = async (isNSPartType: boolean, manifest: AddStockManifestRowItemValueType) => {
    if (!isNSPartType) {
      await deleteSerializedManifests.mutateAsync(manifest);
    } else {
      await deleteNonSerializedManifests.mutateAsync({
        manifestId: manifest.manifestId
      });
      await getPartTypeManifestById.refetch();
    }
  };

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (manifestedData?.smartInput && isPaste) {
        setShowProgress(true);

        const formatedManifestedData = parseExcelData(manifestedData.smartInput || '') as Array<Array<string>>;
        const formatedManifestedAsFlatArray = ([] as string[]).concat(...formatedManifestedData);

        const nonSerialsWithExistance: any = await advanceBulkSearchPartTypes.mutateAsync({   
          searchableValues: formatedManifestedAsFlatArray,
          applicableNodeId: applicableNodeId
        });
        setShowProgress(false);
        setIsPaste(false);
        
        return nonSerialsWithExistance;
      }

      setShowProgress(false);
      setIsPaste(false);
    }
  };

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Manifest</Typography>
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <Grid container justifyContent="space-between">
        <Grid item xs={4} mb={GRID_SPACING}>
          <AdvanceLiveSearchBox
            title={isPaste ? 'Part Type Enter' : 'Bulk Part Type Search'}
            timeOffset={400}
            showProgress={showProgress}
            value={manifestedData.smartInput || ''}
            disabled={isPending}
            overrideOnEnterKey={true}
            onClearValue={() => {
              setManifestedData({ ...manifestedData, smartInput: '' });
              setIsPaste(false);
              setShowProgress(false);
            }}
            renderItem={(props: any, option: any) => { 
              if (!isPaste) {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }
            }}
            onChange={async (val: LocationNodeData) => {
              setManifestedData({ ...manifestedData, smartInput: val.name });

              if(val?.name) {
                const formatedManifestedData = parseExcelData(val.name || '') as Array<Array<string>>;
                const formatedManifestedAsFlatArray = ([] as string[]).concat(...formatedManifestedData);
                setShowProgress(true);
                await advanceBulkSearchPartTypes.mutateAsync({   
                  searchableValues: formatedManifestedAsFlatArray,
                  applicableNodeId: applicableNodeId
                });
                setShowProgress(false);
              }
            }}
            onTextChange={(event) => { 
              setManifestedData({ ...manifestedData, smartInput: event.target.value });
            }}
            onApiInvoke={async (searchableData: string) => {
              return await advanceSearchPartTypes.mutateAsync({
                searchableValues: searchableData,
                applicableNodeId: `${applicableNodeId}`
              });
            }}
            onPaste={() => {
              setIsPaste(true);
            }}
            onKeyDown={onKeyDown}
            isPaste={isPaste}
          />
        </Grid>
        <Grid container justifyContent="space-between">
          <AddStockManifestRowItemListFragment
            setSerializedManifestValues={setSerializedManifestValues}
            serializedManifestValues={serializedManifestValues}
            setNonSerializedManifestValues={setNonSerializedManifestValues}
            nonSerializedManifestValues={nonSerializedManifestValues}
            isPending={isPending}
            onSerialsClick={(e, partTypeId: number) => {
              setPartTypeId(partTypeId);
            }}
            onDelete={(isNSPartType: boolean, manifest: AddStockManifestRowItemValueType) => deleteSavedManifests(isNSPartType, manifest)}
          />
        </Grid>
      </Grid>
    </Accordian>
  );
};