import React, { useState } from 'react';
import { Grid, ImageListItem, ImageListItemBar, Stack, Tooltip } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { MultipleImageUploadProps } from './MultipleImageUpload.props';
import { UploadButton } from '../../molecules/UploadButton';
import { CloseButton, ImageBox, MainBox, MainImageBox, MenuButton, MenuIcon, StyledButton, StyledGrid, StyledImage, StyledImageListGrid, Td } from './MultipleImageUpload.styles';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { PART_TYPES_IMAGES_MIME_TYPES } from '../../../configs/envs';
import { Modal } from '../../atoms/Modal';

export const MultipleImageUpload: React.FC<MultipleImageUploadProps> = ({
  defaultImage,
  subImages,
  onClickPicture,
  onExchangePicture,
  onDeleteMainImage,
  onDeleteSubImage,
}) => {
  const [fullImage, setFullImage] = useState('');
  const [open, setOpen] = useState(false);

  const onOpenPicture = async (image: any) => {
    if (image) {
      setFullImage(image);
      setOpen(true);
    }
  };

  const handleTagBoxClose = () => setOpen(false);

  return (
    <MainBox>
      <table>
        <tr>
          <Td>
            <UploadButton 
              fileTypes={PART_TYPES_IMAGES_MIME_TYPES.split(',')} 
              maxFileSize={30} 
              setFileDetails={onClickPicture} 
              label="Upload Photo" 
              icon={<AddPhotoAlternateIcon fontSize="small"/>}
              isText={false}
            />
            {defaultImage?.url &&
              <StyledGrid mt={2}>
                <CloseButton color="error" onClick={() => onDeleteMainImage && onDeleteMainImage(defaultImage?.id)} sx={{ zIndex: 1 }}>
                  <Typography variant={TypographyVariantProps.H5}>x</Typography>
                </CloseButton>
                <MainImageBox src={defaultImage?.url} onClick={() => onOpenPicture(defaultImage?.url)}/>
              </StyledGrid>
            }
          </Td>
          <Td><StyledGrid pl={4} /></Td>
          <Td>
            {subImages.length > 0 &&
              <StyledImageListGrid mt={6}>
                {subImages.map((subImage: any, index: number) => 
                  <StyledGrid key={index} mb={2}>
                    <CloseButton color="error" onClick={() => onDeleteSubImage && onDeleteSubImage(subImage, subImage.id)} sx={{ zIndex: 1 }}>
                      <Typography variant={TypographyVariantProps.H5}>x</Typography>
                    </CloseButton>
                    <ImageListItem>
                      <ImageBox src={subImage.url} onClick={() => onOpenPicture(subImage.url)}/>
                      <ImageListItemBar
                        actionIcon={
                          <Stack direction="row">
                            <Tooltip 
                              title={
                                <Typography variant={TypographyVariantProps.Body1}>
                                  <StyledButton 
                                    onClick={() => onExchangePicture && onExchangePicture(subImage)}
                                  >
                                    Set as Default
                                  </StyledButton>
                                </Typography>                   
                              }  
                            >
                              <MenuButton
                                variant="text"
                                color="inherit"
                              >
                                <MenuIcon />
                              </MenuButton>
                            </Tooltip>
                          </Stack>
                        }
                      />
                    </ImageListItem>
                  </StyledGrid>
                )}
              </StyledImageListGrid>
            }
          </Td>
        </tr>
      </table>
      <Modal
        open={open}
        onClose={handleTagBoxClose}
      >
        <Grid onMouseDown={handleTagBoxClose} sx={{ border: 'none', outline: 'none' }}>
          <StyledImage src={fullImage}/>
        </Grid>
      </Modal>
    </MainBox>
  );
};