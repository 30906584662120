import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { GeneralPermissionGrantFragmentProps } from './GeneralPermissionGrantFragment.props';
import { BulkUpdateInputItems } from '../../../../components/molecules/BulkUpdateInputItems';
import { BulkUpdateInputItemType } from '../../../../@types/bulkUpdateInputItem.type';
import { LINKED_LIST_DROPDOWN_OPTIONS_FOR_SERIALISED, SAMPLE_IS_CHECKED_VALUES_DATA, SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA } from '../../../../constants/part';
import { IsCheckedValuesType } from '../SerialisedPartsUpdateSelection.props';
import { StyledLiveSearchDiv } from './GeneralPermissionGrantFragment.styles';
import { Checkbox, CheckboxColorProps } from '../../../../components/atoms/Checkbox';
import { LocationNodeData } from '../../../../@types';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { useSearchNodePartsWithoutPartId } from '../../../../queries/live-search-query';
import { LinkedListProperties } from '../../../../@types/bulkPartUpdate.type';
import { PartConditionDropdown, SearchCode, SerialisedPartsUpdateSelectionDataType } from '../../../../@types/part.type';
import { useGetPartConditions } from '../../../../queries/part-query';

export const GeneralPermissionGrantFragment: React.FC<GeneralPermissionGrantFragmentProps> = ({
  isOnlySerialized,
  generalPermissionGrantDropdownValues,
  isSwHwFwFleetTagPermisionEnabled,
  partData,
  selectedPartTypeId,
  generalPermissionUpdateValues
}) => {
  const [isCheckedValues, setIsCheckedValues] = useState<IsCheckedValuesType>(SAMPLE_IS_CHECKED_VALUES_DATA);
  const [generalPermissionGrantValues, setGeneralPermissionGrantValues] = useState<SerialisedPartsUpdateSelectionDataType>(SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA);
  const [structureNodeName, setStructureNodeName] = useState('');
  const [conditions, setConditions] = useState<PartConditionDropdown[]>([]);
  const [name, setName] = useState<string>('');

  const getPartConditions = useGetPartConditions();

  useEffect(() => {
    generalPermissionUpdateValues(generalPermissionGrantValues);
  }, [generalPermissionGrantValues]);

  useEffect(() => {
    getPartConditions.data && setConditions(getPartConditions.data);
  }, [getPartConditions.data]);

  useEffect(() => {
    if(!selectedPartTypeId) {
      setIsCheckedValues({ ...isCheckedValues, isCheckedSoftware: false, isCheckedHardware: false, isCheckedFirmware: false });
      setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, software: '-1', hardware: '-1', firmware: '-1' });
    }
  }, [selectedPartTypeId]);

  const searchNodePartsWithoutPartId = useSearchNodePartsWithoutPartId();

  const valueIntializer = (value: string) => {
    switch(value) {
      case LinkedListProperties.Notes:
        generalPermissionGrantValues.notes = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedNotes: false });
        break;
      case LinkedListProperties.SoftwareVersionId:
        generalPermissionGrantValues.software = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedSoftware: false });
        break;
      case LinkedListProperties.FirmwareVersionId:
        generalPermissionGrantValues.firmware = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedFirmware: false });
        break;
      case LinkedListProperties.HardwareRevisionId:
        generalPermissionGrantValues.hardware = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedHardware: false });
        break;
      case LinkedListProperties.FleetTagId:
        generalPermissionGrantValues.fleetTag = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedFleetTag: false });
        break;
      case LinkedListProperties.LocationId:
        generalPermissionGrantValues.locationId = '';
        generalPermissionGrantValues.locationTypeCode = 'node';
        setStructureNodeName('');
        setIsCheckedValues({ ...isCheckedValues, isCheckedStructureNode: false });
        break;
      case LinkedListProperties.ConditionCode:
        generalPermissionGrantValues.conditionCode = '';
        setIsCheckedValues({ ...isCheckedValues, isCheckedCondition: false });
        break;
      case LinkedListProperties.ParentPartId:
        generalPermissionGrantValues.locationId = '';
        generalPermissionGrantValues.locationTypeCode = 'part';
        setStructureNodeName('');
        setIsCheckedValues({ ...isCheckedValues, isCheckedStructureNode: false });
        break;
    }
  };

  return (
    <Stack>
      <Stack direction="column" mb={3} width="1194px">
        <BulkUpdateInputItems
          isChecked={isCheckedValues.isCheckedNotes}
          label="Notes"
          itemInputValue={generalPermissionGrantValues?.notes || ''}
          rows={5}
          renderNotes={true}
          disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.Notes}
          inputItemType={BulkUpdateInputItemType.TextArea}
          onChangeCheckbox={(value) => {
            setIsCheckedValues({ ...isCheckedValues, isCheckedNotes: value });
            setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, notes: '' });
          }}
          onChangeItemInputValue={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, notes: value })}
        />
      </Stack>
      {isSwHwFwFleetTagPermisionEnabled &&
        <Stack direction="row" spacing={2} mb={3} flexWrap="wrap">
          <Stack direction="row" spacing={8}>
            {isOnlySerialized && 
              <>
                <BulkUpdateInputItems
                  id="software-select"
                  isChecked={isCheckedValues.isCheckedSoftware}
                  label="Software Version"
                  minWidth="250px"
                  itemInputValue={generalPermissionGrantValues?.software || '-1'}
                  inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
                  dropdownItems={generalPermissionGrantDropdownValues.softwareOptions}
                  disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.SoftwareVersionId || !selectedPartTypeId}
                  onChangeCheckbox={(value) => {
                    setIsCheckedValues({ ...isCheckedValues, isCheckedSoftware: value });
                    setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, software: '-1' });
                  }}
                  onChangeItemInputValue={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, software: value })}
                />
                <BulkUpdateInputItems
                  id="firmware-select"
                  isChecked={isCheckedValues.isCheckedFirmware}
                  label="Firmware Version"
                  minWidth="250px"
                  itemInputValue={generalPermissionGrantValues?.firmware || '-1'}
                  inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
                  dropdownItems={generalPermissionGrantDropdownValues.firmwareOptions}
                  disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.FirmwareVersionId || !selectedPartTypeId}
                  onChangeCheckbox={(value) => {
                    setIsCheckedValues({ ...isCheckedValues, isCheckedFirmware: value });
                    setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, firmware: '-1' });
                  }}
                  onChangeItemInputValue={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, firmware: value })}
                />
                <BulkUpdateInputItems
                  id="hardware-select"
                  isChecked={isCheckedValues.isCheckedHardware}
                  label="Hardware Version"
                  minWidth="250px"
                  itemInputValue={generalPermissionGrantValues?.hardware || '-1'}
                  inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
                  dropdownItems={generalPermissionGrantDropdownValues.hardwareRevisionOptions}
                  disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.HardwareRevisionId || !selectedPartTypeId}
                  onChangeCheckbox={(value: any) => {
                    setIsCheckedValues({ ...isCheckedValues, isCheckedHardware: value });
                    setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, hardware: '-1' });
                  }}
                  onChangeItemInputValue={(value: any) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, hardware: value })}
                />
              </>
            }
            <BulkUpdateInputItems
              id="fleet-tag-select"
              isChecked={isCheckedValues.isCheckedFleetTag}
              label="Fleet Tag"
              minWidth="250px"
              itemInputValue={generalPermissionGrantValues?.fleetTag || '-1'}
              inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
              dropdownItems={generalPermissionGrantDropdownValues.fleetTagOptions}
              disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.FleetTagId}
              onChangeCheckbox={(value) => {
                setIsCheckedValues({ ...isCheckedValues, isCheckedFleetTag: value });
                setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, fleetTag: '-1' });
              }}
              onChangeItemInputValue={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, fleetTag: value })}
            />
          </Stack>
        </Stack>
      }
      <Stack direction="row" mb={3}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={isCheckedValues.isCheckedStructureNode}
          disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.LocationId}
          onChange={(event) => {
            setIsCheckedValues({ ...isCheckedValues, isCheckedStructureNode: event.target.checked });
            setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, locationId: '', locationTypeCode: '' });
          }}
        />
        <StyledLiveSearchDiv>
          <LiveSearchBox
            disabled={!isCheckedValues.isCheckedStructureNode}
            title="Location Node Search"
            timeOffset={400}
            value={structureNodeName || ''}
            onClearValue={() => {
              setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, locationId: '', locationTypeCode: '' });
              setStructureNodeName('');
              setName('');
            }}
            renderItem={(props: any, option: any) => {
              if (name?.toString().toLocaleLowerCase().includes(SearchCode.AM_LID.toLocaleLowerCase())) {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} isAmLidSearch={true} />
                  </LiveSearchListItem>
                );
              } else if (name?.toString().toLocaleLowerCase().includes(SearchCode.AM_PID.toLocaleLowerCase())) {
                if (partData.some((part) => part.id === option.id)) {
                  return <></>;
                }

                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} isAmPidOrSHashSearch={true} />
                  </LiveSearchListItem>
                );
              } else if (name?.toString().toLocaleLowerCase().includes(SearchCode.S_HASH)) {
                if (partData.some((part) => part.id === option.id)) {
                  return <></>;
                }

                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} isAmPidOrSHashSearch={true} />
                  </LiveSearchListItem>
                );
              } else {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} isAmLidSearch={true}/>
                  </LiveSearchListItem>
                );
              }
            }}
            onChange={(locationPartData: LocationNodeData) => {
              let locationTypeCode = '';

              if (locationPartData.id && locationPartData.locationTypeCode) {
                locationTypeCode = 'part';
              } else if (locationPartData.id) {
                locationTypeCode = 'node';
              }

              setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, locationId: locationPartData.id.toString(), locationTypeCode });
              setStructureNodeName(locationPartData.name);
            }}
            onApiInvoke={async (searchName: string) => {
              setName(searchName);
              return await searchNodePartsWithoutPartId.mutateAsync(searchName.replace('#', '%23'));
            }}
          />
        </StyledLiveSearchDiv>
      </Stack>
      <Stack direction="row" spacing={2} mb={3} flexWrap="wrap">
        <Stack direction="row" spacing={8}>
          <BulkUpdateInputItems
            id="condition-select"
            isChecked={isCheckedValues.isCheckedCondition}
            label="Condition"
            minWidth="250px"
            itemInputValue={generalPermissionGrantValues?.conditionCode || ''}
            inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
            dropdownItems={conditions}
            disabled={generalPermissionGrantValues.linkedFieldColumn === LinkedListProperties.ConditionCode}
            onChangeCheckbox={(value) => {
              setIsCheckedValues({ ...isCheckedValues, isCheckedCondition: value });
              setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, conditionCode: '-1' });
            }}
            onChangeItemInputValue={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, conditionCode: value })}
          />
          <BulkUpdateInputItems
            id="linked-field-select"
            isChecked={!!generalPermissionGrantValues.isActiveLinkedField}
            label="Linked Field"
            minWidth="250px"
            itemInputValue={generalPermissionGrantValues?.linkedFieldColumn || ''}
            inputItemType={BulkUpdateInputItemType.SingleSelectDropdown}
            dropdownItems={LINKED_LIST_DROPDOWN_OPTIONS_FOR_SERIALISED}
            onChangeCheckbox={(value) => setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, isActiveLinkedField: value, linkedFieldColumn: '-1' })}
            onChangeItemInputValue={(value) => {
              valueIntializer(value);
              setGeneralPermissionGrantValues({ ...generalPermissionGrantValues, linkedFieldColumn: value });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};