import React from 'react';
import { Stack } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { CheckItem } from '../../molecules/CheckItem';
import { CheckItemGroupProps, CheckItemProps } from './CheckItemGroup.props';

export const CheckItemGroup: React.FC<CheckItemGroupProps> = ({
  title,
  value,
  onChange,
}) => {
  const handleOnChange = (item: CheckItemProps, val: boolean) => {
    const modifiedValues = value.map(obj => obj.field === item.field ? { 
      ...item, value: val 
    } : obj);
    onChange(modifiedValues);
  };

  const handleCheckAll = () => {
    const modifiedValues = value.map(obj => {
      return { ...obj, value: true };
    });
    onChange(modifiedValues);
  };

  const handleUncheckAll = () => {
    const modifiedValues = value.map(obj => {
      return { ...obj, value: false };
    });
    onChange(modifiedValues);
  };

  return (
    <Stack direction="column">
      <Stack direction="column" spacing={0.5}>
        <Typography variant={TypographyVariantProps.Body1}>{title}</Typography>
        <Stack direction="row" spacing={1}>
          <Button variant={ButtonVariantProps.Text} onClick={handleCheckAll}>Select All</Button>
          &nbsp;&nbsp;|
          <Button variant={ButtonVariantProps.Text} onClick={handleUncheckAll}>Deselect All</Button>
        </Stack>
      </Stack>
      {value.map((item: CheckItemProps, index: number) => (
        <CheckItem
          key={index}
          label={item.title}
          value={item.value}
          onChange={(val) => handleOnChange(item, val)}
        />
      ))}
    </Stack>
  );
};
