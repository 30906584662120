import React from 'react';
import { Stack } from '@mui/material';
import { ServiceTaskQuoteProps } from './ServiceTaskQuote.props';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { TypographyVariantProps } from '../../atoms/Typography';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { ColorProps } from '../../../@types';
import { Switch } from '../../atoms/Switch';
import { Title } from './ServiceTaskQuote.styles';

export const ServiceTaskQuote: React.FC<ServiceTaskQuoteProps> = ({
  serviceTaskQuoteData,
  helperTextData,
  onChange
}) => {
  return (
    <Stack direction="column" spacing={GRID_SPACING}>
      <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
        <Title variant={TypographyVariantProps.Body1}>Value Collection</Title>
        <TextField
          fullWidth
          label="$"
          type={TextFieldTypeProps.Number}
          inputProps={{ min: 0 }}
          helperText={helperTextData.collectionHelperText}
          error={!!helperTextData.collectionHelperText}
          value={serviceTaskQuoteData.valueCollection}
          onChange={(val) => onChange({ ...serviceTaskQuoteData, valueCollection: parseInt(val) })}
        />
      </Stack>
      <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
        <Title variant={TypographyVariantProps.Body1}>Issued To</Title>
        <TextField
          fullWidth
          value={serviceTaskQuoteData.issuedTo}
          helperText={helperTextData.issuedHelperText}
          error={!!helperTextData.issuedHelperText}
          onChange={(val) => onChange({ ...serviceTaskQuoteData, issuedTo: val })}
        />
      </Stack>
      <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
        <Title variant={TypographyVariantProps.Body1}>Contact Email</Title>
        <TextField
          fullWidth
          helperText={helperTextData.contactHelperText}
          error={!!helperTextData.contactHelperText}
          value={serviceTaskQuoteData.contactEmail}
          onChange={(val) => onChange({ ...serviceTaskQuoteData, contactEmail: val })}
        />
      </Stack>
      <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
        <Title variant={TypographyVariantProps.Body1}>Approved</Title>
        <Switch checked={serviceTaskQuoteData.isApproved} color={ColorProps.Success} onChange={() => onChange({ ...serviceTaskQuoteData, isApproved: !serviceTaskQuoteData.isApproved })} />
      </Stack>
      <TextField
        fullWidth
        multiline
        rows={4}
        label="Notes"
        helperText={helperTextData.notesHelperText}
        error={!!helperTextData.notesHelperText}
        value={serviceTaskQuoteData.notes}
        onChange={(val) => onChange({ ...serviceTaskQuoteData, notes: val })}
      />
    </Stack>
  );
};