import { DispatchDetail } from '../../../../@types/shipment.type';
import { ManualShippingData } from '../../../../components/organisms/ManualShipping';

export interface ShippingMethodFragmentProps {
  shipment: DispatchDetail;
  onSaveManual?: (manualShippingData: ManualShippingData) => void;
}

export enum ShippingMethods {
  IntegratedFreight = 'Integrated Freight', 
  Manual = 'Manual'
}