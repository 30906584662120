import React from 'react';
import { Stack } from '@mui/material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TypographyVariantProps } from '../../atoms/Typography';
import { ConfirmationPopupProps } from './ConfirmationPopup.props';
import { StyledDescriptionTypography, StyledMainHeaderTypography } from './ConfirmationPopup.styles';
import { Modal } from '../../atoms/Modal';
import { ContentWrapperBox } from '../MultiClientSelector/MultiClientSelector.styles';
import { CloseIcon } from '../../atoms/CloseIcon';

export const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  open,
  mainHeader,
  description,
  leftButtonName,
  rightButtonName,
  leftButtonOnClick,
  rightButtonOnClick,
  leftButtonVariant
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={leftButtonOnClick} />
        <Stack direction="row">
          <StyledMainHeaderTypography variant={TypographyVariantProps.H6}>
            {mainHeader}
          </StyledMainHeaderTypography>
        </Stack>
        <Stack direction="row">
          <StyledDescriptionTypography variant={TypographyVariantProps.Body1}>
            {description}
          </StyledDescriptionTypography>
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button
            variant={leftButtonVariant || ButtonVariantProps.Primary}
            onClick={leftButtonOnClick}
          >
            {leftButtonName}
          </Button>
          <Button
            variant={ButtonVariantProps.Primary}
            onClick={rightButtonOnClick}
          >
            {rightButtonName}
          </Button>
        </Stack>
      </ContentWrapperBox>
    </Modal>
  );
};