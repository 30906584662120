import React, { useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { Checkbox, Table } from '@mui/material';
import { ChevronRightOutlined } from '@mui/icons-material';
import { PermissionTreeV2Props } from './PermissionTreeV2.props';
import { MarginBox, StyledTableRow, FormLabel } from './PermissionTreeV2.styles';
import { SizeProps } from '../../../@types';

export const PermissionTreeV2: React.FC<PermissionTreeV2Props> = ({
  handlePermissionChange,
  permissions,
  className
}) => {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const [checked, setChecked] = useState<any[]>([]);
  const [checkedIds] = useState<any[]>([]);

  const handleClick = (code: string) => {
    const temp = { ...open };
    temp[code] = !temp[code];
    setOpen(temp);
  };

  //Here we check all the children and set parent isGranted value for inital dataset
  const updateAllParents = (nodes: any[]) => {
    nodes.forEach(node => {
      if (node.children && node.children.length > 0) {
        updateAllParents(node.children);
      }
      else {
        updateParents(permissions, nodes, node.code, node.isGranted);
      }
    });
  };

  useEffect(() => {
    updateAllParents(permissions);
    setChecked(permissions);
    handlePermissionChange(permissions);
  }, [permissions]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, node: any, parent: any) => {
    updateNode(event.target.checked, node);

    if (parent) {
      updateParents(checked, parent.children, node.code, event.target.checked);
    }

    updateChildren(event.target.checked, node.children);
  };

  const getParentCode = (code: string) => {
    const splits = code.split('.');
    if (splits.length > 1) {
      splits.pop();
      return splits.join('.');
    }
    return null;
  };

  const updateParents = (allNodes: any[], nodes: any[], searchCode: string, isGranted: boolean) => {
    nodes.forEach((node: any) => {
      if (node.code === searchCode) {
        updateNode(isGranted, node);
        const parentCode = getParentCode(node.code);
        if (parentCode) {
          updateParents(allNodes, allNodes, parentCode, isGranted);
        }
      }
      updateParents(allNodes, node.children, searchCode, isGranted);
    });
  };

  const updateChildren = async (isGranted: boolean, children: any[]) => {
    children.map((node) => {
      updateNode(isGranted, node);

      if (node.children.length > 0) {
        updateChildren(isGranted, node.children);
      }
    });
  };

  const updateNode = (isGranted: boolean, node: any) => {
    node.isGranted = isGranted;
    setChecked([...checked]);
    checkedIds.push(node.code);
    handlePermissionChange([...checked]);
  };

  const getExpandIcon = (permissionId: string, leftMargin: number) => {
    if (open[permissionId]) {
      return <ExpandLess sx={{ verticalAlign: 'middle', pl: leftMargin }} onClick={() => handleClick(permissionId)} />;
    } else {
      return <ChevronRightOutlined sx={{ verticalAlign: 'middle', pl: leftMargin }} onClick={() => handleClick(permissionId)} />;
    }
  };

  const isAnyChildHasGrantedPermission = (children: any[]): boolean => {
    let isChildNodeHasPermissionGranted = children.some(node => node.isGranted);

    if (!isChildNodeHasPermissionGranted) {
      children.every((child: any) => {
        isChildNodeHasPermissionGranted = isAnyChildHasGrantedPermission(child.children);
        return !isChildNodeHasPermissionGranted;
      });
    }

    return isChildNodeHasPermissionGranted;
  };

  const getIndeterminateState = (permission: any) => {
    return isAnyChildHasGrantedPermission(permission.children) && !permission.children.every((child: any) => child.isGranted);
  };

  const getBranch = (permissions: any[], leftMargin: number, parent: any) => {
    return <>
      {permissions && permissions.map((permission, index) => (
        <>
          <StyledTableRow key={'P' + index}>
            {permission.children.length > 0 ? (
              getExpandIcon(permission.code, leftMargin)
            ) : (
              <MarginBox sx={{ pl: leftMargin }} />
            )}
            &ensp;
            <FormLabel
              label={permission.name}
              control={
                <Checkbox
                  size={SizeProps.Small}
                  checked={permission.isGranted ? true : false}
                  indeterminate={getIndeterminateState(permission)}
                  onChange={(event) => onCheckboxChange(event, permission, parent)}
                />
              }
            />
          </StyledTableRow>
          <Collapse in={open[permission.code]} timeout="auto" unmountOnExit>
            {getBranch(permission.children, leftMargin + 1, permission)}
          </Collapse>
        </>
      ))}
    </>;
  };

  return (
    <Table
      sx={{ width: '100%', mx: 0, bgcolor: 'background.paper' }}
      component="nav"
      size="small"
      className={className}
    >
      {getBranch(checked, 1, null)}
    </Table>
  );
};
