export const SAMPLE_REPLENISHMENT_CONFIG = {
  id: 0,
  name: '',
  nodeId: 0,
  clientId: 0,
  contractId: 0,
  clientContract: '',
  locationId: 0,
  location: '',
  locationName: '',
  type: '-1',
  typeId: 0,
  partTypeOrSkuName: '',
  min: 0,
  max: 0,
  includeChildNode: false,
  isActive: false,
};

export const REPLENISHMENT_CONFIG_HELPER_TEXT = {
  configNameHelperText: '',
  clientContractHelperText: '',
  locationHelperText: '',
  partTypeSkuSearchHelperText: '',
  minHelperText: ''
};

export const TYPE_DROPDOWN_ITEMS = [
  { label: 'SKU', value: 'sku' },
  { label: 'Part Type', value: 'part-type' },
];

export const REPLENISHMENT_REPORT_FILTERS = {
  structureType: '',
  structureName: '',
  structureId: 0,
  structureLocationStateId: 0,
  structureLocationState: '',
  clientId: 0,
  contractId: 0,
  nodeId: 0,
  clientContract: '',
  type: '',
  typeId: 0,
  skuPartType: '',
  condition: '',
  inTransit: true,
  servicesInTrain: true,
  distributionCentreId: 0,
  distributionCentre: '',
  showAllResults: false
};