import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { MyReportsListTypography } from './MyReportsList.styles';
import { MyReportsListTable } from './MyReportsListTable';
import { TabHandler } from '../../../handlers/TabHandler';
import { PLATFORM_NAME } from '../../../configs/common';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';

export const MyReportsList: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  
  TabHandler(`My Reports | ${PLATFORM_NAME}`);
  
  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  return (
    <PageContainer>
      <MyReportsListTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        My Reports
      </MyReportsListTypography>
      {isUserHasPermission(ActionPermissions.Report_My_Report_Search, permissions) && <MyReportsListTable/>}
    </PageContainer>
  );
};