import { QueryOption } from '../@types';
import { NotificationTemplate } from '../@types/notificationTamplate.type';
import { isNotArrayEmpty } from '../utils/array-utils';
import { httpClient, HttpMethod } from './http-client';

export const getNotificationTemplatesPaginated = (queryOptions: QueryOption) => {
  const { page, pageSize, sortModel } = queryOptions;
  let url = `/notificationTemplatesPaginated?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && isNotArrayEmpty(sortModel, 0)) {
    url += `&order_by=${sortModel[0]?.field}&order_direction=${sortModel[0]?.sort}`;
  }

  return httpClient(url, HttpMethod.Get, null);
};

export const getNotificationTemplateById = (id: number) => {
  return httpClient(`/notificationTemplates/${id}`, HttpMethod.Get, null);
};

export const createNotificationTemplate = (notificationTemplate: NotificationTemplate) => {
  return httpClient('/notificationTemplates', HttpMethod.Post, notificationTemplate);
};

export const updateNotificationTemplate = (id: number, notificationTemplate: NotificationTemplate) => {
  return httpClient(`/notificationTemplates/${id}`, HttpMethod.Put, notificationTemplate);
};

export const getSendGridTemplates = () => {
  return httpClient('/sendgridTemplates', HttpMethod.Get, null);
};