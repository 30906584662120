import { Box, FormControlLabel, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableRow = styled(TableRow)`
  border-top: 1px solid rgb(218,218,218);
  display: flex;
  align-items: center;
`;

export const MarginBox = styled(Box)`
  display: inline-block;
  width: 24px;
`;

export const FormLabel = styled(FormControlLabel)`
  height: 30px;
`;
