import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { SerialisedReceiveShipmentManifestData, ShipmentRecieveType, ShipmentTypeCodes } from '../../../@types/shipment.type';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { SerialisedReceiveShipmentManifestItemProps } from '.';
import { Link } from '../../atoms/Link';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Typography } from '../../atoms/Typography';
import { DataGrid } from '../../atoms/DataGrid';
import { useReciveShipmentsSearchNodes } from '../../../queries/live-search-query';
import { GridContainer } from './SerialisedReceiveShipmentManifestItem.styles';

export const SerialisedReceiveShipmentManifestItem: React.FC<SerialisedReceiveShipmentManifestItemProps> = ({
  serializedShipmentManifestItemData,
  selectedSerializedShipmentManifestItem,
  onChangeSelectedData,
  onChange,
  textBoxClick,
  setTextBoxClick,
  isLoading,
  selectionModel,
  setSelectionModel
}) => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [selectedRowIds, setSelectedRowIds] = useState<Array<number>>([]);
  const [autoHeight, setAutoHeight] = useState<boolean>(true);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const reciveShipmentsSearchNodes = useReciveShipmentsSearchNodes();

  useEffect(() => {
    if (serializedShipmentManifestItemData.length > 7) { 
      setAutoHeight(false);
    } else {
      setAutoHeight(true);
    }
  }, [serializedShipmentManifestItemData]);

  useEffect(() => {
    if (selectedSerializedShipmentManifestItem.length > 0) {
      const selectedIds = selectedSerializedShipmentManifestItem.map((selectedItem) => selectedItem.id);
      setSelectionModel(serializedShipmentManifestItemData.filter((manifestItem) => selectedIds.includes(manifestItem.id) ).map((manifest) => manifest.id));
    }
  }, [selectedSerializedShipmentManifestItem]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);
  
  useEffect(() => {
    if (selectedRowIds.length > 0) {
      const updatedData: Array<SerialisedReceiveShipmentManifestData> = serializedShipmentManifestItemData.filter((serializedShipmentManifestItem: SerialisedReceiveShipmentManifestData) => selectedRowIds.some((selectedRowId: number) => selectedRowId === parseInt(serializedShipmentManifestItem.id.toString())));
      onChangeSelectedData(updatedData, selectedRowIds);
    } else {
      onChangeSelectedData([], selectedRowIds);
    }
  }, [selectedRowIds]);

  const onSelectionModelChange = (selectedRows: GridSelectionModel) => {
    setSelectionModel(selectedRows);
    setSelectedRowIds(selectedRows.toString().split(',').map(Number)[0] === 0 ? [] : selectedRows.toString().split(',').map(Number));
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0,
      hide: true,
      valueGetter: (params: GridRenderCellParams) => params.row.id
    },
    {
      field: 'partTypeDetails',
      headerName: '',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack alignItems="flex-start">
          <Typography>Part Type Id: <b>{params.row.partTypeId}</b></Typography>
          <Typography>Part Type Name: <b>{params.row.partTypeName}</b></Typography>
          <Typography>Part Type No: <b>{params.row.partTypeNumber}</b></Typography>
        </Stack>
      )
    },
    {
      field: 'serial',
      headerName: 'Serial1',
      flex: 0.4,
      renderCell: (params: GridRenderCellParams) => 
        params.row.source !== ShipmentTypeCodes.AddStock && params.row.serial1 ? (
          <Link href={`/parts/${params.row.partId}`}>{params.row.serial1}</Link>
        ) : (
          params.row.serial1
        )
    },
    {
      field: 'shipmentId',
      headerName: 'Shipment ID',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.shipmentId
    },
    {
      field: 'partId',
      headerName: 'Part ID',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.source !== ShipmentTypeCodes.AddStock ? params.row.partId : ''
    },
    {
      field: 'conditionName',
      headerName: 'Condition',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.conditionName
    },
    {
      field: 'hardwareVersion',
      headerName: 'HW',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.hardwareVersion
    },
    {
      field: 'firmwareVersion',
      headerName: 'FW',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.firmwareVersion
    },
    {
      field: 'softwareVersion',
      headerName: 'SW',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.softwareVersion
    },
    {
      field: 'fleetTagName',
      headerName: 'Fleet Tag',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.fleetTagName
    },
    {
      field: 'putAwayDestination',
      headerName: 'Put Away Destination',
      flex: 0.5,
      hide: !isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions),
      renderCell: (params: GridRenderCellParams) => (
        <Grid width="100%">
          <LiveSearchBox
            title="Select a Location"
            timeOffset={400}
            value={params.row.putAwayLocationName}
            onClearValue={() => {
              const updatedData = [...serializedShipmentManifestItemData];
              const index = updatedData.findIndex(obj => obj.id == params.row.id);
              updatedData[index].putAwayLocationName = '';
              updatedData[index].putAwayLocationId = NaN;
              onChange(updatedData);
            }}
            onClick={() => setTextBoxClick(true)}
            defaultSuggetions={params.row.serialisedSuggestions}
            onChange={(value) => {
              const updatedData = [...serializedShipmentManifestItemData];
              const index = updatedData.findIndex(obj => obj.id == params.row.id);
              updatedData[index].putAwayLocationName = value.name;
              updatedData[index].putAwayLocationId = value.id;
              onChange(updatedData);
            }}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onApiInvoke={async (name: string) => {
              if (textBoxClick && name) {
                return await reciveShipmentsSearchNodes.mutateAsync({
                  id: params.row.shipmentId,
                  recieveType: ShipmentRecieveType.Individual,
                  name
                });
              } else {
                return [];
              }
            }}
            isOptionEqualToValue={(option, value) => {
              value = option;
              return option.id === value.id;
            }}
          />
        </Grid>
      )
    }
  ];

  return (
    <GridContainer autoHeight={autoHeight}>
      <DataGrid
        columns={columns}
        rows={serializedShipmentManifestItemData}
        getRowId={(row) => row?.id}
        loading={isLoading}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        checkboxSelection={true}
        getRowHeight={() => 'auto'}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        autoHeight={autoHeight}
      />
    </GridContainer>
  );
};