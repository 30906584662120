export enum LoaderColorProps {
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export interface LoaderProps {
  color?: LoaderColorProps;
}
