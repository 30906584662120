import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ImageBox = styled('img')`
  width: 32px;
  height: 32px;
`;

export const FullImage = styled('img')`
  display: block;
  margin-left: auto;
  margin-right: 50%;
  margin-top: 15%;
  width: 25%;
`;

export const RowListDataStyled = styled(Stack)`
  white-space: pre-line;
`;