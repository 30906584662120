import React, { useEffect, useState } from 'react';
import {
  GridDensityTypes,
  LicenseInfo,
  GridLinkOperator,
  GridToolbar,
  GridColumnOrderChangeParams,
  GridFilterPanel,
  GridFilterModel,
  useGridApiContext
} from '@mui/x-data-grid-pro';
import { DataGridProps } from './DataGrid.props';
import { FilterWithSearchButtonWrapper, FilterWithSearchWrapper, GridFlexGrowWrapper, GridFlexWrapper, StyledDataGrid } from './DataGrid.styles';
import { Button, ButtonColorProps, ButtonVariantProps } from '../Button';
import { checkListPagetFilterAvailability } from '../../../utils/common';

LicenseInfo.setLicenseKey('10a4ce6ba93bfc717b294e06813b9d34Tz01NjM1OSxFPTE3MDMwNDgwMTUzMjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

// replace emptly element with DataGrid footer if footer disabled
export function CustomFooterStatusComponent() {
  return (
    <></>
  );
}

export const DataGrid: React.FC<DataGridProps> = ({
  columns,
  rows,
  density = GridDensityTypes.Compact,
  getRowId,
  componentsProps,
  columnVisibilityModel,
  disableColumnFilter,
  disableColumnMenu,
  disableColumnSelector,
  disableDensitySelector,
  checkboxSelection,
  getDetailPanelHeight,
  getDetailPanelContent,
  filterMode,
  onFilterModelChange,
  loading,
  rowCount,
  rowsPerPageOptions,
  pagination,
  page,
  pageSize,
  paginationMode,
  onPageChange,
  onPageSizeChange,
  customToolbar,
  filterModel,
  onSortModelChange,
  sortingMode,
  getRowClassName,
  selectionModel,
  onSelectionModelChange,
  hideFooter,
  autoHeight = false,
  treeData,
  getTreeDataPath,
  defaultGroupingExpansionDepth,
  groupingColDef,
  initialState,
  getRowHeight,
  isRowSelectable,
  setColumns,
  customFooter,
  onCellClick,
  onCellEditStop,
  apiRef,
  experimentalFeatures,
  getCellClassName,
  showCellRightBorder,
  headerHeight,
  onCellDoubleClick,
  isGroupExpandedByDefault,
  serviceColumns,
  disableSelectionOnClick,
  hasFilterPanelWithSearch = true
}) => {
  const [customFilterModel, setCustomFilterModel] = useState<GridFilterModel>({ items: [] });

  // For report filters or default filters in list pages
  useEffect(() => {
    hasFilterPanelWithSearch && customFilterModel.items.length === 0 && filterModel && filterModel?.items.length !== 0 && setCustomFilterModel(filterModel);
  }, [filterModel]);

  const FilterPanelWithSearch = (props: any) => {
    const apiRef = useGridApiContext();
    
    const handleSearch = () => {
      onFilterModelChange && onFilterModelChange(customFilterModel as GridFilterModel,{});
      apiRef.current.hideFilterPanel(); // Hide filter panel when Search is clicked
    };

    return (
      <FilterWithSearchWrapper>
        <GridFilterPanel {...props} />
        <FilterWithSearchButtonWrapper>
          <Button
            variant={ButtonVariantProps.Secondary}
            color={ButtonColorProps.Primary}
            disabled={!checkListPagetFilterAvailability(customFilterModel as GridFilterModel) || customFilterModel.items.length === 0}
            onClick={handleSearch}
          >
            Search
          </Button>
        </FilterWithSearchButtonWrapper>
      </FilterWithSearchWrapper>
    );
  };

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    if (hasFilterPanelWithSearch) {
      // If a filter is removed trigger the query to get the result 
      if (customFilterModel?.items && filterModel.items.length < customFilterModel.items.length) {
        onFilterModelChange && onFilterModelChange(filterModel, {});
      }
      setCustomFilterModel(filterModel);
    } else {
      onFilterModelChange && onFilterModelChange(filterModel, {});
    }
  };

  return (
    <GridFlexWrapper>
      <GridFlexGrowWrapper>
        <StyledDataGrid
          columns={columns}
          rows={rows}
          density={density}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          disableColumnFilter={disableColumnFilter}
          disableColumnMenu={disableColumnMenu}
          disableColumnSelector={disableColumnSelector}
          disableDensitySelector={disableDensitySelector}
          disableSelectionOnClick={disableSelectionOnClick}
          checkboxSelection={checkboxSelection}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          initialState={initialState}
          componentsProps={{
            ...componentsProps,
            filterPanel: {
              // Force usage of "And" operator
              linkOperators: [GridLinkOperator.And],
              // Display columns by ascending alphabetical order
              columnsSort: 'asc',
              filterFormProps: {
                // Customize inputs by passing props
                linkOperatorInputProps: {
                  sx: { display: 'none' }
                },
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 'auto' },
                },
                valueInputProps: {
                  InputComponentProps: {
                    variant: 'outlined',
                    size: 'small',
                  },
                  sx: { width: 'auto', minWidth: '200px' }
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                  },
                },
              }
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          filterMode={filterMode}
          onFilterModelChange={handleFilterModelChange}
          loading={loading}
          rowCount={rowCount}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination={pagination}
          page={page}
          pageSize={pageSize}
          paginationMode={paginationMode}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          sortingMode={sortingMode}
          components={{
            Toolbar: customToolbar || GridToolbar,
            ...(hideFooter && { Footer: customFooter || CustomFooterStatusComponent }),
            ...(hasFilterPanelWithSearch && { FilterPanel: FilterPanelWithSearch })
          }}
          filterModel={hasFilterPanelWithSearch ? customFilterModel : filterModel}
          getRowClassName={getRowClassName}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
          autoHeight={autoHeight}
          treeData={treeData}
          getTreeDataPath={getTreeDataPath}
          defaultGroupingExpansionDepth={defaultGroupingExpansionDepth}
          groupingColDef={groupingColDef}
          isRowSelectable={isRowSelectable}
          disableVirtualization={true}
          onColumnOrderChange={(params: GridColumnOrderChangeParams) => {
            const columnsSet = serviceColumns || columns;
            const movingColumn = columnsSet.find((column, index) => index === params.oldIndex);
            [
              ...columnsSet.slice(0, params.targetIndex),
              movingColumn,
              ...columnsSet.slice(params.targetIndex)
            ];

            setColumns && setColumns(columnsSet);
          }}
          onCellClick={onCellClick}
          onCellEditStop={onCellEditStop}
          apiRef={apiRef}
          experimentalFeatures={experimentalFeatures}
          getCellClassName={getCellClassName}
          showCellRightBorder={showCellRightBorder}
          headerHeight={headerHeight || 56}
          onCellDoubleClick={onCellDoubleClick}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
        />
      </GridFlexGrowWrapper>
    </GridFlexWrapper>
  );
};