import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';
import { SelectMenu } from '../../../components/atoms/SelectMenu';

export const MainBox = styled(Box)`
  margin-top: 10px;
  border: 2px solid #eeeeee;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;

export const ImageBox = styled('img')`
  width: 200px;
  height: 200px;
`;

export const ImageInput = styled('input')`
  display: none
`;
