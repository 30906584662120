import React from 'react';
import { TypographyProps } from './Typography.props';
import { Element } from './Typography.styles';

export const Typography:React.FC<TypographyProps> = ({
  children,
  className, 
  variant,
  fontWeight,
  textColor,
  id,
  fontStyle
}) => {
  return (
    <Element
      className={className}
      variant={variant}
      textColor={textColor}
      fontWeight={fontWeight}
      id={id}
      fontStyle={fontStyle}
    >
      {children}
    </Element>
  );
};