import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { SerialisedPartsUpdateSelectionProps } from './SerialisedPartsUpdateSelection.props';
import { ContentWrapperBox, HeadingWrapper, SelectionWrapper } from './SerialisedPartsUpdateSelection.styles';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { SAMPLE_GENERAL_PERMISSION_GRANT_DROPDOWN_DATA, SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA } from '../../../constants/part';
import { GeneralPermissionGrantFragment } from './GeneralPermissionGrantFragment';
import { SerialNumberPermissionGrantFragment } from './SerialNumberPermissionGrantFragment';
import { PartTypePermissionGrantFragment } from './PartTypePermissionGrantFragment';
import { useGetPartTypeManifestById } from '../../../queries/part-type-query';
import { GeneralPermissionGrantDropdownValueType, SerialisedPartsUpdateSelectionDataType } from '../../../@types/part.type';
import { dropdownOptionArrayFiller } from '../../../utils/array-utils';
import { isUserHasPermission } from '../../../configs/permissions';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { useGetCommonFleetTagsByPartTypeIds } from '../../../queries/structure-query';

export const SerialisedPartsUpdateSelection: React.FC<SerialisedPartsUpdateSelectionProps> = ({
  isOnlySerialized,
  partType,
  partData,
  serialisedPartsUpdateSelectionValue
}) => {
  const [updateSelectionValues, setUpdateSelectionValue] = useState<SerialisedPartsUpdateSelectionDataType>(SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA);
  const [linkedFieldValue, setLinkedFieldValue] = useState('');
  const [selectedPartTypeId, setSelectedPartTypeId] = useState<number>(0);
  const [generalPermissionGrantDropdownValues, setGeneralPermissionGrantDropdownValues] = useState<GeneralPermissionGrantDropdownValueType>(SAMPLE_GENERAL_PERMISSION_GRANT_DROPDOWN_DATA);
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);

  const getPartTypeManifestById = useGetPartTypeManifestById(selectedPartTypeId);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getCommonFleetTagsByPartTypeIds = useGetCommonFleetTagsByPartTypeIds();

  useEffect(() => {
    const getFleetTagOptions = async() => {
      if(partData && partData.length) {
        const fleetTagOptionsData = await getCommonFleetTagsByPartTypeIds.mutateAsync({ partTypeIds: partData.map(data => data.partTypeId) });
        const fleetTagOptions = dropdownOptionArrayFiller(fleetTagOptionsData.map((item: any) => {
          return {
            label: item.name,
            value: item.id
          };
        }));
    
        setGeneralPermissionGrantDropdownValues(prev => ({ ...prev, fleetTagOptions }));
      }
    };

    getFleetTagOptions();
  }, [partData]);

  useEffect(() => {
    serialisedPartsUpdateSelectionValue(updateSelectionValues);
  }, [updateSelectionValues]);

  useEffect(() => {
    getPartTypeManifestById.refetch();
  }, [selectedPartTypeId]);

  useEffect(() => {
    if (getPartTypeManifestById.data) {
      const softwareOptions = dropdownOptionArrayFiller(getPartTypeManifestById?.data?.sfhOptions?.softwares);
      const firmwareOptions = dropdownOptionArrayFiller(getPartTypeManifestById?.data?.sfhOptions?.firmwares);
      const hardwareRevisionOptions = dropdownOptionArrayFiller(getPartTypeManifestById?.data?.sfhOptions?.hardwares);

      setGeneralPermissionGrantDropdownValues({
        ...generalPermissionGrantDropdownValues,
        softwareOptions: softwareOptions,
        firmwareOptions: firmwareOptions,
        hardwareRevisionOptions: hardwareRevisionOptions
      });
    }
  }, [getPartTypeManifestById.data]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  return (
    <PageContainer>
      <ContentWrapperBox>
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Update Selection
        </HeadingWrapper>
        <SelectionWrapper>
          {isUserHasPermission(ActionPermissions.Part_Bulk_Update_Serial_Number, permissions) && (
            <SerialNumberPermissionGrantFragment
              linkedFieldValue={linkedFieldValue}
              serialNumberPermissionUpdateValues={(serialNumberPermissionUpdateValues) => setUpdateSelectionValue({
                ...updateSelectionValues,
                serial1: serialNumberPermissionUpdateValues.serial1,
                serial2: serialNumberPermissionUpdateValues.serial2,
                serial3: serialNumberPermissionUpdateValues.serial3
              })}
            />
          )}
        </SelectionWrapper>
        <SelectionWrapper>
          <PartTypePermissionGrantFragment
            linkedFieldValue={linkedFieldValue}
            partType={partType}
            isPartTypePermissionEnabled={isUserHasPermission(ActionPermissions.Part_Bulk_Update_Part_Type_Client_Lock_Bypass, permissions)}
            partTypePermissionUpdateValues={(partTypePermissionUpdateValues) => {
              setUpdateSelectionValue({
                ...updateSelectionValues,
                partTypeId: partTypePermissionUpdateValues.partTypeId
              });
              setSelectedPartTypeId(partTypePermissionUpdateValues.partTypeId || partType?.partTypeId || 0);
            }}
          />
        </SelectionWrapper>
        <SelectionWrapper>
          <GeneralPermissionGrantFragment
            isOnlySerialized={isOnlySerialized}
            generalPermissionGrantDropdownValues={generalPermissionGrantDropdownValues}
            isSwHwFwFleetTagPermisionEnabled={isUserHasPermission(ActionPermissions.Part_Bulk_Update_Sw_Hw_Fw_Fleet_Tags, permissions)}
            partData={partData}
            selectedPartTypeId={updateSelectionValues.partTypeId}
            generalPermissionUpdateValues={(generalPermissionUpdateValues) => {
              setUpdateSelectionValue({
                ...updateSelectionValues,
                notes: generalPermissionUpdateValues.notes,
                software: generalPermissionUpdateValues.software,
                firmware: generalPermissionUpdateValues.firmware,
                hardware: generalPermissionUpdateValues.hardware,
                fleetTag: generalPermissionUpdateValues.fleetTag,
                locationId: generalPermissionUpdateValues.locationId,
                locationTypeCode: generalPermissionUpdateValues.locationTypeCode,
                conditionCode: generalPermissionUpdateValues.conditionCode,
                isActiveLinkedField: generalPermissionUpdateValues.isActiveLinkedField,
                linkedFieldColumn: generalPermissionUpdateValues.linkedFieldColumn
              });
              setLinkedFieldValue(generalPermissionUpdateValues?.linkedFieldColumn || '');
            }}
          />
        </SelectionWrapper>
      </ContentWrapperBox>
    </PageContainer>
  );
};