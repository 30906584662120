import React from 'react';
import dayjs from 'dayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { SizeProps } from '../../../@types';
import { DatePickerProps } from './DatePicker.props';
import { DateTextField } from './DatePicker.styles';

export const DatePicker: React.FC<DatePickerProps> = ({
  label = 'Date',
  normalSize = false,
  value,
  onChange
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        label={label}
        value={value ? dayjs(value, 'YYYY-MM-DD') : null}
        inputFormat="YYYY-MM-DD"
        disableMaskedInput={true}
        onChange={(text) => onChange(text?.format('YYYY-MM-DD') || '')}
        renderInput={(params) => <>{normalSize ? <TextField {...params} size={SizeProps.Small}/> : <DateTextField {...params} size={SizeProps.Small}/>}</>}
      />
    </LocalizationProvider>
  );
};
