import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';
import { Box } from '../../../components/atoms/Box';
import { COLORS } from '../../../configs/colors';

export const TLCLoader= styled('div')`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TLCHeading = styled(Typography)`
  padding-bottom: 10px;
  font-weight: 600;
`;

export const TLCLoaderText = styled(Typography)`
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
`;

export const TLCBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const TLCSubHeading = styled(Typography)`
  color: ${COLORS.Blue};
  font-size: 17px;
  font-weight: 500;
`;

export const TLCText= styled(Typography)`
  font-size: 17px;
`;

export const TLCSerialScan = styled(TextField)`
  min-width: 500px;
  margin-right: 200px;
`;

export const TLCManifestContainer = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid lightgrey;
  border-radius: 5px;
  background-color: ${COLORS.LightGrey};
`;

export const TLCNote = styled(TextField)`
  min-width: 600px;
  margin-right: 150px;
`;