import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridValueGetterParams, GridSelectionModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TableContainer } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { Link } from '../../../components/atoms/Link';
import { BatchReceiveListResponse } from '../../../@types/shipment.type';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { useGetAllShipmentBatchReceive } from '../../../queries/shipment-query';
import { ActionPermissions, CustomPermission, ListType } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { formatDate } from '../../../utils/common';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { batchRecieveColumnSelectionItems } from '../../../constants/shipment';

export const BatchReceiveList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [shipmentBatchReceives, getShipmentBatchReceives] = useState<BatchReceiveListResponse>();
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const getAllShipmentBatchReceives = useGetAllShipmentBatchReceive({ filterModel, page, pageSize, sortModel });
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  TabHandler(`Batch Receive | ${PLATFORM_NAME}`);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams) => <Link href={`/shipments/batchReceive/${params.row.id}`}>{params.row.id}</Link>,
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      type: 'string',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'date',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
    },
    {
      field: 'destinationNodeName',
      headerName: 'Destination',
      type: 'string',
      width: 200,
      renderCell: (params: GridRenderCellParams) => <Link href={`/structure/${params.row.destinationNodeId}`}>{params.row.destinationNodeName}</Link>
    },
    {
      field: 'qty',
      headerName: 'Total Qty',
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    }
  ];

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    getAllShipmentBatchReceives.data && getShipmentBatchReceives(getAllShipmentBatchReceives.data);
  }, [getAllShipmentBatchReceives.data, getAllShipmentBatchReceives.isRefetching]);

  useEffect(() => {
    if (page || pageSize || filterModel || sortModel) {
      getAllShipmentBatchReceives.refetch();
    }
  }, [page, pageSize, filterModel, sortModel]);

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Batch Receive
          </Typography>
          {isUserHasPermission(ActionPermissions.Shipment_Batch_Receive, permissions) && (
            <Link href="/shipments/batchReceive/create">
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
                Create Batch
              </Button>
            </Link>
          )}
        </InlineGroup>
        <GridContainer>
          <TableContainer style={{ height: '100%', width: '100%' }}>
            <CustomDataGrid
              columns={columns}
              rows={shipmentBatchReceives?.data || []}
              rowCount={shipmentBatchReceives?.total}
              exportFileName={ListType.ShipmentBatchReceiveList}
              enableCSVExport={isUserHasPermission(ActionPermissions.Shipment_Batch_Receive, permissions)}
              columnSelectionItems={batchRecieveColumnSelectionItems}
              filterMode={GridFeatureModeConstant.server}
              filterModel={filterModel}
              onFilterModelChange={(filterModel: GridFilterModel) => {
                onFilterChange(filterModel);
              }}
              loading={getAllShipmentBatchReceives.isLoading}
              rowsPerPageOptions={[25, 50, 100]}
              pagination={true}
              page={page}
              pageSize={pageSize}
              paginationMode={GridFeatureModeConstant.server}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sortingMode={GridFeatureModeConstant.server}
              onSortModelChange={handleSortModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={handleSelectionModelChange}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    parentNodeId: false,
                    referenceNumbers: false,
                    status: false,
                  },
                },
              }}
              componentsProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true }
                }
              }}
              getRowHeight={() => 'auto'}
              sortModel={sortModel}
            />
          </TableContainer>
        </GridContainer>
      </Wrapper>
    </PageContainer>
  );
};