import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { VisibilityRule, VisibilityRulesKey } from '../@types/visibilityRule.type';
import { getVisibilityRuleById, getVisibilityRules } from '../api/visibilityRules';

export const useGetVisibilityRules = (): UseQueryResult<[VisibilityRule]> => {
  return useQuery([VisibilityRulesKey], () => getVisibilityRules(), { staleTime: 5 * 60 * 60 * 1000 });
};

export const useGetOneVisibilityRule = (id: number): UseQueryResult<VisibilityRule> => {
  return useQuery([VisibilityRulesKey, id], () => getVisibilityRuleById(id), { staleTime: 5 * 60 * 60 * 1000 });
};