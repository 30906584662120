import { AddressDataType } from '../components/organisms/StructureAddress';
import { AutocompleteTagType } from '../components/organisms/Tags';
import { Warning } from './common.type';
import { Tag } from './tag.type';

export interface StructureType {
  code: string;
  name: string;
  categoryCode: string;
  isActive: boolean;
  propertyTypes: string[];
  childNodeTypes: ChildNodeType[];
}
export interface ChildNodeType {
  name: string;
  code: string;
}

export interface PropertyType {
  name: string;
  propertyType: string;
  min: number;
  max?: number;
}

export interface StructureType {
  id: number;
  code: string;
  name: string;
  categoryCode: string;
  isActive: boolean;
  schema: any;
}

export interface StructureTypeResponse {
  code: string;
  name: string;
  categoryCode: string;
  isActive: boolean;
  isCodeExist: boolean;
  propertyTypes: PropertyType[];
  childNodeTypes: ChildNodeType[];
}

export interface PrioritySelectionObjectType {
  client: string[];
  contract: string[];
  brand: string[];
  serviceTypes: string[];
}

export interface StructureTypeHelper {
  nameHelperText: string;
  codeHelperText: string;
}

export enum ParentSelectionType {
  None = 'none',
  Client = 'client',
  Search = 'search'
}

export interface StructureBasicData {
  id: number;
  name: string;
  isActive: boolean;
}

export interface StructureAttribute {
  id: number;
  name?: string;
  nodeId?: number;
  propertyTypeCode: string;
  value: any;
}

export interface StructureResponse {
  code: string;
  logoPic: string;
  id: number;
  isActive: boolean;
  name: string;
  nodeTypeCode: string;
  parentNodeId: string;
  parentTree?: string;
  notes: string;
  tags: Tag[];
  nodeType: StructureType;
  attributes: StructureAttribute[];
  warnings: Warning[];
  clientId: number;
  contractId: number;
}

export enum NodeType {
  Client = 'client',
  Contract = 'contract',
  Brand = 'brand',
  ShippingCompany = 'shipping-company',
  Sibling = 'sibling'
}

export interface MostRecentPresentationType {
  description: string;
  date: string;
}

export interface StructureLayoutData {
  id?: number;
  name: string;
  notes?: string;
  referenceNo1: string;
  referenceNo2: string;
  referenceNo3: string;
  siteSchemaId: string;
  selectedTags?: Array<AutocompleteTagType | string>;
  newTags?: Array<Tag>;
  enableSiteSchemaType?: boolean;
  refAttribId?: number;
  siteSchemaAttribId?: number;
  globalReturns?: boolean;
  mostRecentPresentation?: MostRecentPresentationType;
}

export interface StructureHelperText {
  nameHelperText?: string;
}

export interface SiteSchemaDropdown {
  id: number;
  name: string;
  description: string;
}

export interface StructureImport {
  id: number;
  structId?: number;
  name: string;
  notes: string;
  structType: string;
  parentType: string;
  client: string;
  contract: string;
  brand: string;
  manualParentType: string;
  manualParentName: string;
  manualParentId: string;
  siteSchema: string;
  reference1: string;
  reference2: string;
  reference3: string;
  addressType: string;
  addressName: string;
  addressLine1: string;
  addressLine2: string;
  addressSuburb: string;
  addressCity: string;
  addressState: string;
  addressPostcode: string;
  addressCountry: string;
  addressTraditionalName: string;
  contactType: string;
  contactName: string;
  contactOrganisation: string;
  contactPhone1: string;
  contactPhone2: string;
  contactEmail: string;
  contactNote: string;
  tradingHoursType: string;
  tradingHoursStart: string;
  tradingHoursEnd: string;
  tradingHoursPh: string;
  tradingHoursNote: string;
  active: string;
}

export interface StructureStatesResponse {
  id: number;
  stateName: string;
  countryName: string;
}

export interface SiblingParentDetails {
  siblingDetails: StructureResponse;
  parentDetails: StructureResponse;
}

export interface ParentAttributesType {
  id: number;
  propertyTypeCode: string;
  value: any; // value can be change based on the propertyType
}

export interface AddressAttributeResponseType {
  id: number;
  nodeId?: number;
  propertyTypeCode: string;
  name?: string;
  value: AddressDataType; 
}

export interface NodeAttributesReponse {
  id: number;
  name: string;
  nodeId: number;
  propertyTypeCode: string;
  value: any;
  createdBy: number;
  updatedBy?: number;
  createdAt: string;
  updatedAt: string;
}
  
export interface NodeData {
  id: number;
  name: string;
  code: string;
  nodeTypeCode: string;
  parentNodeId: number;
  parentTree: string;
  isActive: boolean;
  notes: string;
  createdBy: number;
  updatedBy?: number;
  createdAt: string;
  updatedAt: string;
}

export interface ParentAttributesType {
  id: number;
  propertyTypeCode: string;
  value: any; // value can be change based on the propertyType
}

export interface AddressAttributeResponseType {
  id: number;
  nodeId?: number;
  propertyTypeCode: string;
  name?: string;
  value: AddressDataType; 
}

export interface CountryObject {
  id: number;
  name: string;
}

export interface StatesWithCountryResponse {
  id: number;
  name: string;
  shortName: string;
  countryId: number;
  country: CountryObject;
}

export enum LocationMergeFields {
  AddressesField = 'Addresses dropdown',
  ContactsField = 'Contacts dropdown',
  TradingHoursField = 'Trading Hours dropdown',
}

export interface StructureListRowData {
  client?: string;
  contract?: string;
  brand?: string;
  line1?: string;
  line2?: string;
  suburb?: string;
  city?: string;
  stateName?: string;
  postCode?: string;
  countryName?: string;
  contactName?: string;
  organisation?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  type?: string;
  start?: string;
  end?: string;
  ph?: string;
}

export interface DispatchDestinationNodeType {
  id: number;
  section: string;
  type: string;
  name: string;
  nodeTypeName: string;
  mainAddress?: string;
  logisticsAddress?: string;
  serviceAddress?: string;
}

export interface NodeTypeProps {
  name: string;
}

export interface ServiceStatusTransitionsType {
  id?: number;
  ruleId: number;
  selectedServiceType: string;
  selectedServiceStatus: string;
  selectedPriorities: string[];
  selectedActionTypes: string[];
  blockedStatuses: string[];
  isActive: boolean;
}