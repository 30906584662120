import { InputCategoryProps, PropertyType, StructureTypeResponse } from '../@types';
import { EMPTY_FIELD_MESSAGE } from '../constants/common';
import { CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE } from '../constants/user.constant';
import { isEmptyString } from './common';
import { KeyValueType, PreDefinedStructureType, StructureType } from '../pages/StructureTypes/AddEditStructureType/AddEditStructureType.props';

export const getPropertyTypeValue = (propertyType: string, propertyTypes?: PropertyType[]) => {
  return propertyTypes && propertyTypes.some(
    (item: PropertyType) => item.propertyType === propertyType) ? 'Yes' : 'No';
};

export const conatainValidCharacters = (value: string, type: string) => {
  if(type === InputCategoryProps.Name) {
    if (/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/i.test(value.replace(/[-/:]/g, 'a'))) {  
      return false;
    } else {
      return true;
    }
  } else if(type === InputCategoryProps.Code) {
    if (/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/i.test(value.replace('-', ' '))) {
      return false;
    } else {
      return true;
    }
  }
};

export const nameValidation = (value: string, type: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else if (conatainValidCharacters(value, type)) {
    return CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE + value;
  } else {
    return '';
  }
};

export const isError = (isCodeExist: boolean, validateName: string, validateCode: string) => {
  return isCodeExist || validateName || validateCode;
};

export const getSelectedProperties = (structureTypeFields: Array<StructureType>) => {
  const selectedStructureTypeFields = structureTypeFields.filter(item => item.selected);
  return selectedStructureTypeFields.map(item => item.key);
};

export const getSelectedChildNodeTypes = (preferedChildren: Array<KeyValueType>) => {
  return preferedChildren.map(item => ({ name: item.label, code: item.id }));
};

export const getActiveStructureTypes = (structureTypes: Array<StructureTypeResponse>, preferedChildren: Array<KeyValueType>) => {
  const filteredStructureTypes = structureTypes.filter(type => type.isActive).map(type => ({ id: type.code, label: type.name }));

  // remove already selected values from autocomplete dropdown
  const nonSelectedChildren = filteredStructureTypes.filter(filteredStructureType => !preferedChildren.some(preferedChild => filteredStructureType.id === preferedChild.id));
  return nonSelectedChildren;
};

export const getStructureTypesArray = (preDefinedStructureTypeFields: Array<PreDefinedStructureType>) => {
  const structureTypesArray: StructureType[] = [];

  preDefinedStructureTypeFields.map(item => {
    structureTypesArray.push({ key: item.key, value: item.value, selected: false });
  });

  return structureTypesArray;
};