import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { IdSearchItemProps } from './IdSearchItem.props';
import { StyledStack } from './IdSearchItem.styles';

export const IdSearchItem: React.FC<IdSearchItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.id}</Typography>
    </StyledStack>
  );
};
