import React from 'react';
import { Stack, Table, TableBody, TableContainer, useMediaQuery } from '@mui/material';
import { DispatchManifestListProps } from './DispatchManifestList.props';
import { DispatchManifestRowItem, DispatchManifestRowItemValueType } from '../../organisms/DispatchManifestRowItem';
import { Typography } from '../../atoms/Typography';
import { ScreenSize, SizeProps } from '../../../@types';
import { onChangeDispatchDataMapper } from '../../../utils/shipment';

export const DispatchManifestList: React.FC<DispatchManifestListProps> = ({
  manifestData,
  onChange
}) => {

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  return (
    <Stack direction="column" width="100%" maxWidth="1300px">
      <TableContainer>
        <Stack direction="column" marginTop={3} marginBottom={1}>
          <Typography>Serialized Part Type Manifest Items</Typography>
          <Table padding={isMobileView ? 'none' : 'normal'} size={SizeProps.Small}>
            <TableBody>
              {manifestData?.serialisedManifestData?.map((serialisedManifestDataItemValue: DispatchManifestRowItemValueType, index) =>
                <DispatchManifestRowItem
                  key={index}
                  value={serialisedManifestDataItemValue}
                  index={index + 1}
                  onChange={(val) => {
                    const serialisedData = onChangeDispatchDataMapper(manifestData.serialisedManifestData, val, index);
                    onChange({
                      ...manifestData,
                      serialisedManifestData: serialisedData
                    });
                  }}
                />
              )}
            </TableBody>
          </Table>
        </Stack>
      </TableContainer>
      <TableContainer>
        <Stack direction="column" marginTop={3} marginBottom={1}>
          <Typography> Non Serialized Part Type Manifest Items</Typography>
          <Table padding={isMobileView ? 'none' : 'normal'} size={SizeProps.Small}>
            <TableBody >
              {manifestData?.nonSerializedManifestData?.map((nonSerializedManifestDataItemValue: DispatchManifestRowItemValueType, index) =>
                <DispatchManifestRowItem
                  key={index}
                  value={nonSerializedManifestDataItemValue}
                  index={index + 1}
                  onChange={(val) => {
                    const nonSerialisedData = onChangeDispatchDataMapper(manifestData.nonSerializedManifestData, val, index);
                    onChange({
                      ...manifestData,
                      nonSerializedManifestData: nonSerialisedData
                    });
                  }}
                />
              )}
            </TableBody>
          </Table>
        </Stack>
      </TableContainer>
    </Stack>
  );
};