import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';

export const WrapperBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 10px;;
`;

export const ContentWrapper = styled(Box)`
  padding-bottom: 10px;
`;

export const StructureFieldsWrapper = styled(Box)`
  padding-left: 24px;
  padding-bottom: 10px;
`;