import { AllocationConfigData, AllocationResponse } from '../@types/allocation.type';
import { httpClient, HttpMethod } from './http-client';

export const getAllAllocationConfigs = (): Array<AllocationResponse> => {
  return httpClient('/allocationconfigs', HttpMethod.Get, null);
};

export const createAllocationConfig = (data: AllocationConfigData) => {
  return httpClient('/allocationconfigs', HttpMethod.Post, data);
};

export const updateAllocationConfig = (id: number, data: AllocationConfigData) => {
  return httpClient(`/allocationconfigs/${id}`, HttpMethod.Put, data);
};