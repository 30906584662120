import React from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridDensityTypes } from '@mui/x-data-grid-pro';
import { ConfigurationHistoryProps } from './ConfigurationHistory.props';
import { StyledTypography, StyledWrapper } from './ConfigurationHistory.styles';
import { Accordian } from '../../atoms/Accordian';
import { calculateDateTimeDuration, formatDate } from '../../../utils/common';
import { DataGrid } from '../../atoms/DataGrid';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';

export const ConfigurationHistory: React.FC<ConfigurationHistoryProps> = ({
  configurationHistoryData,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Datestamp',
      minWidth: 200,
      renderCell: (params) => (
        <Grid>
          <Grid><Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.createdAt)}</Typography></Grid>
          <Grid><StyledTypography variant={TypographyVariantProps.Body2}>{calculateDateTimeDuration(params.row.createdAt)}</StyledTypography></Grid>
        </Grid>
      )
    },
    {
      field: 'createdBy',
      headerName: 'User',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
    },
    {
      field: 'configurationChangeNotes',
      headerName: 'Change Notes',
      flex: 1,
    }
  ];

  return (
    <StyledWrapper>
      <Accordian title="Configuration History" defaultExpanded={true}>
        <DataGrid
          density={GridDensityTypes.Standard}
          columns={columns}
          rows={configurationHistoryData}
          getRowId={(row) => row?.id}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            },
          }}
          autoHeight={true}
        />
      </Accordian>
    </StyledWrapper>
  );
};
