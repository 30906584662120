import { CapacityHourTypes } from '../components/organisms/Capacity';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';
import { PropertyType, ServiceCreationRuleTypes, SpecialServiceTasksEnum } from '../configs/enums';

export const LOCATION_MERGE_WARNING_MESSAGE = 'The Selected Node will be merged into this node, and all associated child nodes, Services, Shipments, Parts etc will be updated';

export const SAMPLE_STRUCTURE_LAYOUT = {
  name: '',
  notes: '',
  selectedTags: [],
  newTags: [],
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  siteSchemaId: '0'
};

export const SAMPLE_CAPACITY_DATA = {
  type: CapacityHourTypes.AllDays,
  checkInventory: true,
  allowOveride: true,
  max: '4',
  itemsData: [],
  clientsData: [],
  contractsData: [],
};

export const STRUCTURE_ATTRIBUTE_TYPE_FIELDS: Array<string> = [
  PropertyType.Contact,
  PropertyType.Address,
  PropertyType.TradingHours,
  PropertyType.ReferenceNumbers,
  PropertyType.SiteSchema
];

export const SERVICE_CREATION_RULE_TYPES = [
  { value: ServiceCreationRuleTypes.Reference1, label: 'Reference 1' },
  { value: ServiceCreationRuleTypes.Reference2, label: 'Reference 2' },
  { value: ServiceCreationRuleTypes.Reference3, label: 'Reference 3' },
  { value: ServiceCreationRuleTypes.FaultAndDescription, label: 'Fault Code and Description' },
  { value: ServiceCreationRuleTypes.Model, label: 'Model' },
  { value: ServiceCreationRuleTypes.SerialNumber, label: 'Serial Number' },
  { value: ServiceCreationRuleTypes.Notes, label: 'Notes' },
];

export const ADDRESS_CONTACT_TYPES = [
  { label: 'Main', value: 'Main' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Alternative', value: 'Alternative' },
  { label: 'Technical', value: 'Technical' },
  { label: 'Management', value: 'Management' },
  { label: 'Notification', value: 'Notification' }
];

export const SITE_SCHEMA_SAMPLE_DATA = [
  {
    id: 0,
    name: '',
    description: ''
  }
];

export const STRUCTURE_IMPORT_TOGGLE_BUTTONS = [
  {
    id: 1,
    toggleName: 'Show Error records'
  },
  {
    id: 2,
    toggleName: 'Show Createable records'
  },
  {
    id: 3,
    toggleName: 'Show Updateable records'
  },
];

export const STRUCTURE_IMPORT_TOGGLE_BUTTON_VALUES = [
  {
    id: 1,
    value: true
  },
  {
    id: 2,
    value: true
  },
  {
    id: 3,
    value: true
  },
];

export const STRUCTURE_IMPORT_DATA = {
  fileName: '',
  fileSize: 0,
  fileId: 0,
  sessionId: 0,
  preprocessingStatus: '',
  processingStatus: '',
  preprocessingErrorMessage: '',
  processingErrorMessage: ''
};

export const STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA = {
  id: 0,
  name: '',
  code: '',
  structureName: '',
  nodeTypeCode: '',
  mainAddress: '',
  reference1: '',
  logoPic: '',
  canHaveAddress: false,
  canHaveContact: false,
  canHaveTradingHours: false,
};

export const LOCATION_MERGE_DROPDOWN_ITEMS = [
  { label: 'Discard', value: 'discard' },
  { label: 'Copy Inbound', value: 'copy-inboud' },
  { label: 'Overwrite', value: 'overwrite' }
];

export const STRUCTUE_MERGE_DECISION_SAMPLE_DATA = {
  addressesDecision: '-1',
  contactsDecision: '-1',
  tradingHoursDecision: '-1',
  mergeNodeId: 0,
  currentNodeId: 0
};

export const columnSelectionItems: ColumnSelectionItem[] = [
  {
    title: 'General',
    value: [
      {
        title: 'Node ID',
        field: 'id',
        value: true
      },
      {
        title: 'Node Name',
        field: 'name',
        value: true
      },
      {
        title: 'Node Type',
        field: 'nodeTypeName',
        value: true
      },
      {
        title: 'Tags',
        field: 'structureTags',
        value: true
      },
      {
        title: 'Notes',
        field: 'notes',
        value: true
      },
      {
        title: 'Region Type',
        field: 'regionType',
        value: true
      },
      {
        title: 'Main Address',
        field: 'mainAddress',
        value: true
      },
      {
        title: 'All Addresses',
        field: 'allAddresses',
        value: false
      },
      {
        title: 'Main Contact',
        field: 'mainContact',
        value: true
      },
      {
        title: 'All Contacts',
        field: 'allContacts',
        value: false
      },
      {
        title: 'Trading Hours',
        field: 'tradingHrs',
        value: false
      },
      {
        title: 'Active',
        field: 'isActive',
        value: false
      },
      {
        title: 'Site Schema',
        field: 'siteSchema',
        value: false
      },
      {
        field: 'importsessionId',
        title: 'Import Session ID',
        value: false
      }
    ]
  },
  {
    title: 'Hierarchy',
    value: [
      {
        title: 'Parent Node ID',
        field: 'parentNodeId',
        value: true
      },
      {
        title: 'Parent Node Type',
        field: 'parentNodeTypeName',
        value: true
      },
      {
        title: 'Parent Node Name',
        field: 'parentName',
        value: true
      },
      {
        title: 'Parent Active',
        field: 'parentActive',
        value: false
      },
      {
        title: 'Client/ Contract/ Brand',
        field: 'clientContractBrand',
        value: true
      },
      {
        title: '# of Children (direct)',
        field: 'directChildCount',
        value: false
      },
      {
        title: '# of Children (total)',
        field: 'totalChildCount',
        value: false
      }
    ]
  },
  {
    title: 'Reference Numbers',
    value: [
      {
        title: 'Reference Number 1',
        field: 'referenceNo1',
        value: true
      },
      {
        title: 'Reference Number 2',
        field: 'referenceNo2',
        value: true
      },
      {
        title: 'Reference Number 3',
        field: 'referenceNo3',
        value: true
      }
    ]
  },
  {
    title: 'Presentation',
    value: [
      {
        title: 'Presentation',
        field: 'presentationValue',
        value: true
      },
      {
        title: 'Presentation Date',
        field: 'presentationDate',
        value: true
      }
    ]
  },
  {
    title: 'Parts',
    value: [
      {
        title: 'Quantity Stock (Good)',
        field: 'goodPartCount',
        value: false
      },
      {
        title: 'Quantity Stock (Total)',
        field: 'partCount',
        value: false
      }
    ]
  },
  {
    title: 'Misc',
    value: [
      {
        title: 'Created At',
        field: 'createdAt',
        value: true
      },
      {
        title: 'Created By',
        field: 'createdBy',
        value: true
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        value: true
      },
      {
        title: 'Updated By',
        field: 'updatedBy',
        value: true
      }
    ]
  },
  {
    title: 'Labels',
    value: [
      {
        title: 'Print Label',
        field: 'printLabel',
        value: true
      }
    ]
  }
];

export const SAMPLE_SITE_SCHEMA = {
  nodeId: -1,
  propertyTypeCode: 'site-schema',
  name: '',
  description: '',
  isActive: true,
  siteSchemaItems: []
};

export const EXPLICIT_SERVICE_STATUS_TRANSITIONS = [
  {
    ruleId: 0,
    selectedServiceType: '',
    selectedServiceStatus: '',
    selectedPriorities: [],
    selectedActionTypes: [],
    blockedStatuses: [],
    isActive: false
  }
];

export const SPECIAL_SERVICE_TASKS = [
  { value: SpecialServiceTasksEnum.SpecialFileUpload, label: 'Special File Upload', additionalDataLabel: 'Special File Upload - Allowed File Types' },
  { value: SpecialServiceTasksEnum.WorkOrderRequest, label: 'Work Order Request', additionalDataLabel: 'Work Order Request - Label' }
];
