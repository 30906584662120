import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useGetUserSystemPermissions } from '../../queries/user-query';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { InlineGroup, HorizontalAlignProps } from '../../components/atoms/InlineGroup';
import { PageContainer } from '../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { ServiceStatusList } from './ServiceStatusList/ServiceStatusList';
import { ActionPermissions, CustomPermission } from '../../@types';
import { isUserHasPermission } from '../../configs/permissions';
import { Wrapper } from '../../styled/common.styles';
import { Link } from '../../components/atoms/Link';
import { ServiceStatus } from './AddEditServiceStatus/AddEditServiceStatus.props';
import { RoutePath } from '../../@types/route.type';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';

export const ServiceStatuses: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  TabHandler(`List Service Statuses | ${PLATFORM_NAME}`);
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            {ServiceStatus.ListServiceStatuses}
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Service_Status_Create, permissions) && (
            <Link href={RoutePath.AddServiceStatus}>
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
                {ServiceStatus.AddServiceStatus}
              </Button>
            </Link>
          )}
        </InlineGroup>
        {isUserHasPermission(ActionPermissions.Configure_Service_Status_Search_View, permissions) && (
          <ServiceStatusList/>
        )}
      </Wrapper>
    </PageContainer>
  );
};