import { Grid } from '@mui/material';
import React from 'react';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { SwHwFwSingleSelectorProps } from './SwHwFwSingleSelector.props';
import { StyledSelectMenu } from './SwHwFwSingleSelector.styles';

export const SwHwFwSingleSelector: React.FC<SwHwFwSingleSelectorProps> = ({
  items,
  swhwfwData,
  verionCheckedData,
  disabledCheckBoxes,
  onChange,
  onChangeCheck
}) => {
  return (
    <Grid container mt={3} spacing={GRID_SPACING}>
      {!disabledCheckBoxes && 
      <Grid item>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={verionCheckedData?.isCheckSWVersions}
          onChange={(event) => onChangeCheck({ ...verionCheckedData, isCheckSWVersions: event.target.checked })}
        />
      </Grid>
      }
      <Grid item xs={3}>
        <StyledSelectMenu
          id="sw-version-menu"
          disabled={!verionCheckedData?.isCheckSWVersions}
          label="Select SW"
          minWidth={240}
          required
          selectedValue={swhwfwData.sw.toString()}
          onChange={(version: string) => onChange({
            ...swhwfwData,
            sw: version
          })}
          items={items.sw}
        />
      </Grid>
      {!disabledCheckBoxes && 
      <Grid item ml={2}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={verionCheckedData?.isCheckHWRevision}
          onChange={(event) => onChangeCheck({ ...verionCheckedData, isCheckHWRevision: event.target.checked })}
        />
      </Grid>
      }
      <Grid item xs={3}>
        <StyledSelectMenu
          id="hw-version-menu"
          disabled={!verionCheckedData?.isCheckHWRevision}
          label="Select HW"
          minWidth={240}
          required
          selectedValue={swhwfwData.hw.toString()}
          onChange={(version: string) => onChange({
            ...swhwfwData,
            hw: version
          })}
          items={items.hw}
        />
      </Grid>
      {!disabledCheckBoxes && 
      <Grid item ml={2}>
        <Checkbox
          color={CheckboxColorProps.Primary}
          checked={verionCheckedData?.isCheckFWVersions}
          onChange={(event) => onChangeCheck({ ...verionCheckedData, isCheckFWVersions: event.target.checked })}
        />
      </Grid>
      }
      <Grid item xs={3}>
        <StyledSelectMenu
          id="fw-version-menu"
          disabled={!verionCheckedData?.isCheckFWVersions}
          label="Select FW"
          minWidth={240}
          required
          selectedValue={swhwfwData.fw.toString()}
          onChange={(version: string) => onChange({
            ...swhwfwData,
            fw: version
          })}
          items={items.fw}
        />
      </Grid>
    </Grid>
  );
};