import React from 'react';
import { ColorSelectorProps } from './ColorSelector.props';
import Menu from '@mui/material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import { MenuContainer, ItemBox } from './ColorSelector.styles';
import { defaultColors } from '../../../configs/colors';
import { FontSizeProps } from '../../../@types';

export const ColorSelector: React.FC<ColorSelectorProps> = ({
  open,
  handleClose,
  anchorEl,
  colors = defaultColors,
  selectedColor,
  onChangeSelectedColor
}) => {
  const onChange = (color: string) => {
    onChangeSelectedColor(color);
    handleClose();
  };

  return (
    <Menu open={open} onClose={handleClose} anchorEl={anchorEl} >
      <MenuContainer>
        {colors && colors.map((color, index) =>
          <ItemBox key={index} colorHex={color} onClick={() => onChange(color)}>{selectedColor == color ? <CheckIcon sx={{ width: 16, height: 16, color: 'white', fontSize: FontSizeProps.Large }} /> : ''}</ItemBox>)}
      </MenuContainer>
    </Menu>
  );
};