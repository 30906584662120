import { NonSerialisedPartsUpdateSelectionDataType } from '../@types/bulkPartUpdate.type';
import { GeneralPermissionGrantDropdownValueType, SerialisedPartsUpdateSelectionDataType } from '../@types/part.type';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { TAGS } from '../constants/tag.constant';
import { capitalizeFirstLetter } from '../utils/common';
import { IsCheckedValuesType } from '../pages/PartsBulkUpdate/SerialisedPartsUpdateSelection/SerialisedPartsUpdateSelection.props';
import { PartActionType } from '../@types/partAction.type';
import { HistoryTypeCode } from '../@types/partHistory.type';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const SAMPLECONDITION = {
  value: '',
  label: ''
};

export const SAMPLE_SFH = {
  softwares: [{
    value: '',
    label: ''
  }],
  firmwares: [{
    value: '',
    label: ''
  }],
  hardwares: [{
    value: '',
    label: ''
  }]
};

export const PARTCONDITIONHISTORY = {
  serviceId: '',
  lastLocation: '',
  lastCondition: '',
};

export const SAMPLE_PART = {
  id: 0,
  partTypeId: 0,
  partType: {
    id: 1,
    name: '',
    client: '',
    number: ''
  },
  locationId: 0,
  nodeName: '',
  mainNodeId: 0,
  locationHierarchy: '',
  nodeList: [],
  conditionCode: '-1',
  conditionName: '',
  condition: SAMPLECONDITION,
  serial1: '',
  serial2: '',
  serial3: '',
  notes: '',
  firstInstalled: '',
  locationTree: '',
  partTypeTree: '',
  tags: [TAGS],
  partConditionHistory: PARTCONDITIONHISTORY,
  partChildrens: [],
  softwareVersionId: -1,
  hardwareRevisionId: -1,
  firmwareVersionId: -1,
  fleetTagId: -1
};

export const SAMPLE_PART_LOCATION = {
  id: 0,
  name: '',
  nodeTypeName: '',
  locationId: 0,
  locationTypeCode: '',
  mainNodeId: 0,
  nodeName: '',
  locationHierarchy: '',
  nodeList: [],
  locationTree: '',
};

export const SAMPLE_UPDATED_PART_LOCATION = {
  id: 0,
  locationId: 0,
  locationTypeCode: '',
  locationHierarchy: ''
};

export const INIT_PART_EDIT_PERMISSIONS = {
  isSerial: false,
  isCondition: false,
  isLocation: false,
  isPartType: false,
  isPartMerge: false,
  isClientHierarchy: false,
};

export const SAMPLE_PART_EXISTANCE = {
  id: 0,
  serial1: '',
  linkedField: '',
  isExist: false
};

export const SAMPLE_NS_PART_EXISTANCE = {
  id: 0,
  partTypeName: '',
  locationId: '',
  conditionName: '',
  fleetTagName: '',
  linkedField: '',
  isExist: false
};

export const SAMPLE_PART_BULK_UPDATE = {
  serialsDataSet: [SAMPLE_PART_EXISTANCE] || [SAMPLE_NS_PART_EXISTANCE],
  smartInput: ''
};

export const PART_MANIFEST = {
  serial: [],
  nonSerial: [],
};

export const PART_ACTION_ITEMS = Object.values(PartActionType).map(action => ({
  value: action,
  label: capitalizeFirstLetter(action)
}));

export const SAMPLE_NON_SERIALIZED_PARTS_UPDATE_SELECTION_DATA: NonSerialisedPartsUpdateSelectionDataType = {
  quantityInStock: 0,
  isActiveLinkedField: false,
  linkedFieldColumn: ''
};

export const LINKED_LIST_DROPDOWN_OPTIONS: Array<DropdownItem> = [{ label: 'QTY', value: 'quantityInStock' }];
export const LINKED_LIST_DROPDOWN_OPTIONS_FOR_SERIALISED: Array<DropdownItem> = [
  { label: 'Part Type Id', value: 'partTypeId' },
  { label: 'Location Id', value: 'locationId' },
  { label: 'Parent Part Id', value: 'parentPartId' },
  { label: 'Condition Code', value: 'conditionCode' },
  { label: 'Software Version Id', value: 'softwareVersionId' },
  { label: 'Firmware Version Id', value: 'firmwareVersionId' },
  { label: 'Hardware Revision Id', value: 'hardwareRevisionId' },
  { label: 'Fleet Tag Id', value: 'fleetTagId' },
  { label: 'Serial 1', value: 'serial1' },
  { label: 'Serial 2', value: 'serial2' },
  { label: 'Serial 3', value: 'serial3' },
  { label: 'Notes', value: 'notes' }
];

export const SAMPLE_BULK_SERIALIZED_PARTS = {
  'Serial': '',
  'Linked_Field': ''
};

export const SAMPLE_BULK_NON_SERIALIZED_PARTS = {
  'Part_Type': '',
  'Structure_Node': '',
  'Condition_Name': '',
  'Fleet_Tag_Name': '',
  'Linked_Field': ''
};

export const SAMPLE_IS_CHECKED_VALUES_DATA: IsCheckedValuesType = {
  isCheckedSerial1: false,
  isCheckedSerial2: false,
  isCheckedSerial3: false,
  isCheckPartType: false,
  isCheckedNotes: false,
  isCheckedSoftware: false,
  isCheckedFirmware: false,
  isCheckedHardware: false,
  isCheckedFleetTag: false,
  isCheckedStructureNode: false,
  isCheckedCondition: false
};

export const SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA: SerialisedPartsUpdateSelectionDataType = {
  serial1: '',
  serial2: '',
  serial3: '',
  partTypeId: 0,
  notes: '',
  software: '-1',
  firmware: '-1',
  hardware: '-1',
  fleetTag: '-1',
  locationId: '',
  conditionCode: '-1',
  isActiveLinkedField: false,
  linkedFieldColumn: '-1',
  locationTypeCode: ''
};

export const SAMPLE_GENERAL_PERMISSION_GRANT_DROPDOWN_DATA: GeneralPermissionGrantDropdownValueType = {
  softwareOptions: [],
  firmwareOptions: [],
  hardwareRevisionOptions: [],
  fleetTagOptions: [],
  conditionCodeOptions: []
};

export const SAMPLE_MERCHANT_DAMAGE_PART_DATA = {
  id: 1,
  partId: 1,
  partTypeName: 'sample part',
  serial: 'sample-serial-123'
};

export const PART_HISTORY_TYPES_FOR_SOLUTION_ACTION = [
  HistoryTypeCode.Installed,
  HistoryTypeCode.Removed,
  HistoryTypeCode.Replaced,
  HistoryTypeCode.Tested,
  HistoryTypeCode.Cleaned,
  HistoryTypeCode.ServicePartAction,
  HistoryTypeCode.ReplacedIn,
  HistoryTypeCode.ReplacedOut,
  HistoryTypeCode.DOA,
  HistoryTypeCode.Connected,
  HistoryTypeCode.Adjusted,
  HistoryTypeCode.Monitored,
  HistoryTypeCode.PoweredUp,
  HistoryTypeCode.Repaired,
];

export const partListColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Part',
    value: [
      {
        title: 'Asset ID',
        field: 'assetId',
        value: true
      },
      {
        title: 'Serial 1',
        field: 'serial1',
        value: true
      },
      {
        title: 'Serial 2',
        field: 'serial2',
        value: false
      },
      {
        title: 'Serial 3',
        field: 'serial3',
        value: false
      },
      {
        title: 'Condition',
        field: 'conditionName',
        value: true
      },
      {
        title: 'Time in Condition',
        field: 'timeInCondition',
        value: false
      },
      {
        title: 'Software Ver',
        field: 'softwareVersion',
        value: false
      },
      {
        title: 'Firmware Ver',
        field: 'firmwareVersion',
        value: false
      },
      {
        title: 'Hardware Rev',
        field: 'hardwareVersion',
        value: false
      },
      {
        title: 'Fleet Tag',
        field: 'fleetTagName',
        value: false
      },
      {
        title: 'Warranty End',
        field: 'warrantyEnd',
        value: false
      },
      {
        title: 'Serialised/ Consumable',
        field: 'serialised',
        value: true
      },
      {
        title: 'Parent Type Code',
        field: 'locationTypeCode',
        value: false
      },
      {
        title: 'Tags',
        field: 'tags',
        value: false
      },
      {
        title: 'QTY',
        field: 'quantityInStock',
        value: true
      },
      {
        title: 'Notes',
        field: 'notes',
        value: false
      },
      {
        title: 'Created At',
        field: 'createdAt',
        value: true
      },
      {
        title: 'Created By',
        field: 'createdByName',
        value: true
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        value: true
      },
      {
        title: 'Updated By',
        field: 'updatedByName',
        value: true
      }
    ]
  },
  {
    title: 'Part Type',
    value: [
      {
        title: 'Part Type ID',
        field: 'partTypeId',
        value: false
      },
      {
        title: 'Part Type Name',
        field: 'partTypeName',
        value: true
      },
      {
        title: 'Part No.',
        field: 'partTypeNumber',
        value: true
      },
      {
        title: 'Part Type Tags',
        field: 'partTypeTags',
        value: false
      },
      {
        title: 'Part Type Active',
        field: 'partTypeIsActive',
        value: false
      },
      {
        title: 'Client',
        field: 'client',
        value: true
      },
      {
        title: 'Contract(s)',
        field: 'contract',
        value: true
      },
    ]
  },
  {
    title: 'Structure',
    value: [
      {
        title: 'Location',
        field: 'location',
        value: true
      },
      {
        title: 'Node ID',
        field: 'nodeId',
        value: true
      },
      {
        title: 'Node Name',
        field: 'nodeName',
        value: true
      },
      {
        title: 'Node Type',
        field: 'nodeTypeName',
        value: true
      },
      {
        title: 'Time in Location',
        field: 'timeInLocation',
        value: true
      },
      {
        title: 'Location - Last Out Part Action',
        field: 'partActionLocation',
        value: false
      },
    ]
  },
  {
    title: 'Labels',
    value: [
      {
        title: 'Print Label',
        field: 'printLabel',
        value: true
      }
    ]
  }
];