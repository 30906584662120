export interface NotificationTemplate {
  id: number;
  name: string;
  type: string;
  templateId: string;
  template: string;
  subject: string;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
}

export interface NotificationTemplatePaginateListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [NotificationTemplate];
}

export interface NotificationTemplateHelperText {
  nameHelperText: string;
  typeHelperText: string;
}

export enum NotificationTemplateFields {
  NameField = 'Name field',
  TypeField = 'Type field',
  TemplateIdField = 'TemplateId field',
}

export enum NotificationTemplateType {
  Email = 'email',
  SMS = 'sms',
}

export interface SendGridTemplate {
  id: number;
  name: string;
}