import React, { useState, useEffect } from 'react';
import { ServiceCheckInFragmentProps } from './ServiceCheckInFragment.props';
import { ServiceCheckIn } from '../../../../components/organisms/ServiceCheckIn';
import {
  getCurrentTime, 
  getCurrentTimestamp, 
  getDateFromTimestamp, 
  getJSTimestamp, 
  getTimestamp 
} from '../../../../utils/common';
import { AllocatedService, CheckInObject, LinkObject } from '../../../../@types';
import { filterInteractionData, processBulkCreateData } from '../../../../utils/services';
import { useCreateServiceCheckIn, useGetServiceCheckIn, useGetServiceListByTechnician, useGetCurrentUserAllocatedServices, useCreateBulkServiceCheckInQuery } from '../../../../queries/service-checkin-checkout-query';

export const ServiceCheckInFragment: React.FC<ServiceCheckInFragmentProps> = ({
  data,
  onClose,
  interactionsAvailable,
  onNoteAndServiceIdsUpdate
}) => {
  const [noteText, setNoteText] = useState<string>('');
  const [travelStart, setTravelStart] = useState<string>('');
  const [onSiteTime, setOnSiteTime] = useState<string>('');
  const [interactionData, setInteractionData] = useState<LinkObject[]>([]);
  const [checkedInService, setCheckedInService] = useState<AllocatedService[]>([]);
  const [notCheckedInService, setNotCheckedInService] = useState<AllocatedService[]>([]);
  const [allocatedServices, setAllocatedServices] = useState<AllocatedService[]>([]);
  const [initialSubmit, setInitialSubmit] = useState(false);

  const createServiceCheckInQuery = useCreateServiceCheckIn(data.id);
  const getServiceCheckInQuery = useGetServiceCheckIn(data.id);
  const getServiceListByTechnician = useGetServiceListByTechnician();
  const getCurrentUserAllocatedServices = useGetCurrentUserAllocatedServices(data.mainLocationNodeId ?? 0, data.id);
  const createBulkServiceCheckInQuery = useCreateBulkServiceCheckInQuery();

  useEffect(() => {
    getCurrentUserAllocatedServices.data && setAllocatedServices(getCurrentUserAllocatedServices.data);
  }, [getCurrentUserAllocatedServices.data]);

  useEffect(() => {
    getServiceListByTechnician.data && setInteractionData(filterInteractionData(getServiceListByTechnician.data, data.id));
  }, [getServiceListByTechnician.data]);

  useEffect(() => {
    setOnSiteTime(getCurrentTime());

    if (data.travelStartTime) {
      const dateObj = getDateFromTimestamp(data.travelStartTime);
      dateObj && setTravelStart(dateObj);
    }

    if (data.id) {
      getServiceCheckInQuery.refetch();
    }
  }, [data]);

  useEffect(() => {
    if (!initialSubmit && travelStart) {
      handleCheckInSubmit();
      setInitialSubmit(true);
    }
  }, [travelStart]);

  useEffect(() => {
    if (interactionsAvailable) {
      const selectedServiceIds: number[] = checkedInService?.map((service) => service.serviceId);
      onNoteAndServiceIdsUpdate(getTimestamp(travelStart),noteText,selectedServiceIds);
    }
  }, [interactionsAvailable,travelStart,noteText,checkedInService]);

  const handleCheckInSubmit = (byPassInteractionCheck?: boolean) => {
    const bulkCreateData: Array<CheckInObject> = processBulkCreateData(
      allocatedServices,
      notCheckedInService,
      checkedInService,
      travelStart,
      data,
      noteText
    );

    const selectedServiceIds: number[] = checkedInService?.map((service) => service.serviceId);

    const checkinData = {
      serviceId: data.id,
      checkInTime: onSiteTime ? getJSTimestamp(onSiteTime) : getCurrentTimestamp(),
      travelStartTime: getTimestamp(travelStart),
      locationNodeId: data.mainLocationNodeId,
      notes: noteText,
      selectedServiceIds: selectedServiceIds,
      byPassInteractionCheck,
      actualCheckInTime: onSiteTime ? getDateFromTimestamp(onSiteTime) : getCurrentTime()
    };

    if (getServiceCheckInQuery.data) {
      createServiceCheckInQuery.mutateAsync(checkinData);
      bulkCreateData.length > 0 && createBulkServiceCheckInQuery.mutateAsync(bulkCreateData);
    } else {
      createServiceCheckInQuery.mutateAsync(checkinData);
    }
  };

  return (
    <ServiceCheckIn 
      travelStart={travelStart}
      onSiteTime={onSiteTime}
      noteText={noteText}
      interactionData={interactionData}
      onNoteText={(val) => setNoteText(val)}
      onChangeTravelStart={(val) => setTravelStart(val)}
      onChangeOnSiteTime={(val) => setOnSiteTime(val)} 
      onSubmit={handleCheckInSubmit}
      onClose={onClose}
      onChangeAllocation={(val, checked) => {
        if(!val?.isUserCheckedIn && checked) {
          setCheckedInService([ ...checkedInService, val ]);
        }
        if(val?.isUserCheckedIn && !checked) {
          setNotCheckedInService([ ...notCheckedInService, val]);
        }
      }}
      allocatedServiceData={allocatedServices}
      interactionsAvailable={interactionsAvailable}
      allocatedServicesLoading={getCurrentUserAllocatedServices.isLoading}
    />
  );
};