import React from 'react';
import { Stack } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { StyledCheckbox } from './CheckItem.styles';
import { CheckItemProps } from './CheckItem.props';

export const CheckItem: React.FC<CheckItemProps> = ({
  label = 'CheckItem',
  value,
  onChange
}) => {
  return (
    <Stack direction="row" alignItems="center" padding={0} spacing={0.5}>
      <StyledCheckbox color="primary" size="small" checked={value} onChange={(evt) => onChange(evt.target.checked)} />
      <Typography variant={TypographyVariantProps.Body2}>{label}</Typography>
    </Stack>
  );
};
