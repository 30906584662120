export enum HistoryTypeCode {
  Installed = 'installed',
  Removed = 'removed',
  Replaced = 'replaced',
  Tested = 'tested',
  PartUpdated = 'part_updated',
  Cleaned = 'cleaned',
  UserTransfer = 'user-transfer',
  ShipmentCancelled = 'shipment-cancelled',
  ShipmentReceived = 'shipment-received',
  InternalTransfer = 'internal-transfer',
  ServiceLogged = 'service-logged',
  ServicePartAction = 'service-part-action',
  ReplacedIn = 'replaced-in',
  ReplacedOut = 'replaced-out',
  DOA = 'doa',
  Connected = 'connected',
  Monitored = 'monitored',
  Adjusted = 'adjusted',
  PoweredUp = 'powered-up',
  Repaired = 'repaired',
  ShipmentDispatched = 'shipment-dispatched',
}

export interface PartActionHistoryDataType {
  id: number;
  partId: number;
  serial: string;
  historyTypeName: string;
  partTypeName: string;
  notes: string;
  createdAt: string;
  createdBy: string;
}