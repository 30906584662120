import { DateTypeNames } from '../@types/attendanceReport.type';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const AUTOCOMPLET_DROPDOWN_ITEMS = {
  label: '',
  value: ''
};

export const MONTHS_OF_YEAR = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const RAW_DATE_RANGES = [
  { label: 'Now', value: 'now' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'first day of this week', value: DateTypeNames.First_Day_Of_This_Week },
  { label: 'first day of this month', value: DateTypeNames.First_Day_Of_This_Month },
  { label: 'first day of this year', value: DateTypeNames.First_Day_Of_This_Year },
  { label: 'last day of this week', value: DateTypeNames.Last_Day_Of_This_Week },
  { label: 'last day of this month', value: DateTypeNames.Last_Day_Of_This_Month },
  { label: 'last day of this year', value: DateTypeNames.Last_Day_Of_This_Year },
  { label: 'first day of last week', value: DateTypeNames.First_Day_Of_Last_Week },
  { label: 'first day of last month', value: DateTypeNames.First_Day_Of_Last_Month },
  { label: 'first day of last year', value: DateTypeNames.First_Day_Of_Last_Year },
  { label: 'last day of last week', value: DateTypeNames.Last_Day_Of_Last_Week },
  { label: 'last day of last month', value: DateTypeNames.Last_Day_Of_Last_Month },
  { label: 'last day of last year', value: DateTypeNames.Last_Day_Of_Last_Year }
];

export const attendanceColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Attendance Report',
    value: [
      {
        title: 'Service ID',
        field: 'serviceId',
        value: true
      },
      {
        title: 'Attendance ID',
        field: 'attendanceId',
        value: true
      },
      {
        title: 'Service Type',
        field: 'serviceType',
        value: false
      },
      {
        title: 'Service Action Type',
        field: 'serviceActionType',
        value: false
      },
      {
        title: 'Service Process Type',
        field: 'serviceProcessType',
        value: false
      },
      {
        title: 'Client/Contract',
        field: 'clientContract',
        value: true
      },
      {
        title: 'Service Location',
        field: 'serviceLocation',
        value: true
      },
      {
        title: 'User',
        field: 'siblingNodeName',
        value: true
      },
      {
        title: 'Parent',
        field: 'parentNodeName',
        value: true
      },
      {
        title: 'Reported CheckIn DateTime',
        field: 'reportedCheckInDateTime',
        value: true
      },
      {
        title: 'Reported CheckOut DateTime',
        field: 'reportedCheckOutDateTime',
        value: true
      },
      {
        title: 'On-Site Time',
        field: 'onSiteTime',
        value: true
      },
      {
        title: 'CheckOut Status',
        field: 'checkOutStatus',
        value: true
      },
      {
        title: 'Actual CheckIn TimeStamp',
        field: 'actualCheckInTimeStamp',
        value: false
      },
      {
        title: 'Actual CheckOut TimeStamp',
        field: 'actualCheckOutTimeStamp',
        value: false
      },
      {
        title: 'Service Location Suburb',
        field: 'serviceLocationSuburb',
        value: false
      },
      {
        title: 'Service Location State',
        field: 'serviceLocationState',
        value: false
      },
      {
        title: 'Service Location Postcode',
        field: 'serviceLocationPostcode',
        value: false
      },
    ]
  }
];