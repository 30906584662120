import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { RoleBox, RoleTypography } from '../../Roles/AddRole/AddRole.styles';
import { MultiSelectCheckmarksMenu } from '../../../components/atoms/MultiSelectCheckmarksMenu';
import { DropdownItem, SelectMenu } from '../../../components/atoms/SelectMenu';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { ActionPermissions, ColorProps, CustomPermission, InputCategoryProps } from '../../../@types';
import { nameValidation } from '../../../utils/services';
import {
  useCreateServiceType,
  useGetAllServiceTargets,
  useGetServiceTypesByCode,
  useGetServiceTypesByShortName,
  useUpdateServiceType
} from '../../../queries/service-type-query';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { ALL_KEYWORD, NAME_FIELD_HELPER_TEXT, SHORT_NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { RoutePath } from '../../../@types/route.type';
import { SHORT_NAME_NOT_EXIST_HELPER_TEXT, TARGET_OPTION_CANNOT_EMPTY_HELPER_TEXT } from '../../../constants/serviceType.constant';
import { ServiceType } from './AddEditServiceType.props';
import { getAvailabeServiceStatuses, getServiceTargets, isError } from '../../../utils/service-type';
import { hasAllKeyword } from '../../../utils/service-type';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { useGetAllServiceStatus } from '../../../queries/service-status-query';

export const AddEditServiceType: React.FC = () => {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  let isNew = searchParams.get('isNew') === 'true' && code === 'add';
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [serviceTargetOptionsList, setServiceTargetOptionsList] = useState<DropdownItem[]>([]);
  const [serviceTargetOptions, setServiceTargetOptions] = useState<string[]>([]);
  const [defaultServiceTargetsList, setDefaultServiceTargetsList] = useState<DropdownItem[]>([]);
  const [defaultServiceTarget, setDefaultServiceTarget] = useState<string>('');
  const [availableStatusesList, setAvailableStatusesList] = useState<DropdownItem[]>([]);
  const [availableStatuses, setAvailableStatuses] = useState<string[]>([]);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isShortCodeExist, setIsShortCodeExist] = useState<boolean>(false);

  const [nameHelperText, setNameHelperText] = useState('');
  const [shortNameHelperText, setShortNameHelperText] = useState('');
  const [targetOptionHelperText, setTargetOptionHelperText] = useState('');
  
  const createServiceType = useCreateServiceType(shortName);
  const updateServiceType = useUpdateServiceType(code || '');
  const getServiceTypesByCode = useGetServiceTypesByCode(code || '');
  const getAllServiceStatus = useGetAllServiceStatus();
  const getAllServiceTargets = useGetAllServiceTargets();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getServiceTypesByShortName = useGetServiceTypesByShortName(shortName);

  TabHandler(isNew ? `Create Service Type | ${PLATFORM_NAME}` : `Service Type: ${code} | Service Types | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  // Get all service types
  useEffect(() => {
    if(getAllServiceTargets.data) {
      const serviceTargetOptionsItems = getServiceTargets(getAllServiceTargets.data, code ?? '');
      setServiceTargetOptionsList(serviceTargetOptionsItems);
    }
  }, [getAllServiceTargets.data]);

  // Get all service statuses
  useEffect(() => {
    if(getAllServiceStatus.data) {
      const availableStatusItems = getAvailabeServiceStatuses(getAllServiceStatus.data);
      setAvailableStatusesList(availableStatusItems);
      isNew && setAvailableStatuses(availableStatusItems.map((item) => {
        return item.value;
      }));
    }
  }, [getAllServiceStatus.data]);

  useEffect(() => {
    !isNew && getServiceTypesByCode.refetch();
  }, [isNew]);

  useEffect(() => {
    if (!isNew && getServiceTypesByCode.data) {
      setName(getServiceTypesByCode.data.name);
      setShortName(getServiceTypesByCode.data.code);
      setServiceTargetOptions(getServiceTypesByCode.data.targetOptions.map((mapItem) => mapItem.code));
      setDefaultServiceTarget(getServiceTypesByCode.data.targetOptions.find((defaultValue: any) => defaultValue.isDefault)?.code);
      setAvailableStatuses(getServiceTypesByCode.data.statusOptions);
      setIsCheck(getServiceTypesByCode.data.isCheckInAvailable);
      setIsActive(getServiceTypesByCode.data.isActive);
    }
  }, [getServiceTypesByCode.data, isNew]);

  useEffect(() => {
    setDefaultServiceTargetsList(serviceTargetOptionsList.filter((filterItem) => serviceTargetOptions.includes(filterItem.value)));
  }, [serviceTargetOptions, serviceTargetOptionsList]);

  // check short name code already exist or not
  useEffect(() => {
    if (isNew && getServiceTypesByShortName.data && shortName) {
      setIsShortCodeExist(true);
      setShortNameHelperText(SHORT_NAME_NOT_EXIST_HELPER_TEXT);
    } else if (isNew && getServiceTypesByShortName.error) {
      setIsShortCodeExist(false);
      setShortNameHelperText('');
    }
  }, [getServiceTypesByShortName.data, getServiceTypesByShortName.error, isNew]);

  const onChangeName = (text: string) => {
    setNameHelperText(
      nameValidation(text, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT, 50)
    );
    setName(text);
  };

  const onChangeShortName = (text: string) => {
    setShortNameHelperText(
      nameValidation(text, InputCategoryProps.ShortName, SHORT_NAME_FIELD_HELPER_TEXT, 3)
    );
    setShortName(text);
  };

  const handleShortNameExist = async () => {
    await getServiceTypesByShortName.refetch();
  };

  const onChangeServiceTargetOptions = (data: string) => {
    if (data === '') {
      setTargetOptionHelperText(TARGET_OPTION_CANNOT_EMPTY_HELPER_TEXT);
    } else {
      setTargetOptionHelperText('');
    }
    setServiceTargetOptions(data === '' ? [] : data.split(','));
  };

  const onChangeDefaultServiceTarget = (data: string) => {
    setDefaultServiceTarget(data);
  };

  const onChangeAvailableStatus = (data: string) => {
    if (hasAllKeyword(data)) {
      setAvailableStatuses(availableStatusesList.map((item) => {
        return item.value;
      }));
    } else if (data.length > 0) {
      setAvailableStatuses(data.split(','));
    } else {
      setAvailableStatuses([]);
    }
  };

  const handleCanUseCheckIn = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCheck(event.target.checked);
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const handleSave = async () => {
    const savedData: any = {
      name: name,
      code: shortName,
      config: {},
      targetOptions: getAllServiceTargets.data?.filter((filterItem) => serviceTargetOptions.includes(filterItem.code)).map((mapItem) => ({
        isDefault: mapItem.code === defaultServiceTarget,
        code: mapItem.code
      })),
      statusOptions: availableStatuses.filter((filteredData) => filteredData !== ALL_KEYWORD),
      isCheckInAvailable: isCheck,
      isActive: isActive
    };
    
    const validateName = nameValidation(name, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT, 50);
    const validateShortName = nameValidation(shortName, InputCategoryProps.ShortName, SHORT_NAME_FIELD_HELPER_TEXT, 3);

    setNameHelperText(validateName);
    setShortNameHelperText(isShortCodeExist ? SHORT_NAME_NOT_EXIST_HELPER_TEXT : validateShortName);
    setTargetOptionHelperText(!serviceTargetOptions.length ? TARGET_OPTION_CANNOT_EMPTY_HELPER_TEXT : '');

    if (isError(isShortCodeExist, validateName, validateShortName, savedData.targetOptions)) {
      return;
    }

    if (isNew) {
      const serviceType: any = await createServiceType.mutateAsync(savedData);
      setOpenToast(true);
      navigate(`${RoutePath.UpdateServiceType}/${serviceType.code}`);
    } else {
      await updateServiceType.mutateAsync(savedData);
      setOpenToast(true);
    }
  };

  if (isNew && isUserHasPermission(ActionPermissions.Configure_Service_Types_Create, permissions)) {
    isNew = true;
  } else if (getServiceTypesByCode.data && isUserHasPermission(ActionPermissions.Configure_Service_Types_Edit, permissions)) {
    isNew = false;
  }

  if (!getServiceTypesByCode.data && !isNew) {
    return getServiceTypesByCode.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  } else if (isNew && !isUserHasPermission(ActionPermissions.Configure_Service_Types_Create, permissions)) {
    return <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <RoleTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        {isNew ? ServiceType.AddServiceType : ServiceType.EditServiceType}
      </RoleTypography>
      <RoleBox>
        <Grid item xs={12} mb={GRID_SPACING}>
          <TextField
            fullWidth
            required
            error={!!nameHelperText}
            helperText={nameHelperText}
            label="Name"
            value={name}
            onChange={(text) => {onChangeName(text);}}
          />
        </Grid>
        <Grid item xs={12} mb={GRID_SPACING}>
          <TextField
            fullWidth
            required
            disabled={!isNew}
            error={isNew && (!!shortNameHelperText || isShortCodeExist)}
            helperText={isNew ? shortNameHelperText : ''}
            label="ShortName"
            value={shortName}
            onChange={(text) => onChangeShortName(text) }
            onBlur={handleShortNameExist}
          />
        </Grid>
        <Grid item xs={12} mb={GRID_SPACING}>
          <MultiSelectCheckmarksMenu 
            id="service-target-options"
            label="Service Target Options *"
            error={!!targetOptionHelperText}
            helperText={targetOptionHelperText}
            selectedValue={serviceTargetOptions}
            onChange={(data) => onChangeServiceTargetOptions(data)}
            items={serviceTargetOptionsList} 
          />
        </Grid>
        <Grid item xs={12} mb={GRID_SPACING}>
          <SelectMenu
            required={false}
            id="default-service-target"
            labelId="default-service-target"
            label="Default Service Target"
            selectedValue={defaultServiceTarget}
            onChange={(data) => onChangeDefaultServiceTarget(data)}
            items={defaultServiceTargetsList}
          />
        </Grid>
        <Grid item xs={12} mb={GRID_SPACING}>
          <MultiSelectCheckmarksMenu
            wildCard 
            id="available-status"
            label="Available Status"
            selectedValue={availableStatuses}
            onChange={(data) => onChangeAvailableStatus(data)}
            items={availableStatusesList} 
          />
        </Grid>
        <Grid item xs={12} mb={1} ml={1}>
          <FormControlLabel
            control={<Switch onChange={handleCanUseCheckIn} checked={isCheck} color={ColorProps.Success}/>}
            label="Can Use Check In"
          />
        </Grid>
        <Grid item xs={12} mb={3} ml={1}>
          <FormControlLabel
            control={<Switch onChange={handleIsActiveChange} checked={isActive} color={ColorProps.Success}/>}
            label="Active"
          />
        </Grid>
        <Grid item container xs={12} spacing={GRID_SPACING} ml={0}>
          <Button variant={ButtonVariantProps.Primary} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </RoleBox>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => { setOpenToast(false); }}
      />
    </PageContainer>
  );
};