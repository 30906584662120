import { httpClient, HttpMethod } from './http-client';
import {
  REACT_APP_API_CONNECTION_NAME,
  REACT_APP_API_PASSWORD
} from '../configs/envs';

export async function createClientAuthSide(
  email: string,
  givenName: string,
  familyName: string,
  picture: string
) {
  const name = givenName+ ' ' + familyName;
  const emailVerified = false;
  const blocked = false;

  const authUser = {
    connection: REACT_APP_API_CONNECTION_NAME,
    email: email,
    given_name: givenName,
    family_name: familyName,
    name: name,
    password: REACT_APP_API_PASSWORD,
    email_verified: emailVerified,
    blocked: blocked,
    user_metadata: {
      picture: picture
    }
  };

  return httpClient('/authUsers', HttpMethod.Post, authUser);
}

export async function updateAuthUserById(userId: string, authUser: any) {
  return httpClient(`/authUsers/${userId}`, HttpMethod.Patch, authUser);
}

export async function getAuthUserByEmail(email: string) {
  return httpClient(`/authUsers/byEmail/${email}`, HttpMethod.Get, null);
}

export async function getAuthUserById(userId: string) {
  return httpClient(`/authUsers/${userId}`, HttpMethod.Get, null);
}

export async function sendWelcomeEmail(userId: string) {
  return httpClient(`/authUsers/welcomeEmail/${userId}`, HttpMethod.Post, null);
}

export const deleteAuthUserById = async (userId: string) => {
  return httpClient(`/authUsers/${userId}`, HttpMethod.Delete, null);
};