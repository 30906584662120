import React, { useState, MouseEvent } from 'react';
import Autocomplete, { } from '@mui/material/Autocomplete';
import { TagsProps, AutocompleteTagType, AutoCompleteChangeReasons } from './Tags.props';
import { ColorSelector } from '../../molecules/ColorSelector';
import { isString } from '../../../utils/common';
import { defaultColors } from '../../../configs/colors';
import { ChipStyled } from './Tags.styles';
import { SizeProps, Tag } from '../../../@types';
import TextField from '@mui/material/TextField';

export const Tags: React.FC<TagsProps> = ({
  allTags,
  selectedTags,
  setSeletedTags,
  newTags,
  setNewTags,
  inputRef,
  label,
  placeHolder,
  disabled
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTag, setSelectedTag] = useState<AutocompleteTagType | string>();
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<any>, option: AutocompleteTagType | string) => {
    if (isString(option) || !option.isSystem) {
      setAnchorEl(event.currentTarget);
      setSelectedTag(option);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSelectedColor = (color: string) => {
    if (isString(selectedTag)) {
      const newTag = newTags.find(item => item.text === selectedTag);
      if (newTag) {
        newTag.colour = color;
      }
    }
    else {
      const tag = selectedTags.find((item) => !isString(item) && item.id == selectedTag?.id);
      if (tag && !isString(tag)) {
        tag.color = color;
      }
      setSeletedTags(selectedTags);
    }
  };

  const setChipColor = (option: AutocompleteTagType | string) => {
    if (isString(option)) {
      const newTag = newTags.find(tag => tag.text === option);
      return newTag?.colour;
    }

    return option.color;
  };

  return (
    <>
      <ColorSelector open={open} handleClose={handleClose} anchorEl={anchorEl} selectedColor={selectedTag && setChipColor(selectedTag)} onChangeSelectedColor={onChangeSelectedColor} />
      <Autocomplete
        disabled = {disabled}
        multiple
        id="tags-filled"
        size={SizeProps.Small}
        options={allTags.map(option => ({ id: option.id, label: option.text, isSystem: option.isSystem, color: option.colour }))}
        freeSolo
        renderTags={(value: readonly (AutocompleteTagType | string)[], getTagProps) =>
          value.map((option: AutocompleteTagType | string, index: number) => (
            // key is assigning through getTagProps with other props 
            // eslint-disable-next-line react/jsx-key
            <ChipStyled clickable bgColor={setChipColor(option)} size={SizeProps.Small} isSystem={!isString(option) && option.isSystem} variant="outlined" label={!isString(option) ? option.label : option} {...getTagProps({ index })} onClick={(event) => handleClick(event, option)} />)
          )
        }
        onChange={(event, value, reason) => {
          if (reason === AutoCompleteChangeReasons.Clear) {
            setNewTags([]);
          }

          if (reason === AutoCompleteChangeReasons.RemoveOption || reason === AutoCompleteChangeReasons.CreateOption) {
            const newlyAssignedTags: Tag[] = [];
            value.map(item => {
              if (isString(item)) {
                const newTag = newTags.find(tag => tag.text === item);

                if (newTag) {
                  newlyAssignedTags.push(newTag);
                }
                else {
                  newlyAssignedTags.push({ id: 0, text: item, colour: defaultColors[0], isSystem: false });
                }
              }
            });
            setNewTags(newlyAssignedTags);
          }

          setSeletedTags(value);
        }}
        isOptionEqualToValue={(option, value) => {
          if (!isString(option) && typeof value !== 'string')
            return option.id === value.id;
          return option === value;
        }}
        value={selectedTags}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || 'Tags'}
            inputRef={inputRef}
            placeholder={placeHolder || 'Add more tags'}
          />
        )} />
    </>);
};