import React from 'react';
import { Grid } from '@material-ui/core';
import { TextField } from '../../atoms/TextField';
import { StyledSelectMenu } from './PartActionRow.styles';
import { PART_ACTION_ROW_DATA, PART_ACTION_TYPES } from '../../../constants/tlc';
import { PartActionRowProps } from './PartActionRow.props';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { GRID_SPACING } from '../../../configs/ui-constants';

export const PartActionRow: React.FC<PartActionRowProps> = ({
  disabled,
  rowData,
  onChangeRow,
  onClearRow
}) => {
  const handleFieldChange = (field: string, rowfieldValue: string) => {
    const RowData = { ...rowData, [field]: rowfieldValue };
    onChangeRow(rowData.id, RowData);
  };

  return (
    <Grid item container mt={3} xs={12} spacing={GRID_SPACING} key={rowData.id}>
      <Grid item xs={5}>
        <StyledSelectMenu
          disabled={disabled}
          id="part-action-menu"
          label="Select Part Action"
          minWidth={240}
          selectedValue={rowData.selectedRowAction}
          onChange={(action) => handleFieldChange(PART_ACTION_ROW_DATA.SELECTED_ROW_ACTION, action)}
          items={PART_ACTION_TYPES}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          disabled={disabled}
          label="Note"
          value={rowData.rowNote}
          onChange={(note) => handleFieldChange(PART_ACTION_ROW_DATA.ROW_NOTE, note)}
        />
      </Grid>
      <Grid item>
        <Button disabled={disabled} variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Error} onClick={() => onClearRow(rowData)}>
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};