import { styled } from '@mui/material/styles';

export const Span = styled('span')`
  padding: 5px;
  min-width: 180px;
  text-align: center;
`;

export const NameSpan = styled('span')`
  min-width: 180px;
  text-align: center;
`;