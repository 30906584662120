import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

export const StyledModalBox = styled(Box)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background-color: white;
  border-radius: 1ch;
  box-shadow: 0 0 10px 5px #363636;
`;

export const ModalContent = styled(Box)`
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2ch;
  margin-top: 15px;
`;

export const ModalFooter = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 5px;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  margin-bottom: 10px;
`;

export const StyledStack = styled(Stack)`
  width: 100%;
  border: 2px solid lightgray;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer !important;
`;