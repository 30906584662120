import React, { ChangeEvent } from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridFeatureModeConstant, GridRenderCellParams } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Add from '@mui/icons-material/Add';
import { Accordian } from '../../atoms/Accordian';
import { FormControlLabel } from '../../atoms/FormControlLabel';
import { ColorProps } from '../../../@types';
import { Switch } from '../../atoms/Switch';
import { TableWrapper, SwitchGrid } from './StructureChild.styles';
import { StructureChildProps } from './StructureChild.props';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { DataGrid } from '../../atoms/DataGrid';
import { Link } from '../../atoms/Link';

export const StructureChild: React.FC<StructureChildProps> = ({
  switchLabel = 'Active',
  childrenData,
  onClick,
  onChangeActiveFlag
}) => {
  const columns: GridColDef[] = [
    {
      field: 'typeName',
      headerName: 'Node Type',
      flex: 0.3,
    },
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams) => params.row.id ? <Link href={`/structure/${params.row.id}`}>{params.row.name}</Link> : params.row.name,
      flex: 0.4,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: false,
      width: 450,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => <Grid container spacing="24px" justifyContent="center" display="flex" width="100%">
        <Grid item xs={1}>
          <IconButton aria-label="edit" color="primary" onClick={() => onClick && onClick(params.row.type)}>
            <Add />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="add" color="primary" onClick={() => onClick && onClick( undefined, params.row.id)}>
            <EditIcon />
          </IconButton>
        </Grid>
        <SwitchGrid item xs={1} marginLeft={2}>
          <FormControlLabel
            control={
              <Switch
                color={ColorProps.Success}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeActiveFlag(e.target.checked, params.row.id)}
                checked={params.row.isActive}
              />
            }
            label={switchLabel}
          />
        </SwitchGrid>
      </Grid>
    }
  ];

  return (
    <Accordian
      title={
        <Grid container alignItems="center">
          <Grid item spacing={1} alignItems="center" sm container display="flex">
            <Grid item>
              <Typography>{`Children (${childrenData.length})`}</Typography>
            </Grid>
            <Grid item marginLeft="auto">
              <Button variant={ButtonVariantProps.Primary} onClick={(e) => { e.stopPropagation(); onClick && onClick(); }}>Add</Button>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <TableWrapper>
        <DataGrid
          columns={columns}
          rows={childrenData}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          initialState={{
            pagination: {
              pageSize: 15
            }
          }}
          paginationMode={GridFeatureModeConstant.client}
          rowsPerPageOptions={[15,30,100]}
          pagination
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
        />
      </TableWrapper>
    </Accordian>
  );
};
