import React from 'react';
import { 
  FormControl,
  Radio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';
import { RadioGroupProps } from './RadioGroup.props';
import { StyledFormLabel, StyledFormControlLabel } from './RadioGroup.styles';

export const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  title,
  options,
  value,
  disabled,
  onChange,
  error
}) => {
  return (
    <FormControl error={error}>
      <StyledFormLabel id={`radio-buttons-group-${id}`}>{title}</StyledFormLabel>
      <MuiRadioGroup
        aria-labelledby={`radio-buttons-group-${id}`}
        name={`radio-buttons-group-${id}`}
        value={value}
        onChange={(event, val) => onChange(val)}
      >
        {options?.map((obj) => (
          <StyledFormControlLabel
            disabled={disabled}
            key={obj.id}
            label={obj.title}
            value={obj.value} 
            style={{ height: '24px' }}
            control={<Radio size="small" />}  
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
