import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../../atoms/Button';

export const StyledDiv = styled('div')`
  margin-top: 5px;
`;

interface StyledTableCellProps extends TableCellProps {
  isMobileView?: boolean;
}

export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  border: 0;
  height: 30px;
  padding: ${(props) => (props.isMobileView ? '6px 0px' : '6px 16px')};
`;

export const CompactCheckBoxTableCell = styled(StyledTableCell)`
  border: 0;
  height: 30px;
  padding: 1px;
  max-width: 48px;
  width: auto;
`;

export const CompactTableCell = styled(StyledTableCell)`
  border: 0;
  height: 30px;
  padding: 2px;
  width: auto;
`;

export const UnknownItemCell = styled(TableCell)`
  border: 0;
  height: 30px;
  background-color: red;
  color: white;
`;

export const SerializedStyledTableCell = styled(TableCell)`
  border: 0;
  height: 15px;
  padding-inline: 10px;
  text-align: center;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(Button)`
  padding: 1px;
`;