import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { 
  ButtonProps, 
  ButtonVariantProps, 
  ButtonColorProps, 
  ButtonSizeProps 
} from './Button.props';
import { StyledButton, StyledText } from './Button.styles';

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = ButtonVariantProps.Primary,
  className,
  startIcon,
  color = ButtonColorProps.Primary,
  fullWidth = false,
  disabled: manuallyDisabled = false,
  size = ButtonSizeProps.Small,
  onClick,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!loading && !manuallyDisabled) {
      try {
        setLoading(true);
        await onClick?.(event);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {variant === ButtonVariantProps.Primary &&
        <StyledButton
          className={className}
          variant="contained"
          startIcon={startIcon}
          color={color}
          fullWidth={fullWidth}
          disabled={loading || manuallyDisabled}
          size={size}
          onClick={handleClick}
        >
          {children}
        </StyledButton>
      }
      {variant === ButtonVariantProps.Secondary &&
        <StyledButton
          className={className}
          variant="outlined"
          startIcon={startIcon}
          color={color}
          fullWidth={fullWidth}
          disabled={loading || manuallyDisabled}
          size={size}
          onClick={handleClick}
          {...rest}
        >
          {children}
        </StyledButton>
      }
      {variant === ButtonVariantProps.Icon &&
        <IconButton onClick={handleClick} size={size}>
          {children}
        </IconButton>
      }
      {variant === ButtonVariantProps.Text &&
        <StyledText color={color} onClick={handleClick}>
          {children}
        </StyledText>
      }
    </>
  );
};
