import React from 'react';
import { Grid, Link, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Add, DoneOutlined, Edit } from '@mui/icons-material';
import { ContractBrandDataType, ContractBrandListProps } from './ContractBrandList.props';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TextField } from '../../atoms/TextField';
import { StyledTableContainer, Topic } from './ContractBrandList.styles';
import { TypographyVariantProps } from '../../atoms/Typography';

export const ContractBrandList: React.FC<ContractBrandListProps> = ({
  gridItems,
  formItems,
  isContract,
  error,
  editBrandDataValues,
  onClickBrandEdit,
  onChange,
  onEditChange,
  handleIsEnableChange,
  onClick,
  onClickAdd
}) => {
  return (
    <>
      <Grid container justifyContent="space-between" mb={1}>
        <Grid item>
          <Topic variant={TypographyVariantProps.H6}>{isContract ? 'Contracts' : 'Brands'}</Topic>
        </Grid>
        <Grid item>
          <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} onClick={onClickAdd}>
            Add
          </Button>
        </Grid>
      </Grid>
      <StyledTableContainer>
        <Table size="small">
          <TableBody>
            {gridItems?.map((row: ContractBrandDataType, index: number) => {
              const editIndex: any = editBrandDataValues?.findIndex((data) => data.index === index);
              return <TableRow
                key={index}
              >
                {editBrandDataValues?.some((data) => data.index === index) ? ( 
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      required
                      error={error}
                      value={editBrandDataValues[editIndex].value.name}
                      onChange={(text: string) => onEditChange && onEditChange({
                        ...editBrandDataValues[editIndex],
                        value: {
                          ...editBrandDataValues[editIndex].value,
                          name: text
                        }
                      }, editIndex)}
                    />
                  </TableCell>
                ) : ( 
                  <TableCell align="center" component="th" scope="row">
                    {isContract && row.id && row.id > 0 ? <Link href={`/configure/clients/contract/${row.id}`}>{row.name}</Link> : row.name}
                  </TableCell>)
                }
                {isContract ?
                  <TableCell align="center">{row.id && row.id > 0 ? <Link href={`/configure/clients/contract/${row.id}`}>{row.shortName}</Link> : row.shortName}</TableCell>
                  :
                  <TableCell align="center">{row.shortName}</TableCell>
                }
                {editBrandDataValues?.some((data) => data.index === index) ||
                  <TableCell align="center">
                    <Switch
                      color={ColorProps.Success}
                      onChange={(event) => handleIsEnableChange({
                        ...row,
                        isEnable: event.target.checked
                      }, index)}
                      checked={row.isEnable}
                    />
                  </TableCell>
                }
                {!isContract && !editBrandDataValues?.some((data) => data.index === index) ? (                   
                  <TableCell align="center">
                    <Edit fontSize="small" aria-label="pdf" color="primary" onClick={() => onClickBrandEdit && onClickBrandEdit(index)}/>
                  </TableCell>
                ) : isContract || (
                  <TableCell align="center">
                    <Button variant={ButtonVariantProps.Secondary} onClick={() => onClick && onClick(index)} >
                      <DoneOutlined/>
                    </Button>
                  </TableCell>
                )}
              </TableRow>;
            })}
            {formItems?.map((data,index) => {
              return <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <TextField
                    fullWidth
                    required
                    error={error}
                    value={data.name}
                    onChange={(value: string) => onChange && onChange({
                      ...data,
                      name: value
                    }, index)}
                    placeholder={isContract ? 'New Contract Name Here':'New Brand Name Here'}
                  />
                </TableCell>
                {isContract &&
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      required
                      error={error}
                      value={data.shortName}
                      onChange={(value: string) => onChange && onChange({
                        ...data,
                        shortName: value
                      }, index)}
                      placeholder={'Contract ShortName Here'}
                    />
                  </TableCell>
                }
                <TableCell align="center">
                  <Button variant={ButtonVariantProps.Secondary} onClick={() => {
                    if(data.name === '') return;
                    else if(isContract && data.shortName === '') return;
                    onClick && onClick(index);
                  }} >
                    <DoneOutlined/>
                  </Button>
                </TableCell>
              </TableRow>;
            })
            }
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};