import React, { useEffect, useState } from 'react';
import { PartHistoryFragmentProps } from '.';
import { PartHistoryDataType } from '../../../../@types/part.type';
import { PartHistory } from '../../../../components/molecules/PartHistory';
import { useGetPartHistoryByPartId } from '../../../../queries/part-query';

export const PartHistoryFragment: React.FC<PartHistoryFragmentProps> = ({
  partId
}) => {
  const [partHistory, setPartHistory] = useState<PartHistoryDataType[]>([]);
  const getPartHistoryByPartId = useGetPartHistoryByPartId(partId);

  useEffect(() => {
    getPartHistoryByPartId.data && setPartHistory(getPartHistoryByPartId.data);
  }, [getPartHistoryByPartId.data]);

  return (
    <PartHistory partHistoryData={partHistory} partId={partId} />
  );
};