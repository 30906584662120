import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Typography } from '../../../../components/atoms/Typography';
import { Box } from '../../../../components/atoms/Box';
import { TextField } from '../../../../components/atoms/TextField';

export const StyledTypography = styled(Typography)`
  margin-bottom:10px;
`;

export const StyledWrapperGrid = styled(Grid)`
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-top: 10px;
`;

export const InputGroupWrapperBox = styled(Box)`
  width: 98%;
  margin-left: 10px;
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 5px;
`;