import { styled } from '@mui/material/styles';
import { ScreenSize } from '../../../@types';

export const Root = styled('div')`
  display: flex;

  @media ${ScreenSize.MOBILE} {
    display: inline;
  };

`;

export const OutletWrapper = styled('div')`
  width: 100%;
  background-color: #fcfdfd;
`;
