import React, { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiListItemButton, { ListItemButtonProps as MuiListItemButtonProps } from '@mui/material/ListItemButton';

export const ListItemButtonStyled = styled((buttonProps: MuiListItemButtonProps & { url: string }) => {
  const linkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <Link ref={ref} {...props} to={buttonProps.url} />);
  linkRef.displayName = 'LinkRef';
  const listItemProps = { component: linkRef };

  return (
    <MuiListItemButton {...listItemProps} {...buttonProps} />
  );
})(() => `
  padding-left: 50px;
  margin-left: 10px;
  margin-top: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;

  &:hover {
    background-color: #2B8293;
  }

  &.Mui-selected {
    background-color: #2B8293;
  }
`);
