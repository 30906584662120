import React from 'react';
import { InputLabel, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { SolutionActionMenuProps } from './SolutionActionMenu.props';
import { 
  StyledFormControl,
  StyledMenuItem,
  MicroText 
} from './SolutionActionMenu.styles';

export const SolutionActionMenu: React.FC<SolutionActionMenuProps> = ({
  options,
  disabled,
  error,
  value,
  onChange,
}) => {
  return (
    <StyledFormControl error={error && !value}>
      <InputLabel id="solution-action-menu-select-label">Action</InputLabel>
      <Select
        labelId="solution-action-menu-select-label"
        id="solution-action-menu-select"
        label="Action"
        value={value}
        disabled={disabled}
        onChange={(event: SelectChangeEvent) => onChange && onChange(event.target.value)}
      >
        <StyledMenuItem 
          value=""
          divider
        >
          <em>Not Selected</em>
        </StyledMenuItem>
        {options?.map((obj, index) => (
          <StyledMenuItem
            key={index} 
            value={obj.value}
            disabled={disabled}
            divider
          >
            <Stack>
              <Typography 
                variant={TypographyVariantProps.Body1}
              >
                {obj.actionName}
              </Typography>
              <Typography 
                variant={TypographyVariantProps.Subtitle2}
              >
                {obj.partType}
              </Typography>
              <MicroText>{obj.note}</MicroText>
            </Stack>
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};
