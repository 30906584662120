import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../../components/atoms/Typography';
import { RadioGroup } from '../../../../../../components/atoms/RadioGroup';
import { DECISION_PROMPT_OPTIONS } from '../../../../../../constants/form-builder';
import { DecisionPromptFormFragmentProps } from './DecisionPromptFormFragment.props';

export const DecisionPromptFormFragment: React.FC<DecisionPromptFormFragmentProps> = ({
  value,
  onChange
}) => {
  const [answer, setAnswer] = useState<string>('');

  return (
    <Grid xs={12} minWidth="400px">
      <Typography
        variant={TypographyVariantProps.Body2}
        fontWeight={TypographyFontWeightProps.Bold}
      >
        {value.questionData?.questionText}
      </Typography>
      <Grid ml={5} mt={1}>
        <RadioGroup
          id={`${value.id}-DecisionPrompt`}
          title=""
          options={DECISION_PROMPT_OPTIONS}
          value={answer}
          onChange={(val) => {
            setAnswer(val);
            onChange({
              ...value,
              questionData: {
                ...value.questionData,
                isYes: val === DECISION_PROMPT_OPTIONS[0].value,
                hasValue: true
              }
            });
          }}
        />
      </Grid>
    </Grid>
  );
};