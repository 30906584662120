import { styled } from '@mui/material/styles';
import { Button } from '../../../../components/atoms/Button';
import { InlineGroup } from '../../../../components/atoms/InlineGroup';
import { PermissionList } from '../../../../components/organisms/PermissionList';
import { PermissionTreeV2 } from '../../../../components/molecules/PermissionTreeV2';

export const Root = styled(InlineGroup)`
  width: 100%;
`;

export const TreeView = styled(InlineGroup)`
  flex: 1;
  flex-direction: column;
  padding-right: 24px;
`;

export const PermissionsWrapper = styled(InlineGroup)`
  flex: 1;
  flex-direction: column;
  border-left: 2px solid lightgray;
  padding-left: 24px;
`;

export const SaveButton = styled(Button)`
  margin-top: 40px;
  width: fit-content;
`;

export const Permissions = styled(PermissionList)`
  margin-top: 20px;
`;

export const PermissionTreeView = styled(PermissionTreeV2)`
  margin-top: 10px;
`;