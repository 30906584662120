import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Button,
  ButtonColorProps,
  ButtonSizeProps,
  ButtonVariantProps
} from '../../atoms/Button';
import { Chip } from '../../atoms/Chip';
import { PrioritySelectMenu } from '../../molecules/PrioritySelectMenu';
import { EditPrioritySelectMenuProps } from './EditPrioritySelectMenu.props';
import { StyledTypography } from './EditPrioritySelectMenu.styles';
import { isDropDownEmpty } from '../../../utils/common';

export const EditPrioritySelectMenu: React.FC<EditPrioritySelectMenuProps> = ({
  id,
  isEditable,
  isFocused,
  items,
  value,
  disabled,
  priorityLabel,
  priorityColor,
  onChange
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (value !== textValue) {
      setTextValue(value);
    }
  }, [value]);

  const handleOnSubmit = () => {
    if (isDropDownEmpty(textValue)) {
      setValidate(true);
      return;
    }

    onChange(textValue);
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setValidate(false);
    setTextValue(value);
    setIsEdit(false);
    setIsShown(false);
  };

  const selectedItem = items?.find((obj) => obj.value === textValue);

  return (
    <Grid
      container 
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
      alignItems="center"
    >
    
      {isEdit ?
        <Grid item width="80%">
          <PrioritySelectMenu
            id={id}
            label=""
            items={items}
            value={textValue || '-1'}
            validate={validate}
            disabled={disabled}
            onChange={(val) => setTextValue(val)}
            isFocused={isFocused}
            isCompact={true}
            optionalLabelEnabled={true}
          />
        </Grid>
        : selectedItem &&
          <Grid item>
            <StyledTypography>
              <Chip
                key={selectedItem.value}
                label={selectedItem.label}
                textColor="white"
                backgroundColor={selectedItem.color}
              /> 
            </StyledTypography>
          </Grid> 
          ||
          <Grid item>
            <StyledTypography>
              <Chip
                label={priorityLabel}
                textColor="white"
                backgroundColor={priorityColor}
              />
            </StyledTypography>
          </Grid> 
      }
      {isEdit &&
        <Grid item>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </Grid>
      }
      {isShown && !isEdit &&
        <Button
          variant={ButtonVariantProps.Icon}
          size={ButtonSizeProps.Small}
          onClick={() => setIsEdit(true)}
        >
          <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
        </Button>
      }
    </Grid>
  );
};
