import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { Grid, Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { TLCTemplateListItemProps } from './TLCTemplateListItem.props';
import { StyledStack, StyledTypography } from './TLCTemplateListItem.styles';

export const TLCTemplateListItem: React.FC<TLCTemplateListItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="row" alignItems="center">
      <Grid pl={2} pr={1} mt={1}>
        <DescriptionIcon color="primary"/>
      </Grid> 
      <Stack direction="column" pl={1}>
        <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>{data.name}</Typography>
        <StyledTypography variant={TypographyVariantProps.Body2} >{data.description}</StyledTypography>
      </Stack>
    </StyledStack>
  );
};