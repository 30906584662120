import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  getStructureTypesByCategoryCode,
  createStructureType,
  getStructureTypeByCode,
  updateStructureType,
  getStructureTypeByCodeForUpdate
} from '../api/structureTypes';
import { StructureTypeResponse } from '../@types';
import { StructureTypeByCodeKey, StructureTypeKey } from '../configs/structure-type';

export const useGetStructureTypesByCategoryCode = (catagoryCode: string): UseQueryResult<StructureTypeResponse[]> => {
  return useQuery([StructureTypeKey], () => getStructureTypesByCategoryCode(catagoryCode), {
    select: (response: any) => response,
    staleTime: 60 * 60 * 1000
  });
};

export const useCreateStructureType = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createStructureType(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([StructureTypeKey]);
    },
  });
};

export const useUpdateStructureType = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateStructureType(data.code, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([StructureTypeKey]);
    },
  });
};

export const useGetStructureTypesByCode = (code: string): UseQueryResult<StructureTypeResponse> => {
  return useQuery([StructureTypeKey + code], () => code !== '-1' && getStructureTypeByCode(code), {
    select: (response: any) => response
  });
};

export const useGetStructureTypesByCodeForUpdate = (code: string): UseQueryResult<StructureTypeResponse> => {
  return useQuery([StructureTypeKey + code], () => getStructureTypeByCodeForUpdate(code), {
    select: (response: any) => response
  });
};

export const useGetStructureTypesExistanceByCode = (code: string): UseQueryResult<StructureTypeResponse> => {
  return useQuery([StructureTypeByCodeKey, code], () => getStructureTypeByCodeForUpdate(code), {
    select: (response: any) => response,
    retry: false,
    enabled: false
  });
};