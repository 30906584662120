export enum DisplayProps {
  Block = 'block',
  Inline = 'inline',
  InlineBlock = 'inline-block',
  Flex = 'flex',
  InlineFlex = 'inline-flex',
  Default = '',
  None = 'none',
}

export enum ScreenSize {
  MOBILE = '(max-width: 600px)',
  DESKTOP = '(min-width: 900px)',
}