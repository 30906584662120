import React from 'react';
import { FormControlLabel as MaterialFormControlLabel } from '@mui/material';
import { FormControlLabelProps } from './FormControlLabel.props';

export const FormControlLabel: React.FC<FormControlLabelProps> = ({
  control,
  label,
  value,
  labelPlacement
}) => {
  return (
    <MaterialFormControlLabel 
      control={control} 
      label={label} 
      value={value} 
      labelPlacement={labelPlacement} 
    />
  );
};
