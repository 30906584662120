import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import {
  getStructuresByTypeCategoryCode,
  getStructuresByTypeCategoryCodeAndTypeCode,
  createStructure,
  getNodeChildrenByNodeType,
  getNodeAttributesByCustomRange,
  getNodeById,
  updateStructure,
  getNodeChildren,
  updateStructureBasicData,
  getNodeSubTree,
  getNodeFilteredAttributesByCustomRange,
  getParentAttributesByPropertyType,
  getPrioritySelection,
  getSuggestgedNodesFromAllocationEngine,
  getSiteSchemasByClientContract,
  getAllPartsForStructures,
  getNodeByName,
  getAllSiteSchemas,
  postSiteSchema,
  updateSiteSchema,
  getSiteSchema,
  getLoggedInSiblingParentDetails,
  getAllShipmentsForStructures,
  getDestinationAddressByNodeId,
  getNodeAttributesByPropertyTypeAndNodeIds,
  getNodeAttributesByNodeId,
  getAllNodesByNodeType,
  mergeStructure,
  getDispatchDestinationSuggestions,
  getNodeAttributeById,
  getAllocationNodeParentData,
  getCommonFleetTagsByPartTypeIds
} from '../api/structures';
import { LocationNodeData, PaginationSettings, StructureResponse, SiteSchemaDropdown, SiblingParentDetails, NodeAttributesReponse, AddressAttributeResponseType, NodeData, DispatchDestinationNodeType, FaultCode } from '../@types';
import { uploadFiles } from '../api/uploadedFiles';
import { StructurePartListType } from '../pages/Structures/AddEditStructureLocation/PartsAccordianFragment';
import {
  FilteredNodeAttributesByCustomRangeKey,
  NodeAttributesByCustomRangeKey,
  NodeByName,
  ParentAttributesByPropertyTypeKey,
  PrioritySelectionObjectTypeKey,
  SiteSchemaAllKey,
  SiteSchemaOneKey,
  StructureChildrenKey,
  StructureKey,
  StructureSubTreeKey,
  StructureSiteSchemaKey,
  PartsForStructuresKey,
  SiblingParentDetailsKey,
  ShipmentsForStructuresKey,
  NodeAttributesByPropertyTypeAndNodeIdsKey,
  NodeAttributesByNodeIdKey,
  NodeByTypeKey,
  NodesAllocationByNode,
  NodesAllocationByService,
  ShipmentDestinationNodesKey,
  NodeAttributeByIdKey,
  NodeAllocationParentByIdKey,
} from '../configs/structures';
import { Folders } from '../@types/uploaded-files.type';
import { StructureShipmentListType } from '../pages/Structures/AddEditStructureLocation/ShipmentAccordianFragment';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';
import { getSelectedColumnNames } from '../utils/columnSelectionOperations';

export interface StructureListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  limit?: number;
  data?: [any];
}

export interface LogoPicUploadData {
  id: string;
  logoPic?: string;
}

export const useGetStructurePaginatedDataByType = (catagoryCode: string, queryOption: any, columnSelectionItems?: ColumnSelectionItem[], defaultExcludedColumns: string[] = []): UseQueryResult<StructureListResponse> => {
  return useQuery([StructureKey, queryOption], () => getStructuresByTypeCategoryCode(catagoryCode, { ...queryOption, excludedColumns: getSelectedColumnNames(columnSelectionItems, defaultExcludedColumns) }), { cacheTime: 0, enabled: false, keepPreviousData: true });
};

export const useGetStructuresByTypeCategoryCodeAndTypeCode = (catagoryCode: string, typeCode: string): UseQueryResult<StructureResponse[]> => {
  return useQuery([StructureKey, catagoryCode, typeCode], () => getStructuresByTypeCategoryCodeAndTypeCode(catagoryCode, typeCode), { enabled: false });
};

export const useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment = (catagoryCode: string, typeCode: string): UseQueryResult<StructureResponse[]> => {
  return useQuery([StructureKey, catagoryCode, typeCode], () => getStructuresByTypeCategoryCodeAndTypeCode(catagoryCode, typeCode));
};

export const useGetNodeChildrenByNodeType = (nodeId: string, nodeTypeCode: string): UseQueryResult<StructureResponse[]> => {
  return useQuery([StructureChildrenKey, nodeId, nodeTypeCode], () => nodeId && getNodeChildrenByNodeType(nodeId, nodeTypeCode), { enabled: false });
};

export const useGetNodeChildrenByNodeTypeAsEnabled = (): UseMutationResult<StructureResponse[]> => {
  return useMutation(async (data: any) => {
    return await getNodeChildrenByNodeType(data.nodeId, data.nodeTypeCode);
  });
};

export const useGetNodeChildren = (nodeId: string): UseQueryResult<StructureResponse[]> => {
  return useQuery([StructureChildrenKey, nodeId], () => getNodeChildren(nodeId), { enabled: false });
};

export const useCreateStructure = (): UseMutationResult<any, any, any, any> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: LogoPicUploadData) => {
    const createResponse = await createStructure(data);

    if (createResponse && createResponse.id && data.logoPic) {
      const formData = new FormData();
      const files = data.logoPic;
      formData.append('fileList', files[0]);
      await uploadFiles(createResponse.id, formData, Folders.ClientContractLogos);
    }

    return createResponse;
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([StructureKey]);
      await queryClient.invalidateQueries([StructureChildrenKey, `${response.parentNodeId}`, `${response.nodeTypeCode}`]);
    },
  });
};

export const useGetFilteredNodeAttributesByCustomRange = (nodeId: string, nodeAttributes: any, serviceTypeCode: string): UseQueryResult<any> => {
  return useQuery([FilteredNodeAttributesByCustomRangeKey, nodeId, nodeAttributes, serviceTypeCode], () =>
    getNodeFilteredAttributesByCustomRange(nodeId, nodeAttributes, serviceTypeCode), { enabled: false }
  );
};

export const useGetNodeAttributesByCustomRange = (nodeId: string, nodeAttributes: any): UseQueryResult<any> => {
  return useQuery([NodeAttributesByCustomRangeKey, nodeId, nodeAttributes], () => getNodeAttributesByCustomRange(nodeId, nodeAttributes), { enabled: false });
};

export const useGetNodeAttributesByCustomRangeForReplenishment = (nodeId: string, nodeAttributes: any): UseQueryResult<any> => {
  return useQuery([NodeAttributesByCustomRangeKey, nodeId, nodeAttributes], () => getNodeAttributesByCustomRange(nodeId, nodeAttributes));
};

export const useGetNodeByName = (name: string, nodeTypeCode: string, parentNodeId?: number): UseQueryResult<any> => {
  return useQuery([NodeByName, name, nodeTypeCode, parentNodeId || 0], () => name && nodeTypeCode && getNodeByName(name, nodeTypeCode, parentNodeId));
};

export const useUpdateStructure = (): UseMutationResult<any, any, any, any> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: LogoPicUploadData) => {
    if (data.logoPic) {
      const formData = new FormData();
      const files = data.logoPic;
      formData.append('fileList', files[0]);
      await uploadFiles(Number(data.id), formData, Folders.ClientContractLogos);
    }

    return await updateStructure(data.id, data);
  }, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([StructureKey]);
      await queryClient.invalidateQueries([StructureKey, `${data.id}`]);
      await queryClient.invalidateQueries([StructureSubTreeKey, `${data.id}`]);
      await queryClient.invalidateQueries([StructureChildrenKey, `${data.parentNodeId}`, `${data.nodeTypeCode}`]);
    },
  });
};

export const useUpdateStructureBasicData = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateStructureBasicData(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([StructureKey]);
    },
  });
};

export const useGetNodeById = (nodeId: string, nodeTypeCategoryCode?: string): UseQueryResult<StructureResponse> => {
  return useQuery([StructureKey, nodeId], async () => getNodeById(nodeId, nodeTypeCategoryCode), { enabled: false });
};

export const useGetNodeSubTree = (nodeId: string): UseQueryResult<StructureResponse[]> => {
  return useQuery([StructureSubTreeKey, nodeId], () => nodeId !== '0' && getNodeSubTree(nodeId), { enabled: false });
};

export const useGetParentAttributesByPropertyType = (nodeId: string, propertyTypeCode: string): UseQueryResult<any[]> => {
  return useQuery([ParentAttributesByPropertyTypeKey, nodeId, propertyTypeCode], () => getParentAttributesByPropertyType(nodeId, propertyTypeCode), { enabled: false });
};

export const useGetPrioritySelection = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await getPrioritySelection(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PrioritySelectionObjectTypeKey]);
    },
  });
};

export const useGetSuggestedNodesFromAllocationEngine = (serviceTypeCode: string, actionTypeCode: string, processTypeCode: string, brandId?: number, mainLocationId?: number, serviceId?: number): UseQueryResult<LocationNodeData[]> => {
  return useQuery(serviceId ? [NodesAllocationByService, serviceId] : [NodesAllocationByNode, serviceTypeCode, actionTypeCode, processTypeCode, mainLocationId], () => serviceTypeCode && actionTypeCode && mainLocationId && getSuggestgedNodesFromAllocationEngine(serviceTypeCode, actionTypeCode, processTypeCode, brandId, mainLocationId, serviceId),{ enabled: false });
};

export const useGetSiteSchemasByClientContract = (clientId: string, contractId: string): UseQueryResult<SiteSchemaDropdown[]> => {
  return useQuery([StructureSiteSchemaKey, contractId], () => getSiteSchemasByClientContract(clientId, contractId), {
    enabled: false
  });
};

export const useGetAllPartsForStructures = (pageSettings: PaginationSettings, nodeId: number, isEnableToShowChildrenParts: boolean): UseQueryResult<StructurePartListType> => {
  return useQuery({
    queryKey: [PartsForStructuresKey, pageSettings.currentPage, pageSettings.limit, nodeId, isEnableToShowChildrenParts],
    queryFn: async () => getAllPartsForStructures(pageSettings.limit, pageSettings.currentPage, nodeId, isEnableToShowChildrenParts),
    cacheTime: 0
  });
};

export const useGetAllSiteSchemas = (nodeId: number) => {
  return useQuery([SiteSchemaAllKey, nodeId], () => getAllSiteSchemas(nodeId));
};

export const useGetSiteSchema = (siteSchemaId: number) => {
  return useQuery([SiteSchemaOneKey, siteSchemaId], () => getSiteSchema(siteSchemaId));
};

export const useCreateSiteSchema = (nodeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await postSiteSchema(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([SiteSchemaOneKey, response.id]);
      await queryClient.invalidateQueries([SiteSchemaAllKey, nodeId]);
    },
  });
};

export const useUpdateSiteSchema = (nodeId: number) => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateSiteSchema(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([SiteSchemaOneKey, response.id]);
      await queryClient.invalidateQueries([SiteSchemaAllKey, nodeId]);
    },
  });
};

export const useGetLoggedInSiblingParentDetails = (): UseQueryResult<SiblingParentDetails> => {
  return useQuery([SiblingParentDetailsKey], () => getLoggedInSiblingParentDetails(), {
    enabled: false
  });
};

export const useGetAllShipmentsForStructures = (pageSettings: PaginationSettings, nodeId: number, isEnableToShowChildrenShipments: boolean): UseQueryResult<StructureShipmentListType> => {
  return useQuery({
    queryKey: [ShipmentsForStructuresKey, pageSettings.currentPage, pageSettings.limit, nodeId, isEnableToShowChildrenShipments],
    queryFn: async () => getAllShipmentsForStructures(pageSettings.limit, pageSettings.currentPage, nodeId, isEnableToShowChildrenShipments),
    cacheTime: 0
  });
};

export const useGetDestinationAddressByNodeId = (nodeId: string): UseQueryResult<AddressAttributeResponseType[]> => {
  return useQuery([ShipmentsForStructuresKey, nodeId], () => getDestinationAddressByNodeId(nodeId), {
    enabled: false
  });
};

export const useGetNodeAttributesByPropertyTypeAndNodeIds = (propertyTypeCode: string, nodeIds: string): UseQueryResult<NodeAttributesReponse[]> => {
  return useQuery([NodeAttributesByPropertyTypeAndNodeIdsKey, propertyTypeCode, nodeIds], () => getNodeAttributesByPropertyTypeAndNodeIds(propertyTypeCode, nodeIds));
};

export const useGetNodeAttributesByNodeId = (nodeId: number): UseQueryResult<NodeAttributesReponse[]> => {
  return useQuery([NodeAttributesByNodeIdKey, nodeId], () => nodeId && getNodeAttributesByNodeId(nodeId));
};

export const useGetAllNodesByNodeType = (nodeTypeCode: string): UseQueryResult<NodeData[]> => {
  return useQuery([NodeByTypeKey, nodeTypeCode], () => getAllNodesByNodeType(nodeTypeCode));
};

export const useMergeStructure = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await mergeStructure(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([StructureKey]);
    },
  });
};

export const useGetDispatchDestinationSuggestions = (serviceId: number, mainLocationNodeId: number, subLocationNodeId: number, primaryAllocationNodeId: number, secondaryAllocationNodeId: number): UseQueryResult<DispatchDestinationNodeType[]> => {
  return useQuery([ShipmentDestinationNodesKey, serviceId], () => serviceId && getDispatchDestinationSuggestions(serviceId, mainLocationNodeId, subLocationNodeId, primaryAllocationNodeId, secondaryAllocationNodeId), {
    enabled: false
  });
};

export const useGetNodeAttributeById = (nodeAttributeId: number): UseQueryResult<FaultCode> => {
  return useQuery([NodeAttributeByIdKey, nodeAttributeId], () => nodeAttributeId !== -1 && getNodeAttributeById(nodeAttributeId));
};

export const useGetAllocationNodeParentData = (nodeId?: number): UseQueryResult<NodeData> => {
  return useQuery([NodeAllocationParentByIdKey, nodeId], () => nodeId && getAllocationNodeParentData(nodeId),{ enabled: false });
};

export const useGetCommonFleetTagsByPartTypeIds = (): UseMutationResult<{ id: number; name: string }[]> => {
  return useMutation(async (data: any) => {
    return await getCommonFleetTagsByPartTypeIds(data.partTypeIds);
  });
};