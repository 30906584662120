import React, { useEffect, useState } from 'react';
import { DropdownItem } from '../../../../../../../components/atoms/SelectMenu';
import { LiveSearchListItem } from '../../../../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../../../../components/molecules/LocationSearchItem';
import { useSearchNodesParts } from '../../../../../../../queries/live-search-query';
import { useGetPartLocationDataById, useGetPartConditions, useGetOnePart } from '../../../../../../../queries/part-query';
import { useGetSoftwareFirmwareHardwareOptions } from '../../../../../../../queries/part-type-query';
import { ParameterUIDataFormPartAttributes } from '../../../../../../../@types';
import { PartParameterFormFragmentProps } from './PartParameterFormFragment.props';
import { StyledStack, StyledSelectMenu, StyledLiveSearchBox } from './PartParameterFormFragment.styles';
import { getPartSFHversions } from '../../../../../../../utils/form-generator';

export const PartParameterFormFragment: React.FC<PartParameterFormFragmentProps> = ({
  partParameterData,
  isError,
  value,
  onChange,
  partActionData
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<Array<DropdownItem>>([]);
  
  const getPartLocationDataById = useGetPartLocationDataById(partActionData?.value.partId || 0);
  const searchNodesParts = useSearchNodesParts();
  const getPartConditions = useGetPartConditions();
  const getSoftwareFirmwareHardwareOptions = useGetSoftwareFirmwareHardwareOptions(partActionData?.value.partTypeId || 0);
  const getOnePart = useGetOnePart(partActionData?.value.partId || 0);

  useEffect(() => {
    if (getPartConditions.data && partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartCondition) {
      setDropdownOptions(getPartConditions.data);
    }
  }, [getPartConditions.data]);

  useEffect(() => {
    if (partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartSoftware ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartHardware ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartFirmware) {
      if (getSoftwareFirmwareHardwareOptions.data) {
        setDropdownOptions(getPartSFHversions(getSoftwareFirmwareHardwareOptions.data, partParameterData?.partAttribute));
      } else {
        getSoftwareFirmwareHardwareOptions.refetch();
      }
    }
  }, [partActionData?.value, getSoftwareFirmwareHardwareOptions.data]);

  useEffect(() => {
    if (getOnePart.data && partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartFleetTag) {
      const fleetTags = getOnePart.data.fleetTag?.map((fleetTag) => ({
        label: fleetTag.label,
        value: fleetTag.value.toString()
      }));
      fleetTags && setDropdownOptions(fleetTags);
    }
  }, [getOnePart.data]);

  return (
    <StyledStack spacing={2}>
      {partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartLocation &&
        <StyledLiveSearchBox
          title={partParameterData?.partAttribute} 
          timeOffset={400}
          value={value?.name || ''} 
          onClearValue={() => onChange('')}
          onChange={(locationData) => onChange(locationData)}
          onApiInvoke={async (name) => {
            return await searchNodesParts.mutateAsync({ 
              id: partActionData?.value.partId,
              name: name.replace('#', '%23'),
              nodeIdTree: getPartLocationDataById.data?.locationHierarchy 
            });
          }} 
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          isError={isError && !value}
        />
      }
      {(partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartCondition ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartSoftware ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartHardware ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartFirmware ||
        partParameterData?.partAttribute === ParameterUIDataFormPartAttributes.PartFleetTag) &&
        <StyledSelectMenu
          id="part-parameter-fragment-attribute-options"
          label={partParameterData?.partAttribute}
          items={dropdownOptions}
          selectedValue={value}
          onChange={(partAttributeData) => onChange(partAttributeData)}
          validate={isError && !value}
        />
      }
    </StyledStack>
  );
};