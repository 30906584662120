import React from 'react';
import { Stack } from '@mui/material';
import { TextField } from '../../atoms/TextField';
import { PartTypeActionNoteProps } from './PartTypeActionNote.props';

export const PartTypeActionNote: React.FC<PartTypeActionNoteProps> = ({
  value,
  error,
  helperText,
  onChange,
}) => {
  return (
    <Stack direction="row" spacing={1} width="100%">
      <TextField
        label="Notes" 
        multiline
        rows={3}
        fullWidth
        value={value}
        error={error}
        helperText={helperText}
        onChange={onChange}
      />
    </Stack>
  );
};