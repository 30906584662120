import { styled } from '@mui/material/styles';
import { Box } from '../../../../../components/atoms/Box';
import { Button } from '../../../../../components/atoms/Button';

export const GridContainer = styled('div')`
  height: 35vh;
  width: 96%;
  margin: 20px 50px 10px 10px;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    overflow: hidden;
    white-space: initial !important;
    display: flex !important;
    align-items: center;
    padding-top: 15px !important; 
    padding-bottom: 15px !important;
    background-color: none; 
    border: 1px solid #ddd;
  }
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export const ContentWrapperBox = styled(Box)`
  overflow-y: auto;
  overflow-x: auto;
  height: 60vh;
`;