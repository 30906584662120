export const ShipmentsKey = 'shipments';
export const ShipmentBatchReceiveKey = 'shipment-batch-receive';
export const ShipmentsReturnSuggestionsKey = 'shipments-return-suggestions';
export const ShipmentStatusKey = 'shipment-status';
export const ShipmentTypeKey = 'shipment-type';
export const ShipmentAddStockManifestKey = 'shipment-add-stock-manifest';
export const ShipmentBatchReceiveOneKey = 'shipment-batch-receive-one';
export const ShipmentIndividualReceiveKey = 'shipment-individual-receive';
export const ShipmentManifestKey = 'shipment-manifest';
export const ShipmentServiceAllocationListKey = 'shipment-service-allocation-list';
export const ShipmentIncomingShipmentsKey = 'shipment-incoming-shipments';
export const ShipmentServiceAllocationsKey = 'shipment-service-allocations';
export const ShipmentManifestSuppliersKey = 'shipment-manifest-suppliers';
export const PartShipmentManifestKey = 'part-shipment-manifest';
export const ShipmentsReturnSourceSuggestionKey = 'shipment-return-source-suggestions';
export const ShipmentsReturnDestinationSuggestionKey = 'shipment-return-destination-suggestions';
export const LatestShipmentByPartIdKey = 'latest-shipment-by-part-id-key';