import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import {
  getRoles,
  getRoleById,
  getActiveRoles,
  createRole,
  createRolePermission,
  getPermission,
  getRolePermissionForRoleEdit,
  updateRole,
  updateRolePermission,
  getRolePermissionForUserEdit
} from '../api/roles';
import { RoleListResponse, Role, QueryOption } from '../@types';
import { ActiveRolesKey, RolePermissionsKey, RolesKey } from '../configs/roles';

export const useGetAllRoles = (queryOption: QueryOption): UseQueryResult<RoleListResponse> => {
  return useQuery([RolesKey, queryOption.page, queryOption.pageSize], () => getRoles(queryOption));
};

export const useGetOneRole = (id: number): UseQueryResult<Role> => {
  return useQuery([RolesKey, id], () => getRoleById(id));
};

export const useGetAllActiveRoles = (): UseQueryResult<[Role]> => {
  return useQuery([ActiveRolesKey], () => getActiveRoles(), {
    select: (response: any) => response
  });
};

export const useAddRole = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await createRole(data);
    await createRolePermission(response.id, data.rolePermissions);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([RolesKey]);
    },
  });
};

export const useGetPermissions = (): UseQueryResult<any> => {
  return useQuery([RolePermissionsKey], () => getPermission(), { staleTime: 2 * 60 * 60 * 1000 });
};

export const useGetRolePermissionsForRoleEdit = (roleId: number): UseQueryResult<any> => {
  return useQuery([RolePermissionsKey, roleId], () => roleId > 0 && getRolePermissionForRoleEdit(roleId), { staleTime: 60 * 60 * 1000 });
};

export const useGetRolePermissionsForUserEdit = (roleId: number): UseQueryResult<any> => {
  return useQuery([RolePermissionsKey, roleId], () => roleId > 0 && getRolePermissionForUserEdit(roleId), { staleTime: 60 * 60 * 1000 });
};

export const useUpdateRole = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    await updateRole(data.id, data);
    await updateRolePermission(data.id, data.rolePermissions);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([RolesKey]);
    },
  });
};