import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { StyledStack } from './PartTypeSearchItem.styles';
import { PartTypeSearchItemProps } from './PartTypeSearchItem.props';

export const PartTypeSearchItem: React.FC<PartTypeSearchItemProps> = ({
  data,
  isPart,
  partData
}) => {
  return (
    <>
      {!isPart && data &&
        <StyledStack direction="column">
          <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.displayId}</Typography>
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.name}</Typography>
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Part No: {data.number}</Typography>
        </StyledStack>
      }
      {isPart && partData &&
        <StyledStack direction="column">
          <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{partData.displayId}</Typography>
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{partData.name}</Typography>
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{partData?.partId && 'Part No:' + partData?.partId}</Typography>
          <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{partData?.ns_partId && 'NS Part No:' + partData?.ns_partId}</Typography>
        </StyledStack>
      }
    </>
  );
};