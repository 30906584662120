import * as React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import { TextField } from '../../atoms/TextField';
import { AddressComponent, GeocoderResult, MapSearchProps, PlaceType } from './MapSearch.props';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { GOOGLE_MAPS_API_KEY } from '../../../configs/envs';
import { SizeProps } from '../../../@types';
import { isEmptyString } from '../../../utils/common';

const loadScript = (src: string, position: HTMLElement | null, id: string) => {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };
const geocoder = { current: null };

export const MapSearch: React.FC<MapSearchProps> = ({ label, selectedValue, addressData, onChange }) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(selectedValue ? selectedValue : '');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geocoding`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useCallback((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
    (autocompleteService.current as any).getPlacePredictions(request, callback);
  }, []);

  const fillAddressFields = (components: AddressComponent[]) => {
    let fillAddress = { 
      line1: '', 
      suburb: '',
      city: '',
      stateName: '',
      countryName: '',
      postCode: '' 
    };
    components.map(({ longName, types }: AddressComponent) => {
      if(types.includes('subpremise')) {
        fillAddress = { ...fillAddress, line1: longName };
      } else if (types.includes('street_number')) {
        fillAddress = { ...fillAddress, line1: `${fillAddress.line1 && fillAddress.line1.concat('/')}${longName}` };
      } else if (types.includes('route')) {
        fillAddress = { ...fillAddress, line1: `${fillAddress.line1 && fillAddress.line1.concat(' ')}${longName}` };
      } else if (types.includes('sublocality')) {
        fillAddress = { ...fillAddress, suburb: longName };
      } else if (types.includes('locality')) {
        if (isEmptyString(fillAddress.suburb))
          fillAddress = { ...fillAddress, suburb: longName };
        else
          fillAddress = { ...fillAddress, city: longName };
      } else if (types.includes('administrative_area_level_2')) {
        const countryValidation = components.find((field) => field.types.includes('country'))?.longName !== 'Australia';
        if (isEmptyString(fillAddress.city) && countryValidation)
          fillAddress = { ...fillAddress, city: longName };
      } else if (types.includes('administrative_area_level_1')) {
        fillAddress = { ...fillAddress, stateName: longName };
      } else if (types.includes('country')) {
        fillAddress = { ...fillAddress, countryName: longName };
      } else if (types.includes('postal_code')) {
        fillAddress = { ...fillAddress, postCode: longName };
      }
    });
    return fillAddress;
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
      geocoder.current = new (window as any).google.maps.Geocoder();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        if(selectedValue === newOptions[0].description){
          setValue(newOptions[0]);
        }

        setOptions(newOptions);
      }
    });
    value && addressData && onChange({
      ...addressData,
      googleAddress: value?.description
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, selectedValue]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(_event, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if(geocoder.current){
          (geocoder.current as any).geocode({ placeId: newValue?.place_id }, function(results: any[], status: string) {
            if (status == 'OK') {
              const result: GeocoderResult = {
                formattedAddress: results[0]?.formatted_address || '',
                addressComponents: results[0]?.address_components.map((component: any) => ({
                  longName: component['long_name'],
                  shortName: component['short_name'],
                  types: component['types'],
                }))
                 || [],
                placeId: results[0]?.place_id || ''
              };
              const fillAddress = fillAddressFields(result.addressComponents);
              addressData && onChange({
                ...addressData,
                ...fillAddress,
                googleAddress: result.formattedAddress,
                googleResponse: results[0]
              });
            }
          });
        }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return <TextField {...params} label={label} fullWidth size={SizeProps.Small} />;
      }}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: React.Key) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant={TypographyVariantProps.Body2}>
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
