import React from 'react';
import ReactQuill from 'react-quill';
import { RichTextEditorProps, defaultModules } from './RichTextEditor.props';
import { StyledReactQuill } from './RichTextEditor.styles';
import './quill.snow.css';

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onValueChange,
  readOnly,
  modules,
  error
}) => {
  return <ReactQuill style={StyledReactQuill(!!error)} theme="snow" value={value} onChange={onValueChange} modules={modules ?? defaultModules} readOnly={readOnly}/>;
};