import { ReactNode } from 'react';

export enum HorizontalAlignProps {
  Center = 'center',
  Start = 'start',
  End = 'end',
  Around = 'around',
  Between = 'between',
  Evenly = 'evenly'
}

export interface PageContainerProps {
  align?: HorizontalAlignProps;
  children: ReactNode;
  className?: string;
}
