import { styled } from '@mui/material';
import { ArrowUpward, PeopleAlt } from '@mui/icons-material';
import { Box, BoxProps } from '../../atoms/Box';
import { Button } from '../../atoms/Button';

export const ArrowUpwardIcon = styled(ArrowUpward)`
  color: #ED6C02;
`;

export const IconContainer = styled('div')((props: { backgroundColor: string }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${props.backgroundColor};
  margin-right: 10px;
`);

export const SecondaryAllocationIcon = styled(PeopleAlt)`
  color: #ED6C02;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: whitesmoke!important;
`;

export const LiveSearchWrapper = styled('div')`
  width: 100%;
`;

export const StyledBox = styled(Box)((props: BoxProps & { heighlightBackground?: boolean }) => `
  background-color: ${props.heighlightBackground ? 'rgba(255, 99, 71, 0.1)' : 'transparent'};`
);

export const StyledButton = styled(Button)`
  margin-right: 10px;
`;