import { IMPORT_SESSION_FILE_DATA } from '../configs/envs';
import { ImportSessionFileData, ImportSessionResource } from '../@types';
import { ImportStatusCode } from '../configs/enums';

export const setImportSessionData = (resourceType: string, importFileData: ImportSessionFileData) => {
  importFileData && sessionStorage.setItem(`${IMPORT_SESSION_FILE_DATA} - ${resourceType}`, JSON.stringify(importFileData));
};

export const getImportSessionData = (resourceType: string) => {
  const importDataJson = sessionStorage.getItem(`${IMPORT_SESSION_FILE_DATA} - ${resourceType}`);
  if (importDataJson) {
    return JSON.parse(importDataJson);
  } 
};

export const clearImportSessionData = (resourceType: string) => {
  sessionStorage.removeItem(`${IMPORT_SESSION_FILE_DATA} - ${resourceType}`);
};

export const getImportedRecordsCount = (data: ImportSessionResource[], importStatus: string) => {
  return data?.filter((record) => record.statusCode === importStatus).length;
};

export const getImportedRecordsStatusCount = (data: any, importStatus: string) => {
  const statusData = data?.find((record: any) => record.statusCode === importStatus);
  const count = statusData ? statusData.count : 0;
  return count;
};

export const getImportFailedRecords = (data: any) => {
  return data?.find((record: any) => record.statusCode === ImportStatusCode.ProcessingFailed)?.fileLines;
};