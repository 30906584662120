import { styled } from '@mui/material/styles';
import { TextField } from '../../atoms/TextField';

export const StyledScrollableWrapper = styled('div')`
  max-height: 400px; 
  height: 250px; 
  overflow-y: auto;
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;
`;