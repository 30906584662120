import { HttpMethod, httpClient } from './http-client';
import { 
  SoftwareFirmwareHardwareData,
  WarrantyData, 
  ChildPartTypeData, 
  SalePricingObject, 
  SalesRepairSupplierData 
} from '../@types/partType.type';
import { RequestDataType } from '../@types/tlc.type';

export const getPartTypeById = (id: number) => {
  return httpClient(`/partTypes/${id}`, HttpMethod.Get, null);
};

export const getPartTypeByIdForService = (id: number) => {
  return httpClient(`/partTypes/${id}/forService`, HttpMethod.Get, null);
};

export const getAllConfigurationHistory = (partTypeId: number) => {
  return httpClient(`/configurationHistories/getByPartType/${partTypeId}`, HttpMethod.Get, null);
};

export const createPartType = (partType: any) => {
  return httpClient('/partTypes', HttpMethod.Post, partType);
};
  
export const updatePartType = (id: number, partType: any) => {
  return httpClient(`/partTypes/${id}`, HttpMethod.Put, partType);
};

export const createWarranty = (warranty: WarrantyData) => {
  return httpClient('/warranties', HttpMethod.Post, warranty);
};
  
export const updateWarranty = (id: number, warranty: WarrantyData) => {
  return httpClient(`/warranties/${id}`, HttpMethod.Put, warranty);
};

export const createSupplier = (supplier: SalesRepairSupplierData) => {
  return httpClient('/supplierPricings', HttpMethod.Post, supplier);
};

export const updateSupplier = (id: number, supplier: SalesRepairSupplierData) => {
  return httpClient(`/supplierPricings/${id}`, HttpMethod.Put, supplier);
};

export const createSFHware = (sfhware: SoftwareFirmwareHardwareData) => {
  return httpClient('/sfhwares', HttpMethod.Post, sfhware);
};
  
export const updateSFHware = (id: number, sfhware: SoftwareFirmwareHardwareData) => {
  return httpClient(`/sfhwares/${id}`, HttpMethod.Put, sfhware);
};

export const createSalePricing = (partTypeId: number, salePricing: SalePricingObject) => {
  return httpClient(`/partTypes/salePricings/${partTypeId}`, HttpMethod.Post, salePricing);
};
  
export const updateSalePricing = (id: number, salePricing: SalePricingObject) => {
  return httpClient(`/partTypes/salePricings/${id}`, HttpMethod.Put, salePricing);
};

export const createRepairPricing = (partTypeId: number, repairPricing: SalesRepairSupplierData) => {
  return httpClient(`/partTypes/repairPricings/${partTypeId}`, HttpMethod.Post, repairPricing);
};
  
export const updateRepairPricing = (id: number, repairPricing: SalesRepairSupplierData) => {
  return httpClient(`/partTypes/repairPricings/${id}`, HttpMethod.Put, repairPricing);
};

export const createChildPartType = (partTypeId: number, childPartType: ChildPartTypeData) => {
  return httpClient(`/partTypes/childrens/${partTypeId}`, HttpMethod.Post, childPartType);
};
  
export const updateChildPartType = (partTypeId: number, childPartType: ChildPartTypeData) => {
  return httpClient(`/partTypes/childrens/${partTypeId}`, HttpMethod.Put, childPartType);
};

export const deleteChildPartType = (partTypeId: number, childPartTypeId: number) => {
  return httpClient(`/partTypes/${partTypeId}/childrens/${childPartTypeId}`, HttpMethod.Delete, null);
};

export const getPartTypesByClientContract = (clientId: number, contractId?: number) => {
  let url = `/partTypes/client/${clientId}`;
  if(contractId) {
    url += `?contractId=${contractId}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const getPartTypeSFHwaresDetails = (partTypeId: number) => {
  return httpClient(`/partTypes/${partTypeId}/sfhwares`, HttpMethod.Get, null);
};

export const getPartTypeManifestById = (id: number) => {
  return httpClient(`/partTypes/manifest/${id}`, HttpMethod.Get, null);
};

// This API can be used to retrieve shipment manifest data for a part type by passing the shipment status codes
export const getPartTypeShipmentDataByPartTypeIdAndShipmentStatus = (partTypeId: number, isSerialized: boolean, shipmentStatusCodes: string) => {
  return httpClient(`/partTypes/${partTypeId}/shipmentData?isSerialized=${isSerialized}&shipmentStatusCodes=${shipmentStatusCodes}`, HttpMethod.Get, null);
};

export const getSearchTLCPartTypes = (data: string) => {
  return httpClient(`/partTypeSearchTLC?searchData=${data}`, HttpMethod.Get, null);
};

export const getSearchTLCPartTypeLocation = (data: RequestDataType) => {
  return httpClient(`/partTypeLocationSearch?isIncludeChild=${data.isIncludeChild}&searchData=${data.searchData}`, HttpMethod.Get, null);
};