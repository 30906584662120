import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { PermissionItem } from '../../molecules/PermissionItem';
import { PermissionListProps } from './PermissionList.props';
import { Container, Root } from './PermissionList.styles';

export const PermissionList: React.FC<PermissionListProps> = ({
  className,
  updatedItems,
  onRemovePermission
}) => {
  const grantedItems = updatedItems.filter(item => item.isGranted);
  const deniedItems = updatedItems.filter(item => !item.isGranted);

  return <Root className={className}>
    <Container>
      <Typography variant={TypographyVariantProps.Subtitle1}>Granted</Typography>
      {
        grantedItems.map(grantedItem =>
          <PermissionItem
            key={grantedItem.permissionId}
            permission={grantedItem}
            onRemovePermission={onRemovePermission}
          />
        )
      }
    </Container>
    <Container>
      <Typography variant={TypographyVariantProps.Subtitle1}>Denied</Typography>
      {
        deniedItems.map(deniedItem =>
          <PermissionItem
            key={deniedItem.permissionId}
            permission={deniedItem}
            onRemovePermission={onRemovePermission}
          />
        )
      }
    </Container>
  </Root>;
};
