import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { 
  getSubLocationsForCreateServices, 
  searchChildPartTypesByClientAndContract, 
  searchClientContractPartTypes, 
  searchManufacturerNodes, 
  searchManufacturerNodesByClientAndContract, 
  searchNode, 
  searchNodeByParentId, 
  searchNodeOperational, 
  replenishmentSearchNodes, 
  searchNodesParts, 
  searchParts, 
  searchService, 
  replenishmentSkuSearch,
  replenishmentPartTypeSearch,
  searchNodeShipment,
  advanceSearchPartTypes,
  advanceBulkSearchPartTypes,
  searchNodeDispatch,
  searchPartsBulkUpdate,
  searchPartsWithPid,
  searchPartsOrPartTypes,
  searchReturnPageDestination,
  searchReturnPageSource,
  searchNodeInStructureMerge,
  searchDispatchParts,
  advanceBulkSearchSerials,
  getSearchShipment,
  searchNodesPartsWithoutPartId,
  advanceSearchPartTypesForBulkPartUpdate,
  searchLinkShipments,
  advancePartSearch,
  searchAllocationNodes,
  searchNodeParts,
  searchAllNodesByName,
  advancePartSearchByName,
  getSubLocationsForUpdateServices,
  searchPartTypesByClientAndContractForSKU,
  searchNodeByNameOrCode,
  searchTlcNodesParts,
  reciveShipmentsSearchNodes,
  searchNodeReceive,
  batchRecieveBulkSearchSerials,
  searchDesiredParentsForSerial,
} from '../api/live-search';
import { 
  LocationNodeData, 
  PartTypeActionLiveSearchItem, 
  StructureMergeLocationNodeData, 
  PartSearchData, 
  ShipmentSearchData, 
  LinkShipmentSearchResult,
  LiveSearchResponse,
  DesiredSerialParentDataResponse,
} from '../@types';
import { ServiceData } from '../@types/service-search.type';
import { PartLiveSearchData, PartTypeLiveSearchData } from '../@types/part-type.type';

export const useSubLocationsForCreateServices = (isNestedCheck?: boolean): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await getSubLocationsForCreateServices(data, isNestedCheck);
  });
};

export const useSearchNode = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNode(data);
  });
};

export const useSearchNodeByParentId = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeByParentId(data);
  });
};

export const useSearchService = (): UseMutationResult<Array<ServiceData>> => {
  return useMutation(async (id: any) => {
    return await searchService(id);
  });
};

export const useSearchManufacturerNodes = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (name: any) => {
    return await searchManufacturerNodes(name);
  });
};

export const useSearchNodeOperational = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeOperational(data);
  });
};

export const useSearchManufacturerNodesByClientAndContract = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchManufacturerNodesByClientAndContract(data);
  });
};

export const useSearchNodesParts = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodesParts(data);
  });
};

export const useSearchChildPartTypesByClientAndContract = (isSerialized?: boolean): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchChildPartTypesByClientAndContract(data, isSerialized);
  });
};

export const useSearchPartTypesByClientAndContractForSKU = (isSerialized?: boolean): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchPartTypesByClientAndContractForSKU(data, isSerialized);
  });
};

export const useSearchClientContractPartTypes = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchClientContractPartTypes(data);
  });
};

export const useSearchParts = (searchLimit?: number): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchParts(data, searchLimit);
  });
};

export const useReplenishmentSearchNodesSearchNodes = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await replenishmentSearchNodes(data);
  });
};

export const useReplenishmentSkuSearch = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await replenishmentSkuSearch(data);
  });
};

export const useReplenishmentPartTypeSearch = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await replenishmentPartTypeSearch(data);
  });
};

export const useSearchNodeShipment = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeShipment(data);
  });
};

export const useAdvanceSearchPartTypes = (): UseMutationResult<Array<PartTypeLiveSearchData>> => {
  return useMutation(async (data: any) => {
    return await advanceSearchPartTypes(data);
  }, );
};

export const useAdvanceSearchPartTypesForBulkPartUpdate = (): UseMutationResult<Array<PartTypeLiveSearchData>> => {
  return useMutation(async (data: any) => {
    return await advanceSearchPartTypesForBulkPartUpdate(data);
  });
};

export const useAdvanceBulkSearchPartTypes = (): UseMutationResult<Array<PartTypeLiveSearchData>> => {
  return useMutation(async (data: any) => {
    return await advanceBulkSearchPartTypes(data);
  });
};

export const useSearchNodeDispatch = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeDispatch(data);
  });
};

export const useSearchPartsBulkUpdate = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchPartsBulkUpdate(data);
  });
};

export const useSearchPartsWithPid = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchPartsWithPid(data);
  });
};

export const useSearchPartsOrPartTypes = (): UseMutationResult<Array<PartTypeActionLiveSearchItem>> => {
  return useMutation(async (data: any) => {
    return await searchPartsOrPartTypes(data);
  });
};

export const useSearchPartsByName = () => {
  return useMutation(async (data: any) => {
    return await advancePartSearchByName(data);
  });
};

export const useSearchReturnPageSource = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchReturnPageSource(data);
  });
};

export const useSearchReturnPageDestination = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchReturnPageDestination(data);
  });
};

export const useSearchNodeInStructureMerge = (): UseMutationResult<Array<StructureMergeLocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeInStructureMerge(data);
  });
};

export const useSearchDispatchParts = (): UseMutationResult<Array<PartSearchData>> => {
  return useMutation(async (data: any) => {
    return await searchDispatchParts(data);
  });
};

export const useAdvanceBulkSearchSerials = (): UseMutationResult<Array<PartSearchData>> => {
  return useMutation(async (data: any) => {
    return await advanceBulkSearchSerials(data);
  });
};

export const useGetSearchShipment = (): UseMutationResult<Array<ShipmentSearchData>> => {
  return useMutation(async (data: any) => {
    return await getSearchShipment(data);
  });
};

export const useSearchNodePartsWithoutPartId = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodesPartsWithoutPartId(data);
  });
};

export const useSearchLinkShipments = (): UseMutationResult<Array<LinkShipmentSearchResult>> => {
  return useMutation(async (data: any) => {
    return await searchLinkShipments(data);
  });
};

export const useAdvancePartSearch = (): UseMutationResult<Array<PartLiveSearchData>> => {
  return useMutation(async (data: any) => {
    return await advancePartSearch(data);
  });
};

export const useSearchAllNodesByName = (): UseMutationResult<Array<LiveSearchResponse>> => {
  return useMutation(async (data: any) => {
    return await searchAllNodesByName(data);
  });
};

export const useSearchAllocationNodes = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchAllocationNodes(data);
  });
};

export const useSearchNodeWithParts = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeParts(data);
  });
};

export const useSubLocationsForUpdateServices = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await getSubLocationsForUpdateServices(data);
  });
};

export const useSearchNodeByNameOrCode = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeByNameOrCode(data);
  });
};

export const useSearchTlcNodesParts = (): UseMutationResult<Array<any>> => { // Data type changes
  return useMutation(async (data: any) => {
    return await searchTlcNodesParts(data);
  });
};

export const useReciveShipmentsSearchNodes = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await reciveShipmentsSearchNodes(data);
  });
};

export const useSearchNodeReceive = (): UseMutationResult<Array<LocationNodeData>> => {
  return useMutation(async (data: any) => {
    return await searchNodeReceive(data);
  });
};

export const useBatchRecieveBulkSearchSerials = (): UseMutationResult<Array<any>> => {
  return useMutation(async (data: any) => {
    return await batchRecieveBulkSearchSerials(data);
  });
};

export const useSearchDesiredParentsForSerial = (): UseMutationResult<Array<DesiredSerialParentDataResponse>> => {
  return useMutation(async (data: any) => {
    return await searchDesiredParentsForSerial(data);
  });
};