import { styled } from '@mui/material/styles';
import { Fab, Stack, Paper } from '@mui/material';
import { Typography } from '../../../components/atoms/Typography';
import { Box } from '../../../components/atoms/Box';

export const ServiceUpdateTypography = styled(Typography)`
  padding-bottom: 20px;
`;

export const AddTagFabButton = styled(Fab)`
  transform: scale(0.4);
`;

export const ServiceSummeryBox = styled(Box)`
  width: 100%;
  padding: 24px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const SummeryHeaderBox = styled(Box)`
  width: 100%;
  padding: 16px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  background-color: #fafafa;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 85%;
  max-width: 400px;
`;

export const Footer = styled(Stack)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ContentBox = styled('div')`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const CarouselWrapper = styled(Paper)`
  padding: 10px;
  border-radius: 7px;
`;

export const StyledCarouselContent = styled(Stack)`
  flex-direction: row;
  margin-left: 20px;
  align-items: center;
`;