import { styled } from '@mui/material';
import { Button } from '../../atoms/Button';

export const StyledButton = styled(Button)`
  display: flex;
  padding: 10px;
`;

export const StyledWrapper = styled('div')`
  max-height: 400px;
  overflow-y: auto;
`;
