export const menuData = [
  { id: 'home', name: 'Home', url: '/' },
  {
    id: 'services', permission: 'service', name: 'Services', url: '',
    subMenuItems: [
      { id: 'services-search', permission: 'service.search', name: 'Search', url: '/services' },
      { id: 'services-create', permission: 'service.create', name: 'Create', url: '/services/create' },
      { id: 'services-import', permission: 'service.import', name: 'Import', url: '/services/import' }
    ]
  },
  {
    id: 'structures', permission: 'structure', name: 'Structure', url: '',
    subMenuItems: [
      { id: 'structures-search', permission: 'structure.search', name: 'Search', url: '/structure/search' },
      { id: 'structures-create', permission: 'structure.create', name: 'Create', url: '/structure/create' },
      { id: 'structures-import', permission: 'structure.import', name: 'Import', url: '/structure/import' }
    ]
  },
  {
    id: 'parts', permission: 'part', name: 'Parts', url: '',
    subMenuItems: [
      { id: 'parts-search', permission: 'part.search', name: 'Search', url: '/parts/search' },
      { id: 'parts-movement', permission: 'part.movement', name: 'Movements', url: '/parts/movements' },
      { id: 'parts-bulk-update', permission: 'part.bulk_update', name: 'Bulk Update', url: '/parts/bulkUpdate' },
      { id: 'parts-tlc', permission: 'part.tlc', name: 'T.L.C', url: '/parts/tlc' },
      { id: 'parts-template-tlc', permission: 'part.template_tlc', name: 'T.L.C Templates', url: '/parts/tlcTemplates' },
    ]
  },
  {
    id: 'shipments', permission: 'shipment', name: 'Shipments', url: '',
    subMenuItems: [
      { id: 'shipment-search', permission: 'shipment.search', name: 'Search', url: '/shipments' },
      { id: 'shipment-receive', permission: 'shipment.receive', name: 'Receive', url: '/shipments/receive' },
      { id: 'shipment-batch-receive', permission: 'shipment.batch_receive', name: 'Batch Receive', url: '/shipments/batchReceive' },
      { id: 'shipment-dispatch', permission: 'shipment.dispatch', name: 'Dispatch', url: '/shipments/dispatch/create' },
      { id: 'shipment-add-stock', permission: 'shipment.add_stock', name: 'Add Stock', url: '/shipments/stock/create' },
      { id: 'shipment-return', permission: 'shipment.return', name: 'Return', url: '/shipments/return/create' }
    ]
  },
  {
    id: 'reports', permission: 'report', name: 'Reports', url: '',
    subMenuItems: [
      { id: 'reports-attendance', permission: 'report.attendance', name: 'Attendance', url: '/reports/attendance' },
      { id: 'reports-replenishment', permission: 'report.replenishment', name: 'Replenishment', url: '/reports/replenishment' },
      { id: 'reports-my-reports', permission: 'report.my_reports', name: 'My Reports', url: '/reports/myreports' }
    ]
  },
  {
    id: 'configure', permission: 'configure', name: 'Configure', url: '',
    subMenuItems: [
      {
        id: 'configure-users', permission: 'configure.users', name: 'Users', url: '',
        subMenuItems: [
          { id: 'configure-users-search', permission: 'configure.users.search', name: 'Search', url: '/configure/users' },
          { id: 'configure-users-create', permission: 'configure.users.create', name: 'Create', url: '/configure/users/create' },
          { id: 'configure-users-roles', permission: 'configure.users.roles', name: 'Group/Roles', url: '/configure/users/roles' }
        ]
      },
      {
        id: 'configure-structure', permission: 'configure.structure', name: 'Structure', url: '',
        subMenuItems: [
          { id: 'configure-structure-type', permission: 'configure.structure.type', name: 'Types', url: '/configure/structure/types' }
        ]
      },
      {
        id: 'configure-replenishment', permission: 'configure.replenishment', name: 'Replenishment', url: '',
        subMenuItems: [
          { id: 'configure-replenishment-config', permission: 'configure.replenishment.replenishment_config', name: 'Config', url: '/configure/replenishment/config' },
          { id: 'configure-replenishment-sku-config', permission: 'configure.replenishment.sku_config', name: 'SKU Config', url: '/configure/sku/rules' }
        ]
      },
      {
        id: 'configure-client-structure', permission: 'configure.client_structure', name: 'Clients', url: '',
        subMenuItems: [
          { id: 'configure-client-structure-clients-contracts', permission: 'configure.client_structure.clients_or_contracts', name: 'Search', url: '/configure/clients' },
        ]
      },
      {
        id: 'configure-service', permission: 'configure.services', name: 'Services', url: '',
        subMenuItems: [
          { id: 'configure-service-status', permission: 'configure.services.status', name: 'Statuses', url: '/configure/services/statuses' },
          { id: 'configure-service-type', permission: 'configure.services.types', name: 'Types', url: '/configure/services/types' }
        ]
      },
      {
        id: 'configure-sla', permission: 'configure.sla', name: 'SLA', url: '',
        subMenuItems: [
          { id: 'configure-sla-rules', permission: 'configure.sla.rules', name: 'Rules', url: '/configure/sla/rules' }
        ]
      },  
      { id: 'configure-partTypes', permission: 'configure.part_types', name: 'Part Types', url: '',
        subMenuItems: [
          { id: 'configure-partTypes-search', permission: 'configure.part_types.search', name: 'Search', url: '/configure/partTypes' },
          { id: 'configure-partTypes-create', permission: 'configure.part_types.create', name: 'Create', url: '/configure/partTypes/create' }
        ]
      },
      {
        id: 'configure-allocation', permission: 'configure.allocation', name: 'Allocation', url: '',
        subMenuItems: [
          { id: 'configure-allocation-rules', permission: 'configure.allocation.rules', name: 'Rules', url: '/configure/allocation/rules' }
        ]
      },
      {
        id: 'configure-interactions', permission: 'configure.interactions', name: 'Interactions', url: '',
        subMenuItems: [
          { id: 'configure-interactions-rules', permission: 'configure.interactions.search_rules', name: 'Rules', url: '/configure/interactions/rules' },
          { id: 'configure-interactions-templates', permission: 'configure.interactions.search_templates', name: 'Templates', url: '/configure/interactions/templates' }    
        ]
      },
      { id: 'configure-notifications', permission: 'configure.notifications', name: 'Notifications', url: '',
        subMenuItems: [
          { id: 'configure-notifications-rules', permission: 'configure.notifications.rules', name: 'Rules', url: '/configure/notifications/rules' },
          { id: 'configure-notifications-templates', permission: 'configure.notifications.templates', name: 'Templates', url: '/configure/notifications/templates' }
        ]
      },
    ]
  }
];