import React from 'react';
import { Stack } from '@mui/material';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { FormControlLabel } from '../../atoms/FormControlLabel';
import { SelectMenu } from '../../atoms/SelectMenu';
import { TimePicker } from '../../atoms/TimePicker';
import { DateTimePicker } from '../../atoms/DateTimePicker';
import { TradingHoursProps, TradingHourTypes } from './TradingHours.props';
import { NoteTextField, TradingHoursDelete, DateTimePickerWrapper, TimePickerWrapper } from './TradingHours.styles';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';

export const TradingHours: React.FC<TradingHoursProps> = ({
  value,
  disableElement,
  onChange,
  onDelete
}) => {
  const getKeyByValue = (value: string): TradingHourTypes => {
    const item = Object.entries(TradingHourTypes).find(obj => obj[1] === value);
    return item ? item[1] : TradingHourTypes.AllDays;
  };

  return (
    <>
      <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
        <SelectMenu
          id="trading-hour-type"
          minWidth={220}
          items={Object.values(TradingHourTypes).map(text => ({
            value: text,
            label: text
          }))}
          selectedValue={value.type}
          onChange={(type: string) => {
            const typeValue = getKeyByValue(type);
            if (value.type === TradingHourTypes.IndividualDate || typeValue === TradingHourTypes.IndividualDate) {
              onChange({
                ...value,
                start: '',
                type: typeValue
              });
            } else {
              onChange({
                ...value,
                type: typeValue,
                ph: typeValue === TradingHourTypes.PublicHoliday
              });
            }
          }}
        />
        {value.type === TradingHourTypes.IndividualDate ?
          <DateTimePickerWrapper>
            <DateTimePicker
              label="Start"
              value={value.start}
              onChange={(val: string) => onChange({
                ...value,
                start: val
              })}
            />
          </DateTimePickerWrapper>
          :
          <TimePickerWrapper>
            <TimePicker
              label="Start"
              value={value.start}
              onChange={(val: string) => onChange({
                ...value,
                start: val
              })}
            />
          </TimePickerWrapper>
        }
        <TimePickerWrapper>
          <TimePicker
            label="End"
            value={value.end}
            onChange={(val: string) => onChange({
              ...value,
              end: val
            })}
          />
        </TimePickerWrapper>
        <FormControlLabel
          control={
            <Checkbox
              color={CheckboxColorProps.Primary}
              disabled={value.type === TradingHourTypes.PublicHoliday}
              checked={value.ph}
              onChange={(event) => onChange({
                ...value,
                ph: event.target.checked
              })} />
          }
          label="P/H"
        />
        <NoteTextField
          label="Note"
          value={value?.note}
          onChange={(text: string) => onChange({
            ...value,
            note: text
          })}
          InputLabelProps={{ shrink: true }}
        />
        {disableElement || (
          <TradingHoursDelete onClick={() => onDelete()} />
        )}
      </Stack>
    </>
  );
};