import { StructureStatesResponse } from '../@types';
import { PartTypeCategory, PartTypeCategoryName, SerializationType } from '../configs/enums';
import { EMPTY_FIELD_MESSAGE, LARGER_THAN_MESSAGE, UNDEFINED_TREE, UNDEFINED_TREE_FIRST_ITEM } from '../constants/common';
import { isDropDownEmpty, isEmptyString } from './common';

export const nameValidation = (value: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else if (value === UNDEFINED_TREE || value === UNDEFINED_TREE_FIRST_ITEM) {
    return text + EMPTY_FIELD_MESSAGE;
  } else {
    return '';
  }
};

export const minMaxValidation = (min: number, max: number, text1: string, text2: string) => {
  if (min > max) {
    return text1 + LARGER_THAN_MESSAGE + text2;
  } else {
    return '';
  }
};

export const hasErrorInReplenishmentConfig = (name: string, clientContract: string, location: string, type: string, partTypeOrSkuName: string, min: number, max: number) => {
  return isEmptyString(name) || isEmptyString(clientContract) || clientContract === UNDEFINED_TREE || clientContract === UNDEFINED_TREE_FIRST_ITEM || isEmptyString(location) || isDropDownEmpty(type) || isEmptyString(partTypeOrSkuName) || min > max;
};

export const serialisationChecker = (serializationType?: string) => {
  if (serializationType === PartTypeCategory.SerialisedDevice || serializationType === PartTypeCategory.SerialisedComponent || serializationType === PartTypeCategoryName.SerialisedDevice || serializationType === PartTypeCategoryName.SerialisedComponent) {
    return SerializationType.Serialised;
  } else {
    return SerializationType.NonSerialised;
  }
};

export const getLocationStates = (locationStates: Array<StructureStatesResponse>) => {
  const locationStateList = locationStates?.map((locationState: StructureStatesResponse) => {
    return { value: locationState.id.toString(), label: `${locationState.countryName}/${locationState.stateName}` };
  });

  return locationStateList;
};