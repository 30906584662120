import React from 'react';
import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { PageContainer } from '../../../../../../components/atoms/PageContainer';
import { Accordian } from '../../../../../../components/atoms/Accordian';
import { Typography } from '../../../../../../components/atoms/Typography';
import { DataGrid } from '../../../../../../components/atoms/DataGrid';
import { Link } from '../../../../../../components/atoms/Link';
import { IncomingShipmentListFragmentProps } from './IncomingShipmentListFragment.props';
import { formatDate, formatDateOnly } from '../../../../../../utils/common';
import { DatagridSortingTypes } from '../../../../../../@types';
import { GridContainer } from './IncomingShipmentListFragment.styles';

export const IncomingShipmentListFragment: React.FC<IncomingShipmentListFragmentProps> = ({
  incomingShipments,
  selectedShipmentId
}) => {
  const columns: GridColDef[] = [
    {
      field: 'shipmentId',
      headerName: 'Shipment ID',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link 
            href={`/shipments/${params.row.shipmentId}`} 
            onClick={()=> {
              selectedShipmentId(params.row.shipmentId);
              window.open(`/shipments/${params.row.shipmentId}`, '_blank');
            }}
          >
            {params.row.shipmentId}
          </Link>
        );
      }
    },    
    {
      field: 'sendDate',
      headerName: 'Send Date',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => formatDateOnly(params.row.sentDate)
    },
    {
      field: 'connote',
      headerName: 'Connote',
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.connote
    },
    {
      field: 'lastTrackingEvent',
      headerName: 'Last Tracking Event',
      flex: 0.35,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.lastTrackingEvent)
    },
    {
      field: 'totalQuantity',
      headerName: 'Total Quantity',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.totalQty
    },
  ];

  return (
    <PageContainer>
      <Accordian title={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>Incoming Shipments</Typography>
          </Grid>
        </Grid>
      } defaultExpanded={true}>
        <GridContainer>
          <DataGrid
            columns={columns}
            rows={incomingShipments}
            getRowId={(row) => row?.shipmentId}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'shipmentId', sort: DatagridSortingTypes.Ascending }],
              },
            }}
            onCellClick={(params: any) => selectedShipmentId(params.id)}
            getRowHeight={() => 'auto'}
          />
        </GridContainer>
      </Accordian>
    </PageContainer>
  );
};