import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps, LocationNodeData } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { RepairPricingProps } from './RepairPricing.props';
import { SwitchGrid } from './RepairPricing.styles';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../LocationSearchItem';

export const RepairPricing: React.FC<RepairPricingProps> = ({
  value,
  multiline = true,
  onChange,
  recallSearchNodeQuery,
  renderNotes
}) => {
  const [textBoxClick, setTextBoxClick] = useState<boolean>(false);
  
  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      {renderNotes && <Grid item xs={2}>
        <TextField
          fullWidth
          label="Description"
          multiline
          rows={1}
          value={value.description}
          onChange={(text) => onChange({
            ...value,
            description: text
          })}
        />
      </Grid>}
      <Grid item xs={2}>
        <LiveSearchBox
          title="Repairer Location *"
          timeOffset={400}
          multiline={multiline}
          value={value.nodeName}
          onClearValue={() => onChange({
            ...value,
            nodeId: NaN,
            nodeName: ''	
          })}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onClick={() => setTextBoxClick(true)}
          onChange={async (obj: LocationNodeData) => {
            onChange({
              ...value,
              nodeId: obj.id,
              nodeName: obj.name
            });     
          }}
          onApiInvoke={async (name: string) => {
            if (textBoxClick && name) {
              return await recallSearchNodeQuery(name);
            } else {
              return [];
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          type={TextFieldTypeProps.Number}
          label="Repair Cost *"
          inputProps={{ min: 0 }}
          value={value.cost && value.cost.toString()}
          onChange={(text) => onChange({ ...value, cost: text })}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          type={TextFieldTypeProps.Number}
          label="Price *"
          inputProps={{ min: 0 }}
          value={value.price && value.price.toString()}
          onChange={(text) => onChange({ ...value, price: text })}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          fullWidth
          type={TextFieldTypeProps.Number}
          label="QTY *"
          inputProps={{ min: 1 }}
          value={value?.quantity?.toString()}
          onChange={(text) => onChange({ ...value, quantity: text })}
        />
      </Grid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isDefault}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isDefault: event.target.checked })} />
          }
          label="Is Default"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isActive: event.target.checked })} />
          }
          label="Is Active"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Grid>
  );
};