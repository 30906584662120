import React from 'react';
import { PartTypeActionSHFDataProps } from './PartTypeActionSHFData.props';
import { SfhTable } from './PartTypeActionSHFData.styles';

export const PartTypeActionSHFData: React.FC<PartTypeActionSHFDataProps> = ({
  sw,
  hw,
  fw
}) => {
  return (
    <SfhTable>
      <tr>
        <th>SW</th>
        <th>HW</th>
        <th>FW</th>
      </tr>
      <tr>
        <td>{sw}</td>
        <td>{hw}</td>
        <td>{fw}</td>
      </tr>
    </SfhTable>
  );
};
