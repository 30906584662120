import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Button, ButtonVariantProps, ButtonColorProps } from '../../../components/atoms/Button';
import {
  RoleTypography,
  RoleBox,
  PageActionTypography,
  ActionGrid,
} from './EditRole.styles';
import { containValidCharacters, nameValidation } from '../../../utils/roles';
import { PermissionTreeV2 } from '../../../components/molecules/PermissionTreeV2';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { useGetOneRole, useGetRolePermissionsForRoleEdit, useUpdateRole } from '../../../queries/roles-query';
import { ColorProps } from '../../../@types/common.type';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags, Tags } from '../../../components/organisms/Tags';
import { ActionPermissions, CustomPermission, Tag } from '../../../@types';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { EntityType } from '../../../configs/enums';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { getFlatArray } from '../../../utils/array-utils';
import { isDropDownEmpty, isEmptyString } from '../../../utils/common';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { SelectMenu } from '../../../components/atoms/SelectMenu';
import { VisibilityRuleDropDownItems } from '../../../@types/visibilityRule.type';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { useGetVisibilityRules } from '../../../queries/visibilityRules-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { PLATFORM_NAME } from '../../../configs/common';

export const EditRole: React.FC = () => {
  const { id }: any = useParams();

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [nameHelperText, setNameHelperText] = useState('');
  const [permissionTree, setPermissionTree] = useState<any[]>([]);
  const [rolePermission, setRolePermission] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [validate, setValidate] = useState(false);
  const [checkAll] = useState<boolean>(false);
  const [visibilityRuleList, setVisibilityRuleList] = useState<VisibilityRuleDropDownItems[]>([]);
  const [visibilityRule, setVisibilityRule] = useState<number>(0);

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [customPermissions, setCustomPermissions] = useState<CustomPermission[]>();

  const isError = isEmptyString(name) || containValidCharacters(name) || isDropDownEmpty(visibilityRule.toString());

  const getRoleQuery = useGetOneRole(id);
  const getRolePermissionQuery = useGetRolePermissionsForRoleEdit(id);
  const updateRoleQuery = useUpdateRole();
  const updateTagsQuery = useAddTags();
  const roleTagsQuery = getAllTags(EntityType.TYPE_ROLE);
  const visibilityRulesQuery = useGetVisibilityRules();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setCustomPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (getRoleQuery.data) {
      setName(getRoleQuery.data.name);
      setNotes(getRoleQuery.data.notes);
      setIsActive(getRoleQuery.data.isActive);
      setVisibilityRule(getRoleQuery.data.visibilityRuleId);
      setSelectedTags(mapSavedTags(getRoleQuery.data.tags));
      document.title = `Role: ${getRoleQuery.data.id} | Roles | ${PLATFORM_NAME}`;
    }
  }, [getRoleQuery.data]);

  useEffect(() => {
    if (visibilityRulesQuery.isSuccess) {
      const visibilityRulesItems: VisibilityRuleDropDownItems[] = [];
      visibilityRulesQuery?.data?.map((item) => {
        const visibilityRule = {
          value: item.id,
          label: item.name
        };
        visibilityRulesItems.push(visibilityRule);
      });
      setVisibilityRuleList(visibilityRulesItems);
    }
  }, [visibilityRulesQuery.isSuccess, visibilityRulesQuery.isLoading]);

  useEffect(() => {
    getRolePermissionQuery.data && setPermissionTree(getRolePermissionQuery.data.children);
  }, [getRolePermissionQuery.data]);

  useEffect(() => {
    roleTagsQuery.data && setAllTags(roleTagsQuery.data);
  }, [roleTagsQuery.data]);

  const handleNameChange = (text: string) => {
    setNameHelperText(nameValidation(text, NAME_FIELD_HELPER_TEXT));
    setName(text);
  };

  const handleNoteChange = (text: string) => {
    setNotes(text);
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const onChangeVisibilityRule = (data: number) => {
    setVisibilityRule(data);
  };

  const handlePermissionChange = (checked: any[]) => {
    setRolePermission(checked);
  };

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  // Update role details
  const handleRoleUpdate = async () => {
    setNameHelperText(nameValidation(name, NAME_FIELD_HELPER_TEXT));

    if (isError) {
      setValidate(true);
      return;
    }

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    const rolePermissions = getFlatArray(rolePermission)
      .filter(p => p.children.length === 0 && p.isGranted)
      .map(({ id, isGranted }) => ({ permissionId: id, isGranted }));

    await updateRoleQuery.mutateAsync({
      id,
      name: name,
      notes: notes,
      isActive: isActive,
      visibilityRuleId: visibilityRule,
      rolePermissions: rolePermissions
    });

    await updateTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_ROLE,
      entityId: id,
      freshTags,
      existingTags
    });

    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  if (!getRoleQuery.data) {
    return getRoleQuery.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <Grid>
        <RoleTypography variant={TypographyVariantProps.H5} fontWeight={600}>
          Update Role
        </RoleTypography>
        <RoleBox>
          <Grid container spacing={GRID_SPACING}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                error={!!nameHelperText}
                helperText={nameHelperText}
                label="Name"
                value={name}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Tags
                selectedTags={selectedTags}
                newTags={newTags}
                setSeletedTags={onChangeSelectedTags}
                setNewTags={onChangeNewTags}
                allTags={allTags}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={4}
                value={notes}
                onChange={handleNoteChange}
              />
            </Grid>
            {isUserHasPermission(ActionPermissions.Configure_User_Roles_Edit_Vibility_Rules, customPermissions) && (
              <Grid item xs={12}>
                <SelectMenu
                  required
                  id="visibility-rule"
                  labelId="visibility-rule"
                  label="Visibility Rule"
                  selectedValue={visibilityRule}
                  onChange={(data) => onChangeVisibilityRule(data)}
                  items={visibilityRuleList}
                  validate={validate}
                  optionalLabelEnabled={true}
                />
              </Grid>
            )}
            <Grid item ml="8px" xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    color={ColorProps.Success}
                    onChange={handleIsActiveChange}
                    checked={isActive !== true ? false : true}
                  />
                }
                label="Active"
              />
            </Grid>
            {isUserHasPermission(ActionPermissions.Configure_User_Roles_Edit_Role_Permission, customPermissions) && (
              <Grid container item xs={12}>
                <ActionGrid>
                  <PageActionTypography>
                    Page Action Permission{' '}
                  </PageActionTypography>
                  <PermissionTreeV2
                    checkAll={checkAll}
                    handlePermissionChange={handlePermissionChange}
                    permissions={permissionTree}
                  />
                </ActionGrid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Grid item>
                <Button variant={ButtonVariantProps.Primary} onClick={handleRoleUpdate}>
                  Update Role
                </Button>
              </Grid>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Role Updated!"
                action={action}
              />
            </Grid>
          </Grid>
        </RoleBox>
      </Grid>
    </PageContainer>
  );
};
