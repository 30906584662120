import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';

export const MicroText = styled(Typography)`
  font-size: 10px;
  font-weight: bold;
  color: ${COLORS.DarkGray};
`;

export const StyledStack = styled(Stack)`
  width: 100%;
`;