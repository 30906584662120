import { Stack, StackProps, styled } from '@mui/material';
import { MobileDataGridItemEntryData } from '../../molecules/MobileDataGridItemEntry';

export const StyledStack = styled(Stack)((props: { enableSingleColumn?: boolean }) =>`
    display: ${!props.enableSingleColumn && 'grid'};
    grid-template-columns: repeat(2, auto);
    width: 90vw;
    grid-gap: 4px;
    border-radius: 10px;
    border-color: rgb(224, 224, 224);
    border-style: solid;
    padding: 0px 5px 0px 5px;
  };`
);

const getColumnTextAlignment = (key: number, gridDataItem: MobileDataGridItemEntryData, previousGridDataItem: MobileDataGridItemEntryData) => {
  const { value: previousValue, textValue: previousTextValue } = previousGridDataItem;
  const { value, textValue } = gridDataItem;

  if (typeof value === 'string' && value?.length > 50 || textValue && textValue.length > 50) {
    return 'text-align: left; margin-top: -10px; margin-bottom: -10px;';
  }

  if (typeof previousValue === 'string' && previousValue?.length > 50 || previousTextValue && previousTextValue.length > 50) {
    return 'text-align: left;';
  }
  else {
    if (key % 2) {
      return 'text-align: right;';
    }
  }

  return 'text-align: left;';
};

export const StyledMobileDataGridItemEntryWrapper = styled(Stack)((props: StackProps & { gridData: MobileDataGridItemEntryData; previousGridDataItem: MobileDataGridItemEntryData; index: number; enableSingleColumn?: boolean }) => {
  if (props.enableSingleColumn) return;

  const { value, textValue } = props.gridData;

  if (textValue) {
    return `grid-column: ${textValue.length > 50 ? 'span 2;' : 'span 1;'}
            display: flex;
            ${getColumnTextAlignment(props.index, props.gridData, props.previousGridDataItem)}`;
  }
  else if (typeof value === 'string' && value.length) {
    return `grid-column: ${value.length > 50 ? 'span 2;' : 'span 1;'}
            display: flex;
            ${getColumnTextAlignment(props.index, props.gridData, props.previousGridDataItem)}`;
  }

  return `
    grid-column: span 1; 
    display: flex;
    ${getColumnTextAlignment(props.index, props.gridData, props.previousGridDataItem)}`;
});