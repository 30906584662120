import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { MultiSelectDropdownProps, ServiceStatusTransitionsFragmentProps } from './ServiceStatusTransitionsFragment.props';
import { MultiSelectActionTypes, MultiSelectPriorities, ProgressionBlocks, SwitchGrid } from './ServiceStatusTransitionsFragment.styles';
import { ColorProps, ServiceStatusTransitionsType } from '../../../../@types';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { GRID_SPACING } from '../../../../configs/ui-constants';
import { ToogleButtonTextAlignment } from '../../../../components/atoms/ToogleButtonGroup';
import { configureSelectedDropdownOptions, getDropdownOptionsWithHandlingAnyKey } from '../../../../utils/structure';
import { Switch } from '../../../../components/atoms/Switch';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../../components/atoms/FormControlLabel';
import { useGetAllServiceTypes } from '../../../../queries/service-type-query';
import { useGetActionTypes } from '../../../../queries/service-query';
import { IconButtonColor, IconFontSize } from '../../../../configs/enums';
import { EXPLICIT_SERVICE_STATUS_TRANSITIONS } from '../../../../constants/structure';
import { ConfirmationPopup } from '../../../../components/molecules/ConfirmationPopup';
import { NEW_STATUS_DROPDOWN_ELEMENT } from '../../../../constants/serviceStatus.constant';

export const ServiceStatusTransitionsFragment: React.FC<ServiceStatusTransitionsFragmentProps> = ({
  serviceStatusTransitionsRules,
  prioritiesItemsList,
  onChange,
  error
}) => {
  const [expanded, setExpanded] = useState(true);
  const [serviceTypes, setServiceTypes] = useState<DropdownItem[]>([]);
  const [actionTypes, setActionTypes] = useState<DropdownItem[]>([]);
  const [modalStatus, setModalStatus] = useState<{open: boolean; data: ServiceStatusTransitionsType}>({ open: false, data: EXPLICIT_SERVICE_STATUS_TRANSITIONS[0] });

  const getAllServiceTypes = useGetAllServiceTypes();
  const getActionTypes = useGetActionTypes();

  useEffect(() => {
    if (getAllServiceTypes.data) {
      const serviceTypesItems: DropdownItem[] = [];
      getAllServiceTypes?.data?.filter((serviceType) => serviceType.isActive).map((serviceTypeItem) => {
        const serviceType = {
          value: serviceTypeItem.code,
          label: serviceTypeItem.name
        };
        serviceTypesItems.push(serviceType);
      });
      setServiceTypes(serviceTypesItems);
    }
  }, [getAllServiceTypes.data]);

  useEffect(() => {
    if (getActionTypes.data) {
      const actionTypesItems: DropdownItem[] = [];
      const wildCard = {
        value: 'any',
        label: 'Any'
      };
      actionTypesItems.push(wildCard);
      getActionTypes?.data?.map((actionTypeItem) => {
        const actionType = {
          value: actionTypeItem.code,
          label: actionTypeItem.name
        };
        actionTypesItems.push(actionType);
      });
      setActionTypes(actionTypesItems);
    }
  }, [getActionTypes.data]);

  const getMatchingPrioritiesForServiceType = (prioritiesList: MultiSelectDropdownProps[], serviceStatusTransitionsData: ServiceStatusTransitionsType) => {
    return prioritiesList.filter((priority) => priority.value === 'any' || priority.serviceTypes?.some((serviceType) => serviceType === serviceStatusTransitionsData.selectedServiceType));
  };

  const updateData = (ruleId: number, fieldData: ServiceStatusTransitionsType, fieldName: string, selectedValue: any, updatedBlockedStatuses?: string[]) => {
    const dataArray = [...serviceStatusTransitionsRules];
    const updatedArray = dataArray.map((serviceTransitionItem) => 
      serviceTransitionItem.ruleId === ruleId ? {
        ...fieldData,
        [fieldName]: selectedValue,
        selectedServiceStatus: fieldName === 'selectedServiceType' ? [] : fieldName === 'selectedServiceStatus' ? selectedValue : fieldData.selectedServiceStatus,
        selectedPriorities: fieldName === 'selectedServiceType' ? [] : fieldName === 'selectedPriorities' ? selectedValue : fieldData.selectedPriorities,
        blockedStatuses: updatedBlockedStatuses || (fieldName === 'selectedServiceType' ? [] : fieldName === 'blockedStatuses' ? selectedValue : fieldData.blockedStatuses)
      } : serviceTransitionItem
    );
    onChange(updatedArray);
  };

  const handleAdd = (event: any) => {
    event.stopPropagation();
    const updatedDataArray = [...serviceStatusTransitionsRules];
    updatedDataArray.push({
      ...EXPLICIT_SERVICE_STATUS_TRANSITIONS[0],
      ruleId: serviceStatusTransitionsRules.length > 0 ? Math.max(...serviceStatusTransitionsRules.map((data) => data.ruleId)) + 1 : 0
    });
    onChange(updatedDataArray);
  };

  const handleDelete = () => {
    const updatedDataArray = [...serviceStatusTransitionsRules].filter((statusTransitionData) => statusTransitionData.ruleId !== modalStatus.data.ruleId);
    onChange(serviceStatusTransitionsRules.length > 0 ? updatedDataArray : []);
    setModalStatus({ open: false, data:  EXPLICIT_SERVICE_STATUS_TRANSITIONS[0] });
  };

  const getStatusesList = (selectedServiceTypeCode: string) => {
    if (getAllServiceTypes.data && getAllServiceTypes.data.length > 0) {
      const statusesList = getAllServiceTypes.data.find((serviceType) => serviceType.code === selectedServiceTypeCode)?.statusOptions || [];
      const mappedStatuses = statusesList?.map((status) => {
        return {
          value: status.serviceStatus.code,
          label: status.serviceStatus.name
        };
      });
      mappedStatuses.push(NEW_STATUS_DROPDOWN_ELEMENT); // Pushing New status to status list
      return mappedStatuses;
    } else return [];
  };

  return (
    <>
      <Accordian
        title={
          <Grid container justifyContent="space-between">
            <Grid item mr={2}>
              <Typography>Explicit Service Status Transitions</Typography>
            </Grid>
            <Grid item mr={2}>
              <Button variant={ButtonVariantProps.Secondary} startIcon={<Add />} onClick={handleAdd}>
              Add
              </Button>
            </Grid>
          </Grid>
        }
        onChange={(_, newExpanded: boolean) => setExpanded(newExpanded)}
        expanded={expanded}
      >
        {serviceStatusTransitionsRules?.map((transitionRule) =>
          <Stack key={transitionRule.ruleId} direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
            <SelectMenu
              id="service-type"
              label="Service Type"
              minWidth={200}
              items={serviceTypes}
              selectedValue={transitionRule.selectedServiceType}
              onChange={(serviceType: string) => updateData(transitionRule.ruleId, transitionRule, 'selectedServiceType', serviceType)}
              validate={error}
              helperTextEnabled={false}
            />
            <SelectMenu
              id="status"
              label="Status"
              disabled={!transitionRule.selectedServiceType}
              minWidth={200}
              items={getStatusesList(transitionRule.selectedServiceType)}
              selectedValue={transitionRule.selectedServiceStatus}
              onChange={(serviceStatus: string) => {
                updateData(transitionRule.ruleId, transitionRule, 'selectedServiceStatus', serviceStatus, transitionRule.blockedStatuses?.filter((status: string) => status !== serviceStatus));
              }}
              validate={error}
              helperTextEnabled={false}
            />
            <MultiSelectPriorities
              id="priorities"
              label="Priorities"
              disabled={!transitionRule.selectedServiceType}
              selectedValue={transitionRule.selectedPriorities}
              onChange={(priorities) => updateData(transitionRule.ruleId, transitionRule, 'selectedPriorities', priorities ? configureSelectedDropdownOptions(priorities) : [])}
              items={getDropdownOptionsWithHandlingAnyKey(transitionRule.selectedPriorities, getMatchingPrioritiesForServiceType(prioritiesItemsList, transitionRule))}
              error={error && !transitionRule.selectedPriorities.length}
            />
            <MultiSelectActionTypes
              id="action-types"
              label="Action Types"
              selectedValue={transitionRule.selectedActionTypes}
              onChange={(actionTypes) => updateData(transitionRule.ruleId, transitionRule, 'selectedActionTypes', actionTypes ? configureSelectedDropdownOptions(actionTypes) : [])}
              items={getDropdownOptionsWithHandlingAnyKey(transitionRule.selectedActionTypes, actionTypes)}
              error={error && !transitionRule.selectedActionTypes.length}
            />
            <ProgressionBlocks
              id="progression-blocks"
              label="Progression Blocks"
              disabled={!transitionRule.selectedServiceType}
              selectedValue={transitionRule.blockedStatuses}
              onChange={(progressionBlocks) => updateData(transitionRule.ruleId, transitionRule, 'blockedStatuses', progressionBlocks ? progressionBlocks.split(',') : [])}
              items={getStatusesList(transitionRule.selectedServiceType).filter((status) => status.value !== transitionRule?.selectedServiceStatus)}
              error={error && !transitionRule.blockedStatuses.length}
            />
            <SwitchGrid>
              <FormControlLabel
                control={
                  <Switch
                    color={ColorProps.Success}
                    onChange={(event) => updateData(transitionRule.ruleId, transitionRule, 'isActive', event.target.checked)}
                    checked={transitionRule.isActive} />
                }
                label="Active"
                labelPlacement={FormControlLabelPlacementProps.Top}
              />
            </SwitchGrid>
            <IconButton color={IconButtonColor.Error} onClick={() => setModalStatus({ open: true, data: transitionRule })}>
              <Delete fontSize={IconFontSize.Large} />
            </IconButton>
          </Stack>
        )}
      </Accordian>
      <ConfirmationPopup
        open={modalStatus.open}
        mainHeader="Warning"
        description="Are you sure you want to remove this transition rule?"
        leftButtonName="Cancel"
        rightButtonName="Remove"
        leftButtonOnClick={() => setModalStatus({ open: false, data: EXPLICIT_SERVICE_STATUS_TRANSITIONS[0] })}
        rightButtonOnClick={handleDelete}
        leftButtonVariant={ButtonVariantProps.Secondary}
      />
    </>
  );
};