import React, { useEffect } from 'react';
import { useState } from 'react';
import { MerchantDamageTemplateFormFragmentProps } from './MerchantDamageTemplateFormFragment.props';
import { MerchantDamageTemplateForm } from '../../../../../../components/organisms/MerchantDamageTemplateForm';
import { SerialNumberMenuItem } from '../../../../../../components/molecules/SerialNumberMenu';
import { MerchantDamagePartSerialMenuData } from '../../../../../../@types/part.type';
import { useGetMerchantDamagePartSerialDataQuery } from '../../../../../../queries/part-query';

export const MerchantDamageTemplateFormFragment: React.FC<MerchantDamageTemplateFormFragmentProps> = ({
  service,
  onChange,
  isError,
  value
}) => {
  const [serial, setSerial] = useState<MerchantDamagePartSerialMenuData>();
  const [photos, setPhotos] = useState<FormData>();
  const [options, setOptions] = useState<Array<SerialNumberMenuItem>>([]);

  const getMerchantDamagePartSerialDataQuery = useGetMerchantDamagePartSerialDataQuery({ serviceId: service.id, mainLocationNodeId: service.mainLocationNodeId || 0 });

  useEffect(() => {
    getMerchantDamagePartSerialDataQuery.data && setOptions(getMerchantDamagePartSerialDataQuery.data);
  },[getMerchantDamagePartSerialDataQuery.data]);
  
  const handleSerialNumberMenu = (selectedSerial: string) => {
    const selectedOption = options.find(option => option.serial === selectedSerial);
    selectedOption && setSerial({ serial: selectedOption.serial, partId: selectedOption.partId, partTypeName: selectedOption.partTypeName });
  };

  const handleUploadedPhotos = async (fileList: FileList) => {
    const formData = new FormData();
    const filesArray = Array.from(fileList);
    if (filesArray.length > 0) {
      const files = [...filesArray];
      files.forEach((item) => {
        formData.append('fileList', item);
      });
    }
    filesArray.length && setPhotos(formData);
  };

  useEffect(() => {
    serial && photos && onChange({ serialData: serial, photoKeys: photos });
  }, [serial, photos]);

  return (
    <MerchantDamageTemplateForm
      options={options}
      handleSerialNumberMenu={handleSerialNumberMenu}
      handleUploadedPhotos={handleUploadedPhotos}
      isError={isError}
      value={value}
    />
  );
};