import { HttpMethod, httpClient } from './http-client';
import { SKUDataType } from '../@types/sku.type';
import { QueryOptionsType } from '../@types/queryOptions.type';

export const getAllSKUConfigs = (queryOptions: QueryOptionsType) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/skuconfig?page=${page + 1}&limit=${pageSize}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getSKUConfigById = (id: number) => {
  return httpClient(`/skuconfig/${id}`, HttpMethod.Get, null);
};

export const createSKUConfig = (skuConfig: SKUDataType) => {
  return httpClient('/skuconfig', HttpMethod.Post, skuConfig);
};

export const updateSKUConfig = (id: number, skuConfig: SKUDataType) => {
  return httpClient(`/skuconfig/${id}`, HttpMethod.Put, skuConfig);
};

export const getSKUConfigByName = (name: string, id: number) => {
  return httpClient(`/checkIsSKUNameExists?name=${name}&id=${id}`, HttpMethod.Get, null);
};

//Check whether the IN and OUT part types of the REPLACE part action belong to a common sku
export const checkMatchingSKUConfigs = (inPartId: number, outPartId: number) => {
  return httpClient(`/skuconfig/inPartId/${inPartId}/outPartId/${outPartId}`, HttpMethod.Get, null);
};