import { ReactNode } from 'react';

export enum ButtonColorProps {
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export enum ButtonVariantProps {
  Primary = 'primary',
  Secondary = 'secondary',
  Icon = 'icon',
  Text = 'text',
  Fab = 'fab',
}

export enum ButtonSizeProps {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export interface ButtonProps {
  children: ReactNode;
  className?: string;
  variant?: ButtonVariantProps;
  startIcon?: ReactNode;
  color?: ButtonColorProps;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: ButtonSizeProps;
  onClick?: (event: any) => void;
}