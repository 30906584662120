export const PartsKey = 'parts';
export const PartKey = 'part-edit';
export const PartConditionKey = 'part-conditions';
export const WarrantySummaryKey = 'warranty-summary';
export const PartLocationKey = 'part-location-edit';
export const PartHistoryKey = 'part-history';
export const PartExistanceKey = 'part-existance';
export const NSPartExistanceKey = 'ns-part-existance';
export const PartLocationsListKey = 'part-locations-list';
export const NSPartLocationListKey = 'ns-part-location-list';
export const MerchantDamagePartSerialDataKey = 'merchant-damage-part-serial-data';
export const PartHistoryListForServiceKey = 'part-history-list-for-service';
export const DuplicatePartsKey = 'duplicate-parts';
export const PartRepairHistory = 'part-repair-history';
export const LatestPartMomentHistory = 'latest_part_moment_history';