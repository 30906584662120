import React, { useEffect, useState } from 'react';
import { Box, Stack, Table, TableHead, TableRow } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { DispatchManifestRowItemProps, DispatchManifestRowItemValueType, DynamicTableCellProps } from './DispatchManifestRowItem.props';
import { SerialisedType } from '../../../configs/enums';
import { CompactCheckBoxTableCell, CompactTableCell, SerializedStyledTableCell, StyledButton, StyledDiv, StyledTableCell, UnknownItemCell } from './DispatchManifestRowItem.styles';
import { ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { ScreenSize } from '../../../@types/display.types';
import { useMediaQuery } from '@material-ui/core';

export const DispatchManifestRowItem: React.FC<DispatchManifestRowItemProps> = ({
  value,
  onChange,
}) => {
  const [selectedQty, setSelectedQty] = useState(0);
  const [total, setTotal] = useState(0);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);
  useEffect(() => {
    const items = value.items;
    setSelectedQty(items.filter((item) => item.selected).length);
    setTotal(items.length);
  }, [value]);

  const onClickAll = () => {
    const items = value.items.map((item) => ({ ...item, selected: true }));
    onChange({ ...value, items: items });
  };

  const TableHeadView = () => {
    return (
      <>
        <TableHead>
          <SerializedStyledTableCell>
            {!isMobileView ? <StyledButton size={ButtonSizeProps.Small} variant={ButtonVariantProps.Primary} onClick={onClickAll}>
              All
            </StyledButton> : null}
          </SerializedStyledTableCell>
          <DynamicTableCell>Serial</DynamicTableCell>
          {!isMobileView ? <DynamicTableCell>Condition</DynamicTableCell> : null}
          {!isMobileView ? <DynamicTableCell>Location</DynamicTableCell> : null}
          {!isMobileView ? <DynamicTableCell>SW</DynamicTableCell> : null}
          {!isMobileView ? <DynamicTableCell>HW</DynamicTableCell> : null}
          {!isMobileView ? <DynamicTableCell>FW</DynamicTableCell> : null}
          <DynamicTableCell>{isMobileView ? 'F/T' : 'Fleet Tag'}</DynamicTableCell>
        </TableHead>
      </>
    );
  };

  const DynamicTableCell: React.FC<DynamicTableCellProps> = ({ children }) => {
    return isMobileView ?
      (
        <CompactTableCell>{children}</CompactTableCell>
      ) :
      (
        <SerializedStyledTableCell>
          {children}
        </SerializedStyledTableCell>
      );
  };

  const PartTypeData: React.FC<{ value: DispatchManifestRowItemValueType }> = ({ value }) => {
    return (
      <StyledTableCell padding="none" width="auto">
        {
          isMobileView ?
            <Stack direction={'column'}>
              <Typography variant={TypographyVariantProps.Subtitle2}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.partTypeName}</Typography></Typography>
              <Stack sx={{ flexDirection: 'row' }}>
                <Box sx={{
                  width: '100%',
                }}><Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.partTypeNo}</Typography></Typography></Box>
                {
                  value.serialisation === SerialisedType.Serialised && <Box sx={{
                    width: '100%',
                  }}>
                    <Typography variant={TypographyVariantProps.Caption}>QTY: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{selectedQty} of {total}</Typography></Typography>
                  </Box>
                }

              </Stack>
            </Stack> : <Stack direction={'column'}>
              <Typography variant={TypographyVariantProps.Caption}>Part Type Id: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.partTypeId}</Typography></Typography>
              <Typography variant={TypographyVariantProps.Caption}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.partTypeName}</Typography></Typography>
              <Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.partTypeNo}</Typography></Typography>
            </Stack>
        }
      </StyledTableCell>
    );
  };

  const PartsData: React.FC<{ value: DispatchManifestRowItemValueType }> = ({ value }) => {
    return (
      <StyledTableCell width="auto" isMobileView={isMobileView}>
        <Table size="small" padding="none">
          <TableHeadView />
          {value.items.map((item, index) => <TableRow key={index}>
            {
              isMobileView ?
                <>
                  <CompactCheckBoxTableCell>
                    <Checkbox
                      color={CheckboxColorProps.Primary}
                      checked={item.selected}
                      onChange={(event) => {
                        const items = value.items;
                        items[index].selected = event.target.checked;
                        onChange({ ...value, items: items });
                      }}
                    />
                  </CompactCheckBoxTableCell>
                </>
                :
                <>
                  <SerializedStyledTableCell>
                    <Checkbox
                      color={CheckboxColorProps.Primary}
                      checked={item.selected}
                      onChange={(event) => {
                        const items = value.items;
                        items[index].selected = event.target.checked;
                        onChange({ ...value, items: items });
                      }}
                    />
                  </SerializedStyledTableCell>
                </>
            }
            <DynamicTableCell>{item.serial}</DynamicTableCell>
            {!isMobileView && <DynamicTableCell>{item.condition}</DynamicTableCell>}
            {!isMobileView && <DynamicTableCell>{item.location}</DynamicTableCell>}
            {!isMobileView && <DynamicTableCell>{item.softwareVersion}</DynamicTableCell>}
            {!isMobileView && <DynamicTableCell>{item.hardwareVersion}</DynamicTableCell>}
            {!isMobileView && <DynamicTableCell>{item.firmwareVersion}</DynamicTableCell>}
            <DynamicTableCell>{item.fleetTag}</DynamicTableCell>
          </TableRow>)}
        </Table>
      </StyledTableCell>
    );
  };

  const CheckBoxCell: React.FC<{ value: DispatchManifestRowItemValueType }> = ({ value }) => {
    return (
      isMobileView
        ? <CompactCheckBoxTableCell width="auto">
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={value.items[0]?.selected}
            onChange={(event) => {
              const items = [...value.items]; // Use a copy of the items array for immutability
              items[0].selected = event.target.checked;
              onChange({ ...value, items });
            }}
          />
        </CompactCheckBoxTableCell>
        : <StyledTableCell width="auto">
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={value.items[0]?.selected}
            onChange={(event) => {
              const items = [...value.items]; // Use a copy of the items array for immutability
              items[0].selected = event.target.checked;
              onChange({ ...value, items });
            }}
          />
        </StyledTableCell>
    );
  };


  const NsPartData: React.FC<{ value: DispatchManifestRowItemValueType }> = ({ value }) => {
    return (
      <>
        {!isMobileView &&
          <>
            <StyledTableCell width="auto">
              <Stack direction="column">
                <Typography variant={TypographyVariantProps.Caption}>Condition: <b>{value.items[0]?.condition}</b></Typography>
                <Typography variant={TypographyVariantProps.Caption}>Fleet Tag: <b>{value.items[0]?.fleetTag}</b></Typography>
              </Stack>
            </StyledTableCell>
            <StyledTableCell width="auto">
              <Typography variant={TypographyVariantProps.Caption}>Location: <b>{value.items[0]?.location}</b></Typography>
            </StyledTableCell>   
          </>
        }
      </>
    );
  };

  return (
    <TableRow>
      {value.serialisation === SerialisedType.Unknown ?
        <UnknownItemCell color="error" colSpan={5} width="auto">
          <Typography variant={TypographyVariantProps.Caption}>Serial: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{value.items[0]?.serial}</Typography></Typography>
        </UnknownItemCell> : <>
          {
            isMobileView && <Stack >
              <PartTypeData value={value} />
              <StyledDiv>
                {value.serialisation === SerialisedType.Serialised && value.items.length > 0 && <PartsData value={value} />}
              </StyledDiv>
              <TableRow>
                {value.serialisation === SerialisedType.Consumable && <CheckBoxCell value={value} />}
                {value.serialisation === SerialisedType.Consumable &&
                <StyledTableCell width="100%">
                  <Stack direction="column">
                    <StyledTableCell width="auto">
                      <Typography variant={TypographyVariantProps.Caption}>Fleet Tag: <b>{value.items[0]?.fleetTag}</b></Typography>
                    </StyledTableCell>
                    <StyledTableCell width="100%">
                      <TextField
                        fullWidth
                        type={TextFieldTypeProps.Number}
                        label="QTY"
                        inputProps={{ min: 0, max: value.max }}
                        value={value?.quantity?.toString()}
                        onChange={(text) => {
                          let input = parseInt(text);
                          if (value.max && input > value.max)
                            input = value.max;
                          onChange({ ...value, quantity: input });
                        }}
                      />
                    </StyledTableCell>
                  </Stack>
                </StyledTableCell>
                }
              </TableRow>
            </Stack>
          }
          {
            !isMobileView && <>
              {value.serialisation === SerialisedType.Consumable && <CheckBoxCell value={value} />}
              <PartTypeData value={value} />
              <StyledDiv>
                {value.serialisation === SerialisedType.Serialised && value.items.length > 0 && <PartsData value={value} />}
              </StyledDiv>
              {value.serialisation === SerialisedType.Consumable && <NsPartData value={value} />}
              {value.serialisation === SerialisedType.Consumable &&
              <StyledTableCell align="center">
                <TextField
                  fullWidth
                  type={TextFieldTypeProps.Number}
                  label="QTY"
                  inputProps={{ min: 0, max: value.max }}
                  value={value?.quantity?.toString()}
                  onChange={(text) => {
                    let input = parseInt(text);
                    if (value.max && input > value.max)
                      input = value.max;
                    onChange({ ...value, quantity: input });
                  }}
                />
              </StyledTableCell>
              }
              {value.serialisation === SerialisedType.Serialised && <StyledTableCell width="auto" align="center">
                Qty: <b>{selectedQty} of {total}</b>
              </StyledTableCell>}
            </>
          }

        </>}
    </TableRow>
  );
};