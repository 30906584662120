import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../../components/atoms/LiveSearchBox';
import { TextField } from '../../../../../../components/atoms/TextField';
import { LocationSearchItem } from '../../../../../../components/molecules/LocationSearchItem';
import { DateTimePicker } from '../../../../../../components/atoms/DateTimePicker';
import { RichTextEditor } from '../../../../../../components/atoms/RichTextEditor';
import { PrioritySelectMenu } from '../../../../../../components/molecules/PrioritySelectMenu';
import { LocationSearchSelect } from '../../../../../../components/organisms/LocationSearchSelect';
import { AutocompleteTagType, Tags, mapSavedTags } from '../../../../../../components/organisms/Tags';
import { getAllTags } from '../../../../../../queries/tags-query';
import { useGetServiceActionProcessTypes } from '../../../../../../queries/service-query';
import { 
  useGetFilteredNodeAttributesByCustomRange,
  useGetSuggestedNodesFromAllocationEngine 
} from '../../../../../../queries/structure-query';
import { 
  useSearchNodeByParentId, 
  useSearchNodeOperational, 
  useSearchParts, 
  useSubLocationsForCreateServices 
} from '../../../../../../queries/live-search-query';
import { useGetUserSystemPermissions } from '../../../../../../queries/user-query';
import {
  SAMPLE_PRIORITY_TYPE,
  SAMPLE_SERVICE_COMMON_TYPE 
} from '../../../../../../constants/service.constant';
import { 
  ActionPermissions,
  LocationNodeData,
  PriorityType, 
  ServiceCommonType,
  Tag 
} from '../../../../../../@types';
import { EntityType, NodeAttributeType, ServiceAttributeType, ServiceCreationRuleTypes } from '../../../../../../configs/enums';
import { actionTypes, getHasCodeForNodeSearch, isRuleExist, priorityTypes, processTypes, reportedFaultTypes, reportedModelTypes, serviceTypes } from '../../../../../../utils/services';
import { isUserHasPermission } from '../../../../../../configs/permissions';
import { EMPTY_FIELD_MESSAGE, RICH_TEXT_EDITOR_EMPTY_TEXT } from '../../../../../../constants/common';
import { ServiceCreationFormFragmentProps } from './ServiceCreationFormFragment.props';
import { ServiceBox, StyledBox, StyledSelectMenu } from './ServiceCreationFormFragment.styles';
import { emptyFieldValidation, isEmptyString } from '../../../../../../utils/common';
import { ContactData } from '../../../../../../components/molecules/Contact';
import { ServiceContactLayout } from '../../../../../../components/templates/ServiceContactLayout';
import { ContactDataType } from '../../../../../../components/templates/StructureAddressContactLayout';

export const ServiceCreationFormFragment: React.FC<ServiceCreationFormFragmentProps> = ({
  preSelectedData,
  isError = true,
  service,
  value,
  onChange
}) => {
  const activeOnly = true;

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [serviceCommonTypes, setServiceCommonTypes] = useState<ServiceCommonType>(SAMPLE_SERVICE_COMMON_TYPE);
  const [nodeAttributes, setNodeAttributes] = useState<[PriorityType]>([SAMPLE_PRIORITY_TYPE]);

  const [locationText, setLocationText] = useState<string>();
  const [locationNodeOptions, setLocationNodeOptions] = useState<Array<LocationNodeData>>();
  const [mainLocationNode, setMainLocationNode] = useState<LocationNodeData>();
  const [subLocationNode, setSubLocationNode] = useState<LocationNodeData>();
  const [suggestedNodesFromAllocation, setSuggestedNodesFromAllocation] = useState<LocationNodeData[]>([]);
  const [userAllocationPermission, setUserAllocationPermission] = useState(false);
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [notesHelperText, setNotesHelperText] = useState('');
  const [focusField, setFocusField] = useState('');

  const serviceTagsQuery = getAllTags(EntityType.TYPE_SERVICES);
  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const getNodeAttributesByCustomRange = useGetFilteredNodeAttributesByCustomRange(`${service.contractId}`, [
    NodeAttributeType.PriorityAndServices, 
    NodeAttributeType.Models, 
    NodeAttributeType.FaultCategory,
    NodeAttributeType.FaultCode
  ], value?.serviceTypeCode || '');
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();
  const searchNodeByParentId = useSearchNodeByParentId();
  const searchNodeQuery = useSearchNodeOperational();
  const getSuggestgedNodesFromAllocationEngine = useGetSuggestedNodesFromAllocationEngine(value?.serviceTypeCode || '', value?.actionTypeCode || '', value?.processTypeCode || '', Number(service.parentId), mainLocationNode?.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchParts = useSearchParts();

  useEffect(() => {
    let checkServiceObjectValue: NodeJS.Timer;
    if (service && !value) {
      checkServiceObjectValue = setInterval(() => {
        const contactsData = service?.attributes?.filter((attribute) => attribute.code === ServiceAttributeType.Contact)?.map((contactData) => contactData.value);
        contactsData?.length && setStructureContactDataset(contactsData);
        if (preSelectedData?.data && preSelectedData.data.autoFill) {
          onChange({
            ...service,
            priorityCode: '',
            scheduledAt: '',
            userDepotAllocation: '',
            userDepotAllocationNodeId: undefined,
            serviceTypeCode: preSelectedData.data.autoFill.serviceTypeCode,
            actionTypeCode: preSelectedData.data.autoFill.actionTypeCode,
            processTypeCode: preSelectedData.data.autoFill.processTypeCode,
            notes: preSelectedData.data.autoFill.notes || service?.creationNotes || '',
            contactData: contactsData
          });
        } else {
          onChange({ ...service, contactData: contactsData });
        }
        setLocationText(`${service.mainLocation || ''} ${(service.mainLocation && service.subLocation) ? '/' : ''} ${service.subLocation || ''}`);
      }, 500);
    }
    return () => {
      clearInterval(checkServiceObjectValue);
    };
  }, [preSelectedData, value]);
  
  useEffect(() => {
    setUserAllocationPermission(getUserPermissionsQuery.data ? isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, getUserPermissionsQuery.data) : false);
  }, [getUserPermissionsQuery.data]);
  
  useEffect(() => {
    serviceActionProcessTypesQuery.data && setServiceCommonTypes(serviceActionProcessTypesQuery.data);
  }, [serviceActionProcessTypesQuery.data]);
  
  useEffect(() => {
    if (getSuggestgedNodesFromAllocationEngine.data && userAllocationPermission) {
      const data = getSuggestgedNodesFromAllocationEngine.data;
      data.forEach(item => item.isSuggestedNode = true);
      setSuggestedNodesFromAllocation(data);
    }
  }, [getSuggestgedNodesFromAllocationEngine.data]);
  
  useEffect(() => {
    serviceTagsQuery.data && setAllTags(serviceTagsQuery.data);
  }, [serviceTagsQuery.data]);

  useEffect(() => {
    allTags?.length && !selectedTags.length && service?.tags?.length && setSelectedTags(mapSavedTags(service?.tags));
    value && !value.tagCreationData && onChange({ ...value, tagCreationData: { selectedTags: mapSavedTags(service.tags) } });
  }, [allTags, value]);
  
  useEffect(() => {
    if (service.parentId && value?.serviceTypeCode) {
      getNodeAttributesByCustomRange.refetch();
    }
  }, [service.parentId, value?.serviceTypeCode]);
  
  useEffect(() => {
    getNodeAttributesByCustomRange.data && setNodeAttributes(getNodeAttributesByCustomRange.data);
    const priorities = getNodeAttributesByCustomRange?.data?.filter((attribute: any) => attribute.propertyTypeCode === NodeAttributeType.PriorityAndServices && attribute.value?.isActive);
    if (priorities?.length === 1) {
      value && onChange({
        ...value, 
        priorityCode: priorities[0].id,
        selectedServiceCreationTypeValue: priorities[0]?.value?.selectedServiceCreationTypeValue || []
      });
    }
    priorities?.length > 1 && setFocusField('priorityCode');
  }, [getNodeAttributesByCustomRange.data, getNodeAttributesByCustomRange.isFetching]);

  useEffect(() => {
    value && onChange({ ...value, mainLocationNodeId: mainLocationNode?.id || undefined, subLocationNodeId: subLocationNode?.id || undefined });
  }, [mainLocationNode, subLocationNode]);

  useEffect(() => {
    value && onChange({ ...value, tagCreationData: { selectedTags, newTags } });
  }, [selectedTags, newTags]);

  useEffect(() => {
    structureContactDataset.length && value && onChange({ ...value, contactData: structureContactDataset });
  }, [structureContactDataset]);

  useEffect(() => {
    getErrorStatus(value?.notes, ServiceCreationRuleTypes.Notes) && setNotesHelperText(emptyFieldValidation(value?.notes || '', ServiceCreationRuleTypes.Notes));
  }, [value?.notes, value?.selectedServiceCreationTypeValue]);

  const onChangePriority = (val: any) => {
    const selectedPriority: any = nodeAttributes.filter((data: any) => data?.id === val);
    value && onChange({
      ...value, 
      priorityCode: val,
      selectedServiceCreationTypeValue: selectedPriority[0]?.value?.selectedServiceCreationTypeValue || []
      // TODO: Remove above validation helper code after E2E testings.
      // selectedServiceCreationTypeValue: ['ref1', 'ref2', 'ref3', 'faultAndDescription', 'model', 'serialNo', 'notes']
    });
  };

  const getErrorStatus = (val: any, ruleType: ServiceCreationRuleTypes) => {
    const ruleExist = isRuleExist(value?.selectedServiceCreationTypeValue, ruleType);
    if (ruleExist) {
      if (isNaN(val)) {
        return (isError && (!val || isEmptyString(val)));
      } else if (typeof(val) === 'number') {
        return (isError && Number(val) < 1);
      } else if (!val) {
        return isError;
      }
    } else {
      return false;
    }
  };

  const onChangeSelectedTags = (val: (string | AutocompleteTagType)[]) => {
    setSelectedTags(val);
    value && onChange({ ...value, tagCreationData: { ...value.tagCreationData, selectedTags: val } });
  };

  const onChangeNewTags = (val: Tag[]) => {
    setNewTags(val);
    value && onChange({ ...value, tagCreationData: { ...value.tagCreationData, newTags: val } });
  };
  
  return (
    <ServiceBox>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledSelectMenu
                key={`${serviceCommonTypes.serviceTypes}-${value}`}
                id="service-type-select"
                label="Service Type"
                defaultSelectedValue={value?.serviceTypeCode}
                items={serviceTypes(serviceCommonTypes.serviceTypes)}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelectMenu
                key={`${serviceCommonTypes.actionTypes}-${value}`}
                id="action-type-select"
                label="Action Type"
                selectedValue={value?.actionTypeCode}
                items={actionTypes(serviceCommonTypes.actionTypes)}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <StyledSelectMenu
                key={`${serviceCommonTypes.processTypes}-${value}`}
                id="process-type-select"
                label="Process Type"
                selectedValue={value?.processTypeCode}
                items={processTypes(serviceCommonTypes.processTypes)}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client Selection"
                value={`${value?.client}/${value?.contract}${value?.brand && `/${value?.brand}`}`}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <PrioritySelectMenu
                key={`${nodeAttributes}-${value}`}
                id="priority-type-select"
                label="Priority"
                value={value?.priorityCode || ''}
                onChange={onChangePriority}
                items={priorityTypes(nodeAttributes)}
                validate={isError && (!value?.priorityCode || isEmptyString(value?.priorityCode))}
                isFocused={focusField === 'priorityCode'}
              />
            </Grid>
            <Grid item xs={12}>
              {locationNodeOptions ?
                <LocationSearchSelect
                  title="Location Search"
                  options={locationNodeOptions}
                  value={subLocationNode}
                  onChange={(selectedNode) => {
                    const main = locationNodeOptions.find(item => item.isMain);
                    const sub = locationNodeOptions.find(item => !item.isMain && item.id === selectedNode.id);
                    setMainLocationNode(main);
                    setSubLocationNode(sub);
                    setLocationText(`${main?.name || ''} ${(main?.name && sub?.name) ? '/' : ''} ${sub?.name || ''}`);
                    setLocationNodeOptions(undefined);
                  }}
                />
                :
                <LiveSearchBox
                  title="Location Search"
                  timeOffset={400}
                  value={locationText || ''}
                  onClearValue={() => {
                    setLocationText(undefined);
                    setMainLocationNode(undefined);
                    setSubLocationNode(undefined);
                    setLocationNodeOptions(undefined);
                  }}
                  renderItem={(props: any, option: any) => {
                    return (
                      <LiveSearchListItem {...props}>
                        <LocationSearchItem data={option} />
                      </LiveSearchListItem>
                    );
                  }}
                  onChange={async (obj: LocationNodeData) => {
                    const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(obj.id);
                    if (subLocationsResult.length > 0) {
                      const main = subLocationsResult.find(item => item.isMain);
                      const sub = subLocationsResult.find(item => !item.isMain && item.isSelected);

                      if (main && sub) {
                        setMainLocationNode(main);
                        setSubLocationNode(sub);
                        setLocationText(`${main.name || ''} ${(main.name && sub.name) ? '/' : ''} ${sub.name || ''}`);
                      }
                      else {
                        const subLocationsOnly = subLocationsResult.filter(item => !item.isMain);
                        if (main?.isSelected && subLocationsOnly.length === 0) {
                          setMainLocationNode(main);
                          setSubLocationNode(undefined);
                          setLocationText(main.name);
                        }
                        else {
                          setLocationNodeOptions(subLocationsResult);
                        }
                      }
                    } else {
                      setLocationNodeOptions(undefined);
                      setMainLocationNode(undefined);
                      setSubLocationNode(undefined);
                      setLocationText(undefined);
                    }
                  }}
                  onApiInvoke={async (name) => {
                    const hasCode = getHasCodeForNodeSearch(name);
                    return await searchNodeByParentId.mutateAsync({
                      selectedParentId: service.parentId,
                      name,
                      hasCode: hasCode
                    });
                  }}
                  isError={isError && value?.mainLocationNodeId === undefined}
                  helperText={(isError && value?.mainLocationNodeId === undefined) ? `Location ${EMPTY_FIELD_MESSAGE}` : ''}
                />
              }
            </Grid>
            <Grid item xs={6}>
              <TextField
                key={`${value}`}
                fullWidth
                label="Reference No 1"
                value={value?.referenceNo1}
                onChange={(val) => value && onChange({ ...value, referenceNo1: val })}
                helperText={getErrorStatus(value?.referenceNo1, ServiceCreationRuleTypes.Reference1) ? `Reference No 1 ${EMPTY_FIELD_MESSAGE}` : ''}
                error={getErrorStatus(value?.referenceNo1, ServiceCreationRuleTypes.Reference1)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key={`${value}`}
                fullWidth
                label="Reference No 2"
                value={value?.referenceNo2}
                onChange={(val) => value && onChange({ ...value, referenceNo2: val })}
                helperText={getErrorStatus(value?.referenceNo2, ServiceCreationRuleTypes.Reference2) ? `Reference No 2 ${EMPTY_FIELD_MESSAGE}` : ''}
                error={getErrorStatus(value?.referenceNo2, ServiceCreationRuleTypes.Reference2)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                key={`${value}`}
                fullWidth
                label="Reference No 3"
                value={value?.referenceNo3}
                onChange={(val) => value && onChange({ ...value, referenceNo3: val })}
                helperText={getErrorStatus(value?.referenceNo3, ServiceCreationRuleTypes.Reference3) ? `Reference No 3 ${EMPTY_FIELD_MESSAGE}` : ''}
                error={getErrorStatus(value?.referenceNo3, ServiceCreationRuleTypes.Reference3)}
              />
            </Grid>
            <Grid item xs={12}>
              <Tags
                allTags={allTags}
                selectedTags={selectedTags}
                setSeletedTags={onChangeSelectedTags}
                newTags={newTags}
                setNewTags={onChangeNewTags}
              />
            </Grid>
            {userAllocationPermission && (
              <Grid item xs={6}>
                <LiveSearchBox
                  key={`${value}`}
                  title="User/Depot Allocation"
                  timeOffset={400}
                  value={value?.userDepotAllocation ?? ''}
                  onClearValue={() => value && onChange({ ...value, userDepotAllocation: '', userDepotAllocationNodeId: undefined })}
                  defaultSuggetions={suggestedNodesFromAllocation}
                  renderItem={(props: any, option: any) => {
                    return (
                      <StyledBox heighlightBackground={option.isSuggestedNode}>
                        <LiveSearchListItem {...props}>
                          <LocationSearchItem data={option} />
                        </LiveSearchListItem>
                      </StyledBox>
                    );
                  }}
                  onChange={async (obj: LocationNodeData) => value && onChange({ ...value, userDepotAllocation: obj.name, userDepotAllocationNodeId: obj.id })} //node: obj
                  onApiInvoke={async (obj) => {
                    return new Promise((resolve) => {
                      searchNodeQuery.mutateAsync(obj).then(data => {
                        const filtedList: LocationNodeData[] = data.filter((result) => result.canHaveAllocations);
                        if (suggestedNodesFromAllocation && suggestedNodesFromAllocation.length > 0) {
                          const newListWithoutDuplicates = filtedList.filter(item => {
                            return !suggestedNodesFromAllocation.some(suggestedNode => suggestedNode.id == item.id);
                          });
                          resolve(newListWithoutDuplicates);
                        }
                        resolve(filtedList);
                      });
                    });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <DateTimePicker
                key={`${value}`}
                label="Scheduled Date/Time"
                disablePast={true}
                value={value?.scheduledAt || ''}
                onChange={(val) => value && onChange({ ...value, scheduledAt: val })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <FormControl
                  error={getErrorStatus(value?.notes, ServiceCreationRuleTypes.Notes)}
                  required={getErrorStatus(value?.notes, ServiceCreationRuleTypes.Notes)}
                >
                  <RichTextEditor
                    value={value?.notes || ''}
                    onValueChange={(val: string) => {
                      if (val === RICH_TEXT_EDITOR_EMPTY_TEXT) {
                        val = '';
                      }
                      getErrorStatus(val, ServiceCreationRuleTypes.Notes) && setNotesHelperText(emptyFieldValidation(val, ServiceCreationRuleTypes.Notes));
                      value && onChange({ ...value, notes: val });
                    }}
                    error={getErrorStatus(value?.notes, ServiceCreationRuleTypes.Notes)}
                  />
                  {getErrorStatus(value?.notes, ServiceCreationRuleTypes.Notes) && (
                    <FormHelperText>{notesHelperText}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <LiveSearchBox
                isRequired={getErrorStatus(value?.reportedSerialNumber, ServiceCreationRuleTypes.SerialNumber)}
                helperText={getErrorStatus(value?.reportedSerialNumber, ServiceCreationRuleTypes.SerialNumber) ? `Reported Serial Number ${EMPTY_FIELD_MESSAGE}` : ''}
                isError={getErrorStatus(value?.reportedSerialNumber, ServiceCreationRuleTypes.SerialNumber)}
                title="Reported Serial Number"
                timeOffset={400}
                value={value?.reportedSerialNumber || ''}
                onClearValue={() => value && onChange({ ...value, reportedSerialNumber: '', reportedSerialNumberPartId: NaN })}
                renderItem={(props: any, option: any) => { 
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(serialData: LocationNodeData) => value && onChange({
                  ...value,
                  reportedSerialNumberPartId: serialData.id,
                  reportedSerialNumber: serialData.name
                })}
                onApiInvoke={async (name: string) => {
                  return await searchParts.mutateAsync({ name });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelectMenu
                key={`${nodeAttributes}-${value}`}
                id="model-type-select"
                label="Reported Model"
                selectedValue={value?.reportedModelId || '-1'}
                onChange={(val) => value && onChange({ ...value, reportedModelId: val })}
                items={reportedModelTypes(nodeAttributes)}
                validate={getErrorStatus(value?.reportedModelId, ServiceCreationRuleTypes.Model)}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelectMenu
                key={`${nodeAttributes}-${value}`}
                id="fault-type-select"
                label="Reported Fault and Description"
                selectedValue={value?.reportedFaultAndDescriptionId || '-1'}
                onChange={(val) => value && onChange({ ...value, reportedFaultAndDescriptionId: val })}
                items={reportedFaultTypes(nodeAttributes)}
                validate={getErrorStatus(value?.reportedFaultAndDescriptionId, ServiceCreationRuleTypes.FaultAndDescription)}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ServiceContactLayout
                    isService
                    contactFormData={contactData}
                    editContactFormData={editContactData}
                    contactDataset={structureContactDataset ? structureContactDataset : []}
                    onContactChange={(value) => {
                      setContactData(value);
                    }}
                    onClickContactEdit={(event, index) => {
                      const selectedValue: any = structureContactDataset.splice(index, 1);
                      setStructureContactDataset(structureContactDataset);
                      setEditContactData(selectedValue[0]);
                    }}
                    onClickContactDelete={(event, index) => {
                      const updatedData = [...structureContactDataset];
                      if (index > -1) updatedData.splice(index, 1);
                      setStructureContactDataset(updatedData);
                    }}
                    handleContactCreate={(index) => {
                      if (index > -1) contactData[index].isActive = true;
                      setStructureContactDataset([...structureContactDataset, contactData[index]]);
                      contactData.splice(index, 1);
                      setContactData([...contactData]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ServiceBox>
  );
};
