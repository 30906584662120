import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { Typography } from '../../../components/atoms/Typography';

export const ImportServicesTypography = styled(Typography)`
  padding-bottom: 10px;
`;

export const ImportServicesSection= styled('div')`
  flex: 1;
  margin-top: 40px;
`;

export const ServiceImportsGridContainer = styled('div')`
  height: 50vh;
  width: 100%;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

export const ImportServicesButton= styled('div')`
  margin-top: 20px;
  bottom: 20px;
`;

export const ImportServicesLoader= styled('div')`
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledWarningAlert = styled(Alert)`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  max-width: 1100px;
  word-wrap: break-word;
`;