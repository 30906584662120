import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { NotificationConfigListResponse, NotificationTemplateListResponse } from '../@types/notification.type';
import { getNotificationConfigsByCateogry, createNotificationConfig, updateNotificationConfig, getNotificationTemplates } from '../api/notifications';
import { NotificationConfigsKey, NotificationTemplatesKey } from '../configs/notification';
import { QueryOption } from '../@types';

export const useGetNotificationConfigsByCateogry = (category: string, queryOption: QueryOption): UseQueryResult<NotificationConfigListResponse> => {
  return useQuery([NotificationConfigsKey, category], () => getNotificationConfigsByCateogry(category, queryOption), { enabled: false, cacheTime: 0 });
};

export const useCreateNotificationConfig = (category: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => createNotificationConfig(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([NotificationConfigsKey, category]);
    },
  }); 
};
  
export const useUpdateNotificationConfig = (category: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateNotificationConfig(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([NotificationConfigsKey, category]);
    },
  }); 
};

export const useGetNotificationTemplates = (): UseQueryResult<{ data: NotificationTemplateListResponse[] }> => {
  return useQuery([NotificationTemplatesKey], () => getNotificationTemplates());
};