import { TradingHourTypes } from '../../../components/molecules/TradingHours';

export const state = [
  { name: 'Victoria', id: 1 },
  { name: 'New South Wales', id: 2 },
  { name: 'Queensland', id: 3 },
];

export const country = [
  { name: 'Australia', id: 1 },
  { name: 'New Zealand', id: 2 },
  { name: 'United States', id: 3 },
];

export const stateType = [
  { name: 'Victoria', id: 1 },
  { name: 'New South Wales', id: 2 },
  { name: 'Queensland', id: 3 },
];

export const countryType = [
  { name: 'Australia', id: 1 },
  { name: 'New Zealand', id: 2 },
  { name: 'United States', id: 3 },
];

export const AddressFormData = [{
  addressType: 'Main', 
  name: 'Joe Home',
  traditionalPlaceName: 'Joe Home',
  line1: '1640 York Ave',
  line2: 'Unit 2B', 
  suburb: 'Brooks',
  city: 'New Wales',
  state: '',
  postCode: 'BC-12345',
  country: 'Australia',
  contactType: 'Main', 
  contactName: 'Joe Home', 
  organization: 'ANZ', 
  phone1: '+5458478478', 
  phone2: '+548784414', 
  email: 'ki@hmakk.lk', 
  notes: 'hsgfyysgd'
},{
  addressType: 'Logistic', 
  name: 'Joe Home',
  traditionalPlaceName: 'Joe Home',
  line1: '1640 York Ave',
  line2: 'Unit 2B', 
  suburb: 'Brooks',
  city: 'New Wales',
  state: '',
  postCode: 'BC-12345',
  country: 'Australia',
  contactType: 'Secondary', 
  contactName: 'Joe Home', 
  organization: 'ANZ', 
  phone1: '+5458478478', 
  phone2: '+548784414', 
  email: 'ki@hmakk.lk', 
  notes: 'hsgfyysgd'
}];

export const ContactFormData = [{
  addressType: 'Main', 
  name: 'Joe Home',
  traditionalPlaceName: 'Joe Home',
  line1: '1640 York Ave',
  line2: 'Unit 2B', 
  suburb: 'Brooks',
  city: 'New Wales',
  state: '',
  postCode: 'BC-12345',
  country: 'Australia',
  contactType: 'Main', 
  contactName: 'Joe Home', 
  organization: 'ANZ', 
  phone1: '+5458478478', 
  phone2: '+548784414', 
  email: 'ki@hmakk.lk', 
  notes: 'hsgfyysgd'
},{
  addressType: 'Logistic', 
  name: 'Joe Home',
  traditionalPlaceName: 'Joe Home',
  line1: '1640 York Ave',
  line2: 'Unit 2B', 
  suburb: 'Brooks',
  city: 'New Wales',
  state: '',
  postCode: 'BC-12345',
  country: 'Australia',
  contactType: 'Secondary', 
  contactName: 'Joe Home', 
  organization: 'ANZ', 
  phone1: '+5458478478', 
  phone2: '+548784414', 
  email: 'ki@hmakk.lk', 
  notes: 'hsgfyysgd'
}];

export const toogleButtonGroupItems = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'client',
    label: 'Client',
  },
  {
    value: 'search',
    label: 'Search',
  }
];

export const STRUCTURE_HELPER_TEXT = {
  nameHelperText: '',
};

export const structureLayoutData = {
  name: 'Sample Name',
  notes: 'Notes',
  referenceNo1: '123a',
  referenceNo2: '123s',
  referenceNo3: '123a',
  siteSchemaType: 'one'
};

export const structureLayoutSchemaType = [{
  value: 'one',
  label: 'One'
}];

export const allTags = [
  { id: 1, text: 'system tag 1', isSystem: true, colour: 'red' },
  { id: 2, text: 'system tag 2', isSystem: true, colour: 'blue' },
  { id: 3, text: 'system tag 3', isSystem: true, colour: 'purple' },
  { id: 4, text: 'custom tag 1', isSystem: false, colour: 'green' },
  { id: 5, text: 'custom tag 2', isSystem: false, colour: 'yellow' }
];

export const tradingHourValue = [{
  type: TradingHourTypes.AllDays,
  start: '06:30',
  end: '18:00',
  ph: false
}];

export const childrenData = [
  { id: 1, type: 'Lane', name: 'Lane 1 (Front Desk)' },
  { id: 2, type: 'Lane', name: 'Lane 2 (Loading Dock)' },
  { id: 3, type: 'Terminal', name: 'TID1234' },
];