import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { isUserHasPermission } from '../../../configs/permissions';
import { CreateUser } from '../CreateUser';
import { UpdateUser } from '../UpdateUser';

export const AddEditUser: React.FC = () => {
  const { id } = useParams();
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.data]);
  
  if(id === 'create' && isUserHasPermission(ActionPermissions.Configure_User_Create, permissions)) {
    return <CreateUser />;
  } else if (isUserHasPermission(ActionPermissions.Configure_User_Edit_General, permissions)) {
    return <UpdateUser />;
  } else {
    return null;
  }
};