import React from 'react';
import { Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { PartTypeLocationItemProps } from './PartTypeLocationItem.props';
import { StyledStack } from './PartTypeLocationItem.styles';

export const PartTypeLocationItem: React.FC<PartTypeLocationItemProps> = ({
  data,
  onClick
}) => {
  return (
    <StyledStack key={data.id} spacing={0.5} onClick={onClick}>
      <Typography 
        variant={TypographyVariantProps.Body1} 
        fontWeight={TypographyFontWeightProps.Bold}
      >
        {data.locationName}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant={TypographyVariantProps.Body2}>{data.conditionCode} | QTY: {data.quantityInStock}</Typography>
        <Typography variant={TypographyVariantProps.Body2}>{data.fleetTagName}</Typography>
      </Stack>
    </StyledStack>
  );
};
