import { httpClient, HttpMethod } from './http-client';
import {
  PrioritySelectionObjectType,
  StructureType,
  StructureTypeResponse,
  SiteSchemaData,
  StructureMergeDecisionData
} from '../@types';

export const getStructureTypesByCategoryCode = (catagoryCode: string): StructureTypeResponse[] => {
  return httpClient(`/nodeTypeCategories/${catagoryCode}/nodeTypes`, HttpMethod.Get, null);
};

export const createStructureType = (nodeType: StructureType) => {
  return httpClient('/nodeTypes', HttpMethod.Post, nodeType);
};

export const getStructuresByTypeCategoryCode = (catagoryCode: string, queryOptions: any) => {
  const { filterModel, page, pageSize, sortModel, excludedColumns } = queryOptions;
  let url = `/nodeTypeCategories/${catagoryCode}/nodes?page=${page + 1}&limit=${pageSize}&excludedColumns=${excludedColumns}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getStructuresByTypeCategoryCodeAndTypeCode = (catagoryCode: string, typeCode: string) => {
  return httpClient(`/nodeTypeCategories/${catagoryCode}/nodes/${typeCode}`, HttpMethod.Get, null);
};

export const getNodeChildrenByNodeType = (nodeId: string, nodeTypeCode: string) => {
  return httpClient(`/nodes/${nodeId}/children?nodeTypeCode=${nodeTypeCode}`, HttpMethod.Get, null);
};

export const getNodeChildren = (nodeId: string) => {
  return httpClient(`/nodes/${nodeId}/children`, HttpMethod.Get, null);
};

export const createStructure = (node: any) => {
  return httpClient('/nodes', HttpMethod.Post, node);
};

export const getNodeFilteredAttributesByCustomRange = (nodeId: string, nodeAttributes: any, serviceTypeCode: string) => {
  const url = `/nodes/${nodeId}/filteredAttributes?nodeAttributes=${nodeAttributes}&serviceTypeCode=${serviceTypeCode}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getNodeAttributesByCustomRange = (nodeId: string, nodeAttributes: any) => {
  const url = `/nodes/${nodeId}/attributes?nodeAttributes=${nodeAttributes}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const updateStructure = (nodeId: string, node: any) => {
  return httpClient(`/nodes/${nodeId}`, HttpMethod.Put, node);
};

export const updateStructureBasicData = (nodeId: string, node: any) => {
  return httpClient(`/nodes/${nodeId}/basic`, HttpMethod.Put, node);
};

export const getNodeById = (nodeId: string, nodeTypeCategoryCode?: string) => {
  let url = `/nodes/${nodeId}`;
  if (nodeTypeCategoryCode) {
    url += `?nodeTypeCategoryCode=${nodeTypeCategoryCode}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const getNodeByName = (name: string, nodeTypeCode: string, parentNodeId?: number) => {
  const parentNodeIdParam = parentNodeId ? `&parentNodeId=${parentNodeId}` : '';
  return httpClient(`/nodes/name/${name}/nodeTypeCode/${nodeTypeCode}?${parentNodeIdParam}`, HttpMethod.Get, null);
};

export const getNodeSubTree = (nodeId: string) => {
  return httpClient(`/nodes/${nodeId}/subtree`, HttpMethod.Get, null);
};

export const getParentAttributesByPropertyType = (nodeId: string, propertyTypeCode: string) => {
  return httpClient(`/nodes/${nodeId}/getparentattributes/${propertyTypeCode}`, HttpMethod.Get, null);
};

export const getPrioritySelection = (prioritySelectionObject: PrioritySelectionObjectType) => {
  return httpClient('/nodes/priorityselection', HttpMethod.Post, prioritySelectionObject);
};

export const getSuggestgedNodesFromAllocationEngine = (serviceTypeCode: string, actionTypeCode: string, processTypeCode: string, brandId?: number, mainLocationId?: number, serviceId?: number) => {
  return httpClient(`/preferedNodesByAllocationEngine?serviceTypeCode=${serviceTypeCode}&actionTypeCode=${actionTypeCode}&processTypeCode=${processTypeCode}&brandId=${brandId}&mainLocationId=${mainLocationId}&serviceId=${serviceId}`, HttpMethod.Get, null);
};

export const getSiteSchemasByClientContract = (clientId: string, contractId: string) => {
  return httpClient(`/nodeattributes/client/${clientId}/contract/${contractId}`, HttpMethod.Get, null);
};

export const getAllPartsForStructures = (limit: number, page: number, nodeId: number, isEnableToShowChildrenParts: boolean) => {
  const url =
    `/getPartsByStructureLocationId?limit=${limit}&page=${page + 1}&locationId=${nodeId}&isEnableToShowChildrenParts=${isEnableToShowChildrenParts}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getAllSiteSchemas = (nodeId: number) => {
  return httpClient(`/siteschema/nodeId/${nodeId}`, HttpMethod.Get, null);
};

export const getSiteSchema = (siteSchemaId: number) => {
  return httpClient(`/siteschema/${siteSchemaId}`, HttpMethod.Get, null);
};

export const postSiteSchema = (data: SiteSchemaData) => {
  return httpClient('/siteschema', HttpMethod.Post, data);
};

export const updateSiteSchema = (data: SiteSchemaData) => {
  return httpClient(`/siteschema/${data.id}`, HttpMethod.Put, data);
};

export const getLoggedInSiblingParentDetails = () => {
  return httpClient('/nodes/loggedinsibling/parentdetails', HttpMethod.Get, null);
};

export const getAllShipmentsForStructures = (limit: number, page: number, nodeId: number, isEnableToShowChildrenShipments: boolean) => {
  const url =
    `/getShipmentsByStructureLocationId?limit=${limit}&page=${page + 1}&locationId=${nodeId}&isEnableToShowChildrenShipments=${isEnableToShowChildrenShipments}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getDestinationAddressByNodeId = (nodeId: string) => {
  return httpClient(`/nodes/getLocationAttributes/${nodeId}`, HttpMethod.Get, null);
};


export const getNodeAttributesByPropertyTypeAndNodeIds = (propertyTypeCode: string, nodeIds: string) => {
  const url = `/nodes/attributes/${propertyTypeCode}/getByIds?nodeIds=${nodeIds}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getNodeAttributesByNodeId = (nodeId: number) => {
  const url = `/nodes/${nodeId}/attributes`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getAllNodesByNodeType = (nodeTypeCode: string) => {
  return httpClient(`/nodeTypes/${nodeTypeCode}/nodes`, HttpMethod.Get, null);
};

export const mergeStructure = (data: StructureMergeDecisionData) => {
  return httpClient(`/locationMerge/currentNode/${data.currentNodeId}/mergeNode/${data.mergeNodeId}`, HttpMethod.Put, data);
};

export const getDispatchDestinationSuggestions = (serviceId: number, mainLocationNodeId: number, subLocationNodeId: number, primaryAllocationNodeId: number, secondaryAllocationNodeId: number) => {
  const url = `/destinationSuggestions/dispatchShipment?serviceId=${serviceId}&mainLocationNodeId=${mainLocationNodeId}&subLocationNodeId=${subLocationNodeId}&primaryAllocationNodeId=${primaryAllocationNodeId}&secondaryAllocationNodeId=${secondaryAllocationNodeId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getNodeAttributeById = (nodeAttributeId: number) => {
  return httpClient(`/nodeattributes/${nodeAttributeId}`, HttpMethod.Get, null);
};

export const getAllocationNodeParentData = (nodeId: number) => {
  return httpClient(`/allocationNodeParentData/${nodeId}`, HttpMethod.Get, null);
};

export const getCommonFleetTagsByPartTypeIds = (partTypeIds: number[]) => {
  return httpClient('/getCommonFleetTagsByPartTypeIds', HttpMethod.Post, { partTypeIds });
};