import React from 'react';
import { LinkListProps } from './LinkList.props';
import { Grid } from '@mui/material';
import { Link } from '../../atoms/Link';
import { LinkObject } from '../../../@types';

export const LinkList: React.FC<LinkListProps> = ({
  data,
}) => {
  return (
    <Grid container mb={2}>
      List Service IDs:&nbsp;
      {data.map((value: LinkObject, index: number) => 
        <Link key={value?.serviceId} href={`/services/${value?.serviceId}`}>
          {value?.serviceId}{index !== data.length -1 ? ',' : ''}
        </Link>
      )}
    </Grid>
  );
};