import React, { useState, useEffect } from 'react';
import { Stack, Table, TableHead, TableBody, TableRow, Grid } from '@mui/material';
import { TextField } from '../../../../../components/atoms/TextField';
import { Typography, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { SelectMenu, DropdownItem } from '../../../../../components/atoms/SelectMenu';
import { Checkbox, CheckboxColorProps } from '../../../../../components/atoms/Checkbox';
import { ShipmentPackagesList, SelectedShipmentPackage, FreightPackageType } from '../../../../../components/molecules/ShipmentPackagesList';
import { StructureAddressContactLayout } from '../../../../../components/templates/StructureAddressContactLayout';
import { Modal } from '../../../../../components/atoms/Modal';
import { Snackbar } from '../../../../../components/atoms/Snackbar';
import { Loader, LoaderColorProps } from '../../../../../components/atoms/Loader';
import { IntegratedFreightShippingProps, IntegratedFreightShippingData } from './IntegratedFreightShippingFragment.props';
import { ContentWrapperBox, HeadingWrapper, BoldLabel, StyledTableCell, Footer } from './IntegratedFreightShippingFragment.styles';
import { contactType } from '../../../../ClientStructure/AddEditClient/AddClientData.data';
import { AddressDataType } from '../../../../../components/organisms/StructureAddress';
import { NodeAttributesReponse } from '../../../../../@types/structure.type';
import { CourierData, QuoteFreightOrderTypeResponse } from '../../../../../@types/shipment.type';
import { useGetNodeAttributesByPropertyTypeAndNodeIds, useGetNodeAttributesByNodeId, useGetDestinationAddressByNodeId } from '../../../../../queries/structure-query';
import { useGetFreightPackageTypes, useCreateFreightOrder, useArchiveFreightOrder, useReturnFreightOrder, useQuoteFreightOrder, useGetFreightOrdersByShipment } from '../../../../../queries/freight-query';
import { useGetStateWithCountryData } from '../../../../../queries/geosocial-query';
import { checkIntegratedFreightErrors, getCourierIds, getFreightIntegrationEnabledCouriers } from '../../../../../utils/shipment';
import { NodeAttributeType, FreightOrderReference, CountryCode, FreightIntegrator } from '../../../../../configs/enums';
import { INTEGRATED_FREIGHT_SHIPPING_DATA, CUSTOM_PACKAGE_TYPES } from '../../../../../constants/shipment';
import { PrintLabel } from '../../../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../../../@types/print-label.type';
import { CloseIcon } from '../../../../../components/atoms/CloseIcon';

export const IntegratedFreightShipping: React.FC<IntegratedFreightShippingProps> = ({
  couriers,
  shipment
}) => {
  const [freightEnabledCouriers, setFreightEnabledCouriers] = useState<CourierData[]>([]);
  const [serviceTypes, setServiceTypes] = useState<DropdownItem[]>([]);
  const [freightPackageTypes, setFreightPackageTypes] = useState<FreightPackageType[]>([]);
  const [integratedFreightShippingData, setIntegratedFreightShippingData] = useState<IntegratedFreightShippingData>(INTEGRATED_FREIGHT_SHIPPING_DATA);

  const [destinationAddress, setDestinationAddress] = useState<AddressDataType>();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [destinationAddressOptions, setDestinationAddressOptions] = useState<DropdownItem[]>([]);
  const [structureAddressDataset, setStructureAddressDataset] = useState<AddressDataType[]>([]);
  const [addressData, setAddressData] = useState<AddressDataType[]>([]);
  const [editAddressData, setEditAddressData] = useState<AddressDataType>();
  const [addressForm, setAddressForm] = useState<any[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [quoteData, setQuoteData] = useState<QuoteFreightOrderTypeResponse[]>([]);
  const [toastMessage, setToastMessage] = useState<string>();
  const [openToast, setOpenToast] = useState<boolean>(false);

  const getNodeAttributesByPropertyTypeAndNodeIds = useGetNodeAttributesByPropertyTypeAndNodeIds(NodeAttributeType.FreightIntegrationEnabled, getCourierIds(couriers));
  const getNodeAttributesByNodeId = useGetNodeAttributesByNodeId(integratedFreightShippingData.shippingProviderId);
  const getFreightPackageTypes = useGetFreightPackageTypes();
  const createFreightOrder = useCreateFreightOrder(shipment.id);
  const archiveFreightOrder = useArchiveFreightOrder(shipment.id);
  const returnFreightOrder = useReturnFreightOrder(shipment.id);
  const quoteFreightOrder = useQuoteFreightOrder();
  const getFreightOrdersByShipment = useGetFreightOrdersByShipment(shipment.id || 0);
  const getContactByNodeId = useGetNodeAttributesByNodeId(shipment?.destinationNodeId);
  const getStateWithCountryData = useGetStateWithCountryData(destinationAddress?.stateId || 0);
  const { data: destinationAddressdata, refetch: refetchDestinationNode } = useGetDestinationAddressByNodeId(shipment?.destinationNodeId.toString() || '');

  useEffect(() => {
    getNodeAttributesByPropertyTypeAndNodeIds.data && setFreightEnabledCouriers(getFreightIntegrationEnabledCouriers(couriers, getNodeAttributesByPropertyTypeAndNodeIds.data));
  }, [getNodeAttributesByPropertyTypeAndNodeIds.data]);

  useEffect(() => {
    getNodeAttributesByNodeId.data && getCourierServiceTypes(getNodeAttributesByNodeId.data);
  }, [getNodeAttributesByNodeId.data]);

  useEffect(() => {
    getFreightPackageTypes.data && setFreightPackageTypes(getFreightPackageTypes.data);
  }, [getFreightPackageTypes.data]);
  
  useEffect(() => {
    refetchDestinationNode();
  }, [shipment.destinationNodeId]);

  useEffect(() => {  
    if(destinationAddressdata?.length == 0){
      setIntegratedFreightShippingData({ ...integratedFreightShippingData, isCustomAddress: true });
    } else {
      const options: { value: string; label: string; addressLabel:AddressDataType }[] = [];
      destinationAddressdata && destinationAddressdata?.map((obj) => {
        options.push({ value: obj.id.toString(), label: `${obj.value.line1}, ${obj.value.line2}, ${obj.value.suburb}, ${obj.value.stateName}, ${obj.value.postCode}, ${obj.value.countryName}`, addressLabel: obj.value });
      });
      destinationAddressdata && setSelectedOption( destinationAddressdata[0].id.toString());
      setDestinationAddressOptions(options);
    }

  }, [destinationAddressdata]);

  useEffect(() => {
    selectedOption && setDestinationAddress(destinationAddressdata && destinationAddressdata.filter((item) => item.id === parseInt(selectedOption))[0].value);
    structureAddressDataset.length > 0 && setDestinationAddress(structureAddressDataset[0]);
    getContactByNodeId.refetch();
    getStateWithCountryData.refetch();
  }, [selectedOption, structureAddressDataset]);

  useEffect(() => {
    getStateWithCountryData.refetch();
  }, [destinationAddress]);

  useEffect(() => {
    getFreightOrdersByShipment.refetch();
  }, [toastMessage]);

  const getCourierServiceTypes = (data: NodeAttributesReponse[]) => {
    const serviceTypes = [];
    for (const item of data) {
      item.propertyTypeCode === NodeAttributeType.CourierServiceTypes && serviceTypes.push({
        value: item.value.code,
        label: item.value.name
      });
    }
    setServiceTypes(serviceTypes);
  };

  const onPackageSelect = (selectedPackages: SelectedShipmentPackage[]) => {
    setIntegratedFreightShippingData({ ...integratedFreightShippingData, selectedPackages: selectedPackages });
  };

  const getOrdersData = () => {
    const mainOrder = getFreightOrdersByShipment.data?.find((item) => !item.isReturnOrder);
    const returnedOrder = getFreightOrdersByShipment.data?.find((item) => item.isReturnOrder);

    const ordersData = {
      mainId: mainOrder?.id,
      mainOrderId: mainOrder?.orderId,
      mainOrderNumber: mainOrder?.orderNumber,
      mainOrderShowArchived: mainOrder?.showArchived,
      returnedId: returnedOrder?.id,
      returnedOrderId: returnedOrder?.orderId,
      returnedOrderNumber: returnedOrder?.orderNumber,
      returnedOrderShowArchived: returnedOrder?.showArchived
    };
    
    return ordersData;
  };

  const onSaveOrder = async() => {
    const shippingMethod = serviceTypes.find((item) => item.value === integratedFreightShippingData.serviceType)?.label;
    const phoneNumber = getContactByNodeId.data?.find((item) => item.propertyTypeCode === NodeAttributeType.Contact)?.value.phone1;
    const stateCode = getStateWithCountryData.data?.shortName;

    const validateErrors = checkIntegratedFreightErrors('saveOrder', destinationAddress as AddressDataType, integratedFreightShippingData.serviceType, phoneNumber, stateCode);
    if (validateErrors !== '') {
      setToastMessage(validateErrors);
      setOpenToast(true);
      return;
    }

    const packagesList: { weight: number; height: number; width: number; length: number }[] = [];
    integratedFreightShippingData.selectedPackages.map((value) => {
      for (let i = 0; i < value.quantity; i++) {
        packagesList.push({
          weight: value.weight,
          height: value.height/100,
          width: value.width/100,
          length: value.length/100
        });
      }
    });
    
    const dispatchDetail = {
      shipmentId: shipment.id,
      reference: FreightOrderReference.OnlineOrder,
      shipping_method: shippingMethod,
      shippingProviderId: integratedFreightShippingData.shippingProviderId,
      signature_required: !integratedFreightShippingData.isAuthToLeave,
      dangerous_goods: integratedFreightShippingData.isSafeDrop,
      destination: {
        name: destinationAddress?.name || destinationAddress?.placeName,
        phone: destinationAddress?.contactNumber || phoneNumber,
        street: destinationAddress?.line1 || destinationAddress?.line2,
        suburb: destinationAddress?.suburb,
        state: stateCode,
        post_code: destinationAddress?.postCode,
        country: destinationAddress?.countryName,
        delivery_instructions: integratedFreightShippingData.deliveryNote
      },
      packages: packagesList
    };
    
    await createFreightOrder.mutateAsync(dispatchDetail);
    setToastMessage('Successfully created freight order');
    setOpenToast(true);
  };

  const onQuote = async() => {
    const stateCode = getStateWithCountryData.data?.shortName;

    const validateErrors = checkIntegratedFreightErrors('quote', destinationAddress as AddressDataType, '', '', stateCode);
    if (validateErrors !== '') {
      setToastMessage(validateErrors);
      setOpenToast(true);
      return;
    }

    const packagesList: { weight: number; height: number; width: number; length: number }[] = [];
    integratedFreightShippingData.selectedPackages.map((value) => {
      for (let i = 0; i < value.quantity; i++) {
        packagesList.push({
          weight: value.weight,
          height: value.height/100,
          width: value.width/100,
          length: value.length/100
        });
      }
    });

    const quoteData = {
      street: destinationAddress?.line1 || destinationAddress?.line2,
      suburb: destinationAddress?.suburb,
      city: destinationAddress?.city,
      state: stateCode,
      post_code: destinationAddress?.postCode?.toString(),
      country_code: CountryCode.Australia,
      packages: packagesList,
      return_order: false
    };
    
    const quoteOrder: QuoteFreightOrderTypeResponse[] = await quoteFreightOrder.mutateAsync(quoteData) as QuoteFreightOrderTypeResponse[];

    if (quoteOrder) {
      setQuoteData(quoteOrder);
      setOpenModal(true);
    }
  };

  const onArchiveOrder = async() => {
    const archiveDetails = {
      id: getOrdersData().mainId,
      shipmentId: shipment.id,
      freightIntegrator: FreightIntegrator.StarShipIt,
      orderNumber: getOrdersData().mainOrderNumber,
      orderId: getOrdersData().mainOrderId,
      isReturnOrder: false
    };

    await archiveFreightOrder.mutateAsync(archiveDetails);
    setToastMessage('Successfully archived freight order');
    setOpenToast(true);
  };

  const onArchiveReturnOrder = async() => {
    const archiveDetails = {
      id: getOrdersData().returnedId,
      shipmentId: shipment.id,
      freightIntegrator: FreightIntegrator.StarShipIt,
      orderNumber: getOrdersData().returnedOrderNumber,
      orderId: getOrdersData().returnedOrderId,
      isReturnOrder: true
    };

    await archiveFreightOrder.mutateAsync(archiveDetails);
    setToastMessage('Successfully archived return order');
    setOpenToast(true);
  };

  const onReturnOrder = async() => {
    const returnOrderDetails = {
      shipmentId: shipment.id,
      freightIntegrator: FreightIntegrator.StarShipIt,
      orderNumber: getOrdersData().mainOrderNumber,
      orderId: getOrdersData().mainOrderId
    };

    await returnFreightOrder.mutateAsync(returnOrderDetails);
    setToastMessage('Successfully created return order');
    setOpenToast(true);
  };

  return (
    <Table size="medium">
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography>Courier</Typography>
            {freightEnabledCouriers.map((courier) => 
              <Button 
                key={courier.id} 
                variant={integratedFreightShippingData.shippingProviderId === courier.id ? ButtonVariantProps.Primary : ButtonVariantProps.Secondary} 
                color={ButtonColorProps.Success}
                onClick={() => setIntegratedFreightShippingData({ ...integratedFreightShippingData, serviceType: '', shippingProviderId: courier.id })}
              >
                {courier.name}
              </Button>)}
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" justifyItems="center" direction="row" spacing={1}>
            <Typography>Service Type</Typography>
            <SelectMenu
              id="service-type-select"
              label={serviceTypes.length ? 'Select Service Type' : ''}
              disabled={!serviceTypes.length}
              items={serviceTypes}
              selectedValue={integratedFreightShippingData?.serviceType}
              minWidth="90%"
              onChange={(value) => {
                setIntegratedFreightShippingData({ ...integratedFreightShippingData, serviceType: value });
              }}
            />
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" justifyItems="center" direction="row" spacing={1}>
            <Typography>Table Layout for Packages</Typography>
            <ShipmentPackagesList freightTypes={freightPackageTypes} customPackageTypes={CUSTOM_PACKAGE_TYPES} onChange={onPackageSelect}/>
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" justifyItems="center" direction="row" spacing={1}>
            <Typography>Delivery Note</Typography>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              value={integratedFreightShippingData?.deliveryNote}
              onChange={(value) => {
                setIntegratedFreightShippingData({ ...integratedFreightShippingData, deliveryNote: value });
              }}
            />
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <Stack direction="row" mb={1} justifyContent="space-around">
          <Stack direction="row" alignItems="center">
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={integratedFreightShippingData?.isSafeDrop}
              onChange={(event) => {
                setIntegratedFreightShippingData({ ...integratedFreightShippingData, isSafeDrop: event.target.checked });
              }}
            /> SafeDrop
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={integratedFreightShippingData?.isAuthToLeave}
              onChange={(event) => {
                setIntegratedFreightShippingData({ ...integratedFreightShippingData, isAuthToLeave: event.target.checked });
              }}
            /> Authority to Leave
          </Stack>
          <Stack direction="row" alignItems="center">
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={integratedFreightShippingData?.isCustomAddress}
              onChange={(event) => {
                setIntegratedFreightShippingData({ ...integratedFreightShippingData, isCustomAddress: event.target.checked });
              }}
            /> Custom Address
          </Stack>
        </Stack>
      </TableRow>
      {!integratedFreightShippingData?.isCustomAddress &&
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" justifyItems="center" direction="row" spacing={1}>
            {destinationAddressOptions.length > 0 && 
            <SelectMenu
              label="Destination Address"
              id="destination-address"
              items={destinationAddressOptions}
              disabled={false}
              selectedValue={selectedOption}
              onChange={(value) => setSelectedOption(value)}
            />
            }
          </Stack>
        </StyledTableCell>
      </TableRow>
      }
      {integratedFreightShippingData.isCustomAddress &&
      <TableRow>
        <StructureAddressContactLayout
          heading="Address"
          isAddress={true}
          isOneAddress={true}
          renderNotes={false}
          contactType={contactType}
          addressDataset={structureAddressDataset || []}
          contactDataset={[]}
          contactFormData={[]}
          addressFormData={addressData}
          editAddressFormData={editAddressData}
          enableContactNumber
          onClickAddressEdit={(event, index) => {
            const selectedValue: AddressDataType[] = structureAddressDataset.splice(index, 1);
            setStructureAddressDataset(structureAddressDataset);
            setEditAddressData(selectedValue[0]);
          }}
          onClickAddressDelete={(event, index) => {
            const updatedData = [...structureAddressDataset];
            if (index > -1) updatedData[index].isActive = false;
            setStructureAddressDataset(updatedData);
          }}
          onStructureAddressChange={(value) => {
            setAddressData(value);
          }}
          handleAddressCreate={(index) => {
            if (index > -1) addressData[index].isActive = true;
            if(addressData[index].isManual) {
              addressData[index] = { ...addressData[index], googleAddress: '' };
            }
            setStructureAddressDataset([...structureAddressDataset, addressData[index]]);
            addressData.splice(index, 1);
            setAddressData([...addressData]);
          }}
          addressForm={addressForm}
          setAddressForm={setAddressForm}
          addFormEnabledByDefault
        />
      </TableRow>
      }
      <TableRow>
        <Stack key={toastMessage} justifyContent="flex-start" direction="row">
          { !getOrdersData().mainOrderShowArchived ?
            <Stack ml={18}>
              <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Success} onClick={onSaveOrder}>
              Save
              </Button>
            </Stack> :
            <Stack ml={18}>
              <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Error} onClick={onArchiveOrder}>
                Archive Order
              </Button>
            </Stack>
          }
          { !getOrdersData().returnedOrderShowArchived && getOrdersData().mainOrderShowArchived &&
            <Stack ml={2}>
              <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Success} onClick={onReturnOrder}>
                Return Order
              </Button>
            </Stack>
          }
          { getOrdersData().returnedOrderShowArchived && 
            <Stack ml={2}>
              <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Error} onClick={onArchiveReturnOrder}>
                Archive Return Order
              </Button>
            </Stack>
          }
          <Stack ml={2}>
            <Button disabled={!integratedFreightShippingData.selectedPackages.length} variant={ButtonVariantProps.Primary} color={ButtonColorProps.Warning} onClick={onQuote}>
              Quote
            </Button>
          </Stack>
          { getOrdersData().mainOrderShowArchived &&
          <Stack ml={2}>
            <PrintLabel entityId={getOrdersData().mainId || NaN} entityType={PrintLabelEntityTypes.FreightOrderLabel} buttonLabel="Print Connote"></PrintLabel>
          </Stack> 
          }
          { getOrdersData().returnedOrderShowArchived && 
          <Stack ml={2}>
            <PrintLabel entityId={getOrdersData().returnedId || NaN} entityType={PrintLabelEntityTypes.FreightOrderLabel} buttonLabel="Print Return Connote"></PrintLabel>
          </Stack>
          }
        </Stack>
      </TableRow>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ContentWrapperBox>
          <CloseIcon handleClose={() => setOpenModal(false)} />
          <HeadingWrapper variant={TypographyVariantProps.H4}>Quote</HeadingWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left"><BoldLabel>Carrier</BoldLabel></StyledTableCell>
                <StyledTableCell align="left"><BoldLabel>Service Code</BoldLabel></StyledTableCell>
                <StyledTableCell align="left"><BoldLabel>Total Price($)</BoldLabel></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quoteData.map((row) => {
                return (
                  <TableRow key={row.carrier_name}>
                    <StyledTableCell align="center">
                      <Typography>{row.carrier}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{row.service_code}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{row.total_price}</Typography>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Footer>
            <Button variant={ButtonVariantProps.Secondary} onClick={() => setOpenModal(false)}>Close</Button>
          </Footer>
        </ContentWrapperBox>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        message={toastMessage}
        onClose={() => { setOpenToast(false); }}
      />
      {(createFreightOrder.isLoading || archiveFreightOrder.isLoading || returnFreightOrder.isLoading || quoteFreightOrder.isLoading) &&
        <Grid position="absolute" left="50%" bottom="50%"><Loader color={LoaderColorProps.Primary} /></Grid>
      }
    </Table>
  );
};