import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import { QueryOption } from '../@types';
import { createNotificationTemplate, getNotificationTemplateById, getNotificationTemplatesPaginated, getSendGridTemplates, updateNotificationTemplate } from '../api/notificationTemplate';
import { NotificationTemplateKey, SendGridTemplateKey } from '../configs/notificationTemplate';
import { NotificationTemplate, NotificationTemplatePaginateListResponse, SendGridTemplate } from '../@types/notificationTamplate.type';

export const useGetAllNotificationTemplatesPaginated = (queryOption: QueryOption): UseQueryResult<NotificationTemplatePaginateListResponse> => {
  return useQuery([NotificationTemplateKey, queryOption.page, queryOption.pageSize], () => getNotificationTemplatesPaginated(queryOption));
};

export const useGetNotificationTemplate = (id: number): UseQueryResult<NotificationTemplate> => {
  return useQuery([NotificationTemplateKey, id], () => id !== 0 && getNotificationTemplateById(id), { enabled: false });
};

export const useCreateNotificationTemplate = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createNotificationTemplate(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([NotificationTemplateKey]);
    },
  });
};

export const useUpdateNotificationTemplate = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    await updateNotificationTemplate(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([NotificationTemplateKey]);
    },
  });
};

export const useGetSendGridTemplates = (): UseQueryResult<SendGridTemplate[]> => {
  return useQuery([SendGridTemplateKey], () => getSendGridTemplates());
};