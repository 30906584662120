import React, { useState, SyntheticEvent } from 'react';
import { Grid, Stack } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { LocationMergeAccordianFragmentProps } from './LocationMergeAccordianFragment.props';
import { LocationMergeLiveSearch } from '../../../../components/molecules/LocationMergeLiveSearch';
import { StructureMergeDecisionData, StructureMergeLocationNodeData } from '../../../../@types';
import { 
  LOCATION_MERGE_DROPDOWN_ITEMS,
  LOCATION_MERGE_WARNING_MESSAGE,
  STRUCTUE_MERGE_DECISION_SAMPLE_DATA,
  STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA 
} from '../../../../constants/structure';
import { StyledSelectMenu } from './LocationMergeAccordianFragment.styles';
import { COLORS } from '../../../../configs/colors';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { hasErrorInMerge } from '../../../../utils/structure';
import { useMergeStructure } from '../../../../queries/structure-query';
import { Snackbar } from '../../../../components/atoms/Snackbar';

export const LocationMergeAccordianFragment: React.FC<LocationMergeAccordianFragmentProps> = ({
  mergeNodeId,
  nodeTypeCode
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [location, setLocation] = useState<StructureMergeLocationNodeData>(STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA);
  const [locationMergeDecision, setLocationMergeDecision] = useState<StructureMergeDecisionData>(STRUCTUE_MERGE_DECISION_SAMPLE_DATA);
  const [validate, setValidate] = useState(false);
  
  const isError = hasErrorInMerge(location, locationMergeDecision.addressesDecision, locationMergeDecision.contactsDecision, locationMergeDecision.tradingHoursDecision);

  const mergeStructure = useMergeStructure();
  
  const handleCancel = () => {
    setLocation(STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA);
    setLocationMergeDecision(STRUCTUE_MERGE_DECISION_SAMPLE_DATA);
    setIsCancel(true);
  };

  const handleSave = async() => {
    locationMergeDecision.mergeNodeId = mergeNodeId;
    locationMergeDecision.currentNodeId = location.id;

    if (isError) {
      setValidate(true);
      return;
    }

    await mergeStructure.mutateAsync(locationMergeDecision);
    setOpen(true);

    setLocation(STRUCTURE_MERGE_LOCATION_NODE_SAMPLE_DATA);
    setLocationMergeDecision(STRUCTUE_MERGE_DECISION_SAMPLE_DATA);
    setIsCancel(true);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  return (
    <Accordian title={
      <Grid container alignItems="center">
        <Grid item spacing={1} alignItems="center" sm container display="flex">
          <Grid item>
            <Typography>Location Merge</Typography>
          </Grid>
        </Grid>
      </Grid>
    }>
      <Stack direction="column" alignItems="center" spacing={2}>
        <LocationMergeLiveSearch mergeNodeId={mergeNodeId} nodeTypeCode={nodeTypeCode} onChange={setLocation} isCancel={isCancel} setCancel={setIsCancel} />
        {!!location?.id && (
          <Typography textColor={COLORS.Red}>{LOCATION_MERGE_WARNING_MESSAGE}</Typography>
        )}
        {location?.canHaveAddress && (
          <StyledSelectMenu
            required={true}
            id="addresses"
            labelId="addresses"
            label="Addresses"
            selectedValue={locationMergeDecision.addressesDecision}
            onChange={(val) => {
              setLocationMergeDecision({
                ...locationMergeDecision,
                addressesDecision: val,
              });
            }}
            items={LOCATION_MERGE_DROPDOWN_ITEMS}
            validate={validate}
            optionalLabelEnabled={true}
          />
        )}
        {location?.canHaveContact && (
          <StyledSelectMenu
            required={true}
            id="contacts"
            labelId="contacts"
            label="Contacts"
            selectedValue={locationMergeDecision.contactsDecision}
            onChange={(val) => {
              setLocationMergeDecision({
                ...locationMergeDecision,
                contactsDecision: val,
              });
            }}
            items={LOCATION_MERGE_DROPDOWN_ITEMS}
            validate={validate}
            optionalLabelEnabled={true}
          />
        )}
        {location?.canHaveTradingHours && (
          <StyledSelectMenu
            required={true}
            id="trading-hours"
            labelId="trading-hours"
            label="Trading Hours"
            selectedValue={locationMergeDecision.tradingHoursDecision}
            onChange={(val) => {
              setLocationMergeDecision({
                ...locationMergeDecision,
                tradingHoursDecision: val,
              });
            }}
            items={LOCATION_MERGE_DROPDOWN_ITEMS}
            validate={validate}
            optionalLabelEnabled={true}
          />
        )}
      </Stack>
      {!!location?.id && (
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} mt={2}>
          <Button color={ButtonColorProps.Error} onClick={handleCancel}>Cancel</Button>
          <Button color={ButtonColorProps.Primary} onClick={handleSave}>Save</Button>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Structure Merged Successfully!"
            action={action}
          />
        </Stack>
      )}
    </Accordian>
  );
};