import { styled } from '@mui/material/styles';
import { Grid, TableRow } from '@mui/material';
import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

export const RoleBox = styled(Box)`
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const StructureAddressAddGrid = styled(Grid)`
  margin-top: 4px;
`;

export const TableGridContainer = styled(Grid)`
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom:10px;
`;

export const StyledTableRow = styled(TableRow)`
  &:last-child td, &:last-child th { border: 0 }
`;