import React, { useState } from 'react';
import { Box, Tab, Tabs as MaterialTabs } from '@mui/material';
import { TabPanel } from '../../atoms/TabPanel';
import { TabsProps, TextColorProps } from './Tabs.props';
import { a11yProps } from './Tabs.utils';
import { COLORS } from '../../../configs/colors';

export const Tabs: React.FC<TabsProps> = ({
  name,
  tabTitles,
  children,
  textColor = TextColorProps.Primary,
  isCustomColor = false,
  TabIndicatorProps,
  onTabChange
}) => {
  const [value, setValue] = useState(0);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MaterialTabs
          textColor={textColor}
          TabIndicatorProps={TabIndicatorProps}
          value={value}
          onChange={(_, val) => {
            setValue(val);
            onTabChange && onTabChange(tabTitles[val]);
          }}
          aria-label={`${name}-tabs-panel`}
        >
          {tabTitles.map((title, index) => <Tab sx={{ backgroundColor: isCustomColor && index === value ? COLORS.LightGreen : COLORS.White }} key={index} label={title} {...a11yProps(name, index)} />)}
        </MaterialTabs>
      </Box>
      {children.filter(Boolean).map((node, index) => <TabPanel key={index} value={value} index={index}>{node}</TabPanel>)}
    </Box>
  );
};