import React, { SyntheticEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { LocationMapAccordianProps } from './LocationMapAccordian.props';
import { GOOGLE_MAPS_API_KEY } from '../../../configs/envs';
import { LocationGrid, ContactTypography } from './LocationMapAccordian.styles';
import { Accordian } from '../../atoms/Accordian';
import { GOOGLE_MAP_API_URL, GOOGLE_MAP_URL } from '../../../configs/url-constants';
import { OtherServiceList } from '../OtherServiceList';
import { Button, ButtonVariantProps, ButtonSizeProps, ButtonColorProps } from '../../atoms/Button';
import { Link } from '../../atoms/Link';
import { AddressContactType, NodeAttributeType } from '../../../configs/enums';
import { getServiceLocationAccordionAddress } from '../../../utils/services';

export const LocationMapAccordian:React.FC<LocationMapAccordianProps> = ({
  otherServiceData,
  nodeAttributes,
  serviceAttributes,
  bulkEditServiceDropDownsDataset,
  bulkUpdateToastMessage,
  parentServiceId,
  serviceAddress,
  handleBulkUpdateSave,
  setIsEdit,
  expandOnMobile,
  otherServicesLoading
}) => {
  const [expanded, setExpanded] = useState(expandOnMobile);
  const [isShown, setIsShown] = useState(false);
  
  const mainAddress = nodeAttributes?.find((item) => item.propertyTypeCode === NodeAttributeType.Address && item.value.addressType === AddressContactType.Main)?.value;
  const logisticsAddress = nodeAttributes?.find((item) => item.propertyTypeCode === NodeAttributeType.Address && item.value.addressType === AddressContactType.Logistics)?.value;
  const mainContact = nodeAttributes?.find((item) => item.propertyTypeCode === NodeAttributeType.Contact && item.value.contactType === AddressContactType.Main)?.value;
  const logisticsContact = nodeAttributes?.find((item) => item.propertyTypeCode === NodeAttributeType.Contact && item.value.contactType === AddressContactType.Logistics)?.value;
  const tradingHours = nodeAttributes?.filter((item) => item.propertyTypeCode === NodeAttributeType.TradingHours);
  const serviceContact = serviceAttributes?.find((item) => item.code === NodeAttributeType.Contact && item.value.contactType === AddressContactType.Main)?.value;
  const src = `${GOOGLE_MAP_API_URL}/api/staticmap?center=${mainAddress?.googleAddress || ''}&zoom=11&size=180x35&markers=size:mid%7Ccolor:red%7C${mainAddress?.googleAddress || ''}&key=${GOOGLE_MAPS_API_KEY}`;
  const mapPath = `${GOOGLE_MAP_URL}?q=${mainAddress?.googleAddress || ''}`;

  return (
    <>
      <Accordian
        expanded={expanded} 
        onChange={(event: SyntheticEvent, newExpanded: boolean) => setExpanded(newExpanded)}
        title={expanded ?
          <Grid container alignItems="center" sm>
            <Grid item mr={7}>
              <Typography>Location</Typography>
            </Grid>
            <Grid item>
              <img src={src} onClick={() => window.open(mapPath, '_blank')} />
            </Grid>
          </Grid>
          :
          <>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item alignItems="center" sm container>
                <Grid item mr={3}>
                  <Typography>Location</Typography>
                </Grid>
                {(serviceAddress || mainAddress) ? 
                  <Grid item mr={4}>
                    <Typography variant={TypographyVariantProps.Body1}>
                      {getServiceLocationAccordionAddress(serviceAddress || mainAddress)}
                    </Typography>
                  </Grid>
                  : ''
                }
                <Grid item>
                  <img src={src} onClick={()=> window.open(mapPath, '_blank')}/>
                </Grid>
              </Grid>
              {(serviceContact || mainContact) ?
                <Grid item mr={3}>
                  <Link>
                    <Typography>{serviceContact?.phone1 || mainContact?.phone1}</Typography>
                  </Link>
                </Grid>
                : ''
              }
            </Grid>
          </>
        }>
        <LocationGrid container spacing={6}>
          <Grid item xs={12} md={4}>
            <Grid item mb={1}>
              <Typography fontWeight={TypographyFontWeightProps.Bold}>Main Address</Typography>
              {mainAddress && (
                <>
                  <Typography>{mainAddress?.placeName}</Typography>
                  <Typography>{mainAddress?.line1}</Typography>
                  <Typography>{`${mainAddress?.suburb} ${mainAddress?.stateName} ${mainAddress?.postCode}`}</Typography>
                </>
              )}
              {mainContact && (
                <>
                  {mainContact?.phone1 && <ContactTypography>Phone1: {`${mainContact?.phone1}`}</ContactTypography>}
                  {mainContact?.phone2 && <Typography>Phone2: {`${mainContact?.phone2}`}</Typography>}
                </>
              )}
            </Grid>
            <Grid item mb={1} display={logisticsAddress ? '' :'none'}>
              <Typography fontWeight={TypographyFontWeightProps.Bold} variant={TypographyVariantProps.Body2}>Logistics Address</Typography>
              {logisticsAddress && (
                <>
                  <Typography variant={TypographyVariantProps.Body2}>{logisticsAddress?.placeName}</Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{logisticsAddress?.line1}</Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{`${logisticsAddress?.suburb} ${logisticsAddress?.stateName} ${logisticsAddress?.postCode}`}</Typography>
                </>
              )}
              {logisticsContact && (
                <>
                  {logisticsContact?.phone1 && <ContactTypography>Phone1: {`${logisticsContact?.phone1}`}</ContactTypography>}
                  {logisticsContact?.phone2 && <Typography>Phone2: {`${logisticsContact?.phone2}`}</Typography>}
                </>
              )}
            </Grid>
            <Grid item mb={1} display={tradingHours ? '' :'none'}>
              <Typography fontWeight={TypographyFontWeightProps.Bold}>Trading Hours</Typography>
              {tradingHours?.map((item) => <Typography key={item.id}>{item.value.type}: {item.value.start} - {item.value.end}</Typography>)}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            {serviceAddress ? 
              <Grid 
                item mb={1} 
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <Grid container direction="row">
                  <Typography fontWeight={TypographyFontWeightProps.Bold}>Service Address</Typography>
                  {isShown &&
                  <Grid item position="absolute" ml={18}>
                    <Button
                      variant={ButtonVariantProps.Icon}
                      size={ButtonSizeProps.Small}
                      onClick={() => setIsEdit && setIsEdit(true)}
                    >
                      <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
                    </Button>
                  </Grid>
                  }
                </Grid>
                {serviceAddress && (
                  <>
                    <Typography>{serviceAddress?.placeName}</Typography>
                    <Typography>{serviceAddress?.line1}</Typography>
                    <Typography>{`${serviceAddress?.suburb} ${serviceAddress?.stateName} ${serviceAddress?.postCode}`}</Typography>
                  </>
                )}
                {serviceContact && 
                  <>
                    {serviceContact?.phone1 && <ContactTypography>Phone1: {`${serviceContact?.phone1}`}</ContactTypography>}
                    {serviceContact?.phone2 && <Typography>Phone2: {`${serviceContact?.phone2}`}</Typography>}
                  </>
                }
              </Grid> 
              :
              <Grid
                item mb={1} 
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)}
              >
                <Grid container direction="row">
                  <Typography fontWeight={TypographyFontWeightProps.Bold}>[Service] Address</Typography>
                  {isShown &&
                  <Grid item position="absolute" ml={18}>
                    <Button
                      variant={ButtonVariantProps.Icon}
                      size={ButtonSizeProps.Small}
                      onClick={() => setIsEdit && setIsEdit(true)}
                    >
                      <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
                    </Button>
                  </Grid>
                  }
                </Grid>
                {mainAddress && (
                  <>
                    <Typography>{mainAddress?.placeName}</Typography>
                    <Typography>{mainAddress?.line1}</Typography>
                    <Typography>{`${mainAddress?.suburb} ${mainAddress?.stateName} ${mainAddress?.postCode}`}</Typography>
                  </>
                )}
                {mainContact && (
                  <>
                    {mainContact?.phone1 && <ContactTypography>Phone1: {`${mainContact?.phone1}`}</ContactTypography>}
                    {mainContact?.phone2 && <Typography>Phone2: {`${mainContact?.phone2}`}</Typography>}
                  </>
                )}
              </Grid>
            }
            <Grid item mt={2}>
              <Typography fontWeight={TypographyFontWeightProps.Bold}>Other Services</Typography>
              <OtherServiceList 
                data={otherServiceData || []}
                bulkEditServiceDropDownsDataset={bulkEditServiceDropDownsDataset}
                bulkUpdateToastMessage={bulkUpdateToastMessage}
                handleBulkUpdateSave={handleBulkUpdateSave}
                parentServiceId={parentServiceId}
                otherServicesLoading={otherServicesLoading}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item mb={2}>
              <Typography fontWeight={TypographyFontWeightProps.Bold}>Sublocation Parts</Typography>
            </Grid>
            <Grid item mb={2}>
              <Typography fontWeight={TypographyFontWeightProps.Bold}>Parent Location Parts</Typography>
            </Grid>
          </Grid>
        </LocationGrid> 
      </Accordian>
    </>
  );
};