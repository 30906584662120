import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { NonSerializedReceiveShipmentManifestData, ShipmentRecieveType } from '../../../@types/shipment.type';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { NonSerialisedReceiveShipmentManifestItemProps } from '.';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Typography } from '../../atoms/Typography';
import { GridColDef, GridRenderCellParams, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { useReciveShipmentsSearchNodes } from '../../../queries/live-search-query';
import { DataGrid } from '../../atoms/DataGrid';
import { GridContainer } from './NonSerialisedReceiveShipmentManifestItem.styles';

export const NonSerialisedReceiveShipmentManifestItem: React.FC<NonSerialisedReceiveShipmentManifestItemProps> = ({
  nonSerializedShipmentManifestItemData,
  selectedNonSerializedShipmentManifestItem,
  onChange,
  onChangeSelectedData,
  textBoxClick,
  setTextBoxClick,
  isLoading,
  selectionModel,
  setSelectionModel
}) => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [selectedRowIds, setSelectedRowIds] = useState<Array<number>>([]);
  const [autoHeight, setAutoHeight] = useState<boolean>(true);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const reciveShipmentsSearchNodes = useReciveShipmentsSearchNodes();

  useEffect(() => {
    if (nonSerializedShipmentManifestItemData.length > 7) { 
      setAutoHeight(false);
    } else {
      setAutoHeight(true);
    }
  }, [nonSerializedShipmentManifestItemData]);

  useEffect(() => {
    if (selectedNonSerializedShipmentManifestItem.length > 0) {
      const selectedIds = selectedNonSerializedShipmentManifestItem.map((selectedItem) => selectedItem.id);
      setSelectionModel(nonSerializedShipmentManifestItemData.filter((manifestItem) => selectedIds.includes(manifestItem.id) ).map((manifest) => manifest.id));
    }
  }, [selectedNonSerializedShipmentManifestItem]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);
  
  useEffect(() => {
    if (selectedRowIds.length > 0) {
      const updatedData: Array<NonSerializedReceiveShipmentManifestData> = nonSerializedShipmentManifestItemData.filter((nonSerializedShipmentManifestItem: NonSerializedReceiveShipmentManifestData) => selectedRowIds.some((selectedRowId: number) => selectedRowId === parseInt(nonSerializedShipmentManifestItem.id.toString())));
      onChangeSelectedData(updatedData, selectedRowIds);
    } else {
      onChangeSelectedData([], selectedRowIds);
    }
  }, [selectedRowIds]);

  const onSelectionModelChange = (selectedRows: GridSelectionModel) => {
    setSelectionModel(selectedRows);
    setSelectedRowIds(selectedRows.toString().split(',').map(Number)[0] === 0 ? [] : selectedRows.toString().split(',').map(Number));
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0,
      hide: true,
      valueGetter: (params: GridRenderCellParams) => params.row.id
    },
    {
      field: 'partTypeDetails',
      headerName: '',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack alignItems="flex-start">
          <Typography>Part Type Id: <b>{params.row.partTypeId}</b></Typography>
          <Typography>Part Type Name: <b>{params.row.partTypeName}</b></Typography>
          <Typography>Part Type No: <b>{params.row.partTypeNumber}</b></Typography>
        </Stack>
      )
    },
    {
      field: 'shipmentId',
      headerName: 'Shipment ID',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.shipmentId
    },
    {
      field: 'conditionName',
      headerName: 'Condition',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.conditionName
    },
    {
      field: 'fleetTagName',
      headerName: 'Fleet Tag',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.fleetTagName
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <TextField
          type={TextFieldTypeProps.Number}
          label="QTY"
          inputProps={{ min: 0, max: params.row.maxQty }}
          value={params.row.qty}
          onChange={(text) => {
            const updatedData = [...nonSerializedShipmentManifestItemData];
            const index = updatedData.findIndex(obj => obj.id == params.row.id);
            updatedData[index].qty = Number(text);
            onChange(updatedData);
          }}
        />
      )
    },
    ... isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions) ? [
      {
        field: 'putAwayDestination',
        headerName: 'Put Away Destination',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams) => (
          <Grid width="100%">
            <LiveSearchBox
              title="Select a Location"
              timeOffset={400}
              value={params.row.putAwayLocationName}
              onClearValue={() => {
                const updatedData = [...nonSerializedShipmentManifestItemData];
                const index = updatedData.findIndex(obj => obj.id == params.row.id);
                updatedData[index].putAwayLocationName = '';
                updatedData[index].putAwayLocationId = NaN;
                onChange(updatedData);
              }}
              onClick={() => setTextBoxClick(true)}
              defaultSuggetions={params.row.serialisedSuggestions}
              onChange={(value) => {
                const updatedData = [...nonSerializedShipmentManifestItemData];
                const index = updatedData.findIndex(obj => obj.id == params.row.id);
                updatedData[index].putAwayLocationName = value.name;
                updatedData[index].putAwayLocationId = value.id;
                onChange(updatedData);
              }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onApiInvoke={async (name: string) => {
                if (textBoxClick && name) {
                  return await reciveShipmentsSearchNodes.mutateAsync({
                    id: params.row.shipmentId,
                    recieveType: ShipmentRecieveType.Individual,
                    name
                  });
                } else {
                  return [];
                }
              }}
              isOptionEqualToValue={(option, value) => {
                value = option;
                return option.id === value.id;
              }}
            />
          </Grid>
        )
      }
    ] : []
  ];

  return (
    <GridContainer autoHeight={autoHeight}>
      <DataGrid
        columns={columns}
        rows={nonSerializedShipmentManifestItemData}
        getRowId={(row) => row?.id}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        checkboxSelection={true}
        getRowHeight={() => 'auto'}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        loading={isLoading}
        autoHeight={autoHeight}
      />
    </GridContainer>
  );
};