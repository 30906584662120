import React, { useState, useEffect, useRef } from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Label, MicroText, StyledStack } from './SelectMenu.styles';
import { SizeProps } from '../../../@types';
import { ComponentMenuItem, SelectMenuProps } from './SelectMenu.props';
import { Typography, TypographyVariantProps } from '../Typography';
import { COLORS } from '../../../configs/colors';
import { SupplierLabel } from '../SupplierLabel/SupplierLabel';
import { dropdownVerifiedMessage } from '../../../utils/common';

export const SelectMenu: React.FC<SelectMenuProps> = ({
  className,
  size = SizeProps.Small,
  id,
  label,
  items,
  minWidth = '100%',
  labelId,
  selectedValue,
  defaultSelectedValue,
  onChange,
  title,
  required,
  disabled,
  isFocused,
  readOnly,
  optionalLabelEnabled = false,
  helperTextEnabled = true,
  validate = false,
  optionalLabel
}) => {
  const selectRef = useRef<HTMLElement>();
  const [menuWidth, setMenuWidth] = useState<number | string>(minWidth);
  const [open, setOpen] = useState(false);
  const [internalHelperText, setHelperText] = useState<string>('');
  
  const trimmedLabel = label?.replace('*', '');
  const optionalItem: ComponentMenuItem[] = [{ value: '-1', label: optionalLabel || `Select ${trimmedLabel}` }];
  const menuItems = optionalLabelEnabled ? optionalItem.concat(items) : items;

  useEffect(() => {
    if ((selectedValue?.toString() === '' || selectedValue?.toString() === '-1') && validate) {
      setHelperText(dropdownVerifiedMessage(selectedValue.toString(), `${trimmedLabel} field` || 'Field'));
    } else {
      setHelperText('');
    }
  }, [selectedValue, validate]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setMenuWidth(selectRef.current?.clientWidth || minWidth);
  }, [selectRef]);

  useEffect(() => {
    isFocused && setOpen(isFocused);
  }, [isFocused]);

  return (
    <FormControl sx={{ minWidth }} size={size} error={!!internalHelperText} className={className}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        ref={selectRef}
        required={required}
        labelId={labelId}
        id={id}
        value={selectedValue}
        defaultValue={defaultSelectedValue}
        autoWidth
        disabled={disabled}
        label={label}
        onChange={(event: SelectChangeEvent) => onChange && onChange(event.target.value)}
        renderValue={(value) => {
          const item = menuItems.find((item: any) => item.value == value);

          if (item?.supplierLabel) {
            return (
              <SupplierLabel supplierData={item?.supplierLabel} />
            );
          }

          if (typeof item?.label === 'string') {
            return (
              <Grid container sx={{ whiteSpace: 'normal' }}>
                <Label>{title}</Label>
                {item?.label || 'Not Selected'}
              </Grid>
            );
          } else {
            return (
              <>{item?.label}</>
            );
          }
        }}
        readOnly={readOnly}
      >
        {menuItems.map(({ value, label, secondaryLabel, addressLabel, supplierLabel, navigateLink, disabled }) =>
          <MenuItem
            style={{ width: menuWidth }}
            key={value}
            value={value}
            disabled={disabled}
            className={className}
            divider
            sx={{ whiteSpace: 'normal' }}
          > 
            {value === 'addNewPricing' ? (
              <Link href={navigateLink} target="_blank" rel="noopener noreferrer">{label}</Link>
            ) : secondaryLabel ? (
              <Stack>
                <Typography>{label}</Typography>
                <MicroText>{secondaryLabel}</MicroText>
              </Stack>
            ) : addressLabel ? (
              <StyledStack direction="column">
                <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{addressLabel.suburb}</Typography> 
                <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{addressLabel.line1}</Typography>
                {addressLabel.line2 && <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{addressLabel.line2},</Typography>}
                <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{addressLabel.stateName},</Typography>
                <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{addressLabel.postCode},</Typography>
                <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{addressLabel.countryName}</Typography>
                <MicroText>Address Type: {addressLabel.addressType}</MicroText>
              </StyledStack>
            ) : supplierLabel ? (
              <SupplierLabel supplierData={supplierLabel} />
            ) : (
              <Typography>{label}</Typography>)
            }
          </MenuItem>
        )}
      </Select>
      {internalHelperText && helperTextEnabled && <FormHelperText>{internalHelperText}</FormHelperText>}
    </FormControl>
  );
};