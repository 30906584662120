import React from 'react';
import Grid from '@mui/material/Grid';
import { Chip, ChipVariantProps } from '../../atoms/Chip';
import { TagCellProps, TagCellItem } from './TagCell.props';

export const TagCell: React.FC<TagCellProps> = ({
  tags,
}) => {
  return (
    <Grid container spacing={0.5}>
      {tags.map((item: TagCellItem, index: number) => (
        <Grid key={index} item xs="auto">
          <Chip 
            variant={ChipVariantProps.Outlined} 
            label={item.title} 
            backgroundColor={item.isSystem ? `${item.color}44` : '#FFFFFF'} 
            borderColor={item.color} 
          />
        </Grid>
      ))}
    </Grid>
  );
};
