import React, { ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField } from '../../atoms/TextField';
import { SwitchGrid } from './SoftwareFirmwareHardware.styles';
import { SoftwareFirmwareHardwareProps } from './SoftwareFirmwareHardware.props';

export const SoftwareFirmwareHardware: React.FC<SoftwareFirmwareHardwareProps> = ({
  value,
  onChange,
  renderNotes,
  parentComponentTestId = ''
}) => {
  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <Grid item xs={3}>
        <TextField
          fullWidth
          required
          label="Version"
          value={value.version}
          onChange={(text) => onChange({ ...value, version: text })}
          inputProps={{
            'data-testid': `${parentComponentTestId}-version`
          }}
        />
      </Grid>
      {renderNotes && <Grid item xs={7}>
        <TextField
          fullWidth
          label="Description"
          multiline
          rows={1}
          value={value.description}
          onChange={(text) => onChange({
            ...value,
            description: text
          })}
          inputProps={{
            'data-testid': `${parentComponentTestId}-description`
          }}
        />
      </Grid>}
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isActive: event.target.checked })} />
          }
          label="Is Active"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Grid>
  );
};