export const USER_ERROR_MESSAGES = {
  EMAIL_VERIFIED: 'Please varify your email before access to the system.',
  BLOCKED: 'Account inactive.  Please contact your Amtek representative for assistance.',
  UNKNOWN: 'Account not configured to access Amtek. Please contact your system administrator for assistance',
  RETRY: 'Please try to login again'
};

export const USER_ERROR_SCENARIOS = {
  ACCESS_DENIED: 'Access denied.',
  CONSENT_ERROR: 'Unable to correlate completed consent.',
  BLOCKED_ERROR: 'user is blocked',
  INVALID_STATE: 'Invalid state'
};