import { Invoice } from '../../../@types';

export enum InvoiceType {
  Internal = 'internal',
  External = 'external'
}

export interface ServiceBillingData {
  billingReviewed: boolean;
  billableType: string;
  clientInvoiced: boolean;
  billingNotes: string;
  privateNotes: string;
  externalInvoices: Invoice[];
  internalInvoices: Invoice[];
}

export interface ServiceBillingProps {
  billingData: ServiceBillingData;
  onChange: (serviceBillingData: ServiceBillingData) => void;
  onSave: (serviceBillingData: ServiceBillingData) => void;
  onAddForm?: (value: Invoice[]) => void;
}