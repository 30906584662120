import { GridSortModel } from '@mui/x-data-grid-pro';
import { httpClient, HttpMethod } from './http-client';
import { ServiceTypeResponse, ServiceType } from '../@types/service.type';
import { isNotArrayEmpty } from '../utils/array-utils';

export const createServiceType = (nodeType: ServiceTypeResponse) => {
  return httpClient('/serviceTypes', HttpMethod.Post, nodeType);
};

export const updateServiceType = (code: string, nodeType: ServiceTypeResponse) => {
  return httpClient(`/serviceTypes/${code}`, HttpMethod.Put, nodeType);
};

export const getServiceTypeByCode = (code: string) => {
  return httpClient(`/serviceTypes/${code}`, HttpMethod.Get, null);
};

export const getServiceTypeByCodeForServiceUpdate = (code: string) => {
  return httpClient(`/serviceTypes/${code}/serviceUpdate`, HttpMethod.Get, null);
};

export const getServiceTypes = (sortModel?: GridSortModel): any => {
  let url = '/serviceTypes';
  if (sortModel && isNotArrayEmpty(sortModel, 0)) {
    url += `?order_by=${sortModel[0]?.field}&order_direction=${sortModel[0]?.sort}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const getServiceTypeAvailableStatuses = (serviceTypes: Array<ServiceType>) => {
  return httpClient('/serviceTypes/nextStatuses', HttpMethod.Post, serviceTypes);
};