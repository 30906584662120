import React, { ChangeEvent } from 'react';
import { Grid } from '@mui/material';
import { SelectMenu } from '../../atoms/SelectMenu';
import { WarrantyProps } from './Warranty.props';
import { SwitchGrid } from './Warranty.styles';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { WarrantyDurationTypes, WarrantyTriggerTypes } from '../../../@types/partType.type';

export const Warranty: React.FC<WarrantyProps> = ({
  value,
  onChange,
}) => {
  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <Grid item>
        <TextField
          fullWidth
          required
          label="Warranty Name"
          value={value.name}
          onChange={(text) => onChange({ ...value, name: text })}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          required
          type={TextFieldTypeProps.Number}
          inputProps={{ min: 1 }}
          label="Warranty Duration"
          value={value.term?.duration}
          onChange={(text) => onChange({ ...value, term: { ...value.term, duration: text } })}
        />
      </Grid>
      <Grid item>
        <SelectMenu
          required
          minWidth="220px"
          id="duration-type"
          label="Duration Type*"
          items={WarrantyDurationTypes}
          selectedValue={value.term?.type}
          onChange={(text) => onChange({ ...value, term: { ...value.term, type: text } })}
          optionalLabelEnabled={true}
        />
      </Grid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.autoTrigger}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, autoTrigger: event.target.checked })} />
          }
          label="Auto Trigger"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
      <Grid item xs="auto">
        <SelectMenu
          required
          minWidth="210px"
          id="trigger-type"
          label="Trigger Type*"
          items={WarrantyTriggerTypes}
          selectedValue={value.triggerTypeCode}
          onChange={(text) => onChange({ ...value, triggerTypeCode: text })}
          optionalLabelEnabled={true}
        />
      </Grid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isActive: event.target.checked })} />
          }
          label="Is Enabled"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Grid>
  );
};
