import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { ServiceCommonType, ProcessType, ServiceAttendanceObject, ServiceObject, EstimatedTimeOfArrivalQuery, ServicesNodesAttributes, ServiceBillingInfo } from './../@types/service.type';
import { ActionType, BillingType } from '../@types/service.type';
import { 
  createService,
  getActionTypes, 
  getAllServices, 
  getBulkServicesByIds, 
  getProcessTypes, 
  getServiceActionProcessTypes, 
  getOtherServicesUnderMainLocation, 
  updateServiceById,
  getLinkedServices,
  updateParentService,
  updateReportedInformationByServiceId,
  getAllServiceAttendance,
  updateBillingInfo,
  getServiceById,
  getServicesUnderNodeId,
  updateServiceNodeAllocation,
  getEstimatedTimeOfArrival,
  getServicesNodesLocationRelatedAttributes,
  createOrUpdateServiceAttributes,
  getBillingTypes,
  getBillingInfo,
  updateBulkServiceList,
  updateBulkServiceEditList
} from '../api/services';
import { LinkedServiceData, OtherServiceData, ServiceAccordianData, ServiceListResponse } from '../@types';
import { ServiceTypeKey } from '../configs/service-type';
import {
  AppActionTypesKey,
  AppLinkedServicesKey,
  AppProcessTypesKey,
  AppServiceActionProcessTypesKey,
  AppServicesKey,
  GetServiceKey,
  OtherServicesKey,
  ServiceAttendanceKey,
  ServiceBulkUpdateKey,
  ServiceAccordianKey,
  GetEstimatedTimeOfArrivalKey,
  ServicesNodesAttributesKey,
  ServiceBillingTypesKey,
  ServiceLogAllKey,
  ServiceBillingInfoKey
} from '../configs/services';
import { NodesAllocationByService } from '../configs/structures';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const useAddService = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await createService(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppServicesKey]);
    },
  });
};

export const useGetActionTypes = (): UseQueryResult<[ActionType]> => {
  return useQuery([AppActionTypesKey], () => getActionTypes(), {
    select: (response: any) => response,
    staleTime: 2 * 60 * 60 * 1000
  });
};

export const useGetProcessTypes = (): UseQueryResult<[ProcessType]> => {
  return useQuery([AppProcessTypesKey], () => getProcessTypes(), {
    select: (response: any) => response,
    staleTime: 2 * 60 * 60 * 1000
  });
};

export const useGetServiceActionProcessTypes = (activeOnly?: boolean): UseQueryResult<ServiceCommonType> => {
  return useQuery([AppServiceActionProcessTypesKey], () => getServiceActionProcessTypes(activeOnly), {
    select: (response: any) => response,
    staleTime: 2 * 60 * 60 * 1000
  });
};

export const useGetServicePaginatedData = (queryOption: any, columnSelectionItems: ColumnSelectionItem[]): UseQueryResult<ServiceListResponse> => {
  return useQuery([AppServicesKey, queryOption], () => getAllServices(queryOption, columnSelectionItems), { cacheTime: 0, enabled: false });
};

export const useUpdateService = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateServiceById(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppServicesKey]);
      await queryClient.invalidateQueries([GetServiceKey, id]);
      await queryClient.invalidateQueries([NodesAllocationByService, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
    },
  }); 
};

export const useGetServiceById = (id: number): UseQueryResult<ServiceObject> => {
  return useQuery([GetServiceKey, id], () => id && getServiceById(id), {
    refetchOnWindowFocus: false, //https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
    select: (response: any) => response
  });
};

export const useUpdateReportedInformation = (): UseMutationResult => {
  return useMutation((data: any) => updateReportedInformationByServiceId(data.id, data)); 
};

export const useUpdateBulkServiceList = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => await updateBulkServiceList(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppServicesKey]);
      await queryClient.invalidateQueries([ServiceBulkUpdateKey]);
    },
  });
};

export const useUpdateBulkServiceEditList = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => await updateBulkServiceEditList(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppServicesKey]);
      await queryClient.invalidateQueries([ServiceBulkUpdateKey]);
      await queryClient.invalidateQueries([ServiceLogAllKey]);
      await queryClient.invalidateQueries([GetServiceKey]);
    },
  });
};

export const useGetBulkServicesByIds = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await getBulkServicesByIds(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceBulkUpdateKey]);
    },
  });
};

export const useGetOtherServicesUnderMainLocation = (nodeId: number): UseQueryResult<[OtherServiceData]> => {
  return useQuery({
    queryKey: [OtherServicesKey], 
    queryFn: async () => getOtherServicesUnderMainLocation(nodeId),
    select: (response: any) => response,
    cacheTime: 0, 
    enabled: false
  });
};

export const useGetLinkedServices = (serviceId: number): UseQueryResult<LinkedServiceData> => {
  return useQuery([AppLinkedServicesKey, serviceId], () => serviceId && getLinkedServices(serviceId));
};

export const useUpdateParentService = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateParentService(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppServicesKey]);
      await queryClient.invalidateQueries([AppLinkedServicesKey, serviceId]);
    },
  }); 
};

export const useGetAllServiceAttendance: any = (serviceId: number): UseQueryResult<[ServiceAttendanceObject]> => {
  return useQuery([ServiceAttendanceKey, serviceId], () => getAllServiceAttendance(serviceId));
};

export const useUpdateBillingInfo = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation((data: any) => updateBillingInfo(serviceId, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceTypeKey, serviceId]);
    },
  }); 
};

export const useGetServicesUnderNodeId = (nodeId: number, includeChildRecords: boolean): UseQueryResult<ServiceAccordianData[]> => {
  return useQuery([ServiceAccordianKey, nodeId], () => getServicesUnderNodeId(nodeId, includeChildRecords), {
    select: (response: any) => response
  });
};

export const useUpdateServiceAllocation = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateServiceNodeAllocation(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([GetServiceKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
    },
  });
};

export const useGetEstimatedTimeOfArrival = ( queryOption: EstimatedTimeOfArrivalQuery): UseQueryResult<string> => {
  return useQuery([GetEstimatedTimeOfArrivalKey], () => getEstimatedTimeOfArrival(queryOption), { enabled: false });
};

export const useGetServicesNodesLocationRelatedAttributes = (serviceId: number, mainLocationNodeId: number, subLocationNodeId: number): UseQueryResult<ServicesNodesAttributes> => {
  return useQuery([ServicesNodesAttributesKey, serviceId], () => serviceId && getServicesNodesLocationRelatedAttributes(serviceId, mainLocationNodeId, subLocationNodeId), { enabled: false });
};

export const useCreateOrUpdateServiceAttributes = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createOrUpdateServiceAttributes(data);
  }, {
    onSuccess: async (mutationResponse, variables) => {
      await queryClient.invalidateQueries([ServicesNodesAttributesKey, parseInt(variables?.serviceId)]);
      await queryClient.invalidateQueries([GetServiceKey, parseInt(variables?.serviceId)]);
      await queryClient.invalidateQueries([NodesAllocationByService, parseInt(variables?.serviceId)]);
    },
  });
};

export const useGetServiceBillingTypes = (): UseQueryResult<[BillingType]> => {
  return useQuery([ServiceBillingTypesKey], () => getBillingTypes(), {
    select: (response: any) => response
  });
};

export const useGetBillingInfo= (serviceId: number): UseQueryResult<ServiceBillingInfo> => {
  return useQuery([ServiceBillingInfoKey, serviceId], () => serviceId && getBillingInfo(serviceId), { enabled: false });
};