import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { TypographyVariantProps } from '../../atoms/Typography';
import { MultiClientSelectorProps } from './MultiClientSelector.props';
import { ContentWrapperBox, HeadingWrapper } from './MultiClientSelector.styles';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { NodeType } from '../../../@types';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';
import { ANY_KEYWORD } from '../../../constants/common';

export const MultiClientSelector: React.FC<MultiClientSelectorProps> = ({
  open,
  onClose,
  clients,
  contracts,
  brands,
  handleParentSelection,
  onChangeNodeType,
  onChange,
  selectedMultiClients,
  selectedMultiContracts,
  selectedMultiBrands,
  hasBrandSelection,
  isMandotory
}) => {
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  useEffect(() => {
    selectedMultiClients && setSelectedClients(selectedMultiClients);
    selectedMultiContracts && setSelectedContracts(selectedMultiContracts);
    selectedMultiBrands && setSelectedBrands(selectedMultiBrands);
  }, [selectedMultiClients, selectedMultiContracts, selectedMultiBrands]);

  useEffect(() => {
    onChange && onChange(selectedClients, selectedContracts, selectedBrands, getClientHierarchy());
  }, [selectedClients, selectedContracts, selectedBrands]);

  const onSelectParent = () => {
    handleParentSelection(selectedClients, selectedContracts, getClientHierarchy(), selectedBrands);
    onChange && onChange(selectedClients, selectedContracts, selectedBrands);
    onClose();
  };

  const getClientHierarchy = () => {
    const clientString = clients.reduce((accumulator: string[], currentValue: any) => {
      if (selectedClients.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');
    const contractString = contracts.reduce((accumulator: string[], currentValue: any) => {
      if (selectedContracts.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');
    const brandString = brands?.reduce((accumulator: string[], currentValue: any) => {
      if (selectedBrands.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');

    if (hasBrandSelection) {
      if (isMandotory) {
        return clientString ? `${clientString} ${contractString ? `-> ${contractString}` : '-> Any'} ${brandString ? `-> ${brandString}` : '-> Any'}` : '';
      } else {
        return clientString ? `${clientString} ${contractString && `-> ${contractString}`} ${brandString && `-> ${brandString}`}` : '';
      }
    } else {
      if (isMandotory) {
        return clientString ? `${clientString} ${contractString ? `-> ${contractString}` : '-> Any'}` : '';
      } else {
        return clientString ? `${clientString} ${contractString && `-> ${contractString}`}` : '';
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={onClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Client/Contract{hasBrandSelection && '/Brand'} Selection
        </HeadingWrapper>
        <Grid container width="100%" gap={2}>
          <Grid item xs>
            <MultiSelectCheckmarksMenu
              label="Select Clients"
              id="page-size-menu"
              items={selectedClients.includes(ANY_KEYWORD) ? clients.filter((item) => item.value === ANY_KEYWORD) : clients}
              selectedValue={selectedClients}
              onChange={(value) => {
                const values = value.split(',');
                setSelectedClients(value === '' ? [] : values);
                if (values.includes(ANY_KEYWORD)) {
                  setSelectedClients([ANY_KEYWORD]);
                  setSelectedContracts([ANY_KEYWORD]);
                  setSelectedBrands([ANY_KEYWORD]);
                }
                if (values.length > 1){
                  setSelectedContracts([ANY_KEYWORD]);
                  setSelectedBrands([ANY_KEYWORD]);
                }
                if (value === ''){
                  setSelectedContracts([]);
                  setSelectedBrands([]);
                }
                onChangeNodeType && onChangeNodeType(NodeType.Contract, value);
              }}
            />
          </Grid>
          <Grid item xs>
            <MultiSelectCheckmarksMenu
              label="Select Contracts"
              id="page-size-menu"
              items={selectedContracts.includes(ANY_KEYWORD) ? contracts.filter((item) => item.value === ANY_KEYWORD) : contracts}
              disabled={selectedClients.length === 0 || selectedClients.length > 1 || selectedClients.includes(ANY_KEYWORD)}
              selectedValue={selectedContracts}
              onChange={(value) => {
                const values = value.split(',');
                setSelectedContracts(value === '' ? [] : values);
                if (values.includes(ANY_KEYWORD)) {
                  setSelectedContracts([ANY_KEYWORD]);
                  setSelectedBrands([ANY_KEYWORD]);
                }
                if (values.length > 1) setSelectedBrands([ANY_KEYWORD]);
                if (value === '') setSelectedBrands([]);
                onChangeNodeType && onChangeNodeType(NodeType.Brand, value);
              }}
            />
          </Grid>
          {hasBrandSelection && brands &&
            <Grid item xs>
              <MultiSelectCheckmarksMenu
                label="Select Brands"
                id="page-size-menu"
                items={selectedBrands.includes(ANY_KEYWORD) ? brands.filter((item) => item.value === ANY_KEYWORD) : brands}
                disabled={selectedContracts.length === 0 || selectedContracts.length > 1 || selectedContracts.includes(ANY_KEYWORD)}
                selectedValue={selectedBrands}
                onChange={(value) => {
                  const values = value.split(',');
                  setSelectedBrands(value === '' ? [] : values);
                  if (values.includes(ANY_KEYWORD)) {
                    setSelectedBrands([ANY_KEYWORD]);
                  }
                }}
              />
            </Grid>
          }
        </Grid>
        <Grid container mt={2} justifyContent="flex-end" gap={2}>
          <Grid>
            <Button variant={ButtonVariantProps.Secondary} onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid>
            <Button variant={ButtonVariantProps.Primary} onClick={onSelectParent}>Select</Button>
          </Grid>
        </Grid>
      </ContentWrapperBox>
    </Modal>
  );
};