import React, { useEffect, useState } from 'react';
import { SolutionActionMenuItem } from '../../../../../../components/molecules/SolutionActionMenu';
import { SolutionActionForm } from '../../../../../../components/organisms/SolutionActionForm';
import { SolutionActionFormFragmentProps } from './SolutionActionFormFragment.props';
import { useGetPartHistoryByServiceIdAndHistoryTypeCodes } from '../../../../../../queries/part-query';
import { PartActionHistoryDataType } from '../../../../../../@types/partHistory.type';
import { PART_HISTORY_TYPES_FOR_SOLUTION_ACTION } from '../../../../../../constants/part';

export const SolutionActionFormFragment: React.FC<SolutionActionFormFragmentProps> = ({
  service,
  value,
  onChange,
  isError
}) => {
  const [options, setOptions] = useState<Array<SolutionActionMenuItem>>([]);
  const partHistoryListQuery = useGetPartHistoryByServiceIdAndHistoryTypeCodes(
    service.id, 
    PART_HISTORY_TYPES_FOR_SOLUTION_ACTION as string[]
  );

  useEffect(() => {
    if (partHistoryListQuery.data) {
      const logs: Array<PartActionHistoryDataType> = partHistoryListQuery.data;
      const optionList: Array<SolutionActionMenuItem> = logs.map((obj) => {
        return {
          value: obj.id.toString(),
          actionName: obj.historyTypeName,
          partType: obj.partTypeName,
          note: obj.notes
        };
      });
      setOptions(optionList);
    }
  }, [partHistoryListQuery.data]);

  return (
    <SolutionActionForm 
      options={options} 
      value={value} 
      onChange={onChange}
      isError={isError}
    />
  );
};