import React, { MouseEvent, useEffect, useState } from 'react';
import { Grid, Table, TableRow, IconButton } from '@mui/material';
import { CloseRounded, Add, Delete } from '@mui/icons-material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { StyledTableCell } from '../../molecules/SiteSchemaRow/SiteSchemaRow.styles';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { AddStockManifestRowItemProps, AddStockManifestRowItemValueType } from './AddStockManifestRowItem.props';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { useGetAddStockShipmentManifestPricing } from '../../../queries/shipment-query';
import { ShipmentManifestSuppliers } from '../../../@types/shipment.type';
import { SelectMenu } from '../../atoms/SelectMenu';
import { SizeProps } from '../../../@types';
import { getShipmentSupplierPricings } from '../../../utils/shipment';

export const AddStockManifestRowItem: React.FC<AddStockManifestRowItemProps> = ({
  isNSPartType,
  value,
  isRemovable,
  index,
  serialValues,
  isPending,
  onChange,
  onSerialsClick,
  onAttributesClick,
  onCancel,
  onDelete
}) => {
  const [data, setData] = useState<AddStockManifestRowItemValueType>(value);
  const [supplierPricingData, setSupplierPricingData] = useState<Array<ShipmentManifestSuppliers>>([]);
  const [isPriceActive, setIsPriceAvtive] = useState<boolean>(false);

  const getAddStockShipmentManifestPricing = useGetAddStockShipmentManifestPricing(value.partTypeId);

  useEffect(() => {
    getAddStockShipmentManifestPricing.data && setSupplierPricingData(getAddStockShipmentManifestPricing.data);
  }, [getAddStockShipmentManifestPricing.data]);

  useEffect(() => {
    value && setData(value);
  }, [value]);

  const onClickSerials = (event: MouseEvent<HTMLButtonElement>, partTypeId: number, id: number, serialValues: any) => {
    onSerialsClick && onSerialsClick(event, partTypeId, id, serialValues);
  };

  const onClickAttributes = (event: MouseEvent<HTMLButtonElement>, partTypeId: number, id: number) => {
    onAttributesClick && onAttributesClick(event, partTypeId, id);
  };

  return (
    <Grid container>
      <Table>
        <TableRow>
          <StyledTableCell width="auto">
            <b>{index}</b>
          </StyledTableCell>
          <StyledTableCell width="15%">
            <Typography variant={TypographyVariantProps.Caption}>Part Type Id</Typography><br/><b>{value.partTypeId}</b>
          </StyledTableCell>
          <StyledTableCell width="20%">
            <Typography variant={TypographyVariantProps.Caption}>Part Type Name</Typography><br/><b>{value.partTypeName}</b>
          </StyledTableCell>
          <StyledTableCell width="15%">
            <Typography variant={TypographyVariantProps.Caption}>Part Type No</Typography><br/><b>{value.partTypeNo}</b>
          </StyledTableCell>
          {!isNSPartType && <StyledTableCell width="auto">
            <Button variant={ButtonVariantProps.Primary} startIcon={<Add />} onClick={(e) => onClickSerials(e, value.partTypeId, value?.id || 0, serialValues)}>
              SERIALS
            </Button>
          </StyledTableCell>}
          {isNSPartType && <StyledTableCell width="auto">
            <Button variant={ButtonVariantProps.Primary} startIcon={<Add />} onClick={(e) => onClickAttributes(e, value.partTypeId, value?.id || 0)}>
              ATTRIBUTES
            </Button>
          </StyledTableCell>}
          <StyledTableCell width="auto">
            <Button variant={ButtonVariantProps.Primary} startIcon={<Add />} onClick={() => isPriceActive ? setIsPriceAvtive(false) : setIsPriceAvtive(true)}>
              PRICES
            </Button>
          </StyledTableCell>
          {isPriceActive && 
            <StyledTableCell width="20%">
              <SelectMenu
                required={false}
                disabled={isPending}
                minWidth="100%"
                size={SizeProps.Small}
                id="demo-simple-select-price"
                labelId="demo-simple-select-price-label"
                label="Supplier Prices"
                selectedValue={value?.additionalData?.supplierPriceId || '-1'}
                onChange={(supplierPriceId) => {
                  onChange({
                    ...value,
                    additionalData: {
                      ...value.additionalData,
                      supplierPriceId: supplierPriceId === 'addNewPricing' ? undefined : supplierPriceId,
                      unitPrice: supplierPriceId === 'addNewPricing' ? undefined : supplierPricingData.find((supplierPricingItem) => supplierPricingItem.id === parseInt(supplierPriceId))?.itemPrice
                    }
                  });
                }}
                items={getShipmentSupplierPricings(supplierPricingData, value.partTypeId)}
                optionalLabelEnabled={true}
                optionalLabel="No Pricing"
              />
            </StyledTableCell>
          }
          {isNSPartType && 
            <StyledTableCell width="15%">
              <TextField
                disabled={isPending}
                fullWidth
                type={TextFieldTypeProps.Number}
                label="QTY"
                inputProps={{ min:0 }}
                value={data?.quantity}
                onChange={async (text) => {
                  setData({ ...value, quantity: parseInt(text) });
                  onChange({ ...value, quantity: parseInt(text) });
                }}
              />
            </StyledTableCell>
          }
          {isNSPartType ? 
            <StyledTableCell width="20%">
              Total Price: <b>{(value.quantity || 0) * (value?.additionalData?.unitPrice || 0)}</b>
            </StyledTableCell>
            : 
            <>
              <StyledTableCell width="10%">
                Total Qty: <b>{value.total || 0}</b>
              </StyledTableCell>
              <StyledTableCell width="10%">
                Total Price: <b>{(value.total || 0) * (value?.additionalData?.unitPrice || 0)}</b>
              </StyledTableCell>
            </>
          }
          {isRemovable && <StyledTableCell width="50px">
            <IconButton color="error" onClick={onCancel}>
              <CloseRounded /> 
            </IconButton>
          </StyledTableCell>}
          {!isRemovable && <StyledTableCell width="50px">
            <IconButton disabled={isPending} color="error" onClick={() => onDelete && onDelete(isNSPartType, value)}>
              <Delete/> 
            </IconButton>
          </StyledTableCell>}
        </TableRow>
      </Table>
    </Grid>
  );
};