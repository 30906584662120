import { DropdownItem } from '../components/atoms/SelectMenu';
import { AutocompleteTagType } from '../components/organisms/Tags';
import { NodeType } from './structure.type';
import { Tag } from './tag.type';
import { FleetTags } from './tlc.type';

export interface PartType {
  id: number;
  name: string;
  number: string;
  manufacturerNodeId: number;
  manufacturerNodeName: string;
  categoryCode: string;
  applicableNodeIds: number[];
  notes: string;
  isActive: boolean;
  tags: Array<Tag>;
  selectedClient: string;
  selectedContract: Array<string>;
  serialised: string;
  partTypeTree: string;
  fleetTag: FleetTags[];
  selectedParentId: string;
  configurationChangeNotes: string;
  selectedParentHierarchy: string;
  selectedTags: Array<AutocompleteTagType | string>;
  partTypeCategoryList: DropdownItem[];
  warranties: Array<WarrantyData>;
  softwares: Array<SoftwareFirmwareHardwareData>;
  firmwares: Array<SoftwareFirmwareHardwareData>;
  hardwares: Array<SoftwareFirmwareHardwareData>;
  salePricings: Array<SalePricingObject>;
  repairPricings: Array<SalesRepairSupplierData>;
  supplierPricings: Array<SalesRepairSupplierData>;
  billOfMaterials: Array<BillOfMaterialType>;
}

export interface PartTypeValidator {
  isEmptyName: boolean;
  isEmptyClientContract: boolean;
  isEmptyManufacturer: boolean;
  openPartTypeSuccessToast: boolean;
  isActiveChangeNotes: boolean;
  openParentSelector: boolean;
  isCategoryEmpty: boolean;
  showWarningToast: boolean;
  openImage: boolean;
  configurationChangeNotesHelperText: string;
  warningMessage: string;
}

export interface PartTypeParentSelector {
  clients: DropdownItem[];
  contracts: DropdownItem[];
  queryNodeType: NodeType;
}

export interface PartTypeParentSelector {
  clients: DropdownItem[];
  contracts: DropdownItem[];
  queryNodeType: NodeType;
}

export interface ConfigurationHistoryData {
  partTypeId: number;
  notes: string;
  configurationChangeNotes?: string;
}

export enum ConfigurationHistoryField {
  Name = 'name',
  Number = 'number',
  ManufacturerNodeId = 'manufacturerNodeId',
  Client = 'client',
  Contracts = 'contracts',
  Notes = 'notes',
  IsActive = 'isActive',
}

export interface ConfigurationHistoryList {
  id: number;
  partTypeId: number;
  createdBy: string;
  createdAt: string;
  notes: string;
  configurationChangeNotes: string;
}

export const WarrantyTriggerTypes = [
  { label: 'Creation', value: 'creation' },
  { label: 'First Installation', value: 'firstinstallation' },
  { label: 'None', value: 'none' },
];

export const WarrantyDurationTypes = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
  { label: 'Years', value: 'years' },
];

export interface WarrantyTerm {
  duration: string;
  type: string;
}

export interface WarrantyData {
  id?: number;
  partTypeId?: number;
  name: string;
  term: WarrantyTerm;
  autoTrigger: boolean;
  triggerTypeCode: string;
  isActive: boolean;
}

export interface SoftwareFirmwareHardwareData {
  id?: number;
  version: string;
  partTypeId?: number;
  type?: string;
  description?: string;
  isActive: boolean;
  isNew?: boolean;
}

export enum SFHwareTypeEnum {
  Software = 'sw',
  Firmware = 'fw',
  Hardware = 'hw'
}

export enum SFHwareHeaderEnum {
  Software = 'Software',
  Firmware = 'Firmware',
  HardwareRevision = 'Hardware Revision'
}

export interface SalePricingObject {
  id: number;
  nodeIds: string;
  regionType: string;
  name: string;
  price: number;
  isDefault: boolean;
  isActive: boolean;
}

export interface SalesRepairSupplierData {
  id?: number | string;
  partTypeId?: number;
  nodeName: string;
  quantity: string;
  isDefault: boolean;
  isActive: boolean;
  description?: string;
  nodeId?: number;
  price?: string;
  cost?: string;
  partTypeName?: string;
  partTypeNumber?: string;
  pricePerPackage?: string;
  isNew?: boolean;
}

export interface SalesRepairSupplierRecallType {
  name: string;
}

export interface BillOfMaterialType {
  childPartTypeId?: number;
  childPartTypeName: string;
  serialised: string;
  quantity: number;
}

export interface ChildPartTypeData {
  childPartTypeId: number;
  quantity: number;
}

export interface ClientContractData {
  clientId: number;
  contractId?: number;
}

export interface SoftwareFirmwareHardwareOptions {
  softwares: Array<SoftwareFirmwareHardwareData>;
  firmwares: Array<SoftwareFirmwareHardwareData>;
  hardwares: Array<SoftwareFirmwareHardwareData>;
}

export interface PartTypeLiveSearchItem {
  id: number;
  name: string;
  notes: string;
  number: string;
  applicableNodeIds: string;
  categoryCode: string;
  manufacturerNodeId: number;
  createdAt: string;
  updatedAt: string;
  createdBy: number;
  updatedBy: number;
  isActive: boolean;
}

export enum PartTypeActionLiveSearchItemCategoryCode {
  SerialiseDevice = 'serialised-device',
  SerialiseComponent = 'serialised-component',
  NonSerialisedComponent = 'non-serialised-component',
  Consumable = 'consumable'
}

export enum PartTypeActionLiveSearchSerialiseType {
  Serialised = 'serialised',
  NonSerialised = 'non-serialised'
}

export interface PartOrPartTypeSearchData {
  name: string;
  serialiseType?: PartTypeActionLiveSearchItemCategoryCode;
  partTypeId?: number;
  clientContractIds?: Array<number>;
  locationId?: number;
  allowedPartLocationType?: string;
  partActionType?: string;
  searchType?: string;
}

export interface PartCondition {
  name: string;
}

export interface PartTypeActionLiveSearchItem {
  name: string;
  partTypeId: number;
  partTypeName: string;
  categoryCode: PartTypeActionLiveSearchItemCategoryCode;
  partId?: number;
  ns_partId?:number;
  conditionCode: string;
  serial1: string;
  softwareVersion: string;
  hardwareVersion: string;
  firmwareVersion: string;
  fleetTagName: string;
  locationName: string;
  locationId: number;
  serviceId: number;
  isInSiblingHierarchy?: boolean;
  isInSiteHierarchy?: boolean;
  isUnderClientStructure?: boolean;
  searchItemType?: string;
  partCondition?: PartCondition;
  isPartCreation?:boolean;
}

export enum SerialisationType {
  Serialised = 'Serialised',
  Consumable = 'Consumable',
}

export interface PendingTransfersList {
  id: number;
  partTypeId: number;
  shipmentId: number;
  conditionName?: string;
  quantity: number;
  from: string;
  to: string;
  shipmentTypeCode: string;
  createdAt: string;
  createdBy: string;
}

export enum PartConditionName {
  Good = 'Good',
  Faulty = 'Faulty'
}

export enum PartConditionCode {
  DOA = 'doa'
}