import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, Stack, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { AddCircle, Clear, Delete, DoneOutlined } from '@mui/icons-material';
import {
  StyledBox,
  StyledGridContainer,
  StyledTableCell,
  StyledTotalTableCell
} from './InvoiceList.styles';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { InvoiceListProps } from './InvoiceList.props';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { Invoice } from '../../../@types';

export const InvoiceList: React.FC<InvoiceListProps> = ({
  heading,
  invoiceListValues,
  onChange,
  onDelete,
  onCreate,
  isInlineEdit,
  onAddForm
}) => {
  const [listData, setListData] = useState<Invoice[]>([]);
  const [formData, setFormData] = useState<Invoice[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    invoiceListValues.length > 0 && setListData(invoiceListValues);
  }, [invoiceListValues]);
  
  useEffect(() => {
    const totalValue = listData?.reduce((accumulator, value) => { 
      return value.isActive ? accumulator + parseFloat(value.amount) : accumulator;
    }, 0);
    totalValue >= 0 && setTotal(totalValue);
  }, [listData]);

  const addNewForm = () => {
    setFormData([...formData, { invoiceNo: '', amount: '', isActive: true }]);
  };

  const handleOnChange = (key: string, val: string, index: number) => {
    const newData = listData.map((obj, i) => index === i ? { ...obj, [key]: val } : obj);
    setListData(newData);
    onChange(newData);
  };

  const handleOnChange2 = (key: string, val: string, index: number) => {
    const newData = formData.map((obj, i) => index === i ? { ...obj, [key]: val } : obj);
    setFormData(newData);
    onAddForm && onAddForm(formData);
  };

  return (
    <StyledBox>
      <StyledGridContainer container>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant={TypographyVariantProps.H6}>{heading}</Typography>
          <IconButton aria-label="pdf" color="success" onClick={addNewForm}>
            <AddCircle/>
          </IconButton>
        </Stack>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {listData?.map((data, index) => 
                data.isActive && <TableRow key={index}>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      label="Invoice No"
                      value={data.invoiceNo}
                      onChange={(text) => handleOnChange('invoiceNo', text, index)}
                      disabled={isInlineEdit}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      type={TextFieldTypeProps.Number}
                      label="Amount"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      value={data.amount}
                      onChange={(text) => handleOnChange('amount', text, index)}
                      onBlur={() => handleOnChange('amount', parseFloat(data.amount).toFixed(2), index)}
                      disabled={isInlineEdit}
                    />
                  </StyledTableCell>
                  {!isInlineEdit && 
                    <StyledTableCell align="center" width="10%">
                      <IconButton aria-label="pdf" color="error" onClick={() => {
                        const newData = listData.map((obj, i) => index === i ? { ...obj, isActive: false } : obj);
                        setListData(newData);
                        onDelete(newData);
                      }}>
                        <Delete/>
                      </IconButton>
                    </StyledTableCell>
                  }
                </TableRow>)}
              {listData && <TableRow>
                <StyledTableCell colSpan={1}/>
                <StyledTotalTableCell align="right" colSpan={2}>Total: ${total.toFixed(2)}</StyledTotalTableCell>
              </TableRow>}
              {formData?.map((data, index) => 
                <TableRow key={index}>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      label="Invoice No"
                      value={data.invoiceNo}
                      onChange={(text) => handleOnChange2('invoiceNo', text, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      type={TextFieldTypeProps.Number}
                      label="Amount"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      value={data.amount}
                      onChange={(text) => handleOnChange2('amount', text, index)}
                      onBlur={() => handleOnChange2('amount', parseFloat(data.amount).toFixed(2), index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" width="15%">
                    <IconButton aria-label="pdf" color="success" onClick={() => {
                      if(data.invoiceNo && data.amount){
                        onCreate(formData[index]); 
                        const reducedArr = [...formData];
                        reducedArr.splice(index, 1);
                        setFormData(reducedArr);
                        onAddForm && onAddForm([]);
                      }
                    }}>
                      <DoneOutlined/>
                    </IconButton>
                    <IconButton aria-label="pdf" color="error" onClick={() => {
                      const reducedArr = [...formData];
                      reducedArr.splice(index, 1);
                      setFormData(reducedArr);
                      onAddForm && onAddForm([]);
                    }}>
                      <Clear/>
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGridContainer>
    </StyledBox>
  );
};