import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import MaterialBox, { BoxProps } from '@mui/material/Box';

export const MenuContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  max-width: 250px;
  border-radius: 5px;
`;

export const ItemBox = styled(MaterialBox)((props: BoxProps & { colorHex: string }) => `
  background-color: ${props.colorHex};
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  width: 15px;
  height: 15px;
`);