import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { PartSearchItemProps } from './PartSearchItem.props';
import { MicroText, StyledStack } from './PartSearchItem.styles';

export const PartSearchItem: React.FC<PartSearchItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.serial1}</Typography>
      <MicroText>{data.locationName}</MicroText>
      <MicroText>{`AM-PID-${data.id}`}</MicroText>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.partCondition?.name}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.partType?.name}</Typography>
    </StyledStack>
  );
};