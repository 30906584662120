import React, { useState } from 'react';
import { Stack } from '@mui/material';
import {
  SLAContactBox,
  ContactGridContainer,
  LetterAvatar,
  ReviewedLetterAvatar,
  GridInnerWrapper,
} from './SLAContactRow.styles';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { SLAContactRowProps, SLAOutcomeData } from './SLAContactRow.props';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { EditDateTimePicker } from '../../molecules/EditDateTimePicker';
import { formatDateOnly, formatDateSimple } from '../../../utils/common';
import { OutcomeTypeCode } from '../../../@types';

export const SLAContactRow: React.FC<SLAContactRowProps> = ({
  onChange,
  slaDetailsData,
  handleSLADateChange
}) => {
  const [isShown, setIsShown] = useState(false);

  const getBorderColor = () => {
    if (slaDetailsData.isBreached) {
      if (slaDetailsData.slaOutcomes?.length === 0) {
        return 'red';
      }
    }
    else if (slaDetailsData.isMet) {
      return 'green';
    }

    return 'white';
  };

  return (
    <SLAContactBox>
      <ContactGridContainer container spacing={GRID_SPACING} margin={0} width={'100%'} border={2} borderColor={getBorderColor()}>
        <GridInnerWrapper>
          <Stack direction="row" spacing={1} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
            {isShown && (
              <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>
                ID: {slaDetailsData.configId}
              </Typography>
            )}
            <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>
              {slaDetailsData.scopeName}: {slaDetailsData.configName}
            </Typography>
          </Stack>
          <Stack direction="row">
            {slaDetailsData.isBreached && (
              <Stack marginTop={'-5px'}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={slaDetailsData.isChecked}
                  onChange={(event) => onChange({
                    ...slaDetailsData,
                    isChecked: event.target.checked
                  })} />
              </Stack>
            )}
            <Stack direction="row">
              <EditDateTimePicker
                isEditable={!slaDetailsData.isChecked}
                value={formatDateSimple(slaDetailsData.loggedSLADateTime)}
                onChange={(val) => handleSLADateChange && handleSLADateChange({
                  ...slaDetailsData,
                  loggedSLADateTime: val
                })}
              />
              {slaDetailsData.isBreached && slaDetailsData.slaOutcomes?.some(outcome => outcome.outcomeTypeCode === OutcomeTypeCode.Mitigated) && (
                <LetterAvatar>
                  <Typography variant={TypographyVariantProps.Body2}><b>M</b></Typography>
                </LetterAvatar>
              )}
              {slaDetailsData.isBreached && slaDetailsData.slaOutcomes?.some(outcome => outcome.outcomeTypeCode === OutcomeTypeCode.Reviewed) && (
                <ReviewedLetterAvatar>
                  <Typography variant={TypographyVariantProps.Body2}><b>R</b></Typography>
                </ReviewedLetterAvatar>
              )}
            </Stack>
          </Stack>
          <Stack ml={4}>
            {slaDetailsData.slaOutcomes?.map((outcome: SLAOutcomeData, index) => (
              <Stack key={index} direction="row" mt={outcome.outcomeTypeCode === OutcomeTypeCode.Mitigated ? 1 : 0}>
                <Typography variant={TypographyVariantProps.Body2}>
                  {formatDateOnly(outcome.createdAt ?? '')} {outcome.outcomeTypeCode}: {outcome.reasonCategoryCode}, {outcome.notes}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </GridInnerWrapper>
      </ContactGridContainer>
    </SLAContactBox>
  );
};