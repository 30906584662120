import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';
import { Typography } from '../../../components/atoms/Typography';
import { Button } from '../../../components/atoms/Button';

export const ContentWrapperBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1800px;
  width: 80%;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;

export const SelectBox = styled(Box)`
  padding-right: 15px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 20px;
`;

export const SelectionWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 25px;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;