import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import { createImportSession, getImportSessionById, getImportSessionSummary, getImportedResourceBySessionId, updateImportSession, uploadResource } from '../api/imports';
import { ImportSessionKey, ImportSessionResourceKey, ImportSessionSummaryKey } from '../configs/imports';
import { ImportSession, ImportSessionQueryData, ImportSessionResource, ImportSessionResourceResponse } from '../@types';

export const useCreateImportSession= (type: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createImportSession(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ImportSessionKey, type, response.id]);
    },
  });
};

export const useGetImportSessionById= (type: string, sessionId: number, enabled: boolean): UseQueryResult<ImportSession> => {
  return useQuery([ImportSessionKey, type, sessionId], () => getImportSessionById(sessionId, type), { refetchInterval: 2000, enabled: enabled });
};

export const useGetImportedResourceBySessionId= (type: string, sessionId: number, enabled: boolean, queryData: ImportSessionQueryData): UseQueryResult<ImportSessionResourceResponse> => {
  return useQuery([ImportSessionResourceKey, type, sessionId], () => getImportedResourceBySessionId(sessionId, type, queryData), { refetchInterval: 2000, enabled: enabled });
};

export const useGetImportSessionSummary= (type: string, sessionId: number, enabled: boolean): UseQueryResult<Array<ImportSessionResource>> => {
  return useQuery([ImportSessionSummaryKey, type, sessionId], () => getImportSessionSummary(sessionId, type), { refetchInterval: 2000, enabled: enabled });
};

export const useUploadResource = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const formData = new FormData();
    const files = data.file;
    const type = data.type;
    formData.append('resourceImports', files);
    return await uploadResource(type, formData);
  });
};

export const useUpdateImportSession= (type: string): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await updateImportSession(data.id, data, type);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ImportSessionKey, type, response.id]);
    },
  });
};