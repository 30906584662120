import { styled } from '@mui/material/styles';
import { CustomPermission } from '../../../@types/permission.type';
import { Typography } from '../../atoms/Typography';
import { InlineGroup } from '../../atoms/InlineGroup';

export const Root = styled(InlineGroup)`
  display: flex;
  color: #ffff;
  margin: 8px 0 0 15px;
`;

export const PermissionTitle = styled(Typography) <CustomPermission>`
  padding: 2px 10px;
  border-radius: 8px;

  ${({ isGranted }) =>
    (isGranted && 'background-color: green;') ||
    (!isGranted && 'background-color: #FF6766;')
}
`;