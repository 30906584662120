import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridDensityTypes, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Checkbox } from '@mui/material';
import { DuplicatePartsPanelProps } from './DuplicatePartsPanel.props';
import { StyledWrapper, ContentWrapperBox, StyledButton } from './DuplicatePartsPanel.styles';
import { Accordian } from '../../atoms/Accordian';
import { DataGrid } from '../../atoms/DataGrid';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { Modal } from '../../atoms/Modal';
import { Snackbar } from '../../atoms/Snackbar';
import { formatDate } from '../../../utils/common';
import { DuplicatePartsDataType } from '../../../@types/part.type';
import { useMergeDuplicateParts } from '../../../queries/part-query';
import { disablePartsMergeButton } from '../../../utils/part';
import { Link } from '../../atoms/Link';
import { CloseIcon } from '../../atoms/CloseIcon';

export const DuplicatePartsPanel: React.FC<DuplicatePartsPanelProps> = ({
  duplicatePartsData,
  mainPartId
}) => {
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [mergePartList, setMergePartList] = useState<Array<DuplicatePartsDataType>>([]);

  const mergeDuplicateParts = useMergeDuplicateParts(mainPartId);

  useEffect(() => {
    duplicatePartsData && setMergePartList(duplicatePartsData);
  }, [duplicatePartsData]);

  const onChange = (duplicatePartsData: DuplicatePartsDataType, mergeAllow: boolean) => {
    const mergeList = [ ...mergePartList ];
    const updatedList = mergeList.map((part) => {
      if (duplicatePartsData.id === part.id) {
        return {
          ...part,
          allowMerge: mergeAllow,
        };
      }
      return part;
    });
    setMergePartList(updatedList);
  };

  const onMerge = async() => {
    const mergeResult: any = await mergeDuplicateParts.mutateAsync({
      mainPartId: mainPartId,
      duplicatePartsData: mergePartList
    });
    setToastMessage(mergeResult.success ? 'Parts successfully merged!' : 'Parts Merge failed!');
    setOpenModel(false);
    setOpenToast(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'allowMerge',
      headerName: '',
      width: 70,
      renderCell: (params: GridRenderCellParams) => <Checkbox defaultChecked={params.row.allowMerge} onChange={(event) => onChange(params.row, event.target.checked)}></Checkbox>
    },
    {
      field: 'serial1',
      width: 170,
      headerName: 'Serial 1',
      renderCell: (params: GridRenderCellParams) => <Link href={`parts/${params.row.id}`}>{params.row.serial1}</Link>
    },
    {
      field: 'serial2',
      width: 170,
      headerName: 'Serial 2',
    },
    {
      field: 'serial3',
      width: 170,
      headerName: 'Serial 3',
    },
    {
      field: 'locationName',
      width: 270,
      headerName: 'Location',
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created At',
      renderCell: (params) => (
        <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.createdAt)}</Typography>
      )
    },
    {
      field: 'createdByName',
      flex: 1,
      headerName: 'Created By',
    }
  ];

  return (
    <>
      <StyledWrapper>
        <Accordian
          title={<Grid container justifyContent="space-between">
            <Grid item>
              <Typography>Duplicate Parts</Typography>
            </Grid>
          </Grid>}
          defaultExpanded={true}
        >
          <DataGrid
            key={duplicatePartsData.length}
            density={GridDensityTypes.Standard}
            columns={columns}
            rows={duplicatePartsData}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              },
            }}
            autoHeight={true}
            getRowClassName={() => 'duplicate-part-styling'}
            isRowSelectable={() => false} />
          <Grid container mt={2} mr={2} justifyContent="flex-end">
            <Button disabled={disablePartsMergeButton(mergePartList)} variant={ButtonVariantProps.Primary} onClick={() => setOpenModel(true)}>
              Merge selected parts
            </Button>
          </Grid>
        </Accordian>
      </StyledWrapper>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ContentWrapperBox>
          <CloseIcon handleClose={() => setOpenModel(false)} />
          <Grid container>
            <Typography>Executing this action will merge the selected parts in to this page&apos;s part. This action cannot be undone. Do you want to proceed?</Typography>
          </Grid>
          <Grid container mt={2} mb={2}>
            <StyledButton variant={ButtonVariantProps.Secondary} onClick={() => setOpenModel(false)}>Cancel</StyledButton>
            <StyledButton variant={ButtonVariantProps.Primary} onClick={onMerge}>Merge</StyledButton>
          </Grid>
        </ContentWrapperBox>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
};