import { styled } from '@mui/material/styles';
import { LocationOn } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Box } from '../../../../components/atoms/Box';
import { COLORS } from '../../../../configs/colors';
import { CustomFooterProps } from './ServiceAccordianFragment.props';

export const TableWrapper = styled('div')`
  overflow: auto;
  width: 100%;
  height: 40vh;
  max-height: calc(100vh - 278px);
`;

export const LogoImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

export const LogoImage = styled('img')`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`;

export const LoggedIcon = styled(LocationOn)`
  color: white;
  background-color: black;
  padding: 4px;
  font-size: 15px;
  border-radius: 4px;
`;

export const CustomFooter = styled(Stack)<CustomFooterProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 52px;
  border-top: 1px solid ${COLORS.LightGrey};
  align-items: center;
  justify-content: ${(props) => (props.rowsSelected !== 0 ? 'space-between' : 'end')};
`;