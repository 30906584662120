import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { PartSearchData } from '../../../../@types';
import { Button } from '../../../../components/atoms/Button';
import { LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { PartTypeBulkLiveSearchDataInsertionType } from '../../../../@types/part-type.type';
import { useAdvanceBulkSearchSerials, useAdvanceSearchPartTypes, useSearchDispatchParts } from '../../../../queries/live-search-query';
import { GRID_SPACING } from '../../../../configs/ui-constants';
import { SAMPLE_PARTTYPE_BULK_LIVESEARCH_INSERTION_DATA } from '../../../../constants/addStock';
import { getHasCodeForPartTypeSearch, manifestDataMapper } from '../../../../utils/shipment';
import { CONDITION_ORDER, DispatchManifestFragmentProps, SELECTED_DATA, SelectedData } from '.';
import { DispatchManifestDataType, DispatchManifestList } from '../../../../components/templates/DispatchManifestList';
import { INITIAL_DISPATCH_MANIFEST, S_HASH } from '../../../../constants/shipment';
import { PartSearchItem } from '../../../../components/molecules/PartSearchItem';
import { DispatchNSInputPopup } from '../../../../components/organisms/DispatchNSInputPopup';
import { useGetNSPartByPartTypeAndLocation } from '../../../../queries/part-query';
import { NSPartLocationList, SearchCode } from '../../../../@types/part.type';
import { SerialisedType } from '../../../../configs/enums';
import { DispatchManifestGridFragment } from './DispatchManifestGridFragment/DispatchManifestGridFragment';
import { useExportDispatchManifest } from '../../../../queries/uploadedfiles-query';
import { downloadFile } from '../../../../utils/file-utils';
import { AdvanceLiveSearchBox } from '../../../../components/atoms/AdvanceLiveSearchBox';
import { parseExcelData } from '../../../../utils/common';
import { PartTypeSearchItem } from '../../../../components/molecules/PartTypeSearchItem';
import { StyledBox } from './DispatchManifestFragment.styles';

export const DispatchManifestFragment: React.FC<DispatchManifestFragmentProps> = ({
  applicableNodeId,
  sourceLocationId,
  sourceLocationName,
  shipmentId,
  isNew,
  manifestData,
  onChangeManifestData
}) => {
  const [manifestValues, setManifestValues] = useState<DispatchManifestDataType>(INITIAL_DISPATCH_MANIFEST);
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);
  const [serialSearchItems, setSerialSearchItems] = useState('');
  const [advanceBulkSearchData, setAdvanceBulkSearchData] = useState<PartTypeBulkLiveSearchDataInsertionType>(SAMPLE_PARTTYPE_BULK_LIVESEARCH_INSERTION_DATA);
  const [searchItem, setSearchItem] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedPartType, setSelectedPartType] = useState<SelectedData>(SELECTED_DATA);
  const [selectedLocation, setSelectedLocation] = useState<SelectedData>(SELECTED_DATA);
  const [locationList, setLocationList] = useState<NSPartLocationList[]>([]);
  const [isPaste, setIsPaste] = useState(false);

  const advanceSearchPartTypes = useAdvanceSearchPartTypes();
  const advanceBulkSearchSerials = useAdvanceBulkSearchSerials();
  const searchParts = useSearchDispatchParts();
  const getNSPartByPartTypeAndLocation = useGetNSPartByPartTypeAndLocation(selectedPartType?.id, selectedLocation?.id);
  const exportDispatchManifest = useExportDispatchManifest();

  const handleAddNewSerialised = (partLiveSearchData: PartSearchData, index: number) => {
    if (partLiveSearchData && partLiveSearchData.id) {
      const newItem = {
        selected: true,
        condition: partLiveSearchData.partCondition?.name,
        conditionCode: partLiveSearchData.conditionCode,
        partId: partLiveSearchData.id,
        serial: partLiveSearchData.serial1,
        softwareVersion: partLiveSearchData.software?.version,
        softwareVersionId: partLiveSearchData.software?.id,
        hardwareVersion: partLiveSearchData.hardware?.version,
        hardwareVersionId: partLiveSearchData.hardware?.id,
        firmwareVersion: partLiveSearchData.firmware?.version,
        firmwareVersionId: partLiveSearchData.firmware?.id,
        fleetTag: partLiveSearchData.fleetTag || '',
        fleetTagId: partLiveSearchData.fleetTagId,
        location: partLiveSearchData.locationName,
        locationId: partLiveSearchData.locationId
      };
      const foundData = manifestValues.serialisedManifestData.find((item) => item.partTypeId === partLiveSearchData?.partTypeId);
      if(foundData) {
        if (foundData.items?.some((item) => item.partId === partLiveSearchData.id))
          return 0;
        const items = [...foundData.items, newItem];
        const rowData = { ... foundData, items: items };
        setManifestValues(prevState=>{
          return { 
            ...prevState, 
            serialisedManifestData: prevState.serialisedManifestData.map((data) => data.id === foundData.id ? rowData : data) 
          };
        });
      } else {
        const rowData = {
          id: index,
          partTypeId: partLiveSearchData.partTypeId,
          partTypeName: partLiveSearchData.partType.name,
          partTypeNo: partLiveSearchData.partType.number,
          items: [newItem],
          serialisation: SerialisedType.Serialised
        };
        setManifestValues(prevState=>{
          return { 
            ...prevState, 
            serialisedManifestData: [...prevState.serialisedManifestData, rowData] 
          };
        });
      }
    } else {
      const newItem = {
        selected: false,
        serial: partLiveSearchData ? partLiveSearchData.serial1 : searchItem
      };
      const rowData = {
        id: index,
        partTypeId: 0,
        partTypeName: '',
        partTypeNo: '',
        items: [newItem],
        serialisation: SerialisedType.Unknown
      };
      setManifestValues(prevState=>{
        return { 
          ...prevState, 
          serialisedManifestData: [...prevState.serialisedManifestData, rowData] 
        };
      });
    }
    setSearchItem('');
    setIsEnterPressed(false);
  };

  const handleAddNewNonSerialised = (partTypeLiveSearchData: NSPartLocationList, index: number) => {
    const newItem = {
      selected: true,
      ns_part_id: partTypeLiveSearchData.id,
      conditionCode: partTypeLiveSearchData.conditionCode || '',
      condition: partTypeLiveSearchData.condition || '',
      fleetTag: partTypeLiveSearchData.fleetTagName || '',
      fleetTagId: partTypeLiveSearchData.fleetTagId || 0,
      location: partTypeLiveSearchData.locationName || '',
      locationId: partTypeLiveSearchData.locationId || 0,
    };
    const rowData = {
      id: index,
      partTypeId: selectedPartType.id,
      partTypeName: selectedPartType.name,
      partTypeNo: selectedPartType.number || '',
      quantity: 0,
      max: partTypeLiveSearchData.quantity,
      items: [newItem],
      serialisation: SerialisedType.Consumable,
      partTypeCategoryCode: selectedPartType.category
    };
    const nonserialisedData = [...manifestValues.nonSerializedManifestData, rowData];
    nonserialisedData && setManifestValues({ 
      ...manifestValues, 
      nonSerializedManifestData: nonserialisedData 
    });
    setSearchItem('');
    setIsEnterPressed(false);
  };

  useEffect(() => {
    if (searchParts.data && isEnterPressed) {
      const enteredData = searchParts.data[0];
      setSearchItem(enteredData?.name);
      handleAddNewSerialised(enteredData, manifestValues.serialisedManifestData.length);  
    }
  }, [searchParts.data]);
  
  useEffect(() => {
    isEnterPressed && searchItem && onApiInvoke(searchItem);
  }, [isEnterPressed]);

  useEffect(() => {
    onChangeManifestData(manifestDataMapper(manifestValues));
  }, [manifestValues]);

  useEffect(() => {
    if (!!selectedPartType.id && !!selectedLocation.id) {      
      getNSPartByPartTypeAndLocation.refetch();
    }
  }, [selectedPartType, selectedLocation]);

  useEffect(() => {
    sourceLocationId &&     
      setSelectedLocation({
        ...selectedLocation,
        id: sourceLocationId
      });
  }, [sourceLocationId]);

  useEffect(() => {
    if (getNSPartByPartTypeAndLocation.data) {
      // arranging data in order of given array
      const arrangedData: NSPartLocationList[] = [];
      getNSPartByPartTypeAndLocation.data.forEach((a: NSPartLocationList) => {
        arrangedData[CONDITION_ORDER.indexOf(a.condition || '')] = a;
      });  
      setLocationList(arrangedData);
    }
  }, [getNSPartByPartTypeAndLocation.data]);

  useEffect(() => {
    if (advanceBulkSearchSerials.data) {
      advanceBulkSearchSerials.data.map((liveSearchResult: PartSearchData, index: number) => {        
        handleAddNewSerialised(liveSearchResult, manifestValues.serialisedManifestData.length + index);
      });
    }
  }, [advanceBulkSearchSerials.data]);

  useEffect(() => {
    if(advanceBulkSearchData && serialSearchItems.endsWith('\n')){
      const handleSearch = async () => {
        setIsEnterPressed(true);
        await advanceBulkSearchSerials.mutateAsync(advanceBulkSearchData);
        setSerialSearchItems('');
      };
    
      handleSearch();
    }
  }, [advanceBulkSearchData]);

  const bulkSearchInputValueOptimizer = (inputText: string) => {
    if (inputText) {
      const formatedManifestedData = parseExcelData(inputText || '') as Array<Array<string>>;
      const formatedManifestedAsFlatArray = ([] as string[]).concat(...formatedManifestedData);

      setAdvanceBulkSearchData({   
        searchableValues: formatedManifestedAsFlatArray,
        applicableNodeId: applicableNodeId
      });
    }
  };

  const onApiInvoke = async (searchableData: string, enterKeyPressed?: boolean) => {
    setSearchItem(searchableData);
    if(enterKeyPressed) {
      if(getHasCodeForPartTypeSearch(searchableData)) {
        const response = await advanceSearchPartTypes.mutateAsync({
          searchableValues: searchableData,
          applicableNodeId: `${applicableNodeId}`
        });

        return response.filter((responseItem) => responseItem.serialisation === SerialisedType.Consumable);
      }
      return await searchParts.mutateAsync({ name: searchableData.toUpperCase().startsWith(SearchCode.AM_PID) ? searchableData : S_HASH.concat(searchableData), applicableNodeIds: applicableNodeId });
    }

    const response = await advanceSearchPartTypes.mutateAsync({
      searchableValues: searchableData,
      applicableNodeId: `${applicableNodeId}`
    });

    return response.filter((responseItem) => responseItem.serialisation === SerialisedType.Consumable);
   
  };

  const onClickExport = async (e: MouseEvent) => {
    e.stopPropagation();
    const exportFileLink: any = await exportDispatchManifest.mutateAsync(shipmentId); 
    downloadFile(exportFileLink.url, `shipment-${shipmentId}-manifest`);
  };

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>Manifest</Typography>
        </Grid>
        {!isNew && 
          <Grid mr={2} item>
            <Button onClick={onClickExport}>Export Manifest</Button>
          </Grid>
        }
      </Grid>
    } defaultExpanded={true}>
      {isNew ? 
        <Grid container justifyContent="space-between">
          <Grid item xs={12} mb={GRID_SPACING}>
            <StyledBox>
              <AdvanceLiveSearchBox
                title={isPaste ? 'Serial 1 Copy Paste' : 'PartType/ Part Search'}
                timeOffset={400}
                value={serialSearchItems || ''}
                overrideOnEnterKey={true}
                onClearValue={() => {
                  setIsEnterPressed(false);
                  setIsPaste(false);
                  setSerialSearchItems('');
                }}
                renderItem={(props: any, option: any) => { 
                  if (!isPaste) {
                    return (
                      <LiveSearchListItem {...props}>
                        { option.serial1 ? <PartSearchItem data={option} /> : <PartTypeSearchItem data={option} /> }
                        
                      </LiveSearchListItem>
                    );
                  }
                }}
                onChange={(partTypeLiveSearchData: any) => {
                  if('number' in partTypeLiveSearchData) {
                    setSelectedPartType({
                      id: partTypeLiveSearchData.id,
                      name: partTypeLiveSearchData.name,
                      number: partTypeLiveSearchData.number,
                      category: partTypeLiveSearchData.categoryCode
                    });
                    setIsOpen(true);
                  }
                  else {
                    handleAddNewSerialised(partTypeLiveSearchData, manifestValues.serialisedManifestData.length);  
                  }
                }}
                onTextChange={(event) => {
                  setSerialSearchItems(event.target.value);
                  bulkSearchInputValueOptimizer(event.target.value);
                }}
                onApiInvoke={onApiInvoke}
                onPaste={() => {
                  setIsPaste(true);
                }}
                onKeyDown={async (event: KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (serialSearchItems) {
                      setIsEnterPressed(true);
                      await advanceBulkSearchSerials.mutateAsync(advanceBulkSearchData);
                      setSerialSearchItems('');
                    }
                  }
                  setIsPaste(false);
                }}
                isPaste={isPaste}
              />
            </StyledBox>
          </Grid>
          <Grid container justifyContent="space-between" width="100%">
            <DispatchManifestList
              manifestData={manifestValues}
              onChange={(val) => setManifestValues(val)}    
            />
          </Grid>
        </Grid> : 
        <DispatchManifestGridFragment manifestData={manifestData || []} shipmentId={shipmentId || 0} />
      }
      <DispatchNSInputPopup
        isOpen={isOpen}
        onChange={(val) => {
          setSelectedLocation({
            id: val.id,
            name: val.name || ''
          });
        }}
        onClick={(selectedItem) => {
          handleAddNewNonSerialised(selectedItem, manifestValues.nonSerializedManifestData.length);
          setIsOpen(false);      
        }}
        onCancel={() => {
          setIsOpen(false);
          setSelectedPartType({
            id: 0,
            name: '',
            number: '',
          });
          setSearchItem('');
        }}
        onClose={() => setIsOpen(false)}
        sourceLocation={sourceLocationId}
        sourceLocationName={sourceLocationName}
        partTypeName={selectedPartType?.name || ''}
        locationData={locationList}
      />
    </Accordian>
  );
};