import { Stack, styled } from '@mui/material';
import { LiveSearchBox } from '../../../../../../../components/atoms/LiveSearchBox';
import { SelectMenu } from '../../../../../../../components/atoms/SelectMenu';

export const StyledStack = styled(Stack)`
  min-width: 300px;
  max-width: 300px;
`;

export const StyledLiveSearchBox = styled(LiveSearchBox)`
  min-width: 300px;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  min-width: 300px;
`;