import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { SelectMenu } from '../../../components/atoms/SelectMenu';
import { TextField } from '../../../components/atoms/TextField';

export const StyledSelectMenu = styled(SelectMenu)`
  width: 25%;
  min-width: 25%;
`;

export const StyledTextField = styled(TextField)`
  width: 25%;
  min-width: 25%;
`;

export const StyledNumberField = styled(TextField)`
  width: 11.8%;
  min-width: 11.8%;
`;

export const LocationSearchBox = styled(Box)`
  width: 75%;
`;

export const PartTypeSKUSearchBox = styled(Box)`
  width: 100%;
`;