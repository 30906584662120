import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { StructureStatesResponse, StatesWithCountryResponse } from '../@types';
import { StatesKey, StatesWithCountryKey } from '../@types/address.type';
import { getStates, getStateWithCountryData } from '../api/geosocial';

export const useGetStates = (): UseQueryResult<StructureStatesResponse[]> => {
  return useQuery([StatesKey], () => getStates(), { staleTime: 5 * 60 * 60 * 1000 });
};

export const useGetStateWithCountryData = (stateId: number): UseQueryResult<StatesWithCountryResponse> => {
  return useQuery([StatesWithCountryKey, stateId], () => getStateWithCountryData(stateId), { staleTime: 5 * 60 * 60 * 1000 });
};