import { httpClient, HttpMethod } from './http-client';
import { ImportSessionData, ImportSessionQueryData, ImportSessionStatusCode } from '../@types';

export const createImportSession = (importSessionDataObject: ImportSessionData) => {
  return httpClient('/importSessions', HttpMethod.Post, importSessionDataObject);
};

export const getImportSessionById = (sessionId: number, resourceType: string) => {
  return httpClient(`/importSessions/${resourceType}/${sessionId}`, HttpMethod.Get, null);
};

export const getImportedResourceBySessionId = (sessionId: number, resourceType: string, queryData: ImportSessionQueryData) => {
  let url = `/importSessions/${resourceType}/${sessionId}/importedResources?page=${queryData.page + 1}&limit=${queryData.limit}&showErrorRecords=${queryData.showErrorRecords}&showCreateableRecords=${queryData.showCreateableRecords}`;
  if (queryData?.showUpdateableRecords !== undefined) {
    url += `&showUpdateableRecords=${queryData.showUpdateableRecords}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const uploadResource = (resourceType: string, file: any) => { // Uploaded file data is passed here.file data types cannot be defined
  const headers = { 'Content-Type': 'multipart/form-data' };
  return httpClient(`/uploadedResources/uploadFile/${resourceType}`, HttpMethod.Post, file, headers);
};

export const updateImportSession = (id: number,importSessionStatusCode: ImportSessionStatusCode, resourceType: string) => {
  return httpClient(`/importSessions/${resourceType}/${id}`, HttpMethod.Put, importSessionStatusCode);
};

export const getImportSessionSummary = (sessionId: number, resourceType: string) => {
  return httpClient(`/importSessions/${resourceType}/${sessionId}/summary`, HttpMethod.Get, null);
};