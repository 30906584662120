import React from 'react';
import { IconButton, Stack, TableRow } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { SiteSchemaItemRowProps } from './SiteSchemaItemRow.props';
import { StyledTableCell, StyledTableCellPartType, StyledTableCellServiceType } from './SiteSchemaItemRow.styles';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { Switch } from '../../atoms/Switch';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { ColorProps, PartTypeLiveSearchItem } from '../../../@types';
import { ANY_KEYWORD } from '../../../constants/common';
import { getDropdownOptionsWithHandlingAnyKey } from '../../../utils/structure';

export const SiteSchemaItemRow: React.FC<SiteSchemaItemRowProps> = ({
  isNew,
  value,
  serviceTypeOptions,
  softwareVersionOptions,
  firmwareVersionOptions,
  hardwareVersionOptions,
  fleetTagOptions,
  multiline = true,
  onChange,
  onPartTypeSearchApiInvoke,
  onCancel
}) => {
  return (
    <TableRow>
      <StyledTableCellPartType>
        <LiveSearchBox
          title="Part Type Search"
          multiline={multiline}
          timeOffset={400}
          value={value.partTypeName}
          onClearValue={() => ''}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onChange={(val: PartTypeLiveSearchItem) => onChange({ ...value, partTypeId: val.id, partTypeName: val.name })}
          onApiInvoke={onPartTypeSearchApiInvoke}
        />
      </StyledTableCellPartType>
      <StyledTableCellServiceType>
        <MultiSelectCheckmarksMenu
          label="Service Type"
          items={serviceTypeOptions}
          selectedValue={value.serviceType}
          onChange={(val) => onChange({ ...value, serviceType: val.split(',').filter((obj) => obj !== '') })}
        />
      </StyledTableCellServiceType>
      <StyledTableCell>
        <MultiSelectCheckmarksMenu
          label="Software Version"
          disabled={!value.partTypeName}
          items={getDropdownOptionsWithHandlingAnyKey(value.softwareVersion, softwareVersionOptions)}
          selectedValue={value.softwareVersion}
          onChange={(val) => {
            const values = val.split(',');
            if (values.includes(ANY_KEYWORD))
              onChange({ ...value, softwareVersion: [ANY_KEYWORD] });
            else
              onChange({ ...value, softwareVersion: values.filter((obj) => obj !== '') });
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <MultiSelectCheckmarksMenu
          label="Firmware Version"
          disabled={!value.partTypeName}
          items={getDropdownOptionsWithHandlingAnyKey(value.firmwareVersionId, firmwareVersionOptions)}
          selectedValue={value.firmwareVersionId}
          onChange={(val) => {
            const values = val.split(',');
            if (values.includes(ANY_KEYWORD))
              onChange({ ...value, firmwareVersionId: [ANY_KEYWORD] });
            else
              onChange({ ...value, firmwareVersionId: values.filter((obj) => obj !== '') });
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <MultiSelectCheckmarksMenu
          label="Hardware Version"
          disabled={!value.partTypeName}
          items={getDropdownOptionsWithHandlingAnyKey(value.hardwareRevisionId, hardwareVersionOptions)}
          selectedValue={value.hardwareRevisionId}
          onChange={(val) => {
            const values = val.split(',');
            if (values.includes(ANY_KEYWORD))
              onChange({ ...value, hardwareRevisionId: [ANY_KEYWORD] });
            else
              onChange({ ...value, hardwareRevisionId: values.filter((obj) => obj !== '') });
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <MultiSelectCheckmarksMenu
          label="Fleet Tag"
          items={getDropdownOptionsWithHandlingAnyKey(value.fleetTagId, fleetTagOptions)}
          selectedValue={value.fleetTagId}
          onChange={(val) => {
            const values = val.split(',');
            if (values.includes(ANY_KEYWORD))
              onChange({ ...value, fleetTagId: [ANY_KEYWORD] });
            else
              onChange({ ...value, fleetTagId: values.filter((obj) => obj !== '') });
          }}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Stack width="50px">
          <Typography variant={TypographyVariantProps.Caption}>Is Active</Typography>
          <Switch
            color={ColorProps.Success}
            checked={value.isActive}
            onChange={() => onChange({ ...value, isActive: !value.isActive })}
          />
        </Stack>
      </StyledTableCell>
      {isNew && 
        <StyledTableCell width="50px">
          <IconButton color="error" onClick={onCancel}>
            <CloseRounded /> 
          </IconButton>
        </StyledTableCell>
      }
    </TableRow>
  );
};
