import axios from 'axios';
import { BASE_URL } from '../configs/envs';

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export const httpClient = (url: string, method: HttpMethod, data: any, headers: any = undefined): any => {
  return new Promise((resolve, reject) => {
    const params: any = { method, url: `${BASE_URL}${url}` };
    if (data) {
      params.data = data;
    }

    if (headers) {
      params.headers = headers;
    }

    axios(params).then(response => {
      if (response.status > 199 && response.status < 300) {
        resolve(response.data);
      } else {
        reject('Response is not success');
      }
    }).catch(error => {
      reject(error);
    });
  });
};
