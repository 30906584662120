import { styled } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

export const ThumbImage = styled('img')`
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-color: black;
  cursor: pointer;
`;

export const MainImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
`;

export const CloseButton = styled(CloseRounded)`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 15px;
  opacity: 0.8;
  cursor: pointer;
`;
