import { SizeProps } from '../../../@types/common.type';
import { UploadedFile } from '../../../@types/uploaded-files.type';
export enum FileUploadActionType {
  Archive = 'archive',
  Download = 'download',
  View = 'view',
}

export interface AttachmentListProps {
  size?: SizeProps;
  error?: boolean;
  onClick: (id: number, actionType: FileUploadActionType, mimeType: string) => void;
  rows: UploadedFile[];
  scrollable?: boolean;
  maxFileSize: number;
  fileTypes: string[];
  isMobile?: boolean;
  setFileDetails?: (file: FileList) => Promise<void>;
}
