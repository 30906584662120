import { ReactNode, MouseEvent } from 'react';
import { SizeProps } from '../../../@types';

export enum ToogleButtonGroupColor {
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Standard = 'standard',
}

export enum ToogleButtonTextAlignment {
  Center = 'center',
  Start = 'flex-start',
  End = 'flex-end',
  Stretch = 'stretch',
  Around = 'space-around'
}

export interface ToogleButtonItem {
  value: string;
  label: string;
  subLabel?: string | ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: (value: string) => void;
}

export interface ToogleButtonGroupProps {
  color?: ToogleButtonGroupColor;
  value: any;
  exclusive?: boolean;
  onChange?: (event: MouseEvent<HTMLElement>, item: any) => void;
  size?: SizeProps;
  items: ToogleButtonItem[];
}