import React, { ChangeEvent } from 'react';
import { Stack } from '@mui/material';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { FleetTagProps } from './FleetTag.props';
import { StyledTextField } from './FleetTag.styles';

export const FleetTag: React.FC<FleetTagProps> = ({
  fleetTagData,
  onChange
}) => {
  return (
    <Stack direction="row" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <StyledTextField
        fullWidth
        required
        label="Name"
        value={fleetTagData?.fleetTagName}
        onChange={(text) => onChange({
          ...fleetTagData,
          fleetTagName: text
        })}
      />
      <FormControlLabel
        control={
          <Switch
            color={ColorProps.Success}
            checked={fleetTagData?.isEnabled}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({
              ...fleetTagData,
              isEnabled: event.target.checked
            })} />
        }
        label="Enabled"
        labelPlacement={FormControlLabelPlacementProps.Top}
      />
    </Stack>
  );
};