
import React, { useEffect, useState } from 'react';
import { Box, Icon, Stack, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { KeyboardDoubleArrowRight, AccountCircle, KeyboardDoubleArrowLeft } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { DrawerProps } from './Drawer.props';
import { DrawerContent } from './DrawerContent';
import { DrawerFooter } from './DrawerFooter';
import { Wrapper, HeaderWrapper, DrawerWrapper, MobileNavbar, IconStyled, HeaderWrapperChild, StyledArrowBox, IconImage, MaterialDrawerStyled } from './Drawer.styles';
import { DisplayProps, ScreenSize } from '../../../@types';
import { toggleSidebar } from '../../../store/sidebarStore';

export const Drawer: React.FC<DrawerProps> = ({
  menuData,
  isOutsideClick,
  handleOutsideClick
}) => {
  const HIDE_MENU_KEY = 'hide_menu';
  const [searchParams] = useSearchParams();

  const hideMenu = searchParams.get(HIDE_MENU_KEY) || localStorage.getItem(HIDE_MENU_KEY);
  
  const [isHidden, setIsHidden] = useState<boolean>(hideMenu === 'true');
  const [isMenuItemClicked, setIsMenuItemClicked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  useEffect(() => {
    isMobileView && isOutsideClick && setIsHidden(true);
  }, [isOutsideClick]);

  useEffect(() => {
    if (isHidden) {
      localStorage.setItem(HIDE_MENU_KEY, 'true');
    } else {
      localStorage.removeItem(HIDE_MENU_KEY);
    }
  }, [isHidden]);

  useEffect(() => {
    isMobileView && isMenuItemClicked && toggleHiddenState();
  }, [isMenuItemClicked]);

  const toggleHiddenState = () => {
    setIsHidden(!isHidden);
    dispatch(toggleSidebar());
    setIsMenuItemClicked(false);
    handleOutsideClick(false);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setIsHidden(newOpen);
  };

  return (
    <>
      <MaterialDrawerStyled anchor={'left'}
        hidden={isHidden}
        variant="permanent"
        sx={{
          width: 255,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 255,
            boxSizing: 'border-box',
            zIndex: 2,
          },
        }}
        onClose={toggleDrawer(false)}
      >
        <Wrapper>
          <HeaderWrapper>
            <HeaderWrapperChild>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 499.99998 499.99998"
                version="1.1"
                width="82%"
                height="85%"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs id="defs1">
                  <style id="style1">
                    {'.cls-1 { fill: #fff; }'}
                  </style>
                </defs>
                <title id="title1">Amtek</title>
                <path
                  className="cls-1"
                  d="m 181.85753,293.64875 68.14246,32.25769 68.13077,-32.25769 -68.13077,-69.59329 z M 317.14794,147.9217 292.21463,103.75313 250.0117,29 v 0 0 0 l -49.4688,87.63514 -17.69084,31.28656 67.14793,68.58708 z M 500,471.7856 440.55085,366.48303 h -43.60697 l 41.92213,42.82305 11.19717,11.44288 v 0 z M 246.30271,220.22947 180.13759,152.71882 117.76337,263.22802 v 0 l 59.08645,27.97537 z m 76.88258,70.97392 59.07474,-27.96367 -62.40933,-110.57941 -66.15341,67.56916 z M 103.06782,366.57663 H 59.425749 L 0,471.7856 29.730425,441.36486 79.725745,390.30481 Z m 288.69284,-5.28852 h 45.80662 l -52.65129,-93.39168 -57.79941,27.35527 z m -69.722,-63.64956 -65.87261,31.19296 68.52857,32.4566 h 59.67145 z M 172.90682,295.2985 115.13081,267.93153 62.409323,361.3349 h 45.830017 z m 162.96157,71.31324 100.06085,47.35105 -46.40334,-47.39785 z M 187.63748,361.3349 h 124.71334 l -62.35083,-29.53151 z m -9.68784,-63.64955 -62.31571,63.64955 h 59.67145 l 68.52857,-32.45658 z M 95.755132,381.6349 64.936584,413.13207 64.070763,413.99789 164.1433,366.61174 h -53.6809 z"
                  id="path1"
                  style={{ strokeWidth: 1.17003 }}
                />
              </svg>
            </HeaderWrapperChild>
            <StyledArrowBox>
              <Icon onClick={toggleHiddenState}>
                <KeyboardDoubleArrowLeft />
              </Icon>
            </StyledArrowBox>
          </HeaderWrapper>
          {/* TODO:: This is hiden until the functionality complete
          <StyledInput
            placeholder="Search..."
            startAdornment={
              <AdornmentWrapper position="start">
                <SearchRounded />
              </AdornmentWrapper>
            }
          /> */}
          <DrawerWrapper>
            <DrawerContent menuData={menuData} handleMenuItemClick={setIsMenuItemClicked}/>
          </DrawerWrapper>
          <DrawerFooter />
        </Wrapper>
      </MaterialDrawerStyled>
      <Box sx={{ display: isHidden ? DisplayProps.Block : DisplayProps.None }}>
        {isMobileView ?
          <MobileNavbar>      
            <IconStyled onClick={toggleHiddenState} >
              <KeyboardDoubleArrowRight/>
            </IconStyled>
            <IconImage src="/images/leftMenu/Amtek_Logo_Phoenix_Single_Black@0.5x.png" />
            <IconStyled>
              <AccountCircle/>
            </IconStyled>
          </MobileNavbar>
          :
          <Stack direction="column">
            <IconImage src="/images/leftMenu/Amtek_Logo_Phoenix_Single_Black@0.5x.png" />
            <IconStyled onClick={toggleHiddenState} >
              <KeyboardDoubleArrowRight/>
            </IconStyled>
          </Stack>
        }
      </Box>
    </>
  );
};
