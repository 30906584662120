import { abortUploadedFilesMultipartUpload, completeUploadedFilesMultipart, getUploadedFilesMultipartUrl, initializeUploadedFilesMultipart } from '../api/uploadedFiles';

export const uploadFileAsChunks = async (file: File, entityId: number, entityType: string, setUploadProgress: (value: number) => void) => {
  const partSize = 5 * 1024 * 1024;
  const numParts = Math.ceil(file.size / partSize);

  const { uploadId, key: fileNameKey } = await initializeUploadedFilesMultipart(file.name, entityId, entityType);

  try {
    const parts = [];
    let uploadedSize = 0;

    for (let i = 0;i < numParts;i++) {
      const partNumber = i + 1;
      const start = i * partSize;
      const end = Math.min(start + partSize, file.size);
      const blob = file.slice(start, end);

      const { url } = await getUploadedFilesMultipartUrl(fileNameKey, partNumber, uploadId);

      const response = await fetch(url, { method: 'PUT', body: blob });

      if (!response.ok) {
        throw new Error('Failed to upload file');
      }

      parts.push({
        ETag: response.headers.get('ETag'),
        PartNumber: partNumber,
      });
      uploadedSize += blob.size;
      setUploadProgress((uploadedSize / file.size) * 100);
    }

    return await completeUploadedFilesMultipart(fileNameKey, uploadId, parts, file.name, entityId, entityType, file.type);
  } catch (error) {
    await abortUploadedFilesMultipartUpload(fileNameKey, uploadId);
    setUploadProgress(-1);
  }
};