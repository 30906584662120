import { styled } from '@mui/material/styles';
import { Button } from '../../atoms/Button';
import { Box } from '../../atoms/Box';

export const StyledWrapper = styled('div')`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export const ContentWrapperBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 80%;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;