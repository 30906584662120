import React from 'react';
import { Grid } from '@mui/material';
import { AdditionalConfigFragmentProps } from './AdditionalConfigFragment.props';
import { MultiSelectCheckmarksMenu } from '../../../../components/atoms/MultiSelectCheckmarksMenu';
import { SPECIAL_SERVICE_TASKS } from '../../../../constants/structure';
import { TypographyVariantProps } from '../../../../components/atoms/Typography';
import { InputGroupWrapperBox, StyledTextField, StyledTypography, StyledWrapperGrid } from './AdditionalConfigFragment.styles';
import { filterAdditionalConfigs } from '../../../../utils/structure';

export const AdditionalConfigFragment: React.FC<AdditionalConfigFragmentProps> = ({
  nodeId,
  additonalConfig,
  setAdditonalConfig
}) => {
  return (
    <StyledWrapperGrid container mt={0}>
      <Grid>
        <StyledTypography variant={TypographyVariantProps.H6}>{'Additional Config'}</StyledTypography>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <MultiSelectCheckmarksMenu
            id="admin-aditional-config-menu"
            label="Service Tasks"
            required={false}
            items={filterAdditionalConfigs(SPECIAL_SERVICE_TASKS, nodeId)}
            selectedValue={additonalConfig.serviceTasksConfigs?.map(serviceTask => serviceTask.code)}
            onChange={(selectedItems) => {
              if (selectedItems !== '') {
                const codes = selectedItems.split(',');
                const updatedAdditionalConfigs = additonalConfig.serviceTasksConfigs?.filter(serviceTask => codes.some(code => code === serviceTask.code));
                const newCodes = codes.filter(code => !additonalConfig.serviceTasksConfigs?.some(serviceTask => serviceTask.code === code));
                const newConfigs = newCodes.map(code => ({
                  code: code,
                  additionalData: ''
                }));
                setAdditonalConfig({ ...additonalConfig, serviceTasksConfigs: [...updatedAdditionalConfigs, ...newConfigs] });
              }
              else {
                setAdditonalConfig({ ...additonalConfig, serviceTasksConfigs: [] });
              }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputGroupWrapperBox>
            {
              additonalConfig.serviceTasksConfigs?.map(serviceTask => {
                return (serviceTask?.code &&
                  <StyledTextField
                    fullWidth
                    key={serviceTask.code}
                    label={filterAdditionalConfigs(SPECIAL_SERVICE_TASKS, nodeId).find(task => task.value === serviceTask.code)?.additionalDataLabel}
                    value={serviceTask.additionalData}
                    onChange={(textValue) => {
                      const configDataIndex = additonalConfig.serviceTasksConfigs.findIndex(data => data.code === serviceTask.code);
                      if (configDataIndex != -1) {
                        additonalConfig.serviceTasksConfigs[configDataIndex].additionalData = textValue;
                      }
                      setAdditonalConfig({ ...additonalConfig });
                    }}
                  />
                );
              })
            }
          </InputGroupWrapperBox>
        </Grid>
      </Grid>
    </StyledWrapperGrid>
  );
};