import { Grid } from '@mui/material';
import React from 'react';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { SwHwFwSelectorProps } from './SwHwFwSelector.props';

export const SwHwFwSelector: React.FC<SwHwFwSelectorProps> = ({
  items,
  selectedValue,
  onChange,
  disabled
}) => {
  return (
    <Grid container mt="1px" spacing={GRID_SPACING}>
      <Grid item xs={4}>
        <MultiSelectCheckmarksMenu
          id="part-type-sw"
          label="Select SW"
          disabled={disabled}
          items={items.sw}
          selectedValue={selectedValue.sw}
          onChange={(version) => {
            onChange({
              ...selectedValue,
              sw: version ? version.split(',') : []
            });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MultiSelectCheckmarksMenu
          id="part-type-hw"
          label="Select HW"
          disabled={disabled}
          items={items.hw}
          selectedValue={selectedValue.hw}
          onChange={(version) => {
            onChange({
              ...selectedValue,
              hw: version ? version.split(',') : []
            });
          }}
        />
      </Grid>
      <Grid direction={'row'} item xs={4}>
        <MultiSelectCheckmarksMenu
          id="part-type-fw"
          label="Select FW"
          disabled={disabled}
          items={items.fw}
          selectedValue={selectedValue.fw}
          onChange={(version) => {
            onChange({
              ...selectedValue,
              fw: version ? version.split(',') : []
            });
          }}
        />
      </Grid>
    </Grid>
  );
};