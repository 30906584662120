import { TLCManifestRowItemValueType } from '../components/organisms/TLCManifestRowItem/TLCManifestRowItem.props';

export const arrayItemsToString = (array: any[]) => {
  return array?.map((item: string) => item.toString());
};

export const arrayItemsToInt = (array: any[]) => {
  return array?.map((item: string) => (parseInt(item)));
};

export const canHaveSWFWHWData = (isChecked: boolean, isHavePartType: boolean, selectedData: string) => {
  return isChecked && isHavePartType && selectedData !== '' ;
};

export const isDuplicatedSerial = (manifest: TLCManifestRowItemValueType[], validationResult: any) => { // Data type changes for validationResult
  return manifest.some((part) => part.serial === validationResult.serial && !part.errors);
};