import React from 'react';
import { Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { PartTypeActionSearchItemProps } from './PartTypeActionSearchItem.props';

export const PartTypeActionSearchItem: React.FC<PartTypeActionSearchItemProps> = ({
  name,
  serial,
  partTypeId,
  partId,
  nsPartId,
  category,
  isCreateAction = false
}) => {
  return (
    isCreateAction ?
      <Stack direction="column" spacing={1}>
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
          Create Part Record
        </Typography>
      </Stack>
      :
      <Stack direction="column" spacing={1}>
        {serial &&
          <Typography
            variant={TypographyVariantProps.Body2}
            fontWeight={TypographyFontWeightProps.Bold}
          >
            Serial -: {serial}
          </Typography>
        }
        {name &&
          <Typography
            variant={TypographyVariantProps.Body2}
            fontWeight={TypographyFontWeightProps.Bold}
          >
            PartType Name -: {name}
          </Typography>
        }
        {partTypeId &&
          <Typography variant={TypographyVariantProps.Body2}>
            AM-PTID-{partTypeId}
          </Typography>
        }
        {partId &&
          <Typography variant={TypographyVariantProps.Body2}>
            AM-PID-{partId}
          </Typography>
        }
        {nsPartId &&
          <Typography variant={TypographyVariantProps.Body2}>
            AM-PID-{nsPartId}
          </Typography>
        }
        <Typography variant={TypographyVariantProps.Body2}>
          {category}
        </Typography>
      </Stack>
  );
};
