import { Box, styled, Stack } from '@mui/material';
import { Typography } from '../Typography';
import { COLORS } from '../../../configs/colors';

export const Label = styled(Box)`
  opacity: 0.6;
  padding-right: 8px;
`;

export const MicroText = styled(Typography)`
  font-size: 10px;
  font-weight: bold;
  color: ${COLORS.DarkGray};
`;

export const StyledStack = styled(Stack)`
  width: 100%;
`;