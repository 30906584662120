import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { createInteractionRule, getAllInteractionTemplates, getAllTriggerPointTypes, updateInteractionRule } from '../api/interaction';
import { ActiveInteractionTemplate, InteractionTemplate, TriggerPointTypeListResponse } from '../@types/interaction.type';
import { ActiveInteractionTemplateKey, InteractionsRulesConfigsKey, InteractionsTrigerPointTypeKey, InteractionTemplateKey } from '../configs/interactions-rules';
import { getAllActiveInteractionTemplates } from '../api/interactions';

export const useCreateInteractionRule = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    const queryResult = await createInteractionRule(data);
    return queryResult;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([InteractionsRulesConfigsKey]);
    },
  });
};
  
export const useUpdateInteractionRule = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateInteractionRule(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([InteractionsRulesConfigsKey]);
    },
  });
};

export const useGetAllInteractionTemplates = (): UseQueryResult<InteractionTemplate> => {
  return useQuery([InteractionTemplateKey], () => getAllInteractionTemplates());
};

export const useGetAllTriggerPointTypes = (): UseQueryResult<TriggerPointTypeListResponse> => {
  return useQuery([InteractionsTrigerPointTypeKey], () => getAllTriggerPointTypes());
};

export const useGetAllActiveInteractionTemplates = (): UseQueryResult<ActiveInteractionTemplate[]> => {
  return useQuery([ActiveInteractionTemplateKey], () => getAllActiveInteractionTemplates());
};