import { TableCell, styled, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { COLORS } from '../../../configs/colors';

export const StyledTableCell = styled(TableCell)`
  border: 0;
  vertical-align: middle;
  padding: 5px;
`;

export const BoldLabel = styled(Typography)`
  text-align: center;
  padding: 0;
  font-weight: bold;
`;

export const NameLabel = styled(Typography)`
  text-align: center;
  padding: 7px;
  width: 100px;
  height: 25px;
  background-color: ${COLORS.LightGrey};
`;

export const RemoveButton = styled(RemoveIcon)`
  padding: 7px;
  background-color: ${COLORS.Cyan};
  color: ${COLORS.White};
`;

export const AddButton = styled(AddIcon)`
  padding: 7px;
  background-color: ${COLORS.Blue};
  color: ${COLORS.White};
`;

export const ClearButton = styled(DisabledByDefaultIcon)`
  width: 30px;
  height: 30px;
  margin-left: 15px;
  background-color: ${COLORS.Red};
  color: ${COLORS.White};
`;