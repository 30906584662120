export const SAMPLE_SERVICE_BILLING = {
  billingReviewed: false,
  billableType: '',
  clientInvoiced: false,
  billingNotes: '',
  privateNotes: '',
  externalInvoices: [],
  internalInvoices: []
};

export const SAMPLE_SLA_DETAILS = {
  contactRowData: [],
  reviewMitigationData: {
    reviewValue: '-1',
    reviewNote: '',
    mitigationValue: '-1',
    mitigationNote: ''
  }
};

export const SAMPLE_SLA_DETAILS_HELPER_TEXT = {
  reviewNoteHelperText: '',
  mitigationNoteHelperText: ''
};

export const SAMPLE_SLA_CONFIG_DATA = {
  id: -1,
  name: '',
  notes: '',
  ph: false,
  isActive: false,
  serviceType: [],
  actionType: [],
  processType: [],
  priority: [],
  region: [],
  scope: '',
  scopeName: '',
  targetType: '',
  serviceStatuses: [[]],
  slaType: '',
  timer: { type: '', days: 0, hours: 0, minutes: 0 },
  nodeHierarchy: '',
  client: [],
  contract: [],
  brand: [],
  serviceTypeNames: '',
  processTypeNames: '',
  actionTypeNames: '',
  priorityNames: '',
  targetText: '',
  regionNames: '',
  slaTypeText: '',
  duration: ''
};

export const SAMPLE_PRIORITY_SELECTION_OBJECT = {
  client: [],
  contract: [],
  brand: [],
  serviceTypes: []
};