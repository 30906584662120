import { styled } from '@mui/material/styles';
import { Box } from '../../../../components/atoms/Box';

export const ManifestBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;