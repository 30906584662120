import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';
import { Typography } from '../../../components/atoms/Typography';
import { Button } from '../../../components/atoms/Button';

export const MainBox = styled(Box)`
  margin-top: 10px;
  border: 2px solid #eeeeee;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const StyledTypography = styled(Typography)`
  margin-top: 40px;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
`;

export const WrapperButton = styled(Stack)`
  flex-direction: row-reverse;
`;

export const StyledStack = styled(Stack)`
  width: fit-content;
`;

export const BulkPutawayWrapper = styled(Stack)`
  float: right;
  margin-right: 5px;
`;