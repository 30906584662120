import { styled } from '@mui/material/styles';
import { Typography } from '../../../../components/atoms/Typography';
import { Box } from '../../../../components/atoms/Box';
import { SelectMenu } from '../../../../components/atoms/SelectMenu';

export const TLCTypography = styled(Typography)`
  padding-bottom: 10px;
`;

export const TLCBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;
