export const serialisedColumnSelectionItems = [
  {
    title: 'Part Manifest',
    value: [
      {
        field: 'id',
        title: 'Asset ID',
        value: true
      },
      {
        field: 'serial1',
        title: 'Serial 1',
        value: true
      },
      {
        field: 'serial2',
        title: 'Serial 2',
        value: true
      },
      {
        field: 'serial3',
        title: 'Serial 3',
        value: true
      },
      {
        field: 'partTypeId',
        title: 'Part Type ID',
        value: true
      },
      {
        field: 'partTypeName',
        title: 'Part Type Name',
        value: true
      },
      {
        field: 'locationName',
        title: 'Structure Node (Location)',
        value: true
      },
      {
        field: 'condition',
        title: 'Condition',
        value: true
      },
      {
        field: 'software',
        title: 'Software',
        value: true
      },
      {
        field: 'firmware',
        title: 'Firmware',
        value: true
      },
      {
        field: 'hardwareRev',
        title: 'Hardware Rev.',
        value: true
      },
      {
        field: 'fleetTagName',
        title: 'Fleet Tag Name',
        value: true
      },
      {
        field: 'notes',
        title: 'Note',
        value: true
      },
    ]
  }
];

export const nonSerialisedColumnSelectionItems = [
  {
    title: 'Part Manifest',
    value: [
      {
        field: 'id',
        title: 'NS Part ID',
        value: true
      },
      {
        field: 'partTypeId',
        title: 'Part Type ID',
        value: true
      },
      {
        field: 'partTypeName',
        title: 'Part Type Name',
        value: true
      },
      {
        field: 'locationName',
        title: 'Structure Node (Location)',
        value: true
      },
      {
        field: 'condition',
        title: 'Condition',
        value: true
      },
      {
        field: 'fleetTagName',
        title: 'Fleet Tag Name',
        value: true
      },
      {
        field: 'quantityInStock',
        title: 'Current QTY',
        value: true
      },
    ]
  }
];