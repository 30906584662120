import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { Alert, Grid, Stack } from '@mui/material';
import { PageContainer } from '../../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { IncomingShipmentDetailsFragment } from './IncomingShipmentDetailsFragment';
import { Div } from '../../AddStock/AddEditStock.styles';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { useBulkCreateShipmentIndividualReceive, useGetAllManifestByShipment } from '../../../../queries/shipment-query';
import { useReciveShipmentsSearchNodes } from '../../../../queries/live-search-query';
import { BulkPutawayWrapper, MainBox, StyledTypography } from './IndividualShipmentReceive.styles';
import { SerialisedReceiveShipmentManifestItem } from '../../../../components/organisms/SerialisedReceiveShipmentManifestItem';
import { SerialisedReceiveShipmentManifestData, IndividualShipmentReceiveData, ShipmentRecieveType, NonSerializedReceiveShipmentManifestData } from '../../../../@types/shipment.type';
import { BulkPutaway } from '../../../../components/molecules/BulkPutaway';
import { updatePutawayLocationForIndividualShipment } from '../../../../utils/shipment';
import { PLATFORM_NAME } from '../../../../configs/common';
import { TabHandler } from '../../../../handlers/TabHandler';
import { SAMPLE_SHIPMENT_INDIVIDUAL_RECEIVAL } from '../../../../constants/shipment';
import { NonSerialisedReceiveShipmentManifestItem } from '../../../../components/organisms/NonSerialisedReceiveShipmentManifestItem';

export const IndividualShipmentReceive: React.FC = () => {
  const { id } = useParams();
  const isSearch = id === undefined;

  const [shipmentId, setShipmentId] = useState<number>(0);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [receiveData, setReceiveData] = useState<IndividualShipmentReceiveData>(SAMPLE_SHIPMENT_INDIVIDUAL_RECEIVAL);
  const [selectedSerializedShipmentManifestItem, setSelectedSerializedShipmentManifestItem] = useState<SerialisedReceiveShipmentManifestData[]>([]);
  const [selectedNonSerializedShipmentManifestItem, setSelectedNonSerializedShipmentManifestItem] = useState<NonSerializedReceiveShipmentManifestData[]>([]);
  const [textBoxClick, setTextBoxClick] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [serialisedSelectionModel, setSerialisedSelectionModel] = useState<GridSelectionModel>([]);
  const [nonSerialisedSelectionModel, setNonSerialisedSelectionModel] = useState<GridSelectionModel>([]);

  const chunkSize = 100;

  const getAllManifestByShipment = useGetAllManifestByShipment(shipmentId);
  const bulkCreateShipmentIndividualReceive = useBulkCreateShipmentIndividualReceive(shipmentId);
  const reciveShipmentsSearchNodes = useReciveShipmentsSearchNodes();

  TabHandler(isSearch ? `Receive | ${PLATFORM_NAME}` : `Receive: ${id} | Receive | ${PLATFORM_NAME}`);

  useEffect(() => {
    !isSearch && setShipmentId(parseInt(id as string));
  }, [isSearch]);

  useEffect(() => {
    getAllManifestByShipment.refetch();
  }, [shipmentId]);

  useEffect(() => {
    if (getAllManifestByShipment.data) {
      setReceiveData({
        ...receiveData,
        serializedShipmentManifestItem: [ ...(getAllManifestByShipment.data?.serial || [])],
        nonSerializedShipmentManifestItem: [ ...(getAllManifestByShipment.data?.nonSerial || [])],
      });
    }
  }, [getAllManifestByShipment.data]);

  const onClick = async () => {
    if (!receiveData.selectedSerializedShipmentManifestItem?.length && !receiveData.selectedNonSerializedShipmentManifestItem?.length) {
      return;
    }

    setIsOpen(true);
    setOpenToast(false);

    let shipmentReceiptId = 0;

    for (let i = 0; i < receiveData.selectedSerializedShipmentManifestItem?.length; i += chunkSize) {
      const chunk = receiveData.selectedSerializedShipmentManifestItem.slice(i, i + chunkSize);
      const chunkedManifest = { serializedShipmentManifestItem: chunk, shipmentReceiptId };
      const response: any = await bulkCreateShipmentIndividualReceive.mutateAsync([chunkedManifest]);

      setMessage(`serialised manifest ${chunk.length < 100 ? (i + chunk.length) : (i + chunkSize)} out of ${receiveData.selectedSerializedShipmentManifestItem?.length} created`);

      if (response?.length > 0) {
        shipmentReceiptId = response[0].shipmentReceiptId;
      }
    }

    for (let i = 0; i < receiveData.selectedNonSerializedShipmentManifestItem?.length; i += chunkSize) {
      const chunk = receiveData.selectedNonSerializedShipmentManifestItem.slice(i, i + chunkSize);
      const chunkedManifest = { nonSerializedShipmentManifestItem: chunk, shipmentReceiptId };
      const response: any = await bulkCreateShipmentIndividualReceive.mutateAsync([chunkedManifest]);

      setMessage(`non-serialised manifest ${chunk.length < 100 ? (i + chunk.length) : (i + chunkSize)} out of ${receiveData.selectedNonSerializedShipmentManifestItem?.length} created`);

      if (response?.length > 0) {
        shipmentReceiptId = response[0].shipmentReceiptId;
      }
    }

    await getAllManifestByShipment.refetch();

    setSerialisedSelectionModel([]);
    setNonSerialisedSelectionModel([]);
    setOpenToast(true);
    setIsOpen(false);
  };

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        Receive
      </Typography>
      <IncomingShipmentDetailsFragment
        shipmentId={(id: number) => setShipmentId(id)}
      />
      <Grid mt={2} ml={3}>
        <Typography variant={TypographyVariantProps.H6} fontWeight={600}>
          Manifest - Shipment{`${shipmentId === 0 ? '' : `#${shipmentId}`}`}
        </Typography>
        <MainBox>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={TypographyVariantProps.H6}>Manifest</Typography>
            {(receiveData?.serializedShipmentManifestItem?.length > 0 || receiveData?.nonSerializedShipmentManifestItem.length > 0) &&
              <BulkPutawayWrapper>
                <BulkPutaway 
                  onChange={(putawayLocationId, putawayLocationName) => {
                    setReceiveData(updatePutawayLocationForIndividualShipment(receiveData, putawayLocationId, putawayLocationName));
                  }} 
                  onApiInvoke={async (name: string) => {
                    if (name) {
                      setTextBoxClick(false);
                      return await reciveShipmentsSearchNodes.mutateAsync({
                        id: shipmentId,
                        recieveType: ShipmentRecieveType.Individual,
                        name
                      });
                    } else {
                      return [];
                    }
                  }}
                />
              </BulkPutawayWrapper>
            }
          </Stack>
          {receiveData?.serializedShipmentManifestItem.length > 0 && (
            <Grid>
              <StyledTypography variant={TypographyVariantProps.Body1}>
                Serialized Parts
              </StyledTypography>
              <SerialisedReceiveShipmentManifestItem
                serializedShipmentManifestItemData={receiveData?.serializedShipmentManifestItem}
                selectedSerializedShipmentManifestItem={selectedSerializedShipmentManifestItem}
                onChangeSelectedData={(item: Array<SerialisedReceiveShipmentManifestData>, selectedRowIds: any[]) => {
                  const filteredSelectedSerializedShipmentManifestItem = [ ...selectedSerializedShipmentManifestItem, ...item ].reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item.id === current.id);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, []);

                  const selectedRows = selectedRowIds.length > 0 ? filteredSelectedSerializedShipmentManifestItem.filter((item: any) => selectedRowIds.includes(parseInt(item.id))) : [];

                  setSelectedSerializedShipmentManifestItem(selectedRows);
                  setReceiveData({ ...receiveData, selectedSerializedShipmentManifestItem: selectedRows });
                }}
                onChange={(item: Array<SerialisedReceiveShipmentManifestData>) =>
                  setReceiveData({ ...receiveData, serializedShipmentManifestItem: item })
                }
                textBoxClick={textBoxClick}
                setTextBoxClick={setTextBoxClick}
                isLoading={getAllManifestByShipment.isLoading}
                selectionModel={serialisedSelectionModel}
                setSelectionModel={setSerialisedSelectionModel}
              />
            </Grid>
          )}
          {receiveData?.nonSerializedShipmentManifestItem.length > 0 && (
            <Grid>
              <StyledTypography variant={TypographyVariantProps.Body1}>
                Non Serialized Parts
              </StyledTypography>
              <NonSerialisedReceiveShipmentManifestItem
                nonSerializedShipmentManifestItemData={receiveData?.nonSerializedShipmentManifestItem}
                selectedNonSerializedShipmentManifestItem={selectedNonSerializedShipmentManifestItem}
                onChange={(item: Array<NonSerializedReceiveShipmentManifestData>) =>
                  setReceiveData({
                    ...receiveData,
                    nonSerializedShipmentManifestItem: item,
                  })
                }
                onChangeSelectedData={(item: Array<NonSerializedReceiveShipmentManifestData>, selectedRowIds: any[]) => {
                  const filteredSelectedNonSerializedShipmentManifestItem = [ ...selectedNonSerializedShipmentManifestItem, ...item ].reduce((acc: any, current: any) => {
                    const x = acc.find((item: any) => item.id === current.id);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, []);

                  const selectedRows = selectedRowIds.length > 0 ? filteredSelectedNonSerializedShipmentManifestItem.filter((item: any) => selectedRowIds.includes(parseInt(item.id))) : [];

                  setSelectedNonSerializedShipmentManifestItem(selectedRows);
                  setReceiveData({ ...receiveData, selectedNonSerializedShipmentManifestItem: selectedRows });
                }}
                textBoxClick={textBoxClick}
                setTextBoxClick={setTextBoxClick}
                isLoading={getAllManifestByShipment.isLoading}
                selectionModel={nonSerialisedSelectionModel}
                setSelectionModel={setNonSerialisedSelectionModel}
              />
            </Grid>
          )}
        </MainBox>
      </Grid>
      <Div direction="row" spacing={2}>
        <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Primary} onClick={onClick}>
          Receive
        </Button>
      </Div>
      {message &&
        <Snackbar open={isOpen}>
          <Alert severity="info" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      }
      <Snackbar open={openToast} autoHideDuration={2000}>
        <Alert
          severity="success"
          onClose={() => setOpenToast(false)}
          sx={{ width: '100%' }}
        >
          Successfully Received
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};