import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MultiSelectCheckmarksMenu } from '../../../../components/atoms/MultiSelectCheckmarksMenu';

export const MultiSelectPriorities = styled(MultiSelectCheckmarksMenu)`
  width: 200px;
`;

export const MultiSelectActionTypes = styled(MultiSelectCheckmarksMenu)`
  width: 250px;
`;

export const ProgressionBlocks = styled(MultiSelectCheckmarksMenu)`
  width: 600px;
`;

export const SwitchGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
`;