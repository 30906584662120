import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)`
  & .super-app.negative {
    background-color: #E0B73C;
  }

  & .super-app.positive {
    background-color: #ffffff,
  }
`;

export const AdvanceLiveSearchWrapper = styled('div')`
  width: 75%;
`;

export const ImportWrapper = styled('div')`
  width: 25%;
  display: flex;
  flex-direction: column;
`;
