import { styled } from '@mui/material/styles';
import { Autocomplete, MenuItem, Popper } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  padding-left: 0px;
  padding-right: 0px;
`;

export const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 200,
  zIndex: 1,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

export const StyledAutoComplete = styled(Autocomplete)`
  width: 200px;
`;