import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { StyledStack } from './LinkShipmentSearchItem.styles';
import { LinkShipmentSearchItemProps } from './LinkShipmentSearchItem.props';

export const LinkShipmentSearchItem: React.FC<LinkShipmentSearchItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Subtitle2}>AM-SID-{data.id}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Source: {data.source}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Destination: {data.destination}</Typography>
    </StyledStack>
  );
};