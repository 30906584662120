import React, { useEffect, useState } from 'react';
import { Snackbar, Alert, AlertTitle, Box, Divider, Stack, Table, TableBody, TableContainer } from '@mui/material';
import { Add } from '@mui/icons-material';
import { SiteSchemaPopupFragmentProps } from './SiteSchemaPopupFragment.props';
import { Switch } from '../../../../components/atoms/Switch';
import { PopupDialog } from '../../../../components/molecules/PopupDialog';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { TextField } from '../../../../components/atoms/TextField';
import { ColorProps, SiteSchemaData, SiteSchemaItem } from '../../../../@types';
import { SiteSchemaItemRowFragment } from './SiteSchemaItemRowFragment';
import { isValideStringLength } from '../../../../utils/common';
import { SITE_SCHEMA_CONSTANTS } from '../../../../constants/site-schema';
import { useCreateSiteSchema, useUpdateSiteSchema } from '../../../../queries/structure-query';

export const SiteSchemaPopupFragment: React.FC<SiteSchemaPopupFragmentProps> = ({
  isOpen,
  isEdit,
  siteSchemaData,
  nodeId,
  onClose
}) => {
  const [siteSchemaFormData, setSiteSchemaFormData] = useState<SiteSchemaData>();
  const [rowFormData, setRowFormData] = useState<Array<SiteSchemaItem>>([]);
  const [rowFormDataIndex, setRowFormDataIndex] = useState(-1);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const createSiteSchemaQuery = useCreateSiteSchema(nodeId);
  const updateSiteSchemaQuery = useUpdateSiteSchema(nodeId);

  useEffect(() => {
    setSiteSchemaFormData(siteSchemaData);
  }, [siteSchemaData]);

  const handleAddNew = () => {
    setRowFormData([
      ...rowFormData,
      {
        id: rowFormDataIndex,
        partTypeId: -1,
        nodeId,
        propertyTypeCode: 'site-schema',
        partTypeName: '',
        serviceType: [],
        softwareVersion: [],
        firmwareVersionId: [],
        hardwareRevisionId: [],
        fleetTagId: [],
        isActive: true
      }
    ]);
    setRowFormDataIndex(rowFormDataIndex -1);
  };

  const handleSave = () => {
    if (!isValideStringLength(siteSchemaFormData?.name || '', 100)) {
      setWarningMessage(SITE_SCHEMA_CONSTANTS.NAME_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (!isValideStringLength(siteSchemaFormData?.description || '', 100)) {
      setWarningMessage(SITE_SCHEMA_CONSTANTS.DESCRIPTION_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    const updatedSiteSchemaFormData = { ...siteSchemaFormData };

    if (rowFormData.length > 0) {
      const filteredFormData = rowFormData.filter((siteSchemaItem: SiteSchemaItem) => siteSchemaItem.partTypeId > 0);
      const newRowData: Array<SiteSchemaItem> = filteredFormData.map((obj: SiteSchemaItem) => {
        delete obj.id;
        return obj;
      });
      updatedSiteSchemaFormData.siteSchemaItems?.push(...newRowData);
    }

    if (isEdit) {
      siteSchemaFormData && updateSiteSchemaQuery.mutateAsync(updatedSiteSchemaFormData);
    } else {
      if (siteSchemaFormData) {
        delete updatedSiteSchemaFormData.id;
        createSiteSchemaQuery.mutateAsync(updatedSiteSchemaFormData);
      }
    }

    onClose();
  };

  return (
    <PopupDialog 
      maxWidth="1200px"
      title={`Site Schema ${isEdit ? 'Edit' : 'Add'}`} 
      isOpen={isOpen} 
      onClose={onClose} 
      onSave={handleSave}
    >
      <Stack direction="column">
        <Stack direction="row" spacing={2} marginTop={1} marginBottom={2}>
          <TextField
            label="Name"
            required
            value={siteSchemaFormData?.name || ''}
            onChange={(val) => {
              siteSchemaFormData && setSiteSchemaFormData({
                ...siteSchemaFormData,
                name: val 
              });
            }}
          />
          <TextField
            label="Description"
            required
            value={siteSchemaFormData?.description || ''}
            onChange={(val) => {
              siteSchemaFormData && setSiteSchemaFormData({
                ...siteSchemaFormData,
                description: val 
              });
            }}
          />
          <Stack width="60px">
            <Typography variant={TypographyVariantProps.Caption}>Is Active</Typography>
            <Switch
              color={ColorProps.Success}
              checked={siteSchemaFormData?.isActive}
              onChange={() => {
                siteSchemaFormData && setSiteSchemaFormData({
                  ...siteSchemaFormData,
                  isActive: !siteSchemaFormData.isActive 
                });
              }}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" marginBottom={1}>
          <Typography>Site Schema List</Typography>
          <Button variant={ButtonVariantProps.Secondary} onClick={handleAddNew} startIcon={<Add/>}>
            Add
          </Button> 
        </Stack>
        <Divider/>
        <br/>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {siteSchemaFormData?.siteSchemaItems?.map((val: SiteSchemaItem, index) => 
                <SiteSchemaItemRowFragment 
                  key={index}
                  nodeId={nodeId}
                  value={val}
                  onChange={(val) => {
                    const siteSchemaItems = siteSchemaFormData?.siteSchemaItems?.map((obj) => obj.id === val.id ? val : obj);
                    siteSchemaItems && setSiteSchemaFormData({ 
                      ...siteSchemaFormData, 
                      siteSchemaItems 
                    });
                  }}
                />
              )}
              {rowFormData?.map((val: SiteSchemaItem, index) => 
                <SiteSchemaItemRowFragment 
                  key={index}
                  isNew={true}
                  nodeId={nodeId}
                  value={val}
                  onChange={(val) => {
                    const updatedFormData = rowFormData?.map((obj) => obj.id === val.id ? val : obj);
                    updatedFormData && setRowFormData(updatedFormData);
                  }}
                  onCancel={() => {
                    const filteredFormData = rowFormData?.filter((obj) => obj.id !== val.id);
                    filteredFormData && setRowFormData(filteredFormData);
                  }}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={4000} onClose={() => setShowWarningToast(false)}>
        <Alert onClose={() => setShowWarningToast(false)} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Box>{warningMessage}</Box>
        </Alert>
      </Snackbar>
    </PopupDialog>
  );
};
