import React, { SyntheticEvent } from 'react';
import { Accordion as MuiAccordion, AccordionDetails } from '@mui/material';
import { AccordianProps } from './Accordian.props';
import { Typography } from '../Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledAccordionSummary } from './Accordian.styles';

export const Accordian: React.FC<AccordianProps> = ({
  title,
  expanded,
  children,
  onChange,
  defaultExpanded
}) => {
  return (
    <MuiAccordion expanded={expanded} onChange={(event: SyntheticEvent, newExpanded: any) => onChange && onChange(event, newExpanded)} defaultExpanded={defaultExpanded}>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        {typeof title === 'string' ? (<Typography>{title}</Typography>) : title}
      </StyledAccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};