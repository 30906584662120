import { styled } from '@mui/material/styles';
import { InlineGroup } from '../../atoms/InlineGroup';

export const Root = styled(InlineGroup)`
  flex-direction: column;
  background: #ffff;

  > *:not(:first-child) {
    margin-top: 24px;
  }
`;

export const Container = styled('div')`
  flex: 1;
`;