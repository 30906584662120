import React from 'react';
import { Stack } from '@mui/material';
import { PartTypeShipmentPopupProps } from '.';
import { ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { StyledButton, StyledWrapper } from './PartTypeShipmentPopup.styles';
import { NonSerializedShipmentManifestData } from '../../../@types/shipment.type';

export const PartTypeShipmentPopup: React.FC<PartTypeShipmentPopupProps> = ({
  data,
  onClick,
}) => {
  return (
    <StyledWrapper>
      <Stack direction="column" spacing={0.5}>
        {data?.map((item: NonSerializedShipmentManifestData, index: number) => {          
          return (
            <StyledButton key={index}
              variant={ButtonVariantProps.Primary}
              onClick={item && onClick && (() => onClick(item))}
              fullWidth
              size={ButtonSizeProps.Large}
            >
              <Typography variant={TypographyVariantProps.Body2}>
                {`SHIPMENT ID: ${item.shipmentId} | CONDITION: ${item.conditionCode} | FLEET TAGS: ${item.fleetTags} | QTY: ${item.qty}`}
              </Typography>
            </StyledButton>
          );
        })}
      </Stack>
    </StyledWrapper>
  );
};
