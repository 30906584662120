import React, { SyntheticEvent, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Grid, IconButton, Stack } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { GridContainer, StyledModalBox, ModalContent, ModalFooter, HeadingWrapper } from './AllocationRuleList.styles';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { AllocationConfigData, AllocationResponse } from '../../../@types/allocation.type';
import { selectionData, usageData } from './AllocationRuleList.data';
import { DropdownItem, SelectMenu } from '../../../components/atoms/SelectMenu';
import { useGetServiceActionProcessTypes } from '../../../queries/service-query';
import { LabelValue, LocationNodeData, NodeType, ServiceCommonType, StatusChangeData } from '../../../@types';
import { actionTypesData, processTypesData, serviceTypesData } from '../../../utils/services';
import { useGetRegionTypes } from '../../../queries/regionTypes-query';
import { useGetAllServiceStatus, useGetPossibleStatusesByServiceTypes } from '../../../queries/service-status-query';
import { useCreateAllocationConfig, useGetAllAllocationConfigs, useUpdateAllocationConfig } from '../../../queries/allocation-query';
import { MultiSelectCheckmarksMenu } from '../../../components/atoms/MultiSelectCheckmarksMenu';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../components/atoms/FormControlLabel';
import { SelectionButton } from '../../../components/molecules/SelectionButton';
import { useGetNodeChildrenByNodeType, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../queries/structure-query';
import { StructureTypeCategory } from '../../../configs/enums';
import { MultiClientSelector } from '../../../components/molecules/MultiClientSelector';
import { TextField } from '../../../components/atoms/TextField';
import { DataGrid } from '../../../components/atoms/DataGrid';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useSearchNodeOperational } from '../../../queries/live-search-query';
import { SAMPLE_SERVICE_COMMON_TYPE } from '../../../constants/service.constant';
import { Wrapper } from '../../../styled/common.styles';
import { STATUS_CHANGE_DATA } from '../../../constants/serviceStatus.constant';
import { ANY_ITEM_ARRAY, ANY_KEYWORD, NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { Modal } from '../../../components/atoms/Modal';
import { CloseIcon } from '../../../components/atoms/CloseIcon';
import { isDropDownEmpty, isEmptyString, nameValidation } from '../../../utils/common';

export const AllocationRuleList: React.FC = () => {
  const activeOnly = true;
  const [allocationRules, setAllocationRules] = useState<Array<AllocationResponse>>([]);
  const [serviceCommonTypes, setServiceCommonTypes] = useState<ServiceCommonType>(SAMPLE_SERVICE_COMMON_TYPE);
  const [regionTypeList, setRegionTypeList] = useState<DropdownItem[]>([]);
  const [statusesList, setStatusesList] = useState<LabelValue[]>([]);
  const [allStatusesList, setAllStatusesList] = useState<LabelValue[]>([]);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>(ANY_ITEM_ARRAY);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState<AllocationConfigData>();
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [selectedParentHierarchy, setSelectedParentHierarchy] = useState('');
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [statusChangeData, setStatusChangeData] = useState<StatusChangeData>(STATUS_CHANGE_DATA);
  const [validate, setValidate] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');

  const allocationConfigsQuery = useGetAllAllocationConfigs();
  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const regionTypesQuery = useGetRegionTypes();
  const getServicesStatuses = useGetPossibleStatusesByServiceTypes(statusChangeData);
  const getAllServicesStatuses = useGetAllServiceStatus();
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const searchNodeQuery = useSearchNodeOperational();
  const createAllocationConfig = useCreateAllocationConfig();
  const updateAllocationConfig = useUpdateAllocationConfig();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.05,
      valueGetter: (params: GridValueGetterParams) => params.row.id
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'selection', 
      headerName: 'Selection', 
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => params.row.selection,
      renderCell: (params: GridRenderCellParams) => <Grid>{selectionData?.find((item) => item.value === params.row.selection)?.label}</Grid>
    },
    {
      field: 'serviceType', 
      headerName: 'Service Type', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceType,
      renderCell: (params: GridRenderCellParams) => <Grid>
        {serviceTypesData(serviceCommonTypes.serviceTypes)?.reduce((accumulator: string[], currentValue: DropdownItem) => {
          if (params.row.serviceType.includes(currentValue.value)) {
            return [...accumulator, currentValue.label];
          }
          return accumulator;
        }, []).join(', ')}</Grid>
    },
    {
      field: 'actionType', 
      headerName: 'Action Type', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.actionType,
      renderCell: (params: GridRenderCellParams) => <Grid>
        {actionTypesData(serviceCommonTypes.actionTypes)?.reduce((accumulator: string[], currentValue: DropdownItem) => {
          if (params.row.actionType.includes(currentValue.value)) {
            return [...accumulator, currentValue.label];
          }
          return accumulator;
        }, []).join(', ')}</Grid>
    },
    {
      field: 'processType', 
      headerName: 'Process Type', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.processType,
      renderCell: (params: GridRenderCellParams) => <Grid>
        {processTypesData(serviceCommonTypes.processTypes)?.reduce((accumulator: string[], currentValue: DropdownItem) => {
          if (params.row.processType.includes(currentValue.value)) {
            return [...accumulator, currentValue.label];
          }
          return accumulator;
        }, []).join(', ')}</Grid>
    },
    {
      field: 'clientContract', 
      headerName: 'Client/Contract', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.clientContract,
      renderCell: (params: GridRenderCellParams) => getClientSelection(params.row.clientContract, false)
    },
    {
      field: 'regionType', 
      headerName: 'Region Type', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.regionType,
      renderCell: (params: GridRenderCellParams) => <Grid>
        {regionTypeList?.reduce((accumulator: string[], currentValue) => {
          if (params.row.regionType.includes(currentValue.value)) {
            return [...accumulator, currentValue.label];
          }
          return accumulator;
        }, []).join(', ')}</Grid>
    },
    {
      field: 'node', 
      headerName: 'Specific Allocation', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.node?.name ?? 'none'
    },
    {
      field: 'usage', 
      headerName: 'Specific Allocation Usage', 
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => params.row.usage,
      renderCell: (params: GridRenderCellParams) => <Grid>{usageData?.find((item) => item.value === params.row.usage)?.label}</Grid>
    },
    {
      field: 'checkCapacity', 
      headerName: 'Check Capacity', 
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.checkCapacity
    },
    {
      field: 'nextServiceStatus', 
      headerName: 'Status Change', 
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.nextServiceStatus,
      renderCell: (params: GridRenderCellParams) => <Grid>{allStatusesList?.find((item) => item.value === params.row.nextServiceStatus)?.label || 'none'}</Grid>
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 0.5,
      valueGetter: (params: GridValueGetterParams) => params.row.notes
    },
    {
      field: 'isActive', 
      headerName: 'Active', 
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 50,
      flex: 0.1,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => <Grid>
        <IconButton aria-label="edit" onClick={(e) => {
          const foundIndex = allocationRules.findIndex(x => x.id === params.row.id);
          handleEdit(e, foundIndex);
        }}>
          <Edit />
        </IconButton>
      </Grid>
    }
  ];

  TabHandler(`Allocation Rules | ${PLATFORM_NAME}`);
  
  useEffect(() => {
    getAllServicesStatuses.refetch();    
  }, []);

  useEffect(() => {
    allocationConfigsQuery.data && setAllocationRules(allocationConfigsQuery.data);      
  }, [allocationConfigsQuery.isLoading, allocationConfigsQuery.isSuccess, allocationConfigsQuery.isRefetching]);

  useEffect(() => {
    serviceActionProcessTypesQuery.data && setServiceCommonTypes(serviceActionProcessTypesQuery.data);
  }, [serviceActionProcessTypesQuery.data]);

  useEffect(() => {
    if (regionTypesQuery.data) {
      const regionTypesItems: DropdownItem[] = [];
      const anyWildCard = ANY_ITEM_ARRAY[0];
      regionTypesItems.push(anyWildCard);
      regionTypesQuery?.data?.map((item) => {
        const regionType = {
          value: item.code,
          label: item.name
        };
        regionTypesItems.push(regionType);
      });
      setRegionTypeList(regionTypesItems);
    }
  }, [regionTypesQuery.data]);
  
  useEffect(() => {
    if (getServicesStatuses.data) {
      const values = getServicesStatuses.data.possibleStatuses.filter((item) => item.isActive);
      setStatusesList(values.map(listData => (
        { value: listData.code, label: listData.name }
      )));
    }
  }, [getServicesStatuses.data]);
  
  useEffect(() => {
    if ((getAllServicesStatuses.isSuccess || getAllServicesStatuses.isRefetching) && getAllServicesStatuses.data) {
      const values = getAllServicesStatuses.data.filter((item) => item.isActive);
      setAllStatusesList(values.map(listData => (
        {
          value: listData.code,
          label: listData.name,
          disabled: !listData.isActive
        }
      )));
    }
  }, [getAllServicesStatuses.isLoading, getAllServicesStatuses.isSuccess, getAllServicesStatuses.isRefetching]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.isSuccess) {
      setClients(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive })));
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.isLoading, getStructuresByTypeCategoryCodeAndTypeCode.isSuccess, getStructuresByTypeCategoryCodeAndTypeCode.isRefetching]);

  useEffect(() => {
    if ((getNodeChildrenByNodeType.isSuccess || getNodeChildrenByNodeType.isRefetching) && getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        setContracts(ANY_ITEM_ARRAY.concat(getNodeChildrenByNodeType.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
      }
    }
  }, [getNodeChildrenByNodeType.isLoading, getNodeChildrenByNodeType.isSuccess, getNodeChildrenByNodeType.isRefetching]);

  useEffect(() => {
    if (selectedParentId && queryNodeType && selectedParentId !== ANY_KEYWORD && selectedParentId.split(',').length === 1) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    statusChangeData.serviceTypes.length > 0 && getServicesStatuses.refetch();
  }, [statusChangeData.serviceTypes]);

  useEffect(() => {
    setModalData({ 
      ...modalData, 
      clientContract: getClientDataForSaving(selectedClients, selectedContracts)
    });
  }, [selectedClients, selectedContracts]);

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };
  
  const getClientSelection = (clientContract: any, editMode: boolean) => {
    // get client ids and names as value and label
    const clients = clientContract?.reduce((accumulator: string[], currentValue: any) => {
      if (currentValue.nodeTypeCode === NodeType.Client) {
        return [...accumulator, { value: currentValue.id.toString(), label: currentValue.name }];
      }
      return accumulator;
    }, []);

    // get contract ids and names as value and label
    const contracts = clientContract?.reduce((accumulator: string[], currentValue: any) => {
      if (currentValue.nodeTypeCode === NodeType.Contract) {
        return [...accumulator, { value: currentValue.id.toString(), label: currentValue.name }];
      }
      return accumulator;
    }, []);

    // map names in array to comma seperated string
    const clientData = clients.map((item: DropdownItem) => item.label).join(', ');
    const contractData = contracts.map((item: DropdownItem) => item.label).join(', ');

    // get client and contact ids to string array
    const clientValues = clients.map((item: DropdownItem) => item.value);
    const contractValues = contracts.map((item: DropdownItem) => item.value);
    
    if (clients?.length !== 0 && (clients?.length > 1 || contracts?.length === 0)){
      // if multiple clients or no contracts
      // set client ids and set selected contracts to any if in edit mode
      if (editMode){
        setSelectedClients(clientValues);
        setSelectedContracts([ANY_KEYWORD]);
      }

      // append contracts as Any in display string
      return clientData.concat(' -> Any');
    } else if (contracts?.length > 0){
      // if one client and one or many contracts
      if (editMode){
        setSelectedClients(clientValues);
        setSelectedParentId(clientValues[0]);
        // get dropdown values for contracts dropdown
        setQueryNodeType(NodeType.Contract);
        setSelectedContracts(contractValues);
      }

      // append contracts to display string as comma seperated names
      return clientData.concat(' -> ').concat(contractData);
    } else {
      if (editMode){
        setSelectedClients([]);
        setSelectedContracts([]);
      }

      // if no client contract data set display string to none
      return 'none';
    }
  };

  // map client contract data acc to required format in DB
  const getClientDataForSaving = (selectedClients: string[], selectedContracts: string[]) => {
    if (selectedContracts.includes(ANY_KEYWORD))
      return {
        selectedNodeType: NodeType.Client,
        nodeIds: selectedClients.map((id) => parseInt(id))
      };
    else if (selectedClients.length === 1)
      return {
        selectedNodeType: NodeType.Contract,
        nodeIds: selectedContracts.map((id) => parseInt(id))
      };
    else
      return null;
  };

  const addNewRule = () => {
    const newRule: AllocationConfigData = {
      id: 0,
      name: '',
      selection: '-1',
      serviceType: [],
      actionType: [],
      processType: [],
      regionType: [],
      usage: '-1',
      checkCapacity: true,
      nextServiceStatus: '',
      isActive: true,
      notes: '',
      clientContract: null
    };
    setSelectedClients([]);
    setSelectedContracts([]);
    setSelectedParentHierarchy('');
    setModalData(newRule);
    setOpenModal(true);
  };

  const handleEdit = (e: SyntheticEvent, id: number) => {
    e.stopPropagation();
    const data = allocationRules[id];
    setStatusChangeData({
      code: 'new',
      serviceTypes: data.serviceType
    });
    setSelectedParentHierarchy(getClientSelection(data.clientContract, true));
    setModalData({ 
      ...data, 
      nodeId: data.node?.id,
    });
    setOpenModal(true);
  };

  const handleSave = () => {
    const data = modalData;

    setNameHelperText(nameValidation(data?.name ||'', NAME_FIELD_HELPER_TEXT));

    const isNameEmpty = data?.name && isEmptyString(data.name);
    const isSelectionEmpty = data?.selection && isDropDownEmpty(data.selection);
    const isUsageEmpty = data?.usage && isDropDownEmpty(data.usage);

    if (isNameEmpty || isSelectionEmpty || isUsageEmpty) {
      setValidate(true);
      return;
    }

    if (data?.id === 0) {
      delete data?.id;
      createAllocationConfig.mutateAsync(data);
    } else {
      updateAllocationConfig.mutateAsync(data);
    }
    setSelectedParentHierarchy('');
    setSelectedParentId('');
    setQueryNodeType(NodeType.Client);
    setOpenModal(false);
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Allocation Rules
          </Typography>
          <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addNewRule()}>
            ADD NEW RULE
          </Button>
        </InlineGroup>
        <GridContainer>
          <DataGrid
            columns={columns}
            rows={allocationRules}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              }
            }}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'super-app-theme--even' : 'super-app-theme--odd' }
            getRowHeight={() => 'auto'}
            loading={allocationConfigsQuery.isLoading}
          />
        </GridContainer>
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <StyledModalBox>
            <CloseIcon handleClose={() => {
              setOpenModal(false);
              setValidate(false);
            }} />
            <ModalContent>
              <HeadingWrapper variant={TypographyVariantProps.H6}>
                Allocation Config #{`${modalData?.id || 'New'}`}
              </HeadingWrapper>
              <Stack direction="column" mb={GRID_SPACING} spacing={GRID_SPACING}>
                <Grid container spacing={GRID_SPACING}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      error={!!nameHelperText}
                      helperText={nameHelperText}
                      label="Name"
                      value={modalData?.name}
                      onChange={(name) => {
                        setNameHelperText(nameValidation(name, NAME_FIELD_HELPER_TEXT));
                        setModalData({ ...modalData, name });
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectMenu
                      id="selection-select"
                      label="Selection *"
                      required={true}
                      items={selectionData}
                      selectedValue={modalData?.selection || '-1'}
                      onChange={(value) => setModalData({ ...modalData, selection: value === '-1' ? '' : value })}
                      validate={validate}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                </Grid>
                <MultiSelectCheckmarksMenu 
                  id="service-type-options"
                  label="Service Type"
                  selectedValue={modalData?.serviceType}
                  onChange={(value) => {
                    const serviceTypes = value === '' ? [] : value.split(',');
                    setStatusChangeData({
                      code: 'new',
                      serviceTypes: serviceTypes
                    });
                    setModalData({ ...modalData, serviceType: serviceTypes });
                  }}
                  wildCard={false}
                  items={serviceTypesData(serviceCommonTypes.serviceTypes)} 
                />
                <MultiSelectCheckmarksMenu
                  id="action-type-options"
                  label="Action Type"
                  selectedValue={modalData?.actionType}
                  onChange={(value) => setModalData({ ...modalData, actionType: value === '' ? [] : value.split(',') })}
                  items={actionTypesData(serviceCommonTypes.actionTypes)}
                />
                <MultiSelectCheckmarksMenu
                  id="process-type-options"
                  label="Process Type"
                  selectedValue={modalData?.processType}
                  onChange={(value) => setModalData({ ...modalData, processType: value === '' ? [] : value.split(',') })}
                  items={processTypesData(serviceCommonTypes.processTypes)} 
                />
                <SelectionButton
                  label="Client Selection"
                  value={selectedParentHierarchy}
                  onClick={() => {
                    setOpenParentSelector(true);
                    getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                  }}
                />
                <MultiSelectCheckmarksMenu
                  id="region-select"
                  label="Region Type"
                  selectedValue={modalData?.regionType}
                  onChange={(value) => setModalData({ ...modalData, regionType: value === '' ? [] : value.split(',') })}
                  items={
                    modalData?.regionType?.some((regionType: string) => regionType === ANY_KEYWORD) ?
                      regionTypeList.filter((regionTypeItem: any) => regionTypeItem.value === ANY_KEYWORD) :
                      regionTypeList
                  } 
                />
              </Stack>
              <Grid mb={GRID_SPACING}>
                <LiveSearchBox
                  title="Specific allocation"
                  timeOffset={400}
                  value={modalData?.node?.name ?? ''}
                  onClearValue={() => setModalData({ ...modalData, nodeId: undefined, node: undefined })}
                  renderItem={(props: any, option: any) => {
                    return (
                      <LiveSearchListItem {...props}>
                        <LocationSearchItem data={option} />
                      </LiveSearchListItem>
                    );
                  }}
                  onChange={async (obj: LocationNodeData) => setModalData({ ...modalData, nodeId: obj.id, node: obj })}
                  onApiInvoke={async (obj) => {
                    return (await searchNodeQuery.mutateAsync(obj)).filter((result) => result.canHaveAllocations);
                  }}
                />
              </Grid>            
              <Grid mb={GRID_SPACING} container spacing={GRID_SPACING}>
                <Grid item xs={6}>
                  <SelectMenu
                    id="usage-select"
                    label="Usage *"
                    required={true}
                    items={usageData}
                    selectedValue={modalData?.usage || '-1'}
                    onChange={(value) => setModalData({ ...modalData, usage: value })}
                    validate={validate}
                    optionalLabelEnabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectMenu
                    id="status-select"
                    label="Status"
                    disabled={modalData?.serviceType?.length === 0}
                    items={statusesList}
                    selectedValue={modalData?.nextServiceStatus || '-1'}
                    onChange={(value) => setModalData({ ...modalData, nextServiceStatus: value === '-1' ? '' : value })}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
              <Stack>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  value={modalData?.notes}
                  onChange={(value) => setModalData({ ...modalData, notes: value })}
                />
                <Stack direction="row" spacing={GRID_SPACING}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={CheckboxColorProps.Primary}
                        checked={modalData?.checkCapacity}
                        onChange={(event) => setModalData({ ...modalData, checkCapacity: event.target.checked })}
                      />
                    }
                    label="Check capacity"
                    labelPlacement={FormControlLabelPlacementProps.End}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color={CheckboxColorProps.Primary}
                        checked={modalData?.isActive}
                        onChange={(event) => setModalData({ ...modalData, isActive: event.target.checked })}
                      />
                    }
                    label="Is active"
                    labelPlacement={FormControlLabelPlacementProps.End}
                  />
                </Stack>
                <MultiClientSelector
                  open={openParentSelector}
                  onChangeNodeType={onChangeNodeType}
                  onClose={() => setOpenParentSelector(false)}
                  handleParentSelection={(selectedClients: string[], selectedContracts: string[], clientHierarchy: string) => { 
                    setOpenParentSelector(false);
                    setSelectedParentHierarchy(clientHierarchy);
                    if (selectedClients.length > 0) {
                      setSelectedClients(selectedClients);
                      setSelectedContracts(selectedContracts.length > 0 ? selectedContracts : [ANY_KEYWORD]);
                    }
                    setModalData({ ...modalData, clientContract: getClientDataForSaving(selectedClients, selectedContracts) });
                  }}
                  clients={clients}
                  contracts={contracts}
                  hasBrandSelection={false}
                  selectedMultiClients={selectedClients}
                  selectedMultiContracts={selectedContracts}
                  isMandotory={true}
                />
              </Stack>
            </ModalContent>
            <ModalFooter>
              <Button variant={ButtonVariantProps.Secondary} onClick={() => {
                setOpenModal(false);
                setValidate(false);
              }}>
                Cancel
              </Button>
              <Grid mx={3}>
                <Button variant={ButtonVariantProps.Primary} onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </ModalFooter>
          </StyledModalBox>
        </Modal>
      </Wrapper>
    </PageContainer>
  );
};