import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import ScheduleEditor from 'react-js-cron-mui';
import { Footer, StyledButton, StyledSelectMenu } from './ScheduleEmailFragment.styles';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { ScheduleEmailFragmentProps } from './ScheduleEmailFragment.props';
import { TextField } from '../../../../../components/atoms/TextField';
import { getAttachmentTypes, getScheduleTimeUsingType, getScheduleTypeUsingTime } from '../../../../../utils/report';
import { HorizontalPosition, ScheduleType, VerticlePosition, WarningMessages } from '../../../../../configs/enums';
import { AutocompleteTagType, Tags } from '../../../../../components/organisms/Tags';
import { ColorProps, Tag } from '../../../../../@types';
import { useAddScheduleReport, useGetScheduleReportDataByReportId, useSendSavedReportTestEmail, useUpdateReportScheduleEmailByReportId } from '../../../../../queries/report-query';
import { Checkbox, CheckboxColorProps } from '../../../../../components/atoms/Checkbox';
import { SCHEDULE_TYPE } from '../../../../../constants/report';
import { ContentWrapperBox } from './ScheduleEmailFragment.styles';
import { AlertMessage } from '../../../../../components/molecules/AlertMessage';
import { isDropDownEmpty } from '../../../../../utils/common';
import { Snackbar } from '../../../../../components/atoms/Snackbar';

export const ScheduleEmailFragment: React.FC<ScheduleEmailFragmentProps> = ({
  reportId,
  attachmentTypes,
  handleRefetch
}) => {
  const [attachmentType, setattachmentType] = useState<number>(attachmentTypes[0]?.id);
  const [emailBody, setEmailBody] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [scheduleValue, setScheduleValue] = useState('');
  const [scheduleType, setScheduleType] = useState<string>('-1');
  const [disabledScheduleEditor, setDisabledScheduleEditor] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<(AutocompleteTagType | string)[]>([]);
  const [newEmails, setNewEmails] = useState<Tag[]>([]);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [warningMessage, setWarningMessage] = useState('Empty Fields!');
  const [testEmailStatus, setTestEmailStatus] = useState<string>('');

  const createScheduleReport = useAddScheduleReport();
  const updateScheduleReport = useUpdateReportScheduleEmailByReportId(reportId?.toString() || '');
  const getScheduleEmailDataQuery = useGetScheduleReportDataByReportId(reportId?.toString() || '');
  const sendSavedReportTestEmail = useSendSavedReportTestEmail();

  useEffect(() => {
    getScheduleEmailDataQuery.refetch();
  }, [reportId]);

  useEffect(() => {
    if (getScheduleEmailDataQuery.data) {
      const cronValue = getScheduleEmailDataQuery.data.schedule?.minute.concat(
        ' ', 
        getScheduleEmailDataQuery.data.schedule.hour, 
        ' ', 
        getScheduleEmailDataQuery.data.schedule.dayOfMonth, 
        ' ', 
        getScheduleEmailDataQuery.data.schedule.month, 
        ' ', 
        getScheduleEmailDataQuery.data.schedule.dayOfWeek
      );
      setattachmentType(getScheduleEmailDataQuery.data.attachmentTypeId);
      setEmailBody(getScheduleEmailDataQuery.data.body);
      setSubject(getScheduleEmailDataQuery.data.subject);
      setIsActive(getScheduleEmailDataQuery.data.isActive);
      setSelectedEmails(getScheduleEmailDataQuery.data.recepients.slice(1, -1).split(','));

      const scheduleType: string = getScheduleTypeUsingTime(cronValue);
      if (scheduleType === ScheduleType.Custom) {
        setDisabledScheduleEditor(false);
      }
      setScheduleType(scheduleType);
      setScheduleValue(cronValue);
    }

  }, [getScheduleEmailDataQuery.data]);

  useEffect(() => {
    if (testEmailStatus !== '' && testEmailStatus !== 'Email report generation inprogress!') {
      setTimeout(() => {
        setTestEmailStatus('');
      }, 3000);
    }
  }, [testEmailStatus]);

  const handleSave = async() => {
    if (isDropDownEmpty(attachmentType.toString())) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.AttachmentTypeEmpty);
    } else if (selectedEmails.length === 0) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.RecipientsEmpty);
    } else if (!subject) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.SubjectEmpty);
    } else if (!emailBody) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.EmailBodyEmpty);
    } else if (isDropDownEmpty(scheduleType)) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.ScheduleTypeEmpty);
    } else if (!scheduleValue) {
      setShowWarningToast(true);
      setWarningMessage(WarningMessages.ScheduleTimeEmpty);
    } else {
      const scheduleSplitArray = scheduleValue.split(' ');

      const scheduleData = {
        reportId: reportId,
        attachmentTypeId: attachmentType,
        recepients: `[${selectedEmails}]`,
        subject: subject,
        body: emailBody,
        schedule: {
          minute: scheduleSplitArray[0],
          hour: scheduleSplitArray[1],
          dayOfMonth: scheduleSplitArray[2],
          month: scheduleSplitArray[3],
          dayOfWeek: scheduleSplitArray[4]
        },
        isActive : isActive
      };
  
      if (getScheduleEmailDataQuery.data && getScheduleEmailDataQuery.data.id) {
        await updateScheduleReport.mutateAsync(scheduleData);
      } else {
        await createScheduleReport.mutateAsync(scheduleData);
      }
      getScheduleEmailDataQuery.refetch();
      handleRefetch(true);
    }
  };

  const handleSend = async() => {
    setTestEmailStatus('');
    if (!subject) {
      setTestEmailStatus('Test email subject is required');
      return;
    }
    if (!emailBody) {
      setTestEmailStatus('Test email body is required');
      return;
    }
    const testEmailData = {
      reportId: reportId,
      attachmentTypeId: attachmentType,
      subject: subject,
      emailBody: emailBody
    };

    setTestEmailStatus('Email report generation inprogress!');
    let testEmailResponse: { success: boolean; message: string };
    try {
      testEmailResponse = await sendSavedReportTestEmail.mutateAsync(testEmailData);
      setTestEmailStatus(testEmailResponse.message);
    } catch (error: any) {
      setTestEmailStatus(error?.response?.data?.message || 'Test email sending failed!');
    }
  };

  const onChangeScheduleType = (type: string) => {
    setScheduleType(type);
    if (type === ScheduleType.Custom) {
      setDisabledScheduleEditor(false);
    } else {
      setDisabledScheduleEditor(true);
    }
    const scheduleTime = getScheduleTimeUsingType(type);
    setScheduleValue(scheduleTime);
  };

  const onChangeSelectedEmails = (selectedEmail: (string | AutocompleteTagType)[]) => {
    setSelectedEmails(selectedEmail);
  };

  const onChangeNewEmails = (newEmail: Tag[]) => {
    setNewEmails(newEmail);
  };

  return (
    <>
      <ContentWrapperBox>
        <Grid container ml={2} mt={3}>
          <Grid item xs={6}>
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={isActive}
              disabled={false}
              onChange={(event) =>setIsActive(event.target.checked)}
            /> Set Email Active
          </Grid>
        </Grid>
        <Grid container ml={2} mt={3}>
          <Grid item xs={6}>
            <StyledSelectMenu
              id="email-attachment-type-menu"
              label="Attachment Type *"
              minWidth={240}
              required
              selectedValue={attachmentType || '-1'}
              onChange={(attachmentId: number) => setattachmentType(attachmentId)}
              items={getAttachmentTypes(attachmentTypes || [])}
              optionalLabelEnabled={true}
            />
          </Grid>
        </Grid>
        <Grid container ml={2} mt={3}>
          <Grid item xs={11}>
            <Tags
              allTags={[]}
              selectedTags={selectedEmails}
              setSeletedTags={onChangeSelectedEmails}
              newTags={newEmails}
              setNewTags={onChangeNewEmails}
              label="Recipients *"
              placeHolder="Enter email address(es)"
            />
          </Grid>
        </Grid>
        <Grid container ml={2} mt={3}>
          <Grid item xs={11} mb={3}>
            <TextField
              label="Subject" 
              required
              fullWidth
              value={subject}
              onChange={(subjectText) => setSubject(subjectText)}
            />
          </Grid>
          <Grid item xs={11}>
            <TextField
              label="Body" 
              multiline
              required
              rows={3}
              fullWidth
              value={emailBody}
              onChange={(bodyText) => setEmailBody(bodyText)}
            />
          </Grid>
        </Grid>
        <Grid container ml={2} mt={3}>
          <Grid item xs={6}>
            <StyledSelectMenu
              id="email-schedule-type-"
              label="Schedule (All times are in Melbourne timezone) *"
              minWidth={240}
              required
              items={SCHEDULE_TYPE}
              selectedValue={scheduleType}
              onChange={(type: string) => onChangeScheduleType(type)}
              optionalLabelEnabled={true}
              optionalLabel="Select Schedule"
            />
          </Grid>
        </Grid>
        {scheduleType !== '-1' &&
          <Grid container ml={2} mt={3}>
            <Grid item xs={6}>
              <ScheduleEditor
                disabled={disabledScheduleEditor}
                clockFormat="12-hour-clock"
                value={scheduleValue}
                setValue={(scheduleTime: string) => setScheduleValue(scheduleTime)}
              />
            </Grid>
          </Grid>
        }
        <Grid container ml={1} mb={2}>
          <Footer>
            <StyledButton variant={ButtonVariantProps.Primary} onClick={handleSave}>{getScheduleEmailDataQuery.data && getScheduleEmailDataQuery.data.id ? 'Update Emailing Options' : 'Save Emailing Options' }</StyledButton>
            <StyledButton variant={ButtonVariantProps.Primary} color={ButtonColorProps.Error} onClick={handleSend}>Send Test Email (to yourself)</StyledButton>
          </Footer>
        </Grid>
        <AlertMessage horizontalPosition={HorizontalPosition.Right} verticalPosition={VerticlePosition.Top} alertMessage={warningMessage} alertTitle={ColorProps.Warning} open={showWarningToast} onClose={() => setShowWarningToast(false)}/>
        <Snackbar
          open={testEmailStatus !== ''}
          message={testEmailStatus}
          onClose={(_,reason) => {
            if (reason === 'clickaway' || reason === 'escapeKeyDown') {
              return;
            } else {
              setTestEmailStatus('');
            }
          }}
        />
      </ContentWrapperBox>
    </>
  );
};