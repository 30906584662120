import React, { useEffect, useState } from 'react';
import { DropdownItem, SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../../components/molecules/LocationSearchItem';
import { AutocompleteTagType, Tags } from '../../../../../components/organisms/Tags';
import { useGetServiceStatuses } from '../../../../../queries/service-status-query';
import { useSearchAllocationNodes } from '../../../../../queries/live-search-query';
import { PARAMETER_FORM_TYPE_PARAMETER_OPTIONS, PARAMETER_UI_FORM_PART_ATTRIBUTES, PARAMETER_UI_FORM_PART_MOVEMENT_TYPES } from '../../../../../constants/form-builder';
import { ActionPermissions, CustomPermission, LocationNodeData, ParameterDataFormParameterType, Tag } from '../../../../../@types';
import { getAllTags } from '../../../../../queries/tags-query';
import { EntityType } from '../../../../../configs/enums';
import { ParameterFormBuilderFieldFragmentProps } from './ParameterFormBuilderFieldFragment.props';
import { StyledStack } from './ParameterFormBuilderFieldFragment.styles';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';

export const ParameterFormBuilderFieldFragment: React.FC<ParameterFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const [presetSuggestion1Options, setPresetSuggestion1Options] = useState<Array<DropdownItem>>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  
  const getServiceStatusesQuery = useGetServiceStatuses();
  const searchAllocationNodes = useSearchAllocationNodes();
  const userTagsQuery = getAllTags(EntityType.TYPE_SERVICES);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (getServiceStatusesQuery.data && preSelectedData?.parameterType === ParameterDataFormParameterType.Status) {
      const statusData: Array<DropdownItem> = getServiceStatusesQuery.data?.map((obj) => ({
        label: obj.name,
        value: obj.code,
        disabled: !obj.isActive
      }));
      setPresetSuggestion1Options(statusData);
    }
  }, [getServiceStatusesQuery.data, preSelectedData?.parameterType]);

  useEffect(() => {
    if (userTagsQuery.data) {
      setAllTags(userTagsQuery.data);
    }
  }, [userTagsQuery.data]);

  useEffect(() => {
    if (preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag) {
      setSelectedTags(preSelectedData.presetSuggestion1 || []);
      setNewTags(preSelectedData.presetSuggestion2 || []);
    }
  }, []);

  useEffect(() => {
    if (preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag) {
      onPreSelectedData({
        parameterType: ParameterDataFormParameterType.SetTag,
        presetSuggestion1: selectedTags,
        presetSuggestion2: newTags
      });
    }
  }, [selectedTags, newTags]);

  return (
    <StyledStack spacing={2}>
      <SelectMenu
        id="parameter-form-builder-field-fragment-parameter-type"
        label="Parameter Type"
        items={PARAMETER_FORM_TYPE_PARAMETER_OPTIONS}
        selectedValue={preSelectedData?.parameterType}
        onChange={(selectedParameter) => onPreSelectedData({ 
          parameterType: selectedParameter, 
          presetSuggestion1: '',
          presetSuggestion2: '' ,
          partParameter: undefined
        })}
        validate={showErrors && !preSelectedData?.parameterType}
      />
      {preSelectedData?.parameterType === ParameterDataFormParameterType.Status &&
        <SelectMenu
          id="form-builder-modify-structure-field-options"
          label="Preset Suggestion"
          items={presetSuggestion1Options}
          selectedValue={preSelectedData?.presetSuggestion1}
          onChange={(selectedSuggestion) => onPreSelectedData({
            parameterType: preSelectedData.parameterType,
            presetSuggestion1: selectedSuggestion,
            presetSuggestion2: ''
          })}
          validate={showErrors && !preSelectedData?.presetSuggestion1}
        />
      }
      {((preSelectedData?.parameterType === ParameterDataFormParameterType.PrimaryAllocation && isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, permissions)) || 
        (preSelectedData?.parameterType === ParameterDataFormParameterType.SecondaryAllocation) && isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Secondary, permissions)) &&
        <LiveSearchBox 
          title="Preset Suggestion" 
          timeOffset={400}
          value={preSelectedData?.presetSuggestion1.name || ''} 
          onClearValue={() => onPreSelectedData({
            parameterType: preSelectedData.parameterType,
            presetSuggestion1: '',
            presetSuggestion2: ''
          })}
          onChange={async (searchValue: LocationNodeData) => {
            if (searchValue.name) {
              onPreSelectedData({
                parameterType: preSelectedData.parameterType,
                presetSuggestion1: searchValue,
                presetSuggestion2: undefined
              });
            }
          }}
          onApiInvoke={async (name) => {
            return await searchAllocationNodes.mutateAsync(name);
          }} 
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}     
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag &&
        <Tags
          allTags={allTags}
          selectedTags={selectedTags}
          newTags={newTags}
          setSeletedTags={(selectedTag: (string | AutocompleteTagType)[]) => setSelectedTags(selectedTag)}
          setNewTags={(newTag: Tag[]) => setNewTags(newTag)}
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.Part &&
      <>
        <SelectMenu
          id="form-builder-set-parameter-UI-part-attributes"
          label="Attribute"
          items={PARAMETER_UI_FORM_PART_ATTRIBUTES}
          selectedValue={preSelectedData?.partParameter?.partAttribute}
          onChange={(selectedAttribute) => onPreSelectedData({
            parameterType: preSelectedData.parameterType,
            partParameter: { ...preSelectedData.partParameter, partAttribute: selectedAttribute },
            presetSuggestion1: undefined,
            presetSuggestion2: undefined
          })}
          validate={showErrors && !preSelectedData?.partParameter?.partAttribute}
        />
        <SelectMenu
          id="form-builder-set-parameter-UI-part-movement-types"
          label="Preferred Movement Type"
          items={PARAMETER_UI_FORM_PART_MOVEMENT_TYPES}
          selectedValue={preSelectedData?.partParameter?.movementType}
          onChange={(selectedMovementType) => onPreSelectedData({
            parameterType: preSelectedData.parameterType,
            partParameter: { ...preSelectedData.partParameter, movementType: selectedMovementType },
            presetSuggestion1: undefined,
            presetSuggestion2: undefined
          })}
          validate={showErrors && !preSelectedData?.partParameter?.movementType}
        />
      </>
      }
    </StyledStack>
  );
};
