import React from 'react';
import { PartStructureProps } from './PartStructure.props';
import { Link } from '../../atoms/Link';
import AccountTree from '@mui/icons-material/AccountTree';
import { COLORS } from '../../../configs/colors';
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { PartChildrenObject } from '../../../@types/part.type';

export const PartStructure: React.FC<PartStructureProps> = ({
  serial,
  partStructureData
}) => {

  const ChildPartItem: React.FC<{ childPart: PartChildrenObject }> = ({ childPart }) => {
    return (<ListItemButton key={childPart.id} href={`/parts/${childPart.id}`}>
      <ListItemIcon>{childPart.hasChildren ? <AccountTree style={{ color: COLORS.Blue }} />
        : <></>}</ListItemIcon>
      <ListItemText><Link href={`/parts/${childPart.id}`}><Typography>{childPart.serial1}</Typography></Link></ListItemText>
      <ListItemText><Typography> {childPart.partTypeName}</Typography></ListItemText>
    </ListItemButton>);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {serial}
        </ListSubheader>
      }
    >
      {partStructureData && partStructureData.map((childPart) => {
        return (<ChildPartItem key={childPart.id} childPart={childPart} />);
      })}
    </List>
  );
};