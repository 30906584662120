import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useParams } from 'react-router-dom';
import {
  ImageBox,
  MainBox,
  StyledSelectMenu
} from '../CreateUser/CreateUser.styles';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps, ButtonColorProps } from '../../../components/atoms/Button';
import {
  emailVerifiedMessage,
  getRoles,
  getTimeZones,
  getTimezoneId,
  getUpdatedRoleId,
  getAttributes,
  hasErrorInUpdate,
  isADConnection,
  namesValidation,
} from '../../../utils/users';
import {
  Auth0AppUser,
  AppUser,
  Role,
  Tag,
  Timezone,
  LocationNodeData,
  CustomPermission,
  ActionPermissions,
  UserSectionFields,
  UserHelperText,
  UserFormType,
  FontSizeProps,
  IconColorProps,
  NodeType
} from '../../../@types';
import { CustomPermissions } from '../CustomPermissions';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../components/atoms/FormControlLabel';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import {
  useGetOneAuthUser,
  useGetOneUser,
  useGetUserSystemPermissions,
  useSendWelcomeEmail,
  useUpdateAuth0User,
  useUpdateUser
} from '../../../queries/user-query';
import { useGetAllActiveRoles } from '../../../queries/roles-query';
import { useGetTimezones } from '../../../queries/timezones-query';
import { deleteAuthUserById } from '../../../api/auth';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags, Tags } from '../../../components/organisms/Tags';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { EntityType, PropertyType as PropertyTypeEnum } from '../../../configs/enums';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { formatDate, isEmptyString } from '../../../utils/common';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { useSearchNode } from '../../../queries/live-search-query';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { UploadButton } from '../../../components/molecules/UploadButton';
import { ContactDataType, StructureAddressContactLayout } from '../../../components/templates/StructureAddressContactLayout';
import { contactType } from '../../ClientStructure/AddEditClient/AddClientData.data';
import { AddressDataType } from '../../../components/organisms/StructureAddress';
import { ContactData } from '../../../components/molecules/Contact';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { NODE_NAME_CANNOT_EMPTY, SAMPLEAUTH0USER, SAMPLEUSER, SAMPLEUSERHELPERTEXT } from '../../../constants/user.constant';
import { SAMPLEROLE } from '../../../constants/role.constant';
import { SAMPLETIMEZONE } from '../../../constants/timezone.constant';
import { ImagePlaceHolder } from '../Users.styles';
import { useGetStructureTypesByCode } from '../../../queries/structure-type-query';
import { PLATFORM_NAME } from '../../../configs/common';
import { ConfirmationPopup } from '../../../components/molecules/ConfirmationPopup';
import { USER_PROFILE_MIME_TYPES } from '../../../configs/envs';

export const UpdateUser: React.FC = () => {
  const params = useParams();
  const id: any = params.id;

  const [AdConnection, setAdConnection] = useState('');
  const [updated, setUpdated] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [validate, setValidate] = useState(false);

  const [profilePic, setProfilePic] = useState('');
  const [profilePicDetails, setProfilePicDetails] = useState<FileList>();

  const [activeUser, setActiveUser] = useState<AppUser>(SAMPLEUSER);
  const [auth0User, setAuth0User] = useState<Auth0AppUser>(SAMPLEAUTH0USER);
  const [roles, setRoles] = useState<[Role]>([SAMPLEROLE]);
  const [timezones, setTimezones] = useState<[Timezone]>([SAMPLETIMEZONE]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [contactForm, setContactForm] = useState<any[]>([]);
  const [addressForm, setAddressForm] = useState<any[]>([]);

  // validation properties
  const [helperText, setHelperText] = useState<UserHelperText>(SAMPLEUSERHELPERTEXT);

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);

  const [addressData, setAddressData] = useState<AddressDataType[]>([]);
  const [editAddressData, setEditAddressData] = useState<AddressDataType>();
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [structureAddressDataset, setStructureAddressDataset] = useState<AddressDataType[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);
  const [canHaveSiblingRefNumber, setCanHaveSiblingRefNumber] = useState<boolean>(false);

  const getUserQuery = useGetOneUser(id);
  const activeRolesQuery = useGetAllActiveRoles();
  const timeZonesQuery = useGetTimezones();
  const sendWelcomeEmailQuery = useSendWelcomeEmail();
  const updateUserQuery = useUpdateUser();
  const updateAuth0User = useUpdateAuth0User();
  const updateTagsQuery = useAddTags();
  const userTagsQuery = getAllTags(EntityType.TYPE_USER);
  const searchNodeQuery = useSearchNode();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getStructureTypeQuery = useGetStructureTypesByCode(NodeType.Sibling);

  useEffect(() => {
    if (getStructureTypeQuery.data) {
      const { propertyTypes } = getStructureTypeQuery.data;
      const canHaveRef = propertyTypes.some(propertyTypeData => propertyTypeData.propertyType === PropertyTypeEnum.ReferenceNumbers);
      setCanHaveSiblingRefNumber(canHaveRef);
    }
  }, [getStructureTypeQuery.data]);

  useEffect(() => {
    if (getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.data]);

  const onClickPicture = async (fileList: FileList) => {
    setProfilePic(URL.createObjectURL(fileList[0]));
    setProfilePicDetails({ ...fileList });
  };

  useEffect(() => {
    if (getUserQuery.data) {
      setActiveUser(getUserQuery.data);
      setProfilePic(getUserQuery.data.profilePic);
      setSelectedTags(mapSavedTags(getUserQuery.data.tags));
      if (getUserQuery.data.siblingAttributes && getUserQuery.data.siblingAttributes.length > 0) {
        const attributes = getUserQuery.data.siblingAttributes;
        attributes.forEach((attribute: any) => {
          const { id, propertyTypeCode, value } = attribute;

          if (propertyTypeCode === PropertyTypeEnum.Contact && value?.isActive) {
            setStructureContactDataset(prevStructureContactDataset => [...prevStructureContactDataset, { ...value, id: id }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.Address && value?.isActive) {
            setStructureAddressDataset(prevStructureAddressDataset => [...prevStructureAddressDataset, {
              ...value,
              id: id,
              name: value.addressName
            }]);
          }
          if (propertyTypeCode === PropertyTypeEnum.ReferenceNumbers && value) {
            setActiveUser(prevActiveUser => ({ ...prevActiveUser, siblingRefNumber1: value.referenceNo1, siblingRefNumber2: value.referenceNo2, siblingRefNumber3: value.referenceNo3 }));
          }
        });
      }

      document.title = `User: ${getUserQuery.data.id} | Users | ${PLATFORM_NAME}`;
    }
  }, [getUserQuery.data]);

  const getAuthUserQuery = useGetOneAuthUser(activeUser.auth0Id);

  useEffect(() => {
    if (getAuthUserQuery.data) {
      setAuth0User(getAuthUserQuery.data);
      if (getAuthUserQuery.data?.identities?.length > 0) {        
        setAdConnection(getAuthUserQuery.data.identities[0].connection);
      }
    }

    if (activeRolesQuery.data) {
      setRoles(activeRolesQuery.data);
    }

    if (timeZonesQuery.data) {
      setTimezones(timeZonesQuery.data);
    }

    if (userTagsQuery.data) {
      setAllTags(userTagsQuery.data);
    }
  }, [getAuthUserQuery.data, activeRolesQuery.data, timeZonesQuery.data, userTagsQuery.data]);

  const handleWelcomeEmail = async (userId: string) => {
    await sendWelcomeEmailQuery.mutateAsync(userId);
    setEmailStatus(true);
  };

  const handleUserUpdate = async () => {
    setHelperText({ 
      ...helperText, 
      firstNameHelperText: namesValidation(activeUser.firstName, UserSectionFields.FirstNameField),
      lastNameHelperText: namesValidation(activeUser.lastName, UserSectionFields.LastNameField),
      EmailHelperText: emailVerifiedMessage(activeUser.contactEmail)
    });

    const lock = auth0User?.blocked ? true : false;

    const isError = hasErrorInUpdate(activeUser);

    if (isError) {
      setValidate(true);
      return;
    }

    const updatedUser = {
      id: activeUser.id,
      firstName: activeUser.firstName,
      lastName: activeUser.lastName,
      email: activeUser.email,
      contactEmail: activeUser.contactEmail,
      profilePic: profilePicDetails || '',
      isActive: activeUser.isActive,
      isLocked: lock,
      isEmailVerified: auth0User.email_verified,
      systemRoleId: activeUser.systemRoleId,
      timezoneId: activeUser.timezoneId,
      notes: activeUser.notes,
      lastActive: auth0User.updated_at,
      siblingNodeName: activeUser.siblingNodeName,
      siblingParentNodeId: activeUser.siblingParentNodeId,
      siblingAttributes: getAttributes(structureAddressDataset, structureContactDataset, activeUser)
    };

    if (!activeUser.isActive) {   
      await deleteAuthUserById(activeUser.auth0Id);
    } else {
      let authUser;

      if (isADConnection(AdConnection)) {
        authUser = {
          id: activeUser.auth0Id,
          blocked: lock,
          account_enabled: auth0User.account_enabled
        };
      } else {
        authUser = {
          id: activeUser.auth0Id,
          name: `${activeUser.firstName} ${activeUser.lastName}`,
          given_name: activeUser.firstName,
          family_name: activeUser.lastName,
          blocked: lock,
          account_enabled: auth0User.account_enabled
        };
      }
      
      await updateAuth0User.mutateAsync(authUser); 
    }

    await updateUserQuery.mutateAsync(updatedUser);

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    await updateTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_USER,
      entityId: activeUser.id,
      freshTags,
      existingTags
    });

    setUpdated(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setUpdated(false);
    setEmailStatus(false);
  };

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  const onChangeActiveUser = (key: string, value: any) => {
    switch (key) {
      case 'firstName':
        setHelperText({ ...helperText, firstNameHelperText: namesValidation(value, UserSectionFields.FirstNameField) });
        break;

      case 'lastName':
        setHelperText({ ...helperText, lastNameHelperText: namesValidation(value, UserSectionFields.LastNameField) });
        break;

      case 'contactEmail':
        setHelperText({ ...helperText, EmailHelperText: emailVerifiedMessage(activeUser.contactEmail) });
        break;

      case 'isActive':
        if (value) value = false;
        else value = true;
        break;

      case 'siblingNodeName':
        setHelperText({ ...helperText, nodeNameHelperText: isEmptyString(value) ? NODE_NAME_CANNOT_EMPTY : '' });
        break;
    }

    setActiveUser({
      ...activeUser,
      [key]: value
    });
  };

  const onChangeAuth0User = (key: string, value: any) => {

    switch (key) {
      case 'blocked':
        if (value) value = false;
        else value = true;
        break;
    }

    setAuth0User({
      ...auth0User,
      [key]: value
    });
  };

  const handleDeleteAcceptance = () => {
    setActiveUser({
      ...activeUser,
      ['isActive']: false
    });

    setIsOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
        X
      </Button>
    </React.Fragment>
  );

  if (!getUserQuery.data) {
    return getUserQuery.isLoading ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        {UserFormType.UpdateUser}
      </Typography>
      <MainBox>
        <Grid>
          <Typography variant={TypographyVariantProps.H6}>General</Typography>
          <Grid container mt="1px" spacing={GRID_SPACING}>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                error={!!helperText.firstNameHelperText}
                helperText={helperText.firstNameHelperText}
                label="First Name"
                value={activeUser.firstName}
                onChange={(val) => onChangeActiveUser('firstName', val)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                error={!!helperText.lastNameHelperText}
                helperText={helperText.lastNameHelperText}
                label="Last Name"
                value={activeUser.lastName}
                onChange={(val) => onChangeActiveUser('lastName', val)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                disabled
                label="Login Email"
                value={activeUser.email}
                onChange={(val) => onChangeActiveUser('email', val)}
              />
            </Grid>
            <Grid item container
              xs={6}
              display="flex"
              alignItems="center"
              columnSpacing="24px"
              rowSpacing="auto"
            >
              {isUserHasPermission(ActionPermissions.Configure_User_Edit_Lock, permissions) && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant={ButtonVariantProps.Primary}
                    onClick={() => onChangeAuth0User('blocked', auth0User.blocked)}
                    color={auth0User.blocked ? ButtonColorProps.Error : ButtonColorProps.Secondary}
                  >
                    {auth0User.blocked ? 'Unlock' : 'Lock'}
                  </Button>
                </Grid>
              )}
              {/* TODO: Hiding the Send Welcome Email as per the requirement #AMT2-3066. This will be handled later. */}
              <Grid item xs={6} display={'none'}>
                <Button
                  fullWidth
                  variant={ButtonVariantProps.Primary}
                  color={ButtonColorProps.Info}
                  onClick={() => handleWelcomeEmail(activeUser.auth0Id)}
                >
                  Send Welcome Email
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                label="Last Active"
                value={formatDate(auth0User.updated_at)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                label="Contact Email"
                error={!!helperText.EmailHelperText}
                helperText={helperText.EmailHelperText}
                value={activeUser.contactEmail}
                onChange={(val) => onChangeActiveUser('contactEmail', val)}
              />
            </Grid>
          </Grid>
          <Grid mt={GRID_SPACING}>
            <UploadButton fileTypes={USER_PROFILE_MIME_TYPES.split(',')} maxFileSize={30} setFileDetails={onClickPicture} 
              label="Upload Profile Picture" icon={<AddPhotoAlternateIcon fontSize={FontSizeProps.Small} />}/>
          </Grid>
          {profilePic ? (
            <Grid mt={GRID_SPACING}>
              <ImageBox src={profilePic} />
            </Grid>
          ):(
            <ImagePlaceHolder color={IconColorProps.Disabled}/>
          )}
          <Grid ml="-15px" mt="2px">
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  checked={activeUser.isActive}
                  onChange={() => activeUser.isActive ? setIsOpen(true) : onChangeActiveUser('isActive', activeUser.isActive)}
                  color={CheckboxColorProps.Success}
                />
              }
              label="Is Enabled"
              labelPlacement={FormControlLabelPlacementProps.Start}
            />
          </Grid>

          <Grid mt={GRID_SPACING}>
            <Typography variant={TypographyVariantProps.H6}>Additional</Typography>
          </Grid>

          <Grid container mt="1px" spacing={GRID_SPACING}>
            {isUserHasPermission(ActionPermissions.Configure_User_Edit_Role_Selection, permissions) && (
              <Grid item xs={6}>
                <StyledSelectMenu
                  required={false}
                  id="demo-simple-select-required"
                  labelId="demo-simple-select-required-label"
                  label="Role *"
                  selectedValue={getUpdatedRoleId(roles, activeUser.systemRoleId)}
                  onChange={(val) => onChangeActiveUser('systemRoleId', val)}
                  items={getRoles(roles)}
                  validate={validate}
                  optionalLabelEnabled={true}
                />        
              </Grid>
            )}
            <Grid item xs={6}>
              <StyledSelectMenu
                required={true}
                id="demo-simple-select-required"
                labelId="demo-simple-select-required-label"
                label="Timezone *"
                selectedValue={getTimezoneId(timezones, activeUser.timezoneId)}
                onChange={(val) => onChangeActiveUser('timezoneId', val)}
                items={getTimeZones(timezones)}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Tags 
                selectedTags={selectedTags} 
                newTags={newTags} 
                setSeletedTags={onChangeSelectedTags} 
                setNewTags={onChangeNewTags}
                allTags={allTags}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                multiline
                rows={4}
                value={activeUser.notes}
                onChange={(val) => onChangeActiveUser('notes', val)}
              />
            </Grid>
          </Grid>
          {isUserHasPermission(ActionPermissions.Configure_User_Edit_Sibling_Config, permissions) && (
            <>
              <Grid mt={GRID_SPACING}>
                <Typography variant={TypographyVariantProps.H6}>Structure Link</Typography>
              </Grid>
              <Grid container mt="1px" spacing={GRID_SPACING}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    error={!!helperText.nodeNameHelperText}
                    helperText={helperText.nodeNameHelperText}
                    label="Display Name"
                    value={activeUser.siblingNodeName}
                    onChange={(val) => onChangeActiveUser('siblingNodeName', val)}
                  />
                </Grid>
              </Grid>
              <Grid container mt="1px" spacing={GRID_SPACING}>
                <Grid item xs={6}>
                  <LiveSearchBox
                    title="Parent Node Search"
                    timeOffset={400}
                    value={activeUser.siblingParentNodeName || ''}
                    onClearValue={() => {
                      setActiveUser({ ...activeUser, siblingParentNodeId: null, siblingParentNodeName: '' });
                    }}
                    renderItem={(props: any, option: any) => { 
                      return (
                        <LiveSearchListItem {...props}>
                          <LocationSearchItem data={option} />
                        </LiveSearchListItem>
                      );
                    }}
                    onChange={async (obj: LocationNodeData) => {
                      setActiveUser(prevActiveUser => ({ ...prevActiveUser, siblingParentNodeId: obj?.id, siblingParentNodeName: obj?.name }));
                    }}
                    onApiInvoke={async (obj) => {
                      return await searchNodeQuery.mutateAsync(obj);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={GRID_SPACING}>
                <StructureAddressContactLayout
                  heading="Address"
                  isAddress={true}
                  renderNotes={false}
                  contactType={contactType}
                  addressDataset={structureAddressDataset ? structureAddressDataset : []}
                  contactDataset={structureContactDataset}
                  contactFormData={contactData}
                  addressFormData={addressData}
                  editAddressFormData={editAddressData}
                  onClickAddressEdit={(event, index) => {
                    const selectedValue: any = structureAddressDataset.splice(index, 1);
                    setStructureAddressDataset(structureAddressDataset);
                    setEditAddressData(selectedValue[0]);
                  }}
                  onClickAddressDelete={(event, index) => {
                    const updatedData = [...structureAddressDataset];
                    if (index > -1) updatedData[index].isActive = false;
                    setStructureAddressDataset(updatedData);
                  }}
                  onStructureAddressChange={(value) => {
                    setAddressData(value);
                  }}
                  handleAddressCreate={(index) => {
                    if (index > -1) addressData[index].isActive = true;
                    if(addressData[index].isManual) {
                      addressData[index] = { ...addressData[index], googleAddress: '' };
                    }
                    setStructureAddressDataset([...structureAddressDataset, addressData[index]]);
                    addressData.splice(index, 1);
                    setAddressData([...addressData]);
                  }}
                  addressForm={addressForm}
                  setAddressForm={setAddressForm}
                />
                <StructureAddressContactLayout
                  heading="Contact"
                  isAddress={false}
                  renderNotes={false}
                  contactType={contactType}
                  addressDataset={structureAddressDataset}
                  contactDataset={structureContactDataset ? structureContactDataset : []}
                  contactFormData={contactData}
                  addressFormData={addressData}
                  editContactFormData={editContactData}
                  onClickContactEdit={(event, index) => {
                    const selectedValue: any = structureContactDataset.splice(index, 1);
                    setStructureContactDataset(structureContactDataset);
                    setEditContactData(selectedValue[0]);
                  }}
                  onClickContactDelete={(event, index) => {
                    const updatedData = [...structureContactDataset];
                    if (index > -1) updatedData[index].isActive = false;
                    setStructureContactDataset(updatedData);
                  }}
                  onStructureAddressChange={() => ''}
                  onContactChange={(value) => {
                    setContactData(value);
                  }}
                  handleContactCreate={(index) => {
                    if (index > -1) contactData[index].isActive = true;
                    setStructureContactDataset([...structureContactDataset, contactData[index]]);
                    contactData.splice(index, 1);
                    setContactData([...contactData]);
                  }}
                  contactForm={contactForm}
                  setContactForm={setContactForm}
                />
              </Grid>
            </>
          )}
          {canHaveSiblingRefNumber && 
          <>
            <Grid container mt="1px" spacing={GRID_SPACING}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Reference No 1"
                  value={activeUser.siblingRefNumber1 || ''}
                  onChange={(refNumber) => setActiveUser(prevActiveUser => ({ ...prevActiveUser, siblingRefNumber1: refNumber }))}
                />
              </Grid>
            </Grid>
            <Grid container mt="1px" spacing={GRID_SPACING}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Reference No 2"
                  value={activeUser.siblingRefNumber2 || ''}
                  onChange={(refNumber) => setActiveUser(prevActiveUser => ({ ...prevActiveUser, siblingRefNumber2: refNumber }))}
                />
              </Grid>
            </Grid>
            <Grid container mt="1px" spacing={GRID_SPACING}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Reference No 3"
                  value={activeUser.siblingRefNumber3 || ''}
                  onChange={(refNumber) => setActiveUser(prevActiveUser => ({ ...prevActiveUser, siblingRefNumber3: refNumber }))}
                />
              </Grid>
            </Grid>
          </>
          }
          <Stack mt={GRID_SPACING} alignItems="flex-end">
            <Button
              variant={ButtonVariantProps.Primary}
              onClick={handleUserUpdate}
            >
              Update
            </Button>
          </Stack>
          <Snackbar
            open={updated}
            autoHideDuration={3000}
            onClose={handleClose}
            message="Updated!"
            action={action}
          />
          <Snackbar
            open={emailStatus}
            autoHideDuration={3000}
            onClose={handleClose}
            message="Email Sent!"
            action={action}
          />
        </Grid>
      </MainBox>
      {isUserHasPermission(ActionPermissions.Configure_User_Edit_Custom_Permissions, permissions) &&
        <MainBox>
          <CustomPermissions roleId={activeUser.systemRoleId.toString()} />
        </MainBox>
      }
      <ConfirmationPopup
        open={isOpen}
        mainHeader="Warning"
        description="Your decision will lead to delete the user. Are you sure?"
        leftButtonName="No"
        rightButtonName="Yes"
        leftButtonOnClick={() => setIsOpen(false)}
        rightButtonOnClick={() => handleDeleteAcceptance()}
      />
    </PageContainer>
  );
};