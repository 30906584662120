import React from 'react';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { Aod, Monitor } from '@mui/icons-material';
import { DataGridDensityMenuItemsProps } from './DataGridDensityMenuItems.props';
import { Typography, TypographyVariantProps } from '../Typography';
import { CustomDensityOption } from '../../../@types';
import { StyledMenuItem } from './DataGridDensityMenuItems.styles';

export const DataGridDensityMenuItems: React.FC<DataGridDensityMenuItemsProps> = ({
  selectedDesityOption,
  onDensityChange
}) => {
  return (
    <>
      <StyledMenuItem onClick={() => onDensityChange(CustomDensityOption.Default) } selected={selectedDesityOption === CustomDensityOption.Default}>
        <ListItemIcon>
          <Monitor fontSize="small" />
        </ListItemIcon>
        <ListItemText><Typography variant={TypographyVariantProps.Body2}>Default</Typography></ListItemText>
      </StyledMenuItem>
      <StyledMenuItem onClick={() => onDensityChange(CustomDensityOption.Mobile) } selected={selectedDesityOption === CustomDensityOption.Mobile}>
        <ListItemIcon>
          <Aod fontSize="small" />
        </ListItemIcon>
        <ListItemText><Typography variant={TypographyVariantProps.Body2}>Mobile</Typography></ListItemText>
      </StyledMenuItem>
    </>
  );
};