import {
  ActionTriggerFormActionValueOptions,
  FormFieldItem,
  FormFieldType,
  OutcomeReason,
  ParameterNoUIDataFormParameterType,
  ParameterNoUIDataFormPartAttribute,
  ParameterNoUIDataFormSLAOption
} from '../@types';
import { PartActionType } from '../@types/partAction.type';
import { DropdownItem } from '../components/atoms/SelectMenu';

export const getFormFieldTitle = (type: FormFieldType) => {
  switch(type) {
    case FormFieldType.FaultSelection:
    case FormFieldType.SolutionAction:
    case FormFieldType.PartsRequest:
    case FormFieldType.DecisionPrompt:
    case FormFieldType.GetGeoLocation:
    case FormFieldType.EstimatedTimeOfArrival:
    case FormFieldType.MerchantDamageTemplate:
    case FormFieldType.ServiceSearch:
    case FormFieldType.Block:
    case FormFieldType.Presentation:
      return type.toString();
    default:
      return '';
  }
};

export const checkSetParamNoUIFormErrorsAvailability = (formFieldData: FormFieldItem) => {
  if (formFieldData.preSelectedData.parameterType === ParameterNoUIDataFormParameterType.Billable) {
    return !formFieldData.preSelectedData.billableType;
  } else if (formFieldData.preSelectedData.parameterType === ParameterNoUIDataFormParameterType.SLA) {
    if (formFieldData.preSelectedData.slaData.option === ParameterNoUIDataFormSLAOption.Met.toLowerCase()) {
      return !formFieldData.preSelectedData.slaData.type;
    } else {
      return (!formFieldData.preSelectedData.slaData.reason || !formFieldData.preSelectedData.slaData.notes);
    }
  } else if (formFieldData.preSelectedData.parameterType === ParameterNoUIDataFormParameterType.Part) {
    if (!formFieldData.preSelectedData.partData.attribute) return true;
    if (formFieldData.preSelectedData.partData.attribute === ParameterNoUIDataFormPartAttribute.Condition) {
      return (!formFieldData.preSelectedData.partData.condition || !formFieldData.preSelectedData.partData.movementType);
    }
    if (formFieldData.preSelectedData.partData.attribute === ParameterNoUIDataFormPartAttribute.FleetTag) {
      return (!formFieldData.preSelectedData.partData.fleetTag || !formFieldData.preSelectedData.partData.movementType);
    } else return false;
  } else return false;
};

export const getSLAOutcomeReasonName = (outcomeReasons: OutcomeReason[], selectedReasonId: string) => {
  return outcomeReasons.find((reason) => reason.id === parseInt(selectedReasonId))?.name;
};

export const getPrePopulatedPartActionOptions = (optionsList: Array<DropdownItem>, selectedAction: string) => {
  return optionsList.filter((option) => {
    switch(selectedAction) {
      case PartActionType.INSTALL:
      case PartActionType.REMOVE:
      case PartActionType.REPLACE:
        return true;
      case PartActionType.DOA:
      case PartActionType.CLEAN:
      case PartActionType.TEST:
        return option.value !== ActionTriggerFormActionValueOptions.SiteSchema;
      default:
        return true;
    }
  });
};