import { ActionPermissions, CustomPermission, ServiceStatusCode, ServiceStatusResponse } from '../@types';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { isUserHasPermission } from '../configs/permissions';

export const PROGRESSIONBLOCKS = (availableStatusesList: DropdownItem[], preferredPathOptions: string[]) => {
  return availableStatusesList?.filter(
    (availableStatus: DropdownItem) => !preferredPathOptions.find(
      (preferredPathOption: string) => preferredPathOption === availableStatus.value)
  );
};

export const PREFERREDPATHS = (availableStatusesList: DropdownItem[], progressionBlockOptions: string[]) => {
  return availableStatusesList?.filter(
    (availableStatus: DropdownItem) => !progressionBlockOptions.find(
      (progressionBlockOption: string) => progressionBlockOption === availableStatus.value)
  );
};

export const getAvailableStatuses = (serviceStatuses: ServiceStatusResponse[], code: string) => {
  const availableStatusItems: DropdownItem[] = [];
  serviceStatuses.map((item: ServiceStatusResponse) => {
    if(item.code !== code) {
      const status = {
        value: item.code,
        label: item.name,
        disabled: !item.isActive
      };
      availableStatusItems.push(status);
    } 
  });

  return availableStatusItems;
};

export const getPropertyTypeValue = (propertyType: boolean) => {
  return propertyType ? 'Yes' : 'No';
};

export const isError = (isShortCodeExist: boolean, validateName: string, validateShortName: string) => {
  return isShortCodeExist || validateName || validateShortName;
};

export const checkServiceCancelOrFutileAvailability = (permissions: CustomPermission[], isCheckedIn: boolean, serviceStatusCode: string) => {
  const cancelAfterProgressPermission = isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Cancelled_After_Progress, permissions);
  const cancelPermission = isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Cancelled, permissions);
  const futilePermission = isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Futile, permissions);

  if (cancelAfterProgressPermission) {
    return true;
  }

  if (cancelPermission && !isCheckedIn && serviceStatusCode !== ServiceStatusCode.OnSite && serviceStatusCode !== ServiceStatusCode.OnTheWay) {
    return true;
  }

  if (futilePermission) {
    return true;
  }

  return false;
};