import { AutocompleteTagType } from './Tags.props';
import { isString } from '../../../utils/common';
import { Tag } from '../../../@types';

export const getSelectedExistingTagsMapped = (
  selectedTags: (AutocompleteTagType | string)[]
): Tag[] => {
  const assignedExistingTags: Tag[] = [];

  selectedTags.forEach((tag) => {
    if (!isString(tag)) {
      assignedExistingTags.push({
        id: tag.id,
        text: tag.label,
        isSystem: tag.isSystem,
        colour: tag.color,
      });
    }
  });

  return assignedExistingTags;
};

export const getNewTagsMapped = (newTags: Tag[]) => {
  return newTags.map((tag) => ({ text: tag.text, colour: tag.colour }));
};

export const mapSavedTags = (savedTags: Tag[]) : AutocompleteTagType[] => {
  return savedTags.map(savedTag => ({
    id: savedTag.id, label: savedTag.text, isSystem: savedTag.isSystem, color: savedTag.colour
  }));
};