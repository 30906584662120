import React, { useState } from 'react';
import { Stack, Table, TableRow } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { ManualShippingData, ManualShippingProps } from '.';
import { StyledTableCell } from './ManualShipping.styles';
import { TextField } from '../../atoms/TextField';
import { MANUAL_SHIPPING_DATA } from '../../../constants/shipment';

export const ManualShipping: React.FC<ManualShippingProps> = ({
  couriers,
  onSave
}) => {
  const [manualShippingData, setManualShippingData] = useState<ManualShippingData>(MANUAL_SHIPPING_DATA);

  return (
    <Table size="small">
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography>Courier</Typography>
            {couriers.map((courier) => 
              <Button 
                key={courier.id} 
                variant={manualShippingData.shippingProviderId === courier.id ? ButtonVariantProps.Primary : ButtonVariantProps.Secondary} 
                color={ButtonColorProps.Success}
                onClick={() => setManualShippingData({ ...manualShippingData, shippingProviderId: courier.id })}
              >
                {courier.name}
              </Button>)}
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell>
          <Stack alignItems="center" justifyItems="center" direction="row" spacing={2}>
            <Typography>Consignment&nbsp;#</Typography>
            <TextField
              fullWidth
              value={manualShippingData.connoteNo}
              onChange={(consignmentNo: string) => setManualShippingData({ ...manualShippingData, connoteNo: consignmentNo })}
            />
          </Stack>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <Stack justifyContent="flex-end" direction="row" mr={2}>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Primary} onClick={() => {
            onSave(manualShippingData);
            setManualShippingData(MANUAL_SHIPPING_DATA);
          }}>
            Save
          </Button>
        </Stack>
      </TableRow>
    </Table>
  );
};