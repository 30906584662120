import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { HorizontalAlignProps, InlineGroup } from '../../components/atoms/InlineGroup';
import { UserList } from './UserList';
import { PageContainer } from '../../components/atoms/PageContainer';
import { useGetUserSystemPermissions } from '../../queries/user-query';
import { ActionPermissions, CustomPermission, UserFormType } from '../../@types';
import { isUserHasPermission } from '../../configs/permissions';
import { Link } from '../../components/atoms/Link';
import { RoutePath } from '../../@types/route.type';
import { Wrapper } from '../../styled/common.styles';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';

export const Users: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  TabHandler(`List Users | ${PLATFORM_NAME}`);

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            {UserFormType.ListUsers}
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_User_Create, permissions) && (
            <Link href={RoutePath.CreateUser}>
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
                {UserFormType.CreateUser}
              </Button>
            </Link>
          )}
        </InlineGroup>
        <UserList />
      </Wrapper>
    </PageContainer>
  );
};