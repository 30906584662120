import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import { FormControlLabel } from '../../atoms/FormControlLabel';
import { Typography } from '../../atoms/Typography';

export const ContentWrapper = styled(Box)`
  padding-bottom: 10px;
`;

export const EnabledFormControlLabel = styled(FormControlLabel)`
  justify-content: flex-start;
`;

export const Topic = styled(Typography)`
  font-size: 18px;
  line-height: 28.8px;
`;

export const ImageBox = styled('img')`
  width: 200px;
  height: 200px;
`;
