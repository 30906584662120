import React, { useState, useEffect } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ActionPermissions, ChildNodeType, CustomPermission, StructureTypeResponse } from '../../../@types';
import { useGetStructureTypesByCategoryCode } from '../../../queries/structure-type-query';
import { StructureTypeCategory } from '../../../configs/enums';
import { PropertyType as PropertyTypeEnum } from '../../../configs/enums';
import { DataGrid, GridAllignmentTypes } from '../../../components/atoms/DataGrid';
import { GridContainer } from '../../../styled/common.styles';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Link } from '../../../components/atoms/Link';
import { getPropertyTypeValue } from '../../../utils/structure-type';
import { CustomSearchBar } from '../../../components/molecules/CustomSearchBar';

export const StructureTypeList: React.FC = () => {
  const [structureTypes, setStructureTypes] = useState<Array<StructureTypeResponse>>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [search, setSearch] = useState<string>('');

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const { data, isLoading } = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);

  useEffect(() => {
    setColumns([
      {
        field: 'code',
        headerName: 'Code',
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Configure_Structure_Type_Edit, permissions) ?
            <Link href={`/configure/structure/types/${params.row.code}`}>{params.row.code}</Link> :
            params.row.code
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.code
      },
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams) => 
          isUserHasPermission(ActionPermissions.Configure_Structure_Type_Edit, permissions) ?
            <Link href={`/configure/structure/types/${params.row.code}`}>{params.row.name}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
        width: 200
      },
      {
        field: 'childNodeTypes', headerName: 'Preferred Children', width: 200,
        valueGetter: (params: GridValueGetterParams) => (
          params.row.childNodeTypes && params.row.childNodeTypes.map((childNodeType: ChildNodeType) => {
            return childNodeType['name'];
          }).join(', ')
        )
      },
      {
        field: 'propertyTypes.service', 
        headerName: 'Service', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.Service, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.parts', 
        headerName: 'Parts', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.Parts, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.contact', 
        headerName: 'Contact', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.Contact, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.address', 
        headerName: 'Address', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.Address, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.tradingHours', 
        headerName: 'Trading Hours', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.TradingHours, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.assignment', 
        headerName: 'Services Assignment', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.ServicesAssignment, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.schema', 
        headerName: 'Site schema', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.SiteSchema, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.referanceNo', 
        headerName: 'Reference No', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.ReferenceNumbers, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.allocations', 
        headerName: 'Allocations', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center, 
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.Allocations, params.row.propertyTypes)
      },
      {
        field: 'propertyTypes.globalReturns', 
        headerName: 'Global Returns', 
        headerAlign: GridAllignmentTypes.Center, 
        align: GridAllignmentTypes.Center,
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(PropertyTypeEnum.GlobalReturns, params.row.propertyTypes)
      }
    ]);
  }, [permissions]);

  useEffect(() => {
    data && setStructureTypes(data);
  }, [data]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const handleChange = (searchText: string) => {
    setSearch(searchText);
    if (data) {
      const filteredStructureTypes = data?.filter((structureType) => structureType.code?.toLowerCase().includes(searchText.toLowerCase()) || structureType.name?.toLowerCase().includes(searchText.toLowerCase()));
      setStructureTypes(filteredStructureTypes);
    }
  };
  
  return (
    <GridContainer>
      <DataGrid
        columns={columns}
        rows={structureTypes}
        getRowId={(row) => row?.code}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true
          }
        }}
        getRowHeight={() => 'auto'}
        customToolbar={() => <CustomSearchBar value={search} handleChange={handleChange} />}
        loading={isLoading}
      />
    </GridContainer>
  );
};