import { SoftwareFirmwareHardwareData } from '../@types';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { ANY_KEYWORD } from '../constants/common';

export const onChangeSkuSFHValues = (value: string) => {
  const values = value.split(',');
  if (values.includes(ANY_KEYWORD)) {
    return [ANY_KEYWORD];
  } else {
    return values.filter((obj) => obj !== '');
  }
};

export const getSFHwareDropdownItems = (sfhwareData: Array<SoftwareFirmwareHardwareData>) => {
  return sfhwareData?.map((sfhwareItem) => {
    const option: DropdownItem = { label: sfhwareItem.version, value: sfhwareItem?.id?.toString() || '' };
    return option;
  });
};