import { styled } from '@mui/material/styles';
import { Box } from '../../../../../components/atoms/Box';
import { Typography } from '../../../../../components/atoms/Typography';

export const ContentWrapperBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 850px;
  width: 80%;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 20px;
`;

export const CloseWrapper = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
`;
