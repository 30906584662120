import { QueryOption } from '../@types';
import { MerchantDamagePartAdditionalData, MerchantDamageTemplateQuery } from '../@types/part.type';
import { httpClient, HttpMethod } from './http-client';

export const getAllParts = (queryOptions: QueryOption, serialSearch?: string, excludedColumns?: string) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/parts?page=${page + 1}&limit=${pageSize}&serial_search=${serialSearch}&excludedColumns=${excludedColumns}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const getNSPartByPartTypeAndLocation = (partTypeId: number, locationId: number, isReturn?: boolean) => {
  return httpClient(`/nsparts/search?partTypeId=${partTypeId}&locationId=${locationId}&isReturn=${isReturn}`, HttpMethod.Get, null);
};

export const getPartsLogAgainstService = (serviceId: number, locationTypeCode: string) => {
  return httpClient(`/partsLogAgainstService/${serviceId}?locationTypeCode=${locationTypeCode}`, HttpMethod.Get, null);
};

export const getMerchantDamagePartSerialData = (queryOptions: MerchantDamageTemplateQuery) => {
  const { serviceId, mainLocationNodeId } = queryOptions;
  const url = `/partHistory/${serviceId}/location/${mainLocationNodeId}/outPartActions`;
  return httpClient(url, HttpMethod.Get, null);
};

export const updatePartAdditionalData = (queryOptions: MerchantDamagePartAdditionalData, partId: number) => {
  const url = `/parts/${partId}/additionalData/merchantDamageData`;
  return httpClient(url, HttpMethod.Put, queryOptions);
};