import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';

export const StyledTypography = styled(Typography)`
  display: inline-block;
  white-space: pre-line;
  width: 135px;
  color: ${COLORS.DarkGray};
`;

export const StyledStack = styled(Stack)`
  width: 200px;
  margin: auto;
`;