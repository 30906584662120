import React, { SyntheticEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { Accordian } from '../../atoms/Accordian';
import { Typography } from '../../atoms/Typography';
import { SoftwareFirmwareHardwarePanelProps } from './SoftwareFirmwareHardwarePanel.props';
import { SoftwareFirmwareHardwareData } from '../../../@types/partType.type';
import { SFHCommonPanel } from '../../organisms/SFHCommonPanel';

export const SoftwareFirmwareHardwarePanel: React.FC<SoftwareFirmwareHardwarePanelProps> = ({
  header,
  softwareData,
  firmwareData,
  hardwareRevisionData,
  onSoftwareChange,
  onFirmwareChange,
  onHardwareRevisionChange,
  onSoftwareSave,
  onFirmwareSave,
  onHardwareRevisionSave
}) => {
  const [softwareformData, setSoftwareFormData] = useState<SoftwareFirmwareHardwareData[]>([]);
  const [firmwareformData, setFirmwareFormData] = useState<SoftwareFirmwareHardwareData[]>([]);
  const [hardwareformData, setHardwareFormData] = useState<SoftwareFirmwareHardwareData[]>([]);
  const [softwareFormDataId, setSoftwareFormDataId] = useState(-1);
  const [firmwareFormDataId, setFirmwareFormDataId] = useState(-1);
  const [hardwareFormDataId, setHardwareFormDataId] = useState(-1);
  
  const addNewSoftwareForm = (event: SyntheticEvent) => {
    event.stopPropagation();

    setSoftwareFormData([...softwareformData, {
      version: '',
      description: '',
      isActive: true,
      isNew: true
    }]);

    setSoftwareFormDataId(softwareFormDataId - 1);
  };

  const addNewFirmwareForm = (event: SyntheticEvent) => {
    event.stopPropagation();

    setFirmwareFormData([...firmwareformData, {
      version: '',
      description: '',
      isActive: true,
      isNew: true
    }]);

    setFirmwareFormDataId(firmwareFormDataId - 1);
  };

  const addNewHardwareRevisionForm = (event: SyntheticEvent) => {
    event.stopPropagation();

    setHardwareFormData([...hardwareformData, {
      version: '',
      description: '',
      isActive: true,
      isNew: true
    }]);

    setHardwareFormDataId(hardwareFormDataId - 1);
  };

  return (
    <Accordian title={
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>{header}</Typography>
        </Grid>
      </Grid>
    } defaultExpanded={true}>
      <Grid mb={5}>
        <SFHCommonPanel
          header="Software"
          sfhData={softwareData}
          sfhFormData={softwareformData}
          addNewSFHForm={addNewSoftwareForm}
          setSFHFormData={setSoftwareFormData}
          onSFHChange={onSoftwareChange}
          onSFHSave={onSoftwareSave}
        />
      </Grid>
      <Grid mb={5}>
        <SFHCommonPanel
          header="Firmware"
          sfhData={firmwareData}
          sfhFormData={firmwareformData}
          addNewSFHForm={addNewFirmwareForm}
          setSFHFormData={setFirmwareFormData}
          onSFHChange={onFirmwareChange}
          onSFHSave={onFirmwareSave}
        />
      </Grid>
      <Grid>
        <SFHCommonPanel
          header="Hardware Revision"
          sfhData={hardwareRevisionData}
          sfhFormData={hardwareformData}
          addNewSFHForm={addNewHardwareRevisionForm}
          setSFHFormData={setHardwareFormData}
          onSFHChange={onHardwareRevisionChange}
          onSFHSave={onHardwareRevisionSave}
        />
      </Grid>
    </Accordian>
  );
};