import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormControl, FormHelperText, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Button, ButtonVariantProps, ButtonColorProps } from '../../../components/atoms/Button';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { DateTimePicker } from '../../../components/atoms/DateTimePicker';
import { TextField } from '../../../components/atoms/TextField';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { SelectionButton } from '../../../components/molecules/SelectionButton';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { BrandSelector } from '../../../components/molecules/BrandSelector';
import { PrioritySelectMenu } from '../../../components/molecules/PrioritySelectMenu';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, Tags } from '../../../components/organisms/Tags';
import { Tag, LocationNodeData, NodeType, ActionPermissions, SizeProps } from '../../../@types';
import { ServiceTypography, ServiceBox, StyledSelectMenu, StyledBox } from './AddService.styles';
import { ServiceObject, PriorityType, ServiceCommonType, ServiceTemplateDataType, ServiceTargetOptions } from '../../../@types/service.type';
import { actionTypes, getHasCodeForNodeSearch, getInitialPriority, priorityTypes, processTypes, reportedFaultTypes, reportedModelTypes, serviceTypes } from '../../../utils/services';
import { EntityType, NodeAttributeType, ServiceCreationRuleTypes, StructureTypeCategory } from '../../../configs/enums';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { useAddService, useGetServiceActionProcessTypes } from '../../../queries/service-query';
import {
  useGetFilteredNodeAttributesByCustomRange,
  useGetNodeChildrenByNodeType,
  useGetNodeChildrenByNodeTypeAsEnabled,
  useGetNodeSubTree,
  useGetStructuresByTypeCategoryCodeAndTypeCode,
  useGetSuggestedNodesFromAllocationEngine
} from '../../../queries/structure-query';
import {
  useSearchAllocationNodes,
  useSearchNodeByParentId,
  useSearchNodeWithParts,
  useSearchParts,
  useSubLocationsForCreateServices
} from '../../../queries/live-search-query';
import { emptyFieldValidation, formatDate, isDropDownEmpty, isEmptyString } from '../../../utils/common';
import { getServiceAttributes, isClientEmpty, isHaveError, isRuleExist } from '../../../utils/services';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { LocationSearchSelect } from '../../../components/organisms/LocationSearchSelect';
import { ServiceTemplateList } from '../../../components/organisms/ServiceTemplateList';
import { useGetServiceTemplates } from '../../../queries/service-template-query';
import { EMPTY_FIELD_MESSAGE, NONE_ITEM, RICH_TEXT_EDITOR_EMPTY_TEXT, UNDEFINED_TREE_UPTO_THREE } from '../../../constants/common';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { SAMPLE_PRIORITY_TYPE, SAMPLE_SERVICE, SAMPLE_SERVICE_COMMON_TYPE } from '../../../constants/service.constant';
import { RichTextEditor } from '../../../components/atoms/RichTextEditor';
import { ServiceContactLayout } from '../../../components/templates/ServiceContactLayout';
import { ContactData } from '../../../components/molecules/Contact';
import { SearchCode } from '../../../@types/part.type';
import { ContactDataType } from '../../../components/templates/StructureAddressContactLayout';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const AddService: React.FC = () => {
  const navigate = useNavigate();
  const activeOnly = true;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedParentHierarchy, setSelectedParentHierarchy] = useState('');
  const [openParentSelector, setOpenParentSelector] = useState(false);

  const [data, setData] = useState<ServiceObject>(SAMPLE_SERVICE);

  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [serviceCommonTypes, setServiceCommonTypes] = useState<ServiceCommonType>(SAMPLE_SERVICE_COMMON_TYPE);
  const [queryNodeType, setQueryNodeType] = useState('client');
  const [selectedParentId, setSelectedParentId] = useState('');
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [brands, setBrands] = useState<DropdownItem[]>([]);
  const [nodeAttributes, setNodeAttributes] = useState<[PriorityType]>([SAMPLE_PRIORITY_TYPE]);
  const [parentId, setParentId] = useState('');
  const [brandId, setBrandId] = useState('0');
  const [nodeAttributeTypes, setNodeAttributeTypes] = useState<NodeAttributeType[]>([]);
  const [serviceTypeCode, setServiceTypeCode] = useState('');
  const [templateData, setTemplateData] = useState<ServiceTemplateDataType[]>([]);
  const [focusField, setFocusField] = useState('');
  const [defaultServiceTargetOption, setDefaultTargetOption] = useState<ServiceTargetOptions | null>();
  const [serviceTargetOptions, setServiceTargetOptions] = useState<ServiceTargetOptions[] | null>([]);
  const [selectedClientId, setSelectedClientId] = useState(NaN);
  const [selectedContractId, setSelectedContractId] = useState(NaN);
  const [selectedBrandId, setSelectedBrandId] = useState(NaN);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);

  const [locationId, setLocationId] = React.useState<number>();
  const [locationText, setLocationText] = useState<string>();
  const [locationNodeOptions, setLocationNodeOptions] = useState<Array<LocationNodeData>>();
  const [mainLocationNode, setMainLocationNode] = useState<LocationNodeData>();
  const [subLocationNode, setSubLocationNode] = useState<LocationNodeData>();
  const [locationSearchError, setLocationSearchError] = useState(false);
  const [suggestedNodesFromAllocation, setSuggestedNodesFromAllocation] = useState<LocationNodeData[]>([]);
  const [userAllocationPermission, setUserAllocationPermission] = useState(false);
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [validate, setValidate] = useState(false);

  const serviceTagsQuery = getAllTags(EntityType.TYPE_SERVICES);
  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const addServiceQuery = useAddService();
  const addTagsQuery = useAddTags();
  const getNodeAttributesByCustomRange = useGetFilteredNodeAttributesByCustomRange(parentId, nodeAttributeTypes, serviceTypeCode);
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();
  const getServiceTemplatesQuery = useGetServiceTemplates();
  const searchNodeByParentId = useSearchNodeByParentId();
  const getNodeSubTreeQuery = useGetNodeSubTree(locationId?.toString() || '0');
  const getSuggestedNodesFromAllocationEngine = useGetSuggestedNodesFromAllocationEngine(data.serviceTypeCode, data.actionTypeCode, data.processTypeCode, Number(brandId !== '0' ? brandId : parentId,), mainLocationNode?.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchParts = useSearchParts();
  const searchAllocationNodes = useSearchAllocationNodes();
  const searchNodeWithParts = useSearchNodeWithParts();
  const getNodeChildrenByNodeTypeAsEnabled = useGetNodeChildrenByNodeTypeAsEnabled();

  const [clientHelperText, setClientHelperText] = useState('');
  const [locationHelperText, setLocationHelperText] = useState('');
  const [reference1HelperText, setReference1HelperText] = useState('');
  const [reference2HelperText, setReference2HelperText] = useState('');
  const [reference3HelperText, setReference3HelperText] = useState('');
  const [serialNoHelperText, setSerialNoHelperText] = useState('');
  const [notesHelperText, setNotesHelperText] = useState('');
  const [selectedRules, setSelectedRules] = useState([]);

  const focusRef = useRef<any>({});

  const isError: boolean =
    isHaveError(data, selectedParentHierarchy, selectedRules) ||
    locationSearchError ||
    !mainLocationNode?.id ||
    isDropDownEmpty(data.priorityCode);

  TabHandler(`Create Service | ${PLATFORM_NAME}`);

  const isAtAnyLocation = () => {
    return serviceTargetOptions?.some(targetOption => targetOption === ServiceTargetOptions.AnyStructureLocation || targetOption === ServiceTargetOptions.SerialNumberAtAnyLocation);
  };

  useEffect(() => {
    onChangePriority(data.priorityCode);
  }, [data.priorityCode]);

  useEffect(() => {
    if (locationId && (!selectedParentHierarchy || !isAtAnyLocation())) {
      getNodeSubTreeQuery.refetch();
    }
  }, [locationId]);

  useEffect(() => {
    setUserAllocationPermission(getUserPermissionsQuery.data ? isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, getUserPermissionsQuery.data) : false);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (getNodeSubTreeQuery.data) {
      const overrideClientHiearachy = !isAtAnyLocation();
      const clientNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Client);

      if (clientNode) {
        setSelectedClientId(clientNode.id);

        if(overrideClientHiearachy) {
          setClients([{ value: clientNode.id.toString(), label: clientNode.name, disabled: !clientNode.isActive }]);
        }
      }

      const contractNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Contract);

      if (contractNode) {
        setSelectedContractId(contractNode.id);

        if(overrideClientHiearachy) {
          setContracts(NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat([{ value: contractNode.id.toString(), label: contractNode.name, disabled: !contractNode.isActive }]));
        }
        else {
          setSelectedParentId(clientNode?.id.toString() || '');
          setQueryNodeType(NodeType.Contract);
        }
      }

      const brandNode = getNodeSubTreeQuery.data.find(item => item.nodeTypeCode === NodeType.Brand);
      setSelectedParentHierarchy(clientNode ? `${clientNode?.name}/${contractNode?.name || ''}/${brandNode?.name || ''}` : '-');
      if(brandNode){
        setBrandId(brandNode?.id.toString());
        setSelectedParentId(brandNode.id.toString());
      }
      //TODO 
      //Here we are setting client node id as brandId, need to check this logic and refactor or remove
      /*  if (clientNode) {
        setBrandId(locationId?.toString() || '');
      } */
    }
  }, [getNodeSubTreeQuery.data]);

  useEffect(() => {
    if (brandId !== '0' || parentId) {
      getSuggestedNodesFromAllocationEngine.refetch();
    }
  }, [brandId,mainLocationNode,subLocationNode]);


  useEffect(() => {
    serviceActionProcessTypesQuery.data && setServiceCommonTypes(serviceActionProcessTypesQuery.data);
  }, [serviceActionProcessTypesQuery.data]);

  useEffect(() => {
    if (getSuggestedNodesFromAllocationEngine.data && userAllocationPermission) {
      const data = getSuggestedNodesFromAllocationEngine.data;
      data.forEach(item => item.isSuggestedNode = true);
      setSuggestedNodesFromAllocation(data);
    }
  }, [getSuggestedNodesFromAllocationEngine.data]);

  useEffect(() => {
    serviceTagsQuery.data && setAllTags(serviceTagsQuery.data);
  }, [serviceTagsQuery.data]);

  useEffect(() => {
    getStructuresByTypeCategoryCodeAndTypeCode.data && setClients(
      getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))
    );
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        const contractMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(contractMenuList);
        setContracts(contractMenuListWithAny);

        if(selectedContractId > 0) {
          setSelectedParentId(selectedContractId.toString());
          setQueryNodeType(NodeType.Brand);
        }
      }
      else if (queryNodeType === NodeType.Brand) {
        const brandMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        const brandMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(brandMenuList);
        setBrands(brandMenuListWithAny);
      }
    }
  }, [getNodeChildrenByNodeType.data]);


  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (parentId && nodeAttributeTypes && serviceTypeCode) {
      getNodeAttributesByCustomRange.refetch();
    }
  }, [parentId, nodeAttributeTypes, serviceTypeCode]);

  useEffect(() => {
    if (getNodeAttributesByCustomRange.data) {
      setNodeAttributes(getNodeAttributesByCustomRange.data);
      setData(prev => ({ ...prev, priorityCode: getInitialPriority(getNodeAttributesByCustomRange.data) }));
    }
  }, [getNodeAttributesByCustomRange.data]);

  useEffect(() => {
    if (getServiceTemplatesQuery.data) {
      let count = 0;
      const templates: ServiceTemplateDataType[] = [];
      getServiceTemplatesQuery.data.map(item => {
        item.nodeAssociations.map(data => {
          const obj = {
            id: ++count,
            name: item.name,
            description: data.name,
            data: item.data,
            icon: item.data.icon
          };
          templates.push(obj);
        });
      });
      setTemplateData(templates);
    }
  }, [getServiceTemplatesQuery.isLoading, getServiceTemplatesQuery.isSuccess]);

  useEffect(() => {
    if (serviceTypeCode) {
      const selectedServiceType = serviceCommonTypes.serviceTypes.find((serviceType) => serviceType.code === serviceTypeCode);
      let defaultTargetOption: ServiceTargetOptions | null = null;

      if (selectedServiceType?.targetOptions?.length === 1) {
        defaultTargetOption = selectedServiceType?.targetOptions[0].serviceTarget.code;

      }
      else if (selectedServiceType?.targetOptions) {
        const defaultOption = selectedServiceType?.targetOptions?.find(targetOption => targetOption.isDefault);
        if (defaultOption) {
          defaultTargetOption = defaultOption.serviceTarget.code;
        }
        else {
          defaultTargetOption = selectedServiceType?.targetOptions[0].serviceTarget.code;
        }
      }

      setDefaultTargetOption(defaultTargetOption);
      setServiceTargetOptions(selectedServiceType?.serviceTargetOptions || null);
    }
  }, [serviceTypeCode]);

  useEffect(() => {
    if (selectedRules?.length > 0 && data.priorityCode) {
      if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1) && isEmptyString(data.referenceNo1)) {
        setReference1HelperText(`${ServiceCreationRuleTypes.Reference1} ${EMPTY_FIELD_MESSAGE}`);
      }
      if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2) && isEmptyString(data.referenceNo2)) {
        setReference2HelperText(`${ServiceCreationRuleTypes.Reference2} ${EMPTY_FIELD_MESSAGE}`);
      }
      if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3) && isEmptyString(data.referenceNo3)) {
        setReference3HelperText(`${ServiceCreationRuleTypes.Reference3} ${EMPTY_FIELD_MESSAGE}`);
      }
      if (isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber) && (isEmptyString(data.reportedSerialNumber) || !data.reportedSerialNumber)) {
        setSerialNoHelperText(`${ServiceCreationRuleTypes.SerialNumber} ${EMPTY_FIELD_MESSAGE}`);
      }
      if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes) && isEmptyString(data.notes)) {
        setNotesHelperText(`${ServiceCreationRuleTypes.Notes} ${EMPTY_FIELD_MESSAGE}`);
      }
    }
  }, [selectedRules]);

  const onChangeSelectedTags = (value: (string | AutocompleteTagType)[]) => {
    setSelectedTags(value);
  };

  const onChangeNewTags = (value: Tag[]) => {
    setNewTags(value);
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    const nodeAttributeTypes = [NodeAttributeType.PriorityAndServices, NodeAttributeType.Models, NodeAttributeType.FaultCategory, NodeAttributeType.FaultCode];

    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
    setParentId(parentId);
    setNodeAttributeTypes(nodeAttributeTypes);
  };

  const onChangePriority = (value: any) => {
    const selectedPriority: any = nodeAttributes.filter((data: any) => { return data?.id == value; });

    if (selectedPriority.length && selectedPriority[0].value.selectedServiceCreationTypeValue) {
      setSelectedRules(selectedPriority[0].value.selectedServiceCreationTypeValue);
    } else setSelectedRules([]);

    setReference1HelperText('');
    setReference2HelperText('');
    setReference3HelperText('');
    setSerialNoHelperText('');
    setNotesHelperText('');
    
    value === '';
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleServiceCreate = async () => {
    if (isClientEmpty(selectedParentHierarchy)) {
      setClientHelperText(`Client ${EMPTY_FIELD_MESSAGE}`);
    }
    if (!mainLocationNode?.id) {
      setLocationHelperText(`Location ${EMPTY_FIELD_MESSAGE}`);
    }
    if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1) && isEmptyString(data.referenceNo1)) {
      setReference1HelperText(`${ServiceCreationRuleTypes.Reference1} ${EMPTY_FIELD_MESSAGE}`);
    }
    if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2) && isEmptyString(data.referenceNo2)) {
      setReference2HelperText(`${ServiceCreationRuleTypes.Reference2} ${EMPTY_FIELD_MESSAGE}`);
    }
    if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3) && isEmptyString(data.referenceNo3)) {
      setReference3HelperText(`${ServiceCreationRuleTypes.Reference3} ${EMPTY_FIELD_MESSAGE}`);
    }
    if (isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber) && isEmptyString(data.reportedSerialNumber)) {
      setSerialNoHelperText(`${ServiceCreationRuleTypes.SerialNumber} ${EMPTY_FIELD_MESSAGE}`);
    }
    if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes) && isEmptyString(data.notes)) {
      setNotesHelperText(`${ServiceCreationRuleTypes.Notes} ${EMPTY_FIELD_MESSAGE}`);
    }

    if (isError) {
      setValidate(true);
      return;
    }

    const timeout = (delay: number) => {
      return new Promise((res) => setTimeout(res, delay));
    };

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    const service: any = await addServiceQuery.mutateAsync({
      serviceTypeCode: data.serviceTypeCode,
      actionTypeCode: data.actionTypeCode,
      processTypeCode: data.processTypeCode,
      clientHierarchyId: brandId !== '0' ? brandId : parentId,
      priorityCode: data.priorityCode,
      subLocationNodeId: subLocationNode?.id,
      mainLocationNodeId: mainLocationNode?.id,
      scheduledAt: formatDate(data.scheduledAt, ''),
      notes: data.notes,
      serviceAttributes: getServiceAttributes(data),
      userDepotAllocationNodeId: data.userDepotAllocationNodeId,
      elementForDisplaying: structureContactDataset
    });

    await addTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_SERVICES,
      entityId: service.id,
      freshTags,
      existingTags
    }).then(async () => {
      setOpenSnackbar(true);
      await timeout(1000);
      navigate(`/services/${service.id}`);
    });

    setOpenSnackbar(true);
  };

  return (
    <PageContainer>
      <ServiceTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        Create Service
      </ServiceTypography>
      <Grid container>
        <Grid>
          <ServiceTemplateList
            templateData={templateData}
            expanded={true}
            onClickItem={async (obj) => {
              setData(SAMPLE_SERVICE);
              setSelectedParentHierarchy('');
              setSelectedClientId(NaN);
              setSelectedContractId(NaN);
              setSelectedBrandId(NaN);
              setLocationNodeOptions(undefined);
              setMainLocationNode(undefined);
              setSubLocationNode(undefined);
              setLocationText('');
              setLocationSearchError(true);
              setLocationId(NaN);
              setBrandId('0');
              setParentId('');
              setSelectedParentId('');

              if (obj?.data?.autoFill?.serviceTypeCode) {
                setServiceTypeCode(obj.data.autoFill.serviceTypeCode);
              }
              if (obj?.data?.autoFill?.priorityCode) {
                setValidate(false);
              }
              if (obj?.data?.autoFill?.notes) {
                setNotesHelperText('');
              }

              setData({
                ...data,
                serviceTypeCode: obj.data.autoFill.serviceTypeCode || '',
                actionTypeCode: obj.data.autoFill.actionTypeCode || '',
                processTypeCode: obj.data.autoFill.processTypeCode || '',
                priorityCode: obj.data.autoFill.priorityCode || '',
                notes: obj.data.autoFill.notes || ''
              });

              if (obj?.data?.autoFill?.locationSearch) {
                setSelectedParentHierarchy(obj.data.autoFill.parentHierarchy);
                setSelectedClientId(obj.data.autoFill.clientId);
                setSelectedContractId(obj.data.autoFill.contractId);
                setSelectedBrandId(obj.data.autoFill.brandId);

                const contractList = await getNodeChildrenByNodeTypeAsEnabled.mutateAsync({
                  nodeId: obj.data.autoFill.clientId,
                  nodeTypeCode: 'contract'
                });
                const contractMenuList = contractList.map(item => ({
                  value: item.id.toString(),
                  label: item.name,
                  disabled: !item.isActive
                }));
                const contractMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(contractMenuList);
                setContracts(contractMenuListWithAny);

                const brandList = await getNodeChildrenByNodeTypeAsEnabled.mutateAsync({
                  nodeId: obj.data.autoFill.contractId,
                  nodeTypeCode: 'brand'
                });
                const brandMenuList = brandList.map(item => ({
                  value: item.id.toString(),
                  label: item.name,
                  disabled: !item.isActive
                }));
                const brandMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(brandMenuList);
                setBrands(brandMenuListWithAny);

                setParentId(obj.data.autoFill.contractId !== 0 ? obj.data.autoFill.contractId.toString() : obj.data.autoFill.clientId.toString());
                !!obj.data.autoFill.brandId && setBrandId(obj.data.autoFill.brandId.toString());

                const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(parseInt(obj?.data?.autoFill?.locationSearch));
                setLocationId(parseInt(obj?.data?.autoFill?.locationSearch));

                if (subLocationsResult && subLocationsResult.length > 0) {
                  const main = subLocationsResult.find(subLocation => subLocation.isMain);
                  const sub = subLocationsResult.find(subLocation => !subLocation.isMain && subLocation.isSelected);

                  //if we have a main node and a selected subLocation, select them by default
                  if (main && sub) {
                    setMainLocationNode(main);
                    setSubLocationNode(sub);
                    setLocationText(`${main.name || ''} ${(main.name && sub.name) ? '/' : ''} ${sub.name || ''}`.trim());
                  }
                  else {
                    const subLocationsOnly = subLocationsResult.filter(subLocation => !subLocation.isMain);
                    if (main?.isSelected && subLocationsOnly.length === 0) {
                      setMainLocationNode(main);
                      setSubLocationNode(undefined);
                      setLocationText(main.name);
                    }
                    else {
                      setLocationNodeOptions(subLocationsResult);
                    }
                  }

                  setLocationSearchError(false);
                }
                setIsTemplate(true);
              }

              if (obj?.data?.autoFill?.selectedParentHierarchy) {
                setSelectedParentHierarchy(obj.data.autoFill.selectedParentHierarchy);
                setSelectedClientId(obj.data.autoFill.clientId);
                setSelectedContractId(obj.data.autoFill.contractId);
                setSelectedBrandId(obj.data.autoFill.brandId);

                const contractList = await getNodeChildrenByNodeTypeAsEnabled.mutateAsync({
                  nodeId: obj.data.autoFill.clientId,
                  nodeTypeCode: 'contract'
                });
                const contractMenuList = contractList.map(item => ({
                  value: item.id.toString(),
                  label: item.name,
                  disabled: !item.isActive
                }));
                const contractMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(contractMenuList);
                setContracts(contractMenuListWithAny);

                const brandList = await getNodeChildrenByNodeTypeAsEnabled.mutateAsync({
                  nodeId: obj.data.autoFill.contractId,
                  nodeTypeCode: 'brand'
                });
                const brandMenuList = brandList.map(item => ({
                  value: item.id.toString(),
                  label: item.name,
                  disabled: !item.isActive
                }));
                const brandMenuListWithAny = NONE_ITEM.map((item) => ({ ...item, disabled: false })).concat(brandMenuList);
                setBrands(brandMenuListWithAny);

                setParentId(obj.data.autoFill.contractId !== 0 ? obj.data.autoFill.contractId.toString() : obj.data.autoFill.clientId.toString());
                !!obj.data.autoFill.brandId && setBrandId(obj.data.autoFill.brandId.toString());

                setIsTemplate(true);
              }

              if (obj?.data?.focus === 'clientSelection') {
                setOpenParentSelector(true);
                getStructuresByTypeCategoryCodeAndTypeCode.refetch();
              }

              setFocusField(obj?.data?.focus as string);
              focusRef.current[obj?.data?.focus].focus();
            }}
          />
        </Grid>
        <Grid xs={true}>
          <ServiceBox>
            <Grid container spacing={GRID_SPACING}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={GRID_SPACING}>
                  <Grid item xs={12}>
                    <StyledSelectMenu
                      id="service-type-select"
                      required={true}
                      label="Service Type*"
                      isFocused={focusField === 'serviceTypeCode'}
                      selectedValue={data.serviceTypeCode}
                      onChange={(value) => {
                        setServiceTypeCode(value);
                        setData(prev => ({ ...prev, serviceTypeCode: value }));
                      }}
                      items={serviceTypes(serviceCommonTypes.serviceTypes)}
                      validate={validate}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSelectMenu
                      id="action-type-select"
                      required={true}
                      label="Action Type*"
                      isFocused={focusField === 'actionTypeCode'}
                      selectedValue={data.actionTypeCode}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, actionTypeCode: value }));
                      }}
                      items={actionTypes(serviceCommonTypes.actionTypes)}
                      validate={validate}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledSelectMenu
                      id="process-type-select"
                      required={true}
                      label="Process Type*"
                      isFocused={focusField === 'processTypeCode'}
                      selectedValue={data.processTypeCode}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, processTypeCode: value }));
                      }}
                      items={processTypes(serviceCommonTypes.processTypes)}
                      validate={validate}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectionButton
                      label="Client Selection*"
                      error={!!clientHelperText}
                      value={selectedParentHierarchy}
                      onClick={() => {
                        setOpenParentSelector(true);
                        getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PrioritySelectMenu
                      id="priority-type-select"
                      label="Priority"
                      isFocused={focusField === 'priorityCode'}
                      value={data.priorityCode}
                      onChange={(value: string) => {
                        setData(prev => ({ ...prev, priorityCode: value }));
                      }}
                      items={priorityTypes(nodeAttributes)}
                      validate={validate}
                      optionalLabelEnabled={true}
                      disabled={!parentId || !serviceTypeCode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {locationNodeOptions ?
                      <LocationSearchSelect
                        title="Location Search"
                        options={locationNodeOptions}
                        value={subLocationNode}
                        onClose={() => {
                          setLocationNodeOptions(undefined);
                        }}
                        onChange={(selectedNode) => {
                          const main = locationNodeOptions.find(item => item.isMain);
                          setMainLocationNode(main);
                          setLocationHelperText('');

                          const sub = locationNodeOptions.find(item => !item.isMain && item.id === selectedNode.id);
                          setSubLocationNode(sub);

                          setLocationText(`${main?.name || ''} ${(main?.name && sub?.name) ? '/' : ''} ${sub?.name || ''}`.trim());
                          setLocationNodeOptions(undefined);
                        }}
                      />
                      :
                      <LiveSearchBox
                        title="Location Search"
                        isRequired={true}
                        timeOffset={400}
                        freeSolo={false}
                        multiline={false}
                        inputRef={(element: HTMLElement) => { focusRef.current['locationSearch' as keyof typeof focusRef] = element; }}
                        value={locationText || ''}
                        onClearValue={() => {
                          setLocationText(undefined);
                          setMainLocationNode(undefined);
                          setSubLocationNode(undefined);
                          setLocationNodeOptions(undefined);
                        }}
                        renderItem={(props: any, option: any) => {
                          return (
                            <LiveSearchListItem {...props}>
                              <LocationSearchItem data={option} />
                            </LiveSearchListItem>
                          );
                        }}
                        onChange={async (locationNodeData: LocationNodeData) => {
                          let id;
                          if (locationNodeData.isPartSearch) {
                            setData(prev => ({
                              ...prev,
                              reportedSerialNumberPartId: locationNodeData.id,
                              reportedSerialNumber: locationNodeData.name
                            }));
                            id = locationNodeData.locationId;
                            isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber) && setSerialNoHelperText('');
                          } else {
                            id = locationNodeData.id;
                          }

                          const subLocationsResult = id !== 0 && await subLocationsForCreateServicesQuery.mutateAsync(id);
                          id !== 0 && setLocationId(id);

                          if (subLocationsResult && subLocationsResult.length > 0) {
                            const main = subLocationsResult.find(subLocation => subLocation.isMain);
                            const sub = subLocationsResult.find(subLocation => !subLocation.isMain && subLocation.isSelected);

                            //if we have a main node and a selected subLocation, select them by default
                            if (main && sub) {
                              setMainLocationNode(main);
                              setSubLocationNode(sub);
                              setLocationText(`${main.name || ''} ${(main.name && sub.name) ? '/' : ''} ${sub.name || ''}`.trim());
                            }
                            else {
                              const subLocationsOnly = subLocationsResult.filter(subLocation => !subLocation.isMain);
                              if (main?.isSelected && subLocationsOnly.length === 0) {
                                setMainLocationNode(main);
                                setSubLocationNode(undefined);
                                setLocationText(main.name);
                              }
                              else {
                                setLocationNodeOptions(subLocationsResult);
                              }
                            }

                            setLocationSearchError(false);
                          } else {
                            setLocationNodeOptions(undefined);
                            setMainLocationNode(undefined);
                            setSubLocationNode(undefined);
                            setLocationText(' ');
                            setLocationSearchError(true);
                          }
                        }}
                        onApiInvoke={async (name) => {
                          if (!data.reportedSerialNumber) {
                            const hasCode = getHasCodeForNodeSearch(name);
                            if (!name.toLowerCase().startsWith(SearchCode.S_HASH) && defaultServiceTargetOption === ServiceTargetOptions.AnyStructureLocation || serviceTargetOptions?.some(targetOption => targetOption === ServiceTargetOptions.AnyStructureLocation) && name.toUpperCase().startsWith(SearchCode.AM_LID)) {
                              return await searchNodeByParentId.mutateAsync({
                                name,
                                hasCode: hasCode
                              });
                            } else if (!name.toUpperCase().startsWith(SearchCode.AM_LID) && defaultServiceTargetOption === ServiceTargetOptions.SerialNumberUnderClientStructure || serviceTargetOptions?.some(targetOption => targetOption === ServiceTargetOptions.SerialNumberUnderClientStructure) && name.toLowerCase().startsWith(SearchCode.S_HASH)) {
                              return await searchNodeWithParts.mutateAsync({
                                name: name.replace('#', '%23'),
                                isInClientHierarchy: true
                              });
                            } else if (!name.toUpperCase().startsWith(SearchCode.AM_LID) && defaultServiceTargetOption === ServiceTargetOptions.SerialNumberAtAnyLocation || serviceTargetOptions?.some(targetOption => targetOption === ServiceTargetOptions.SerialNumberAtAnyLocation) && name.toLowerCase().startsWith(SearchCode.S_HASH)) {
                              return await searchNodeWithParts.mutateAsync({
                                name: name.replace('#', '%23'),
                                isInClientHierarchy: true
                              });
                            } else if (!name.toLowerCase().startsWith(SearchCode.S_HASH) && defaultServiceTargetOption === ServiceTargetOptions.StructureLocationUnderClient || serviceTargetOptions?.some(targetOption => targetOption === ServiceTargetOptions.StructureLocationUnderClient) && name.toUpperCase().startsWith(SearchCode.AM_LID)) {
                              return await searchNodeByParentId.mutateAsync({
                                selectedParentId: brandId !== '0' ? brandId : parentId,
                                name,
                                hasCode: hasCode
                              });
                            }

                            return await searchNodeByParentId.mutateAsync({
                              selectedParentId: brandId !== '0' ? brandId : parentId,
                              name,
                              hasCode: hasCode
                            });
                          }
                          else {
                            const hasCode = getHasCodeForNodeSearch(name);

                            if(isAtAnyLocation()) {
                              return await searchNodeByParentId.mutateAsync({
                                name,
                                hasCode: hasCode
                              });
                            }
                            return await searchNodeByParentId.mutateAsync({
                              selectedParentId: brandId !== '0' ? brandId : parentId,
                              name,
                              hasCode: hasCode
                            });
                          }
                        }}
                        isError={locationSearchError}
                        helperText={locationHelperText}
                        size={SizeProps.Medium}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required={isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1)}
                      fullWidth
                      label="Reference No 1"
                      inputRef={(element: HTMLElement) => { focusRef.current['referenceNo1' as keyof typeof focusRef] = element; }}
                      value={data.referenceNo1}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, referenceNo1: value }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference1)) {
                          setReference1HelperText(emptyFieldValidation(value, ServiceCreationRuleTypes.Reference1));
                        }
                      }}
                      helperText={reference1HelperText}
                      error={!!reference1HelperText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required={isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2)}
                      fullWidth
                      label="Reference No 2"
                      inputRef={(element: HTMLElement) => { focusRef.current['referenceNo2' as keyof typeof focusRef] = element; }}
                      value={data.referenceNo2}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, referenceNo2: value }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference2)) {
                          setReference2HelperText(emptyFieldValidation(value, ServiceCreationRuleTypes.Reference2));
                        }
                      }}
                      helperText={reference2HelperText}
                      error={!!reference2HelperText}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required={isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3)}
                      fullWidth
                      label="Reference No 3"
                      inputRef={(element: HTMLElement) => { focusRef.current['referenceNo3' as keyof typeof focusRef] = element; }}
                      value={data.referenceNo3}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, referenceNo3: value }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Reference3)) {
                          setReference3HelperText(emptyFieldValidation(value, ServiceCreationRuleTypes.Reference3));
                        }
                      }}
                      helperText={reference3HelperText}
                      error={!!reference3HelperText}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tags
                      allTags={allTags}
                      inputRef={(element: HTMLElement) => { focusRef.current['tags' as keyof typeof focusRef] = element; }}
                      selectedTags={selectedTags}
                      setSeletedTags={onChangeSelectedTags}
                      newTags={newTags}
                      setNewTags={onChangeNewTags}
                    />
                  </Grid>
                  {userAllocationPermission && (
                    <Grid item xs={12} md={6}>
                      <LiveSearchBox
                        title="User/Depot Allocation"
                        timeOffset={400}
                        value={data.userDepotAllocation ?? ''}
                        onClearValue={() => setData({ ...data, userDepotAllocation: '', userDepotAllocationNodeId: undefined })}
                        defaultSuggetions={suggestedNodesFromAllocation}
                        renderItem={(props: any, option: any) => {
                          return (
                            <StyledBox heighlightBackground={option.isSuggestedNode}>
                              <LiveSearchListItem {...props}>
                                <LocationSearchItem data={option} />
                              </LiveSearchListItem>
                            </StyledBox>
                          );
                        }}
                        onChange={async (obj: LocationNodeData) => setData({ ...data, userDepotAllocation: obj.name, userDepotAllocationNodeId: obj.id })} //node: obj
                        onApiInvoke={async (obj) => {
                          const searcedhAllocations = await searchAllocationNodes.mutateAsync(obj);
                          if (suggestedNodesFromAllocation && suggestedNodesFromAllocation.length > 0) {
                            const newListWithoutDuplicates = searcedhAllocations.filter(item => {
                              return !suggestedNodesFromAllocation.some(suggestedNode => suggestedNode.id == item.id);
                            });

                            return newListWithoutDuplicates;
                          }
                          return searcedhAllocations;
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      label="Scheduled Date/Time"
                      disablePast={true}
                      isFocused={focusField === 'scheduledAt'}
                      value={data.scheduledAt}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, scheduledAt: value }));
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={GRID_SPACING}>
                  <Grid item xs={12}>
                    <Box>
                      <FormControl
                        fullWidth
                        required={isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes)}
                        error={!!notesHelperText}>
                        <RichTextEditor
                          value={data?.notes || ''}
                          onValueChange={(value: string) => {
                            setData(prev => ({ ...prev, notes: value }));
                            if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Notes)) {
                              if (value === RICH_TEXT_EDITOR_EMPTY_TEXT) {
                                value = '';
                              }
                              setNotesHelperText(emptyFieldValidation(value, ServiceCreationRuleTypes.Notes));
                            }
                          }}
                          error={!!notesHelperText}
                        />
                        {notesHelperText && (
                          <FormHelperText>{notesHelperText}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <LiveSearchBox
                      isRequired={isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber)}
                      isError={!!serialNoHelperText}
                      helperText={serialNoHelperText}
                      title="Reported Serial Number"
                      timeOffset={400}
                      value={data.reportedSerialNumber || ''}
                      onClearValue={() => {
                        setData(prev => ({
                          ...prev,
                          reportedSerialNumberPartId: NaN,
                          reportedSerialNumber: ''
                        }));
                      }}
                      renderItem={(props: any, option: any) => {
                        return (
                          <LiveSearchListItem {...props}>
                            <LocationSearchItem data={option} />
                          </LiveSearchListItem>
                        );
                      }}
                      onChange={(serialData: LocationNodeData) => {
                        setData(prev => ({
                          ...prev,
                          reportedSerialNumberPartId: serialData?.id,
                          reportedSerialNumber: serialData?.name
                        }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.SerialNumber)) {
                          setSerialNoHelperText(serialData ? emptyFieldValidation(serialData.name, ServiceCreationRuleTypes.SerialNumber) : `${ServiceCreationRuleTypes.SerialNumber} ${EMPTY_FIELD_MESSAGE}`);
                        }
                      }}
                      onApiInvoke={async (name: string) => {
                        return await searchParts.mutateAsync({ name });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSelectMenu
                      id="model-type-select"
                      required={isRuleExist(selectedRules, ServiceCreationRuleTypes.Model)}
                      label="Reported Model"
                      isFocused={focusField === 'reportedModel'}
                      selectedValue={data.reportedModel}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, reportedModel: value }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.Model)) {
                          setValidate(true);
                        } else {
                          setValidate(false);
                        }
                      }}
                      items={reportedModelTypes(nodeAttributes)}
                      disabled={!parentId || !serviceTypeCode}
                      validate={isRuleExist(selectedRules, ServiceCreationRuleTypes.Model) && data.reportedModel === '-1'}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledSelectMenu
                      id="fault-type-select"
                      required={isRuleExist(selectedRules, ServiceCreationRuleTypes.FaultAndDescription)}
                      label="Reported Fault and Description"
                      isFocused={focusField === 'reportedFaultAndDescription'}
                      selectedValue={data.reportedFaultAndDescription}
                      onChange={(value) => {
                        setData(prev => ({ ...prev, reportedFaultAndDescription: value }));
                        if (isRuleExist(selectedRules, ServiceCreationRuleTypes.FaultAndDescription)) {
                          setValidate(true);
                        } else {
                          setValidate(false);
                        }
                      }}
                      items={reportedFaultTypes(nodeAttributes)}
                      disabled={!parentId || !serviceTypeCode}
                      validate={isRuleExist(selectedRules, ServiceCreationRuleTypes.FaultAndDescription) && data.reportedFaultAndDescription === '-1'}
                      optionalLabelEnabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ServiceContactLayout
                      isService={true}
                      contactFormData={contactData}
                      editContactFormData={editContactData}
                      contactDataset={structureContactDataset ? structureContactDataset : []}
                      onContactChange={(value) => {
                        setContactData(value);
                      }}
                      onClickContactEdit={(event, index) => {
                        const selectedValue: any = structureContactDataset.splice(index, 1);
                        setStructureContactDataset(structureContactDataset);
                        setEditContactData(selectedValue[0]);
                      }}
                      onClickContactDelete={(event, index) => {
                        const updatedData = [...structureContactDataset];
                        if (index > -1) updatedData.splice(index, 1);
                        setStructureContactDataset(updatedData);
                      }}
                      handleContactCreate={(index) => {
                        if (index > -1) contactData[index].isActive = true;
                        setStructureContactDataset([...structureContactDataset, contactData[index]]);
                        contactData.splice(index, 1);
                        setContactData([...contactData]);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Service Added!"
                action={
                  <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
                    X
                  </Button>
                }
              />
              <BrandSelector
                open={openParentSelector}
                onChangeNodeType={onChangeNodeType}
                handleClose={() => {
                  setOpenParentSelector(false);
                }}
                handleClear={() => {
                  setSelectedClientId(NaN);
                  setSelectedContractId(NaN);
                  setSelectedBrandId(NaN);
                  setBrandId('0');
                  setParentId('');
                
                  setSelectedParentHierarchy('');
                  setNodeAttributes([SAMPLE_PRIORITY_TYPE]);
                  setData(prev => ({ ...prev, priorityCode: '' }));
                  setSelectedParentHierarchy('');

                  if (!isAtAnyLocation()) {
                    setLocationId(NaN);
                    setLocationText(undefined);
                    setMainLocationNode(undefined);
                    setSubLocationNode(undefined);
                    setLocationNodeOptions(undefined);
                  }

                  getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                }}
                handleParentSelection={(selectedBrandId: string, value: string) => {
                  setBrandId(selectedBrandId);
                  setOpenParentSelector(false);
                  setSelectedParentHierarchy(value === UNDEFINED_TREE_UPTO_THREE ? '' : value);
                  isClientEmpty(value) || setClientHelperText('');

                  if (!isAtAnyLocation()) {
                    setLocationId(NaN);
                    setLocationText(undefined);
                    setMainLocationNode(undefined);
                    setSubLocationNode(undefined);
                    setLocationNodeOptions(undefined);
                  }
                }}
                clients={clients}
                contracts={contracts}
                brands={brands}
                allFieldsRequired={false}
                clientId={selectedClientId}
                contractId={selectedContractId}
                brandId={selectedBrandId}
                pageType="add-service"
                setIsTemplate={setIsTemplate}
                isTemplate={isTemplate}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Stack alignItems="flex-end">
                  <Button variant={ButtonVariantProps.Primary} onClick={handleServiceCreate}>Save</Button>
                </Stack>
              </Grid>
            </Grid>
          </ServiceBox>
        </Grid>
      </Grid>
    </PageContainer>
  );
};