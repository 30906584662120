import { styled } from '@mui/material/styles';
import { SelectMenu } from '../../../../components/atoms/SelectMenu';
import { Typography } from '../../../../components/atoms/Typography';

export const StyledTableWrapper = styled('div')`
  overflow: auto;
  width: 100%;
`;

export const ImageBox = styled('img')`
  width: 100px;
  height: 100px;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: auto;
`;

export const ClampedTypography = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

