import React, { useEffect, useState } from 'react';
import { Autocomplete, createFilterOptions, Grid } from '@mui/material';
import { SizeProps } from '../../../@types';
import { SelectMenu } from '../../atoms/SelectMenu';
import { TextField } from '../../atoms/TextField';
import { ManualAddressProps, OptionType } from './ManualAddress.props';
import { isEmptyString } from '../../../utils/common';
import { GRID_SPACING } from '../../../configs/ui-constants';

export const ManualAddress: React.FC<ManualAddressProps> = ({
  onChange,
  error,
  helperText,
  manualAddressData,
  addressTypeData,
  disableTypeSelection = false,
  countries,
  states,
  enableContactNumber
}) => {
  const [countryValue, setCountryValue] = useState<OptionType | null>(null);
  const [stateValue, setStateValue] = useState<OptionType | null>(null);
  
  useEffect(() => {
    manualAddressData?.countryName && setCountryValue(countries.find(item => item.name === manualAddressData.countryName) || null);
  }, [manualAddressData?.countryName, countries]);

  useEffect(() => {
    manualAddressData?.stateName && setStateValue(states?.find(item => item.name === manualAddressData.stateName) || null);
  }, [manualAddressData?.stateName, states]);

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: OptionType) => option.name,
  });

  return (
    <>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} md={4}>
          <SelectMenu
            id="page-size-menu"
            minWidth={'100%'}
            items={addressTypeData}
            disabled={disableTypeSelection}
            selectedValue={manualAddressData.addressType}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                addressType: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Name"
            error={error}
            helperText={helperText?.nameHelperText}
            value={manualAddressData?.name}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                name: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            required
            label="Line 1"
            error={isEmptyString(manualAddressData?.line1 as string)}
            helperText={helperText?.line1HelperText}
            value={manualAddressData?.line1}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                line1: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Line 2"
            error={error}
            helperText={helperText?.line2HelperText}
            value={manualAddressData?.line2}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                line2: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            required
            label="Suburb"
            error={isEmptyString(manualAddressData?.suburb as string)}
            helperText={helperText?.suburbHelperText}
            value={manualAddressData?.suburb}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                suburb: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="City"
            error={error}
            helperText={helperText?.cityHelperText}
            value={manualAddressData?.city}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                city: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            required
            label="Postcode"
            error={isEmptyString(manualAddressData?.postCode as string)}
            helperText={helperText?.postCodeHelperText}
            value={manualAddressData?.postCode}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                postCode: text
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="country-filter"
            options={countries}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            onChange={(event, data) => {
              setCountryValue(data);
              onChange({
                ...manualAddressData,
                countryId: data?.id,
                countryName: data?.name
              });
            }}
            value={countryValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                required
                size={SizeProps.Small}
                error={isEmptyString(manualAddressData?.countryName as string)}
                helperText={helperText?.countryDataHelperText}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="state-filter"
            options={states}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            onChange={(event, data) => {
              setStateValue(data);
              onChange({
                ...manualAddressData,
                stateId: data?.id,
                stateName: data?.name
              });
            }}
            value={stateValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label="State"
                required
                size={SizeProps.Small}
                error={isEmptyString(manualAddressData?.stateName as string)}
                helperText={helperText?.stateDataHelperText}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Traditional Place Name"
            error={error}
            helperText={helperText?.placeNameHelperText}
            value={manualAddressData?.placeName}
            onChange={(text) => {
              onChange({
                ...manualAddressData,
                placeName: text
              });
            }}
          />
        </Grid>
        {enableContactNumber && 
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              required
              label="Contact Number"
              error={isEmptyString(manualAddressData?.contactNumber?.toString())}
              helperText={helperText?.contactNoHelperText}
              value={manualAddressData?.contactNumber}
              onChange={(text) => {
                onChange({
                  ...manualAddressData,
                  contactNumber: text
                });
              }}
            />
          </Grid>
        }
      </Grid>
    </>
  );
};
