import { styled } from '@mui/material';
import { PageContainerProps, HorizontalAlignProps } from './PageContainer.props';

export const Root = styled('div') <PageContainerProps>`
  padding: 10px;
  display: flex;
  flex-direction: column;

  ${({ align }) => align === HorizontalAlignProps.Center && 'align-items: center'}
`;

export const Children = styled('div') <PageContainerProps>`
  width: 100%;

  ${({ align }) => align === HorizontalAlignProps.Center && 'max-width: 1200px'}
`;