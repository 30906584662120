import React, { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { ExceptionPartEditProps } from './ExceptionPartEdit.props';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';

import { GRID_SPACING } from '../../../configs/ui-constants';
import { PartImageBox } from './ExceptionPartEdit.styles';
import { Button, ButtonColorProps, ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Clear, Done } from '@mui/icons-material';
import { formatDate } from '../../../utils/common';
import { TextField } from '../../atoms/TextField';

export const ExceptionPartEdit: React.FC<ExceptionPartEditProps> = ({
  data,
  onRespond
}) => {
  const [note, setNote] = useState('');

  const onPartExceptionUpdate = async (isAccepted: boolean) => {
    const updateData: any = {
      id: data.id,
      isAccepted,
      exceptionNotes: note
    };

    updateData.partHistoryNotes = isAccepted ? 'Exceptional Part accepted, exception cleared': 'Exceptional Part declined, exception cleared, part disabled';
    onRespond(updateData);
  };

  return (
    <Grid container justifyContent="space-between" spacing={2} pr={1} pl={1} mt={1}>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item alignItems="flex-start" xs={12}>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.H6}>Exception Part</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.Body1}>Created at: {data.createdAt ? formatDate(data.createdAt) : ''}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.Body1}>Created by: {data.createdBy}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.Body1}>Service ID: {data.additionalData?.serviceId || ''}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={TypographyVariantProps.Body1}>Part Action: {data.additionalData?.partActionType || ''}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12} sm={6}>
        <Grid item alignItems="flex-start" xs={12}>
          {(data.additionalData?.imageData && data.additionalData?.imageData.length > 0) &&
            <Grid item container xs={12} spacing={1} justifyContent="flex-end">
              {data.additionalData.imageData[0] && <Grid padding={1} alignItems="center">
                <PartImageBox src={data.additionalData.imageData[0]} />
              </Grid>}
              {data.additionalData.imageData[1] && <Grid padding={1} alignItems="center">
                <PartImageBox src={data.additionalData.imageData[1]} />
              </Grid>}
            </Grid>}
          <Grid item xs={12} mt={1}>
            <TextField
              fullWidth
              label="Acceptance Note"
              value={note}
              onChange={(text) => setNote(text)}
            />
          </Grid>
          <Stack direction="row" spacing={2} marginTop={1} justifyContent="end" width="100%">
            <Grid>
              <Grid mt={GRID_SPACING}>
                <Button
                  variant={ButtonVariantProps.Icon}
                  size={ButtonSizeProps.Medium}
                  onClick={() => onPartExceptionUpdate(false)}
                >
                  <Clear fontSize="large" color={ButtonColorProps.Error} />
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Grid mt={GRID_SPACING}>
                <Button
                  variant={ButtonVariantProps.Icon}
                  size={ButtonSizeProps.Medium}
                  onClick={() => onPartExceptionUpdate(true)}
                >
                  <Done fontSize="large" color={ButtonColorProps.Success} />
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};