import React from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridColumnHeaderParams, GridDensityTypes, GridRenderCellParams, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { AttendanceProps } from './AttendanceList.props';
import { Accordian } from '../../atoms/Accordian';
import { formatDate } from '../../../utils/common';
import { useMediaQuery } from '@material-ui/core';
import { ScreenSize } from '../../../@types';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { GridContainer, StyledCell } from './AttendanceList.styles';

export const AttendanceList: React.FC<AttendanceProps> = ({
  attendanceData,
}) => {

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  function styledCell(params: GridRenderCellParams<any, any, any>) {
    return (
      <StyledCell isMobile={isMobileView}>
        {params.value}
      </StyledCell>
    );
  }

  function styledHeader(params: GridColumnHeaderParams<any, any, any>) {
    return (
      <StyledCell isMobile={isMobileView}>
        {params.colDef.headerName}
      </StyledCell>
    );
  }

  const columns: GridColDef[] = [
    {
      field: 'no',
      headerName: 'No',
      renderCell: (params) => styledCell(params),
      renderHeader: (params) => styledHeader(params)
    },
    {
      field: 'attendanceId',
      headerName: 'Attendance Id',
      renderCell: (params) => styledCell(params),
      renderHeader: (params) => styledHeader(params)
    },
    {
      field: 'siblingNodeName',
      headerName: 'Sibling Node Name',
      renderCell: (params) => styledCell(params),
      renderHeader: (params) => styledHeader(params)
    },
    {
      field: 'checkInDateTime',
      headerName: 'CheckIn DateTime',
      renderCell: (params: GridRenderCellParams) => {
        return <Grid>
          <StyledCell isMobile={isMobileView}>
            {formatDate(params.row.checkInDateTime)}
          </StyledCell>
        </Grid>;
      },
      renderHeader: (params) => styledHeader(params)
    },
    {
      field: 'checkOutDateTime',
      headerName: 'CheckOut DateTime',
      renderCell: (params: GridRenderCellParams) => {
        return <Grid>
          <StyledCell isMobile={isMobileView}>
            {formatDate(params.row.checkOutDateTime)}
          </StyledCell>
        </Grid>;
      },
      renderHeader: (params) => styledHeader(params)
    },
    {
      field: 'onSiteTime',
      headerName: 'On-Site Time',
      renderCell: (params) => styledCell(params),
      renderHeader: (params) => styledHeader(params)
    },
  ];

  return (
    <Accordian title="Attendance Details" defaultExpanded={true}>
      <GridContainer>
        <CustomDataGrid
          columns={columns}
          rows={attendanceData}
          density={isMobileView ? GridDensityTypes.Compact : GridDensityTypes.Standard}
          getRowId={(row) => row?.no}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          isMobile={false}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
          getRowClassName={(params: GridRowClassNameParams) => `super-app-theme--${params.row.logTypeCode}`}
          getRowHeight={() => 'auto'}
          autoHeight={true}
          exportFileName={''}
        />
      </GridContainer>
    </Accordian>
  );
};