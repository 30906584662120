import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Div = styled('div')`
  width: 300px;
`;

export const HorizontalDevider = styled(Divider)`
  border-bottom-width: 3px;
  border-style: dashed;
`;

export const StyledContainer = styled('div')`
  width: 100%;
`;

export const PartImageBox = styled('img')`
  width: 80px;
  height: 80px;
`;

export const ExceptionPartWrapper = styled(Stack)`
  boader: 1,
  borderColor: 'secondary.main'
`;