import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import { Stack } from '@mui/material';

export const MainBox = styled(Box)`
  border: 1.5px solid #e1e1e1;
  width: 100%;
  height: 150px;
  padding-left: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  overflow: auto;
`;

export const StyledStack = styled(Stack)`
  height: auto;
  width: auto;
`;