import React, { useState, useEffect } from 'react';
import { DropdownItem } from '../../../../../components/atoms/SelectMenu';
import { useGetSoftwareFirmwareHardwareOptions } from '../../../../../queries/part-type-query';
import { SKUPropertySelectionItemRowFragmentProps } from './SKUPropertySelectionItemRowFragment.props';
import { SKUPropertySelectionItemRow } from '../../../../../components/organisms/SKUPropertySelectionItemRow';
import { useSearchPartTypesByClientAndContractForSKU } from '../../../../../queries/live-search-query';
import { useGetNodeAttributesByCustomRangeForReplenishment } from '../../../../../queries/structure-query';
import { NodeAttributeType } from '../../../../../configs/enums';
import { SKUConfigData } from '../../../../../@types/sku.type';
import { SAMPLE_SKU_CONFIG_DATA } from '../../../../../constants/sku';
import { getSFHwareDropdownItems } from '../../../../../utils/sku';
import { ANY_KEYWORD } from '../../../../../constants/common';

export const SKUPropertySelectionItemRowFragment: React.FC<SKUPropertySelectionItemRowFragmentProps> = ({
  isNew,
  value,
  nodeId,
  isSerialized,
  onChange,
  onCancel
}) => {
  const [nodeAttributeTypes] = useState<NodeAttributeType[]>([NodeAttributeType.FleetTag]);
  const [configData, setConfigData] = useState<SKUConfigData>(SAMPLE_SKU_CONFIG_DATA);

  const searchClientContractPartTypes = useSearchPartTypesByClientAndContractForSKU(isSerialized);
  const getSoftwareFirmwareHardwareOptions = useGetSoftwareFirmwareHardwareOptions(value.partTypeId);
  const getAttributesInNodeQuery = useGetNodeAttributesByCustomRangeForReplenishment(nodeId.toString() || '', nodeAttributeTypes);

  useEffect(() => {
    value.partTypeId && getSoftwareFirmwareHardwareOptions.refetch();
  }, [value.partTypeId]);

  useEffect(() => {
    if (isNew) {
      onChange({
        ...value,
        softwareVersions: [ANY_KEYWORD],
        firmwareVersions: [ANY_KEYWORD],
        hardwareRevisionVersions: [ANY_KEYWORD],
        fleetTags: [ANY_KEYWORD]
      });
    }
  }, [isNew]);

  useEffect(() => {
    if (getSoftwareFirmwareHardwareOptions.data) {
      const softwaresOptions = getSFHwareDropdownItems(getSoftwareFirmwareHardwareOptions.data.softwares);
      const firmwareOptions = getSFHwareDropdownItems(getSoftwareFirmwareHardwareOptions.data.firmwares);
      const hardwareOptions = getSFHwareDropdownItems(getSoftwareFirmwareHardwareOptions.data.hardwares);

      setConfigData({ 
        ...configData,
        softwareVersionOptions: softwaresOptions,
        firmwareVersionOptions: firmwareOptions,
        hardwareRevisionOptions: hardwareOptions 
      });
    }
  }, [getSoftwareFirmwareHardwareOptions.data]);

  useEffect(() => {
    if (getAttributesInNodeQuery.data) { 
      const fleetTags = getAttributesInNodeQuery.data.reduce((result: DropdownItem[], item: any) => {
        if (item.propertyTypeCode === NodeAttributeType.FleetTag) {
          const option: DropdownItem = { label: item.value.fleetTagName, value: item.id.toString() };
          result.push(option);
        }
        return result;
      }, []);
      fleetTags.length > 0 && setConfigData({ ...configData, fleetTagOptions: fleetTags });
    }
  }, [getAttributesInNodeQuery.data]);

  return (
    <SKUPropertySelectionItemRow 
      isNew={isNew}
      value={value}
      isSerialized={isSerialized}
      configData={configData}
      onChange={onChange}
      onPartTypeSearchApiInvoke={async (partTypeName) => {
        const response = await searchClientContractPartTypes.mutateAsync({
          name: partTypeName,
          applicableNodeIds: `${nodeId}`
        });
        return response;
      }}
      onCancel={onCancel}
    />
  );
};