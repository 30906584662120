import { ReactNode, ReactElement } from 'react';
import { ColorProps, SizeProps } from '../../../@types/common.type';

export enum ChipVariantProps {
  Filled = 'filled',
  Outlined = 'outlined',
}

export interface ChipProps {
  label: ReactNode;
  variant?: ChipVariantProps;
  color?: ColorProps;
  size?: SizeProps;
  borderColor?: string;
  backgroundColor?: string;
  textColor?: string;
  icon?: ReactElement;
  onClick?: () => void;
}