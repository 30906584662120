import { Snackbar as MaterialSnackbar } from '@mui/material';
import React from 'react';
import { SnackbarProps } from './Snackbar.props';

export const Snackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  action,
  autoHideDuration,
  anchorOrigin,
  onClose,
  children
}) => {
  return (
    <MaterialSnackbar
      open={open}
      message={message}
      action={action}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
    >
      {children}
    </MaterialSnackbar>
  );
};