import React, { useState } from 'react';
import { Slide } from '@mui/material';
import Draggable from 'react-draggable';
import { ContainerGrid, IconButtonWrapper, DraggableDiv } from './DispatchShipmentFragment.styles';
import { DispatchShipmentFragmentProps } from './DispatchShipmentFragment.props';
import { Dispatch } from '../../../../Shipments/Dispatch';
import { CancelOutlined } from '@mui/icons-material';
import { COLORS } from '../../../../../configs/colors';
import { ButtonSizeProps } from '../../../../../components/atoms/Button';

export const DispatchShipmentFragment: React.FC<DispatchShipmentFragmentProps> = ({
  data,
  dispatchData,
  onDispatch
}) => {
  const [grabbing, setGrabbing] = useState(false);

  return (
    <Draggable
      handle=".drag-handle"
      cancel=".no-drag"
      onStart={() => setGrabbing(true)}
      onStop={() => setGrabbing(false)}
    >
      <Slide
        direction="left"
        in={dispatchData.openFragment}
        timeout={1000}
        mountOnEnter
        unmountOnExit
        onExited={() => onDispatch({ ...dispatchData, exited: true })}
      >
        <ContainerGrid className="no-drag">
          <DraggableDiv className="drag-handle" grabbing={grabbing}>
            Click to drag
          </DraggableDiv>
          <IconButtonWrapper
            aria-label="close"
            onClick={() => onDispatch({ openFragment: false, serviceId: 0, mainLocation: '', mainLocationNodeId: 0, isFromServiceEdit: true })}
          >
            <CancelOutlined fontSize={ButtonSizeProps.Medium} htmlColor={COLORS.Orange} />
          </IconButtonWrapper>
          <Dispatch
            dispatchData={{ 
              ...dispatchData,
              subLocationNodeId: data.subLocationNodeId,
              primaryAllocationNodeId: data.primaryAllocation?.node?.id || 0,
              secondaryAllocationNodeId: data.secondaryAllocation?.node?.id || 0
            }}
            onDispatch={onDispatch}
          />
        </ContainerGrid>
      </Slide>
    </Draggable>
  );
};