import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocationNodeData } from '../../../@types/live-search.type';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { LocationSearchSelectProps } from './LocationSearchSelect.props';

export const LocationSearchSelect: React.FC<LocationSearchSelectProps> = ({
  title,
  options,
  value,
  onChange,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, [options]);

  const handleChange = (event: SelectChangeEvent) => {
    const val = event.target.value as string;
    if (val) {
      setIsOpen(false);
      const selectedOption = options?.find((op: LocationNodeData) => op.name === val);
      selectedOption && onChange && onChange(selectedOption);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="location-search-select-label">{title}</InputLabel>
      <Select
        labelId="location-search-select-label"
        id="location-search-select"
        open={isOpen}
        value={value?.name}
        label={title}
        onChange={handleChange}
        onClose={handleClose}
      >
        {options?.map((op: LocationNodeData) => {
          return (
            <MenuItem key={op.id} value={op.name}>
              <LocationSearchItem data={op} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
