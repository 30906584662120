import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { QueryOption } from '../@types';
import { getAllPartTypes } from '../api/partTypes';
import { PartTypeList, CSVExportPartType } from '../@types/part-type.type';
import {
  createChildPartType,
  createPartType,
  createRepairPricing,
  createSalePricing,
  createSFHware,
  createSupplier,
  createWarranty,
  deleteChildPartType,
  getAllConfigurationHistory,
  getPartTypeById,
  getPartTypeManifestById,
  getPartTypesByClientContract,
  getPartTypeSFHwaresDetails,
  updateChildPartType,
  updatePartType,
  updateRepairPricing,
  updateSalePricing,
  updateSFHware,
  updateSupplier,
  updateWarranty,
  getPartTypeShipmentDataByPartTypeIdAndShipmentStatus,
  getSearchTLCPartTypes,
  getSearchTLCPartTypeLocation,
  getPartTypeByIdForService
} from '../api/partType';
import { PartType, SoftwareFirmwareHardwareOptions, PendingTransfersList, PartTypeLiveSearchItem } from '../@types/partType.type';
import { SerialPopupObject } from '../@types/shipment.type';
import { PartTypeManifestKey } from '../configs/part-type';
import { ChildPartTypesKey, ConfigurationHistoriesKey, PartTypesByClientContractKey, PartTypesKey, PartTypesSoftwareFirmwareHardwareOptionsKey, PartTypePendingTransfersKey } from '../configs/part-type';
import { ShipmentManifestSuppliersKey } from '../configs/shipment';

export interface PartTypeListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  limit?: number;
  data?: [PartTypeList];
  filterResponse?: [CSVExportPartType];
}

export interface ConfigurationHistoryListResponse {
  id: number;
  partTypeId: number;
  createdBy: string;
  createdAt: string;
  notes: string;
  configurationChangeNotes: string;
}

export const useGetAllPartTypes = (queryOption: QueryOption): UseQueryResult<PartTypeListResponse> => {
  return useQuery([PartTypesKey, queryOption], () => getAllPartTypes(queryOption), { cacheTime: 0 });
};

export const useGetOnePartType = (id: number): UseQueryResult<PartType> => {
  return useQuery([PartTypesKey, id], () => id && getPartTypeById(id));
};

export const useGetOnePartTypeOnClose = (): UseMutationResult<PartType> => {
  return useMutation(async (data: any) => {
    return await getPartTypeById(data);
  });
};

export const useGetOnePartTypeForService = (id: number): UseQueryResult<PartType> => {
  return useQuery([PartTypesKey, id], () => id && getPartTypeByIdForService(id));
};

export const useGetAllConfigurationHistory = (id: number): UseQueryResult<[ConfigurationHistoryListResponse]> => {
  return useQuery([ConfigurationHistoriesKey, id], () => id && getAllConfigurationHistory(id));
};

export const useCreatePartType = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createPartType(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartTypesKey]);
    },
  });
};

export const useUpdatePartType = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updatePartType(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.id]);
    },
  });
};

export const useCreateWarranty = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createWarranty(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useUpdateWarranty = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateWarranty(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useCreateSupplier = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createSupplier(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
      await queryClient.invalidateQueries([ShipmentManifestSuppliersKey, response.partTypeId]);
    },
  });
};

export const useUpdateSupplier = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateSupplier(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
      await queryClient.invalidateQueries([ShipmentManifestSuppliersKey, response.partTypeId]);
    },
  });
};

export const useCreateSFHware = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createSFHware(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useUpdateSFHware = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateSFHware(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useCreateSalePricing = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await createSalePricing(data.partTypeId, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};
  
export const useUpdateSalePricing = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await updateSalePricing(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useCreateRepairPricing = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await createRepairPricing(data.partTypeId, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};
  
export const useUpdateRepairPricing = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await updateRepairPricing(data.id, data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, response.partTypeId]);
    },
  });
};

export const useCreateChildPartType = (partTypeId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await createChildPartType(partTypeId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, partTypeId]);
    },
  });
};
  
export const useUpdateChildPartType = (partTypeId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await updateChildPartType(partTypeId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, partTypeId]);
    },
  });
};

export const useDeleteChildPartType = (partTypeId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await deleteChildPartType(partTypeId, data.childPartTypeId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ChildPartTypesKey, partTypeId]);
    },
  });
};

export const useGetPartTypesByClientContract = (clientId: number, contractId?: number): UseQueryResult<PartTypeLiveSearchItem[]> => {
  return useQuery([PartTypesByClientContractKey, clientId, contractId || 0], 
    () => getPartTypesByClientContract(clientId, contractId), 
    { 
      enabled: false 
    }
  );
};

export const useGetSoftwareFirmwareHardwareOptions = (partTypeId: number): UseQueryResult<SoftwareFirmwareHardwareOptions> => {
  return useQuery([PartTypesSoftwareFirmwareHardwareOptionsKey, partTypeId], 
    () => getPartTypeSFHwaresDetails(partTypeId), 
    { 
      enabled: false 
    }
  );
};

export const useGetPartTypeManifestById = (id: number): UseQueryResult<SerialPopupObject> => {
  return useQuery([PartTypeManifestKey], () => id > 0 && getPartTypeManifestById(id), { enabled: false });
};

export const useGetPartTypeShipmentDataByPartTypeIdAndShipmentStatus = (partTypeId: number, isSerialized: boolean, shipmentStatusCodes: string): UseQueryResult<PendingTransfersList[]> => {
  return useQuery([PartTypePendingTransfersKey, partTypeId], () => partTypeId && getPartTypeShipmentDataByPartTypeIdAndShipmentStatus(partTypeId, isSerialized, shipmentStatusCodes));
};

export const useGetSearchTLCPartTypes = (): UseMutationResult<Array<PartType>> => {
  return useMutation(async (data: any) => {
    return await getSearchTLCPartTypes(data);
  });
};

export const useGetSearchTLCPartTypeLocation = (): UseMutationResult<Array<Node>> => {
  return useMutation(async (data: any) => {
    return await getSearchTLCPartTypeLocation(data);
  });
};