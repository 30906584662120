import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';
import { Grid } from '@mui/material';
import { Box } from '../../atoms/Box';

export const RoleTypography = styled(Typography)`
  padding-bottom: 20px;
`;

export const RoleBox = styled(Box)`
  width: 100%;
  border: 0px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding-top: 2px;
`;

export const ContactGridContainer = styled(Grid)`
  margin-bottom: 10px;
`;
