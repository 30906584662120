import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ImportStructureTypography, ImportStructuresSection } from './ImportStructure.styles';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { UploadButton } from '../../../components/molecules/UploadButton';
import { ImportStructureTable } from './ImportStructureTable';
import { downloadFile } from '../../../utils/file-utils';
import { useGetUploadedCsvFile } from '../../../queries/uploadedfiles-query';
import { IMPORT_FILE_MIME_TYPES, REACT_APP_STRUCTURE_IMPORT_EXCEL_KEY } from '../../../configs/envs';
import { UploadedCsvFile } from '../../../@types/uploaded-files.type';
import { useUploadResource } from '../../../queries/import-query';
import { ImportResourceResponse } from '../../../@types';
import { UploadResourceType, ImportResourceTypeCode } from '../../../configs/enums';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { ImportData } from './ImportStructureTable.props';
import { STRUCTURE_IMPORT_DATA } from '../../../constants/structure';
import { setImportSessionData, getImportSessionData, clearImportSessionData } from '../../../utils/imports';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const ImportStructure: React.FC = () => {
  window.addEventListener('beforeunload', function() {
    clearImportSessionData(ImportResourceTypeCode.Node);
  });

  const importSessionData = getImportSessionData(ImportResourceTypeCode.Node);

  const [fileID, setFileID] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedCsvFile>();
  const [importData, setImportData] = useState<ImportData>(STRUCTURE_IMPORT_DATA);
  
  const uploadedFilesQuery = useGetUploadedCsvFile(REACT_APP_STRUCTURE_IMPORT_EXCEL_KEY);
  const uploadResourceQuery = useUploadResource();

  TabHandler(PLATFORM_NAME);

  useEffect(() => {
    !importSessionData?.isImportComplete ? 
      setImportData(prev => ({
        ...prev,
        fileName: importSessionData?.fileName || '',
        fileSize: importSessionData?.fileSize || 0
      }))
      : 
      clearImportSessionData(ImportResourceTypeCode.Node);
    uploadedFilesQuery.data && setUploadedFiles(uploadedFilesQuery.data);
  }, [uploadedFiles]);

  const handleClick = async ()=> {
    const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
    if(refetchUploadedFilesQuery.data){
      setUploadedFiles(refetchUploadedFilesQuery.data);
      downloadFile(refetchUploadedFilesQuery.data?.url , 'structure-import-template');
    } 
  };

  const handleUpload = async (fileList: FileList)=> {
    setFileID(0);
    const uploadedFile: ImportResourceResponse = await uploadResourceQuery.mutateAsync({
      type: UploadResourceType.Structures,
      file: fileList[0]
    }) as ImportResourceResponse;
    setFileID(uploadedFile.id);
  };

  const onChangeFileData = async (value: any)=> {
    setImportData(prev => ({ ...prev, fileName: value.fileName, fileSize: value.fileSize }));
    setImportSessionData(
      ImportResourceTypeCode.Node,
      {
        fileName: value.fileName,
        fileSize: value.fileSize
      }
    );
  };

  return (
    <PageContainer>
      <ImportStructureTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        Import Structure
      </ImportStructureTypography>
      <Grid xs={9} mb={2} mt={2}>
        <Button variant={ButtonVariantProps.Text} onClick={handleClick}>
          Download Excel template
        </Button>
      </Grid>
      <Grid xs={9}>
        <UploadButton
          showName
          fileTypes={IMPORT_FILE_MIME_TYPES.split(',')}
          maxFileSize={1024}
          setFileDetails={handleUpload}
          value={ { fileName: importData?.fileName, fileSize: importData?.fileSize } }
          onChangeFileData={onChangeFileData}
        />
      </Grid>
      <ImportStructuresSection>
        <ImportStructureTable fileId={fileID}/>
      </ImportStructuresSection>
    </PageContainer>
  );
};