import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../../../configs/colors';
import { StyledDivProps } from './DispatchShipmentFragment.props';

export const ContainerGrid = styled(Grid)({
  position: 'relative',
  backgroundColor: COLORS.DarkGray,
  zIndex: 5,
  borderRadius: 7
});

export const IconButtonWrapper = styled(IconButton)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '30px',
  height: '30px'
});

export const DraggableDiv = styled('div')<StyledDivProps>`
  height: 30px;
  color: white;
  text-align: center;
  cursor: ${(props) => (props.grabbing ? 'grabbing' : 'grab')};
`;