import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Button,
  ButtonColorProps,
  ButtonSizeProps,
  ButtonVariantProps
} from '../../atoms/Button';
import { EditDateTimePickerProps } from './EditDateTimePicker.props';
import { 
  StyledTypography,
  StyledDateTimePicker
} from './EditDateTimePicker.styles';

export const EditDateTimePicker: React.FC<EditDateTimePickerProps> = ({
  isEditable,
  disableFuture = false,
  disablePast = false,
  textColor = '',
  textFontSize = '',
  value,
  onChange
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (value !== textValue) {
      setTextValue(value);
    }
  }, [value]);

  const handleOnSubmit = () => {
    onChange(textValue);
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setTextValue(value);
    setIsEdit(false);
    setIsShown(false);
  };

  return (
    <Grid
      container 
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
      alignItems="center"
    >
      <Grid item>
        {isEdit ?
          <StyledDateTimePicker
            label=""
            isCompact={true}
            disableFuture={disableFuture}
            disablePast={disablePast}
            value={textValue} 
            onChange={(val) => setTextValue(val)}
          />
          :
          <StyledTypography
            color={textColor}
            fontSize={textFontSize}
          >
            {value}
          </StyledTypography>
        }
      </Grid>
      {isEdit &&
        <Grid item>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </Grid>
      }
      {isShown && !isEdit &&
        <Button
          variant={ButtonVariantProps.Icon}
          size={ButtonSizeProps.Small}
          onClick={() => setIsEdit(true)}
        >
          <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
        </Button>
      }
    </Grid>
  );
};
