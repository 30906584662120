import React from 'react';
import { LinkProps, UnderlineTypeProps } from './Link.props';
import { LinkStyled } from './Link.styles';

export const Link:React.FC<LinkProps> = ({
  children,
  color,
  underline = UnderlineTypeProps.Always,
  href,
  target,
  onClick,
}) => {
  return (
    <LinkStyled
      color={color}
      underline={underline}
      target={target}
      href={`/${href?.replace(/^\//, '')}`}
      onClick={(e) => { 
        if(onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      onMouseDown={(event) => {
        if (event.button === 0 && (event.ctrlKey || event.metaKey)) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    >
      {children}
    </LinkStyled>
  );
};