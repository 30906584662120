import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridFeatureModeConstant, GridRenderCellParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionPermissions, ColorProps, CustomPermission, ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse } from '../../../@types';
import { ServiceTypeResponse } from '../../../@types/service.type';
import { Chip } from '../../../components/atoms/Chip';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { useGetAllServiceTypes } from '../../../queries/service-type-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { GridContainer } from '../../../styled/common.styles';
import { Link } from '../../../components/atoms/Link';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { SavedReportTypeList } from '../../../configs/enums';
import { generateReportURL } from '../../../utils/report';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { PLATFORM_NAME } from '../../../configs/common';

export const ServiceTypeList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const reportCode = queryParams.get('savedReportCode');

  const [reportViewCode, setReportViewCode] = useState<string>();
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [reportName, setReportName] = useState('');
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeResponse[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const { data, refetch: refetchGetAllServiceTypes, isLoading } = useGetAllServiceTypes(sortModel);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.ServiceTypeList);

  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    setColumns([
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Configure_Service_Types_Edit, permissions) ?
            <Link href={`/configure/services/types/${params.row.code}`}>{params.row.name}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
        width: 200
      },
      {
        field: 'code',
        headerName: 'Short Name',
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Configure_Service_Types_Edit, permissions) ?
            <Link href={`/configure/services/types/${params.row.code}`}>{params.row.code}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.code,
        width: 200
      },
      {
        field: 'isCheckInAvailable',
        headerName: 'CheckIn Available',
        type: 'boolean',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.isCheckInAvailable ? 
          <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isCheckInAvailable
      },
      {
        field: 'targetOptions',
        headerName: 'Service Target Options',
        valueGetter: (params: GridValueGetterParams) => 
          params.row.targetOptions?.map((obj: any) => obj.serviceTarget.name).join(', '),
        width: 700
      },
      {
        field: 'defaultServiceTarget',
        headerName: 'Default Service Target',
        valueGetter: (params: GridValueGetterParams) => 
          params.row.targetOptions?.find((obj: any) => obj.isDefault)?.serviceTarget.name,
        width: 250
      },
      {
        field: 'statusOptions',
        headerName: 'Service Status',
        valueGetter: (params: GridValueGetterParams) => 
          params.row.statusOptions?.map((obj: any) => obj.serviceStatus.name).join(', '),
        width: 250
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        width: 100,
        renderCell: (params: GridRenderCellParams) => params.row.isActive ? 
          <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isActive
      }
    ]); 
  }, [permissions]);
 
  useEffect(() => {
    !reportCode && defaultReportData.refetch(); 
  }, [SavedReportTypeList.ServiceTypeList]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Services Types | ${PLATFORM_NAME}`;

      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }   
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: { items: [] },
        pageSize: 25,
        sortModel: sortModel || []
      },
      reportTypeName: SavedReportTypeList.ServiceTypeList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [reportName, isDefault, sortModel]);

  useEffect(() => {
    data && setServiceTypes(data);
  }, [data, isDefault]);

  useEffect(() => {
    refetchGetAllServiceTypes();
  }, [sortModel]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true); 
    navigate(reportURL, { replace: true });
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'ServiceType',
      value: [
        {
          title: 'Name',
          field: 'name',
          value: true
        },
        {
          title: 'Short Name',
          field: 'code',
          value: true
        },
        {
          title: 'CheckIn Available',
          field: 'isCheckInAvailable',
          value: false
        },
        {
          title: 'Active',
          field: 'isActive',
          value: true
        }
      ]
    },
    {
      title: 'Target Options',
      value: [
        {
          title: 'Service Target Options',
          field: 'targetOptions',
          value: true
        },
        {
          title: 'Default Service Target',
          field: 'defaultServiceTarget',
          value: true
        }
      ]
    },
    {
      title: 'Status Options',
      value: [
        {
          title: 'Service Status',
          field: 'statusOptions',
          value: false
        }
      ]
    }
  ]; 

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={serviceTypes}
        columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
        exportFileName={ListType.ServiceTypeList}
        enableCSVExport={isUserHasPermission(ActionPermissions.Configure_Service_Types_Export, permissions)}
        getRowId={(row) => row?.code}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true
          }
        }} 
        newReportData={newReportData} 
        handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
        handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
        enableSavedReport={true}
        reportCode={reportViewCode}
        handleColumnsChange={handleColumnsChange}
        handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
        getRowHeight={() => 'auto'}
        sortModel={sortModel}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        loading={isLoading}
      />
      <Grid>
        <Snackbar  
          open={openToast} 
          autoHideDuration={4000} 
          message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
          onClose={()=> setOpenToast(false)}
        />
      </Grid>
    </GridContainer>
  );
};