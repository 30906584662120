import React from 'react';
import { Grid } from '@mui/material';
import {
  RoleBox,
  ContactGridContainer,
} from './Contact.styles';
import { TextField } from '../../atoms/TextField';
import { SelectMenu } from '../../atoms/SelectMenu';
import { ContactProps } from './Contact.props';
import { EmailTextField } from '../../molecules/EmailTextField';
import { GRID_SPACING } from '../../../configs/ui-constants';

export const Contact: React.FC<ContactProps> = ({
  contactType,
  onChange,
  error,
  contactData,
  renderNotes = true,
  disableTypeSelection = false,
  hideContactType
}) => {
  return (
    <RoleBox>
      <ContactGridContainer container spacing={GRID_SPACING}>
        {!hideContactType &&
          <Grid item xs={4}>
            <SelectMenu
              id="page-size-menu"
              minWidth="100%"
              items={contactType || []}
              selectedValue={contactData.contactType}
              disabled={disableTypeSelection}
              onChange={(text) => {
                onChange({
                  ...contactData,
                  contactType: text
                });
              }}
            />
          </Grid>
        }
        <Grid item xs={4}>
          <TextField
            fullWidth
            error={error}
            label="Name"
            value={contactData.contactName}
            onChange={(text) => onChange({
              ...contactData,
              contactName: text
            })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            error={error}
            label="Organisation"
            value={contactData?.organization}
            onChange={(text) => onChange({
              ...contactData,
              organization: text
            })}
          />
        </Grid>
      </ContactGridContainer>
      <ContactGridContainer container spacing={GRID_SPACING}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            error={error}
            label="Phone 1"
            value={contactData?.phone1}
            onChange={(text) => onChange({
              ...contactData,
              phone1: text
            })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            error={error}
            label="Phone 2"
            value={contactData?.phone2}
            onChange={(text) => onChange({
              ...contactData,
              phone2: text
            })}
          />
        </Grid>
        <Grid item xs={4}>
          <EmailTextField
            fullWidth
            value={contactData?.email}
            onChange={(text) => onChange({
              ...contactData,
              email: text
            })}
          />
        </Grid>
      </ContactGridContainer>
      {renderNotes && <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Notes"
            multiline
            rows={4}
            value={contactData?.notes}
            onChange={(text) => onChange({
              ...contactData,
              notes: text
            })}
          />
        </Grid>
      </Grid>}
    </RoleBox>
  );
};
