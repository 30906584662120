import { GridValueGetterParams } from '@mui/x-data-grid-pro';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection/ColumnSelection.props';
import { CheckItemProps } from '../components/organisms/CheckItemGroup/CheckItemGroup.props';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { ImportDataGridColumns, ServiceStatusResponse } from '../@types';
import { ImportDataGridInvisibleColumnList } from '../configs/enums';
import { ANY_ITEM_ARRAY, ANY_KEYWORD } from '../constants/common';

export const sortBy = <T, V>(
  values: Array<T> | IterableIterator<T>,
  valueFunc: (val: T) => V,
  direction: 'desc' | 'asc' = 'desc',
) => {
  return [...values].sort((v1, v2) => {
    const a = valueFunc(v1);
    const b = valueFunc(v2);

    if (a > b) {
      return direction === 'desc' ? -1 : 1;
    } else if (a < b) {
      return direction === 'desc' ? 1 : -1;
    } else {
      return 0;
    }
  });
};

const flatten = (children: any) => Array.prototype.concat.apply(
  children, 
  children.map((x: any) => flatten(x.children || []))
);

export const getFlatArray = (tree: any[]) => {
  return flatten(tree);
};

export const getColumnVisibility = (columnSelectionData: Array<ColumnSelectionItem>) => {
  const columnData = columnSelectionData.reduce((acc1: any, obj1: ColumnSelectionItem) => {
    const items = obj1.value.reduce((acc2: any, obj2: CheckItemProps) => {
      return {
        ...acc2,
        [obj2.field]: obj2.value,
      };
    }, {});
    return { ...acc1, ...items };
  }, {});
  return columnData;
};

export const getImportDataGridDynamicColumnNames = (columnFields: any) => { // Data fields change dynamically, data types not fixed
  // Get column selection values
  const columnSelectionValues: CheckItemProps[] = [];
  Object.entries(columnFields.data).forEach(([columnFieldItem, ]) => {
    if (columnFieldItem !== ImportDataGridInvisibleColumnList.Line &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.NodeObject &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.SubNodeObject &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.ServiceObject) {
      const object = {
        title: columnFieldItem,
        field: columnFieldItem,
        value : true
      };
      columnSelectionValues.push(object);
    }
  });
  columnSelectionValues.splice(0, 0 ,{
    title: 'Line',
    field: 'Line',
    value : true
  });
  columnSelectionValues.splice(1, 0 ,{
    title: 'Errors',
    field: 'Errors',
    value : true
  });

  // Get column names
  const columnNames: ImportDataGridColumns[] = [];
  Object.entries(columnFields.data).forEach(([columnFieldItem, ]) => {
    if (columnFieldItem !== ImportDataGridInvisibleColumnList.Line &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.NodeObject &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.SubNodeObject &&
      columnFieldItem !== ImportDataGridInvisibleColumnList.ServiceObject) {
      const object = {
        field: columnFieldItem,
        headerName: columnFieldItem,
        valueGetter: (params: GridValueGetterParams) => params.row.data[columnFieldItem],
        width: columnFieldItem === 'Reported Notes' ? 500 : 200
      };
      columnNames.push(object);
    }
  });
  columnNames.splice(0, 0 ,{
    field: 'Line',
    headerName: 'Line',
    headerAlign: 'center',
    align: 'center',
    valueGetter : (params: GridValueGetterParams) => params.row['fileLineNo'],
    width: 100
  });
  columnNames.splice(1, 0 ,{
    field: 'Errors',
    headerName: 'Errors',
    valueGetter : (params: GridValueGetterParams) => params.row['errorMessage'],
    width: 300
  });

  return ({ columnSelectionValues, columnNames });
};

export const hasColumnListArrayLength = (columnSelectionValuesLength: number, columnNamesLength: number) =>{
  if (columnSelectionValuesLength && columnNamesLength) {
    return true;
  } else {
    return false;
  }
};

export const isNotArrayEmpty = (targetArray: any[], length: number) => {
  return targetArray.length > length;
};

export const getDropdownItemsWithAnyWildCard = (data: any, selectedValues?: string[]) => {
  if (selectedValues && selectedValues.some((selectedValue: string) => selectedValue === ANY_KEYWORD)) {
    return ANY_ITEM_ARRAY;
  }

  const dropdownItems: DropdownItem[] = [];
  const anyWildCard = ANY_ITEM_ARRAY[0];
  dropdownItems.push(anyWildCard);
  
  data?.map((item: ServiceStatusResponse) => {
    const itemValue = {
      value: item.code,
      label: item.name,
      disabled: item?.isActive ? !item.isActive : false
    };
    dropdownItems.push(itemValue);
  });
  return dropdownItems;
};

export const getDataGridListColumnNames = (data: any, params: GridValueGetterParams, fieldName: string) => {
  return (fieldName === 'event' ? data :getDropdownItemsWithAnyWildCard(data))?.reduce((accumulator: string[], currentValue: DropdownItem) => {
    if (params.row[fieldName].includes(currentValue.value)) {
      return [...accumulator, currentValue.label];
    }
    return accumulator;
  }, []).join(', ');
};

export const dropdownOptionArrayFiller = (inputDataArray: Array<any>) =>{
  return inputDataArray.map((obj) => {
    const option: DropdownItem = { label: obj.label, value: obj.value.toString() };
    return option;
  });
};