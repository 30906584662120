import React from 'react';
import { Grid } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { FaultSelectionsFormProps } from './FaultSelectionsForm.props';
import { StyledSelectMenu } from './FaultSelectionsForm.styles';

export const FaultSelectionsForm: React.FC<FaultSelectionsFormProps> = ({
  options,
  value,
  onChange,
  isError
}) => {
  return (
    <Grid container spacing={1} marginTop={1} marginBottom={1}>
      <Grid item xs={12}>
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
          Fault Selections
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledSelectMenu
          id="fault-selections-form-fault-menu"
          label="Observed Fault"
          items={options}
          selectedValue={value || ''}
          onChange={onChange}
          validate={isError && !value}
        />
      </Grid>
    </Grid>
  );
};
