import React, { useEffect, useState } from 'react';
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Stack, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Wrapper, IconImage } from './Home.styles';
import { GridContainer } from '../../styled/common.styles';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';
import { DataGrid } from '../../components/atoms/DataGrid';
import { useGetServiceActivityMonitorData } from '../../queries/service-activity-monitor-query';
import { ColorProps, ScreenSize, ServiceActivityResponseDataType, SizeProps } from '../../@types';
import { generateServicesFilterURL, setCellStylingInServiceActivityMonitor } from '../../utils/services';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { HorizontalAlignProps, InlineGroup } from '../../components/atoms/InlineGroup';
import { Switch } from '../../components/atoms/Switch';
import { getServiceActivityMonitorTime } from '../../utils/date-time-utils';

export const Home: React.FC = () => {
  TabHandler(PLATFORM_NAME);

  const navigate = useNavigate();

  const [serviceActivityData, setServiceActivityData] = useState<ServiceActivityResponseDataType[]>([]);
  const [refreshEnabled, setRefreshEnabled] = useState(true);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(getServiceActivityMonitorTime());
  const [isMobile, setIsMobile] = useState(false);
  let lastTapTime = 0;

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const getServiceActivityMonitorData = useGetServiceActivityMonitorData(refreshEnabled);

  const columns: GridColDef[] = [
    {
      field: 'rowHeading',
      headerName: '',
      headerAlign: 'center',
      align: 'left',
      width: 200,
      renderHeader: () => {
        return <IconImage src="/images/leftMenu/Amtek_Logo_Phoenix_Single_Black@0.5x.png" />;
      },
      sortable: false,
      disableColumnMenu: true
    },
    {
      field: 'newStatus',
      headerName: 'New',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.newStatus || ''
    },
    {
      field: 'bookingStatus',
      headerName: 'Booking',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.bookingStatus || ''
    },
    {
      field: 'fieldReadyStatus',
      headerName: 'Field Ready',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.fieldReadyStatus || ''
    },
    {
      field: 'otwStatus',
      headerName: 'OTW',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.otwStatus || ''
    },
    {
      field: 'onsiteStatus',
      headerName: 'On Site',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.onsiteStatus || ''
    },
    {
      field: 'partsRequiredStatus',
      headerName: 'Parts Required',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row.partsRequiredStatus || ''
    },
    {
      field: 'partsProcessedStatus',
      headerName: 'Parts (Processed)',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      width: 170,
      valueGetter: (params: GridValueGetterParams) => params.row.partsProcessedStatus || ''
    },
    {
      field: 'logisticsStatus',
      headerName: 'Logistics',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.logisticsStatus || ''
    },
    {
      field: 'dispatchedStatus',
      headerName: 'Dispatched',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.dispatchedStatus || ''
    },
    {
      field: 'escalatedStatus',
      headerName: 'Escalated',
      headerAlign: 'center',
      headerClassName: 'bold-header-styling',
      align: 'center',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.escalatedStatus || ''
    }
  ];

  useEffect(() => {
    setIsMobile(isMobileView);
  }, [isMobileView]);

  useEffect(() => {
    getServiceActivityMonitorData.data && setServiceActivityData(getServiceActivityMonitorData.data);
    setLastUpdatedTime(getServiceActivityMonitorTime());
  }, [getServiceActivityMonitorData.data, getServiceActivityMonitorData.isRefetching]);

  const handleCellDoubleClick = (params: GridCellParams) => {
    if (params.field !== 'rowHeading') {
      const serviceListURL = generateServicesFilterURL(params.row.clientCode, params.row.serviceTypeName, params.colDef.headerName || '');
      navigate(serviceListURL, { replace: false });
    }
  };

  const handleCellClick = (params: GridCellParams) => {
    if (isMobile && params.field !== 'rowHeading') {
      const currentTime = Date.now();
      const isDoubleTap = (currentTime - lastTapTime) < 400; // Time (in ms) threshold to capture double tap in mobile view

      if (isDoubleTap) {
        const serviceListURL = generateServicesFilterURL(params.row.clientCode, params.row.serviceTypeName, params.colDef.headerName || '');
        navigate(serviceListURL, { replace: false });
      }

      lastTapTime = currentTime;
    }
  };

  return (
    <Wrapper>
      <InlineGroup horizontal={HorizontalAlignProps.Between}>
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Service Activity Monitor
        </Typography>
        <Stack direction="row" mr={3} mt={1}>
          <Typography>Refresh(every 120s)</Typography>
          <Switch
            size={SizeProps.Small}
            color={ColorProps.Success}
            checked={refreshEnabled}
            onChange={async() => {
              setRefreshEnabled(!refreshEnabled);
              if (!refreshEnabled) {
                await getServiceActivityMonitorData.refetch();
                setLastUpdatedTime(getServiceActivityMonitorTime());
              }
            }}
          />
        </Stack>
      </InlineGroup>
      <GridContainer>
        <DataGrid
          columns={columns}
          rows={serviceActivityData}
          getRowId={(row) => row?.id}
          loading={getServiceActivityMonitorData.isLoading || getServiceActivityMonitorData.isRefetching}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          getCellClassName={setCellStylingInServiceActivityMonitor}
          showCellRightBorder
          headerHeight={120}
          hideFooter
          onCellDoubleClick={handleCellDoubleClick}
          onCellClick={handleCellClick}
          getRowHeight={() => 'auto'}
        />
        <Stack textAlign="right">
          <Typography variant={TypographyVariantProps.Caption} fontStyle={'italic'}>Last Updated at {lastUpdatedTime} [AEST]</Typography>
        </Stack>
      </GridContainer>
    </Wrapper>
  );
};