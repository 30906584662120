import React, { useState } from 'react';
import { Stack, Table, TableHead, TableBody, TableRow, IconButton, Grid } from '@mui/material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { TextField,TextFieldTypeProps } from '../../atoms/TextField';
import { SelectMenu } from '../../atoms/SelectMenu';
import { ShipmentPackagesListProps, SelectedShipmentPackage, FreightPackageType } from './ShipmentPackagesList.props';
import { StyledTableCell, BoldLabel, NameLabel, RemoveButton, AddButton, ClearButton } from './ShipmentPackagesList.styles';
import { CustomShippingPackageType, FreightPackageTypeName, FreightPackageCriteria, IconButtonSize } from '../../../configs/enums';
import { getShipmentPackageCriteriaIndex, getUpdatedShipmentPackageCriteriaList } from '../../../utils/shipment';

export const ShipmentPackagesList: React.FC<ShipmentPackagesListProps> = ({
  customPackageTypes,
  freightTypes,
  onChange
}) => {

  const [selectedPackages, setSelectedPackages] = useState<Array<SelectedShipmentPackage>>([]);

  const onValueChange = (packageFeature: string, id: number, value: number) => {
    const updatedList = getUpdatedShipmentPackageCriteriaList(selectedPackages,packageFeature, id, value);
    setSelectedPackages(updatedList);
    onChange(updatedList);
  };

  const onPackageTypeChange = (id: number, value: string) => {
    const packageList = [...selectedPackages];
    const updatedRowIndex = getShipmentPackageCriteriaIndex(selectedPackages, id);
    (packageList[updatedRowIndex]).packageType = value;
    setSelectedPackages(packageList);
    onChange(packageList);
  };

  const onAddPackage = (packageData?: FreightPackageType) => {
    const packageList = [...selectedPackages];
    const packageItem = {
      id: selectedPackages.length > 0 ? Math.max(...selectedPackages.map(item => item.id)) + 1 : 1,
      typeId: packageData?.id || 0,
      name: packageData?.name || FreightPackageTypeName.Custom ,
      height: packageData?.height || 1,
      width: packageData?.width || 1,
      length: packageData?.length || 1,
      quantity: 1,
      weight: 1,
      packageType: !packageData?.name ? CustomShippingPackageType.Carton : '',
    };
    packageList.push(packageItem);
    setSelectedPackages(packageList);
    onChange(packageList);
  };

  const onClear = () => {
    setSelectedPackages([]);
    onChange([]);
  };

  return (
    <Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left"><BoldLabel>Type</BoldLabel></StyledTableCell>
            <StyledTableCell align="left"><BoldLabel>Length (cm)</BoldLabel></StyledTableCell>
            <StyledTableCell align="left"><BoldLabel>Width (cm)</BoldLabel></StyledTableCell>
            <StyledTableCell align="left"><BoldLabel>Height (cm)</BoldLabel></StyledTableCell>
            <StyledTableCell align="center"><BoldLabel>Qty</BoldLabel></StyledTableCell>
            <StyledTableCell align="left"><BoldLabel>Weight (kg)</BoldLabel></StyledTableCell>
            <StyledTableCell align="left"><BoldLabel>Packaging Type</BoldLabel></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPackages.map((row, index) => {
            return (
              <TableRow key={row.id}>
                <StyledTableCell>
                  <NameLabel>{row.name}</NameLabel>
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    disabled={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].name !== FreightPackageTypeName.Custom}
                    fullWidth
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 1 }}
                    value={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].length}
                    onChange={(value) => onValueChange(FreightPackageCriteria.Length, row.id, parseInt(value))}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    disabled={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].name !== FreightPackageTypeName.Custom}
                    fullWidth
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 1 }}
                    value={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].width}
                    onChange={(value) => onValueChange(FreightPackageCriteria.Width, row.id, parseInt(value))}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    disabled={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].name !== FreightPackageTypeName.Custom}
                    fullWidth
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 1 }}
                    value={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].height}
                    onChange={(value) => onValueChange(FreightPackageCriteria.Height, row.id, parseInt(value))}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" alignItems="center">
                    <IconButton
                      onClick={() => {
                        const packagesList = [...selectedPackages];
                        packagesList[index].quantity !== 1 && packagesList[index].quantity--;
                        setSelectedPackages(packagesList);
                        onChange(packagesList);
                      }}
                      size={IconButtonSize.Medium}
                    >
                      <RemoveButton/>
                    </IconButton>
                    <TextField
                      fullWidth
                      type={TextFieldTypeProps.Number}
                      inputProps={{ min: 1 }}
                      value={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].quantity}
                      onChange={(value) => onValueChange(FreightPackageCriteria.Quantity, row.id, parseInt(value))}
                    />
                    <IconButton
                      onClick={() => {
                        const packagesList = [...selectedPackages];
                        packagesList[index].quantity++;
                        setSelectedPackages(packagesList);
                        onChange(packagesList);
                      }}
                      size={IconButtonSize.Medium}
                    >
                      <AddButton/>
                    </IconButton>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <TextField
                    fullWidth
                    type={TextFieldTypeProps.Number}
                    inputProps={{ min: 1 }}
                    value={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].weight}
                    onChange={(value) => onValueChange(FreightPackageCriteria.Weight, row.id, parseInt(value))}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <SelectMenu
                    id="package-type-select"
                    label=""
                    disabled={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].name !== FreightPackageTypeName.Custom}
                    items={customPackageTypes}
                    selectedValue={selectedPackages[getShipmentPackageCriteriaIndex(selectedPackages, row.id)].packageType}
                    minWidth="120%"
                    onChange={(value) => onPackageTypeChange(row.id, value)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton>
                    <ClearButton 
                      onClick={() => {
                        const updatedPackages = [...selectedPackages];
                        updatedPackages.splice(index, 1);
                        setSelectedPackages(updatedPackages);
                        onChange(updatedPackages);
                      }}
                    />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Grid container spacing={1} direction="row" mt={2}>
        <Grid item>
          <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Warning} onClick={onAddPackage}>
            + Custom
          </Button>
        </Grid>
        {freightTypes.map((row) => (
          <Grid item key={row.id}>
            <Button key={row.name} variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Primary} onClick={() => onAddPackage(row)}>
              {`+ ${row.name} (${row.length}cm x ${row.width}cm x ${row.height}cm)`}
            </Button>
          </Grid>
        ))}
        <Grid item>
          <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Error} onClick={onClear}>
            + Clear
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};