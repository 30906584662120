import React from 'react';
import { Stack } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { ServiceTaskQuantityProps, } from './ServiceTaskQuantity.props';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';

export const ServiceTaskQuantity: React.FC<ServiceTaskQuantityProps> = ({
  label,
  serviceTaskQuantityData,
  onChange,
}) => {

  const onClick = (adjustValue: number) => {
    const updatedQuantity = Math.max(0, serviceTaskQuantityData.quantity + adjustValue);
    onChange({ ...serviceTaskQuantityData, quantity: updatedQuantity });
  };

  return (
    <Stack direction="column" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Start}>
      <Stack direction="row" spacing={GRID_SPACING}>
        <Button variant={ButtonVariantProps.Icon} onClick={() => onClick(-1)}>
          <Remove />
        </Button>
        <TextField
          fullWidth
          label={label}
          inputProps={{ min: 0 }}
          type={TextFieldTypeProps.Number}
          value={serviceTaskQuantityData.quantity}
          onChange={(val) => onChange({ ...serviceTaskQuantityData, quantity: parseInt(val) })}
        />
        <Button variant={ButtonVariantProps.Icon} onClick={() => onClick(1)}>
          <Add />
        </Button>
      </Stack>
      <TextField
        fullWidth
        label="Notes"
        multiline
        rows={4}
        value={serviceTaskQuantityData.notes}
        onChange={(val) => onChange({ ...serviceTaskQuantityData, notes: val })}
      />
    </Stack>
  );
};
