import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StyledScrollableWrapper } from './PartManifestNonSerial.styles';
import { PartManifestNonSerialProps } from '.';
import { Link } from '../../atoms/Link';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { ListType } from '../../../@types';
import { nonSerialisedColumnSelectionItems } from '../../../constants/partBulkUpdate.constant';

export const PartManifestNonSerial: React.FC<PartManifestNonSerialProps> = ({
  partManifestNonSerialData,
  onChange
}) => {
  const onClickDeactivateRow = (rowId: number) => {
    const updatedPartManifestNonSerialData = partManifestNonSerialData.map((row) => {
      if (row.id === rowId) {
        return { ...row, isActive: false };
      }
      return row;
    }
    );
    onChange(updatedPartManifestNonSerialData);
  };

  const filteredPartManifestNonSerialData = partManifestNonSerialData.filter((item) => item.isActive === true);

  const columns: Array<GridColDef> = [
    {
      field: '',
      headerName: '',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={() => onClickDeactivateRow(params.row.id)}
          color="error"
        >
          <CloseRounded />
        </IconButton>
      ),
    },
    { field: 'partTypeId', headerName: 'Part Type ID', flex: 1 },
    { field: 'partTypeName', headerName: 'Part Type Name', flex: 1 },
    {
      field: 'locationName', headerName: 'Structure Node (Location)', flex: 1.5,
      renderCell: (params: GridRenderCellParams) => <Link href={`structures/${params.row.locationId}`}>{params.row.locationName}</Link>
    },
    { field: 'condition', headerName: 'Condition', flex: 0.8 },
    { field: 'fleetTagName', headerName: 'Fleet Tag', flex: 1 },
    { field: 'id', headerName: 'NS Part ID', flex: 0.7 },
    { field: 'quantityInStock', headerName: 'Current QTY', flex: 1 },
  ];

  return (
    <Stack direction="row" spacing={6} mt={4} justifyContent="center" alignItems="flex-start">
      <StyledScrollableWrapper>
        <CustomDataGrid
          columns={columns}
          rows={filteredPartManifestNonSerialData}
          columnSelectionItems={nonSerialisedColumnSelectionItems}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          exportFileName={ListType.PartBulkUpdateManifestList}
          justifyContent="flex-end"
          nonSerialPartsManifestData={filteredPartManifestNonSerialData}
        />
      </StyledScrollableWrapper>
    </Stack>
  );
};