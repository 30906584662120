import React from 'react';
import { Print } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { PrintLabelProps } from './PrintLabel.props';
import { IconButtonColor } from '../../../configs/enums';
import { useGetPrintLabel } from '../../../queries/print-label-query';
import { printLabel } from '../../../utils/print-label-utils';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';

export const PrintLabel: React.FC<PrintLabelProps> = ({
  entityId,
  entityType,
  buttonLabel
}) => {
  const getPrintLabel = useGetPrintLabel(entityId, entityType);

  const onClickPrintLabel = async () => {
    const labelToPrint = await getPrintLabel.mutateAsync({});
    printLabel(labelToPrint, entityType);
  };

  return (
    buttonLabel ?
      <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Primary} onClick={onClickPrintLabel}>
        {buttonLabel}
      </Button>
      :
      <IconButton aria-label="print label" color={IconButtonColor.Primary} onClick={onClickPrintLabel}>
        <Print />
      </IconButton>
  );
};
