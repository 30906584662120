import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

export const ContactLayoutBox = styled(Box)`
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const StructureAddressGridContainer = styled(Grid)`
  padding: 10px;
`;

export const TableGridContainer = styled(Grid)`
  margin-bottom: 5px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom:10px;
`;