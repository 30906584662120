import { createTheme } from '@mui/material';

export const lightTheme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      'Arial',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#25849D',
    },
    action: {
      active: 'rgba(115, 198, 220, 1)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08
    },
    background: {
      default: 'rgb(0 52 63)',
    },
    divider: '#0000000f',
    success: {
      main: '#4CAF50',
      contrastText: '#ffff'
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
            '&.Mui-focusVisible': { background: 'rgba(0, 0, 0, 0.08)' }
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: '#eeeeee',
          backgroundColor: '#ffffff',
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'rgb(0 52 63)',
        },
      }
    },
  }
});
