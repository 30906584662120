import React, { useEffect, useState, KeyboardEvent } from 'react';
import { GridColDef, GridRenderCellParams, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReturnSuggestionsFragmentProps } from './ReturnSuggestionsFragment.props';
import { ReturnSuggestion } from '../../../../@types/shipment.type';
import { formatDate } from '../../../../utils/common';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { ManifestBox } from './ReturnSuggestionsFragment.styles';
import { LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { useAdvanceBulkSearchSerials, useAdvanceSearchPartTypes, useSearchDispatchParts } from '../../../../queries/live-search-query';
import { PartTypeSearchItem } from '../../../../components/molecules/PartTypeSearchItem';
import { PartTypeBulkLiveSearchDataInsertionType, PartTypeLiveSearchData } from '../../../../@types/part-type.type';
import { DataGrid } from '../../../../components/atoms/DataGrid';
import { TextField } from '../../../../components/atoms/TextField';
import { useGetNSPartByPartTypeAndLocation } from '../../../../queries/part-query';
import { S_HASH } from '../../../../constants/shipment';
import { SAMPLE_PARTTYPE_BULK_LIVESEARCH_INSERTION_DATA } from '../../../../constants/addStock';
import { CONDITION_ORDER, SELECTED_DATA, SelectedData } from '../../Dispatch/DispatchManifestFragment';
import { NSPartLocationList } from '../../../../@types/part.type';
import { PartSearchData } from '../../../../@types';
import { SerialisedType } from '../../../../configs/enums';
import { commaSeparator, getHasCodeForPartTypeSearch } from '../../../../utils/shipment';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { DispatchNSInputPopup } from '../../../../components/organisms/DispatchNSInputPopup';
import { COLORS } from '../../../../configs/colors';
import { Link } from '../../../../components/atoms/Link';
import { PageContainer } from '../../../../components/atoms/PageContainer';
import { AdvanceLiveSearchBox } from '../../../../components/atoms/AdvanceLiveSearchBox';

export const ReturnSuggestionsFragment: React.FC<ReturnSuggestionsFragmentProps> = ({
  returnSuggestions,
  manifest,
  isNew,
  setManifest
}) => {
  const [returnSuggestionData, setReturnSuggestionData] = useState<Array<ReturnSuggestion>>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<Array<number>>([]);
  const [isCopyPaste, setIsCopyPaste] = useState<boolean>(false);
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);
  const [advanceBulkSearchData, setAdvanceBulkSearchData] = useState<PartTypeBulkLiveSearchDataInsertionType>(SAMPLE_PARTTYPE_BULK_LIVESEARCH_INSERTION_DATA);
  const [searchItem, setSearchItem] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedPartType, setSelectedPartType] = useState<SelectedData>(SELECTED_DATA);
  const [selectedLocation, setSelectedLocation] = useState<SelectedData>(SELECTED_DATA);
  const [locationList, setLocationList] = useState<NSPartLocationList[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
  const [showProgress, setShowProgress] = useState(false);

  const advanceSearchPartTypes = useAdvanceSearchPartTypes();
  const advanceBulkSearchSerials = useAdvanceBulkSearchSerials();
  const searchParts = useSearchDispatchParts();
  const getNSPartByPartTypeAndLocation = useGetNSPartByPartTypeAndLocation(selectedPartType?.id, selectedLocation?.id, true);

  useEffect(() => {
    isNew && returnSuggestions && setReturnSuggestionData(returnSuggestions);
  }, [returnSuggestions, isNew]);

  useEffect(() => {
    if (isNew && selectedRowIds) {
      setManifest({
        ...manifest,
        serialisedManifest: returnSuggestionData.filter((returnSuggestionItem: ReturnSuggestion) => selectedRowIds.some((selectedRowId: number) => selectedRowId === returnSuggestionItem.id && returnSuggestionItem.partId)),
        nonSerialisedManifest: returnSuggestionData.filter((returnSuggestionItem: ReturnSuggestion) => selectedRowIds.some((selectedRowId: number) => selectedRowId === returnSuggestionItem.id && returnSuggestionItem.ns_partId))
      });
    }
  }, [isNew, selectedRowIds]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.3,
      hide: true,
      renderCell: (params: GridRenderCellParams) => params.row.id
    },
    {
      field: 'partTypeName',
      headerName: 'Part Type Name',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.partTypeName
    },
    {
      field: 'serial',
      headerName: 'Serial',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.serial || '-'
    },
    {
      field: 'quantityInStock',
      headerName: 'Quantity',
      flex: 0.2,
      valueGetter: (params: GridValueGetterParams) => params.row.quantityInStock
    },
    {
      field: 'condition',
      headerName: 'Condition',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.conditionName
    },
    {
      field: 'nodeName',
      headerName: 'Node Name',
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => params.row.nodeName
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Last Updated Date',
      flex: 0.4,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt)
    },
  ];

  const onSelectionModelChange = (selectedRows: GridSelectionModel) => {
    setSelectionModel(selectedRows);
    setSelectedRowIds(selectedRows.toString().split(',').map(Number));
  };

  const handleAddNewSerialised = (partLiveSearchData: PartSearchData, index: number) => {
    if (partLiveSearchData && partLiveSearchData.id) {
      const newItem = {
        id: partLiveSearchData.id,
        partId: partLiveSearchData.id,
        partTypeName: partLiveSearchData.partType.name,
        partTypeId: partLiveSearchData.partTypeId,
        serial: partLiveSearchData.serial1,
        conditionName: partLiveSearchData.partCondition.name,
        conditionCode: partLiveSearchData.conditionCode,
        nodeName: partLiveSearchData.locationName,
        partTypeNumber: partLiveSearchData.partType.number,
        softwareVersionId: partLiveSearchData?.software?.id || 0,
        firmwareVersionId: partLiveSearchData.firmware?.id || 0,
        hardwareRevisionId: partLiveSearchData.hardware?.id || 0,
        fleetTagId: partLiveSearchData.fleetTagId,
        softwareVersion: partLiveSearchData.software?.version,
        firmwareVersion: partLiveSearchData.firmware?.version,
        hardwareVersion: partLiveSearchData.hardware?.version,
        fleetTagName: partLiveSearchData.fleetTag,
        updatedAt: partLiveSearchData.updatedAt || ''
      };

      if (manifest.serialisedManifestManual.some((serialisedManifestItem) => serialisedManifestItem.id === newItem.id))
        return 0;

      manifest.serialisedManifestManual.push(newItem);

      setManifest({
        ...manifest,
        serialisedManifestManual: manifest.serialisedManifestManual,
      });
    } else {
      const rowData = {
        id: index,
        partTypeId: 0,
        partTypeName: '',
        partTypeNumber: '',
        serial: partLiveSearchData.serial1,
        conditionName: '',
        conditionCode: '',
        nodeName: '',
        updatedAt: ''
      };
      manifest.serialisedManifestManual.push(rowData);

      setManifest({
        ...manifest,
        serialisedManifestManual: manifest.serialisedManifestManual,
      });
    }
    setSearchItem('');
    setIsEnterPressed(false);
  };

  const handleAddNewNonSerialised = (partTypeLiveSearchData: NSPartLocationList, index: number) => {
    const newItem = {
      id: index,
      ns_part_Id: partTypeLiveSearchData.id,
      partTypeName: selectedPartType.name,
      partTypeId: selectedPartType.id,
      partTypeNumber: selectedPartType.number || '',
      conditionName: partTypeLiveSearchData.condition || '',
      conditionCode: partTypeLiveSearchData.conditionCode || '',
      quantityInStock: partTypeLiveSearchData.quantity,
      nodeName: partTypeLiveSearchData.locationName || '',
      fleetTagId: partTypeLiveSearchData.fleetTagId,
      fleetTagName: partTypeLiveSearchData.fleetTagName,
      updatedAt: partTypeLiveSearchData.updatedAt || ''
    };

    setManifest({
      ...manifest,
      nonSerialisedManifestManual: [...manifest.nonSerialisedManifestManual, newItem],
    });

    setSearchItem('');
    setIsEnterPressed(false);
  };

  useEffect(() => {
    if (searchParts.data && isEnterPressed) {
      const enteredData = searchParts.data[0];
      setSearchItem(enteredData?.name);
      handleAddNewSerialised(enteredData, manifest.serialisedManifestManual.length);  
    }
  }, [searchParts.data]);
  
  useEffect(() => {
    isEnterPressed && searchItem && onApiInvoke(searchItem);
  }, [isEnterPressed]);

  useEffect(() => {
    if (!!selectedPartType.id && !!selectedLocation.id) {      
      getNSPartByPartTypeAndLocation.refetch();
    }
  }, [selectedPartType, selectedLocation]);

  useEffect(() => {
    manifest.sourceNodeId &&     
      setSelectedLocation({
        ...selectedLocation,
        id: manifest.sourceNodeId
      });
  }, [manifest.sourceNodeId]);

  useEffect(() => {
    if (getNSPartByPartTypeAndLocation.data) {
      // arranging data in order of given array
      const arrangedData: NSPartLocationList[] = [];
      getNSPartByPartTypeAndLocation.data.forEach((a: NSPartLocationList) => {
        arrangedData[CONDITION_ORDER.indexOf(a.condition || '')] = a;
      });  
      setLocationList(arrangedData);
    }
  }, [getNSPartByPartTypeAndLocation.data]);

  useEffect(() => {
    if (advanceBulkSearchSerials.data) {
      advanceBulkSearchSerials.data.map((liveSearchResult: PartSearchData, index: number) => {    
        handleAddNewSerialised(liveSearchResult, manifest.serialisedManifestManual.length + index);
      });
    }
  }, [advanceBulkSearchSerials.data]);

  const bulkSearchInputValueOptimizer = (value: string) => {
    setAdvanceBulkSearchData({   
      searchableValues: commaSeparator(value),
      applicableNodeId: '',
      isReturn: true
    });
  };

  const onApiInvoke = async (searchableData: string) => {
    setSearchItem(searchableData);
    if (!isEnterPressed || getHasCodeForPartTypeSearch(searchableData)) {
      const response = await advanceSearchPartTypes.mutateAsync({
        searchableValues: searchableData,
        applicableNodeId: ''
      });
      return response.filter((responseItem) => responseItem.serialisation === SerialisedType.Consumable);
    } else if (isEnterPressed && isCopyPaste) {
      return await searchParts.mutateAsync({ name: S_HASH.concat(searchableData), applicableNodeIds: '' });
    } else {
      return [];
    }
  };

  const onSearch = () => {
    advanceBulkSearchSerials.mutateAsync(advanceBulkSearchData);
  };

  return (
    <PageContainer>
      {isNew &&
        <Stack mb={2}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Suggestions
          </Typography>
        </Stack>
      }
      {isNew &&
        <DataGrid
          columns={columns}
          rows={returnSuggestionData}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          checkboxSelection
          autoHeight={true}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
        />
      }
      {isNew &&
        <Grid container justifyContent="space-between" mt={2} mb={2} width="100%">
          <Grid item xs={12} md={4} width="100%">
            <AdvanceLiveSearchBox
              title={isCopyPaste ? 'Serial Copy Paste' : 'PartType Search'}
              timeOffset={400}
              disabled={!manifest.sourceNodeId && !manifest.destinationNodeId}
              showProgress={showProgress}
              value={manifest.smartInput || ''}
              overrideOnEnterKey={true}
              onClearValue={() => {
                setManifest({ ...manifest, smartInput: '' });
                setIsEnterPressed(false);
                setIsCopyPaste(false);
                setShowProgress(false);
              }}
              renderItem={(props: any, option: any) => { 
                if (!isCopyPaste) {
                  return (
                    <LiveSearchListItem {...props}>
                      {option.displayId ? <PartTypeSearchItem data={option} /> : <LocationSearchItem data={option} />}
                    </LiveSearchListItem>
                  );
                }
              }}
              onChange={(value: PartTypeLiveSearchData) => {
                setSelectedPartType({
                  id: value.id,
                  name: value.name,
                  number: value.number
                });
                setIsOpen(true);
              }}
              onTextChange={(event: any) => { 
                bulkSearchInputValueOptimizer(event.target.value);
                setManifest({ ...manifest, smartInput: event.target.value });
              }}
              onApiInvoke={onApiInvoke}
              onPaste={() => setIsCopyPaste(true)}
              onKeyDown={(event: KeyboardEvent) => { 
                if (event.key === 'Enter') {
                  event.preventDefault();
                  
                  if (isCopyPaste) {
                    onSearch();
                  } else {
                    setIsEnterPressed(true);
                  }
                } 
              }}
              isPaste={isCopyPaste}
            />
          </Grid>
        </Grid>
      }
      <DispatchNSInputPopup
        isOpen={isOpen}
        onChange={(val) => {
          setSelectedLocation({
            id: val.id,
            name: val.name || ''
          });
        }}
        onClick={(selectedItem) => {
          handleAddNewNonSerialised(selectedItem, manifest.nonSerialisedManifestManual.length);
          setIsOpen(false);      
        }}
        onCancel={() => {
          setIsOpen(false);
          setSelectedPartType({
            id: 0,
            name: '',
            number: '',
          });
          setSearchItem('');
        }}
        onClose={() => setIsOpen(false)}
        sourceLocation={manifest.sourceNodeId || 0}
        sourceLocationName={manifest.source || ''}
        partTypeName={selectedPartType?.name || ''}
        locationData={locationList}
      />
      <ManifestBox>
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Manifest
        </Typography>
        <Stack mb={4} />
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Serialised Parts
        </Typography>
        <Stack mb={2} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Part Type Name</TableCell>
                <TableCell align="right">Serial</TableCell>
                <TableCell align="center">Condition</TableCell>
                <TableCell align="center">SW</TableCell>
                <TableCell align="center">FW</TableCell>
                <TableCell align="center">HW</TableCell>
                <TableCell align="center">Fleet Tag</TableCell>
                <TableCell align="right">Node Name</TableCell>
                <TableCell align="right">Last Updated Date</TableCell>
                {isNew && <TableCell align="right">Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {manifest?.serialisedManifest?.map((row, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    <Stack direction="column" justifyContent="flex-start">
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Id: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeId}</Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}><Link href={`/configure/partTypes/${row.partTypeId}`}>{row.partTypeName}</Link></Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeNumber}</Typography></Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right"><Link href={`/parts/${row.partId}`}>{row.serial}</Link></TableCell>
                  <TableCell align="center">{row.conditionName}</TableCell>
                  <TableCell align="center">{row.softwareVersion}</TableCell>
                  <TableCell align="center">{row.firmwareVersion}</TableCell>
                  <TableCell align="center">{row.hardwareVersion}</TableCell>
                  <TableCell align="center">{row.fleetTagName}</TableCell>
                  <TableCell align="right">{row.nodeName}</TableCell>
                  <TableCell align="right">{formatDate(row.updatedAt)}</TableCell>
                  {isNew &&
                    <TableCell align="right">
                      <Button variant={ButtonVariantProps.Icon} onClick={() => {
                        const filteredSelectedRowIds = selectedRowIds.filter((obj) => obj !== row.id);
                        const filteredSelectionModel = selectionModel?.filter((obj) => obj !== row.id);
                        setSelectedRowIds(filteredSelectedRowIds);
                        setSelectionModel(filteredSelectionModel);

                        const newData = manifest.serialisedManifest.filter((obj, i) => i !== index);
                        setManifest({
                          ...manifest,
                          serialisedManifest: newData,
                        });
                      }}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  }
                </TableRow>
              ))}
              {isNew && manifest?.serialisedManifestManual?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ backgroundColor: row.partTypeId === 0 ? COLORS.Red : COLORS.White }}
                >
                  <TableCell component="th" scope="row">
                    <Stack direction="column">
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Id: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeId}</Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}><Link href={`/configure/partTypes/${row.partTypeId}`}>{row.partTypeName}</Link></Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeNumber}</Typography></Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    {row.partTypeId === 0 ?
                      row.serial
                      :
                      <Link href={`/parts/${row.partId}`}>{row.serial}</Link>
                    }
                  </TableCell>
                  <TableCell align="center">{row.conditionName}</TableCell>
                  <TableCell align="center">{row.softwareVersion}</TableCell>
                  <TableCell align="center">{row.firmwareVersion}</TableCell>
                  <TableCell align="center">{row.hardwareVersion}</TableCell>
                  <TableCell align="center">{row.fleetTagName}</TableCell>
                  <TableCell align="right">{row.nodeName}</TableCell>
                  <TableCell align="right">{formatDate(row.updatedAt)}</TableCell>
                  {isNew &&
                    <TableCell align="right">
                      <Button variant={ButtonVariantProps.Icon} onClick={() => {
                        const newData = manifest.serialisedManifestManual.filter((obj, i) => i !== index);
                        setManifest({
                          ...manifest,
                          serialisedManifestManual: newData,
                        });
                      }}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack mb={4} />
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Non-Serialised Parts
        </Typography>
        <Stack mb={2} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Part Type Name</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="center">Condition</TableCell>
                <TableCell align="center">Fleet Tag</TableCell>
                <TableCell align="right">Node Name</TableCell>
                <TableCell align="right">Last Updated Date</TableCell>
                {isNew && <TableCell align="right">Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {manifest?.nonSerialisedManifest?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Stack direction="column">
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Id: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeId}</Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}><Link href={`/configure/partTypes/${row.partTypeId}`}>{row.partTypeName}</Link></Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeNumber}</Typography></Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    {isNew ?
                      <TextField
                        value={row.quantityInStock || 0}
                        inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={(val) => {
                          const index = manifest.nonSerialisedManifest.findIndex((item: ReturnSuggestion) => {
                            return item.id === row.id;
                          });
                          manifest.nonSerialisedManifest[index].quantityInStock = parseInt(val);
                          setManifest({
                            ...manifest,
                            nonSerialisedManifest: manifest.nonSerialisedManifest
                          });
                        }}
                      />
                      :
                      row.quantityInStock
                    }
                  </TableCell>
                  <TableCell align="center">{row.conditionName}</TableCell>
                  <TableCell align="center">{row.fleetTagName}</TableCell>
                  <TableCell align="right">{row.nodeName}</TableCell>
                  <TableCell align="right">{formatDate(row.updatedAt)}</TableCell>
                  {isNew &&
                    <TableCell align="right">
                      <Button variant={ButtonVariantProps.Icon} onClick={() => {
                        const filteredSelectedRowIds = selectedRowIds.filter((obj) => obj !== row.id);
                        const filteredSelectionModel = selectionModel?.filter((obj) => obj !== row.id);
                        setSelectedRowIds(filteredSelectedRowIds);
                        setSelectionModel(filteredSelectionModel);
                        const newData = manifest.nonSerialisedManifest.filter((obj, i) => i !== index);
                        setManifest({
                          ...manifest,
                          nonSerialisedManifest: newData,
                        });
                      }}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  }
                </TableRow>
              ))}
              {isNew && manifest?.nonSerialisedManifestManual?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Stack direction="column">
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Id: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeId}</Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part Type Name: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}><Link href={`/configure/partTypes/${row.partTypeId}`}>{row.partTypeName}</Link></Typography></Typography>
                      <Typography variant={TypographyVariantProps.Caption}>Part No: <Typography variant={TypographyVariantProps.Caption} fontWeight={600}>{row.partTypeNumber}</Typography></Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    {isNew ?
                      <TextField
                        value={row.quantityInStock || 0}
                        inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                        onChange={(val) => {
                          const index = manifest.nonSerialisedManifestManual.findIndex((item: ReturnSuggestion) => {
                            return item.id === row.id;
                          });
                          manifest.nonSerialisedManifestManual[index].quantityInStock = parseInt(val);
                          setManifest({
                            ...manifest,
                            nonSerialisedManifestManual: manifest.nonSerialisedManifestManual
                          });
                        }}
                      />
                      :
                      row.quantityInStock
                    }
                  </TableCell>
                  <TableCell align="center">{row.conditionName}</TableCell>
                  <TableCell align="center">{row.fleetTagName}</TableCell>
                  <TableCell align="right">{row.nodeName}</TableCell>
                  <TableCell align="right">{formatDate(row.updatedAt)}</TableCell>
                  {isNew &&
                    <TableCell align="right">
                      <Button variant={ButtonVariantProps.Icon} onClick={() => {
                        const newData = manifest.nonSerialisedManifestManual.filter((obj, i) => i !== index);
                        setManifest({
                          ...manifest,
                          nonSerialisedManifestManual: newData,
                        });
                      }}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ManifestBox>
    </PageContainer>
  );
};