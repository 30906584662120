import React from 'react';
import { SupplierLabelProps } from './SupplierLabel.props';
import { COLORS } from '../../../configs/colors';
import { Typography, TypographyVariantProps } from '../Typography';
import { StyledStack } from './SupplierLabel.styles';

export const SupplierLabel:React.FC<SupplierLabelProps> = ({
  supplierData
}) => {
  return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{supplierData.supplierName}</Typography> 
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>pricePerPackage: {supplierData.pricePerPackage}</Typography>
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{supplierData.supplierPartTypeName}</Typography>
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{supplierData.supplierPartTypeNumber}</Typography>
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>{supplierData.lastUpdated}</Typography>
      <Typography textColor={COLORS.Gray} variant={TypographyVariantProps.Caption}>itemPrice: {supplierData.itemPrice}</Typography>
    </StyledStack>
  );
};