import { ReactNode } from 'react';

export enum UnderlineTypeProps {
  Always = 'always',
  Hover = 'hover',
  None = 'none'
}

export interface LinkProps {
  children?: ReactNode;
  color?: any;
  underline?: UnderlineTypeProps;
  href?: string;
  target?: string;
  onClick?: (event: any) => void;
}