import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';

export type PaginationSettings = {
  currentPage: number;
  total: number;
  limit: number;
}

export type OrderSettings = {
  order_by: string;
  order_direction: 'asc' | 'desc';
}

export interface CustomFilterSettings {
  locationId?: number;
  includeChilds: boolean;
  startDate: string;
  endDate: string;
}

export enum ColorProps {
  Default = 'default',
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export enum IconColorProps {
  Disabled = 'disabled',
  Action = 'action',
  Inherit = 'inherit',
  Secondary = 'secondary',
  Primary = 'primary',
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

export enum SizeProps {
  Small = 'small',
  Medium = 'medium'
}

export enum FontSizeProps {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Inherit = 'inherit'
}

export interface LabelValue {
  value: number | string;
  label: string;
}

export interface Warning {
  message: string;
  field: string;
}

export enum ListType {
  UserList = 'UserList',
  StructureList = 'StructureList',
  ServiceTypeList = 'ServiceTypeList',
  ServiceList = 'ServiceList',
  RoleList = 'RoleList',
  ClientContractList = 'ClientContractList',
  PartTypeList = 'PartTypeList',
  PartList = 'PartList',
  ShipmentList = 'ShipmentList',
  AttendanceReportList = 'AttendanceReportList',
  ShipmentBatchReceiveList = 'ShipmentBatchReceiveList',
  InteractionRules = 'InteractionRuleList',
  PartMovementList = 'PartMovementList',
  SkuConfigList = 'SkuConfigList',
  PartBulkUpdateManifestList = 'PartBulkUpdateManifestList'
}

export enum FileType {
  XLSX = 'xlsx',
  CSV = 'csv'
}

export enum ADConnectionNames {
  AmtekAD = 'Amtek-AD',
  AmtekADDev = 'Amtek-AD-dev',
  AmtekCMSAuth0UAT = 'Amtek-CMS-Auth0-UAT'
}

export enum DatagridSortingTypes {
  Ascending = 'asc',
  Descending = 'desc'
}

export interface QueryOption {
  filterModel: GridFilterModel;
  page: number;
  pageSize: number;
  sortModel?: GridSortModel;
}

export interface ServiceGeoLocationPosition {
  serviceId: number;
  latitude: number;
  longitude: number;
  altitude?: number;
  accuracy?: number;
  altitudeAccuracy?: number;
  heading?: number;
  speed?: number;
}