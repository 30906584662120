import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  padding: 10px;
  > *:not(:first-child) {
    margin-top: 10px;
  }  
`;

export const IconImage = styled('img')`
  height: 100px;
  width: 100px;
  margin-top: 25px;
`;