import React, { useState, useEffect } from 'react';
import { ServiceCheckOutFragmentProps } from './ServiceCheckOutFragment.props';
import { ServiceCheckOut } from '../../../../components/organisms/ServiceCheckOut';
import { useGetNextServicesStatuses } from '../../../../queries/service-status-query';
import { getCurrentTime, getCurrentTimestamp, getDateFromTimestamp, getTimestamp } from '../../../../utils/common';
import { getCheckedInData } from '../../../../utils/services';
import { useGetServiceCheckInData, useUpdateServiceCheckOut } from '../../../../queries/service-checkin-checkout-query';

export const ServiceCheckOutFragment: React.FC<ServiceCheckOutFragmentProps> = ({
  data,
  onClose,
  interactionsAvailable,
  onNoteStatusAndTimeUpdate
}) => {
  const [serviceStatus, setServiceStatus] = useState<string>('-1');
  const [noteText, setNoteText] = useState<string>('');
  const [checkinId, setCheckinId] = useState<number>(0);
  const [offSiteTime, setOffnSiteTime] = useState<string>('');
  const [initialSubmit, setInitialSubmit] = useState(false);

  const getServiceCheckIn = useGetServiceCheckInData(data.id);
  const nextServicesStatusesQuery = useGetNextServicesStatuses(data.serviceStatusCode, data.serviceTypeCode, data.id);

  useEffect(() => {
    if (getServiceCheckIn.data) {
      const checkin = getCheckedInData(getServiceCheckIn.data);
      
      if (checkin) {
        setCheckinId(checkin.id);
      }
    }
  }, [getServiceCheckIn.data]);

  const updateServiceCheckOutQuery = useUpdateServiceCheckOut(checkinId, data.id);

  useEffect(() => {
    if (data.serviceStatusCode && data.serviceTypeCode) {
      nextServicesStatusesQuery.refetch();
    }
  }, [data]);

  useEffect(() => {
    if (!initialSubmit && checkinId) {
      handleCheckInSubmit();
      setInitialSubmit(true);
    }
    serviceStatus !== '-1' && handleCheckInSubmit();
  }, [checkinId, serviceStatus]);

  useEffect(() => {
    onNoteStatusAndTimeUpdate(serviceStatus,noteText,offSiteTime ? getTimestamp(offSiteTime) : getCurrentTimestamp());
  }, [interactionsAvailable,serviceStatus,noteText,offSiteTime]);

  const handleCheckInSubmit = (byPassInteractionCheck?: boolean) => {
    const params = {
      nextStatus: serviceStatus,
      notes: noteText,
      checkOutTime: offSiteTime ? getTimestamp(offSiteTime) : getCurrentTimestamp(),
      byPassInteractionCheck,
      actualCheckOutTime: offSiteTime ? getDateFromTimestamp(offSiteTime) : getCurrentTime()
    };
    updateServiceCheckOutQuery.mutateAsync(params);
  };

  return (
    <ServiceCheckOut
      noteText={noteText}
      onNoteText={(val) => setNoteText(val)}
      onSubmit={handleCheckInSubmit}
      onClose={onClose} 
      isChangeState={true} 
      statusOptions={nextServicesStatusesQuery.data?.possibleNextStatuses || []} 
      suggestedOptions={nextServicesStatusesQuery.data?.preferedStatuses || []}
      statusValue={serviceStatus}
      offsiteTime={offSiteTime}
      onOffsiteTime={(val) => setOffnSiteTime(val)} 
      onChangeStatusValue={(val) => setServiceStatus(val)}
      interactionsAvailable={interactionsAvailable}
    />
  );
};