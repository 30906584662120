import React from 'react';
import { Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DispatchManifestGridFragmentProps } from './DispatchManifestGridFragment.props';
import { Typography, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { TableWrapper } from './DispatchManifestGridFragment.styles';
import { DataGrid } from '../../../../../components/atoms/DataGrid';

export const DispatchManifestGridFragment: React.FC<DispatchManifestGridFragmentProps> = ({
  manifestData
}) => {
  const columns: GridColDef[] = [
    {
      field: 'partTypeId',
      headerName: 'Part Type Id',
      width: 80,
    },
    {
      field: 'partTypeName',
      headerName: 'Part Type',
      type: 'string',
      width: 180,
      renderCell: (params: GridRenderCellParams) => 
        <Stack direction="column">
          <Typography variant={TypographyVariantProps.Body2}>{params.row.partTypeName}</Typography>
          <Typography variant={TypographyVariantProps.Caption}>{params.row.partTypeNo}</Typography>
        </Stack>,
    },
    {
      field: 'serial',
      headerName: 'Serial',
      type: 'string',
      width: 150,
    },
    {
      field: 'location',
      headerName: 'Location',
      type: 'string',
      width: 180,
    },
    {
      field: 'conditionFleetTag',
      headerName: 'Condition / Fleet Tag',
      type: 'string',
      width: 200
    },
    {
      field: 'softwareVersion',
      headerName: 'Software Version',
      type: 'string',
      width: 100,
    },
    {
      field: 'hardwareVersion',
      headerName: 'Hardware Version',
      type: 'string',
      width: 100,
    },
    {
      field: 'firmwareVersion',
      headerName: 'Firmware Version',
      type: 'string',
      width: 100,
    },
    {
      field: 'quantity',
      headerName: 'Qty ',
      width: 80
    }
  ];
  
  return (
    <TableWrapper>
      <DataGrid
        columns={columns}
        rows={manifestData || []}
        rowCount={manifestData?.length}
        disableColumnSelector
        disableColumnFilter
        disableDensitySelector
        disableColumnMenu
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: false
          }
        }}
        autoHeight
      />
    </TableWrapper>
  );
};