import React, { useEffect, useState } from 'react';
import { Stack, Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { ActionPermissions, CustomPermission, SizeProps } from '../../../@types';
import { NonSerializedShipmentManifestData } from '../../../@types/shipment.type';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { NonSerializedStyledLocationTableCell, NonSerializedStyledPartTypeTableCell, NonSerializedStyledTableCell } from './NonSerializedShipmentManifestItem.styles';
import { NonSerializedShipmentManifestItemProps } from '.';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Typography } from '../../atoms/Typography';

export const NonSerializedShipmentManifestItem: React.FC<NonSerializedShipmentManifestItemProps> = ({
  nonSerializedShipmentManifestItemData,
  onChange,
  onApiInvoke,
  isEditMode,
  isCheckbox
}) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);
  
  useEffect(() => {
    setSelectAllCheckbox(nonSerializedShipmentManifestItemData.every((manifestItem) => manifestItem.selected));
  }, [nonSerializedShipmentManifestItemData]);
  
  const onClickAll = () => {
    const updatedData: Array<NonSerializedShipmentManifestData> = nonSerializedShipmentManifestItemData.map((item) => ({ ...item, selected: !selectAllCheckbox }));
    onChange(updatedData);
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  return (
    <TableContainer>
      <Table size={SizeProps.Small} sx={{ width: '100%', overflow: 'auto' }}>
        <TableHead>
          {isCheckbox &&
            <NonSerializedStyledTableCell>
              <Checkbox
                color={CheckboxColorProps.Primary}
                checked={selectAllCheckbox}
                onChange={onClickAll}
              />
            </NonSerializedStyledTableCell>
          }
          <NonSerializedStyledTableCell></NonSerializedStyledTableCell>
          <NonSerializedStyledTableCell>Shipment ID</NonSerializedStyledTableCell>
          <NonSerializedStyledTableCell>Condition</NonSerializedStyledTableCell>
          <NonSerializedStyledTableCell>FleetTags</NonSerializedStyledTableCell>
          <NonSerializedStyledTableCell>Quantity</NonSerializedStyledTableCell>
          {isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions) &&
            <NonSerializedStyledTableCell>Put Away Destination</NonSerializedStyledTableCell>
          }
        </TableHead>
        {nonSerializedShipmentManifestItemData.map((item, index: number) => 
          <TableRow key={index} sx={{ overflow: 'auto' }}>
            {isCheckbox &&
              <NonSerializedStyledTableCell>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={item.selected}
                  onChange={(event) => {
                    const updatedData = [...nonSerializedShipmentManifestItemData];
                    updatedData[index].selected = event.target.checked;
                    onChange(updatedData);
                  }}
                />
              </NonSerializedStyledTableCell>
            }
            <NonSerializedStyledPartTypeTableCell>
              <Stack alignItems="flex-start">
                <Typography>Part Type Id: <b>{item.partTypeId}</b></Typography>
                <Typography>Part Type Name: <b>{item?.partTypeName}</b></Typography>
                <Typography>Part No: <b>{item?.partTypeNumber}</b></Typography>
              </Stack>
            </NonSerializedStyledPartTypeTableCell>
            <NonSerializedStyledTableCell>{item.shipmentId}</NonSerializedStyledTableCell>
            <NonSerializedStyledTableCell>{item.conditionName}</NonSerializedStyledTableCell>
            <NonSerializedStyledTableCell>{item.fleetTagName}</NonSerializedStyledTableCell>
            <NonSerializedStyledTableCell>
              <TextField
                type={TextFieldTypeProps.Number}
                label="QTY"
                inputProps={{ min: 0, max: item.maxQty }}
                value={item.qty}
                disabled={!isEditMode}
                onChange={(text) => {
                  const updatedData = [...nonSerializedShipmentManifestItemData];
                  updatedData[index].qty = Number(text);
                  onChange(updatedData);
                }}
              />
            </NonSerializedStyledTableCell>
            {isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions) &&
              <NonSerializedStyledLocationTableCell>
                <LiveSearchBox
                  title="Select a Location"
                  timeOffset={400}
                  value={item?.putAwayLocationName || ''}
                  defaultSuggetions={item.nonSerialisedSuggestions}
                  onClearValue={() => ''}
                  disabled={!isEditMode}
                  onChange={(value) => {
                    const updatedData = [...nonSerializedShipmentManifestItemData];
                    updatedData[index].putAwayLocationName = value.name;
                    updatedData[index].putAwayLocationId = value.id;
                    onChange(updatedData);
                  }}
                  renderItem={(props: any, option: any) => {
                    return (
                      <LiveSearchListItem {...props}>
                        <LocationSearchItem data={option} />
                      </LiveSearchListItem>
                    );
                  }}
                  onApiInvoke={onApiInvoke}
                />
              </NonSerializedStyledLocationTableCell>
            }
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
};