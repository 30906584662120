import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { GridCellParams, GridColDef, GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import { Grid, Stack } from '@mui/material';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { AdvanceLiveSearchWrapper, ImportWrapper, StyledBox } from './BulkUpdate.styles';
import { useSearchPartsBulkUpdate } from '../../../queries/live-search-query';
import { parseExcelData } from '../../../utils/common';
import { AdvanceLiveSearchBox, LiveSearchListItem } from '../../../components/atoms/AdvanceLiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { ActionPermissions, CustomPermission, LocationNodeData } from '../../../@types';
import { StyledScrollableWrapper } from '../../../components/organisms/ManifestSerial/ManifestSerial.styles';
import { DataGrid } from '../../../components/atoms/DataGrid';
import { useGetPartExistanceBySerials, useGetNSPartExistanceBySerials, useUpdateBulkParts, useProcessPartBulkUpdateFile } from '../../../queries/part-query';
import { BulkUpdatePartItemSelection, NSPartExistance, PartBulkUpdate, PartExistance, PartManifest, PartManifestNonSerialList, PartManifestSerialList, SearchCode, SerialisedPartsUpdateSelectionDataType } from '../../../@types/part.type';
import { PART_MANIFEST, SAMPLE_BULK_NON_SERIALIZED_PARTS, SAMPLE_BULK_SERIALIZED_PARTS, SAMPLE_PART_BULK_UPDATE } from '../../../constants/part';
import { PartManifestNonSerial } from '../../../components/organisms/PartManifestNonSerial';
import { PartManifestSerial } from '../../../components/organisms/PartManifestSerial';
import { CSVDownload } from '../../../components/molecules/CSVDownload';
import { UploadButton } from '../../../components/molecules/UploadButton';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../components/atoms/FormControlLabel';
import { ENTER_LABEL } from '../../../constants/common';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { NonSerialisedPartsUpdateSelection } from '../../PartsBulkUpdate/NonSerialisedPartsUpdateSelection';
import { LinkedListProperties, NonSerialisedPartsUpdateSelectionDataType } from '../../../@types/bulkPartUpdate.type';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';

export const BulkUpdate: React.FC = () => {
  const [isPaste, setIsPaste] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [manifestedData, setManifestedData] = useState<PartBulkUpdate>(SAMPLE_PART_BULK_UPDATE);
  const [isSerial, setIsSerial] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [part, setPart] = useState<any>();
  const [partManifestData, setPartManifestData] = useState<PartManifest>(PART_MANIFEST);
  const [isImportData, setIsImportData] = useState<boolean>(false);
  const [importDataSmartInput, setImportDataSmartInput] = useState<string>('');
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [updateSelectionData, setUpdateSelectionData] = useState<SerialisedPartsUpdateSelectionDataType | NonSerialisedPartsUpdateSelectionDataType>({});
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [blockSerials, setBlockSerials] = useState<boolean>(false);

  const searchPartsBulkUpdate = useSearchPartsBulkUpdate();
  const getPartExistanceBySerials = useGetPartExistanceBySerials();
  const getNSPartExistanceBySerials = useGetNSPartExistanceBySerials();
  const updateBulkParts = useUpdateBulkParts();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const processBulkUpdateFile = useProcessPartBulkUpdateFile();

  const chunkSize = 50;

  TabHandler(`Bulk Update | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (part && part.length > 0) {
      part.forEach((item: BulkUpdatePartItemSelection) => {
        if (item.isExist) {
          setPartManifestData((prevPartManifestData) => {
            let updatedPartManifestData;
            if (isSerial) {
              updatedPartManifestData = {
                ...prevPartManifestData,
                serial: [
                  ...prevPartManifestData.serial,
                  {
                    id: item.id,
                    serial1: item.serial1,
                    serial2: item.serial2,
                    serial3: item.serial3,
                    partTypeId: item.partTypeId,
                    partTypeName: item.partTypeName,
                    locationName: item.locationName || '',
                    locationId: item.locationId,
                    locationTypeCode: item.locationTypeCode,
                    condition: item.conditionCode,
                    software: item.softwareVersion || '',
                    firmware: item.firmwareVersion || '',
                    hardwareRev: item.hardwareRevision || '',
                    fleetTagName: item.fleetTagName || '',
                    notes: item.notes,
                    isActive: true,
                    linkedField: item.linkedField
                  },
                ],
              };
            } else {
              updatedPartManifestData = {
                ...prevPartManifestData,
                nonSerial: [
                  ...prevPartManifestData.nonSerial,
                  {
                    id: item.recordId,
                    partTypeId: item.partTypeId,
                    partTypeName: item.partTypeName,
                    partType: item.partType,
                    locationName: item.locationName,
                    locationId: item.locationId,
                    location: item.location,
                    condition: item.conditionCode,
                    fleetTagName: item.fleetTagName,
                    quantityInStock: item.quantityInStock,
                    isActive: true,
                    linkedField: item.linkedField
                  },
                ],
              };
            }
            return updatedPartManifestData;
          });
        }
      });
    }
  }, [part]);

  const loadData =async (inputValue: string) => {
    if (inputValue) {
      const formatedManifestedData = parseExcelData(inputValue || '') as Array<Array<string>>;
      let currentManifestedData;
      let filteredSerials: any = []; //This type varies with the data (Array<PartExistance> | Array<NSPartExistance>)

      // trigger when non-serialised data enter
      if (!!formatedManifestedData.length && formatedManifestedData[0].length > 3) {
        setIsSerial(false);
        setShowProgress(true);
        // check serialised part existance by partTypeName, locationId, conditionName, fleetTagName
        const nonSerialArray = formatedManifestedData.map((nsPartItem) => {
          let linkFieldValue = nsPartItem[4];
          if(nsPartItem[4]?.toUpperCase().startsWith(SearchCode.AM_LID)){
            linkFieldValue = nsPartItem[4].replace(/\D/g,'');
          } else if(nsPartItem[4]?.toUpperCase().startsWith(SearchCode.AM_PTID)){
            linkFieldValue = nsPartItem[4].replace(/\D/g,'');
          }
          return {
            partType: nsPartItem[0],
            location: nsPartItem[1],
            conditionCode: nsPartItem[2],
            fleetTagName: nsPartItem[3],
            linkedField: linkFieldValue,
          };
        });
        let nonSerialsWithExistance: any[] = [];
        for (let i = 0;i < nonSerialArray.length; i += chunkSize) {
          const nonSerialChunk = nonSerialArray.slice(i, i + chunkSize);
          const existingParts: any = await await getNSPartExistanceBySerials.mutateAsync(nonSerialChunk);
          nonSerialsWithExistance = nonSerialsWithExistance.concat(existingParts);
        }

        setPart(nonSerialsWithExistance);
        setShowProgress(false);
        currentManifestedData = formatedManifestedData?.map((nsPartItem: Array<string>, index: number) => {
          return {
            id: index + 1,
            partType: nsPartItem[0],
            location: nsPartItem[1],
            conditionCode: nsPartItem[2],
            fleetTagName: nsPartItem[3],
            linkedField: nsPartItem[4],
            isExist: nonSerialsWithExistance.some((nsPart: any) =>
              nsPart.partType === nsPartItem[0] &&
              nsPart.location === nsPartItem[1] &&
              nsPart.conditionCode === nsPartItem[2] &&
              nsPart.fleetTagName === nsPartItem[3] &&
              nsPart.isExist
            )
          };
        });

        if (currentManifestedData?.length > 0 && manifestedData?.serialsDataSet?.length > 0) {
          const lastSerialId = manifestedData.serialsDataSet[manifestedData.serialsDataSet.length - 1].id || 0;
          let nextSerialId = lastSerialId + 1; // Initialize the next serial ID

          filteredSerials = [
            ...manifestedData.serialsDataSet,
            ...currentManifestedData.map((nsPartItem: any) => ({
              id: nextSerialId++, // Use the current value of nextSerialId and then increment it
              partType: nsPartItem['partType'],
              location: nsPartItem['location'],
              conditionCode: nsPartItem['conditionCode'],
              fleetTagName: nsPartItem['fleetTagName'],
              linkedField: nsPartItem['linkedField'],
              isExist: nsPartItem['isExist']
            })),
          ];
        } else {
          filteredSerials = currentManifestedData;
        }

        filteredSerials = filteredSerials.filter((filteredSerial: any) => Object.keys(filteredSerial).length > 4 && !!filteredSerial.id);

        setColumns([
          {
            field: 'partType',
            headerName: 'Part Type',
            flex: 1,
            cellClassName: (params: GridCellParams<string, any>) => {
              return clsx('super-app', {
                negative: !params.row.isExist,
                positive: params.row.isExist,
              });
            },
          },
          { field: 'location', headerName: 'Location', flex: 1 },
          { field: 'conditionCode', headerName: 'Condition Name', flex: 1 },
          { field: 'fleetTagName', headerName: 'Fleet Tag Name', flex: 1 },
          { field: 'linkedField', headerName: 'Linked Field', flex: 1 },
        ]);
      }

      // trigger when serialised data enter
      if (!!formatedManifestedData.length && formatedManifestedData[0].length <= 2) {
        setIsSerial(true);
        setShowProgress(true);
        // check serialised part existance by serials
        const serialArray = formatedManifestedData.map((sPartItem: Array<string>) => {
          let linkFieldValue = sPartItem[1];
          if(sPartItem[1]?.toUpperCase().startsWith(SearchCode.AM_LID)){
            linkFieldValue = sPartItem[1].replace(/\D/g,'');
          } else if(sPartItem[1]?.toUpperCase().startsWith(SearchCode.AM_PTID)){
            linkFieldValue = sPartItem[1].replace(/\D/g,'');
          }
          return {
            serial1: sPartItem[0],
            linkedField: linkFieldValue
          };
        });

        let serialsWithExistance: any[] = [];
        for (let i = 0;i < serialArray.length; i += chunkSize) {
          const serialChunk = serialArray.slice(i, i + chunkSize);
          const existingParts: any = await getPartExistanceBySerials.mutateAsync(serialChunk);
          serialsWithExistance = serialsWithExistance.concat(existingParts);
        }
        setPart(serialsWithExistance);

        setShowProgress(false);
        currentManifestedData = formatedManifestedData?.map((sPartItem: Array<string>, index: number) => {
          return {
            id: index + 1,
            serial1: sPartItem[0],
            linkedField: sPartItem[1],
            isExist: serialsWithExistance.some((serial: PartExistance) => serial.serial1 === sPartItem[0] && serial.isExist)
          };
        });

        if (!!currentManifestedData?.length && !!manifestedData?.serialsDataSet?.length) {
          const lastSerialId = manifestedData.serialsDataSet[manifestedData.serialsDataSet.length - 1]?.id || 0;
          let nextSerialId = lastSerialId + 1; // Initialize the next serial ID

          filteredSerials = [
            ...manifestedData.serialsDataSet,
            ...currentManifestedData.map((sPartItem: PartExistance) => ({
              id: nextSerialId++, // Use the current value of nextSerialId and then increment it
              serial1: sPartItem['serial1'],
              linkedField: sPartItem['linkedField'],
              isExist: sPartItem['isExist']
            })),
          ];
        } else {
          filteredSerials = currentManifestedData;
        }

        filteredSerials = filteredSerials.filter((filteredSerial: { id?: number }) => Object.keys(filteredSerial).length === 4 && !!filteredSerial.id);

        setColumns([
          {
            field: 'serial1',
            headerName: 'Serial1',
            flex: 1,
            cellClassName: (params: GridCellParams<string, any>) => {
              return clsx('super-app', {
                negative: !params.row.isExist,
                positive: params.row.isExist,
              });
            },
          },
          { field: 'linkedField', headerName: 'Linked Field', flex: 1 },
          { field: 'isExist', headerName: 'Is Exist', flex: 1 }
        ]);
      }

      const autoSelectIds: GridSelectionModel = filteredSerials
        .filter((item: { isExist: boolean }) => item.isExist === true)
        .map((item: { id: number}) => item.id);
      setSelectionModel(autoSelectIds);

      setManifestedData({
        ...manifestedData,
        serialsDataSet: filteredSerials,
        smartInput: ''
      });
    }
  };

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === 'Enter' && isPaste) {
      event.preventDefault();
      setIsEnabled(true);
      await loadData(manifestedData.smartInput);
    }
    setIsPaste(false);
  };

  const onChangePartManifestSerial = (partManifestSerialData: Array<PartManifestSerialList>) => {
    setPartManifestData({ ...partManifestData, serial: partManifestSerialData });

    const serial1Values = partManifestSerialData
      .filter((item) => item.isActive === true)
      .map((item) => item.serial1);

    const filteredIds = (manifestedData.serialsDataSet as PartExistance[])
      .filter((item: { serial1: string }) => serial1Values.includes(item.serial1))
      .map((item: { id: number }) => item.id);
    setSelectionModel(filteredIds);
  };

  const onChangePartManifestNonSerial = (partManifestNonSerialData: Array<PartManifestNonSerialList>) => {
    setPartManifestData({ ...partManifestData, nonSerial: partManifestNonSerialData });

    const values = partManifestNonSerialData
      .filter((item) => item.isActive === true)
      .map((item) => ({
        partType: item.partType,
        location: item.location,
        conditionCode: item.condition,
        fleetTagName: item.fleetTagName
      }));

    const filteredIds = (manifestedData.serialsDataSet as PartExistance[])
      .filter((item: any) => {
        const matchingValue = values.find((value) =>
          value.partType === item.partType &&
          value.location === item.location &&
          value.conditionCode === item.conditionCode &&
          value.fleetTagName === item.fleetTagName
        );
        return matchingValue !== undefined;
      })
      .map((item: { id: number }) => item.id);
    setSelectionModel(filteredIds);
  };

  const parseCSV = async (value: FileList) => {
    setShowProgress(true);
    const formData = new FormData();
    formData.append('fileList', value[0]);
    const processedData: any = await processBulkUpdateFile.mutateAsync(formData);

    if(processedData && processedData.data){
      setIsEnabled(true);
      setIsSerial(processedData.isSerial == true);
      setPart(processedData.data);
      if(processedData?.isSerial){
        setColumns([
          {
            field: 'serial1',
            headerName: 'Serial1',
            flex: 1,
            cellClassName: (params: GridCellParams<string, any>) => {
              return clsx('super-app', {
                negative: !params.row.isExist,
                positive: params.row.isExist,
              });
            },
          },
          { field: 'linkedField', headerName: 'Linked Field', flex: 1 },
          { field: 'isExist', headerName: 'Is Exist', flex: 1 }
        ]);
      }else{
        setColumns([
          {
            field: 'partType',
            headerName: 'Part Type',
            flex: 1,
            cellClassName: (params: GridCellParams<string, any>) => {
              return clsx('super-app', {
                negative: !params.row.isExist,
                positive: params.row.isExist,
              });
            },
          },
          { field: 'location', headerName: 'Location', flex: 1 },
          { field: 'conditionCode', headerName: 'Condition Name', flex: 1 },
          { field: 'fleetTagName', headerName: 'Fleet Tag Name', flex: 1 },
          { field: 'linkedField', headerName: 'Linked Field', flex: 1 },
        ]);
      }

      const autoSelectIds: GridSelectionModel = processedData.data
        .filter((item: { isExist: boolean }) => item.isExist === true)
        .map((item: { id: number }) => item.id);
      setSelectionModel(autoSelectIds);

      setManifestedData({
        ...manifestedData,
        serialsDataSet: processedData.data,
        smartInput: ''
      });
    }
    setShowProgress(false);
  };

  useEffect(() => {
    if (importDataSmartInput && manifestedData.smartInput) {
      const enterKeyEvent = new KeyboardEvent('keydown', { key: ENTER_LABEL });
      onKeyDown(enterKeyEvent);
      setImportDataSmartInput('');
    }
  }, [manifestedData.smartInput]);

  const onSelectionModelChange = (updatedSelectionModel: GridSelectionModel) => {
    setSelectionModel(updatedSelectionModel);
    if (isSerial) {
      const serial1Values = (manifestedData.serialsDataSet as PartExistance[])
        .filter(item => updatedSelectionModel.includes(item.id))
        .map(item => item.serial1);

      const partManifestSerialData = partManifestData.serial.map((item) => {
        if (serial1Values.includes(item.serial1)) {
          return { ...item, isActive: true };
        } else {
          return { ...item, isActive: false };
        }
      });
      setPartManifestData({ ...partManifestData, serial: partManifestSerialData });
    }
    else {
      const idValues = (manifestedData.serialsDataSet as NSPartExistance[])
        .filter(item => updatedSelectionModel.includes(item.id))
        .map(item => ({
          partType: item.partType,
          location: item.location,
          conditionCode: item.conditionCode,
          fleetTagName: item.fleetTagName
        }));

      const partManifestNonSerialData = partManifestData.nonSerial.map((item) => {
        const matchingItem = idValues.find((idValue) =>
          idValue.partType === item.partType &&
          idValue.location === item.location &&
          idValue.conditionCode === item.condition &&
          idValue.fleetTagName === item.fleetTagName
        );

        if (matchingItem) {
          return { ...item, isActive: true };
        } else {
          return { ...item, isActive: false };
        }
      });
      setPartManifestData({ ...partManifestData, nonSerial: partManifestNonSerialData });
    }
  };

  const partsUpdateSelectionValue = (value: SerialisedPartsUpdateSelectionDataType | NonSerialisedPartsUpdateSelectionDataType) => {
    setUpdateSelectionData(value);
  };

  const onClickSubmit = async () => {
    if (updateSelectionData.linkedFieldColumn === LinkedListProperties.ParentPartId) {
      updateSelectionData.linkedFieldColumn = LinkedListProperties.LocationId;
    }
    const partsToUpdate = isSerial ? partManifestData.serial : partManifestData.nonSerial;

    for (let i = 0;i < partsToUpdate.length; i += chunkSize) {
      const partsChunk = partsToUpdate.slice(i, i + chunkSize);
      await updateBulkParts.mutateAsync({
        partList: partsChunk,
        selectedPart: updateSelectionData,
        isSerial
      });
    }
    setBlockSerials(true);
    setOpenToast(true);
  };

  return (
    <PageContainer>
      <Wrapper>
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          Bulk Update
        </Typography>
        <Stack direction="row" spacing={6} justifyContent="center">
          <AdvanceLiveSearchWrapper>
            <AdvanceLiveSearchBox
              title={isPaste ? 'Part Enter' : 'Serialised Part Search'}
              timeOffset={400}
              disabled={blockSerials}
              value={manifestedData.smartInput || ''}
              onClearValue={() => {
                setManifestedData({ ...manifestedData, smartInput: '' });
                setIsPaste(false);
                setShowProgress(false);
              }}
              renderItem={(props: any, option: any) => {
                if (!isPaste) {
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }
              }}
              onChange={async (val: LocationNodeData) => { 
                setManifestedData({ ...manifestedData, smartInput: val.name });
                setIsPaste(false);
                setIsEnabled(true);
                await loadData(val.name);
              }}
              onTextChange={(event) => setManifestedData({ ...manifestedData, smartInput: event.target.value })}
              onApiInvoke={async (obj) => {
                return await searchPartsBulkUpdate.mutateAsync({
                  name: obj,
                });
              }}
              onPaste={() => {
                setIsPaste(true);
              }}
              onKeyDown={onKeyDown}
              isPaste={isPaste}
              showProgress={showProgress}
            />
          </AdvanceLiveSearchWrapper>
          {isImportData ? (
            <ImportWrapper>
              <Stack direction="column" spacing={1}>
                <CSVDownload
                  isButton={false}
                  targetObject={SAMPLE_BULK_SERIALIZED_PARTS}
                  fileName="BulkSerialUpdateCSV"
                  label="Download Serialize template CSV"
                />
                <CSVDownload
                  isButton={false}
                  targetObject={SAMPLE_BULK_NON_SERIALIZED_PARTS}
                  fileName="BulkNonSerialUpdateCSV"
                  label="Download Non-Serialize template CSV"
                />
                <UploadButton
                  fileTypes={['text/csv']}
                  maxFileSize={1024}
                  setFileDetails={parseCSV}
                />
              </Stack>
            </ImportWrapper>
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={isImportData}
                  disabled={blockSerials}
                  onChange={(event) => {
                    setIsImportData(event.target.checked);
                  }}
                />
              }
              label="Import Data"
              labelPlacement={FormControlLabelPlacementProps.End}
            />
          )}
        </Stack>
        <StyledBox>
          {manifestedData?.serialsDataSet && !!manifestedData?.serialsDataSet?.length && !!manifestedData.serialsDataSet[0].id && (
            <Stack direction="row" spacing={6} mt={4} justifyContent="center" alignItems="flex-start">
              <StyledScrollableWrapper>
                <DataGrid
                  columns={columns}
                  rows={manifestedData?.serialsDataSet}
                  disableColumnFilter
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  checkboxSelection
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        isExist: false,
                      },
                    },
                  }}
                  isRowSelectable={(params: GridRowParams) => params.row.isExist}
                  onSelectionModelChange={onSelectionModelChange}
                  selectionModel={selectionModel}
                />
              </StyledScrollableWrapper>
            </Stack>
          )}
        </StyledBox>
        {isEnabled &&
          <>
            <Stack spacing={0.5}>
              {isSerial && isUserHasPermission(ActionPermissions.Searialised_Part_Bulk_Update, permissions) &&
                <>
                  <Typography variant={TypographyVariantProps.Body1}>Manifest</Typography>
                  <PartManifestSerial partManifestSerialData={partManifestData.serial} isSerial={isSerial} partsUpdateSelectionValue={partsUpdateSelectionValue} onChange={onChangePartManifestSerial} />
                  <Stack alignItems="flex-end">
                    <Button variant={ButtonVariantProps.Primary} onClick={onClickSubmit}>Submit</Button>
                  </Stack>
                </>
              }
              {!isSerial && isUserHasPermission(ActionPermissions.Non_Searialised_Part_Bulk_Update, permissions) &&
                <>
                  <Typography variant={TypographyVariantProps.Body1}>Manifest</Typography>
                  <PartManifestNonSerial partManifestNonSerialData={partManifestData.nonSerial} onChange={onChangePartManifestNonSerial} />
                  <NonSerialisedPartsUpdateSelection nonSerialisedPartsUpdateSelectionValue={partsUpdateSelectionValue} />
                  <Stack alignItems="flex-end">
                    <Button variant={ButtonVariantProps.Primary} onClick={onClickSubmit}>Submit</Button>
                  </Stack>
                </>
              }
            </Stack>
            <Snackbar
              open={openToast}
              autoHideDuration={2000}
              message="Successfully Saved"
              onClose={() => setOpenToast(false)}
            />
          </>
        }
        {showProgress && <Grid ml="570px"><Loader color={LoaderColorProps.Primary} /></Grid>}
      </Wrapper>
    </PageContainer>
  );
};