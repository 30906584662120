import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { PopupDialog } from '../../../../../components/molecules/PopupDialog';
import { useGetPartTypeManifestById } from '../../../../../queries/part-type-query';
import { SerialPopupObject } from '../../../../../@types/shipment.type';
import { ManifestAttributesPopupProps } from './ManifestAttributesPopup.props';
import { GRID_SPACING } from '../../../../../configs/ui-constants';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { SizeProps } from '../../../../../@types';
import { NonSerialisedAttributesType } from '../../../../../components/organisms/AddStockManifestRowItem';
import { SAMPLE_NON_SERIALISED_ATTRIBUTES, SAMPLE_SERIAL_POPUP } from '../../../../../constants/shipment';
import { isDropDownEmpty } from '../../../../../utils/common';

export const ManifestAttributesPopup: React.FC<ManifestAttributesPopupProps> = ({
  openPopup,
  isEdit,
  attribute,
  partTypeId,
  index,
  closePopup,
  onChangeManifestAttribute
}) => {
  const [nonSerialisedAttributes, setNonSerialisedAttributes] = useState<NonSerialisedAttributesType>(SAMPLE_NON_SERIALISED_ATTRIBUTES);
  const [serialPopupItems, setSerialPopupItems] = useState<SerialPopupObject>(SAMPLE_SERIAL_POPUP);
  const [validate, setValidate] = useState(false);
  
  const getPartTypeManifestById = useGetPartTypeManifestById(partTypeId);

  useEffect(() => {
    attribute.attributes && setNonSerialisedAttributes(attribute.attributes);
  }, [attribute.attributes]);

  useEffect(() => {
    partTypeId && getPartTypeManifestById.refetch();
  }, [partTypeId]);

  useEffect(() => {
    getPartTypeManifestById.data && setSerialPopupItems({ ...getPartTypeManifestById.data, id: index });
  }, [getPartTypeManifestById.data]);

  const onSave = async () => {
    if (isDropDownEmpty(nonSerialisedAttributes.condition)) {
      setValidate(true);
      return;
    }

    onChangeManifestAttribute({ 
      ...attribute,
      attributes : {
        ...nonSerialisedAttributes,
        fleetTag: nonSerialisedAttributes.fleetTag === '-1' ? '' : nonSerialisedAttributes.fleetTag,
      }
    });
    closePopup(false);
  };

  const onClose = (event?: any, reason?: string) => {
    (reason !== 'backdropClick') && closePopup(false);
  };
  
  return (
    <PopupDialog
      maxWidth="1200px"
      title="Serials"
      isOpen={openPopup}
      onClose={onClose}
      onSave={onSave}
    >
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-condition"
            labelId="demo-simple-select-condition-label"
            label="Condition *"
            selectedValue={nonSerialisedAttributes.condition}
            onChange={(condition) => setNonSerialisedAttributes({ ...nonSerialisedAttributes, condition })}
            items={serialPopupItems.conditionOptions}
            validate={validate}
            optionalLabelEnabled={true}
          />
        </Grid>
        <Grid item xs={2.4}>
          <SelectMenu
            required={false}
            disabled={isEdit}
            size={SizeProps.Small}
            id="demo-simple-select-fleet-tag"
            labelId="demo-simple-select-fleet-tag-label"
            label="Fleet Tag"
            selectedValue={nonSerialisedAttributes.fleetTag || '-1'}
            onChange={(fleetTag) => setNonSerialisedAttributes({ ...nonSerialisedAttributes, fleetTag })}
            items={serialPopupItems?.fleetTagOptions}
            optionalLabelEnabled={true}
          />
        </Grid>
      </Grid>
    </PopupDialog>
  );
};