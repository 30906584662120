import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { TextField } from '../../atoms/TextField';
import { ModelProps } from './Model.props';
import { ColorProps } from '../../../@types';
import { Switch } from '../../atoms/Switch';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { ModelGridContainer, SwitchGrid } from '../Model/Model.styles';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { GRID_SPACING } from '../../../configs/ui-constants';

export const Model: React.FC<ModelProps> = ({
  onChange,
  error,
  helperText,
  modelData,
  multiSelectData,
  switchLabel,
}) => {
  const [isActive, setIsActive] = useState(modelData?.enabled);

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
    modelData && onChange && onChange({ ...modelData, enabled: event.target.checked });

  };
  return (
    <ModelGridContainer container spacing={GRID_SPACING}>
      <Grid item xs={2.5}>
        <TextField
          fullWidth
          error={error}
          helperText={helperText?.clientModelNameHelperText}
          label="Client Model Name"
          value={modelData?.clientModelName}
          onChange={(value) => modelData && onChange && onChange({ ...modelData, clientModelName: value })}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          fullWidth
          error={error}
          helperText={helperText?.clientModelNumberHelperText}
          label="Client Model Number"
          value={modelData?.clientModelNumber}
          onChange={(value) => modelData && onChange && onChange({ ...modelData, clientModelNumber: value })}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          fullWidth 
          error={error}
          helperText={helperText?.clientModelCustomerIdHelperText}
          label="Client Model Customer ID"
          value={modelData?.clientModelCustomerId}
          onChange={(value) => modelData && onChange && onChange({ ...modelData, clientModelCustomerId: value })}
        />
      </Grid>
      <Grid item xs={3.5}>
        <MultiSelectCheckmarksMenu
          label={multiSelectData.label}
          id={multiSelectData.id}
          items={multiSelectData.items}
          size={multiSelectData.size}
          selectedValue={modelData?.partType}
          helperText={multiSelectData.helperText}
          onChange={(value) => modelData && onChange && onChange({ ...modelData, partType: value.split(',') })}
        />
      </Grid>
      <SwitchGrid item xs={1}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              onChange={handleIsActiveChange}
              checked={isActive}
            />
          }
          label={switchLabel}
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </ModelGridContainer>
  );
};
