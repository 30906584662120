import React from 'react';
import * as Icons from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { ServiceTemplateListItemProps } from './ServiceTemplateListItem.props';
import { StyledStack, StyledTypography } from './ServiceTemplateListItem.styles';

export const ServiceTemplateListItem: React.FC<ServiceTemplateListItemProps> = ({
  data
}) => {
  const generateIcon = (name: string) => {
    const IconComponent = Icons[name as keyof typeof Icons];
    return <IconComponent color="primary"/>;
  };
  
  return (
    <StyledStack direction="row" alignItems="center">
      <Grid pl={2} pr={1} mt={1}>
        {generateIcon(data.icon)}
      </Grid> 
      <Stack direction="column" pl={1}>
        <Typography variant={TypographyVariantProps.Body2} fontWeight={TypographyFontWeightProps.Bold}>{data.name}</Typography>
        <StyledTypography variant={TypographyVariantProps.Body2} >{data.description}</StyledTypography>
      </Stack>
    </StyledStack>
  );
};
