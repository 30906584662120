import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { SupplierPricingProps } from './SupplierPricing.props';
import { SwitchGrid } from './SupplierPricing.styles';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps, LocationNodeData } from '../../../@types';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../LocationSearchItem';

export const SupplierPricing: React.FC<SupplierPricingProps> = ({
  value,
  multiline = true,
  onChange,
  recallSearchNodeQuery
}) => {
  const [textBoxClick, setTextBoxClick] = useState<boolean>(false);
  
  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <Grid item xs={1.7}>
        <LiveSearchBox
          title="Supplier Name *"
          multiline={multiline}
          timeOffset={400}
          value={value.nodeName}
          onClearValue={() => onChange({
            ...value,
            nodeId: NaN,
            nodeName: ''
          })}
          renderItem={(props: any, option: any) => {
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onClick={() => setTextBoxClick(true)}
          onChange={async (obj: LocationNodeData) => {
            onChange({
              ...value,
              nodeId: obj.id,
              nodeName: obj.name
            });
          }}
          onApiInvoke={async (name: string) => {
            if (textBoxClick && name) {
              return await recallSearchNodeQuery(name);
            } else {
              return [];
            }
          }}
        />
      </Grid>
      <Grid item xs={1.7}>
        <TextField
          fullWidth
          required
          type={TextFieldTypeProps.Number}
          label="Price Per Package"
          value={value.pricePerPackage}
          onChange={(text) => onChange({ ...value, pricePerPackage: text })}
        />
      </Grid>
      <Grid item xs={1.7}>
        <TextField
          fullWidth
          required
          type={TextFieldTypeProps.Number}
          label="Package Quantity"
          value={value.quantity}
          onChange={(text) => onChange({ ...value, quantity: text })}
        />
      </Grid>
      <Grid item xs={2.1}>
        <TextField
          fullWidth
          type={TextFieldTypeProps.String}
          label="Supplier Part Type Name"
          value={value.partTypeName}
          onChange={(text) => onChange({ ...value, partTypeName: text })}
        />
      </Grid>
      <Grid item xs={2.3}>
        <TextField
          fullWidth
          type={TextFieldTypeProps.String}
          label="Supplier Part Type Number"
          value={value.partTypeNumber}
          onChange={(text) => onChange({ ...value, partTypeNumber: text })}
        />
      </Grid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isDefault}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isDefault: event.target.checked })} />
          }
          label="Is Default"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
      <SwitchGrid item>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              checked={value.isActive}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, isActive: event.target.checked })} />
          }
          label="Is Enabled"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Grid>
  );
};
