import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridValueGetterParams, GridFilterModel, GridFeatureModeConstant, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Snackbar } from '@mui/material';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { Modal } from '../../../components/atoms/Modal';
import { SKUNonEditableData, SKURulePopupFragment, SuccessMessageValueType } from './SKURulePopupFragment';
import { SKUDataType } from '../../../@types/sku.type';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { useGetSKUConfigs } from '../../../queries/sku-config-query';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { useGetPartTypeCategories } from '../../../queries/part-type-category-query';
import { ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY, SUCCESS_MESSAGE_VALUE_TYPE } from '../../../constants/common';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { columnSelectionItems, SKU_NON_EDITABEL_DATA } from '../../../constants/sku';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { ListType } from '../../../@types';
import { formatDate } from '../../../utils/common';
import { PART_TYPE_CATEGORY_ARRAY } from '../../../constants/partType';

export const SKURuleList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [{ columnField: 'isActive', operatorValue: 'is', value: true }] });
  const [skuConfigs, setSKUConfigs] = useState<Array<SKUDataType>>([]);
  const [openAddEditSKUConfig, setOpenAddEditSKUConfig] = useState(false);
  const [skuConfigId, setSKUConfigId] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const [partTypeCategoryList, setPartTypeCategoryList] = useState<DropdownItem[]>();
  const [skuNonEditableData, setSkuNonEditableData] = useState<SKUNonEditableData>(SKU_NON_EDITABEL_DATA);
  const [openToast, setOpenToast] = useState<SuccessMessageValueType>(SUCCESS_MESSAGE_VALUE_TYPE);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const getAllSKUConfigs = useGetSKUConfigs({ filterModel, page, pageSize });
  const getAllPartTypeCategories = useGetPartTypeCategories();

  TabHandler(`List SKU Config | ${PLATFORM_NAME}`);

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      flex: 0.05,
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.id
    },    
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'clientContract',
      headerName: 'Client / Contract',
      flex: 0.35,
      valueGetter: (params: GridValueGetterParams) => params.row.clientContract
    },
    {
      field: 'serializationType',
      headerName: 'Serialisation Type',
      flex: 0.2,
      type: 'singleSelect',
      valueOptions: partTypeCategoryList || PART_TYPE_CATEGORY_ARRAY,
      valueGetter: (params: GridValueGetterParams) => params.row.serializationType
    },
    {
      field: 'isActive',
      headerName: 'Active',
      type: 'singleSelect',
      valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Yes' : 'No'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'date',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'date',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt)
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 50,
      flex: 0.1,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (<Grid>
        <IconButton aria-label="edit" onClick={() => {
          setSKUConfigId(params.row.id);
          setSkuNonEditableData({
            serialisation: params.row.serializationType,
            clientContract: params.row.clientContract
          });
          setOpenAddEditSKUConfig(true);
          setIsEdit(true);
        }}>
          <Edit />
        </IconButton>
      </Grid>)
    }
  ]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  useEffect(() => {
    getAllPartTypeCategories.refetch();
  }, []);

  // Get all partTypeCategories
  useEffect(() => {
    if (getAllPartTypeCategories.data) {
      const partTypeCategoriesItems: DropdownItem[] = [];
      getAllPartTypeCategories.data.map((item: any) => {
        const target = {
          value: item.name,
          label: item.name
        };
        partTypeCategoriesItems.push(target);
      });
      setPartTypeCategoryList(partTypeCategoriesItems);
    }
  }, [getAllPartTypeCategories.data]);


  useEffect(() => {
    getAllSKUConfigs.refetch();
  }, [page, pageSize, filterModel, sortModel]);

  useEffect(() => {
    getAllSKUConfigs.data && setSKUConfigs(getAllSKUConfigs.data.data || []);
  }, [getAllSKUConfigs.data]);

  const addNewRule = () => {
    setOpenAddEditSKUConfig(true);
    setIsEdit(false);
  }; 

  const handleClose = () => {
    setSKUConfigId(0);
    setOpenAddEditSKUConfig(false);
  };
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List SKU Config
          </Typography>
          <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addNewRule()}>
            ADD NEW RULE
          </Button>
        </InlineGroup>
        <GridContainer>
          <CustomDataGrid
            columns={columns}
            rows={skuConfigs}
            exportFileName={ListType.SkuConfigList}
            columnSelectionItems={columnSelectionItems}
            filterMode={GridFeatureModeConstant.server}
            filterModel={filterModel}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              onFilterChange(filterModel);
            }}
            onSortModelChange={handleSortModelChange}
            loading={getAllSKUConfigs.isLoading}
            rowCount={getAllSKUConfigs.data?.total || 0}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  parentNodeId: false,
                  referenceNumbers: false,
                  status: false,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true }
              }
            }}
            getRowHeight={() => 'auto'}
            setColumns={setColumns}
          />
          <Modal
            open={openAddEditSKUConfig}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SKURulePopupFragment
              isEdit={isEdit}
              skuNonEditableData={skuNonEditableData}
              skuConfigId={skuConfigId}
              onClose={handleClose}
              onSuccessMessage={(value) => {
                setOpenToast(value);
              }}
            />
          </Modal>
          <Snackbar
            open={openToast.isTrue}
            autoHideDuration={2000}
            message="Successfully Saved"
            onClose={() => { setOpenToast({ isTrue: false, message:'' }); }}
          />
        </GridContainer> 
      </Wrapper>
    </PageContainer>
  );
};