import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import { TaskTypeKey, TaskTypesKey } from '../configs/task';
import { createAndUpdateTask, createTaskServiceLogByApprove, deleteTaskById, getTaskByServiceId, getTaskTypes } from '../api/task';
import { TaskCard, TaskTypeCategory, TaskTypes } from '../@types/task.type';
import { ServiceLogAllKey } from '../configs/services';
import { UploadedFilesKey } from '../configs/uploaded-files';
import { Folders } from '../@types/uploaded-files.type';

export const useGetTaskByServiceId = (serviceId: number): UseQueryResult<TaskCard[]> => {
  return useQuery([TaskTypeKey, serviceId], () => getTaskByServiceId(serviceId), { enabled: false });
};

export const useCreateAndUpdateTask = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createAndUpdateTask(data);
  }, {
    onSuccess: async (response, taskData) => {
      await queryClient.invalidateQueries([TaskTypeKey, serviceId]);
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);

      if(response && taskData.taskTypeCategory === TaskTypeCategory.ServiceSpecial) {
        await queryClient.invalidateQueries([UploadedFilesKey, serviceId, Folders.ServiceSpecialFileAttachments]);
      }
    },
  });
};

export const useCreateTaskServiceLogByApprove = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createTaskServiceLogByApprove(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
    },
  });
};

export const useDeleteTaskById = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await deleteTaskById(data.id);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TaskTypeKey, serviceId]);
    },
  });
};

export const useGetTaskTypes = (serviceId: number): UseQueryResult<TaskTypes[]> => {
  return useQuery([TaskTypesKey, serviceId], () => getTaskTypes(serviceId), { enabled: false });
};