import React, { useEffect, useRef, useState } from 'react';
import { Alert, CardActions, Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../../../../components/atoms/TextField';
import { ImagePopup, ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionSHFData } from '../../../../../components/molecules/PartTypeActionSHFData';
import { PartTypeActionNote } from '../../../../../components/molecules/PartTypeActionNote';
import { PartTypeLocationItemData } from '../../../../../components/molecules/PartTypeLocationItem';
import { PartLocationSelectPopup } from '../../../../../components/organisms/PartLocationSelectPopup';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { useGetPartLocationDataByServiceId } from '../../../../../queries/part-query';
import { ActionPermissions, CustomPermission, DesiredSerialParentDataResponse, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { isEmptyString } from '../../../../../utils/common';
import { PartActionInstallCardProps } from './PartActionInstallCard.props';
import { 
  DefaultCard, 
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  SHFDataWrapper,
  StyledButton,
  SelectButton
} from './PartActionInstallCard.styles';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';
import { Action, PartActionType, PartActionValidateType } from '../../../../../@types/partAction.type';
import { partActionConditionValidation, partActionInValidations } from '../../../../../utils/part-action';
import { LOCATION_CANNOT_EMPTY } from '../../../../../constants/partAction';
import { COLORS } from '../../../../../configs/colors';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../components/atoms/LiveSearchBox';
import { PartTypeActionSearchItem } from '../../../../../components/molecules/PartTypeActionSearchItem';
import { useSearchDesiredParentsForSerial } from '../../../../../queries/live-search-query';

export const PartActionInstallCard: React.FC<PartActionInstallCardProps> = ({
  service,
  value,
  helperText,
  onChange,
  onSave,
  onExec,
  onClose,
  setHelperText
}) => {
  const [isHavingPermission, setIsHavingPermission] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [isSerialized, setIsSerialized] = useState(false);
  const [showSerialSearch, setShowSerialSearch] = useState(false);
  const [isSelectLocationPopup, setIsSelectLocationPopup] = useState(false);
  const [partImages, setPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [suggestedDesiredSerialParents, setSuggestedDesiredSerialParents] = useState<DesiredSerialParentDataResponse[]>([]);
  const [desiredParentsAvailable, setDesiredParentsAvailable] = useState(false);

  const uploadedImagesQuery = useGetUploadedImages(value.partTypeId || 0, Folders.PartTypesImages);
  const partLocationDataByServiceIdQuery = useGetPartLocationDataByServiceId(value.partTypeId || 0, service.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchDesiredParentsForSerial = useSearchDesiredParentsForSerial();

  const inputRef: any = useRef(null);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    setIsHavingPermission(isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions));
  }, [permissions]);

  useEffect(() => {
    setIsSearchMode(isEmptyString(value.name));
    setIsSerialized(value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    value.partTypeId && uploadedImagesQuery.refetch();
  }, [value.name, value.category, value.partTypeId]);

  useEffect(() => {
    setShowSerialSearch(value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
  }, [value.category]);

  useEffect(() => {
    if (uploadedImagesQuery.data) {
      const images: Array<ImagePopupSlide> = uploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setPartImages(images);
    }
  }, [uploadedImagesQuery.data]);

  useEffect(() => {
    desiredParentsAvailable && inputRef.current?.focus();
  }, [desiredParentsAvailable]);

  const handlePartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => {
    if (liveSearchSelectedItem.isPartCreation){
      onChange({
        newPartSerial: liveSearchSelectedItem.name,
        isPartCreation: true,
        action: Action.IN,
        partActionType: PartActionType.INSTALL,
        outValue: value,
        inValue: value,
        value: value
      });
    } else {
      setHelperText({
        ...helperText,
        in: { 
          ...helperText.in,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });
  
      if (liveSearchSelectedItem.serial1) {
        onChange({
          outValue: value,
          inValue: value,
          value: {
            ...value,
            id: value.id,
            partTypeId: liveSearchSelectedItem.partTypeId,
            partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: liveSearchSelectedItem.serial1,
            location: liveSearchSelectedItem.locationName,
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName,
            serviceId: liveSearchSelectedItem.serviceId,
            isInSiblingHierarchy: liveSearchSelectedItem.isInSiblingHierarchy,
            isAccepted: true
          }  
        });
        const clientContractIds: Array<number> = [service?.clientId || 0, service?.contractId || 0];
        const results = await searchDesiredParentsForSerial.mutateAsync({
          partTypeId: liveSearchSelectedItem.partTypeId,
          clientContractIds: clientContractIds,
          locationId: service.subLocationNodeId || service.mainLocationNodeId
        });
        setSuggestedDesiredSerialParents(results);
        setDesiredParentsAvailable(!!results?.length);
      } else {
        onChange({
          outValue: value,
          inValue: value,
          value: {
            ...value,
            id: value.id,
            partTypeId: liveSearchSelectedItem.partTypeId,
            ns_partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            location: liveSearchSelectedItem.locationName,
            serial: '',
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName,
            isAccepted: true
          }       
        });
      }
    }
  };


  const handlePartTypeSerialSearch = async(val: PartTypeActionLiveSearchItem) => {
    if (val.isPartCreation) {
      onChange({
        newPartSerial: val.name,
        isPartCreation: true,
        action: Action.IN,
        partActionType: PartActionType.INSTALL,
        outValue: value,
        inValue: value,
        value: value
      });
    } else {
      setHelperText({
        ...helperText,
        in: {
          ...helperText.in,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });

      onChange({
        outValue: value,
        inValue: value,
        value: {
          ...value,
          partId: val.partId,
          partTypeId: val.partTypeId,
          ns_partId: NaN,
          serial: val.serial1,
          location: val.locationName,
          condition: val.conditionCode,
          conditionName: val?.partCondition?.name,
          sw: val.softwareVersion,
          hw: val.hardwareVersion,
          fw: val.firmwareVersion,
          ft: val.fleetTagName,
          serviceId: val.serviceId,
          isInSiblingHierarchy: val.isInSiblingHierarchy
        }
      });
      const clientContractIds: Array<number> = [service?.clientId || 0, service?.contractId || 0];
      const results = await searchDesiredParentsForSerial.mutateAsync({
        partTypeId: val.partTypeId,
        clientContractIds: clientContractIds,
        locationId: service.subLocationNodeId || service.mainLocationNodeId
      });
      setSuggestedDesiredSerialParents(results);
      setDesiredParentsAvailable(!!results?.length);
    }
  };

  const handlePartTypeLocationSelect = (val: PartTypeLocationItemData) => {
    setHelperText({
      ...helperText,
      in: { 
        ...helperText.in,
        locationHelperText: ''
      },
    });
    
    onChange({
      outValue: value,
      inValue: value,
      value:{
        ...value,
        partId: NaN,
        serial: '',
        ns_partId: val.id,
        location: val.locationName,
        condition: val.conditionCode,
        conditionName: val?.partCondition?.name,
        ft: val.fleetTagName
      }
    });
    setIsSelectLocationPopup(false);
  };

  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>Install</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} width="100%" marginTop={1}>
            <Stack direction="column" spacing={2} width="100%">
              <PartTypeActionSearch
                title={showSerialSearch ? 'Part Type Search' : 'Part/Part Type Input'}
                serviceData={service}
                locationId={service.subLocationNodeId || service.mainLocationNodeId}
                value={value?.name || ''}
                partActionType={PartActionType.INSTALL}
                error={!!helperText.in.partTypeHelperText}
                helperText={helperText.in.partTypeHelperText}
                onChange={handlePartTypeActionSearch}
                searchType={showSerialSearch ? 'part-type' : 'part-and-part-type'}
                IsPartCreationEnabled={showSerialSearch ? false : true}
              />
              {showSerialSearch &&
                <PartTypeActionSearch
                  title="Part Serial Search"
                  serialiseType={value.category}
                  locationId={service.subLocationNodeId || service.mainLocationNodeId}
                  partTypeId={`${value.partTypeId}`}
                  serviceData={service}
                  value={value.serial || ''}
                  partActionType={PartActionType.INSTALL}
                  error={!!helperText.in.partSerialHelperText}
                  helperText={helperText.in.partSerialHelperText}
                  onChange={handlePartTypeSerialSearch}
                  searchType="part"
                />
              }
            </Stack>
            {isSearchMode || <ImagePopup title="Photos" images={partImages} />}
          </Stack>
          {isSearchMode || 
            <>
              {isSerialized ||
                <>
                  <SelectButton 
                    variant={ButtonVariantProps.Secondary} 
                    color={ButtonColorProps.Success}
                    onClick={() => setIsSelectLocationPopup(true)}
                  >
                    Select Location
                  </SelectButton>
                  {!!helperText.in.locationHelperText && 
                    <Grid item xs={12}>
                      <Alert severity="error">{LOCATION_CANNOT_EMPTY}</Alert>
                    </Grid>
                  }
                </>
              }
              {isSerialized ?
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value.sw || '_'} hw={value.hw || '_'} fw={value.fw || '_'} />
                </SHFDataWrapper>
                :
                <TextField 
                  label="Qty"
                  type={TextFieldTypeProps.Number}
                  value={value.qty}
                  inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(val) => onChange({  
                    outValue: value,
                    value: {
                      ...value,
                      qty: Number(val)
                    },
                    inValue: value 
                  })}
                />
              }
              {value.condition && 
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    COND:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.conditionName || value.condition}</Typography>
                </Stack>
              }
              {value.location &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    LOC:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.location}</Typography>
                </Stack>
              }
              {value.ft &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    FT:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.ft}</Typography>
                </Stack>
              }
              {desiredParentsAvailable || value?.desiredParentSerial ?
                <LiveSearchBox
                  inputRef={inputRef}
                  title={'Select Desired Parent'}
                  timeOffset={400} 
                  value={value.desiredParentSerial || ''}
                  feature="part-action"
                  defaultSuggetions={suggestedDesiredSerialParents}
                  disableDelayedInput
                  onChange={(serialPart) => onChange({
                    outValue: value,
                    inValue: value,
                    value:{
                      ...value,
                      desiredParentSerial: serialPart.serial1,
                      desiredParentId: serialPart.partId,
                      desiredParentLocationId: serialPart.locationId,
                      locationTypeCode: 'part'
                    }
                  })}
                  onKeyDown={async(event) => {
                    suggestedDesiredSerialParents.length && setSuggestedDesiredSerialParents([]);
                    if (event.key === 'Enter') {
                      const clientContractIds: Array<number> = [service?.clientId || 0, service?.contractId || 0];
                      const results = await searchDesiredParentsForSerial.mutateAsync({
                        serial: (event.target as HTMLInputElement).value,
                        partTypeId: value.partTypeId,
                        clientContractIds: clientContractIds,
                        locationId: service.subLocationNodeId || service.mainLocationNodeId
                      });
                      results?.length && onChange({
                        outValue: value,
                        inValue: value,
                        value:{
                          ...value,
                          desiredParentSerial: results[0]?.serial1,
                          desiredParentId: results[0]?.partId,
                          desiredParentLocationId: results[0]?.locationId,
                          locationTypeCode: 'part'
                        }
                      });
                    }
                  }}
                  onClearValue={async() => {
                    setDesiredParentsAvailable(true);
                    onChange({
                      outValue: value,
                      inValue: value,
                      value:{
                        ...value,
                        desiredParentSerial: undefined,
                        desiredParentId: undefined,
                        desiredParentLocationId: undefined,
                        locationTypeCode: undefined
                      }
                    });
                    const clientContractIds: Array<number> = [service?.clientId || 0, service?.contractId || 0];
                    const results = await searchDesiredParentsForSerial.mutateAsync({
                      partTypeId: value.partTypeId,
                      clientContractIds: clientContractIds,
                      locationId: service.subLocationNodeId || service.mainLocationNodeId
                    });
                    setSuggestedDesiredSerialParents(results);
                  }}
                  renderItem={(props: any, option: PartTypeActionLiveSearchItem) => { 
                    return (
                      <LiveSearchListItem {...props}>
                        <PartTypeActionSearchItem 
                          name={option.partTypeName}
                          serial={option.serial1}
                          partTypeId={option.partTypeId}
                          partId={option?.partId?.toString() || ''}
                          nsPartId={option?.ns_partId?.toString() || ''}
                          category={option.categoryCode}
                        />
                      </LiveSearchListItem>
                    ); }}
                  onApiInvoke={async (searchValue: string) => {
                    const clientContractIds: Array<number> = [service?.clientId || 0, service?.contractId || 0];
                    const results = await searchDesiredParentsForSerial.mutateAsync({
                      serial: searchValue,
                      partTypeId: value.partTypeId,
                      clientContractIds: clientContractIds,
                      locationId: service.subLocationNodeId || service.mainLocationNodeId
                    });
                    return results;
                  }}
                />
                :
                null
              }
              <Grid item xs={12}>
                <PartTypeActionNote
                  value={value?.notes || ''}
                  onChange={(note) => onChange({
                    outValue: value,
                    inValue: value,
                    value:{
                      ...value,
                      notes: note
                    }       
                  })}
                />
              </Grid>
              {value.condition && value.serial && partActionConditionValidation(PartActionValidateType.INSTALL, value.condition, value.serial) &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionConditionValidation(PartActionValidateType.INSTALL, value.condition, value.serial)}</Alert>
                </Grid>
              }
              {value.serviceId && service.id !== value.serviceId &&
                <Grid item xs={12}>
                  <Alert severity="warning">Warning: This part is marked as Reseved for Service ID {value.serviceId}.</Alert>
                </Grid>
              }
              {value.isInSiblingHierarchy === false && value.serial && partActionInValidations(value?.condition || '', value.serial || '', value?.location || '') &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionInValidations(value?.condition || '', value.serial || '', value?.location || '')}</Alert>
                </Grid>
              }
              {!value.isAccepted &&
                <Grid item xs={12}>
                  <Alert severity="warning">Exception: This part has not been reviewed and accepted by the Stock Controller. Please seek assistance prior to usage in this Service</Alert>
                </Grid>
              }
            </>
          }
        </Stack>
      </StyledCardContent>
      {isHavingPermission && (isSearchMode ||
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Error}
              disabled={!value.isAccepted}
              onClick={onExec}
            >
                Exec
            </StyledButton>
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {value.actionId ? 'Update' : 'Save'}
            </StyledButton>
          </Stack>
        </CardActions>
      )}
      <PartLocationSelectPopup
        open={isSelectLocationPopup}
        options={partLocationDataByServiceIdQuery.data || []}
        onSelect={handlePartTypeLocationSelect}
        onClose={() => setIsSelectLocationPopup(false)}
      />
    </DefaultCard>
  );
};