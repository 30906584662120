import { Box, Divider, styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { Button } from '../../atoms/Button';
import { TextField } from '../../atoms/TextField';

export const ContentPaper = styled(Box)`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: whitesmoke;
`;

export const OffsiteTimeText = styled(Typography)`
  width: 60px;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: whitesmoke!important;
`;

export const NotesText = styled(TextField)`
  flex-grow: 1;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;