import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Fab, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button as MaterialButton } from '@mui/material';

export const MainBox = styled(Box)`
  width: 650px;
`;

export const MainImageBox = styled('img')`
  width: 300px;
  height: 300px;
`;

export const ImageBox = styled('img')`
  width: 200px;
  height: 200px;
`;

export const CloseButton = styled(Fab)`
  right:0px;
  position: absolute;
  transform: scale(0.5);
`;

export const StyledGrid = styled(Grid)`
  position: relative;
`;

export const StyledImageListGrid = styled(Grid)`
  position: relative;
  max-height: 700px;
  overflow: auto;
`;

export const Td = styled('td')`
  vertical-align: top;
`;

export const MenuIcon = styled(MoreHorizIcon)`
  font-size: 32px;
  color: white;
`;

export const MenuButton = styled(MaterialButton)`
  color: gray;
`;

export const StyledButton = styled(Button)`
  color: white;
`;

export const StyledImage = styled('img')`
  max-width: 100vh;  
  max-height: 100vh;  
  text-align: center;
  align-items: center;
  justify-content: center;
`;