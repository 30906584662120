import { DropdownItem } from '../components/atoms/SelectMenu';
import { 
  FormConditionOption, 
  FormConditionCompareOption, 
  FormConditionJoinOption ,
  FormFieldOption,
  QuestionObject,
  ModifyStructureFormToggleScopeOption,
  ModifyStructureFormModifyStructureType,
  ActionTriggerFormActionType,
  ActionTriggerFormActionValueOptions,
  ParameterDataFormParameterType,
  ParameterUIDataFormPartAttributes,
  ParameterUIDataFormPartMovementType,
  ParameterNoUIDataFormParameterType,
  ParameterNoUIDataFormSLAOption,
  ParameterNoUIDataFormSLAType,
  ParameterNoUIDataFormSLATypeCode,
  ParameterNoUIDataFormPartAttribute,
  ParameterNoUIDataFormPartMovementType,
  GetParameterOptions
} from '../@types';

export const FORM_BUILDER_CONDITION_OPTIONS: Array<DropdownItem> = [
  {
    label: FormConditionOption.BillableType,
    value: FormConditionOption.BillableType
  },
  {
    label: FormConditionOption.ObservedFaultName,
    value: FormConditionOption.ObservedFaultName
  },
  {
    label: FormConditionOption.ObservedFaultDescription,
    value: FormConditionOption.ObservedFaultDescription
  },
  {
    label: FormConditionOption.ReportedFaultName,
    value: FormConditionOption.ReportedFaultName
  },
  {
    label: FormConditionOption.ReportedFaultDescription,
    value: FormConditionOption.ReportedFaultDescription
  },
  {
    label: FormConditionOption.PrimaryAllocation,
    value: FormConditionOption.PrimaryAllocation
  },
  {
    label: FormConditionOption.SecondaryAllocation,
    value: FormConditionOption.SecondaryAllocation
  },
  {
    label: FormConditionOption.MostRecentPartAction,
    value: FormConditionOption.MostRecentPartAction
  },
  {
    label: FormConditionOption.MostRecentPartActionPartTypeName,
    value: FormConditionOption.MostRecentPartActionPartTypeName
  },
  {
    label: FormConditionOption.ServiceReferenceNo1,
    value: FormConditionOption.ServiceReferenceNo1
  },
  {
    label: FormConditionOption.ServiceReferenceNo2,
    value: FormConditionOption.ServiceReferenceNo2
  },
  {
    label: FormConditionOption.ServiceReferenceNo3,
    value: FormConditionOption.ServiceReferenceNo3
  },
  {
    label: FormConditionOption.SiblingReferenceNo1,
    value: FormConditionOption.SiblingReferenceNo1
  },
  {
    label: FormConditionOption.SiblingReferenceNo2,
    value: FormConditionOption.SiblingReferenceNo2
  },
  {
    label: FormConditionOption.SiblingReferenceNo3,
    value: FormConditionOption.SiblingReferenceNo3
  }
];

export const FORM_BUILDER_COMPARE_OPTIONS: Array<DropdownItem> = [
  {
    label: FormConditionCompareOption.Equal,
    value: FormConditionCompareOption.Equal
  },
  {
    label: FormConditionCompareOption.Wildcard,
    value: FormConditionCompareOption.Wildcard
  }
];

export const FORM_BUILDER_JOIN_OPTIONS: Array<DropdownItem> = [
  {
    label: FormConditionJoinOption.None,
    value: FormConditionJoinOption.None
  },
  {
    label: FormConditionJoinOption.AND,
    value: FormConditionJoinOption.AND
  },
  {
    label: FormConditionJoinOption.OR,
    value: FormConditionJoinOption.OR
  }
];

export const DECISION_PROMPT_OPTIONS: Array<FormFieldOption> = [
  {
    id: 1,
    title: 'Yes',
    value: 'Yes',
    isActive: true
  },
  {
    id: 2,
    title: 'No',
    value: 'No',
    isActive: true
  }
];

export const QUESTION_DATA_INITIAL_VALUE: QuestionObject = {
  id: 1,
  questionText: '',
  isYes: false,
  hasValue: false
};

export const MODIFY_STRUCTURE_TYPE_OPTIONS: Array<DropdownItem> = [
  {
    label:  'Create Location',
    value: ModifyStructureFormModifyStructureType.CreateLocation
  },
  {
    label: 'Toggle Active',
    value: ModifyStructureFormModifyStructureType.ToggleActive
  }
];

export const MODIFY_STRUCTURE_FORM_TOGGLE_SCOPE_OPTIONS: Array<DropdownItem> = [
  {
    label: 'Sublocation only',
    value: ModifyStructureFormToggleScopeOption.SublocationOnly
  },
  {
    label: 'Sublocation, and Parent if empty',
    value: ModifyStructureFormToggleScopeOption.SublocationAndParentIfEmpty
  },
  {
    label: 'Service Location and Sublocation',
    value: ModifyStructureFormToggleScopeOption.ServiceLocationAndSublocation
  },
  {
    label: 'Service Location and All Children',
    value: ModifyStructureFormToggleScopeOption.ServiceLocationAndAllChildren
  }
];

export const ACTION_TRIGGER_FORM_ACTION_TYPE_OPTIONS: Array<DropdownItem> = [
  {
    label: ActionTriggerFormActionType.PartAction,
    value: ActionTriggerFormActionType.PartAction
  },
  {
    label: ActionTriggerFormActionType.Task,
    value: ActionTriggerFormActionType.Task
  },
  {
    label: ActionTriggerFormActionType.PrePopulatedPartActions,
    value: ActionTriggerFormActionType.PrePopulatedPartActions
  }
];

export const ACTION_TRIGGER_FORM_ACTION_VALUE_OPTIONS: Array<DropdownItem> = [
  {
    label: ActionTriggerFormActionValueOptions.AllPartsAtThisNode,
    value: ActionTriggerFormActionValueOptions.AllPartsAtThisNode
  },
  {
    label: ActionTriggerFormActionValueOptions.AllPartsAtThisNodeAndChildren,
    value: ActionTriggerFormActionValueOptions.AllPartsAtThisNodeAndChildren
  },
  {
    label: ActionTriggerFormActionValueOptions.AllPartsInThisNodeGroup,
    value: ActionTriggerFormActionValueOptions.AllPartsInThisNodeGroup
  },
  {
    label: ActionTriggerFormActionValueOptions.SiteSchema,
    value: ActionTriggerFormActionValueOptions.SiteSchema
  }
];

export const PARAMETER_FORM_TYPE_PARAMETER_OPTIONS: Array<DropdownItem> = [
  {
    label: ParameterDataFormParameterType.Status,
    value: ParameterDataFormParameterType.Status
  },
  {
    label: ParameterDataFormParameterType.PrimaryAllocation,
    value: ParameterDataFormParameterType.PrimaryAllocation
  },
  {
    label: ParameterDataFormParameterType.SecondaryAllocation,
    value: ParameterDataFormParameterType.SecondaryAllocation
  },
  {
    label: ParameterDataFormParameterType.ScheduledDate,
    value: ParameterDataFormParameterType.ScheduledDate
  },
  {
    label: ParameterDataFormParameterType.SetTag,
    value: ParameterDataFormParameterType.SetTag
  },
  {
    label: ParameterDataFormParameterType.UpdateSubLocation,
    value: ParameterDataFormParameterType.UpdateSubLocation
  },
  {
    label: ParameterDataFormParameterType.ReportedSerial,
    value: ParameterDataFormParameterType.ReportedSerial
  },
  {
    label: ParameterDataFormParameterType.Part,
    value: ParameterDataFormParameterType.Part
  }
];

export const PARAMETER_UI_FORM_PART_ATTRIBUTES: Array<DropdownItem> = [
  {
    label: ParameterUIDataFormPartAttributes.PartLocation,
    value: ParameterUIDataFormPartAttributes.PartLocation
  },
  {
    label: ParameterUIDataFormPartAttributes.PartCondition,
    value: ParameterUIDataFormPartAttributes.PartCondition
  },
  {
    label: ParameterUIDataFormPartAttributes.PartSoftware,
    value: ParameterUIDataFormPartAttributes.PartSoftware
  },
  {
    label: ParameterUIDataFormPartAttributes.PartFirmware,
    value: ParameterUIDataFormPartAttributes.PartFirmware
  },
  {
    label: ParameterUIDataFormPartAttributes.PartHardware,
    value: ParameterUIDataFormPartAttributes.PartHardware
  },
  {
    label: ParameterUIDataFormPartAttributes.PartFleetTag,
    value: ParameterUIDataFormPartAttributes.PartFleetTag
  }
];

export const PARAMETER_UI_FORM_PART_MOVEMENT_TYPES: Array<DropdownItem> = [
  {
    label: ParameterUIDataFormPartMovementType.In,
    value: ParameterUIDataFormPartMovementType.In
  },
  {
    label: ParameterUIDataFormPartMovementType.Out,
    value: ParameterUIDataFormPartMovementType.Out
  }
];

export const PARAMETER_NO_UI_FORM_TYPE_PARAMETER_OPTIONS: Array<DropdownItem> = [
  {
    label: ParameterNoUIDataFormParameterType.Billable,
    value: ParameterNoUIDataFormParameterType.Billable
  },
  {
    label: ParameterNoUIDataFormParameterType.SLA,
    value: ParameterNoUIDataFormParameterType.SLA
  },
  {
    label: ParameterNoUIDataFormParameterType.Part,
    value: ParameterNoUIDataFormParameterType.Part
  }
];

export const PARAMETER_NO_UI_FORM_SLA_OPTIONS: Array<DropdownItem> = [
  {
    label: ParameterNoUIDataFormSLAOption.Met,
    value: ParameterNoUIDataFormSLAOption.Met.toLowerCase()
  },
  {
    label: ParameterNoUIDataFormSLAOption.Reviewed,
    value: ParameterNoUIDataFormSLAOption.Reviewed.toLowerCase()
  },
  {
    label: ParameterNoUIDataFormSLAOption.Mitigated,
    value: ParameterNoUIDataFormSLAOption.Mitigated.toLowerCase()
  }
];

export const PARAMETER_NO_UI_FORM_SLA_TYPES: Array<DropdownItem> = [
  {
    label: ParameterNoUIDataFormSLAType.Any,
    value: ParameterNoUIDataFormSLATypeCode.Any
  },
  {
    label: ParameterNoUIDataFormSLAType.Client,
    value: ParameterNoUIDataFormSLATypeCode.Client
  },
  {
    label: ParameterNoUIDataFormSLAType.OpsField,
    value: ParameterNoUIDataFormSLATypeCode.OpsField
  },
  {
    label: ParameterNoUIDataFormSLAType.Logistics,
    value: ParameterNoUIDataFormSLATypeCode.Logistics
  }
];

export const PARAMETER_NO_UI_FORM_PART_ATTRIBUTES: Array<DropdownItem> = [
  {
    label: ParameterNoUIDataFormPartAttribute.Condition,
    value: ParameterNoUIDataFormPartAttribute.Condition
  },
  {
    label: ParameterNoUIDataFormPartAttribute.FleetTag,
    value: ParameterNoUIDataFormPartAttribute.FleetTag
  }
];

export const PARAMETER_NO_UI_FORM_PART_MOVEMENT_TYPES: Array<DropdownItem> = [
  {
    label: ParameterNoUIDataFormPartMovementType.In,
    value: ParameterNoUIDataFormPartMovementType.In
  },
  {
    label: ParameterNoUIDataFormPartMovementType.Out,
    value: ParameterNoUIDataFormPartMovementType.Out
  },
  {
    label: ParameterNoUIDataFormPartMovementType.Swap,
    value: ParameterNoUIDataFormPartMovementType.Swap
  }
];

export const GET_PARAMETER_OPTIONS: Array<DropdownItem> = [
  {
    label: GetParameterOptions.ServiceNodeReference1,
    value: GetParameterOptions.ServiceNodeReference1
  },
  {
    label: GetParameterOptions.ServiceNodeReference2,
    value: GetParameterOptions.ServiceNodeReference2
  },
  {
    label: GetParameterOptions.ServiceNodeReference3,
    value: GetParameterOptions.ServiceNodeReference3
  },
  {
    label: GetParameterOptions.ServiceSubLocNodeReference1,
    value: GetParameterOptions.ServiceSubLocNodeReference1
  },
  {
    label: GetParameterOptions.ServiceSubLocNodeReference2,
    value: GetParameterOptions.ServiceSubLocNodeReference2
  },
  {
    label: GetParameterOptions.ServiceSubLocNodeReference3,
    value: GetParameterOptions.ServiceSubLocNodeReference3
  },
  {
    label: GetParameterOptions.SiblingReference1,
    value: GetParameterOptions.SiblingReference1
  },
  {
    label: GetParameterOptions.SiblingReference2,
    value: GetParameterOptions.SiblingReference2
  },
  {
    label: GetParameterOptions.SiblingReference3,
    value: GetParameterOptions.SiblingReference3
  },
  {
    label: GetParameterOptions.ClientReference1,
    value: GetParameterOptions.ClientReference1
  },
  {
    label: GetParameterOptions.ClientReference2,
    value: GetParameterOptions.ClientReference2
  },
  {
    label: GetParameterOptions.ClientReference3,
    value: GetParameterOptions.ClientReference3
  },
  {
    label: GetParameterOptions.UserFirstLastName,
    value: GetParameterOptions.UserFirstLastName
  },
  {
    label: GetParameterOptions.LastINPartActionPartTypeName,
    value: GetParameterOptions.LastINPartActionPartTypeName
  },
  {
    label: GetParameterOptions.LastINPartActionPartTypeNo,
    value: GetParameterOptions.LastINPartActionPartTypeNo
  },
  {
    label: GetParameterOptions.LastINPartActionPartSerialNo,
    value: GetParameterOptions.LastINPartActionPartSerialNo
  },
  {
    label: GetParameterOptions.LastOUTPartActionPartTypeName,
    value: GetParameterOptions.LastOUTPartActionPartTypeName
  },
  {
    label: GetParameterOptions.LastOUTPartActionPartTypeNo,
    value: GetParameterOptions.LastOUTPartActionPartTypeNo
  },
  {
    label: GetParameterOptions.LastOUTPartActionPartSerialNo,
    value: GetParameterOptions.LastOUTPartActionPartSerialNo
  }
];