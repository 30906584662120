export const state = [
  { name: 'Victoria', id: 1 },
  { name: 'New South Wales', id: 2 },
  { name: 'Queensland', id: 3 },
];
  
export const country = [
  { name: 'Australia', id: 1 },
  { name: 'New Zealand', id: 2 },
  { name: 'United States', id: 3 }
];
  
export const contactType = [
  { label: 'Main', value: 'Main' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Alternative', value: 'Alternative' },
  { label: 'Technical', value: 'Technical' },
  { label: 'Management', value: 'Management' },
  { label: 'Notification', value: 'Notification' }
];

export const stateType = [
  { name: 'Victoria', id: 1 },
  { name: 'New South Wales', id: 2 },
  { name: 'Queensland', id: 3 },
];

export const countryType = [
  { name: 'Australia', id: 1 },
  { name: 'New Zealand', id: 2 },
  { name: 'United States', id: 3 },
];