import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import {
  SwitchGrid,
  StyledFormControlLabel,
} from './PriorityAndService.styles';
import { TextField } from '../../atoms/TextField';
import { ColorProps, SizeProps } from '../../../@types';
import { PriorityAndServiceProps } from './PriorityAndService.props';
import { ColorSelector } from '../../molecules/ColorSelector';
import { Switch } from '../../atoms/Switch';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { defaultColors } from '../../../configs/colors';
import { ItemBox } from '../../molecules/ColorSelector/ColorSelector.styles';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { ALL_KEYWORD } from '../../../constants/common';

export const PriorityAndService: React.FC<PriorityAndServiceProps> = ({
  serviceTypes: serviceType,
  onChange,
  error,
  helperText,
  serviceData,
  serviceCreationTypes: serviceCreationType,
  colors = defaultColors
}) => {
  const [isActive, setIsActive] = useState(serviceData?.isActive ? serviceData?.isActive : false);
  const [isRestricted, setIsRestricted] = useState(serviceData?.isRestricted ? serviceData?.isRestricted : false);
  const [selectedColor, setSelectedColor] = useState(serviceData?.color ? serviceData?.color : colors[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIsRestrictedServiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsRestricted(event.target.checked);
    serviceData && onChange({ ...serviceData, isRestricted: event.target.checked });
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
    serviceData && onChange({ ...serviceData, isActive: event.target.checked });
  };

  const onChangeSelectedColor = (color: string) => {
    setSelectedColor(color);
    serviceData && onChange({ ...serviceData, color: color });
  };

  return (
    <Grid container spacing={GRID_SPACING} pt={1}>
      <Grid item xs={2}>
        <TextField
          fullWidth
          required
          error={error}
          label="Short Name"
          helperText={helperText?.shortNameHelperText}
          value={serviceData?.shortName}
          onChange={(value) => {
            serviceData && onChange({ ...serviceData, shortName: value });
          }}
        />
      </Grid>
      <Grid item xs={2} mr={1}>
        <TextField
          fullWidth
          required
          error={error}
          label="Description"
          helperText={helperText?.descriptionHelperText}
          value={serviceData?.description}
          onChange={(value) => {
            serviceData && onChange({ ...serviceData, description: value });
          }}
        />
      </Grid>
      <StyledFormControlLabel control={
        <Grid item mt="-5px">
          <ColorSelector
            open={open} 
            handleClose={handleClose} 
            anchorEl={anchorEl} 
            selectedColor={selectedColor} 
            onChangeSelectedColor={onChangeSelectedColor} 
          />
          <ItemBox colorHex={selectedColor} onClick={(event) => handleClick(event)}></ItemBox>
        </Grid>
      } label="Color" labelPlacement={FormControlLabelPlacementProps.Top} />
      <Grid item xs={2}>
        <MultiSelectCheckmarksMenu
          wildCard
          id="page-size-menu"
          minWidth={180}
          items={serviceType}
          selectedValue={serviceData?.selectedServiceTypeValue}
          label="Service Types"
          size={SizeProps.Small}
          onChange={(value) => {
            serviceData && onChange({ ...serviceData, selectedServiceTypeValue: value.split(',') });
            if(value.includes(ALL_KEYWORD)){
              serviceData && onChange({ ...serviceData, selectedServiceTypeValue: serviceType.map((item) => {
                return item.value;
              }) });
            } else if(value.length > 0) {
              serviceData && onChange({ ...serviceData, selectedServiceTypeValue: value.split(',') });
            } else {
              serviceData && onChange({ ...serviceData, selectedServiceTypeValue: [] });
            }
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <MultiSelectCheckmarksMenu
          required
          id="page-size-menu"
          minWidth={180}
          items={serviceCreationType}
          selectedValue={serviceData?.selectedServiceCreationTypeValue?.filter(selectedServiceCreationType => selectedServiceCreationType)}
          label="Service Creation Rules"
          size={SizeProps.Small}
          onChange={(value) => {
            serviceData && onChange({ ...serviceData, selectedServiceCreationTypeValue: value.split(',') });
          }}
        />
      </Grid>
      <SwitchGrid item xs={1}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              onChange={handleIsRestrictedServiceChange}
              checked={isRestricted}
            />
          }
          label="Restricted"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
      <SwitchGrid item xs={1}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Success}
              onChange={handleIsActiveChange}
              checked={isActive}
            />
          }
          label="Active"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>
    </Grid>
  );
};
  