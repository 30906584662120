import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import { createClientAuthSide, getAuthUserById, sendWelcomeEmail, updateAuthUserById } from '../api/auth';
import {
  getUsers,
  getUserById,
  createUser,
  updateUser,
  updateUserEmailVerification,
  getUserPermissions,
  createUserPermissions,
  createUserWhenLogin,
  getUserByAuth0Id,
  getUserSystemPermissions,
  getLoggenInUserData,
} from '../api/users';
import { Auth0AppUser, CustomPermission, CSVExportUser, AppUser, QueryOption, LoggedInUser } from '../@types';
import { uploadUserProfileImage } from '../api/uploadedFiles';
import { AppUserPermissionKey, AppUserSystemPermissionKey, AppUsersGetOneKey, AppUsersKey, AuthAppUserKey } from '../configs/users';
import { USER_OBJECT_KEY } from '../configs/envs';

export interface UserListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  limit?: number;
  data?: [AppUser];
  filterResponse?: [CSVExportUser];
}

export const useGetAllUsers = (queryOption: QueryOption): UseQueryResult<UserListResponse> => {
  return useQuery([AppUsersKey, queryOption.page, queryOption.pageSize], () => getUsers(queryOption));
};

export const useGetOneUser = (id: number): UseQueryResult<AppUser> => {
  return useQuery([AppUsersGetOneKey, id], () => getUserById(id), {
    select: (response: any) => response
  });
};

export const useGetCurrentUser = (): UseQueryResult<AppUser> | null => {
  const userObj = localStorage.getItem(USER_OBJECT_KEY);
  if (userObj) {
    const user = JSON.parse(userObj);
    return useQuery([AppUsersKey, user.sub], async () => await getUserByAuth0Id(user.sub), { staleTime: 60 * 60 * 1000 });
  }
  return null;
};

export const useCreateUser = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const { email, firstName, lastName, profilePicDetails } = data;
    const { user_id, updated_at } = await createClientAuthSide(email, firstName, lastName, '');
    data.contactEmail = email;
    data.auth0Id = user_id;
    data.lastActive = updated_at;
    data.isActive = true;
    data.isLocked = false;
    data.isEmailVerified = false;
    data.structureId = 1;

    const response = await createUser(data);

    if (response && response.id && profilePicDetails) {
      const formData = new FormData();
      const files = profilePicDetails;
      formData.append('fileList', files[0]);
      await uploadUserProfileImage(response.id, formData);
    }

    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppUsersKey]);
    },
  });
};

export const useUpdateUser = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    const { profilePic, id } = data;
    if (profilePic) {
      const formData = new FormData();
      const files = profilePic;
      formData.append('fileList', files[0]);
      const uploadedFileResponses = await uploadUserProfileImage(id, formData);
      data.profilePic = uploadedFileResponses[0].url;
    } 

    await updateUser(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppUsersKey]);
    },
  });
};

export const useGetUserPermissions = (id: string): UseQueryResult<CustomPermission[]> => {
  return useQuery([AppUserPermissionKey, id], () => getUserPermissions(id));
};

export const useGetUserSystemPermissions = (): UseQueryResult<CustomPermission[]> => {
  return useQuery([AppUserSystemPermissionKey], () => getUserSystemPermissions(), { staleTime: 60 * 60 * 1000 });
};

export const useAddUserPermissions = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => createUserPermissions(data.id, data.customPermissions), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AppUsersKey]);
    },
  });
};

export const useGetOneAuthUser = (id: string): UseQueryResult<Auth0AppUser> => {
  return useQuery([AuthAppUserKey, id], () => id && getAuthUserById(id));
};

export const useSendWelcomeEmail = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (id: any) => await sendWelcomeEmail(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AuthAppUserKey]);
    },
  });
};

export const useUpdateAuth0User = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    const { id, email, name, given_name, family_name, profilePic, blocked } = data;
    await updateAuthUserById(id, { email, name, given_name, family_name, profilePic, blocked });
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AuthAppUserKey]);
    },
  });
};

export const useAuthUser = (user: any): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (user: any) => {
    // if there is no user, response return null
    const authUserResponse = await getUserByAuth0Id(user?.sub).catch((error: any) => { if (error) error; });

    if (authUserResponse) {
      // trigger this when user's email verified from auth0 and not verified in DB
      if (user?.email_verified && !authUserResponse.isEmailVerified) {
        const authUser = {
          isEmailVerified: true
        };
        await updateUserEmailVerification(authUserResponse.id, authUser);
      }
    } else {
      await createUserWhenLogin(user);
    }
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AuthAppUserKey, user?.sub]);
    },
  });
};

export const useGetLoggenInUserData = (): UseQueryResult<LoggedInUser> => {
  return useQuery([AppUsersKey], () => getLoggenInUserData());
};