import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridDensityTypes, GridSelectionModel, GridToolbarContainer, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { AppRegistration } from '@mui/icons-material';
import { Stack, Button } from '@mui/material';
import { OtherServiceListProps } from './OtherServiceList.props';
import { DataGrid } from '../../atoms/DataGrid';
import { TableWrapper } from './OtherServiceList.styles';
import { BulkServiceEditor, SelectedServiceListDataType } from '../BulkServiceEditor';
import { ActionPermissions, BulkEditServiceDataType, CustomPermission } from '../../../@types';
import { formatDateSimple } from '../../../utils/common';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';

export const OtherServiceList: React.FC<OtherServiceListProps> = ({
  data,
  bulkEditServiceDropDownsDataset,
  bulkUpdateToastMessage,
  handleBulkUpdateSave,
  parentServiceId,
  otherServicesLoading
}) => {
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
  const [selectedServices, setSelectedServices] = useState<SelectedServiceListDataType[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [parentServiceID, setParentServiceID] = useState('');
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 50,
    },
    {
      field: 'serviceType',
      headerName: 'Service Type',
      minWidth: 50,
    },
    {
      field: 'serviceStatus',
      headerName: 'Service Status',
      minWidth: 120,
    },
    {
      field: 'subLocationName',
      headerName: 'Sublocation',
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      minWidth: 175,
      valueGetter: (params: GridValueGetterParams) => formatDateSimple(params.row.createdAt) === '-' ? '' : formatDateSimple(params.row.createdAt)
    },
    {
      field: 'observedFaultCode',
      headerName: 'Observed Fault',
      minWidth: 200,
      valueGetter: (params: GridValueGetterParams) => { 
        if (params.row.observedFaultCode) return `${params.row.observedFaultCategory} - ${params.row.observedFaultCode}`; 
        else return '';
      }
    }
  ];

  useEffect(() => {
    const selectedValues = data.reduce((accumulator: SelectedServiceListDataType[], currentValue) => {
      if (selectionModel.includes(currentValue.id)) {
        const item = {
          id: currentValue.id,
          isClosed: currentValue.isClosed,
          image: currentValue.logoPic || ''
        };
        return [...accumulator, item];
      }
      return accumulator;
    }, []);
    setSelectedServices([...selectedValues]);
  }, [selectionModel]);

  useEffect(() => {
    const selected = data.reduce((accumulator: number[], currentValue) => {
      if (currentValue.parentServiceId?.toString() === parentServiceId) {
        return [...accumulator, currentValue.id];
      }
      return accumulator;
    }, []);
    openBulkUpdate || setSelectionModel(selected);
  }, [openBulkUpdate]);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel.filter(item => typeof item === 'number'));
  }, []);

  const CustomToolBar = () => (
    <GridToolbarContainer>
      <Stack direction="row" spacing={2}>
        <Button 
          startIcon={<AppRegistration />} 
          onClick={() => {
            setParentServiceID(parentServiceId || '');
            selectedServices.length > 0 && setOpenBulkUpdate(!openBulkUpdate);
          }}
        >
          Bulk Update
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
  
  return (
    <TableWrapper>
      <DataGrid
        loading={otherServicesLoading}
        density={GridDensityTypes.Compact}
        columns={columns}
        rows={data}
        getRowId={(row) => row?.id}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: false
          }
        }}
        customToolbar={isUserHasPermission(ActionPermissions.Service_Bulk_Update_Edit_Service, permissions) && CustomToolBar}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        treeData={true}
        getTreeDataPath={(row) => [row.isClosed ? 'Closed' : 'Open', `${row.relation} ${row.id}`]}
        defaultGroupingExpansionDepth={-1}
        groupingColDef={{
          headerName: '',
          hideDescendantCount: true,
          valueFormatter: (params: GridValueFormatterParams<string>) => params.value === 'Closed' || params.value === 'Open' ? params.value : '',
          width: 100,
        }}
        getRowHeight={() => 'auto'}
        hideFooter={true}
      />
      <BulkServiceEditor
        open={openBulkUpdate}
        BulkEditServiceDropDownsDataset={bulkEditServiceDropDownsDataset}
        selectedServices={selectedServices}
        parentServiceId={parentServiceID}
        toastMessage={bulkUpdateToastMessage}
        handleClose={() => { 
          setOpenBulkUpdate(false);
          setParentServiceID('');
        }}
        handleSave={(BulkServiceUpdateData: BulkEditServiceDataType) => {
          handleBulkUpdateSave && handleBulkUpdateSave(BulkServiceUpdateData);
        }}
      />
    </TableWrapper>
  );
};