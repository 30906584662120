import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { home, MainRoutes } from './MainRoutes';
import { useGetUserSystemPermissions } from '../queries/user-query';
import { permittedLayouts, permittedRoutes } from '../configs/permissions';
import { CustomPermission } from '../@types';
import { menuData } from '../configs/menu';

const ThemeRoutes = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  let layoutes: any = [];
  
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getUserPermissionsQuery.isSuccess && getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.isLoading, getUserPermissionsQuery.isSuccess]);

  if (permissions) {
    layoutes = permittedLayouts(menuData, permissions);
  }
  
  const filteredRoutes: any = permittedRoutes(layoutes, MainRoutes);
  filteredRoutes.children.push(home);

  return useRoutes([filteredRoutes]);
};

export default withAuthenticationRequired(ThemeRoutes);