import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { PartTypePermissionGrantFragmentProps } from './PartTypePermissionGrantFragment.props';
import { Checkbox, CheckboxColorProps } from '../../../../components/atoms/Checkbox';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { LocationNodeData } from '../../../../@types';
import { IsCheckedValuesType } from '../SerialisedPartsUpdateSelection.props';
import { SAMPLE_IS_CHECKED_VALUES_DATA, SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA } from '../../../../constants/part';
import { useAdvanceSearchPartTypesForBulkPartUpdate } from '../../../../queries/live-search-query';
import { StyledLiveSearchDiv } from './PartTypePermissionGrantFragment.styles';
import { LinkedListProperties } from '../../../../@types/bulkPartUpdate.type';
import { SerialisedPartsUpdateSelectionDataType } from '../../../../@types/part.type';

export const PartTypePermissionGrantFragment: React.FC<PartTypePermissionGrantFragmentProps> = ({
  linkedFieldValue,
  partType,
  isPartTypePermissionEnabled,
  partTypePermissionUpdateValues
}) => {
  const [isCheckedValues, setIsCheckedValues] = useState<IsCheckedValuesType>(SAMPLE_IS_CHECKED_VALUES_DATA);
  const [partTypePermissionGrantValues, setPartTypePermissionGrantValues] = useState<SerialisedPartsUpdateSelectionDataType>(SAMPLE_SERIALIZED_PARTS_UPDATE_SELECTION_DATA);
  const [partTypeName, setPartTypeName] = useState('');

  const advanceSearchPartTypesForBulkPartUpdate = useAdvanceSearchPartTypesForBulkPartUpdate();

  useEffect(() => {
    partType?.partTypeName ? setPartTypeName(partType?.partTypeName) : setPartTypeName('');
    partType?.partTypeId && partType?.partTypeId > 0 ? setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: partType?.partTypeId || 0 }) : setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: 0 });
  }, [partType?.partTypeName, partType?.partTypeId]);

  useEffect(() => {
    partTypePermissionUpdateValues(partTypePermissionGrantValues);
  }, [partTypePermissionGrantValues]);

  useEffect(() => {
    switch(linkedFieldValue) {
      case LinkedListProperties.PartTypeId:
        setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: partType?.partTypeId || 0 });
        setPartTypeName('');
        setIsCheckedValues({ ...isCheckedValues, isCheckPartType: false });
        break;
    }
  }, [linkedFieldValue]);

  return (
    <>
      {isPartTypePermissionEnabled &&
        <Stack direction="row" mb={3} alignItems="center">
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={isCheckedValues.isCheckPartType}
            disabled={linkedFieldValue === LinkedListProperties.PartTypeId}
            onChange={(event) => {
              setIsCheckedValues({ ...isCheckedValues, isCheckPartType: event.target.checked });

              if (!partType?.partTypeName) {
                setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: undefined });
                setPartTypeName('');
              }
              else {
                if(!event.target.checked) {
                  setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: partType.partTypeId });
                  setPartTypeName(partType.partTypeName);
                }
              }
            }}
          />
          <StyledLiveSearchDiv>
            <LiveSearchBox
              disabled={!isCheckedValues.isCheckPartType}
              title="Part Type Search"
              timeOffset={400}
              value={partTypeName || ''}
              onClearValue={() => {
                setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: undefined });
                setPartTypeName('');
              }}
              renderItem={(props: any, option: any) => { 
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(obj: LocationNodeData) => {
                setPartTypePermissionGrantValues({ ...partTypePermissionGrantValues, partTypeId: obj.id });
                setPartTypeName(obj.name);
              }}
              onApiInvoke={async (name: string) => {
                return await advanceSearchPartTypesForBulkPartUpdate.mutateAsync({
                  searchableValues: name
                });
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </StyledLiveSearchDiv>
        </Stack>
      }
    </>
  );
};