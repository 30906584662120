import { styled } from '@mui/material';

export const CloseWrapper = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  cursor: pointer;
`;