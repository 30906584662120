import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { Typography, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { TextField } from '../../../../../components/atoms/TextField';
import { ImagePopup } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Action, PartActionType } from '../../../../../@types/partAction.type';
import { PartActionPartCreationDialogProps, PartImageData } from './PartActionPartCreationCard.props';
import { UploadButton } from '../../../../../components/molecules/UploadButton';
import { PartImageBox } from '../../../../../components/templates/ExceptionPartEdit/ExceptionPartEdit.styles';
import { GRID_SPACING } from '../../../../../configs/ui-constants';
import { SERVICE_ATTACHMENTS_MIME_TYPES } from '../../../../../configs/envs';
import { ServiceAttachmentMimeTypes } from '../../../../../configs/uploaded-files';
import { getMatchingMimeTypes } from '../../../../../utils/upload-file';

export const PartActionPartCreationDialog: React.FC<PartActionPartCreationDialogProps> = ({
  service,
  partActionData,
  isOpen,
  partTypeImages,
  partTypeValue,
  helperText,
  onPartImagesUpdate,
  setPartTypeSearchValue
}) => {

  const [partImages, setPartImages] = useState<PartImageData>();

  useEffect(() => {
    isOpen && setPartImages(undefined);
  }, [isOpen]);

  const onClickImageUpload = async (isDeviceimage: boolean, fileList: FileList) => {
    const updatedImages = { 
      ...partImages, 
      ...(isDeviceimage && { deviceImage: {
        file: fileList[0], 
        displayImage: URL.createObjectURL(fileList[0]) 
      } }),
      ...(!isDeviceimage && { labelImage: {
        file: fileList[0], 
        displayImage: URL.createObjectURL(fileList[0]) 
      } })
    };

    setPartImages(updatedImages);
    onPartImagesUpdate(updatedImages);
  };

  return (
    <>
      {partActionData?.action == Action.IN ?
        <Typography variant={TypographyVariantProps.Body2}>
          This action will create the scanned part for usage in this Service.  Please ensure the information is correct, as the part’s creation will be reported to the Client as delivered in to their custody.
        </Typography> :
        <Typography variant={TypographyVariantProps.Body2}>
          This action will create the scanned part for usage in inventory.  Please ensure the information entered is correct, and the part is promptly returned for review and acceptance.
        </Typography>}
      <Stack direction="row" justifyContent="space-between" spacing={1} width="100%" marginTop={2}>
        <Stack direction="column" spacing={2} marginTop={1} width="100%">
          <PartTypeActionSearch
            title="Part Type Search"
            serviceData={service}
            locationId={service.subLocationNodeId || service.mainLocationNodeId}
            value={partTypeValue?.name || ''}
            partActionType={PartActionType.INSTALL}
            error={!!helperText.in.partTypeHelperText}
            helperText={helperText.in.partTypeHelperText}
            onChange={(selectedOption: PartTypeActionLiveSearchItem) => {
              setPartTypeSearchValue(selectedOption);
            }}
            serialiseType={PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent}
            searchType={'part-type'}
          />
        </Stack>
        {partTypeImages && partTypeImages?.length > 0 && <ImagePopup title="Photos" images={partTypeImages} />}
      </Stack>
      <Stack direction="column" spacing={2} marginTop={1} width="100%">
        <TextField
          required={true}
          fullWidth
          label="Serial 1"
          value={partActionData?.newPartSerial}
        />
      </Stack>
      <Stack direction="row" spacing={2} marginTop={1} width="100%">
        <Grid width="50%">
          <Grid mt={GRID_SPACING}>
            <UploadButton fileTypes={getMatchingMimeTypes(SERVICE_ATTACHMENTS_MIME_TYPES, ServiceAttachmentMimeTypes.PartActionPartCreation)} maxFileSize={30} setFileDetails={(val: FileList) => onClickImageUpload(true, val)}
              label="Upload" isText={false} icon={<AddPhotoAlternateIcon fontSize="small" />} />
            <Typography>{'Take a photo of the whole device'}</Typography>
          </Grid>
          {partImages?.deviceImage && <Grid mt={GRID_SPACING}>
            <PartImageBox src={partImages?.deviceImage?.displayImage} />
          </Grid>}
        </Grid>
        <Grid width="50%">
          <Grid mt={GRID_SPACING}>
            <UploadButton fileTypes={getMatchingMimeTypes(SERVICE_ATTACHMENTS_MIME_TYPES, ServiceAttachmentMimeTypes.PartActionPartCreation)} maxFileSize={30} setFileDetails={(val: FileList) => onClickImageUpload(false, val)}
              label="Upload" isText={false} icon={<AddPhotoAlternateIcon fontSize="small" />} />
            <Typography>{'Take a photo of the device\'s complete serial number label'}</Typography>
          </Grid>
          {partImages?.labelImage && <Grid mt={GRID_SPACING}>
            <PartImageBox src={partImages?.labelImage?.displayImage} />
          </Grid>}
        </Grid>
      </Stack>
    </>
  );
};