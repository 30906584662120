import { NodeType } from '../@types';
import { ANY_ITEM_ARRAY } from './common';

export const CONFIGURATION_CHANGE_NOTES_LENGTH = 200;
export const SFHWARE_VERSION_LENGTH = 200;

export const NAME_HELPER_TEXT = 'Name field is required';
export const MANUFACTURER_HELPER_TEXT = 'Manufacturer field is required';
export const CLIENT_CONTRACT_EXIST_HELPER_TEXT = 'Client/Contract field is required';
export const CONFIGURATION_NOTE_HELPER_TEXT = 'Configuration Note field is required';

export const PART_TYPE_SERIALISED_NATURE = [
  { value: 'Serialised', label: 'Serialised' },
  { value: 'Non-Serialised', label: 'Non-Serialised' }
];

export const PART_TYPE_CATEGORY_ARRAY= [
  { value: 'Serialised Device', label: 'Serialised Device' },
  { value: 'Serialised Component', label: 'Serialised Component' },
  { value: 'Non Serialised Component', label: 'Non Serialised Component' },
  { value: 'Consumable', label: 'Consumable' }
];

export const SAMPLE_PART_TYPE = {
  id: 0,
  name: '',
  number: '',
  manufacturerNodeId: 0,
  manufacturerNodeName: '',
  categoryCode: '-1',
  applicableNodeIds: [],
  notes: '',
  isActive: false,
  tags: [],
  selectedClient: '',
  selectedContract: [],
  serialised: '',
  partTypeTree: '',
  fleetTag: [],
  selectedParentId: '',
  configurationChangeNotes: '',
  selectedParentHierarchy: '',
  selectedTags: [],
  partTypeCategoryList: [],
  warranties: [],
  softwares: [],
  firmwares: [],
  hardwares: [],
  salePricings: [],
  repairPricings: [],
  supplierPricings: [],
  billOfMaterials: []
};

export const SAMPLE_PART_TYPE_VALIDATOR = {
  isEmptyName: false,
  isEmptyClientContract: false,
  isEmptyManufacturer: false,
  openPartTypeSuccessToast: false,
  isActiveChangeNotes: false,
  openParentSelector: false,
  isCategoryEmpty: false,
  showWarningToast: false,
  openImage: false,
  configurationChangeNotesHelperText: '',
  warningMessage: '',
};

export const SAMPLE_PART_TYPE_PARENT_SELECTOR = {
  clients: [],
  contracts: ANY_ITEM_ARRAY,
  queryNodeType: NodeType.Client
};

export const SAMPLE_UPLOADED_FILE_ACTION_DATA = {
  id: 0,
  contentDispositionType: '',
  mimeType: ''
};