export interface Permission {
  id: number;
  code: string;
  name: string;
  isGranted: boolean;
  children: Permission[];
}

export interface CustomPermission {
  id: number;
  permissionId: number;
  code: string;
  name: string;
  isGranted: boolean;
}

export enum ActionPermissions {
  Service_Create = 'service.create',
  Service_Search = 'service.search',
  Service_Search_Columns_Service_details = 'service.search.columns.serviceDetails',
  Service_Search_Columns_Statuse_Allocation = 'service.search.columns.status_allocation_updates',
  Service_Search_Columns_Structure = 'service.search.columns.structure',
  Service_Search_Columns_Part_Details = 'service.search.columns.partDetails',
  Service_Search_Columns_Linked_Records = 'service.search.columns.linkedRecords',
  Service_Search_Columns_SLA = 'service.search.columns.sla',
  Service_Search_Columns_Progress_Data = 'service.search.columns.progressData',
  Service_Search_Columns_Interactions = 'service.search.columns.Interactions',
  Service_Search_Columns_Admin_And_Billing = 'service.search.columns.admin_and_billing',
  Service_Edit_View_Service = 'service.edit.viewService',
  Service_Edit_Add_Notes = 'service.edit.addNotes',
  Service_Edit_Administration_Section = 'service.edit.administration_section',
  Service_Edit_Service_Parameters = 'service.edit.serviceParameters.serviceParameters',
  Service_Edit_Allocation_Primary = 'service.edit.allocation.primary',
  Service_Edit_Allocation_Secondary = 'service.edit.allocation.secondary',
  Service_Edit_Allocation_Send_Up_To = 'service.edit.allocation.sendUpTo',
  Service_Edit_Service_Log_Edit_Notes = 'service.edit.service_log_edit.editNotes',
  Service_Edit_Service_Log_Hide_Records = 'service.edit.service_log_edit.hideRecords',
  Service_Edit_Part_Actions_Exceptional_Creation = 'service.edit.part_actions.exceptional_creation',
  Service_Edit_Part_Actions = 'service.edit.part_actions',
  Service_Edit_Status_Progress_Status_Change = 'service.edit.status_progress.status_change',
  Service_Bulk_Update = 'service.bulkUpdate',
  Service_Bulk_Update_Edit_Service = 'service.bulkUpdate.editService',
  Service_Bulk_Update_Search = 'service.bulkUpdate.search',
  Service_Bulk_Update_File_Upload = 'service.bulkUpdate.fileUpload',
  Service_Bulk_Update_Allow_Closed_Service_Update = 'service.bulkUpdate.allow_closed_service_update',
  Service_Export = 'service.export',
  Structure_Create_General = 'structure.create.general',
  Structure_Edit_View_Structure_Location = 'structure.edit.view_structure_location',
  Structure_Export = 'structure.export',
  Configure_User_Create = 'configure.users.create',
  Configure_User_Export = 'configure.users.export',
  Configure_User_Edit_General = 'configure.users.edit.general',
  Configure_User_Edit_Custom_Permissions = 'configure.users.edit.customPermission',
  Configure_User_Edit_Role_Selection = 'configure.users.edit.roleSelection',
  Configure_User_Edit_Sibling_Config = 'configure.users.edit.siblingConfig',
  Configure_User_Edit_Lock = 'configure.users.edit.lock',
  Service_Edit_Checkin_Checkout_Allow= 'service.edit.checkin_checkout.allow',
  Configure_User_Edit_Checkin_Checkout_On_Behalf_Of = 'service.edit.checkin_checkout.on_behalf_of',
  Configure_User_Edit_Checkin_Checkout_Bypass_Location_Restriction = 'service.edit.checkin_checkout.bypass_location_restriction',
  Configure_User_Roles_Create_General = 'configure.users.roles.create.general',
  Configure_User_Roles_Create_Vibility_Rules = 'configure.users.roles.create.vibilityRules',
  Configure_User_Roles_Create_Role_Permission = 'configure.users.roles.create.rolePermission',
  Configure_User_Roles_Edit_General = 'configure.users.roles.edit.general',
  Configure_User_Roles_Edit_Vibility_Rules = 'configure.users.roles.edit.vibilityRules',
  Configure_User_Roles_Edit_Role_Permission = 'configure.users.roles.edit.rolePermission',
  Configure_User_Roles_Search_View = 'configure.users.roles.search_view',
  Configure_User_Roles_Export = 'configure.users.roles.export',
  Configure_Structure_Type_Create = 'configure.structure.type.create',
  Configure_Structure_Type_Edit = 'configure.structure.type.edit',
  Configure_Structure_Type_Search_View = 'configure.structure.type.search_view',
  Configure_Service_Status_Create = 'configure.services.status.create',
  Configure_Service_Status_Edit = 'configure.services.status.edit',
  Configure_Service_Status_Search_View = 'configure.services.status.search_view',
  Configure_Service_Types_Create = 'configure.services.types.create',
  Configure_Service_Types_Edit = 'configure.services.types.edit',
  Configure_Service_Types_Export = 'configure.services.types.export',
  Configure_Service_Types_Search_View = 'configure.services.types.search_view',
  Configure_Clients_or_Contracts_Create = 'configure.client_structure.clients_or_contracts.create',
  Configure_Clients_or_Contracts_Edit = 'configure.client_structure.clients_or_contracts.edit',
  Configure_Clients_or_Contracts_Export = 'configure.client_structure.clients_or_contracts.export',
  Service_Edit_Service_Parameters_General = 'service.edit.serviceParameters.general',
  Service_Edit_Service_Parameters_Reported_Information = 'service.edit.serviceParameters.reported_information',
  Service_Edit_Service_Parameters_Process_Action = 'service.edit.serviceParameters.process_action_types',
  Service_Edit_Service_Parameters_Advanced = 'service.edit.serviceParameters.advanced',
  Configure_Part_Type_Create = 'configure.part_types.create',
  Configure_Part_Type_Search = 'configure.part_types.search',
  Structure_Edit = 'structure.edit',
  Part = 'part',
  Part_Search = 'part.search',
  Part_Serial = 'part.serial',
  Part_Condition = 'part.condition',
  Part_Exception = 'part.exception',
  Part_Change_Part_Type = 'part.change_part_Type',
  Part_Template_Tlc = 'part.template_tlc',
  Shipment_View_Add_Stock = 'shipment.add_stock',
  Shipment_View_Dispatch = 'shipment.dispatch',
  Shipment_View_Return = 'shipment.return',
  Shipment_View_Search = 'shipment.search',
  Attendance_Report_Export = 'report.attendance',
  Searialised_Part_Bulk_Update = 'part.bulk_update.serialised_parts',
  Non_Searialised_Part_Bulk_Update = 'part.bulk_update.non_serialised_parts',
  Configure_Notifications_Rules_Create = 'configure.notifications.rules.create',
  Configure_Notifications_Rules_Edit = 'configure.notifications.rules.edit',
  Configure_Notifications_Rules_View = 'configure.notifications.rules.search_view',
  Configure_Notifications_Templates_Create = 'configure.notifications.templates.create',
  Configure_Notifications_Templates_Edit = 'configure.notifications.templates.edit',
  Configure_Notifications_Templates_View = 'configure.notifications.templates.search_view',
  Shipment_Batch_Receive = 'shipment.batch_receive',
  Shipment_Uses_Put_away = 'shipment.uses_put_away',
  Shipment_Bulk_Put_way = 'shipment.bulk_put_away',
  Structure_Edit_Allow_Merge = 'structure.edit.allow_merge',
  Part_Bulk_Update_Part_Type_Client_Lock_Bypass = 'part.bulk_update.part_type_client_lock_bypass',
  Part_Bulk_Update_Serial_Number = 'part.bulk_update.serial_numbers',
  Shipment_Bypass_Receipt = 'shipment.bypass_receipt',
  Parts_Merge = 'part.parts_merge',
  Service_Edit_Status_Progress_Cancelled = 'service.edit.status_progress.cancelled',
  Service_Edit_Status_Progress_Cancelled_After_Progress = 'service.edit.status_progress.can_cancelled_after_progress',
  Service_Edit_Status_Progress_Futile = 'service.edit.status_progress.futile',
  Service_Edit_Status_Progress_Reopen_Service = 'service.edit.status_progress.reopen_service',
  Configure_Interactions_Bypass_Mandatory = 'configure.interactions.bypass_mandatory',
  Service_Interaction_Edit = 'service.edit.interaction',
  Configure_Clients_or_Contracts_SiteSchema_View = 'configure.client_structure.clients_or_contracts.site_schema.search_view',
  Configure_Clients_or_Contracts_SiteSchema_Edit = 'configure.client_structure.clients_or_contracts.site_schema.edit',
  Configure_Clients_or_Contracts_SiteSchema_Create = 'configure.client_structure.clients_or_contracts.site_schema.create',
  Service_Edit_Part_Actions_Exceptional_Creation_Acceptance = 'service.edit.part_actions.have_exceptional_creation_acceptance',
  Report_My_Report_Search = 'report.my_reports.search',
  Report_My_Report_Sharing = 'report.my_reports.sharing',
  Report_My_Report_Schedule = 'report.my_reports.schedule',
  Part_Bulk_Update_Sw_Hw_Fw_Fleet_Tags = 'part.bulk_update.sw_hw_fw_fleet_tags',
  Service_Edit_Actions_Tasks = 'service.edit.actions.task',
  Service_Edit_Part_Section = 'service.edit.part_section',
  Configure_Sla_Rules_Create = 'configure.sla.rules.create',
  Configure_Sla_Rules_Edit = 'configure.sla.rules.edit',
  Configure_Sla_Rules_Search_View = 'configure.sla.rules.search_view',
  Service_Edit_Actions_Part_Action_Anytime = 'service.edit.actions.part_action_anytime',
  Service_Edit_Actions_Part_Action_Inprogress = 'service.edit.actions.part_action_inprogress',
  Configure_Interactions_Edit_Rules = 'configure.interactions.edit_rules',
  Configure_Interactions_Edit_Templates = 'configure.interactions.edit_templates',
  Service_Edit_Service_Log_View_Interactions = 'service.edit.service_log_view.interactions_records',
  Service_SLA_Visibility_Client = 'service.sla_visibility.client',
  Service_SLA_Visibility_OPS_Field = 'service.sla_visibility.ops_field',
  Service_SLA_Visibility_OPS_Helpdesk = 'service.sla_visibility.ops_helpdesk',
  Service_SLA_Visibility_OPS_Logistics = 'service.sla_visibility.ops_logistics',
  Configure_Part_Type_Edit = 'configure.part_types.edit'
}

export enum RoutesEnum {
  UserCreateAndUpdateRoute = '/configure/users',
  RoleCreateAndUpdateRoute = '/configure/users/roles',
  StructureTypeCreateAndUpdateRoute = '/configure/structure/types',
  ClientAndContractCreateAndUpdateRoute = '/configure/clients',
  ServiceTypeCreateAndUpdateRoute = '/configure/services/types',
  ServiceStatusCreateAndUpdateRoute = '/configure/services/statuses',
  PartTypeCreateAndUpdateRoute = '/configure/partTypes',
  PartsSearchRoute = '/parts/search',
  PartUpdateRoute = '/parts',
  ShipmentUpdateRoute = '/shipments',
  BatchReceiveCreateAndUpdateRoute = '/shipments/batchReceive',
  GeoLocationPositionKey = 'geo-location-position',
  TLCTemplateCreateAndUpdateRoute = '/parts/tlcTemplates',
  ReceivedShipmentRoute = '/shipments/receive',
  ServiceCreateAndUpdateRoute = '/services',
  StructureCreateAndUpdateRoute = '/structure',
}