import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { BulkPutawayWrapper, MainBox, StyledButton, StyledStack, StyledTypography, WrapperButton } from './AddEditBatchReceive.styles';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { BatchReceivePopupFragment } from './BatchReceivePopupFragment';
import { useAdvanceSearchPartTypes, useBatchRecieveBulkSearchSerials, useReciveShipmentsSearchNodes, useSearchNodeReceive } from '../../../queries/live-search-query';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationNodeData, PartTypeSearchData } from '../../../@types';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useCreateShipmentBatchReceive, useGetAllShipmentManifest, useGetShipmentBatchReceiveById } from '../../../queries/shipment-query';
import { NonSerializedShipmentManifestItem } from '../../../components/organisms/NonSerializedShipmentManifestItem';
import { SerializedShipmentManifestItem } from '../../../components/organisms/SerializedShipmentManifestItem';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import { Link } from '../../../components/atoms/Link';
import { BatchReceive, NonSerializedShipmentManifestData, SerializedShipmentManifestData, ShipmentBatchReceiveData, ShipmentRecieveType } from '../../../@types/shipment.type';
import { SAMPLE_SHIPMENT_BATCH_RECEIVAL } from '../../../constants/shipment';
import { useGetLoggedInSiblingParentDetails } from '../../../queries/structure-query';
import { BulkPutaway } from '../../../components/molecules/BulkPutaway';
import { updatePutawayLocation } from '../../../utils/shipment';
import { TabHandler } from '../../../handlers/TabHandler';
import { PLATFORM_NAME } from '../../../configs/common';
import { parseExcelData } from '../../../utils/common';
import { AdvanceLiveSearchBox } from '../../../components/atoms/AdvanceLiveSearchBox';
import { SearchCode } from '../../../@types/part.type';

export const AddEditBatchReceive: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNew = id === 'create';

  const [batchReceiveData, setBatchReceiveData] = useState<ShipmentBatchReceiveData>(SAMPLE_SHIPMENT_BATCH_RECEIVAL);
  const [isPaste, setIsPaste] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [clearInputOnEmptyResult, setClearInputOnEmptyResult] = useState(false);

  const getShipmentBatchReceiveById = useGetShipmentBatchReceiveById(id);
  const getAdvanceSearchPartTypes = useAdvanceSearchPartTypes();
  const searchNodeReceive = useSearchNodeReceive();
  const getAllShipmentManifest = useGetAllShipmentManifest(
    batchReceiveData.partTypeId,
    batchReceiveData.putAwayLocationId,
  );
  const createShipmentBatchReceive = useCreateShipmentBatchReceive();
  const getLoggedInSiblingParentDetailsQuery = useGetLoggedInSiblingParentDetails();
  const reciveShipmentsSearchNodes = useReciveShipmentsSearchNodes();
  const batchRecieveBulkSearchSerials = useBatchRecieveBulkSearchSerials();

  TabHandler(isNew ? `Create Batch | ${PLATFORM_NAME}` : `Batch: ${id} | Batch Recive | ${PLATFORM_NAME}`);

  useEffect(() => {
    isNew && getLoggedInSiblingParentDetailsQuery.refetch();
  }, []);

  useEffect(() => {
    if (getLoggedInSiblingParentDetailsQuery.data) {
      const obj = getLoggedInSiblingParentDetailsQuery.data;
      obj.parentDetails?.id && setBatchReceiveData({
        ...batchReceiveData,
        putAwayLocationId: obj.parentDetails?.id,
        putAwayLocationName: obj.parentDetails?.name,
        isPartTypeLiveSearch: true
      });
    }
  }, [getLoggedInSiblingParentDetailsQuery.data]);

  useEffect(() => {
    if (getAllShipmentManifest.data) {
      if (getAllShipmentManifest?.data?.serial.length > 0) {
        setBatchReceiveData({
          ...batchReceiveData,
          serializedShipmentManifestItem: [
            ...batchReceiveData.serializedShipmentManifestItem,
            ...(getAllShipmentManifest.data?.serial || []),
          ],
          partTypeId: clearInput ? NaN : batchReceiveData.partTypeId,
          partTypeName: clearInput ? '' : batchReceiveData.partTypeName,
          smartInput: clearInput ? '' : batchReceiveData.smartInput
        });
      }
      if (getAllShipmentManifest?.data?.nonSerial.length > 0)
        setBatchReceiveData({
          ...batchReceiveData,
          nonSerialOptions: getAllShipmentManifest.data?.nonSerial || [],
          isPartTypePopup: true,
          partTypeId: clearInput ? NaN : batchReceiveData.partTypeId,
          partTypeName: clearInput ? '' : batchReceiveData.partTypeName,
          smartInput: clearInput ? '' : batchReceiveData.smartInput
        });
      setClearInput(false);
    }
  }, [getAllShipmentManifest.data]);

  const onSubmit = async () => {
    const newBatchReceive: BatchReceive = await createShipmentBatchReceive.mutateAsync(batchReceiveData);

    setTimeout(() => {
      const url = `/shipments/batchReceive/${newBatchReceive.id}`;
      navigate(url, { replace: true });
    }, 2000);
  };

  useEffect(() => {
    if (!isNew && getShipmentBatchReceiveById.data) {
      setBatchReceiveData({
        ...batchReceiveData,
        serializedShipmentManifestItem: [
          ...(getShipmentBatchReceiveById.data?.manifest?.serializedManifests || batchReceiveData.serializedShipmentManifestItem),
        ],
        nonSerializedShipmentManifestItem: [
          ...(getShipmentBatchReceiveById.data?.manifest?.nonSerializedManifests || batchReceiveData.nonSerializedShipmentManifestItem),
        ],
        putAwayLocationId: getShipmentBatchReceiveById.data?.destinationNodeId,
        putAwayLocationName: getShipmentBatchReceiveById.data?.destinationNodeName
      });
    }
  }, [isNew, getShipmentBatchReceiveById.data]);

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let formatedManifestedAsFlatArray: string[] = [];

      if (batchReceiveData?.smartInput && isPaste) {
        const formatedManifestedData = parseExcelData(batchReceiveData.smartInput || '') as Array<Array<string>>;
        formatedManifestedAsFlatArray = ([] as string[]).concat(...formatedManifestedData);
      }
      else if((event.target as HTMLInputElement).value) {
        const textValue = (event.target as HTMLInputElement).value;
        
        if(!textValue.toLowerCase().startsWith('am-')) {
          formatedManifestedAsFlatArray = [textValue];
        }
      }
      if (formatedManifestedAsFlatArray.length) {
        setShowProgress(true);
        const serialsWithExistance: any = await batchRecieveBulkSearchSerials.mutateAsync({   
          serials: formatedManifestedAsFlatArray,
          nodeId: batchReceiveData.putAwayLocationId
        });
        setShowProgress(false);
        setIsPaste(false);
        setClearInputOnEmptyResult(true);

        setBatchReceiveData({
          ...batchReceiveData,
          smartInput: '' ,
          serializedShipmentManifestItem: [
            ...batchReceiveData.serializedShipmentManifestItem,
            ...serialsWithExistance,
          ]
        });
      }

      setShowProgress(false);
      setIsPaste(false);
    }
  };

  return (
    <PageContainer>
      <Stack direction="column" spacing={GRID_SPACING}>
        <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
          {isNew ? 'Create Batch' : `Batch Receive - ${id}`}
        </Typography>
        {isNew ? (
          <>
            <LiveSearchBox
              title="Location Search"
              timeOffset={400}
              value={batchReceiveData.putAwayLocationName}
              onClearValue={() => {
                setBatchReceiveData({
                  ...batchReceiveData,
                  putAwayLocationId: NaN,
                  putAwayLocationName: ''
                });
              }}
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              }}
              onChange={(nodeItem: LocationNodeData) => {
                setBatchReceiveData({
                  ...batchReceiveData,
                  putAwayLocationId: nodeItem.id,
                  putAwayLocationName: nodeItem.name.includes(SearchCode.AM_LID) ? nodeItem.code : nodeItem.name,
                  isPartTypeLiveSearch: true
                });
              }}
              onApiInvoke={async (name: string) => {
                return await searchNodeReceive.mutateAsync({
                  name,
                  hasCode: true
                });
              }}
            />
            <AdvanceLiveSearchBox
              title={isPaste ? 'Serial Copy/Paste' : 'PartType Search'}
              timeOffset={400}
              showProgress={showProgress}
              value={batchReceiveData.smartInput || ''}
              overrideOnEnterKey={true}
              clearInputOnEmptyResult={clearInputOnEmptyResult}
              onClearValue={() => {
                setClearInput(true);
                setIsPaste(false);
                setShowProgress(false);
                setClearInputOnEmptyResult(false);
              }}
              renderItem={(props: any, option: any) => { 
                if (!isPaste) {
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }
              }}
              onChange={async (val: PartTypeSearchData) => {
                setBatchReceiveData({
                  ...batchReceiveData,
                  partTypeId: val.id,
                  partTypeName: val.name,
                  smartInput: val.name 
                });
              }}
              onTextChange={(event: any) => { 
                setClearInput(true);
                setBatchReceiveData({ ...batchReceiveData, smartInput: event.target.value });
              }}
              onApiInvoke={async (searchableData: string) => {
                return await getAdvanceSearchPartTypes.mutateAsync({
                  searchableValues: searchableData,
                  applicableNodeId: ''
                });
              }}
              onPaste={() => setIsPaste(true)}
              onKeyDown={onKeyDown}
              isPaste={isPaste}
            />


            <BatchReceivePopupFragment
              batchReceivePopupData={batchReceiveData}
              onChange={(item: ShipmentBatchReceiveData) => setBatchReceiveData(item)}
            />
          </>
        ) : (
          <StyledStack direction="row" spacing={0.5} alignItems="center">
            <Typography variant={TypographyVariantProps.Body1}>Destination:</Typography>
            {batchReceiveData?.putAwayLocationId && <Link href={`/structure/${batchReceiveData.putAwayLocationId}`}>{batchReceiveData.putAwayLocationName}</Link>}
          </StyledStack>
        )}
        <MainBox>
          <Typography variant={TypographyVariantProps.H6}>Manifest
            {isNew && (batchReceiveData?.serializedShipmentManifestItem.length > 0 || batchReceiveData?.nonSerializedShipmentManifestItem.length > 0) &&
              <BulkPutawayWrapper>
                <BulkPutaway 
                  onChange={(putawayLocationId, putawayLocationName) => setBatchReceiveData(updatePutawayLocation(batchReceiveData, putawayLocationId, putawayLocationName))} 
                  onApiInvoke={async (name: string) => {
                    return await reciveShipmentsSearchNodes.mutateAsync({
                      id: batchReceiveData.putAwayLocationId,
                      recieveType: ShipmentRecieveType.Bulk,
                      name
                    });
                  }}
                />
              </BulkPutawayWrapper>
            }
          </Typography>
          {batchReceiveData?.serializedShipmentManifestItem.length > 0 && (
            <>
              <StyledTypography variant={TypographyVariantProps.Body1}>
                Serialized Parts
              </StyledTypography>
              <SerializedShipmentManifestItem
                serializedShipmentManifestItemData={
                  batchReceiveData?.serializedShipmentManifestItem
                }
                onChange={(item: any) =>
                  setBatchReceiveData({ ...batchReceiveData, serializedShipmentManifestItem: item, isSerialsNotEmpty: item?.some((element: SerializedShipmentManifestData) => element.selected) > 0 ? true : false })
                }
                isEditMode={isNew ? true : false}
                isCheckbox={true}
                onApiInvoke={async (name: string) => {
                  return await reciveShipmentsSearchNodes.mutateAsync({
                    id: batchReceiveData.putAwayLocationId,
                    recieveType: ShipmentRecieveType.Bulk,
                    name
                  });
                }}
              />
            </>
          )}
          {batchReceiveData?.nonSerializedShipmentManifestItem.length > 0 && (
            <>
              <StyledTypography variant={TypographyVariantProps.Body1}>
                Non Serialized Parts
              </StyledTypography>
              <NonSerializedShipmentManifestItem
                nonSerializedShipmentManifestItemData={
                  batchReceiveData?.nonSerializedShipmentManifestItem
                }
                onChange={(item: any) =>
                  setBatchReceiveData({
                    ...batchReceiveData,
                    nonSerializedShipmentManifestItem: item,
                    isNonSerialsNotEmpty: item?.some((element: NonSerializedShipmentManifestData) => element.selected) > 0 ? true : false
                  })
                }
                isEditMode={isNew ? true : false}
                isCheckbox={true}
                onApiInvoke={async (name: string) => {
                  return await reciveShipmentsSearchNodes.mutateAsync({
                    id: batchReceiveData.putAwayLocationId,
                    recieveType: ShipmentRecieveType.Bulk,
                    name
                  });
                }}
              />
            </>
          )}
        </MainBox>
        {isNew && (
          <WrapperButton>
            <StyledButton
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Success}
              onClick={onSubmit}
              disabled={!batchReceiveData?.isSerialsNotEmpty && !batchReceiveData?.isNonSerialsNotEmpty}
            >
              Receive Batch
            </StyledButton>
          </WrapperButton>
        )}
      </Stack>
    </PageContainer>
  );
};