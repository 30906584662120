import React, { useState, useEffect } from 'react';
import { SelectMenu, DropdownItem } from '../../../../../components/atoms/SelectMenu';
import { 
  MODIFY_STRUCTURE_FORM_TOGGLE_SCOPE_OPTIONS, MODIFY_STRUCTURE_TYPE_OPTIONS 
} from '../../../../../constants/form-builder';
import { useGetStructureTypesByCategoryCode } from '../../../../../queries/structure-type-query';
import { StructureTypeCategory } from '../../../../../configs/enums';
import { ModifyStructureFormModifyStructureType } from '../../../../../@types';
import { ModifyStructureFormBuilderFieldFragmentProps } from './ModifyStructureFormBuilderFieldFragment.props';
import { StyledStack } from './ModifyStructureFormBuilderFieldFragment.styles';

export const ModifyStructureFormBuilderFieldFragment: React.FC<ModifyStructureFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const [options, setOptions] = useState<Array<DropdownItem>>([]);

  const getStructureTypesByCategoryCodeQuery = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);

  useEffect(() => {
    if (getStructureTypesByCategoryCodeQuery.data) {
      const optionList: Array<DropdownItem> = getStructureTypesByCategoryCodeQuery.data.map((obj) => ({
        label: obj.name,
        value: obj.code,
        disabled: !obj.isActive
      }));

      if (preSelectedData?.structureType === ModifyStructureFormModifyStructureType.CreateLocation) {
        setOptions(optionList);
      }
    }
  }, [getStructureTypesByCategoryCodeQuery.data, preSelectedData?.structureType]);

  useEffect(() => {
    if (preSelectedData?.structureType === ModifyStructureFormModifyStructureType.ToggleActive) {
      setOptions(MODIFY_STRUCTURE_FORM_TOGGLE_SCOPE_OPTIONS);
    }
  }, [preSelectedData?.structureType]);

  return (
    <StyledStack spacing={2}>
      <SelectMenu
        id="form-builder-modify-structure-field-type"
        label="Modify Structure Type"
        items={MODIFY_STRUCTURE_TYPE_OPTIONS}
        selectedValue={preSelectedData?.structureType}
        onChange={(val) => onPreSelectedData({ 
          structureType: val, 
          selectedValue: preSelectedData?.selectedValue || '' 
        })}
        validate={showErrors}
      />
      <SelectMenu
        id="form-builder-modify-structure-field-options"
        label={preSelectedData?.structureType === ModifyStructureFormModifyStructureType.CreateLocation ? 'Sub Location Type' : 'Toggle Scope'}
        items={options}
        selectedValue={preSelectedData?.selectedValue}
        onChange={(val) => preSelectedData && onPreSelectedData({ 
          selectedValue: val,
          structureType: preSelectedData?.structureType || ''
        })}
        validate={showErrors}
      />
    </StyledStack>
  );
};
