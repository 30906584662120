import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const columnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Client/Contract',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Name',
        field: 'name',
        value: true
      },
      {
        title: 'Type',
        field: 'nodeTypeCode',
        value: true
      },
      {
        title: 'Short Name',
        field: 'code',
        value: true
      },
      {
        title: 'Logo',
        field: 'logoPic',
        value: true
      },
      {
        title: 'Tags',
        field: 'clientContractTags',
        value: true
      },
      {
        title: 'Active',
        field: 'isActive',
        value: true
      },
      {
        title: 'Created At',
        field: 'createdAt',
        value: true
      },
      {
        title: 'Created By',
        field: 'createdBy',
        value: true
      },
      {
        title: 'Updated At',
        field: 'updatedAt',
        value: true
      },
      {
        title: 'Updated By',
        field: 'updatedBy',
        value: true
      }
    ]
  },
  {
    title: 'Attributes',
    value: [
      {
        title: 'Addresses',
        field: 'allAddresses',
        value: true
      },
      {
        title: 'Contacts',
        field: 'allContacts',
        value: true
      }
    ]
  }
];

export const defaultExcludedColumns = ['structureTagNames', 'clientContractBrand', 'notes', 'regionType', 'categoryCode', 'totalChildCount', 'partCount', 'goodPartCount', 'mainAddress', 'mainContact', 'referenceNo1', 'referenceNo2', 'referenceNo3', 'importsessionId', 'siteSchema', 'tradingHrs', 'parentNodeId', 'parentName', 'parentNodeTypeCode', 'parentNodeTypeName', 'parentActive', 'directChildCount' ];
export const initialFilterModel = { columnField: 'nodeTypeCode', operatorValue: 'is', value: 'client' };