import React, { useState, useEffect } from 'react';
import {
  GridColDef,
  GridFeatureModeConstant
} from '@mui/x-data-grid-pro';
import { AlertTitle, Grid } from '@mui/material';
import { ImportSessionResponse, ImportSessionResource, ImportDataGridColumns } from '../../../@types';
import { ImportDataGrid } from '../../../components/layouts/ImportDataGrid';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { ServiceImportsGridContainer, StyledWarningAlert } from './ImportService.styles';
import { ImportDataGridToggleState } from '../../../components/layouts/ImportDataGrid/ImportDataGrid.props';
import { ImportsTableProps, ImportData } from './ImportServiceTable.props';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { SERVICE_IMPORT_TOGGLE_BUTTONS, SERVICE_IMPORT_TOGGLE_BUTTON_VALUES, SERVICE_IMPORT_DATA } from '../../../constants/service.constant';
import { ImportResourceTypeCode, ImportStatusCode, ImportStatusName } from '../../../configs/enums';
import { getImportDataGridDynamicColumnNames, hasColumnListArrayLength } from '../../../utils/array-utils';
import { useCreateImportSession, useGetImportSessionById, useGetImportSessionSummary, useGetImportedResourceBySessionId, useUpdateImportSession } from '../../../queries/import-query';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { ImportServicesButton, ImportServicesLoader } from './ImportService.styles';
import { COLORS } from '../../../configs/colors';
import { CheckItemProps } from '../../../components/organisms/CheckItemGroup';
import { generateImportURL } from '../../../utils/services';
import { setImportSessionData, getImportSessionData, clearImportSessionData, getImportedRecordsStatusCount, getImportFailedRecords } from '../../../utils/imports';
import { Wrapper } from '../../../styled/common.styles';

export const ImportServiceTable: React.FC<ImportsTableProps> = ({
  fileId
}) => {
  window.addEventListener('beforeunload', function() {
    clearImportSessionData(ImportResourceTypeCode.Service);
  });

  const importSessionData = getImportSessionData(ImportResourceTypeCode.Service);

  const [isLoading, setIsLoading] = useState(true);
  const [columnSelectionValues, setColumnSelectionValues] = useState<Array<CheckItemProps>>([]);
  const [columnNames, setColumnNames] = useState<Array<ImportDataGridColumns>>([]);
  const [serviceImports, setServiceImports] = useState<Array<ImportSessionResource>>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [toggleStates, setToggleStates] = useState<Array<ImportDataGridToggleState>>(SERVICE_IMPORT_TOGGLE_BUTTON_VALUES);
  const [showLoader, setShowLoader] = useState(false);
  const [enableImport, setEnableImport] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [resourceIntervalEnabled, setResourceIntervalEnabled] = useState(false);
  const [importData, setImportData] = useState<ImportData>(SERVICE_IMPORT_DATA);
  const [sessionSummaryIntervalEnabled, setSessionSummaryIntervalEnabled] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const addImportSessionQuery = useCreateImportSession(ImportResourceTypeCode.Service);
  const updateImportSessionQuery = useUpdateImportSession(ImportResourceTypeCode.Service);
  const getImportSessionQuery = useGetImportSessionById(ImportResourceTypeCode.Service, importData.sessionId, enabled);
  const getImportResourceQuery = useGetImportedResourceBySessionId(ImportResourceTypeCode.Service, importData.sessionId, resourceIntervalEnabled, {
    page,
    limit: pageSize,
    showErrorRecords: toggleStates[0].value,
    showCreateableRecords: toggleStates[1].value
  });
  const getImportSessionSummaryQuery = useGetImportSessionSummary(ImportResourceTypeCode.Service, importData.sessionId, sessionSummaryIntervalEnabled);

  useEffect(() => {
    !importSessionData?.isImportComplete ?
      setImportData(prev => ({
        ...prev,
        fileId: importSessionData?.fileId || 0,
        sessionId: importSessionData?.sessionId || 0
      }))
      : 
      clearImportSessionData(ImportResourceTypeCode.Service);

    const sessionData = getImportSessionQuery.data;
    if (sessionData) {
      if (
        sessionData.statusCode !== ImportStatusCode.New &&
        sessionData.statusCode !== ImportStatusCode.PreprocessingInprogress && 
        sessionData.statusCode !== ImportStatusCode.ProcessingReady &&
        sessionData.statusCode !== ImportStatusCode.ProcessingInprogress
      ) {
        setEnabled(false);
      }
      switch (sessionData.statusCode) {
        case ImportStatusCode.PreprocessingInprogress:
          setImportData(prev => ({ ...prev, preprocessingStatus: sessionData.importSessionStatus.name }));
          break;
        case ImportStatusCode.PreprocessingComplete:
          setResourceIntervalEnabled(false);
          getImportResourceQuery.refetch();
          setImportData(prev => ({ ...prev, preprocessingStatus: sessionData.importSessionStatus.name }));
          break;
        case ImportStatusCode.PreprocessingFailed:
          setResourceIntervalEnabled(false);
          setImportData(prev => ({
            ...prev,
            preprocessingStatus: sessionData.importSessionStatus.name,
            preprocessingErrorMessage: sessionData.importSessionStatus.name
          }));
          setShowLoader(false);
          break;
        case ImportStatusCode.ProcessingReady:
          setImportData(prev => ({ ...prev, processingStatus: sessionData.importSessionStatus.name }));
          break;
        case ImportStatusCode.ProcessingInprogress:
          setImportData(prev => ({ ...prev, processingStatus: sessionData.importSessionStatus.name }));
          break;
        case ImportStatusCode.ProcessingComplete:
          setImportData(prev => ({ ...prev, processingStatus: sessionData.importSessionStatus.name }));
          setImportSessionData(
            ImportResourceTypeCode.Service,
            { ...importSessionData,
              isImportComplete: true
            }
          );
          break;
        case ImportStatusCode.ProcessingFailed:
          setImportData(prev => ({
            ...prev,
            processingStatus: sessionData.importSessionStatus.name,
            processingErrorMessage: sessionData.importSessionStatus.name
          }));
          setImportSessionData(
            ImportResourceTypeCode.Service,
            { ...importSessionData,
              isImportComplete: true
            }
          );
          break;
      }
    }
  }, [getImportSessionQuery.data]);

  useEffect(() => {
    if (getImportResourceQuery.data?.data.length &&
      importData.processingStatus !== ImportStatusName.ProcessingReady &&
      importData.processingStatus !== ImportStatusName.ProcessingInprogress &&
      importData.processingStatus !== ImportStatusName.ProcessingComplete &&
      importData.processingStatus !== ImportStatusName.ProcessingFailed) {

      // Dynamically setting column selection values and column names
      if (!hasColumnListArrayLength(columnSelectionValues.length, columnNames.length)) {
        const columns = getImportDataGridDynamicColumnNames(getImportResourceQuery.data.data[0]);
        setColumnSelectionValues(columns.columnSelectionValues);
        setColumnNames(columns.columnNames);
      }
      const setServiceResources = async () => {
        setServiceImports(getImportResourceQuery.data.data);  
        setShowLoader(false);
      };
      setServiceResources();
    }
    if (!getImportResourceQuery.data?.data.length && importData.preprocessingStatus === ImportStatusName.PreprocessingComplete) {
      setServiceImports([]);
      setShowLoader(false);
    }
    setIsLoading(false);
  }, [getImportResourceQuery.data, isLoading]);

  useEffect(() => {
    if (importData.processingStatus === ImportStatusName.ProcessingComplete || importData.processingStatus === ImportStatusName.ProcessingFailed) {
      setResourceIntervalEnabled(false);
      setSessionSummaryIntervalEnabled(false);
      const importURL: string = generateImportURL(importData.sessionId);
      setTimeout(() => {
        window.open(importURL, '_blank');
      }, 1500);
    }
  }, [importData.processingStatus]);

  useEffect(() => {
    setImportData(prev => ({ ...prev, preprocessingErrorMessage: '', preprocessingStatus: '', processingStatus: '', processingErrorMessage: '', fileId: fileId }));
    if (fileId !== 0) {
      setImportSessionData(
        ImportResourceTypeCode.Service,
        { ...importSessionData,
          fileId: fileId
        }
      );
      getImportResourceQuery.remove();
      setServiceImports([]);
      setTotalRecords(0);
      setColumnNames([]);
      setColumnSelectionValues([]);
    }
  }, [fileId]);

  useEffect(() => {
    importData.preprocessingStatus !== '' && getImportResourceQuery.refetch();
  }, [page, pageSize, toggleStates]);

  useEffect(() => {
    importData.preprocessingStatus === ImportStatusName.PreprocessingComplete && getImportSessionSummaryQuery.refetch();
  }, [importData.preprocessingStatus]);

  useEffect(() => {
    if (
      importData.preprocessingStatus === ImportStatusName.PreprocessingComplete && getImportSessionSummaryQuery.data && getImportResourceQuery.data &&
      parseInt(getImportedRecordsStatusCount(getImportSessionSummaryQuery.data, ImportStatusCode.PreprocessingComplete)) === getImportResourceQuery.data?.total &&
      toggleStates.every((toggle) => toggle.value)
    ) {
      setTotalRecords(getImportResourceQuery.data?.total);
      setEnableImport(true);
    }
  }, [getImportSessionSummaryQuery.data, getImportResourceQuery.data, importData.preprocessingStatus]);

  const prepareData = async ()=> {
    setImportData(prev => ({
      ...prev,
      preprocessingStatus: ImportStatusName.PreprocessingInprogress,
      processingStatus: '',
      preprocessingErrorMessage: ''
    }));
    setShowLoader(true);
    setPage(0);
    setPageSize(25);
    setTotalRecords(0);
    setToggleStates([ // Resetting the states using a constant doesn't reset the toggles. Therefore the states are hardcoded here
      {
        id: 1,
        value: true
      },
      {
        id: 2,
        value: true
      },
    ]);
    setEnableImport(false);
    const newSession: ImportSessionResponse = await addImportSessionQuery.mutateAsync({
      resourceTypeCode: ImportResourceTypeCode.Service,
      fileId: importData.fileId
    }) as ImportSessionResponse;
    setImportData(prev => ({ ...prev, sessionId: newSession.id }));
    setImportSessionData(
      ImportResourceTypeCode.Service,
      { ...importSessionData,
        sessionId: newSession.id
      }
    );
    setEnabled(true); // Check for the import session status every 2s
    setResourceIntervalEnabled(true);
  };

  const toggleSelect = async (toggleIndex: number, value: boolean) => {
    const updatedToggleStates = [...toggleStates];
    updatedToggleStates[toggleIndex].value = value;
    setToggleStates(updatedToggleStates);
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    getImportResourceQuery.refetch();
  };

  const importServices = async ()=> {
    setEnableImport(false);
    setImportData(prev => ({ ...prev, preprocessingStatus: '', processingStatus: ImportStatusName.ProcessingReady }));
    const data = {
      id: importData.sessionId,
      statusCode: ImportStatusCode.ProcessingReady
    };
    await updateImportSessionQuery.mutateAsync(data);
    setEnabled(true);
    setResourceIntervalEnabled(true);
    setSessionSummaryIntervalEnabled(true);
  };

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Service',
      value: columnSelectionValues
    } ,
  ];

  const columns: GridColDef[] = columnNames;

  return (
    <Wrapper>
      <>
        <Button variant={ButtonVariantProps.Primary} onClick={prepareData}
          disabled={!importData.fileId || importData.preprocessingStatus !== '' || importData.processingStatus !== ''}>
            Prepare Data
        </Button>
        {importData.preprocessingStatus &&
          <Typography variant={TypographyVariantProps.Body1} textColor={importData.preprocessingStatus === ImportStatusName.PreprocessingComplete ? COLORS.Blue : COLORS.DarkGray}>
            Current Status : {importData.preprocessingStatus}
          </Typography>
        }
      </>
      {importData.preprocessingErrorMessage && 
        <Grid ml={1} mt={1}>
          <Typography variant={TypographyVariantProps.H6} textColor={COLORS.TorchRed}>
            {importData.preprocessingErrorMessage}
          </Typography>
        </Grid>
      }
      {!showLoader && !importData.preprocessingErrorMessage && getImportResourceQuery.data && importSessionData?.sessionId &&
        <>
          <Typography variant={TypographyVariantProps.H5} fontWeight={400}>
              Import Services
          </Typography>
          <ServiceImportsGridContainer>
            <ImportDataGrid
              loading={isLoading || getImportResourceQuery.isRefetching}
              columns={columns}
              rows={serviceImports}
              columnSelectionItems={columnSelectionItems}
              rowCount={getImportResourceQuery.data?.total}
              rowsPerPageOptions={[25, 50, 100]}
              pagination={true}
              page={page}
              pageSize={pageSize}
              paginationMode={GridFeatureModeConstant.server}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    parentNodeId: false,
                    referenceNumbers: false,
                    status: false,
                  },
                },
              }}
              componentsProps={{
                toolbar: {
                  printOptions: { disableToolbarButton: true }
                }
              }}
              toggleButtons={SERVICE_IMPORT_TOGGLE_BUTTONS}
              toggleStates={toggleStates}
              onToggleSelect={toggleSelect}
              getRowClassName={(params) => params.row.statusCode === ImportStatusCode.PreprocessingFailed ? 'error-styling' : 'normal-styling'}
              handleRefresh={handleRefresh}
              getRowHeight={() => 'auto'}
            />
          </ServiceImportsGridContainer>
          <Grid container direction="column">   
            <ImportServicesButton>
              {importData.processingErrorMessage && 
              <Grid ml={1} mt={1}>
                <Typography variant={TypographyVariantProps.H6} textColor={COLORS.TorchRed}>
                  {importData.processingErrorMessage}
                </Typography>
              </Grid>
              }
              <Button variant={ButtonVariantProps.Primary} onClick={importServices} disabled={!enableImport}>
                Import Services
              </Button>
              {importData.processingStatus &&
              <Typography variant={TypographyVariantProps.Body1} textColor={importData.processingStatus === ImportStatusName.ProcessingComplete ? COLORS.Blue : COLORS.DarkGray}>
                Current Status : {importData.processingStatus}
              </Typography>
              }
            </ImportServicesButton>
            {(enableImport || importData.processingStatus) &&
            <Grid item mt={2} mb={1}>
              <Typography variant={TypographyVariantProps.Subtitle1} fontWeight={500} textColor={COLORS.LightGreen}>
                Import Successful Records: {getImportedRecordsStatusCount(getImportSessionSummaryQuery.data, ImportStatusCode.ProcessingComplete)}
              </Typography>
              <Typography variant={TypographyVariantProps.Subtitle1} fontWeight={500} textColor={COLORS.Red}>
                Import Failed Records: {getImportedRecordsStatusCount(getImportSessionSummaryQuery.data, ImportStatusCode.ProcessingFailed)}
              </Typography>
              {
                getImportFailedRecords(getImportSessionSummaryQuery.data) &&
                <StyledWarningAlert severity="error">
                  <AlertTitle>Failed File Lines</AlertTitle>
                  {getImportFailedRecords(getImportSessionSummaryQuery.data)}
                </StyledWarningAlert>
              }
              <Typography variant={TypographyVariantProps.Subtitle1} fontWeight={500} textColor={COLORS.Blue}>
                Total File Records: {totalRecords}
              </Typography>
              <Grid mt={2}>
                <Button variant={ButtonVariantProps.Secondary} onClick={() => getImportSessionSummaryQuery.refetch()} 
                  disabled={
                    importData.processingStatus !== ImportStatusName.ProcessingReady &&
                    importData.processingStatus !== ImportStatusName.ProcessingInprogress &&
                    importData.processingStatus !== ImportStatusName.ProcessingComplete &&
                    importData.processingStatus !== ImportStatusName.ProcessingFailed
                  }>
                  Refresh Import Records Status
                </Button>
              </Grid>
            </Grid>
            }
          </Grid>
        </>
      }
      {showLoader &&
        <ImportServicesLoader><Loader color={LoaderColorProps.Primary} /></ImportServicesLoader>
      }
    </Wrapper>
  );
};