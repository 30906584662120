import React from 'react';
import { Grid } from '@mui/material';
import { RestrictionBox } from './TLCRestriction.styles';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { SwHwFwSelector } from '../../molecules/SwHwFwSelector/SwHwFwSelector';
import { TLCRestrictionProps } from './TLCRestriction.props';
import { PartTypeSearchItem } from '../../molecules/PartTypeSearchItem';
import { MultiSelectLiveSearchBox } from '../../atoms/MultiSelectLiveSearchBox/MultiSelectLiveSearchBox';
import { useGetSearchTLCPartTypeLocation, useGetSearchTLCPartTypes } from '../../../queries/part-type-query';
import { areApplicableNodeIdsSame } from '../../../utils/part-type';
import { PartType } from '../../../@types';
import { EMPTY_FIELD_MESSAGE } from '../../../constants/common';

export const TLCRestriction: React.FC<TLCRestrictionProps> = ({
  data,
  isRequired,
  onChange
}) => {
  const searchPartTypesQuery = useGetSearchTLCPartTypes();
  const searchLocationQuery = useGetSearchTLCPartTypeLocation();

  return (
    <RestrictionBox>
      <Grid container mt="1px" spacing={GRID_SPACING}>
        <Grid item xs={6}>
          <MultiSelectLiveSearchBox
            isError = {isRequired.partType}
            helperText = {isRequired.partType ? EMPTY_FIELD_MESSAGE : ''}
            title="Part Type Search"
            timeOffset={400}
            value={data.selectedSearchPartTypes}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <PartTypeSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(selectedPartTypes: PartType[]) => {
              onChange({ ...data, selectedSearchPartTypes: selectedPartTypes },{ ...isRequired, partType: false });
            }}
            onApiInvoke={async (searchQuery: string) => {
              return await searchPartTypesQuery.mutateAsync(searchQuery);
            }}
          />
        </Grid>
      </Grid>
      <Grid container mt="1px" spacing={GRID_SPACING}>
        <Grid item xs={6}>
          <MultiSelectLiveSearchBox
            isError = {isRequired.location}
            helperText = {isRequired.location ? EMPTY_FIELD_MESSAGE : ''}
            title="Location Search"
            timeOffset={400}
            value={data.locationSearchData}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(selectedLocations: any[]) => {
              onChange({ ...data, locationSearchData: selectedLocations }, { ...isRequired, location: false });
            }}
            onApiInvoke={async (searchQuery: string) => {
              const requestData = {
                isIncludeChild: data.isIncludeChilds,
                searchData: searchQuery
              };
              return await searchLocationQuery.mutateAsync(requestData);
            }}
          />
        </Grid>
        <Grid item ml={2} xs={4}>
          <Checkbox
            color={CheckboxColorProps.Primary}
            checked={data.isIncludeChilds}  
            disabled={false}
            onChange={(event) => onChange({ ...data, isIncludeChilds: event.target.checked })}
          /> Include Child Nodes
        </Grid>   
      </Grid>
      <Grid container mt="1px" spacing={GRID_SPACING}>
        <Grid item xs={6}>
          <MultiSelectCheckmarksMenu
            error = {isRequired.condition}
            helperText = {isRequired.condition ? EMPTY_FIELD_MESSAGE : ''} 
            id="part-type-condition-menu"
            label="Select Condition"
            required = {isRequired.condition}
            items={data.conditions}
            selectedValue={data.selectedConditions}
            onChange={(conditions) => onChange({ ...data, selectedConditions: conditions ? conditions.split(',') : [] }, { ...isRequired, condition: false })}
          />
        </Grid>
      </Grid>
      {data.selectedSearchPartTypes.length === 1 && 
        <SwHwFwSelector
          items={data.dropDownItems}
          selectedValue={data.versions}
          onChange={(value) => onChange({ ...data, versions: value })}          
        />
      }
      {data.selectedSearchPartTypes.length > 0 && areApplicableNodeIdsSame(data.selectedSearchPartTypes) && 
      <Grid container mt="1px" spacing={GRID_SPACING}>
        <Grid item xs={6}>
          <MultiSelectCheckmarksMenu
            id="part-type-fleetTag-menu"
            label="Select Fleet Tag"
            required
            items={data.fleetTags}
            selectedValue={data.selectedFleetTags}
            onChange={(value) => onChange({ ...data, selectedFleetTags: value ? value.split(',') : [] })}
          />
        </Grid>
      </Grid>  
      }              
    </RestrictionBox>
  );
};