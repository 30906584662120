import { styled, FormControl, MenuItem } from '@mui/material';
import { COLORS } from '../../../configs/colors';

export const StyledFormControl = styled(FormControl)`
  min-width: 300px;
`;

export const StyledMenuItem = styled(MenuItem)`
  min-width: 300px;
  max-width: 300px;
`;

export const MicroText = styled('p')`
  font-size: 10px;
  font-weight: bold;
  overflow-wrap: break-word;
  color: ${COLORS.DarkGray};
`;
