import { Box, Stack, styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';

export const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const Footer = styled(Stack)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TitleLabel = styled(Typography)`
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const ContentBox = styled('div')`
  margin-top: 10px;
  margin-bottom: 10px;
`;
