import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';

export const MultiSelectMenu = styled(MultiSelectCheckmarksMenu)`
  width: 150px;
`;

export const SwitchGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
`;