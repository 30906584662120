import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { 
  Typography, 
  TypographyFontWeightProps, 
  TypographyVariantProps 
} from '../../../../../../components/atoms/Typography';
import { TextField } from '../../../../../../components/atoms/TextField';
import { isEmptyString } from '../../../../../../utils/common';
import { GetParameterFormFragmentProps } from './GetParameterFormFragment.props';
import { useGetParameterData } from '../../../../../../queries/interactions-query';
import { COLORS } from '../../../../../../configs/colors';

export const GetParameterFormFragment: React.FC<GetParameterFormFragmentProps> = ({
  preSelectedData,
  isError,
  service,
  value,
  onChange,
}) => {

  const getParameterData = useGetParameterData(service.id, preSelectedData?.selectedParameter || '', service.mainLocationNodeId || 0, service.subLocationNodeId || 0, service.clientId);

  useEffect(() => {
    preSelectedData && onChange({ 
      fieldTitle: preSelectedData.fieldTitle,
      selectedParameter: preSelectedData.selectedParameter,
      parameterValue: ''
    });
  }, [preSelectedData]);

  useEffect(() => {
    getParameterData.data && onChange({ 
      ...value,
      parameterValue: getParameterData.data.parameterValue
    });
  }, [getParameterData.data]);

  return (
    <Stack spacing={2}>
      <Typography
        variant={TypographyVariantProps.Body2}
        fontWeight={TypographyFontWeightProps.Bold}
      >
        Get Parameter
      </Typography>
      <Typography
        variant={TypographyVariantProps.Body2}
        fontWeight={TypographyFontWeightProps.Medium}
      >
        {preSelectedData?.fieldTitle}
      </Typography>
      <TextField
        value={value?.parameterValue}
        onChange={(parameterValue) => onChange({ 
          ...value,
          parameterValue: parameterValue
        })}
        error={isError && (!value?.parameterValue || isEmptyString(value?.parameterValue))}
      />
      {isError && (!value?.parameterValue || isEmptyString(value?.parameterValue)) &&
      <Stack textAlign="right"><Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography></Stack>}
    </Stack>
  );
};