import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LogoutOutlined, AccountCircleOutlined } from '@mui/icons-material';
import { ProfileImage } from '../Drawer.styles';
import { FooterWrapper, DividerStyled, ProfileContainerBox, ProfileImageBox, ProfileWrapper, LogoutImageWrapper } from './DrawerFooter.styles';
import { useGetLoggenInUserData } from '../../../../queries/user-query';
import { LoggedInUser } from '../../../../@types';
import { SAMPLE_LOGIN_USER } from '../../../../constants/user.constant';

export const DrawerFooter: React.FC = () => {
  const { logout } = useAuth0();
  const [userData, setUserData] = useState<LoggedInUser>(SAMPLE_LOGIN_USER);

  const getLoggenInUserData = useGetLoggenInUserData();

  useEffect(() => {
    getLoggenInUserData.data && setUserData({
      ...userData,
      userName: getLoggenInUserData.data.userName,
      profilePic: getLoggenInUserData.data.profilePic
    });
  }, [getLoggenInUserData.data]);

  const onLogOut = useCallback(() => {
    localStorage.clear();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <FooterWrapper>
      <DividerStyled />
      <ProfileContainerBox>
        <ProfileImageBox>
          {(userData.profilePic && userData.profilePic !== '') ? (
            <ProfileImage src={userData.profilePic} />
          ):(
            <AccountCircleOutlined fontSize="large"/>
          )}
          <ProfileWrapper>
            {userData.userName}
          </ProfileWrapper>
        </ProfileImageBox>
        <LogoutImageWrapper onClick={onLogOut}>
          <LogoutOutlined />
        </LogoutImageWrapper>
      </ProfileContainerBox>
    </FooterWrapper>
  );
};
