import React from 'react';
import { Grid, Stack } from '@mui/material';
import { ServiceBillingProps } from './ServiceBilling.props';
import { Accordian } from '../../atoms/Accordian';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { SelectMenu } from '../../atoms/SelectMenu';
import { BILLABLE_TYPES } from '../../../constants/service.constant';
import { HorizontalAlignProps } from '../../atoms/InlineGroup';
import { SwitchGrid } from './ServiceBilling.styles';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { TextField } from '../../atoms/TextField';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { InvoiceList } from '../../molecules/InvoiceList';

export const ServiceBilling: React.FC<ServiceBillingProps> = ({
  billingData,
  onChange,
  onSave,
  onAddForm
}) => {
  return (
    <Accordian title="Billing" defaultExpanded={true}>
      <Grid container spacing={GRID_SPACING}>
        <Grid item xs={12} md={4} mt={1} direction="column">
          <Stack rowGap={GRID_SPACING} alignItems={HorizontalAlignProps.Start}>
            <SwitchGrid>
              <FormControlLabel
                control={
                  <Switch
                    color={ColorProps.Success}
                    onChange={(event) => onChange({ ...billingData, billingReviewed: event.target.checked })}
                    checked={billingData.billingReviewed}
                  />
                }
                label="Reviewed"
                labelPlacement={FormControlLabelPlacementProps.Start}
              />
            </SwitchGrid>
            <SelectMenu
              id="billable-type"
              label="Billable Type"
              items={BILLABLE_TYPES}
              selectedValue={billingData.billableType || BILLABLE_TYPES[0].value}
              onChange={(text: string) => onChange({ ...billingData, billableType: text })}
            />
            <SwitchGrid>
              <FormControlLabel
                control={
                  <Switch
                    color={ColorProps.Success}
                    onChange={(event) => onChange({ ...billingData, clientInvoiced: event.target.checked })}
                    checked={billingData.clientInvoiced}
                  />
                }
                label="Client Invoiced"
                labelPlacement={FormControlLabelPlacementProps.Start}
              />
            </SwitchGrid>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} mt={2}>
          <Grid>
            <TextField
              fullWidth
              label="Billing Notes"
              multiline
              rows={5}
              value={billingData.billingNotes}
              onChange={(billingNotes) => onChange({ ...billingData, billingNotes })}
            />
          </Grid>
          <Grid mt={2}>
            <TextField
              fullWidth
              label="Private Notes"
              multiline
              rows={5}
              value={billingData.privateNotes}
              onChange={(privateNotes) => onChange({ ...billingData, privateNotes })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} mt={2}>
          <InvoiceList 
            heading= "Internal Invoices"
            invoiceListValues= {billingData.internalInvoices}
            onChange={(internalInvoices) => onChange({ ...billingData, internalInvoices })}
            onCreate= {(invoice) => onChange({ ...billingData, internalInvoices: [...billingData.internalInvoices, invoice] })}
            onDelete= {(internalInvoices) => onChange({ ...billingData, internalInvoices })}
            onAddForm= {(formData) => onAddForm && onAddForm(formData)}
          />
          <InvoiceList 
            heading= "External Invoices"
            invoiceListValues= {billingData.externalInvoices}
            onChange={(externalInvoices) => onChange({ ...billingData, externalInvoices })}
            onCreate= {(invoice) => onChange({ ...billingData, externalInvoices: [...billingData.externalInvoices, invoice] })}
            onDelete= {(externalInvoices) => onChange({ ...billingData, externalInvoices })}
            onAddForm= {(formData) => onAddForm && onAddForm(formData)}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button variant={ButtonVariantProps.Secondary} onClick={() => onSave(billingData)}>Save</Button>
      </Grid>
    </Accordian>
  );
};