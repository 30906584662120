import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Button,
  ButtonColorProps,
  ButtonSizeProps,
  ButtonVariantProps
} from '../../atoms/Button';
import { StyledTypography } from './EditLiveSearchField.styles';
import { EditLiveSearchFieldProps } from './EditLiveSearchField.props';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../LocationSearchItem';
import { useSearchManufacturerNodes, useSubLocationsForUpdateServices } from '../../../queries/live-search-query';
import { LocationNodeData } from '../../../@types';

export const EditLiveSearchField: React.FC<EditLiveSearchFieldProps> = ({
  isEditable,
  textColor = '',
  textFontSize = '',
  value,
  mainLocationId,
  isManufacturer = false,
  onChange,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [locationName, setLocationName] = useState('');
  const [locationId, setLocationId] = useState(0);

  const subLocationsForUpdateServicesQuery = useSubLocationsForUpdateServices();
  const searchNodeQuery = useSearchManufacturerNodes();

  useEffect(() => {
    if (value && value !== textValue) {
      setTextValue(value);
      setLocationName(value);
    }
  }, [value]);

  const handleOnSubmit = () => {
    onChange({
      name: locationName,
      id: locationId === 0 ? mainLocationId : locationId
    });
    setIsEdit(false);
    setIsShown(false);
  };

  const handleCancel = () => {
    setTextValue(value);
    setIsEdit(false);
    setIsShown(false);
  };

  return (
    <Grid
      container 
      onMouseEnter={() => isEditable && setIsShown(true)}
      onMouseLeave={() => isEditable && setIsShown(false)}
      alignItems="center"
    >
      {isEdit ?
        <Grid item xs md>
          <LiveSearchBox
            title="Location Search"
            timeOffset={400}
            value={locationName || ''}
            onClearValue={() => {
              setLocationName('');
              setLocationId(NaN);
            }}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: LocationNodeData) => {
              setLocationName(obj.name);
              setLocationId(obj.id);
            }}
            onApiInvoke={async (name: string) => {
              if(!isManufacturer) {
                const sublocations = await subLocationsForUpdateServicesQuery.mutateAsync({ id: mainLocationId, name });
                return sublocations;
              } else {
                return await searchNodeQuery.mutateAsync(name);
              }
            }}
          />
        </Grid>
        :
        <Grid item>
          <StyledTypography
            color={textColor}
            fontSize={textFontSize}
          >
            {locationName || '-'}
          </StyledTypography>
        </Grid>
      }
      {isEdit &&
        <Grid item>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleOnSubmit}
          >
            <CheckOutlined fontSize="small" color={ButtonColorProps.Success} />
          </Button>
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={handleCancel}
          >
            <CloseOutlined fontSize="small" color={ButtonColorProps.Error} />
          </Button>
        </Grid>
      }
      {isShown && !isEdit &&
        <Button
          variant={ButtonVariantProps.Icon}
          size={ButtonSizeProps.Small}
          onClick={() => setIsEdit(true)}
        >
          <EditOutlined fontSize="small" color={ButtonColorProps.Warning} />
        </Button>
      }
    </Grid>
  );
};