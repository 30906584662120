import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { exportDispatchManifest, exportFiles, uploadFiles } from '../api/uploadedFiles';
import { UploadedCsvFile, UploadedFile } from '../@types/uploaded-files.type';
import { getUploadedCsvFile, getUploadedFilePresignedUrlById, getUploadedFiles, updateUploadedFileData, getUploadedFilePresignedUrlByKey } from '../api/uploadedFiles';
import { ExportFilesKey, UploadedFilesKey, UploadedImagesKey, UploadMultipleImagesKey } from '../configs/uploaded-files';
import { ConfigurationHistoriesKey } from '../configs/part-type';

export const useExportFile = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (exportInfo: any) => {
    const response = await exportFiles(exportInfo);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ExportFilesKey]);
    },
  });
};

export const useGetUploadedFiles = (entityId: number, entityType: string, notIncludeUrlsByDefault?: boolean): UseQueryResult<[UploadedFile]> => {
  return useQuery([UploadedFilesKey, entityId, entityType], () => entityId && getUploadedFiles(entityId, entityType, notIncludeUrlsByDefault));
};

export const useGetUploadedImages = (entityId: number, entityType: string): UseQueryResult<[UploadedFile]> => {
  return useQuery([UploadMultipleImagesKey, entityId, entityType], () => entityId !== -1 && getUploadedFiles(entityId, entityType), { enabled: false });
};

export const useUpdateUploadedFileAditionalData = (entityId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    await updateUploadedFileData(data.id, data.isMain);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([UploadedImagesKey]);
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, entityId]);
    },
  });
};

export const useGetUploadedCsvFile = (key: string): UseQueryResult<UploadedCsvFile> => {
  return useQuery([UploadedFilesKey], () => getUploadedCsvFile(key), { enabled: false });
};

export const useGetUploadedFilePresignedUrlById = (id: number, contentDispositionType?: string): UseQueryResult<UploadedCsvFile> => {
  return useQuery([UploadedFilesKey, id, contentDispositionType], () => getUploadedFilePresignedUrlById(id, contentDispositionType), { enabled: false, refetchOnWindowFocus: false });
};

export const useGetUploadedFilePresignedUrlByKey = (key: string, mimeType?: string, contentDispositionType?: string): UseQueryResult<UploadedCsvFile> => {
  return useQuery([UploadedFilesKey, key, mimeType, contentDispositionType], () => getUploadedFilePresignedUrlByKey(key, mimeType, contentDispositionType), { enabled: false, refetchOnWindowFocus: false });
};

export const useExportDispatchManifest = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (exportInfo: any) => {
    const response = await exportDispatchManifest(exportInfo);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ExportFilesKey]);
    },
  });
};

export const useUploadFiles = (entityId: number, entityType: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    await uploadFiles(data.entityId, data.formData, data.entityType);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ConfigurationHistoriesKey, entityId]);
      await queryClient.invalidateQueries([UploadMultipleImagesKey, entityId, entityType]);
    },
  });
};