import { styled } from '@mui/material/styles';
import MaterialTypography, { TypographyProps } from '@mui/material/Typography';
import { TypographyFontWeightProps } from './Typography.props';

export const Element = styled(MaterialTypography)(
  (
    props: TypographyProps & {
      textColor?: string;
      fontWeight?: TypographyFontWeightProps | number;
    }
  ) => `
  font-family: 'Inter';
  color: ${props.textColor};
  font-weight: ${props.fontWeight};
`
);