import React, { useEffect, useState } from 'react';
import { DropdownItem } from '../../../../../../components/atoms/SelectMenu';
import { FaultSelectionsForm } from '../../../../../../components/molecules/FaultSelectionsForm';
import { useGetFilteredNodeAttributesByCustomRange } from '../../../../../../queries/structure-query';
import { NodeAttributeType } from '../../../../../../configs/enums';
import { FaultSelectionsFormFragmentProps } from './FaultSelectionsFormFragment.props';

export const FaultSelectionsFormFragment: React.FC<FaultSelectionsFormFragmentProps> = ({
  service,
  value,
  onChange,
  isError
}) => {
  const [options, setOptions] = useState<Array<DropdownItem>>([]);

  const getNodeAttributesQuery = useGetFilteredNodeAttributesByCustomRange(`${service.contractId}`, [NodeAttributeType.FaultCode], service.serviceTypeCode);

  useEffect(() => {
    if (service.mainLocationNodeId && service.serviceTypeCode) {
      getNodeAttributesQuery.refetch();
    }
  }, [service.mainLocationNodeId, service.serviceTypeCode]);

  useEffect(() => {
    if (getNodeAttributesQuery.data) {
      const attributes = getNodeAttributesQuery.data;
      const faultAttributes = attributes.filter((obj: any) => obj.propertyTypeCode === NodeAttributeType.FaultCode);
      const faults: Array<DropdownItem> = faultAttributes.map((obj: any) => {
        const name = obj?.value?.name;
        return {
          label: name || '_',
          value: JSON.stringify({
            id: obj.id,
            name: name,
            customerId: obj.value?.customerId,
            customerId2: obj.value?.customerId2,
            categoryId: obj.value?.categoryId
          }),
        };
      });
      setOptions(faults);
    }
  }, [getNodeAttributesQuery.data]);

  return (
    <FaultSelectionsForm 
      options={options} 
      value={value} 
      onChange={onChange}
      isError={isError}  
    />
  );
};
