import { Grid, TableCell, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGridContainer = styled(Grid)`
  border: 2px solid #eeeeee;
  border-radius: 5px;
  padding: 15px 15px 10px 10px;
  padding-top: 5px;
`;

export const StyledTableCell = styled(TableCell)`
  border: 0;
`;

export const StyledGreyTextField = styled(TextField)`
  background-color: #DCDCDC;
  color: black;
`;
