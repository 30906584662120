import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { ServiceLogResponse } from '../@types/service.type';
import { getServiceLogsByServiceId, hideServiceLog, getServiceLogsById, updateServiceLogsById, createServiceLogsForService } from '../api/services';
import { ServiceLogAllKey, ServiceLogOneKey } from '../configs/services';

export const useGetServiceLogsByServiceId = (id: number): UseQueryResult<ServiceLogResponse[]> => {
  return useQuery([ServiceLogAllKey, id], () => getServiceLogsByServiceId(id));
};

export const useGetServiceLogsById = (id: number): UseQueryResult<ServiceLogResponse> => {
  return useQuery([ServiceLogOneKey, id], () => getServiceLogsById(id), { enabled: false });
};

export const useHideServiceLog = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await hideServiceLog(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceLogAllKey]);
    },
  });
};

export const useAddServiceLogsForService = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createServiceLogsForService(serviceId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
    },
  });
};

export const useUpdateServiceLogsById = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateServiceLogsById(data.id, data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ServiceLogAllKey, response.serviceId]);
    },
  });
};