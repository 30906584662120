import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';

export const LogoImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

export const LogoImage = styled('img')`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`;
