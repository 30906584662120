import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import { GridSortModel } from '@mui/x-data-grid-pro';
import {
  createServiceType,
  getServiceTypeAvailableStatuses,
  getServiceTypeByCode,
  getServiceTypeByCodeForServiceUpdate,
  getServiceTypes,
  updateServiceType
} from '../api/serviceTypes';
import { getAllServiceTargets } from '../api/services';
import { ServiceStatus, ServiceTargetResponse, ServiceType, ServiceTypeResponse } from '../@types/service.type';
import { ServiceTypeCodeKey, ServiceTypeKey, ServiceTypeTargetOptionKey } from '../configs/service-type';
import { AppServiceTypesKey } from '../configs/services';

export const useCreateServiceType = (code: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createServiceType(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceTypeKey]);
      await queryClient.invalidateQueries([ServiceTypeKey, code]);
    },
  });
};

export const useUpdateServiceType = (code: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateServiceType(data.code, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceTypeKey]);
      await queryClient.invalidateQueries([ServiceTypeKey, code]);
    },
  });
};

export const useGetServiceTypesByCodeForServiceUpdate = (code: string): UseQueryResult<ServiceTypeResponse> => {
  return useQuery([ServiceTypeKey, code], () => code !== '-1' && getServiceTypeByCodeForServiceUpdate(code));
};

export const useGetServiceTypesByCode = (code: string): UseQueryResult<ServiceTypeResponse> => {
  return useQuery([ServiceTypeKey, code], () => getServiceTypeByCode(code));
};

export const useGetServiceTypesByShortName = (code: string): UseQueryResult<ServiceTypeResponse> => {
  return useQuery([ServiceTypeCodeKey, code], () => getServiceTypeByCode(code), {
    select: (response: any) => response,
    retry: false,
    enabled: false
  });
};

export const useGetAllServiceTargets = (): UseQueryResult<[ServiceTargetResponse]> => {
  return useQuery([ServiceTypeTargetOptionKey], () => getAllServiceTargets());
};

export const useGetAllServiceTypes = (sortModel?: GridSortModel): UseQueryResult<ServiceTypeResponse[]> => {
  return useQuery([ServiceTypeKey], () => getServiceTypes(sortModel));
};

export const useGetServiceTypeAvailableStatuses = (): UseMutationResult<ServiceStatus[]> => {
  return useMutation(async (data: any) => {
    return await getServiceTypeAvailableStatuses(data);
  });
};

export const useGetServiceTypes = (): UseQueryResult<[ServiceType]> => {
  return useQuery([AppServiceTypesKey], () => getServiceTypes(), {
    select: (response: any) => response,
    staleTime: 2 * 60 * 60 * 1000
  });
};
