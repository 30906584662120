import TreeModel from 'tree-model';
import { Permission } from '../@types/permission.type';
import { ROOT_NODE } from '../configs/permissions';

export const flatten = (nodes: Permission[]) => {
  const flattedNodes: Permission[] = [];
  const tree = new TreeModel();

  const rootNode = tree.parse({
    ...ROOT_NODE,
    children: nodes
  });

  rootNode.walk((node) => {
    if (node.model.children.length === 0) {
      flattedNodes.push(node.model);
    }
    return true;
  });

  return flattedNodes;
};