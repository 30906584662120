import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { 
  GridColDef,
  GridFeatureModeConstant,
  GridFilterModel,
  GridValueGetterParams,
  GridSelectionModel,
  GridSortModel,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro';
import { Snackbar, Stack } from '@mui/material';
import { Wrapper } from './PartMovementList.styles';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { useGetPartConditions, useGetPartMovementHistory } from '../../../queries/part-query';
import { PartHistoryMovementListResponse } from '../../../@types/part.type';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { formatDate } from '../../../utils/common';
import { CustomDatepicker } from '../../../components/molecules/CustomDatepicker';
import { extractDateByCode } from '../../../utils/attendanceReport';
import { DateTypeNames } from '../../../@types/attendanceReport.type';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { GridContainer } from '../../../styled/common.styles';
import { ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse } from '../../../@types';
import { PART_TYPE_SERIALISED_NATURE } from '../../../constants/partType';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { SavedReportTypeList } from '../../../configs/enums';
import { useAddNewReport, useUpdateReportByCode, useGetReportByCode, useGetDefaultReportByReportType } from '../../../queries/report-query';
import { generateReportURL } from '../../../utils/report';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { useGetAllServiceTypes } from '../../../queries/service-type-query';
import { useGetServiceStatuses } from '../../../queries/service-status-query';
import { columnSelectionItems } from '../../../constants/partMovement';

export const PartMovementList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reportCode = searchParams.get('savedReportCode');
  const partId = searchParams.get('partId');
  
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [partMovementHistory, setPartMovementList] = useState<PartHistoryMovementListResponse>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [startDate, setStartDate] = useState<string>(partId ? '' : DateTypeNames.Yesterday);
  const [endDate, setEndDate] = useState<string>(partId ? '' : DateTypeNames.Today);
  const [reportName, setReportName] = useState('');
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [conditionList, setConditionList] = useState<DropdownItem[]>([]);
  const [serviceTypeList, setServiceTypeList] = useState<DropdownItem[]>([]);
  const [serviceStatusList, setServiceStatusList] = useState<DropdownItem[]>([]);

  const partMovementHistoryQuery = useGetPartMovementHistory({
    filterModel,
    page,
    pageSize,
    sortModel,
    startDate: extractDateByCode(startDate) || '',
    endDate: extractDateByCode(endDate) || '',
    partId: partId || ''
  });
  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.PartMovementList);
  const getPartConditions = useGetPartConditions();
  const getServiceTypes = useGetAllServiceTypes();
  const getServiceStatuses = useGetServiceStatuses();

  useEffect(() => {
    if (getPartConditions.data) {
      setConditionList(getPartConditions.data.map(condition => (
        { value: condition.label, label: condition.label }
      )));
    }
  }, [getPartConditions.data]);

  useEffect(() => {
    if (getServiceTypes.data) {
      setServiceTypeList(getServiceTypes.data.map(serviceType => (
        { value: serviceType.name, label: serviceType.name }
      )));
    }
  }, [getServiceTypes.data]);

  useEffect(() => {
    if (getServiceStatuses.data) {
      setServiceStatusList(getServiceStatuses.data.map(serviceStatus => (
        { value: serviceStatus.name, label: serviceStatus.name }
      )));
    }
  }, [getServiceStatuses.data]);

  useEffect(() => {
    setColumns([
      {
        field: 'createdAt',
        headerName: 'Timestamp',
        type: 'date',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt),
      },
      {
        field: 'nodeName',
        headerName: 'User',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.nodeName,
      },
      {
        field: 'createdByRole',
        headerName: 'User Role',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.createdByRole,
      },
      {
        field: 'historyTypeName',
        headerName: 'Part History Type',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.historyTypeName,
      },
      {
        field: 'partId',
        headerName: 'Asset ID',
        type: 'number',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.partId,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'serial1',
        headerName: 'Serial1',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.serial1,
      },
      {
        field: 'serial2',
        headerName: 'Serial2',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.serial2,
      },
      {
        field: 'serial3',
        headerName: 'Serial3',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.serial3,
      },
      
      {
        field: 'conditionName',
        headerName: 'Condition',
        type: 'singleSelect',
        width: 200,
        valueOptions: conditionList,
        valueGetter: (params: GridValueGetterParams) => params.row.conditionName,
      },
      {
        field: 'quantity',
        headerName: 'QTY',
        type: 'number',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.quantity,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.notes,
      },
      {
        field: 'softwareVersion',
        headerName: 'Software Ver',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.softwareVersion,
      },
      {
        field: 'firmwareVersion',
        headerName: 'Firmware Ver',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.firmwareVersion,
      },
      {
        field: 'hardwareVersion',
        headerName: 'Hardware Rev',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.hardwareVersion,
      },
      {
        field: 'fleetTagName',
        headerName: 'Fleet Tag',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.fleetTagName,
      },
      {
        field: 'shipmentId',
        headerName: 'Shipment ID',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.shipmentId,
      },
      {
        field: 'partTypeId',
        headerName: 'Part Type ID',
        type: 'number',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.partTypeId,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'partTypeName',
        headerName: 'Part Type Name',
        width: 150,
        valueGetter: (params: GridValueGetterParams) => params.row.partTypeName,
      },
      {
        field: 'serialised',
        headerName: 'Serialised',
        type: 'singleSelect',
        valueOptions: PART_TYPE_SERIALISED_NATURE,
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.serialised,
      },
      {
        field: 'partTypeCategoryCode',
        headerName: 'Part Type Category',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.partTypeCategoryCode,
      },
      {
        field: 'partTypeIsActive',
        headerName: 'Part Type Active',
        type: 'singleSelect',
        width: 150,
        valueOptions: [
          { value: 'any', label: 'Any' },
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ],
        valueGetter: (params: GridValueGetterParams) => params.row?.partTypeIsActive ? 'Yes' : 'No',
      },
      {
        field: 'serviceId',
        headerName: 'Service ID',
        type: 'number',
        width: 100,
        valueGetter: (params: GridValueGetterParams) => params.row.serviceId,
        valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
      },
      {
        field: 'serviceTypeName',
        headerName: 'Service Type',
        type: 'singleSelect',
        width: 150,
        valueOptions: serviceTypeList,
        valueGetter: (params: GridValueGetterParams) => params.row.serviceTypeName,
      },
      {
        field: 'serviceStatusName',
        headerName: 'Service Status',
        type: 'singleSelect',
        width: 150,
        valueOptions: serviceStatusList,
        valueGetter: (params: GridValueGetterParams) => params.row.serviceStatusName,
      },
      {
        field: 'customerId1',
        headerName: 'Service Customer ID 1',
        width: 170,
        valueGetter: (params: GridValueGetterParams) => params.row.customerId1,
      },
      {
        field: 'customerId2',
        headerName: 'Service Customer ID 2',
        width: 170,
        valueGetter: (params: GridValueGetterParams) => params.row.customerId2,
      },
      {
        field: 'customerId3',
        headerName: 'Service Customer ID 3',
        width: 170,
        valueGetter: (params: GridValueGetterParams) => params.row.customerId3,
      },
      {
        field: 'clientHierarchyName',
        headerName: 'Client/Contract/Brand',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.clientHierarchyName,
      },
      
      {
        field: 'sourceNodeName',
        headerName: 'Source Node',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.sourceNodeName,
      },
      {
        field: 'sourceLocation',
        headerName: 'Source Location',
        width: 250,
        valueGetter: (params: GridValueGetterParams) => params.row.sourceLocation,
        filterable: false
      },
      {
        field: 'destinationNodeName',
        headerName: 'Destination Node',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.destinationNodeName,
      },
      {
        field: 'destinationLocation',
        headerName: 'Destination Location',
        width: 250,
        valueGetter: (params: GridValueGetterParams) => params.row.destinationLocation,
        filterable: false
      },
    ]);
  }, [conditionList, serviceTypeList, serviceStatusList]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel(filterModel);
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  TabHandler(`Movements | ${PLATFORM_NAME}`);

  useEffect(() => {
    partMovementHistoryQuery.data && setPartMovementList(partMovementHistoryQuery.data);
  }, [partMovementHistoryQuery.data]);

  useEffect(() => {
    if ((page || pageSize || filterModel || sortModel) && ((startDate && endDate) || partId)) {
      partMovementHistoryQuery.refetch();
    }
  }, [page, pageSize, filterModel, sortModel, startDate, endDate, partId]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.PartMovementList]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
      setStartDate(defaultReportData.data[0].dataGridProperties?.startDate || '');
      setEndDate(defaultReportData.data[0].dataGridProperties?.endDate || '');
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);


  useEffect(() => {
    if (reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Structure | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties?.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties?.pageSize);
      setStartDate(reportQueryData.dataGridProperties?.startDate || '');
      setEndDate(reportQueryData.dataGridProperties?.endDate || '');
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }   
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || [],
        startDate: startDate,
        endDate: endDate
      },
      reportTypeName: SavedReportTypeList.PartMovementList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel, startDate, endDate]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Movements
          </Typography>
        </InlineGroup>
      </Wrapper>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mb={2} mt={2}>
        <CustomDatepicker name="Start Date" setDate={setStartDate} dateValue={startDate} />
        <Typography>to</Typography>
        <CustomDatepicker name="End Date" setDate={setEndDate} dateValue={endDate} />
      </Stack>
      <Grid container>
        <GridContainer>
          <Grid>
            <Snackbar
              open={openToast}
              autoHideDuration={4000}
              message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
              onClose={() => setOpenToast(false)}
            />
          </Grid>
          <CustomDataGrid
            columns={columns}
            rows={partMovementHistory?.data || []}
            rowCount={partMovementHistory?.total}
            exportFileName={ListType.PartMovementList}
            enableCSVExport={true}
            columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
            filterMode={GridFeatureModeConstant.server}
            onFilterModelChange={(filterModel: GridFilterModel) => onFilterChange(filterModel)}
            loading={partMovementHistoryQuery.isFetching}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sortingMode={GridFeatureModeConstant.server}
            onSortModelChange={handleSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: false }
              }
            }}
            filterModel={filterModel}
            getRowHeight={() => 'auto'}
            setColumns={setColumns}
            sortModel={sortModel}
            startDate={extractDateByCode(startDate) || ''}
            endDate={extractDateByCode(endDate) || ''}
            enableSavedReport
            newReportData={newReportData}
            handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
            handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
            reportCode={reportViewCode}
            handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
            handleColumnsChange={handleColumnsChange}
          />
        </GridContainer>
      </Grid>
    </PageContainer>
  );
};