import React, { useEffect, useState } from 'react';
import { CardActions, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { TaskQuantityCardProps } from './TaskQuantityCard.props';
import {
  DefaultCard,
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  StyledButton
} from './TaskQuantityCard.styles';
import { ServiceTaskQuantity, ServiceTaskQuantityObject } from '../../../../../components/molecules/ServiceTaskQuantity';
import { TaskType } from '../../../../../@types/task.type';
import { COLORS } from '../../../../../configs/colors';

export const TaskQuantityCard: React.FC<TaskQuantityCardProps> = ({
  data,
  onChange,
  onSave,
  onExec,
  onClose
}) => {
  const [serviceTaskQuantityData, setServiceTaskQuantityData] = useState<ServiceTaskQuantityObject>({
    quantity: data.taskData?.quantity || 0,
    notes: data.taskData?.notes || '',
  });

  useEffect(() => {
    onChange({
      ...data, taskData: {
        quantity: serviceTaskQuantityData.quantity,
        notes: serviceTaskQuantityData.notes
      }
    });
  }, [serviceTaskQuantityData]);

  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>{data.taskTypeName}</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
            <Stack direction="column" spacing={2} marginTop={1} width="100%">
              <ServiceTaskQuantity
                serviceTaskQuantityData={{
                  quantity: data.taskData?.quantity || 0,
                  notes: data.taskData?.notes || '',
                }}
                onChange={(ServiceTaskQuantityObject: ServiceTaskQuantityObject) => setServiceTaskQuantityData(ServiceTaskQuantityObject)}
                label={data.taskTypeCode === TaskType.TRAVEL_DISTANCE ? 'KM' : ''}
              />
            </Stack>
          </Stack>
        </Stack>
      </StyledCardContent>
      <CardActions>
        <Stack direction="row-reverse" spacing={1} width="100%">
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Error}
            onClick={onExec}
          >
            Exec
          </StyledButton>
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Success}
            onClick={onSave}
          >
            {data.isNew ? 'Save' : 'Update'}
          </StyledButton>
        </Stack>
      </CardActions>
    </DefaultCard>
  );
};