import { Box, Grid, Paper, styled, Stack } from '@mui/material';
import { TextField } from '../../atoms/TextField';
import { SelectMenu } from '../../atoms/SelectMenu';
import { DatePicker } from '../../atoms/DatePicker';
import { StyledPaperProps, StyledGridProps } from './FormGenerator.props';
import { FormFieldType } from '../../../@types';
import { setFormPaddingByGeoLocationFormAvailability } from '../../../utils/services';

export const StyledPaper = styled(Paper)<StyledPaperProps>`
  padding: ${(props) => (setFormPaddingByGeoLocationFormAvailability(props.containerData))};
`;

export const StyledGrid = styled(Grid)<StyledGridProps>`
  background-color: whitesmoke;
  padding: ${(props) => (props.fieldType === FormFieldType.GetGeoLocation ? '0px' : '10px')};
  margin-bottom: 5px;
`;

export const StyledTextField = styled(TextField)`
  width: 300px;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  min-width: 300px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 300px;
`;

export const SignatureCanvasWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

export const StyledStack = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
`;