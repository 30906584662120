export const defaultColors = ['#1769aa', '#ff1744', '#ff9800', '#673ab7', '#ffea00', '#2196f3', '#ffc400', '#00e5ff', '#4caf50'];

export const COLORS = {
  Blue: '#1769aa',
  Gray: 'gray',
  DarkGray: 'darkgray',
  Black: 'black',
  Red: 'red',
  TorchRed: '#ff1744',
  White: 'white',
  LightGreen: '#4caf50',
  Cyan: '#4BD1C3',
  LightGrey: '#BEBEBE',
  LightOrange: '#ff9248',
  LightRed: '#DCA7B5',
  LightCyan: '#60C9E4',
  DarkCyan: '#29B6F6',
  DarkRed: '#D32F2F',
  Orange: '#ED6C02',
  Purple: '#6F19EA',
  SubtleRed: '#ffc7ce',
  SubtleGrey: '#D4D5D8'
};