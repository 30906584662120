import React from 'react';
import { Stack } from '@mui/material';
import { SelectMenu } from '../../atoms/SelectMenu';
import { TextField } from '../../atoms/TextField';
import { Tabs } from '../../molecules/Tabs';
import { SLAReviewMitigationProps } from './SLAReviewMitigation.props';
import { nameValidation } from '../../../utils/common';

export const SLAReviewMitigation: React.FC<SLAReviewMitigationProps> = ({
  isReview,
  isMitigation,
  reviewOptions,
  mitigationOptions,
  value,
  onChange,
  onTabChange,
  helperText,
  setHelperText,
  validate
}) => {
  return (
    <Tabs name="sla-review-mitigation-tabs" tabTitles={['Review', 'Mitigation']} onTabChange={onTabChange}>
      {isReview && 
        <Stack direction="column" spacing={3} marginTop={3}>
          <SelectMenu 
            id="sla-review-mitigation-tabs-review-select" 
            label="Reason"
            items={reviewOptions}
            selectedValue={value.reviewValue}
            onChange={(val) => onChange({ ...value, reviewValue: val })}
            validate={validate}
            optionalLabelEnabled={true}
          />
          <TextField
            label="Notes"
            multiline
            required
            rows={6}
            value={value.reviewNote}
            onChange={(val) => {
              onChange({ ...value, reviewNote: val });
              setHelperText && setHelperText({ ...helperText, reviewNoteHelperText: nameValidation(val, 'Notes') });
            }}
            error={!!helperText?.reviewNoteHelperText}
            helperText={helperText?.reviewNoteHelperText}
          />
        </Stack>
      }
      {isMitigation && 
        <Stack direction="column" spacing={3} marginTop={3}>
          <SelectMenu 
            id="sla-review-mitigation-tabs-mitigation-select" 
            label="Reason"
            items={mitigationOptions}
            selectedValue={value.mitigationValue}
            onChange={(val) => onChange({ ...value, mitigationValue: val })}
            validate={validate}
            optionalLabelEnabled={true}
          />
          <TextField
            label="Notes"
            multiline
            required
            rows={6}
            value={value.mitigationNote}
            onChange={(val) => {
              onChange({ ...value, mitigationNote: val });
              setHelperText && setHelperText({ ...helperText, mitigationNoteHelperText: nameValidation(val, 'Notes') });
            }}
            error={!!helperText?.mitigationNoteHelperText}
            helperText={helperText?.mitigationNoteHelperText}
          />
        </Stack>
      }
    </Tabs>
  );
};