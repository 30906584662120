export interface KeyValueType {
  id: string;
  label: string;
}

export interface StructureTypeBasicData {
  name: string;
  code: string;
  isActive: boolean;
  isCodeExist: boolean;
}

export interface StructureType {
  key: string;
  value: string;
  selected: boolean;
}

export interface PreDefinedStructureType {
  key: string;
  value: string;
  min: number;
  max: null;
}

export enum StructureTypeEnum {
  AddStructureType = 'Add Structure Type',
  EditStructureType = 'Update Structure Type',
  ListStructureTypes = 'Structure Types'
}