import { NotificationTemplateListResponse, ServiceType } from '../@types';
import { DropdownItem } from '../components/atoms/SelectMenu';

export const getNotifcationTemplatesList= (data: any, messageType: string ) => {
  const dropdownItems: DropdownItem[] = [];
  data?.filter((value: NotificationTemplateListResponse) => value.notificationType === messageType).map((item: { id: number; name: string }) => {
    const itemValue = {
      value: item.id.toString(),
      label: item.name
    };
    dropdownItems.push(itemValue);
  });
  return dropdownItems;
};

export const getNotificationListColumnLabel = (dataList: DropdownItem[], searchValue: string) => {
  return dataList.find((item) => item.value === searchValue)?.label;
};

export const getAllServiceTypesStringArray = (serviceTypes?: ServiceType[]) => {
  const serviceTypesArray: string[] = [];
  serviceTypes?.map((item: ServiceType) => {
    serviceTypesArray.push(item.code);
  });
  return serviceTypesArray;
};