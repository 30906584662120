export const SAMPLE_PART_ACTION_IN_OUT_HELPER_TEXT = {
  partTypeHelperText: '',
  partSerialHelperText: '',
  locationHelperText: '',
  noteHelperText: ''
};

export const SAMPLE_PART_ACTION_CARD_HELPER_TEXT = {
  in: SAMPLE_PART_ACTION_IN_OUT_HELPER_TEXT,
  out: SAMPLE_PART_ACTION_IN_OUT_HELPER_TEXT
};

export const PART_TYPE_CANNOT_EMPTY = 'Part Type field cannot be empty';
export const PART_SERIAL_CANNOT_EMPTY = 'Part Serial field cannot be empty';
export const LOCATION_CANNOT_EMPTY = 'Location field cannot be empty';
export const NOTE_CANNOT_EMPTY = 'Note field cannot be empty';