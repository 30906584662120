import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../../components/atoms/Typography';
import { ParameterNoUIFormFragmentProps } from './ParameterNoUIFormFragment.props';
import { getSetParameterNoUIFormSelectedValue } from '../../../../../../utils/services';

export const ParameterNoUIFormFragment: React.FC<ParameterNoUIFormFragmentProps> = ({
  preSelectedData,
  onChange,
  value
}) => {

  useEffect(() => {
    let checkValue: NodeJS.Timer;
    if (!value) {
      checkValue = setInterval(() => {
        preSelectedData && onChange(getSetParameterNoUIFormSelectedValue(preSelectedData));
      }, 500);
    }
    return () => {
      clearInterval(checkValue);
    };
  }, [preSelectedData,value]);

  return (
    <Grid container marginTop={1} marginBottom={1}>
      <Typography
        variant={TypographyVariantProps.Body1}
        fontWeight={TypographyFontWeightProps.Bold}
      >
        {`Set parameter ${preSelectedData?.parameterType} interaction in-progress`}
      </Typography>
    </Grid>
  );
};