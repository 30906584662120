import { ReactNode } from 'react';

export enum TypographyVariantProps {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  Subtitle1 = 'subtitle1',
  Subtitle2 = 'subtitle2',
  Body1 = 'body1',
  Body2 = 'body2',
  Caption = 'caption',
  Button = 'button',
  Overline = 'overline',
  Inherit = 'inherit'
}

export enum TypographyFontWeightProps {
  Light = 'light',
  Regular = 'regular',
  Medium = 'medium',
  Bold = 'bold'
}

export interface TypographyProps {
  children: ReactNode;
  className?: string;
  variant?: TypographyVariantProps;
  fontWeight?: TypographyFontWeightProps | number;
  textColor?: string;
  id?: any;
  fontStyle?: string;
}