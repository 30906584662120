import React from 'react';
import { Stack } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { ServiceTaskTimeProps, } from './ServiceTaskTime.props';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TextField } from '../../atoms/TextField';
import { adjustTime } from '../../../utils/services';

export const ServiceTaskTime: React.FC<ServiceTaskTimeProps> = ({
  serviceTaskTimeData,
  helperTextData,
  onChange,
}) => {
  const onClick = (adjustValue: number) => {
    onChange({ ...serviceTaskTimeData, duration: adjustTime(serviceTaskTimeData.duration, adjustValue) });
  };

  return (
    <Stack direction="column" spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Start}>
      <Stack direction="row" spacing={GRID_SPACING}>
        <Button variant={ButtonVariantProps.Icon} onClick={() => onClick(-serviceTaskTimeData.adjustValue)}>
          <Remove />
        </Button>
        <TextField
          fullWidth
          value={serviceTaskTimeData.duration}
          helperText={helperTextData.durationHelperText}
          error={!!helperTextData.durationHelperText}
          onChange={(val) => onChange({ ...serviceTaskTimeData, duration: val })}
          placeholder="hh:mm"
        />
        <Button variant={ButtonVariantProps.Icon} onClick={() => onClick(serviceTaskTimeData.adjustValue)}>
          <Add />
        </Button>
      </Stack>
      <TextField
        fullWidth
        label="Notes"
        multiline
        rows={4}
        value={serviceTaskTimeData.notes}
        helperText={helperTextData.notesHelperText}
        error={!!helperTextData.notesHelperText}
        onChange={(val) => onChange({ ...serviceTaskTimeData, notes: val })}
      />
    </Stack>
  );
};
