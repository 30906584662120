import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from '@tanstack/react-query';
import { getReturnShipmentSuggestions, createAddStockManifestShipment, createNonSerializedManifestShipment, createShipment, deleteNonSerializedManifests, deleteSerializedManifests, getAllShipmentManifestsByShipmentId, getAllShipmentStatus, getShipmentBatchReceive, getShipmentById, getShipments, updateShipment, getAllShipmentTypes, getSerialValues, createFreshStockManifestShipment, deleteSerializedManifestById, getAllShipmentManifest, getShipmentBatchReceiveById, createShipmentBatchReceive, getAllManifestByShipment, createShipmentIndividualReceive, getShipmentServiceAllocationList, createShipmentServiceAllocation, deleteShipmentServiceAllocation, getIncomingShipments, getShipmentServiceAllocationsByShipmentId, getAddStockShipmentManifestPricing, getShipmentDetailsPartIncludes, getLatestShipmentByPartId, bulkCreateShipmentIndividualReceive } from '../api/shipment';
import { ShipmentAddStockManifestKey, ShipmentBatchReceiveKey, ShipmentStatusKey, ShipmentsKey, ShipmentTypeKey, ShipmentManifestKey, ShipmentBatchReceiveOneKey, ShipmentIndividualReceiveKey, ShipmentServiceAllocationListKey, ShipmentIncomingShipmentsKey, ShipmentServiceAllocationsKey, ShipmentManifestSuppliersKey, ShipmentsReturnSourceSuggestionKey, ShipmentsReturnDestinationSuggestionKey, LatestShipmentByPartIdKey } from '../configs/shipment';
import { BatchReceiveListResponse, ShipmentListResponse, ShipmentServiceAllocationList, ShipmentStatus, ShipmentType, ReturnSuggestion, IncomingShipmentType, BatchReceive, ShipmentServiceAllocations, ShipmentManifestSuppliers, ShipmentManifestSummery, ShipmentByPartData } from '../@types/shipment.type';
import { AddStockManifestDataType } from '../pages/Shipments/AddStock/AddStockManifestFragment';
import { ServiceLogAllKey } from '../configs/services';
import { LocationNodeData, QueryOption } from '../@types';
import { searchReturnPageDestination, searchReturnPageSource } from '../api/live-search';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';
import { getSelectedColumnNames } from '../utils/columnSelectionOperations';

export const useCreateShipment = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createShipment(data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentsKey, response.id]);
    },
  });
};

export const useUpdateShipment = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await updateShipment(data.id, data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentsKey, response.id]);
      await queryClient.invalidateQueries([ShipmentsKey]);
    },
  });
};

export const useGetAllShipments = (queryOption: QueryOption, columnSelectionItems?: ColumnSelectionItem[]): UseQueryResult<ShipmentListResponse> => {
  return useQuery([ShipmentsKey], () => getShipments(queryOption, getSelectedColumnNames(columnSelectionItems)), { cacheTime: 0 });
};

export const useGetOneShipment = (id: number): UseQueryResult<any> => {
  return useQuery([ShipmentsKey, id], () => !!id && getShipmentById(id));
};

export const useGetAllShipmentStatus = (): UseQueryResult<[ShipmentStatus]> => {
  return useQuery([ShipmentStatusKey], () => getAllShipmentStatus());
};

export const useGetAllShipmentBatchReceive = (queryOption: QueryOption): UseQueryResult<BatchReceiveListResponse> => {
  return useQuery([ShipmentBatchReceiveKey], () => getShipmentBatchReceive(queryOption), { cacheTime: 0 });
};

export const useGetReturnShipmentSuggestions = (sourceId: number, destinationId: number): UseQueryResult<[ReturnSuggestion]> => {
  return useQuery([ShipmentsKey, sourceId, destinationId], () => getReturnShipmentSuggestions(sourceId, destinationId), { enabled: false });
};

export const useGetAllShipmentTypes = (): UseQueryResult<[ShipmentType]> => {
  return useQuery([ShipmentTypeKey], () => getAllShipmentTypes());
};

export const useGetAllShipmentManifestsByShipmentId = (shipmentId: number): UseQueryResult<AddStockManifestDataType> => {
  return useQuery([ShipmentAddStockManifestKey, shipmentId], () => shipmentId > 0 && getAllShipmentManifestsByShipmentId(shipmentId));
};

export const useDeleteSerializedManifests = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await deleteSerializedManifests(data);
  }, {
    onSuccess: async (reponse) => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey, reponse.id]);
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useDeleteNonSerializedManifests = (shipmentId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await deleteNonSerializedManifests(shipmentId, data.manifestId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey, shipmentId]);
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useCreateAddStockManifestShipment = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createAddStockManifestShipment(data[0].shipmentId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useCreateNonSerializedManifestShipment = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createNonSerializedManifestShipment(data[0].shipmentId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useGetSerialValues = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    return await getSerialValues(data);
  });
};

export const useCreateFreshStockManifestShipment = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createFreshStockManifestShipment(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useDeleteSerializedManifestById = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await deleteSerializedManifestById(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentAddStockManifestKey]);
    },
  });
};

export const useGetShipmentBatchReceiveById = (batchId: any): UseQueryResult<any> => {
  return useQuery([ShipmentBatchReceiveOneKey, batchId], () => batchId !== 'create' && getShipmentBatchReceiveById(batchId), { refetchOnWindowFocus: false });
};

export const useGetAllShipmentManifest = (partTypeId: any, nodeId: any): UseQueryResult<any> => {
  return useQuery([ShipmentManifestKey, partTypeId, nodeId], () => partTypeId && nodeId && getAllShipmentManifest(partTypeId, nodeId), { refetchOnWindowFocus: false });
};

export const useCreateShipmentBatchReceive = (): UseMutationResult<BatchReceive> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createShipmentBatchReceive(data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentBatchReceiveOneKey, response.id]);
    },
  });
};

export const useGetAllManifestByShipment = (shipmentId: number): UseQueryResult<any> => {
  return useQuery([ShipmentManifestKey, shipmentId], () => shipmentId !== 0 && getAllManifestByShipment(shipmentId), { enabled: false, refetchOnWindowFocus: false });
};

export const useCreateShipmentIndividualReceive = (shipmentId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createShipmentIndividualReceive(shipmentId, data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentIndividualReceiveKey, response.id]);
      await queryClient.invalidateQueries([ShipmentManifestKey, shipmentId]);
    },
  });
};

export const useBulkCreateShipmentIndividualReceive = (shipmentId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await bulkCreateShipmentIndividualReceive(shipmentId, data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentIndividualReceiveKey, response.id]);
      await queryClient.invalidateQueries([ShipmentManifestKey, shipmentId]);
    },
  });
};

export const useGetShipmentServiceAllocationList = (serviceId: number): UseQueryResult<[ShipmentServiceAllocationList]> => {
  return useQuery([ShipmentServiceAllocationListKey, serviceId], () => serviceId && getShipmentServiceAllocationList(serviceId));
};

export const useCreateShipmentServiceAllocation = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createShipmentServiceAllocation(data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ShipmentServiceAllocationListKey, response.serviceId]);
      await queryClient.invalidateQueries([ServiceLogAllKey, response.serviceId]);
    },
  });
};

export const useDeleteShipmentServiceAllocation = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await deleteShipmentServiceAllocation(data.id);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ShipmentServiceAllocationListKey, serviceId]);
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
    },
  });
};

export const useGetIncomingShipments = (shipmentStatus: string[]): UseQueryResult<[IncomingShipmentType]> => {
  return useQuery([ShipmentIncomingShipmentsKey], () => getIncomingShipments(shipmentStatus));
};

export const useGetShipmentServiceAllocationsByShipmentId = (shipmentId: number): UseQueryResult<[ShipmentServiceAllocations]> => {
  return useQuery([ShipmentServiceAllocationsKey, shipmentId], () => shipmentId && getShipmentServiceAllocationsByShipmentId(shipmentId));
};

export const useGetAddStockShipmentManifestPricing = (partTypeId: number): UseQueryResult<[ShipmentManifestSuppliers]> => {
  return useQuery([ShipmentManifestSuppliersKey, partTypeId], () => getAddStockShipmentManifestPricing(partTypeId));
};

export const useGetShipmentDetailsPartIncludes = (): UseMutationResult<ShipmentManifestSummery> => {
  return useMutation(async (data: any) => {
    return await getShipmentDetailsPartIncludes(data.partId, data.serviceId);
  });
};

export const useGetReturnPageSourceSuggestions = (): UseQueryResult<LocationNodeData[]> => {
  return useQuery([ShipmentsReturnSourceSuggestionKey], () => searchReturnPageSource(''), { enabled: false });
};

export const useGetReturnPageDestinationSuggestions = (): UseQueryResult<LocationNodeData[]> => {
  return useQuery([ShipmentsReturnDestinationSuggestionKey], () => searchReturnPageDestination(''), { enabled: false });
};

export const useGetLatestShipmentByPartId = (partId: number): UseQueryResult<ShipmentByPartData> => {
  return useQuery([LatestShipmentByPartIdKey], () => getLatestShipmentByPartId(partId));
};