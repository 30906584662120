import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { TypographyVariantProps } from '../../atoms/Typography';
import { ContentWrapperBox, HeadingWrapper, Footer, StyledButton } from './SavedReports.styles';
import { ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { SavedReportsProps } from './SavedReports.props';
import { TextField } from '../../atoms/TextField';
import { generateUniqueCode, isEmptyString } from '../../../utils/common';
import { EMPTY_NAME_FIELD_MESSAGE } from '../../../constants/common';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';

export const SavedReports: React.FC<SavedReportsProps> = ({
  open,
  handleClose,
  newReportData,
  updateColumnData,
  enableOverWrite,
  handleSave,
  handleOverwrite
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const savedReportCode = queryParams.get('savedReportCode');
  const [reportName, setReportName] = useState('');
  const [nameRequiredError, setNameRequiredError] = useState(false);

  useEffect(() => {
    newReportData.reportName && setReportName(newReportData.reportName);
  }, [newReportData?.reportName]);

  const onCancel = () => { 
    setNameRequiredError(false);
    handleClose();
  };

  const onSave = () => {
    const uniqueCode = generateUniqueCode();

    const savedReportData = {
      name: reportName,
      code: uniqueCode,
      reportTypeName: newReportData?.reportTypeName,
      isDefault: false,
      dataGridProperties: newReportData?.dataGridProperties,
      columnSelection: updateColumnData?.columnSelection
    };

    if (isEmptyString(reportName)) {
      setNameRequiredError(true);
    } else {
      setNameRequiredError(false);
      handleSave(savedReportData);
    }    
  };

  const onOverwrite = () => {   
    const overwriteReportData = {
      name: reportName,
      isDefault: false,
      dataGridProperties: newReportData?.dataGridProperties,
      columnSelection: updateColumnData?.columnSelection
    };

    if (isEmptyString(reportName)) {
      setNameRequiredError(true);
    }
    else{
      setNameRequiredError(false);
      handleOverwrite(overwriteReportData);
    }
  };
 
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={handleClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Enter Report Name
        </HeadingWrapper>
        <Grid>
          <Grid xs={9}>
            <TextField
              fullWidth
              value={reportName}
              onChange={(value) => { 
                setReportName(value);
                setNameRequiredError(false);
              }}
              helperText={nameRequiredError ? EMPTY_NAME_FIELD_MESSAGE : ''}
              error={nameRequiredError}
            />
          </Grid>
          <Grid>
            <Footer>
              <StyledButton variant={ButtonVariantProps.Secondary} onClick={onCancel}>Close</StyledButton>
              {savedReportCode && enableOverWrite &&
                <StyledButton color={ButtonColorProps.Error} onClick={onOverwrite}>Overwrite Existing Report</StyledButton>
              }
              <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>{savedReportCode ? 'Save as New Report' : 'Save'}</StyledButton>
            </Footer>
          </Grid>
        </Grid>
      </ContentWrapperBox>
    </Modal>
  );
};