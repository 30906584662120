import React from 'react';
import { Dialog, DialogActions, DialogTitle, Stack } from '@mui/material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { PartTypeLocationItem } from '../../molecules/PartTypeLocationItem';
import { PartLocationSelectPopupProps } from './PartLocationSelectPopup.props';
import { StyledDialogContent } from './PartLocationSelectPopup.styles';

export const PartLocationSelectPopup: React.FC<PartLocationSelectPopupProps> = ({
  open,
  options,
  onSelect,
  onClose
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        Select Location
      </DialogTitle>
      {!!options.length &&
        <StyledDialogContent>
          {options?.map((obj, index) => (
            <PartTypeLocationItem 
              key={index} 
              data={obj} 
              onClick={() => onSelect(obj)} 
            />
          ))}
        </StyledDialogContent>
      }
      <DialogActions>
        <Stack direction="row" justifyContent="center" width="100%" mb={1}>
          <Button 
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Error} 
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
