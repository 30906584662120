import { ReactElement, ReactNode } from 'react';

export enum FormControlLabelPlacementProps {
  Bottom = 'bottom',
  End = 'end',
  Start = 'start',
  Top = 'top'
}

export interface FormControlLabelProps {
  control: ReactElement<any, any>;
  label: ReactNode;
  value?: string;
  labelPlacement?: FormControlLabelPlacementProps;
}
