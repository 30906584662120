import { css, styled } from '@mui/material/styles';
import { InlineGroupProps, VerticalAlignProps, HorizontalAlignProps } from '.';

export const Root = styled('div') <InlineGroupProps>`
  display: flex;

  ${({ vertical }) =>
    (vertical === VerticalAlignProps.Center && 'align-items: center;') ||
    (vertical === VerticalAlignProps.Start && 'align-items: flex-start;') ||
    (vertical === VerticalAlignProps.End && 'align-items: flex-end;') ||
    (vertical === VerticalAlignProps.Baseline && 'align-items: baseline;') ||
    (vertical === VerticalAlignProps.Stretch && 'align-items: stretch;')}

  ${({ horizontal }) =>
    (horizontal === HorizontalAlignProps.Center && 'justify-content: center;') ||
    (horizontal === HorizontalAlignProps.Start && 'justify-content: flex-start;') ||
    (horizontal === HorizontalAlignProps.End && 'justify-content: flex-end;') ||
    (horizontal === HorizontalAlignProps.Around && 'justify-content: space-around;') ||
    (horizontal === HorizontalAlignProps.Between && 'justify-content: space-between;') ||
    (horizontal === HorizontalAlignProps.Evenly && 'justify-content: space-evenly;')}

  ${({ childGap }) => childGap && css`
    > *:not(:first-child) {
      margin-left: ${childGap}px;
    }
  `}

  ${({ fullWidth }) => fullWidth && css`
    flex: 1;
  `}
`;
