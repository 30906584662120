import React from 'react';
import { Stack } from '@mui/material';
import { PageContainer } from '../../../../components/atoms/PageContainer';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { LocationNodeData } from '../../../../@types';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { useSearchNode } from '../../../../queries/live-search-query';
import { BatchReceivePopupFragmentProps } from './BatchReceivePopupFragment.props';
import { PartTypeShipmentPopup } from '../../../../components/molecules/PartTypeShipmentPopup';
import { PopupDialog } from '../../../../components/molecules/PopupDialog';
import { NonSerializedShipmentManifestData } from '../../../../@types/shipment.type';

export const BatchReceivePopupFragment: React.FC<BatchReceivePopupFragmentProps> = ({
  batchReceivePopupData,
  onChange
}) => {
  const getSearchNodes = useSearchNode();

  const onClick = (value: NonSerializedShipmentManifestData) => {
    const updatedNonSerializedShipmentManifestItem = [
      ...(batchReceivePopupData?.nonSerializedShipmentManifestItem || []),
      value,
    ];
    onChange({
      ...batchReceivePopupData,
      nonSerializedShipmentManifestItem: updatedNonSerializedShipmentManifestItem
    });
  };

  return (
    <PageContainer>
      <Stack direction="column" width="100%">
        <PopupDialog
          width="85%"
          maxWidth="800px"
          title={batchReceivePopupData.partTypeName}
          isOpen={batchReceivePopupData.isPartTypePopup}
          onClose={(event?: any, reason?: string) => (reason !== 'backdropClick') && onChange({ ...batchReceivePopupData, isPartTypePopup: false })}
          onSave={() => onChange({ ...batchReceivePopupData, isOther: !batchReceivePopupData.isOther })}
          actionLabel={batchReceivePopupData.isOther ? 'List' : 'Other'}
        >
          {batchReceivePopupData.isOther ? (
            <>
              <LiveSearchBox
                title="Location Search"
                timeOffset={400}
                value={batchReceivePopupData.putAwayLocationName}
                onClearValue={() => ''}
                renderItem={(props: any, option: any) => {
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(nodeItem: LocationNodeData) => {
                  onChange({ ...batchReceivePopupData, putAwayLocationId: nodeItem.id, putAwayLocationName: nodeItem.name, isOther: !batchReceivePopupData.isOther });
                }}
                onApiInvoke={async (name: string) => {
                  return await getSearchNodes.mutateAsync(name);
                }}
              />
            </>
          ) : (
            <PartTypeShipmentPopup data={batchReceivePopupData.nonSerialOptions} onClick={onClick} />
          )}
        </PopupDialog>
      </Stack>
    </PageContainer>
  );
};