import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isEmptyString } from '../../../utils/common';
import { SizeProps } from '../../../@types';
import { EMPTY_FIELD_MESSAGE } from '../../../constants/common';
import { MultiSelectLiveSearchBoxProps } from './MultiSelectLiveSearchBox.props';

export const MultiSelectLiveSearchBox: React.FC<MultiSelectLiveSearchBoxProps> = ({
  title,
  timeOffset,
  value,
  isError,
  inputRef,
  isRequired,
  helperText,
  defaultSuggetions,
  disabled,
  onChange,
  onApiInvoke,
  renderItem
}) => {
  const [options, setOptions] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState('');
  const [helperTextValue, setHelperTextValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (defaultSuggetions && defaultSuggetions.length) {
      setOptions(defaultSuggetions);
    }
  }, [defaultSuggetions]);

  useEffect(() => {
    helperText && setHelperTextValue(helperText);
  }, [helperText]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleInputChange = (event: SyntheticEvent, textValue: string) => {
    setInputValue(textValue);

    clearInterval(timerRef.current);

    if (textValue?.length > 0) {
      setHelperTextValue('');
      setIsLoading(true);
      timerRef.current = setTimeout(async () => {
        const result = await onApiInvoke(textValue);
        setIsLoading(false);
        setOptions(defaultSuggetions && defaultSuggetions.length ? [...defaultSuggetions, ...result] : result);
      }, timeOffset);
    } else if (isRequired) {
      setHelperTextValue(EMPTY_FIELD_MESSAGE);
    }
  };


  return (
    <Autocomplete
      multiple
      title={title}
      disabled={disabled}
      renderOption={(props, option, state) => renderItem(props, option, state)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          helperText={helperTextValue}
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps
          }}
          error={isError || !isEmptyString(helperTextValue)}
        />
      )}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      defaultValue={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={(obj: any) => obj.name || obj.id}
      filterOptions={(items) => items}
      loading={isLoading}
      size={SizeProps.Small}
    />
  );
};