import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SelectMenu } from '../../atoms/SelectMenu';
import { CapacityProps, CapacityHourTypes } from './Capacity.props';
import { CapacityDelete, SwitchGrid } from './Capacity.styles';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { MultiClientSelector } from '../../molecules/MultiClientSelector';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';

export const Capacity: React.FC<CapacityProps> = ({
  capacityData,
  disableElement,
  onChange,
  onDelete
}) => {
  const [open, setOpen] = useState(false);
  const [capacity, setCapacity] = useState(capacityData);
  const itemSelectionData = Object.values(CapacityHourTypes).map(text => ({ value: text, label: text }));

  useEffect(() => {
    capacityData && setCapacity(capacityData);
  }, [capacityData]);

  const handleType = (selectedValue: string) => {
    const item = Object.entries(CapacityHourTypes).find(capacityValue => capacityValue[1] === selectedValue);
    const getKeyByValue = item ? item[1] : CapacityHourTypes.AllDays;
    onChange({ ...capacity, type: getKeyByValue });
  };

  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <Grid item xs={2}>
        <MultiSelectCheckmarksMenu 
          id="multi-select"
          label="Service Types"
          items={capacity.itemsData} 
          selectedValue={capacity?.selectedItems || []}
          onChange={(selectItem: string) => onChange({ ...capacity, selectedItems: selectItem.split(',') })} />
      </Grid>

      <Grid item xs={2}>
        <Button onClick={() => setOpen(true)} fullWidth={true} variant={ButtonVariantProps.Secondary}>
          {capacity.clientContractHierarchy || 'Client/Contract'}
        </Button>

        <MultiClientSelector
          open={open}
          onClose={() => setOpen(false)}
          clients={capacity.clientsData}
          contracts={capacity.contractsData}
          selectedMultiClients={capacity?.selectedClients}
          selectedMultiContracts={capacity?.selectedContracts}
          handleParentSelection={(selectedClients: string[], selectedContracts: string[], clientHierarchy: string) => { 
            onChange({ ...capacity, selectedClients, selectedContracts, clientContractHierarchy: clientHierarchy });
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <SelectMenu
          id="capacity-hour-type"
          label="Day Type"
          items={itemSelectionData}
          selectedValue={capacity.type}
          onChange={(type: string) => handleType(type)}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          label="Max"
          type={TextFieldTypeProps.Number}
          value={capacity.max}
          onChange={(maxValue: string) => onChange({ ...capacity, max: maxValue })} />
      </Grid>

      <SwitchGrid item xs={2.35}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Primary}
              checked={capacity.checkInventory}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...capacity, checkInventory: event.target.checked })} />
          }
          label="Check Inventory"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>

      <SwitchGrid item xs={2.15}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Primary}
              checked={capacity.allowOveride}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...capacity, allowOveride: event.target.checked })} />
          }
          label="Allow Overide"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>

      <Grid item xs={0.5}>
        {disableElement || <CapacityDelete onClick={() => onDelete()} />}
      </Grid>
    </Grid>
  );
};