import { User } from '@auth0/auth0-react';
import { getAuthUserByEmail } from '../api/auth';
import { REACT_APP_API_CONNECTION_NAME, USER_OBJECT_KEY, USER_TOKEN_KEY } from '../configs/envs';
import { isDropDownEmpty, isEmptyString } from './common';
import { ADConnectionNames, AppUser, Role, RoleType, Timezone, UserSectionFields } from '../@types';
import { EMPTY_FIELD_MESSAGE } from '../constants/common';
import { CHARACTER_LENGTH_MESSAGE, CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE, EMAIL_ALREADY_EXISTS_MESSAGE, INVALID_EMAIL_MESSAGE } from '../constants/user.constant';
import { AddressDataType } from '../components/organisms/StructureAddress';
import { ContactDataType } from '../components/templates/StructureAddressContactLayout';
import { PropertyType as PropertyTypeEnum } from '../configs/enums';

export const conatainValidCharacters = (value: string) => {
  if (/^[a-z ,.'-]+$/i.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const isValidEmail = (value: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const namesValidation = (value: string, text: string) => {
  if (isEmptyString(value)) {
    return text + EMPTY_FIELD_MESSAGE;
  } else if (conatainValidCharacters(value)) {
    return CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE + text;
  } else {
    return '';
  }
};

export const isEmailExist = async (email: string) => {
  const userAccounts = await getAuthUserByEmail(email);
  const connectionName = REACT_APP_API_CONNECTION_NAME;

  const isExist = userAccounts.length > 0 && userAccounts.some((userAccount: any) => userAccount.identities[0].connection === connectionName);

  if (isExist) {
    return EMAIL_ALREADY_EXISTS_MESSAGE;
  }
  return '';
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(USER_TOKEN_KEY);
  return token || null;
};

export const getUser = (): User | null => {
  const userJson = localStorage.getItem(USER_OBJECT_KEY);
  if (userJson) {
    return JSON.parse(userJson);
  }
  return null;
};

export const setToken = (token?: string) => {
  token && localStorage.setItem(USER_TOKEN_KEY, token);
};

export const setUser = (user?: User) => {
  user && localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(user));
};

export const logoutUser = () => {
  localStorage.clear();
};

export const hasErrorInUpdate = (activeUser: AppUser) => {
  return isEmptyString(activeUser.firstName) ||
    conatainValidCharacters(activeUser.firstName) ||
    isEmptyString(activeUser.lastName) ||
    conatainValidCharacters(activeUser.lastName) ||
    isEmptyString(activeUser.contactEmail) ||
    activeUser.contactEmail.length > 64 ||
    isValidEmail(activeUser.contactEmail) ||
    isDropDownEmpty(activeUser.timezoneId.toString()) ||
    isEmptyString(activeUser.siblingNodeName) ||
    isDropDownEmpty(activeUser.systemRoleId.toString());
};

export const hasErrorInCreate = (firstName: string, lastName: string, email: string, timezoneId: string, roleId: string) => {
  return isEmptyString(firstName) ||
    conatainValidCharacters(firstName) ||
    isEmptyString(lastName) ||
    conatainValidCharacters(lastName) ||
    isEmptyString(email) ||
    email.length > 64 ||
    isValidEmail(email) ||
    isDropDownEmpty(timezoneId) ||
    isDropDownEmpty(roleId);
};

export const isADConnection = (connection: string) => {
  return connection === ADConnectionNames.AmtekADDev || 
    connection === ADConnectionNames.AmtekAD || 
    connection === ADConnectionNames.AmtekCMSAuth0UAT;
};

export const emailVerifiedMessage = (email: string) => {
  if (isValidEmail(email)) {
    return INVALID_EMAIL_MESSAGE;
  } else if (email.length > 64) {
    return CHARACTER_LENGTH_MESSAGE;
  } else if (isEmptyString(email)) {
    return `${UserSectionFields.EmailField} ${EMPTY_FIELD_MESSAGE}`;
  } else {
    return '';
  }
};

export const getRoles = (roles: Array<Role>) => {
  return roles.map((role: Role) => {
    return { value: role.id, label: role.name };
  });
};

export const getRoleId = (roles: Array<Role>) => {
  const role = roles.find((role) => role.name === RoleType.SoftwareIT);

  return role ? role.id : 0;
};

export const getUpdatedRoleId = (roles: Array<Role>, systemRoleId: number) => {
  roles.map((role: Role) => {
    if(role.id === systemRoleId) systemRoleId = role.id;
  });

  return systemRoleId.toString();
};

export const getTimeZones = (timezones: Array<Timezone>) => {
  // const defaultTimezone = [{ value: 0, label: 'Select a timezone', disabled: true }];
  const timezoneList = timezones?.map((timezone: Timezone) => {
    return { value: timezone.id, label: timezone.name + ' - ' + timezone.state, disabled: false };
  });

  return timezoneList;
};

export const getTimezoneId = (timezones: Array<Timezone>, systemTimezoneId: number) => {
  timezones.map((timezone: Timezone) => {
    if(timezone.id === systemTimezoneId) systemTimezoneId = timezone.id;
  });

  return systemTimezoneId.toString();
};

export const getAttributes = (structureAddressDataset: AddressDataType[], structureContactDataset: ContactDataType[], activeUser: AppUser) => {
  const attributes: any[] = [];
  const siblingRefValues = { referenceNo1: activeUser.siblingRefNumber1 || '', referenceNo2: activeUser.siblingRefNumber2 || '', referenceNo3: activeUser.siblingRefNumber3 || '' };

  structureAddressDataset?.forEach(address => {
    attributes.push({
      ...address,
      addressName: address.name,
      name: 'Address',
      propertyTypeCode: PropertyTypeEnum.Address
    });
  });

  structureContactDataset?.forEach(contact => {
    attributes.push({
      ...contact,
      name: 'Contact',
      propertyTypeCode: PropertyTypeEnum.Contact
    });
  });

  attributes.push({
    ...siblingRefValues,
    name: 'Reference Numbers',
    propertyTypeCode: PropertyTypeEnum.ReferenceNumbers
  });

  return attributes;
}; 