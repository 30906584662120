import React from 'react';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { ShipmentSearchItemProps } from './ShipmentSearchItem.props';
import { MicroText, StyledStack } from './ShipmentSearchItem.styles';
import { SearchCode } from '../../../@types/part.type';
import { formatDate } from '../../../utils/common';

export const ShipmentSearchItem: React.FC<ShipmentSearchItemProps> = ({
  data
}) => {
  return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Subtitle2}>{SearchCode.AM_SID}-{data.id}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Source: {data.sourceNodeName}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Destination: {data.destinationNodeName}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Status: {data.shipmentStatus}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Date Sent: {formatDate(data.dateSent)}</Typography>
      <MicroText>Reason For Match: {data.reasonForMatch}</MicroText>
    </StyledStack>
  );
};