import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '../../atoms/Box';
import { FormControlLabel } from '../../atoms/FormControlLabel';
import { Typography } from '../../atoms/Typography';

export const ContentWrapper = styled(Box)`
  padding-bottom: 24px;
`;

export const EnabledFormControlLabel = styled(FormControlLabel)`
  justify-content: flex-start;
`;

export const Topic = styled(Typography)`
  font-size: 18px;
  line-height: 28.8px;
`;

export const TableGridContainer = styled(Grid)`
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 20px;
  border: 1px solid #eeeeee;
  border-radius: 16px;
  width: fit-content;
`;
