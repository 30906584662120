import { Box, TableCell, Typography, styled } from '@mui/material';

export const StyledTableCell = styled(TableCell)`
  border: 0;
`;

export const StyledSecondaryTableCell = styled(TableCell)`
  border: 0;
  vertical-align: top;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  max-width: 745px;
  margin-top: 20px;
  margin-left: 10px;
`;

export const StyledTypography = styled(Typography)`
  padding-right: 5%
`;