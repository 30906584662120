import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { FleetTag } from '../../../../components/molecules/FleetTag';
import { FleetTagFragmentProps } from './FleetTagFragment.props';
import { FleetTagObject } from '../../../../@types/fleet-tag.type';

export const FleetTagFragment: React.FC<FleetTagFragmentProps> = ({
  fleetTagDataset,
  onChange
}) => {
  const [expanded, setExpanded] = useState(true);
  const [fleetTagData, setFleetTagData] = useState<FleetTagObject[]>(fleetTagDataset);

  useEffect(() => {
    setFleetTagData(fleetTagDataset);
  }, [fleetTagDataset]);

  useEffect(() => {
    fleetTagData && onChange(fleetTagData);
  }, [fleetTagData]);

  const handleAdd = (event: any) => {
    event.stopPropagation();
    const newTag = {
      fleetTagName: '',
      isEnabled: true,
      identifier: `fleet-tag-${(fleetTagData?.length || 0) + 1}`
    };
    setFleetTagData((prevData) => [...prevData, newTag]);
    setExpanded(true);
  };

  const onChangeHandle = (updatedValue: FleetTagObject) => {
    setFleetTagData((prevData) =>
      prevData?.map((item) => (item?.identifier === updatedValue?.identifier ? { ...item, ...updatedValue } : item))
    );
  };

  return (
    <Accordian
      title={
        <Grid container justifyContent="space-between">
          <Grid item mr={2}>
            <Typography>Fleet Tag</Typography>
          </Grid>
          <Grid item mr={2}>
            <Button variant={ButtonVariantProps.Secondary} startIcon={<Add />} onClick={handleAdd}>
              Add
            </Button>
          </Grid>
        </Grid>
      }
      onChange={(_, newExpanded: boolean) => setExpanded(newExpanded)}
      expanded={expanded}
    >
      {fleetTagData?.map((data: FleetTagObject) =>
        <FleetTag
          key={data?.id}
          fleetTagData={data}
          onChange={onChangeHandle}
        />
      )}
    </Accordian>
  );
};
