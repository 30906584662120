import { Tag } from '.';

export enum RoleType {
  Admin = 'admin',
  User = 'user',
  SoftwareIT = 'Software/IT'
}

export interface Role {
  id: number;
  name: string;
  notes: string;
  isActive: boolean;
  visibilityRuleId: number;
  activeUserCount: number;
  tags: Tag[];
  visibilityRule?: string;
  userCustomPermissions?: boolean;
}

export interface RoleListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total: number;
  limit?: number;
  data?: [Role];
}