import { styled, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '../Typography';

export const StyledToggleButton = styled(ToggleButton)`
  color: #666666;
  text-transform: none;
  width: 384px;
  height: 50px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  margin-right: 10px;
`;

export const StyledPeopleAltOutlinedIcon = styled(PeopleAltOutlinedIcon)`
  margin-right: 10px;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 10px;
`;

export const SubLabel = styled(Typography)`
  font-size: 12px;
`;

export const MainLabel = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
`;