import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';
import { Box } from '../../atoms/Box';

export const WrapperBox = styled(Box)`
  display: 'flex'; 
  align-items: 'center';
`;

export const ProgressWrapperBox = styled(Box)`
  width: 100%;
`;

export const PrecentageWrapperBox = styled(Box)`
  min-width: 35px;
  text-align: center;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  height: 10px;
`;