import React from 'react';
import { InlineGroupProps } from '.';
import { Root } from './InlineGroup.styles';

export const InlineGroup: React.FC<InlineGroupProps> = ({
  children,
  vertical,
  horizontal,
  childGap,
  fullWidth = false,
  className,
}) => {
  return (
    <Root
      vertical={vertical}
      horizontal={horizontal}
      childGap={childGap}
      fullWidth={fullWidth}
      className={className}
    >
      {children}
    </Root>
  );
};
