import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { BulkEditServiceDataType, LabelValue, OtherServiceData, ServiceAddressAttributeValue, ServiceContactAttribute, StructureAttribute } from '../../../../@types';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { ContactData } from '../../../../components/molecules/Contact';
import { LocationMapAccordian } from '../../../../components/organisms/LocationMapAccordian';
import { AddressDataType, StructureAddress } from '../../../../components/organisms/StructureAddress';
import { ModalAddressBox, ModalContent, AddressContactGrid, ModalFooter } from './LocationMapAccordianFragment.styles';
import { LocationMapAccordianFragmentProps } from './LocationMapAccordianFragment.props';
import { NodeAttributeType, ServiceAttributeType } from '../../../../configs/enums';
import { ContactDataType } from '../../../../components/templates/StructureAddressContactLayout';
import { useGetOtherServicesUnderMainLocation, useUpdateBulkServiceEditList, useGetServicesNodesLocationRelatedAttributes, useCreateOrUpdateServiceAttributes, useGetServiceActionProcessTypes } from '../../../../queries/service-query';
import { useGetNodeAttributesByCustomRange, useGetParentAttributesByPropertyType } from '../../../../queries/structure-query';
import { useGetServicesStatuses } from '../../../../queries/service-status-query';
import { ADDRESS_CONTACT_TYPES } from '../../../../constants/structure';
import { ServiceContactLayout } from '../../../../components/templates/ServiceContactLayout';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { Modal } from '../../../../components/atoms/Modal';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';

export const LocationMapAccordianFragment: React.FC<LocationMapAccordianFragmentProps> = ({
  data,
  isService,
  expandOnMobile
}) => {
  const [nodeAttributeTypes] = useState<NodeAttributeType[]>([NodeAttributeType.Address, NodeAttributeType.Contact, NodeAttributeType.TradingHours]);
  const [openServiceAddressEdit, setOpenServiceAddressEdit] = useState(false);
  const [otherServiceData, setOtherServiceData] = useState<OtherServiceData[]>([]);
  const [nodeAttributes, setNodeAttributes] = useState<StructureAttribute[]>([]);
  const [tradingHours, setTradingHours] = useState<StructureAttribute[]>([]);
  const [statusesList, setStatusesList] = useState<LabelValue[]>([]);
  const [actionTypesList, setActionTypesList] = useState<LabelValue[]>([]);
  const [processTypesList, setProcessTypesList] = useState<LabelValue[]>([]);
  const [bulkUpdateMessage, setBulkUpdateMessage] = useState<any>('');
  const [updatedServiceAddress, setUpdatedServiceAddress] = useState<AddressDataType>();
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [serviceAddress, setServiceAddress] = useState<ServiceAddressAttributeValue>();
  const [addressOptions, setAddressOptions] = useState<DropdownItem[]>([]);
  const [selectedAddressOption, setSelectedAddressOption] = useState<string>('-1');

  const getOtherServicesUnderMainLocation = useGetOtherServicesUnderMainLocation(data.mainLocationNodeId || 0);
  const getAttributesInNodeQuery = useGetNodeAttributesByCustomRange(data.mainLocationNodeId?.toString() || '', nodeAttributeTypes);
  const getParentAttributesByPropertyType = useGetParentAttributesByPropertyType(data.mainLocationNodeId?.toString() || '', NodeAttributeType.TradingHours);
  const getServicesStatuses = useGetServicesStatuses();
  const updateBulkService = useUpdateBulkServiceEditList();
  const getServicesNodesLocationRelatedAttributes = useGetServicesNodesLocationRelatedAttributes(data.id, data.mainLocationNodeId || 0, data.subLocationNodeId || 0);
  const createOrUpdateServiceAttributes = useCreateOrUpdateServiceAttributes();
  const getActionProcessTypes = useGetServiceActionProcessTypes();

  useEffect(() => {
    const serviceAddress = data.attributes?.find((item) => item.code === NodeAttributeType.Address);
    setUpdatedServiceAddress({ 
      ...serviceAddress?.value,
      id: serviceAddress?.id
    });
    setServiceAddress(data.serviceAddress);
  }, [data]);

  useEffect(() => {
    getServicesStatuses.refetch();    
  }, []);

  useEffect(() => {
    getServicesNodesLocationRelatedAttributes.refetch();    
  }, [openServiceAddressEdit]);

  useEffect(() => {
    if (getServicesNodesLocationRelatedAttributes.data) {
      if (getServicesNodesLocationRelatedAttributes.data?.serviceContacts) {
        const attributes = getServicesNodesLocationRelatedAttributes.data.serviceContacts;
        const contactArray: Array<ContactDataType> = [];
        attributes.forEach((attribute: ServiceContactAttribute) => {
          const { id, attributeTypeCode, value } = attribute;

          if (attributeTypeCode === ServiceAttributeType.Contact && value?.isActive) {
            contactArray.push({ ...value, id: id });
          }
        });
        setStructureContactDataset(contactArray);
      }

      if (getServicesNodesLocationRelatedAttributes.data?.nodeAddresses) {
        const options: { value: string; label: string; addressLabel:AddressDataType }[] = [];
        getServicesNodesLocationRelatedAttributes.data?.nodeAddresses?.map((nodeAddress) => {
          options.push({
            value: nodeAddress.id.toString(),
            label: `${nodeAddress.value.line1}, ${nodeAddress.value.line2}, ${nodeAddress.value.suburb}, ${nodeAddress.value.stateName}, ${nodeAddress.value.postCode}, ${nodeAddress.value.countryName}`,
            addressLabel: nodeAddress.value
          });
        });
        setAddressOptions(options);
      }
    }
  }, [getServicesNodesLocationRelatedAttributes.data]);

  useEffect(() => {
    if (data.mainLocationNodeId) {
      getOtherServicesUnderMainLocation.refetch();
      getAttributesInNodeQuery.refetch();
      getParentAttributesByPropertyType.refetch();
    }
  }, [data.mainLocationNodeId]);

  useEffect(() => {
    getOtherServicesUnderMainLocation.data && setOtherServiceData(getOtherServicesUnderMainLocation.data);
  }, [getOtherServicesUnderMainLocation.data]);

  useEffect(() => {
    getAttributesInNodeQuery.data && setNodeAttributes(getAttributesInNodeQuery.data.filter((item: StructureAttribute) => item.propertyTypeCode !== NodeAttributeType.TradingHours));
  }, [getAttributesInNodeQuery.data]);

  useEffect(() => {
    getParentAttributesByPropertyType.data && setTradingHours(getParentAttributesByPropertyType.data);
  }, [getParentAttributesByPropertyType.data]);

  useEffect(() => {
    getServicesStatuses.data && setStatusesList(getServicesStatuses.data.map(listData => (
      { 
        value: listData.code,
        label: listData.name,
        disabled: !listData.isActive
      }
    )));
  }, [getServicesStatuses.data]);

  useEffect(() => {
    if (getActionProcessTypes.data) {
      setActionTypesList(getActionProcessTypes.data.actionTypes.map(listData => (
        { value: listData.code, label: listData.name }
      )));
      setProcessTypesList(getActionProcessTypes.data.processTypes.map(listData => (
        { value: listData.code, label: listData.name }
      )));
    }
  }, [getActionProcessTypes.data]);

  useEffect(() => {
    if (bulkUpdateMessage !== '')
      getOtherServicesUnderMainLocation.refetch();    
  }, [bulkUpdateMessage]);

  const bulkUpdateData = async (bulkServiceUpdateData: BulkEditServiceDataType) => {
    setBulkUpdateMessage('');
    const response: any = await updateBulkService.mutateAsync(bulkServiceUpdateData);

    setBulkUpdateMessage(response?.message || 'Successfully Updated');
  };

  const onEditServiceAddress = async () => {
    await createOrUpdateServiceAttributes.mutateAsync({
      serviceId: data.id,
      attributeTypeCode: 'contact',
      name: 'Contact',
      value: structureContactDataset
    });

    setEditContactData(undefined);

    if (selectedAddressOption && selectedAddressOption !== '-1') {
      await createOrUpdateServiceAttributes.mutateAsync({
        serviceId: data.id,
        attributeTypeCode: 'address',
        name: 'Address',
        value: {
          nodeAttributeId: selectedAddressOption
        }
      });
    } else if (updatedServiceAddress?.countryName) {
      await createOrUpdateServiceAttributes.mutateAsync({
        serviceId: data.id,
        attributeTypeCode: 'address',
        name: 'Address',
        value: { ...updatedServiceAddress, addressType: 'Service' }
      });
    } 
    setSelectedAddressOption('');
    handleClose();
  };

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') setOpenServiceAddressEdit(false);
  };

  return (
    <>
      <LocationMapAccordian 
        otherServiceData={otherServiceData}
        nodeAttributes={[...nodeAttributes, ...tradingHours]}
        serviceAddress={serviceAddress}
        serviceAttributes={data.attributes}
        bulkEditServiceDropDownsDataset={{
          actionTypeItems: actionTypesList,
          processTypeItems: processTypesList,
          statusItems: statusesList
        }}
        bulkUpdateToastMessage={bulkUpdateMessage ?? ''} 
        parentServiceId={data.id.toString()}
        handleBulkUpdateSave={(BulkServiceUpdateData: BulkEditServiceDataType) => { 
          bulkUpdateData(BulkServiceUpdateData);
        }}
        setIsEdit={setOpenServiceAddressEdit}
        expandOnMobile={expandOnMobile}
        otherServicesLoading={getOtherServicesUnderMainLocation.isLoading}
      />
      <Modal
        open={openServiceAddressEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalAddressBox>
          <CloseIcon handleClose={handleClose} />
          <ModalContent>
            <SelectMenu
              label="Structure Addresses"
              id="structure-addresses"
              items={addressOptions}
              disabled={false}
              selectedValue={selectedAddressOption}
              onChange={(value) => setSelectedAddressOption(value)}
              optionalLabelEnabled={true}
              optionalLabel="Select Structure Address"
            />
            <AddressContactGrid mt={2}>
              <StructureAddress
                switchLabel="Manual"
                addressData={updatedServiceAddress}
                disableTypeSelection={true}
                onChange={(updatedData) => { 
                  setUpdatedServiceAddress(updatedData);
                }}
                addressTypeData={ADDRESS_CONTACT_TYPES}
                isService={isService}
              />
            </AddressContactGrid>
            <AddressContactGrid mt={1}>
              <ServiceContactLayout
                contactFormData={contactData}
                editContactFormData={editContactData}
                contactDataset={structureContactDataset ? structureContactDataset : []}
                onContactChange={(value) => {
                  setContactData(value);
                }}
                onClickContactEdit={(event, index) => {
                  const selectedValue: any = structureContactDataset.splice(index, 1);
                  setStructureContactDataset(structureContactDataset);
                  setEditContactData(selectedValue[0]);
                }}
                onClickContactDelete={(event, index) => {
                  const updatedData = [...structureContactDataset];
                  if (index > -1) updatedData.splice(index, 1);
                  setStructureContactDataset(updatedData);
                }}
                handleContactCreate={(index) => {
                  if (index > -1) contactData[index].isActive = true;
                  setStructureContactDataset([...structureContactDataset, contactData[index]]);
                  contactData.splice(index, 1);
                  setContactData([...contactData]);
                }}
              />
            </AddressContactGrid>
          </ModalContent>
          <ModalFooter>
            <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
              <Button variant={ButtonVariantProps.Primary} onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button variant={ButtonVariantProps.Primary} onClick={onEditServiceAddress}>
                Save
              </Button>
            </Stack>
          </ModalFooter>
        </ModalAddressBox>
      </Modal>
    </>
  );
};