import React from 'react';
import { InputLabel, Select, SelectChangeEvent, Grid, Stack } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { ServiceCreationFormBuilderMenuProps } from './ServiceCreationFormBuilderMenu.props';
import { 
  StyledFormControl,
  StyledMenuItem,
  MicroText
} from './ServiceCreationFormBuilderMenu.styles';

export const ServiceCreationFormBuilderMenu: React.FC<ServiceCreationFormBuilderMenuProps> = ({
  options,
  disabled,
  error,
  value,
  onChange,
}) => {
  const getIcon = (name: string) => {
    const IconComponent = Icons[name as keyof typeof Icons];
    return <IconComponent fontSize="small" />;
  };

  return (
    <StyledFormControl error={error}>
      <InputLabel id="service-creation-menu-select-label">Service Creation</InputLabel>
      <Select
        labelId="service-creation-menu-select-label"
        id="service-creation-menu-select"
        label="Service Creation"
        value={value?.name}
        disabled={disabled}
        onChange={(event: SelectChangeEvent) => {
          const selectedObj = options.find((obj) => obj.name === event.target.value);
          selectedObj && onChange(selectedObj);
        }}
      >
        {options?.map((obj) => (
          <StyledMenuItem
            key={obj.name} 
            value={obj.name}
            disabled={disabled}
            divider
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography 
                    variant={TypographyVariantProps.Subtitle2}
                  >
                    {obj.name}
                  </Typography>
                  {getIcon(obj.data?.icon || '')}
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <MicroText>
                  Service Type
                </MicroText>
                <Typography 
                  variant={TypographyVariantProps.Body2}
                >
                  {obj.data?.autoFill?.serviceTypeCode || ''}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <MicroText>
                  Action Type
                </MicroText>
                <Typography 
                  variant={TypographyVariantProps.Body2}
                >
                  {obj.data?.autoFill?.actionTypeCode || ''}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <MicroText>
                  Process Type
                </MicroText>
                <Typography 
                  variant={TypographyVariantProps.Body2}
                >
                  {obj.data?.autoFill?.processTypeCode || ''}
                </Typography>
              </Grid>
            </Grid>
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};
