import React from 'react';
import { IconButton, TableRow } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { StyledTableCell } from './SKUPropertySelectionItemRow.styles';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { SKUPropertySelectionItemRowProps } from './SKUPropertySelectionItemRow.props';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { PartTypeLiveSearchItem } from '../../../@types';
import { getDropdownOptionsWithHandlingAnyKey } from '../../../utils/structure';
import { onChangeSkuSFHValues } from '../../../utils/sku';
import { ANY_ITEM_ARRAY, ANY_KEYWORD } from '../../../constants/common';

export const SKUPropertySelectionItemRow: React.FC<SKUPropertySelectionItemRowProps> = ({
  isNew,
  isSerialized,
  value,
  configData,
  multiline = true,
  onChange,
  onPartTypeSearchApiInvoke,
  onCancel
}) => {
  return (
    <TableRow>
      <StyledTableCell width="20%">
        <LiveSearchBox
          title="Part Type Search *"
          multiline={multiline}
          timeOffset={400}
          value={value?.partTypeName || ''}
          onClearValue={() => onChange({ 
            ...value, 
            partTypeId: 0, 
            partTypeName: '',
            softwareVersions: [ANY_KEYWORD],
            firmwareVersions: [ANY_KEYWORD],
            hardwareRevisionVersions: [ANY_KEYWORD],
            fleetTags: [ANY_KEYWORD]
          })}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          onChange={(val: PartTypeLiveSearchItem) => onChange({ ...value, partTypeId: val.id, partTypeName: val.name })}
          onApiInvoke={onPartTypeSearchApiInvoke}
        />
      </StyledTableCell>
      {isSerialized && 
        <>
          <StyledTableCell width="20%">
            <MultiSelectCheckmarksMenu
              label="Software Versions *"
              required={true}
              disabled={!value?.partTypeName}
              items={getDropdownOptionsWithHandlingAnyKey(value.softwareVersions || [], ANY_ITEM_ARRAY.concat(configData.softwareVersionOptions))}
              selectedValue={value.softwareVersions}
              onChange={(val) => onChange({ ...value, softwareVersions: onChangeSkuSFHValues(val) })}
            />
          </StyledTableCell>
          <StyledTableCell width="20%">
            <MultiSelectCheckmarksMenu
              label="Firmware Versions *"
              required={true}
              disabled={!value?.partTypeName}
              items={getDropdownOptionsWithHandlingAnyKey(value.firmwareVersions || [], ANY_ITEM_ARRAY.concat(configData.firmwareVersionOptions))}
              selectedValue={value.firmwareVersions}
              onChange={(val) => onChange({ ...value, firmwareVersions: onChangeSkuSFHValues(val) })}
            />
          </StyledTableCell>
          <StyledTableCell width="20%">
            <MultiSelectCheckmarksMenu
              label="Hardware Versions *"
              required={true}
              disabled={!value?.partTypeName}
              items={getDropdownOptionsWithHandlingAnyKey(value.hardwareRevisionVersions || [], ANY_ITEM_ARRAY.concat(configData.hardwareRevisionOptions))}
              selectedValue={value.hardwareRevisionVersions}
              onChange={(val) => onChange({ ...value, hardwareRevisionVersions: onChangeSkuSFHValues(val) })}
            />
          </StyledTableCell>
        </>
      }
      <StyledTableCell width="20%">
        <MultiSelectCheckmarksMenu
          label="Fleet Tags *"
          required={true}
          disabled={!value?.partTypeName}
          items={getDropdownOptionsWithHandlingAnyKey(value.fleetTags || [], ANY_ITEM_ARRAY.concat(configData.fleetTagOptions))}
          selectedValue={value.fleetTags}
          onChange={(val) => onChange({ ...value, fleetTags: onChangeSkuSFHValues(val) })}
        />
      </StyledTableCell>
      {isNew && 
        <StyledTableCell>
          <IconButton color="error" onClick={onCancel}>
            <CloseRounded /> 
          </IconButton>
        </StyledTableCell>
      }
    </TableRow>
  );
};