import { styled } from '@mui/material';

export const SfhTable = styled('table')`
  th {
    width: 75px;
    font-size: 12px;
    text-align: center;
    background-color: lightblue;
    border-radius: 5px;
  }
  td {
    width: 75px;
    font-size: 12px;
    text-align: center;
    background-color: lightgrey;
    border-radius: 5px;
  }
`;
