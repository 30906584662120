import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { Autocomplete, AutocompleteCloseReason, Button, Collapse, Grid, IconButton, InputAdornment } from '@mui/material';
import { ChevronLeft, ChevronRight, Layers, Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ServiceTemplateListProps } from './ServiceTemplateList.props';
import { ServiceTemplateListItem } from '../../molecules/ServiceTemplateListItem';
import { TextField } from '../../atoms/TextField';
import { SizeProps } from '../../../@types';
import { StyledMenuItem, StyledPopper } from './ServiceTemplateList.styles';
import { ServiceTemplateDataType } from '../../../@types/service.type';
import { RootState } from '../../../store';

export const ServiceTemplateList: React.FC<ServiceTemplateListProps> = ({
  templateData,
  expanded,
  onClickExpand,
  onClickItem
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [checked, setChecked] = React.useState(expanded);
  const anchorRef = useRef<any>();
  const searchFilterRef = useRef<any>();
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isSidebarOpen);

  useEffect(() => {
    setAnchorEl(anchorRef.current || null);
  }, [anchorRef.current]);

  useEffect(() => {
    searchFilterRef.current?.focus && searchFilterRef.current?.focus();
  }, [isSidebarOpen]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: ServiceTemplateDataType,
  ) => {
    setSelectedIndex(item.id);
    handleChange();
    onClickItem(item);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
    onClickExpand && onClickExpand(checked);
    setAnchorEl(checked ? null : anchorRef.current);
  };
  
  return (
    <Grid container alignItems="center" mt={checked ? 1 : 0}>
      <Grid item>
        <IconButton>
          {checked ? <ChevronLeft onClick={handleChange}/> : <ChevronRight onClick={handleChange}/>}
        </IconButton>
      </Grid>
      <Grid item mr={1}>
        <Collapse timeout={0} orientation="horizontal" in={checked}>
          <Button 
            size="small"
            ref={anchorRef} 
            startIcon={<Layers />} 
            variant="outlined" 
          >
              Select Template
          </Button>
          <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-end">
            <Autocomplete
              open
              openOnFocus
              disablePortal
              id="template-select"
              sx={{ width: 200 }}
              forcePopupIcon={false}
              options={templateData}
              autoHighlight
              getOptionLabel={(option) => option.name}
              onClose={(
                event: SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              renderOption={(props, option) => (
                <StyledMenuItem 
                  key={option.id} 
                  value={option.name} 
                  selected={option.id === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, option)}
                >
                  <ServiceTemplateListItem data={option}/>
                </StyledMenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={searchFilterRef}
                  size={SizeProps.Small}
                  placeholder="Search filter"
                  InputProps={{
                    ...params.InputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    startAdornment:
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>,
                  }}
                />
              )}
            />
          </StyledPopper>
        </Collapse>
      </Grid>
    </Grid>
  );
};
