import { TemplateResponse } from '../@types/tlc.type';
import { QueryOption } from '../@types';
import { TLCExecuteDataType } from '../@types/tlc.type';
import { httpClient, HttpMethod } from './http-client';

export const getTlcTemplates = (queryOptions: QueryOption) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/tlc/templates?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });
  
  return httpClient(url, HttpMethod.Get, null);
};

export const createTLCTemplate = (templateData: TemplateResponse) => {
  return httpClient('/actionTemplate', HttpMethod.Post, templateData);
};

export const updateTLCTemplate = (id: number, updatedTemplateData: TemplateResponse) => {
  return httpClient(`/actionTemplate/${id}`, HttpMethod.Put, updatedTemplateData);
};

export const getTLCTemplateById= (id: number): any => {
  return httpClient(`/actionTemplate/${id}`, HttpMethod.Get, null);
};

export const getTemplateActionTypes = () => {
  return httpClient('/templateActionTypes', HttpMethod.Get, null);
};
  
export const getTlcTemplateDetails = () => {
  return httpClient('/tlc/templateDetails', HttpMethod.Get, null);
};

export const executeTlcTransactions = (tlcExecuteType: TLCExecuteDataType) => {
  return httpClient('/tlc/executeTransactions', HttpMethod.Post, tlcExecuteType);
};
