import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const getSelectedColumnNames = (columnSelectionItems?: ColumnSelectionItem[], defaultExcludedColumns?: string[]) => {
  const excludedColumns: string[] = defaultExcludedColumns || [];
  
  if (columnSelectionItems) {
    columnSelectionItems.forEach(item => {
      return item.value.forEach(valueItem => {
        if (!valueItem.value) {
          excludedColumns.push(valueItem.field);
        }
      });
    });
  }

  const index = excludedColumns.indexOf('id');
  if (index > -1) {
    excludedColumns.splice(index, 1);
  }

  return excludedColumns.toString();
};