import React, { useState, useEffect } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  RoleBox,
  StructureTradingHourAddGrid,
  StyledTypography,
} from './TradingHourList.styles';
import { TradingHourListProps } from './TradingHourList.props';
import { TypographyVariantProps } from '../../atoms/Typography';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TradingHours, TradingHoursData, TradingHourTypes } from '../../molecules/TradingHours';

export const TradingHourList: React.FC<TradingHourListProps> = ({
  heading,
  tradingHourValue,
  onTradingHoursChange,
  onTradingHoursDelete,
  onTradingHoursCreate
}) => {
  const [formData, setFormData] = useState<TradingHoursData[]>(tradingHourValue);

  useEffect(() => {
    tradingHourValue && setFormData(tradingHourValue);
  }, [tradingHourValue]);
  
  const addNewForm = () => {
    const data = {
      type: TradingHourTypes.AllDays,
      start: '12:00am',
      end: '12:00am',
      ph: false
    };
    setFormData([...formData, data]);
    onTradingHoursCreate([...formData, data]);
  };

  return (
    <RoleBox>
      <Grid container>
        <Grid container justifyContent="space-between" mx={1}>
          <Grid>
            <StyledTypography variant={TypographyVariantProps.H6}>{heading}</StyledTypography>
          </Grid>
          <StructureTradingHourAddGrid>
            <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} onClick={() => {addNewForm();}}>
              Add
            </Button>
          </StructureTradingHourAddGrid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody>
              {formData?.map((data,index) => 
                <TableRow key={index}>
                  <TableCell>
                    <Grid pt="5px">
                      <TradingHours 
                        value={data} 
                        onChange={(updatedData) => {
                          const newData = formData.map((obj, i) => index === i ? updatedData : obj);
                          setFormData(newData);
                          onTradingHoursChange(newData);
                        }}
                        onDelete={() => {
                          onTradingHoursDelete(index);
                          const updatedData = [...formData];                  
                          if (index > -1) {
                            updatedData.splice(index, 1);
                          }
                          setFormData([...updatedData]);
                        }}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </RoleBox>
  );
};