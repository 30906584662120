import React from 'react';
import { FormControl, Grid, InputLabel, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import * as Icons from '@mui/icons-material';
import { 
  Typography, 
  TypographyFontWeightProps, 
  TypographyVariantProps 
} from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { CheckItem } from '../../molecules/CheckItem';
import { FormBuilderFieldOption } from '../../molecules/FormBuilderFieldOption';
import { FormFieldItem, FormFieldOption, FormFieldType } from '../../../@types';
import { isEmptyString } from '../../../utils/common';
import { FormBuilderFieldProps } from './FormBuilderField.props';
import { LabelStyledMenuItem, LabelStyledSelect, StyledGrid, StyledTextField } from './FormBuilderField.styles';
import { LABEL_STYLES } from '../../../constants/interaction.constant';

export const FormBuilderField: React.FC<FormBuilderFieldProps> = ({
  subFieldTypes,
  showErrors,
  value,
  onChange,
  onRemove
}) => {
  const handleAddOption = () => {
    if (value.options && value.options.length > 0) {
      const sortedOptions = value.options.sort((a, b) => a.id - b.id);
      const index = sortedOptions[sortedOptions.length - 1].id;
      const newOption: FormFieldOption = {
        id: index + 1,
        title: '',
        value: '',
        isActive: true,
        isDefault: false
      };
  
      onChange({
        ...value,
        options: [...sortedOptions, newOption]
      });
    } else {  
      onChange({
        ...value,
        options: [
          {
            id: 1,
            title: '',
            value: '',
            isActive: true,
            isDefault: false
          }
        ]
      });
    }
  };

  const getSubField = (field: FormFieldItem) => {
    const subFieldType = subFieldTypes?.find((obj) => obj.formType === field.type);
    if (subFieldType) {
      const SubFieldReactNode = subFieldType.node;
      return (
        <SubFieldReactNode
          key={`${value.id}-${field.id}`}
          showErrors={showErrors}
          preSelectedData={field.preSelectedData}
          onPreSelectedData={(val) => onChange({ ...field, title: field.type, preSelectedData: val })}
        />
      );
    }
    return null;
  };

  const getLabelIcon = (name: string, color: any) => {
    const IconComponent = Icons[name as keyof typeof Icons];
    return <IconComponent fontSize="large" color={color} />;
  };

  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={8}>
        <Typography 
          variant={TypographyVariantProps.Body1} 
          fontWeight={TypographyFontWeightProps.Bold}
        >
          {value.type}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {value.type === FormFieldType.Label || value.type === FormFieldType.GetGeoLocation || value.type === FormFieldType.SetParameterNoUI ||
          value.type === FormFieldType.ActionTrigger || value.type === FormFieldType.Block ?
          <Stack direction="row" justifyContent="flex-end" marginRight={1}>
            <Button
              variant={ButtonVariantProps.Icon}
              onClick={() => onRemove(value.id)}
            >
              <CloseRounded fontSize="small" color="error" />
            </Button>
          </Stack>
          :
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" marginRight={1}>
            <CheckItem
              label="Required"
              value={value.isRequired}
              onChange={(val) => onChange({
                ...value,
                isRequired: val
              })}
            />
            <Button
              variant={ButtonVariantProps.Icon}
              onClick={() => onRemove(value.id)}
            >
              <CloseRounded fontSize="small" color="error" />
            </Button>
          </Stack>
        }
      </Grid>
      {(value.type === FormFieldType.Label || 
          value.type === FormFieldType.FaultSelection || 
          value.type === FormFieldType.SolutionAction || 
          value.type === FormFieldType.PartsRequest ||
          value.type === FormFieldType.ServiceCreation ||
          value.type === FormFieldType.GetGeoLocation ||
          value.type === FormFieldType.MerchantDamageTemplate ||
          value.type === FormFieldType.EstimatedTimeOfArrival ||
          value.type === FormFieldType.ModifyStructure ||
          value.type === FormFieldType.ActionTrigger ||
          value.type === FormFieldType.SetParameter || 
          value.type === FormFieldType.SetParameterNoUI ||
          value.type === FormFieldType.GetParameter ||
          value.type === FormFieldType.ServiceSearch ||
          value.type === FormFieldType.Block ||
          value.type === FormFieldType.Presentation) ||
        <Grid item xs={8}>
          <StyledTextField
            label="Field Name"
            required={true}
            error={showErrors && isEmptyString(value.title)}
            value={value.title}
            onChange={(val) => onChange({ ...value, title: val })}
          />
        </Grid>
      }
      {value.type === FormFieldType.Label &&
        <Stack direction="row" spacing={4} ml={2}>
          <StyledTextField
            label="Field Name"
            required={true}
            error={showErrors && isEmptyString(value.title)}
            value={value.title}
            onChange={(val) => onChange({ ...value, title: val })}
          />
          <Stack width="400px">
            <FormControl >
              <InputLabel id="label-style">Label Style</InputLabel>
              <LabelStyledSelect
                labelStyle={value.labelStyle || ''}
                id="label-style"
                label="Label Style"
                defaultValue="None"
                value={value?.labelStyle}
                onChange={(event: any) => {
                  onChange({ ...value, labelStyle: event.target.value });
                }}
              >
                {LABEL_STYLES.map((labelStyle) => (
                  <LabelStyledMenuItem
                    bgColor={labelStyle.bgColor}
                    key={labelStyle.value} 
                    value={labelStyle.value}
                    divider
                  >
                    <Stack spacing={5} direction="row" alignItems="center">
                      {labelStyle?.iconName ? getLabelIcon(labelStyle.iconName, labelStyle.iconType) : ''}
                      <Typography 
                        variant={TypographyVariantProps.Body1}
                      >
                        {labelStyle.label}
                      </Typography>
                    </Stack>
                  </LabelStyledMenuItem>
                ))}
              </LabelStyledSelect>
            </FormControl>
          </Stack>
        </Stack>
      }
      {value.type === FormFieldType.Block &&
        <Grid item xs={8}>
          <StyledTextField
            label="Message"
            value={value?.blockMessage}
            multiline
            onChange={(val) => onChange({ ...value, blockMessage: val.length > 255 ? `${val.substring(0, 255)}` : val })}
          />
        </Grid>
      }
      <Grid item xs={8}>
        {getSubField(value)}
      </Grid>
      {(value.type === FormFieldType.Dropdown || value.type === FormFieldType.Radio) &&
      <>
        <Grid item xs={8}>
          <Stack direction="column" spacing={1}>
            <Typography 
              variant={TypographyVariantProps.Body2} 
              fontWeight={TypographyFontWeightProps.Bold}
            >
              Options:
            </Typography>
            {value.options?.map((obj) => (
              <FormBuilderFieldOption
                key={obj.id}
                showErrors={showErrors}
                value={obj}
                onChange={(val) => {
                  const modifiedOptions = value.options?.map((op) => {
                    return (op.id === val.id) ? val : op;
                  });
                  let newOptions = modifiedOptions;
                  if (val.isDefault) {
                    newOptions = value.options?.map((op) => {
                      return (op.id === val.id) ? val : { ...op, isDefault: false };
                    });
                  }
                  onChange({ ...value, options: newOptions });
                }}
                onRemove={(id) => {
                  const modifiedOptions = value.options?.filter((op) => op.id !== id);
                  onChange({ ...value, options: modifiedOptions });
                }}
                type={value.type}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={ButtonVariantProps.Secondary}
            color={ButtonColorProps.Success}
            onClick={handleAddOption}
          >
            + Add Option
          </Button>
        </Grid>
      </> 
      }
    </StyledGrid>
  );
};
