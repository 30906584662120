import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '../../components/atoms/Loader';

// Can use to protect single route
export class ProtectedRoute extends React.Component<any, any> {
  render() {
    return (
      <Route
        element={withAuthenticationRequired(this.props?.component, {
          onRedirecting: () => <Loader />,
        })}
        {...this.props?.args}
      />
    );
  }
}