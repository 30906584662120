import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { InteractionsRulesQueryResponse } from '../@types/interactionsRules.type';
import { InteractionsRulesConfigsKey } from '../configs/interactions-rules';
import { getAllInteractionsRulesConfigs, getInteractionRuleById } from '../api/interactions';
import { QueryOption } from '../@types';
import { InteractionRuleObject } from '../@types/interaction.type';

export const useGetAllInteractionsRulesConfigs = ( queryOption: QueryOption ): UseQueryResult<InteractionsRulesQueryResponse> => {
  return useQuery([InteractionsRulesConfigsKey], () => getAllInteractionsRulesConfigs(queryOption), { cacheTime: 0 });
};

export const useGetInteractionRuleById = (id: number): UseQueryResult<InteractionRuleObject> => {
  return useQuery([InteractionsRulesConfigsKey, id], () => getInteractionRuleById(id), { enabled: false });
};