import React, { useEffect, useState } from 'react';
import { CardActions, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { TaskQuoteValueCardProps } from './TaskQuoteValueCard.props';
import {
  DefaultCard,
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  StyledButton
} from './TaskQuoteValueCard.styles';
import { ServiceTaskQuote, ServiceTaskQuoteHelperTextObject, ServiceTaskQuoteObject } from '../../../../../components/molecules/ServiceTaskQuote';
import { EMPTY_FIELD_MESSAGE } from '../../../../../constants/common';
import { SAMPLE_TASK_QUOTE_VALUE_CARD_HELPERTEXT } from '../../../../../constants/task';
import { COLORS } from '../../../../../configs/colors';

export const TaskQuoteValueCard: React.FC<TaskQuoteValueCardProps> = ({
  data,
  onChange,
  onSave,
  onExec,
  onClose
}) => {
  const [serviceTaskQuoteData, setServiceTaskQuoteata] = useState<ServiceTaskQuoteObject>({
    valueCollection: data.taskData?.valueCollection || 0,
    issuedTo: data.taskData?.issuedTo || '',
    contactEmail: data.taskData?.contactEmail || '',
    isApproved: data.taskData?.isApproved || false,
    notes: data.taskData?.notes || ''
  });

  const [helperTextData, setHelperTextData] = useState<ServiceTaskQuoteHelperTextObject>(SAMPLE_TASK_QUOTE_VALUE_CARD_HELPERTEXT);

  useEffect(() => {
    if (serviceTaskQuoteData.contactEmail === '') setHelperTextData({ ...helperTextData, contactHelperText: EMPTY_FIELD_MESSAGE });
    else {
      setHelperTextData({ ...helperTextData, contactHelperText: '' });
      onChange({
        ...data, taskData: {
          valueCollection: serviceTaskQuoteData.valueCollection,
          issuedTo: serviceTaskQuoteData.issuedTo,
          contactEmail: serviceTaskQuoteData.contactEmail,
          isApproved: serviceTaskQuoteData.isApproved,
          notes: serviceTaskQuoteData.notes
        }
      });
    }
  }, [serviceTaskQuoteData]);

  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>Quote Value with Approved Flat</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%" mt={1}>
          <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
            <Stack direction="column" spacing={2} marginTop={1} width="100%">
              <ServiceTaskQuote
                serviceTaskQuoteData={{
                  valueCollection: data.taskData?.valueCollection || 0,
                  issuedTo: data.taskData?.issuedTo || '',
                  contactEmail: data.taskData?.contactEmail || '',
                  isApproved: data.taskData?.isApproved || false,
                  notes: data.taskData?.notes || ''
                }}
                helperTextData={helperTextData}
                onChange={(serviceTaskQuoteData: ServiceTaskQuoteObject) => setServiceTaskQuoteata(serviceTaskQuoteData)}
              />
            </Stack>
          </Stack>
        </Stack>
      </StyledCardContent>
      <CardActions>
        <Stack direction="row-reverse" spacing={1} width="100%">
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Error}
            onClick={onExec}
          >
            Exec
          </StyledButton>
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Success}
            onClick={onSave}
          >
            {data.isNew ? 'Save' : 'Update'}
          </StyledButton>
        </Stack>
      </CardActions>
    </DefaultCard>
  );
};