export enum RoutePath {
  Root = '/',
  User = 'configure/users',
  CreateUser = 'configure/users/create',
  AddServiceType = 'configure/services/types/add?isNew=true',
  UpdateServiceType = '/configure/services/types',
  AddServiceStatus = 'configure/services/statuses/add?isNew=true',
  UpdateServiceStatus = '/configure/services/statuses',
  AddStructureType = 'configure/structure/types/create?isNew=true',
  UpdateStructureType = '/configure/structure/types',
  UpdateTLCTemplate = '/parts/tlcTemplates',
}