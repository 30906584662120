import { styled, Paper, Button as MaterialButton, Stack, Badge, MenuItem } from '@mui/material';
import {
  LightbulbOutlined,
  CancelOutlined,
  Person,
  PeopleAlt,
  AccountBoxOutlined,
  ChevronRightOutlined,
  SubdirectoryArrowRightOutlined,
  MenuOutlined
} from '@mui/icons-material';
import BoltIcon from '@mui/icons-material/Bolt';
import { Button } from '../../../../../components/atoms/Button';
import { TextField } from '../../../../../components/atoms/TextField';
import { Typography } from '../../../../../components/atoms/Typography';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';

export const ContentPaper = styled(Paper)`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: whitesmoke;
`;

export const ActionBarStack = styled(Stack)((props: { isStatusChange: boolean }) => `
  min-height: 44px;
  margin-top: ${props.isStatusChange ? '-15px' : '0px'};
`);

export const IconContainer = styled('div')((props: { backgroundColor: string }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${props.backgroundColor};
`);

export const NewButton = styled(Button)`
  height: 40px;
  font-size: 18px;
  text-transform: capitalize;
  border-color: whitesmoke!important;
  color: #ED6C02;
`;

export const SubmitButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: whitesmoke!important;
`;

export const CloseStatusChangeButton = styled(Button)`
  font-size: 18px;
  text-transform: capitalize;
  border-color: whitesmoke !important;
  color: #ED6C02;
`;

export const LightbulbIcon = styled(LightbulbOutlined)`
  color: #ED6C02;
  font-size: x-large!important;
  margin-right: -5px;
`;

export const CancelIcon = styled(CancelOutlined)`
  color: #ED6C02;
`;

export const PrimaryAllocationIcon = styled(Person)`
  color: #ED6C02;
`;

export const SecondaryAllocationIcon = styled(PeopleAlt)`
  color: #ED6C02;
`;

export const AccountIcon = styled(AccountBoxOutlined)`
  color: #1769AA;
`;

export const ForwardIcon = styled(ChevronRightOutlined)`
  color: gray;
`;

export const MenuIcon = styled(MenuOutlined)`
  font-size: 32px;
  color: gray;
`;

export const MenuButton = styled(MaterialButton)`
  margin-right: -20px!important;
  color: gray;
`;

export const SubdirectoryArrowIcon = styled(SubdirectoryArrowRightOutlined)`
  color: #7B1FA2;
`;

export const LocationText = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #ED6C02;
`;

export const LocationSubText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;
  color: gray;
`;

export const AccountText = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: #1769AA;
`;

export const SuggestedText = styled(Typography)`
  font-size: 10px;
  font-weight: 500;
  color: gray;
`;

export const NotesText = styled(TextField)`
  flex-grow: 1;
`;

export const ClickableStack = styled(Stack)`
  cursor: pointer;
  border-radius: 8px;
  padding-right: 5px;
  &:hover {
    background-color: #EDEEEE;
  }
  transition: background-color 0.3s;
`;

export const AddButton = styled(Button)`
  height: 100%;
  min-height: 100px;
  width: 100%;
  min-width: 100%;
`;

export const CancelButton = styled(Button)`
  height: 40px;
  font-size: 18px;
  border-color: whitesmoke!important;
  color: #ED6C02;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;

export const FullWidthBadge = styled(Badge)`
  width: 100%;
`;

export const FullWidthBadgeInteraction = styled(Badge)`
  width: 110%;
`;

export const FullWidthMenuItem = styled(MenuItem)`
  width: 100%;
`;

export const StyledActionBarStack = styled('div')((props: { isMobile?: boolean }) =>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props.isMobile &&
    `
      padding: 5px;
      padding-right: 10px;
      border: 1px solid lightgray;
      border-radius: 8px;
      margin-top: 5px;
    `}
`);

export const SelectStatusContainer = styled(Stack)`
  width: 100%;
  margin-bottom: 12px;
`;

export const CloseIconWrapStack = styled(Stack)`
  margin-top: -20px;
  margin-left: 30px;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const StyledActionBarAllocateStack = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-right: 5px;
  &:hover {
    background-color: #EDEEEE;
  }
  transition: background-color 0.3s;
`;

export const ZapSquareIcon = styled(BoltIcon)`
  margin-right: 8px;
  border: 1px solid black;
  padding: 0px;
`;