export const removeEmptyObjects = (array: any) => {
  const newArray: any = [];

  array.forEach((element: any) => {
    if (Object.keys(element).length !== 0) {
      newArray.push(element);
    }
  });

  return newArray;
};

export const removeObjectAttributes = (array: string[], object: any) => {
  const temp = object;
  array.forEach((element: any) => {
    delete temp[element];
  });
  return temp;
};

export const splitAndSentenceCase = (input: string) => {
  const parts = input.split('-');
  const sentenceCaseParts = parts.map(part => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });
  const result = sentenceCaseParts.join(' ');
  return result;
};

export const PLATFORM_NAME = 'Amtek CMS';