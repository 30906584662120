import { Role, Timezone, Tag, StructureAttribute } from '.';

export interface User {
  username: string;
  role: Role;
  accessToken: string;
  refreshToken: string;
}

export interface AppUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  contactEmail: string;
  auth0Id: string;
  isActive: boolean;
  isLocked: boolean;
  isEmailVerified: boolean;
  profilePic: string;
  profilePicDetails?: FileList;
  timezoneId: number;
  timezoneName?: string;
  timezone?: Timezone;
  notes: string;
  lastActive: string;
  systemRoleId: number;
  role: Role;
  structureId: number;
  createdAt: string;
  updatedAt: string;
  tags: Tag[];
  siblingNodeId: number;
  siblingNodeName: string;
  siblingAttributes: StructureAttribute[];
  siblingAddresses?: string;
  siblingContacts?: string;
  siblingRefNumber1?: string;
  siblingRefNumber2?: string;
  siblingRefNumber3?: string;
  siblingParentNodeId?: number | null;
  siblingParentNodeName?: string;
  siblingParentNodeNamesTree?: string;
  userCreatedBy?: string;
  userUpdatedAt?: Date;
  userUpdatedBy?: string;
  siblingCreatedAt?: Date;
  siblingCreatedBy?: string;
  siblingUpdatedAt?: Date;
  siblingUpdatedBy?: string;
}

export interface UserHelperText {
  firstNameHelperText: string;
  lastNameHelperText: string;
  EmailHelperText: string;
  nodeNameHelperText: string;
}

export interface UserLogin {
  username: string;
  password: Role;
}

export interface Auth0AppUser {
  blocked: boolean;
  email_verified: boolean;
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  updated_at: string;
  picture: string;
  account_enabled: boolean;
  azure_id: string;
  identities: Identities[];
}

export interface Identities {
  connection: string;
}

export interface CSVExportUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  contactEmail?: string;
  auth0Id?: string;
  isActive?: boolean;
  isLocked?: boolean;
  isEmailVerified?: boolean;
  profilePic?: string;
  timezoneId?: string;
  timezoneName?: string;
  notes?: string;
  lastActive?: string;
  systemRoleId?: string;
  structureId?: number;
  roleName?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum UserSectionFields {
  FirstNameField = 'First Name field',
  LastNameField = 'Last Name field',
  EmailField = 'Email field',
  TimezoneField = 'Timezone field',
  RoleField = 'Role field'
}

export enum UserFormType {
  CreateUser = 'Create User',
  UpdateUser = 'Update User',
  ListUsers = 'List Users'
}

export interface LoggedInUser {
  userName: string;
  profilePic: string;
}
