import { httpClient, HttpMethod } from './http-client';
import { StructureType, StructureTypeResponse } from '../@types';

export const getStructureTypesByCategoryCode = (catagoryCode: string): StructureTypeResponse[] => {
  return httpClient(`/nodeTypeCategories/${catagoryCode}/nodeTypes`, HttpMethod.Get, null);
};

export const createStructureType = (nodeType: StructureType) => {
  return httpClient('/nodeTypes', HttpMethod.Post, nodeType);
};

export const getStructureTypeByCode = (code: string) => {
  return httpClient(`/nodeTypes/${code}`, HttpMethod.Get, null);
};

export const getStructureTypeByCodeForUpdate = (code: string) => {
  return httpClient(`/nodeTypes/${code}/updateType`, HttpMethod.Get, null);
};

export const updateStructureType = (code: string, nodeType: StructureType) => {
  return httpClient(`/nodeTypes/${code}`, HttpMethod.Put, nodeType);
};