import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { AutocompleteCloseReason, Button, Collapse, Grid, IconButton, InputAdornment } from '@mui/material';
import { ChevronLeft, ChevronRight, Layers, Search } from '@mui/icons-material';
import { TLCTemplateListProps } from './TLCTemplateList.props';
import { TLCTemplateListItem } from '../../molecules/TLCTemplateListItem';
import { TextField } from '../../atoms/TextField';
import { SizeProps } from '../../../@types';
import { StyledMenuItem, StyledPopper, StyledAutoComplete } from './TLCTemplateList.styles';
import { TLCTemplateDetailsDataType } from '../../../@types/tlc.type';
import { ButtonSizeProps } from '../../atoms/Button';

export const TLCTemplateList: React.FC<TLCTemplateListProps> = ({
  templateData,
  expanded,
  onClickExpand,
  onClickItem
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [checked, setChecked] = React.useState(expanded);
  const anchorRef = useRef<any>();

  useEffect(() => {
    setAnchorEl(anchorRef.current || null);
  }, [anchorRef.current]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: TLCTemplateDetailsDataType,
  ) => {
    setSelectedIndex(item.id);
    handleChange();
    onClickItem(item);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
    onClickExpand && onClickExpand(checked);
    setAnchorEl(checked ? null : anchorRef.current);
  };
  
  return (
    <Grid container alignItems="center" mt={checked ? 1 : 0}>
      <Grid item>
        <IconButton>
          {checked ? <ChevronLeft onClick={handleChange}/> : <ChevronRight onClick={handleChange}/>}
        </IconButton>
      </Grid>
      <Grid item mr={1}>
        <Collapse timeout={0} orientation="horizontal" in={checked}>
          <Button 
            size={ButtonSizeProps.Small}
            ref={anchorRef} 
            startIcon={<Layers />} 
            variant="outlined" 
          >
              Select Template
          </Button>
          <StyledPopper open={open} anchorEl={anchorEl} placement="bottom-end">
            <div>
              <StyledAutoComplete
                open
                openOnFocus
                id="template-select"
                forcePopupIcon={false}
                options={templateData}
                autoHighlight
                getOptionLabel={(option: any) => option.name}
                onClose={(
                  event: SyntheticEvent<Element, Event>,
                  reason: AutocompleteCloseReason,
                ) => {
                  if (reason === 'escape') {
                    handleClose();
                  }
                }}
                renderOption={(props, option: any) => (
                  <StyledMenuItem 
                    key={option.id} 
                    value={option.name} 
                    selected={option.id === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, option)}
                  >
                    <TLCTemplateListItem data={option}/>
                  </StyledMenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size={SizeProps.Small}
                    placeholder="Search filter"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                      startAdornment:
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>,
                    }}
                  />
                )}
              />
            </div>
          </StyledPopper>
        </Collapse>
      </Grid>
    </Grid>
  );
};