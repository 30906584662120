import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, ThemeProvider } from '@mui/material';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { TypographyVariantProps } from '../../components/atoms/Typography';
import { ErrorBox, ErrorTypography } from './Error.styles';
import { MainLayout } from '../../components/organisms/MainLayout';
import { lightTheme } from '../../configs/themes';
import { Modal } from '../../components/atoms/Modal';
import { logoutUser } from '../../utils/users';
import { USER_ERROR_MESSAGES, USER_ERROR_SCENARIOS } from '../../constants/error.constant';

export const Error: React.FC = () => {
  const { user, error, logout } = useAuth0();
  let errorMessage = '';

  if(user?.email_verified === false) {
    errorMessage = USER_ERROR_MESSAGES.EMAIL_VERIFIED;
  }

  if(error?.message === USER_ERROR_SCENARIOS.BLOCKED_ERROR) {
    errorMessage = USER_ERROR_MESSAGES.BLOCKED;
  }

  if(error?.message === USER_ERROR_SCENARIOS.ACCESS_DENIED) {
    errorMessage = USER_ERROR_MESSAGES.UNKNOWN;
  }

  if(error?.message === USER_ERROR_SCENARIOS.CONSENT_ERROR) {
    errorMessage = USER_ERROR_MESSAGES.RETRY;
  }

  if(error?.message === USER_ERROR_SCENARIOS.INVALID_STATE || error?.message === undefined) {
    errorMessage = USER_ERROR_MESSAGES.RETRY;
  }

  const onLogOut = useCallback(() => {
    logoutUser();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        <MainLayout/>
      </ThemeProvider>
      <Modal open={true}>
        <ErrorBox>
          <ErrorTypography variant={TypographyVariantProps.H1}>
            ERROR!
          </ErrorTypography>
          <ErrorTypography variant={TypographyVariantProps.H6}>
            {errorMessage}
          </ErrorTypography>
          <Grid mt="50px">
            <Button variant={ButtonVariantProps.Primary} onClick={onLogOut}>Exit</Button>
          </Grid>
        </ErrorBox>
      </Modal>  
    </div>
  );
};