import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';
import { Box, BoxProps } from '../../../components/atoms/Box';
import { SelectMenu } from '../../../components/atoms/SelectMenu';

export const ServiceTypography = styled(Typography)`
  padding-bottom: 10px;
`;

export const ServiceBox = styled(Box)`
  width: 100%;
  padding: 10px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
`;

export const StyledSelectMenu = styled(SelectMenu)`
  width: 100%;
`;

export const StyledSelectMenuSmall = styled(SelectMenu)`
  width: 50%;
`;

export const StyledListItem = styled('li')`
  border-bottom: 1px solid lightgray;
`;

export const StyledBox = styled(Box)((props: BoxProps & { heighlightBackground?: boolean }) => `
  background-color: ${props.heighlightBackground ? 'rgba(255, 99, 71, 0.1)' : 'transparent'};`
);
