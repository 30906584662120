import { styled, Stack } from '@mui/material';
import { TextField } from '../../../../../components/atoms/TextField';

export const StyledStack = styled(Stack)`
  width: 400px;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
  min-width: 400px;
`;