import React, { useEffect, useState } from 'react';
import { Stack, Table, TableRow } from '@mui/material';
import { ReplenishmentFilterFragmentProps } from './ReplenishmentFilterFragment.props';
import { LocationSearchBox, StyledTableCell } from './ReplenishmentFilterFragment.styles';
import { LocationNodeData, NodeType, StructureStatesResponse, StructureTypeResponse } from '../../../../@types';
import { useGetStructureTypesByCategoryCode } from '../../../../queries/structure-type-query';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { PartConditionDropdown, SearchCode } from '../../../../@types/part.type';
import { useReplenishmentPartTypeSearch, useReplenishmentSkuSearch, useSearchNodeByParentId, useSearchNodeShipment } from '../../../../queries/live-search-query';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { TYPE_DROPDOWN_ITEMS } from '../../../../constants/replenishmentConfig';
import { ANY_ITEM, UNDEFINED_TREE, UNDEFINED_TREE_FIRST_ITEM } from '../../../../constants/common';
import { useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment, useGetNodeChildrenByNodeType } from '../../../../queries/structure-query';
import { ReplenishmentConfigType } from '../../../../@types/replenishmentConfig.type';
import { useGetPartConditions } from '../../../../queries/part-query';
import { BrandSelector } from '../../../../components/molecules/BrandSelector';
import { StructureTypeCategory } from '../../../../configs/enums';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { useGetStates } from '../../../../queries/geosocial-query';
import { getLocationStates } from '../../../../utils/replenishment';

export const ReplenishmentFilterFragment: React.FC<ReplenishmentFilterFragmentProps> = ({
  replenishmentFilterData,
  onChange
}) => {
  const [structureTypes, setStructureTypes] = useState<Array<StructureTypeResponse>>([]);
  const [locationStates, setLocationStates] = useState<Array<StructureStatesResponse>>([]);
  const [conditions, setConditions] = useState<Array<PartConditionDropdown>>([]);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [openParentSelector, setOpenParentSelector] = useState(false);

  const { data } = useGetStructureTypesByCategoryCode(StructureTypeCategory.Operational);
  const searchNodeByParentId = useSearchNodeByParentId();
  const getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment = useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const replenishmentSkuSearch = useReplenishmentSkuSearch();
  const replenishmentPartTypeSearch = useReplenishmentPartTypeSearch();
  const getPartConditions = useGetPartConditions();
  const getStates = useGetStates();
  const searchDestributionNodesQuery = useSearchNodeShipment();

  useEffect(() => {
    data && setStructureTypes(data);
  }, [data]);

  useEffect(() => {
    getStates.data && setLocationStates(getStates.data);
  }, [getStates.data]);

  useEffect(() => {
    onChange(replenishmentFilterData);
  }, [replenishmentFilterData]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data) {
      const clientsList = getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data.map(item => ({ 
        value: item.id.toString(), 
        label: item.name,
        disabled: !item.isActive
      }));

      setClients(clientsList);
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCodeForReplenishment.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({ 
          value: item.id.toString(), 
          label: item.name,
          disabled: !item.isActive
        })); 
        const contractMenuListWithAny = ANY_ITEM.concat(contractMenuList);
        setContracts(contractMenuListWithAny);
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    getPartConditions.data && setConditions(getPartConditions.data);
  }, [getPartConditions.data]);

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);

    replenishmentFilterData && onChange({
      ...replenishmentFilterData,
      nodeId: parseInt(parentId),
    });
  };

  return (
    <Stack>
      <Table size="small">
        <TableRow>
          <StyledTableCell width="25%">
            <SelectMenu
              id="type-selection"
              label="Structure Type"
              selectedValue={replenishmentFilterData.structureType || '-1'}
              items={structureTypes.map(item => {
                return { value: item.code, label: item.name };
              })}
              onChange={(value) => onChange({
                ...replenishmentFilterData,
                structureType: value === '-1' ? '' : value
              })}
              optionalLabelEnabled={true}
            />
          </StyledTableCell>
          <StyledTableCell width="25%">
            <LocationSearchBox>
              <LiveSearchBox
                title="Structure Search"
                timeOffset={400}
                value={replenishmentFilterData.structureName || ''}
                onClearValue={() => onChange({
                  ...replenishmentFilterData,
                  structureId: 0,
                  structureName: ''
                })}
                renderItem={(props: any, option: any) => { 
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => onChange({
                  ...replenishmentFilterData,
                  structureId: obj.id,
                  structureName: obj.name
                })}
                onApiInvoke={async (name: string) => {
                  return await searchNodeByParentId.mutateAsync({
                    name: name,
                    hasCode: name.toLocaleLowerCase().includes(SearchCode.AM_LID.toLocaleLowerCase())
                  });
                }}
              />
            </LocationSearchBox>
          </StyledTableCell>
          <StyledTableCell width="25%"> 
            <SelectMenu
              id="structure-location-state-selection"
              label="Structure Location State"
              selectedValue={replenishmentFilterData.structureLocationStateId || '-1'}
              items={getLocationStates(locationStates)}
              onChange={(value) => onChange({
                ...replenishmentFilterData,
                structureLocationStateId: value === '-1' ? 0 : value
              })}
              optionalLabelEnabled={true}
            />
          </StyledTableCell>
          <StyledTableCell width="25%">
            <SelectionButton
              label="Client Selection"
              value={replenishmentFilterData?.clientContract === UNDEFINED_TREE || replenishmentFilterData?.clientContract === UNDEFINED_TREE_FIRST_ITEM ? 'Client Selection' : replenishmentFilterData?.clientContract || 'Client Selection'}
              onClick={() => setOpenParentSelector(true)}
            />
          </StyledTableCell>
        </TableRow>
      </Table >
      <Table size="small">
        <TableRow>
          <StyledTableCell width="25%">
            <SelectMenu
              required={true}
              id="part-type-or-sku-category"
              labelId="part-type-or-sku-category"
              label="Part Type / SKU"
              selectedValue={replenishmentFilterData?.type || '-1'}
              onChange={async (value) => {
                replenishmentFilterData && onChange({
                  ...replenishmentFilterData,
                  type: value === '-1' ? '' : value,
                  skuPartType: '',
                });
              }}
              items={TYPE_DROPDOWN_ITEMS}
              optionalLabelEnabled={true}
            />
          </StyledTableCell>
          <StyledTableCell width="25%">
            <LocationSearchBox>
              <LiveSearchBox
                title={replenishmentFilterData?.type === ReplenishmentConfigType.Sku ? 'SKU Live Search' : 'Part Type Live Search'}
                key={replenishmentFilterData?.type}
                disabled={!replenishmentFilterData?.type || replenishmentFilterData?.type === '-1'}
                timeOffset={400}
                value={replenishmentFilterData.skuPartType || ''}
                onClearValue={() => replenishmentFilterData && onChange({
                  ...replenishmentFilterData,
                  skuPartType: '',
                  typeId: 0,
                })}
                renderItem={(props: any, option: any) => { 
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => {
                  replenishmentFilterData && onChange({
                    ...replenishmentFilterData,
                    skuPartType: obj.name,
                    typeId: obj.id,
                  });
                }}
                onApiInvoke={async (name: string) => {
                  if (replenishmentFilterData?.type === ReplenishmentConfigType.Sku) {
                    return await replenishmentSkuSearch.mutateAsync({
                      name: name,
                      nodeId: replenishmentFilterData.nodeId
                    });
                  } else {
                    return await replenishmentPartTypeSearch.mutateAsync({
                      name: name,
                      nodeId: replenishmentFilterData.nodeId
                    });
                  }
                }}
              />
            </LocationSearchBox>
          </StyledTableCell>
          <StyledTableCell width="25%">
            <SelectMenu
              id="type-selection"
              label="Condition"
              selectedValue={replenishmentFilterData.condition || '-1'}
              items={conditions}
              onChange={(value) => onChange({
                ...replenishmentFilterData,
                condition: value === '-1' ? '' : value,
              })}
              optionalLabelEnabled={true}
            />
          </StyledTableCell>
          <StyledTableCell width="25%">
            <LocationSearchBox>
              <LiveSearchBox
                title="Distribution Centre"
                timeOffset={400}
                value={replenishmentFilterData.distributionCentre || ''}
                onClearValue={() => replenishmentFilterData && onChange({
                  ...replenishmentFilterData,
                  distributionCentre: '',
                  distributionCentreId: 0,
                })}
                renderItem={(props: any, option: any) => { 
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => {
                  replenishmentFilterData && onChange({
                    ...replenishmentFilterData,
                    distributionCentre: obj.name,
                    distributionCentreId: obj.id,
                  });
                }}
                onApiInvoke={async (name: string) => {
                  return await searchDestributionNodesQuery.mutateAsync(name);
                }}
              />
            </LocationSearchBox>   
          </StyledTableCell>
        </TableRow>
        <BrandSelector
          open={openParentSelector}
          handleClose={() => setOpenParentSelector(false)}
          onChangeNodeType={onChangeNodeType}
          handleParentSelection={(selectedBrandId: string, value: string) => {
            setOpenParentSelector(false);
            replenishmentFilterData && onChange({
              ...replenishmentFilterData,
              clientContract: value,
              skuPartType: ''
            });
          }}
          handleClear={() => {
            replenishmentFilterData && onChange({
              ...replenishmentFilterData,
              clientId: NaN,
              contractId: NaN,
              clientContract: ''
            });
          }}
          clients={clients}
          contracts={contracts}
          brands={[]}
          clientId={replenishmentFilterData.clientId}
          contractId={replenishmentFilterData?.contractId}
          allFieldsRequired={false}
          isBrandEnabled={false}
        />
      </Table>
    </Stack>
  );
};