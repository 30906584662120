import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';


export const SwitchGrid = styled(Grid)`
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;

export const TableWrapper = styled('div')`
  overflow: auto;
  width: 100%;
  height: 40vh;
  max-height: calc(100vh - 278px);
`;
