import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { 
  ServiceStatusResponse, 
  NextServiceStatusesResponse,
  AvailableStatusesForServiceTypes,
  StatusChangeData,
  ServiceType,
} from '../@types/service.type';
import { 
  getNextServiceStatuses,
  saveServiceStatus,
  getServiceCancelOrReopenStatuses
} from '../api/services';
import { 
  createServiceStatus, 
  getServiceStatusByCode, 
  getAllServiceStatus, 
  updateServiceStatus,
  getPossibleStatusesByServiceTypes,
} from '../api/serviceStatuses';
import { AvailableStatusKey, CancelReOpenStatusKey, NextServiceStatusKey, ServiceStatusCodeKey, ServiceStatusKey } from '../configs/service-status';
import { ServiceTypeKey, ServiceTypeStatusOptionKey } from '../configs/service-type';
import {
  ServiceLogAllKey,
  CheckInKey,
  GetServiceKey
} from '../configs/services';
import { PartActionKey } from '../configs/partAction';

export const useGetServiceStatuses = (): UseQueryResult<ServiceStatusResponse[]> => {
  return useQuery([ServiceStatusKey], () => getAllServiceStatus(), {
    select: (response: any) => response
  });
};

export const useGetNextServicesStatuses = (code: string, serviceType: string, serviceId: number): UseQueryResult<NextServiceStatusesResponse> => {
  return useQuery([NextServiceStatusKey, code], () => getNextServiceStatuses(code, serviceType, serviceId), { enabled: false });
};

export const useGetServicesStatuses = (): UseQueryResult<ServiceStatusResponse[]> => {
  return useQuery([ServiceStatusKey], () => getAllServiceStatus(), { enabled: false });
};

export const useGetServiceStatusByCode = (code: string): UseQueryResult<ServiceStatusResponse> => {
  return useQuery([ServiceStatusKey, code], () => code && getServiceStatusByCode(code), {
    select: (response: any) => response,
    enabled: false
  });
};

export const useGetServiceStatusByShortName = (code: string): UseQueryResult<ServiceStatusResponse> => {
  return useQuery([ServiceStatusCodeKey, code], () => getServiceStatusByCode(code), {
    select: (response: any) => response,
    retry: false,
    enabled: false
  });
};

export const useCreateServiceStatus = (code: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createServiceStatus(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceStatusKey]);
      await queryClient.invalidateQueries([ServiceStatusKey, code]);
    },
  });
};

export const useUpdateServiceStatus = (code: string): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateServiceStatus(data.code, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceStatusKey]);
      await queryClient.invalidateQueries([ServiceStatusKey, code]);
    },
  });
};

export const useSaveServiceStatus = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await saveServiceStatus(id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceTypeKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
      await queryClient.invalidateQueries([GetServiceKey, id]);
      await queryClient.invalidateQueries([CheckInKey, id]);
      await queryClient.invalidateQueries([PartActionKey, id]);
    },
  });
};

export const useGetPossibleStatusesByServiceTypes = (data: StatusChangeData): UseQueryResult<AvailableStatusesForServiceTypes> => {
  return useQuery([AvailableStatusKey, data?.serviceTypes], () => getPossibleStatusesByServiceTypes(data.code, data.serviceTypes), { 
    enabled: false,
    select: (response: any) => response
  });
};

export const useGetServiceCancelOrReopenStatuses = (option: string, statusCode: string, serviceType: string, serviceId: number): UseQueryResult<ServiceType[]> => {
  return useQuery([CancelReOpenStatusKey], () => option && getServiceCancelOrReopenStatuses(option, statusCode, serviceType, serviceId), { enabled: false });
};

export const useGetAllServiceStatus = (): UseQueryResult<[ServiceStatusResponse]> => {
  return useQuery([ServiceTypeStatusOptionKey], () => getAllServiceStatus());
};