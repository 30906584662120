import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { TextField, TextFieldVariantProps } from '../../atoms/TextField';
import { CustomSearchBarProps } from './CustomSearchBar.props';

export const CustomSearchBar: React.FC<CustomSearchBarProps> = ({
  value,
  handleChange
}) => {
  return (
    <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
      <TextField
        autoFocus={true}
        placeholder="Search"
        value={value}
        variant={TextFieldVariantProps.Standard}
        onChange={handleChange}
      />
    </GridToolbarContainer>
  );
};