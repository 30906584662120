import React, { useEffect, useState } from 'react';
import { CardActions, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { TaskServiceSpecialCardProps } from './TaskServiceSpecialCard.props';
import { CardTitle, CardWrapper, CloseWrapper, ModalInnerWrapper, StyledButton, StyledCardContent } from './TaskServiceSpecialCard.styles';
import { COLORS } from '../../../../../configs/colors';
import { TextField } from '../../../../../components/atoms/TextField';
import { UploadButton } from '../../../../../components/molecules/UploadButton';
import { LinearProgressBar } from '../../../../../components/molecules/LinearProgressBar/LinearProgressBar';
import { Modal } from '../../../../../components/atoms/Modal';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { getMimeTypeFromExtension } from '../../../../../utils/mime-type-utils';
import { uploadFileAsChunks } from '../../../../../utils/multipart-file-upload-utils';
import { TaskType } from '../../../../../@types/task.type';
import { Typography, TypographyVariantProps } from '../../../../../components/atoms/Typography';

export const TaskServiceSpecialCard: React.FC<TaskServiceSpecialCardProps> = ({
  data,
  onClose,
  onChange,
  onExec,
  onSave
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(-1);
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    setNote(data.notes);
  }, []);

  useEffect(() => {
    data.taskTypeCode === TaskType.WORK_ORDER_REQUEST && !data.taskData?.label && onChange({
      ...data,
      taskData: {
        label: data.additionalData
      }
    });
  }, [data]);

  const onSelectFile = (fileList: FileList) => {
    setSelectedFile(fileList[0]);
    return Promise.resolve();
  };

  const onTaskExec = async () => {
    if (selectedFile) {
      setUploadProgress(0);
      const fileData = await uploadFileAsChunks(selectedFile, data.serviceId, Folders.ServiceSpecialFileAttachments, setUploadProgress);

      if (fileData) {
        const taskData = {
          ...data,
          taskData: {
            notes: note,
            fileId: fileData?.id,
            fileName: fileData?.fileName
          }
        };

        fileData?.id && onExec(taskData);
      }
    } else if (data.taskTypeCode === TaskType.WORK_ORDER_REQUEST) {
      onExec({
        ...data,
        taskData: {
          label: data.additionalData
        }
      });
    }
  };

  return (
    <>
      <Modal open={uploadProgress >= 0}>
        <ModalInnerWrapper>
          <LinearProgressBar progress={uploadProgress} showPrecentage={true} />
        </ModalInnerWrapper>
      </Modal>
      <CardWrapper>
        <StyledCardContent>
          <CardTitle>{data.taskTypeName}</CardTitle>
          <CloseWrapper onClick={onClose}>
            <CloseRounded htmlColor={COLORS.White} />
          </CloseWrapper>
          {data.taskTypeCode === TaskType.SPECIAL_FILE_UPLOAD &&
            <Stack direction="column" spacing={1.5} width="100%">
              <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
                <Stack direction="column" spacing={2} marginTop={1} width="100%">
                  <UploadButton fileTypes={data.additionalData?.split(',').map(extention => getMimeTypeFromExtension(extention)) || []} maxFileSize={20000} setFileDetails={onSelectFile}
                    label="Browse" showName={true} />
                  <TextField
                    fullWidth
                    label="Notes"
                    multiline
                    rows={4}
                    value={note}
                    onChange={(noteText) => {
                      setNote(noteText);
                      onChange({
                        ...data,
                        taskData: {
                          notes: noteText
                        }
                      });
                    }}
                  />

                </Stack>
              </Stack>
            </Stack>
          }
          {data.taskTypeCode === TaskType.WORK_ORDER_REQUEST &&
            <Stack width="100%" alignItems="center" marginTop={1}>
              <Typography variant={TypographyVariantProps.Subtitle1}>{data.additionalData || ''}</Typography>
            </Stack>
          }
        </StyledCardContent>
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Error}
              disabled={!selectedFile && data.taskTypeCode !== TaskType.WORK_ORDER_REQUEST}
              onClick={onTaskExec}
            >
              Exec
            </StyledButton>
            <StyledButton
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {data.isNew ? 'Save' : 'Update'}
            </StyledButton>
          </Stack>
        </CardActions>
      </CardWrapper>
    </>
  );
};