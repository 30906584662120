import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { FormControlLabel } from '../../atoms/FormControlLabel';

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-left: 125px;
`;

export const SwitchGrid = styled(Grid)`
  margin-top: -15px;
  justify-content: center;
  align-items: center;
`;
