import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../../atoms/Button';

export const IconWrapper = styled(Stack)`
    height: 100%;
    margin: auto 0;
    padding-left: 10px;
`;

export const BulkPutawayButton = styled(Button)`
  width: 200px;
`;

export const LiveSearchBoxWrapper = styled(Stack)`
  width: 300px;
`;