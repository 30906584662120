import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { 
  ServiceStatusResponse,
  LinkObject,
  Technician,
  AllocatedService,
} from '../@types/service.type';
import { getCurrentUserAllocatedServices } from '../api/services';
import { 
  getServiceCheckIn,
  createServiceCheckIn,
  updateServiceCheckOut,
  getServiceListByTechnician,
  getTechnicianListByLocationNodeId,
  createBulkServiceCheckInQuery,
} from '../api/checkin-checkout';
import { ServiceTypeKey } from '../configs/service-type';
import {
  ServiceLogAllKey,
  ServiceCheckInKey,
  ServiceLinkKey,
  TechniciansKey,
  CheckInKey,
  CurrentUserAllocatedServicesKey,
  BulkCheckInKey,
  GetServiceKey
} from '../configs/services';
import { PartActionKey } from '../configs/partAction';

export const useGetServiceCheckIn = (id: number): UseQueryResult<ServiceStatusResponse[]> => {
  return useQuery([ServiceCheckInKey, id], () => getServiceCheckIn(id), {
    enabled: false,
    select: (response: any) => response
  });
};

export const useCreateServiceCheckIn = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await createServiceCheckIn(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceCheckInKey, id]);
      await queryClient.invalidateQueries([ServiceTypeKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
      await queryClient.invalidateQueries([GetServiceKey, id]);
      await queryClient.invalidateQueries([CheckInKey, id]);
      await queryClient.invalidateQueries([PartActionKey, id]);
    },
  });
};

export const useGetServiceCheckInData = (id: number): UseQueryResult<ServiceStatusResponse[]> => {
  return useQuery([CheckInKey, id], () => getServiceCheckIn(id));
};

export const useCreateBulkServiceCheckInQuery = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await createBulkServiceCheckInQuery(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([BulkCheckInKey]);
    },
  });
};

export const useUpdateServiceCheckOut = (id: number, serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await updateServiceCheckOut(id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceCheckInKey, id]);
      await queryClient.invalidateQueries([ServiceTypeKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
      await queryClient.invalidateQueries([GetServiceKey, serviceId]);
      await queryClient.invalidateQueries([CheckInKey, serviceId]);
      await queryClient.invalidateQueries([PartActionKey, id]);
    },
  });
};

export const useGetServiceListByTechnician = (): UseQueryResult<LinkObject[]> => {
  return useQuery([ServiceLinkKey], () => getServiceListByTechnician());
};

export const useGetTechnicianListByLocationNodeId = (locationNodeId: number): UseQueryResult<Technician[]> => {
  return useQuery([TechniciansKey, locationNodeId], () => locationNodeId && getTechnicianListByLocationNodeId(locationNodeId));
};

export const useGetCurrentUserAllocatedServices = (locationNodeId: number, serviceId: number): UseQueryResult<AllocatedService[]> => {
  return useQuery([CurrentUserAllocatedServicesKey, serviceId], () => getCurrentUserAllocatedServices(locationNodeId, serviceId));
};