import React, { useState, useEffect } from 'react';
import {
  GridDensityTypes,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { ViewColumn } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { DataGrid } from '../../atoms/DataGrid';
import { FormControlLabel } from '../../atoms/FormControlLabel';
import { Switch } from '../../atoms/Switch';
import { ColumnSelection, ColumnSelectionItem } from '../../templates/ColumnSelection';
import { ImportDataGridProps,ImportDataGridToggle } from './ImportDataGrid.props';
import { ColorProps } from '../../../@types';
import { getColumnVisibility } from '../../../utils/array-utils';

export const ImportDataGrid: React.FC<ImportDataGridProps> = ({
  columns,
  rows,
  columnSelectionItems,
  density = GridDensityTypes.Compact,
  getRowId,
  componentsProps,
  disableColumnFilter,
  disableColumnMenu,
  disableColumnSelector,
  disableDensitySelector,
  filterMode,
  onFilterModelChange,
  loading,
  rowCount,
  rowsPerPageOptions,
  pagination,
  page,
  pageSize,
  paginationMode,
  onPageChange,
  onPageSizeChange,
  initialState,
  filterModel,
  onSortModelChange,
  sortingMode,
  selectionModel,
  onSelectionModelChange,
  getRowClassName,
  toggleButtons,
  toggleStates,
  onToggleSelect,
  handleRefresh,
  getRowHeight
}) => {
  const [open, setOpen] = useState(false);
  const [columnSelectionData, setColumnSelectionData] = useState<Array<ColumnSelectionItem>>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  useEffect(() => {
    columnSelectionItems && setColumnSelectionData(columnSelectionItems);
  }, [columnSelectionItems]);

  useEffect(() => {
    const columnVisibility = getColumnVisibility(columnSelectionData);
    setColumnVisibilityModel(columnVisibility);
  }, [columnSelectionData]);

  const CustomToolBar = () => (
    <GridToolbarContainer>
      <Stack direction="row" spacing={2}>
        <Button startIcon={<ViewColumn />} onClick={() => setOpen(!open)}>Column Selection</Button>
        <GridToolbarDensitySelector />
      </Stack>
      <Stack direction="row" ml={6} spacing={4}>
        {toggleButtons?.map((item: ImportDataGridToggle, index: number) => (
          <FormControlLabel
            key={index}
            control={<Switch color={ColorProps.Success} onChange={(e)=>onToggleSelect(index, e.target.checked )} checked={toggleStates[index].value} />}
            label={item.toggleName}
          />
        ))}
      </Stack>
      <Stack direction="row" ml={4} spacing={4}>
        <Button variant="outlined" onClick={handleRefresh}>Refresh</Button>
      </Stack>
    </GridToolbarContainer>
  );

  return (
    <>
      <DataGrid
        columns={columns}
        rows={rows}
        density={density}
        getRowId={getRowId}
        componentsProps={componentsProps}
        columnVisibilityModel={columnVisibilityModel}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnMenu}
        disableColumnSelector={disableColumnSelector}
        disableDensitySelector={disableDensitySelector}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        loading={loading}
        rowCount={rowCount}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination={pagination}
        page={page}
        pageSize={pageSize}
        paginationMode={paginationMode}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        initialState={initialState}
        customToolbar={CustomToolBar}
        filterModel={filterModel}
        onSortModelChange={onSortModelChange}
        sortingMode={sortingMode}
        getRowClassName={getRowClassName}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        getRowHeight={getRowHeight}
      />
      <ColumnSelection
        open={open}
        title="Column Selection"
        value={columnSelectionData} 
        onChange={(val) => setColumnSelectionData(val)}
        onClose={() => setOpen(false)}
      />
    </>
  );
};