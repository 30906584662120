import { httpClient, HttpMethod } from './http-client';
import { CreateFreightOrderType, ArchiveFreightOrderType, ReturnFreightOrderType, QuoteFreightOrderType } from '../@types/shipment.type';

export const getFreightPackageTypes= () => {
  return httpClient('/freightPackageTypes', HttpMethod.Get, null);
};

export const createFreightOrder = (freightOrder: CreateFreightOrderType) => {
  return httpClient('/freightOrder', HttpMethod.Post, freightOrder);
};

export const archiveFreightOrder = (freightOrder: ArchiveFreightOrderType) => {
  return httpClient('/archiveOrder', HttpMethod.Post, freightOrder);
};

export const returnFreightOrder = (freightOrder: ReturnFreightOrderType) => {
  return httpClient('/returnOrder', HttpMethod.Post, freightOrder);
};

export const quoteFreightOrder = (freightOrder: QuoteFreightOrderType) => {
  return httpClient('/deliveryservices', HttpMethod.Post, freightOrder);
};

export const freightOrderByShipment = (shipmentId: number) => {
  return httpClient(`/freightOrder/shipment/${shipmentId}`, HttpMethod.Get, null);
};