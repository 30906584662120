import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { MainPartEditFragment } from './MainPartEditFragment';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { PartConditionDropdown, PartObject } from '../../../@types/part.type';
import { useGetAllWarrantySummary, useGetOnePart, useGetPartConditions } from '../../../queries/part-query';
import { SAMPLE_PART } from '../../../constants/part';
import { WarrantySummaryFragment } from './WarrantySummaryFragment';
import { WarrantyPanalType } from '../../../@types/warrantySummary.type';
import { PartHistoryFragment } from './PartHistoryFragment';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { NotFoundError } from '../../Error/NotFound';

export const UpdatePart: React.FC = () => {
  const { id } = useParams();
  const partId = id ? parseInt(id) : -1;
  const [conditions, setConditions] = useState<PartConditionDropdown[]>([]);

  const [part, setPart] = useState<PartObject>(SAMPLE_PART);
  const [warrantySummary, setWarrantySummary] = useState<WarrantyPanalType[]>([]);
  const getOnePart = useGetOnePart(partId);
  const getPartConditions = useGetPartConditions();
  const getAllWarrantySummary = useGetAllWarrantySummary(partId);

  useEffect(() => {
    getOnePart.data && setPart(getOnePart.data);
  }, [getOnePart.data]);

  useEffect(() => {
    getPartConditions.data && setConditions(getPartConditions.data);
  }, [getPartConditions.data]);

  useEffect(() => {
    getAllWarrantySummary.data && setWarrantySummary(getAllWarrantySummary.data);
  }, [getAllWarrantySummary.data]);

  if (!getOnePart.data) {
    return getOnePart.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }
  
  return (
    <PageContainer>
      <MainPartEditFragment data={part} conditions={conditions}/>
      <Grid mt={3}>
        <WarrantySummaryFragment warrantySummarydata={warrantySummary} firstInstalled={part.firstInstalled} />
      </Grid>
      <Grid mt={3}>
        <PartHistoryFragment partId={partId} />
      </Grid>
    </PageContainer>
  );
};