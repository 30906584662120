import { 
  PartType,
  PartTypeActionLiveSearchItemCategoryCode, 
  PartTypeActionLiveSearchSerialiseType, 
} from '../@types';
import { PartTypeCategory } from '../configs/enums';
import { CHARACTER_CUSTOM_LENGTH_MESSAGE } from '../constants/user.constant';
import { isInputFieldEmpty } from './services';

export const getPartTypeActionLiveSearchSerialiseType = (categoryCode?: string) => {
  switch(categoryCode) {
    case PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent:
    case PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice:
      return PartTypeActionLiveSearchSerialiseType.Serialised;
    case PartTypeActionLiveSearchItemCategoryCode.NonSerialisedComponent:
    case PartTypeActionLiveSearchItemCategoryCode.Consumable:
      return PartTypeActionLiveSearchSerialiseType.NonSerialised;
    default:
      return '';
  }
};

export const areApplicableNodeIdsSame = (searchPartTypeArray: PartType[]) => {
  const referenceApplicableNodeIds = searchPartTypeArray[0]?.applicableNodeIds;
  return searchPartTypeArray.every((partArray: PartType) => partArray.applicableNodeIds === referenceApplicableNodeIds);
};

export const compareWithPrevious = (previousPartType: PartType, currentPartType: PartType) => {
  if (previousPartType.name !== currentPartType.name) {
    return true;
  }
  if (previousPartType.number !== currentPartType.number) {
    return true;
  }
  if (previousPartType.manufacturerNodeId !== currentPartType.manufacturerNodeId) {
    return true;
  }
  if (previousPartType.selectedClient !== currentPartType.selectedClient) {
    return true;
  }
  if (JSON.stringify(previousPartType.selectedContract) !== JSON.stringify(currentPartType.selectedContract)) {
    return true;
  }
  if (previousPartType.notes !== currentPartType.notes) {
    return true;
  }
  if (previousPartType.isActive !== currentPartType.isActive) {
    return true;
  }

  return false;
};

export const characterLengthValidation = (value: string, length: number) => {
  if (value && value.length > length) {
    return CHARACTER_CUSTOM_LENGTH_MESSAGE + length + ' characters';
  } else {
    return '';
  }
};

export const clientContractValidator = (client: string, contract: string[]) => {
  if (isInputFieldEmpty(client) || contract.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const manufactuterValidator = (manufacturer: number) => {
  if (manufacturer <= 0) {
    return true;
  } else {
    return false;
  }
};

export const timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const getSerialised = (categoryCode: string) => {
  switch(categoryCode) {
    case PartTypeCategory.SerialisedComponent:
    case PartTypeCategory.SerialisedDevice:
      return 'Serialised';
    case PartTypeCategory.NonSerialisedComponent:
    case PartTypeCategory.Consumable:
      return 'Consumable';
    default:
      return 'Not specified';
  }
};