import React from 'react';
import { GridColDef, GridDensityTypes, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PartTypePendingTransfersProps } from './PartTypePendingTransfers.props';
import { StyledWrapper } from './PartTypePendingTransfers.styles';
import { Accordian } from '../../atoms/Accordian';
import { Link } from '../../atoms/Link';
import { DataGrid } from '../../atoms/DataGrid';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { formatDate } from '../../../utils/common';
import { ShipmentTypes } from '../../../configs/enums';

export const PartTypePendingTransfers: React.FC<PartTypePendingTransfersProps> = ({
  partTypePendingTransfersData,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'shipmentId',
      headerName: 'Shipment',
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.shipmentTypeCode === ShipmentTypes.Return) {
          return <Link href={`/shipments/return/${params.row.shipmentId}`}>{params.row.shipmentId}</Link>;
        } else {
          return <Link href={`/shipments/${params.row.shipmentId}`}>{params.row.shipmentId}</Link>;
        }
      }
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'conditionName',
      headerName: 'Condition',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'from',
      headerName: 'From',
      flex: 1,
    },
    {
      field: 'to',
      headerName: 'To',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      flex: 1,
      renderCell: (params) => (
        <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.createdAt)}</Typography>

      )
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
      flex: 1,
    }
  ];

  return (
    <StyledWrapper>
      <Accordian title={`Pending Transfers (${partTypePendingTransfersData.length})`} defaultExpanded={true}>
        <DataGrid
          density={GridDensityTypes.Standard}
          columns={columns}
          rows={partTypePendingTransfersData}
          getRowId={(row) => row?.id}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            },
          }}
          autoHeight={true}
        />
      </Accordian>
    </StyledWrapper>
  );
};