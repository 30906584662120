import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AllocationConfigKey, AllocationResponse } from '../@types/allocation.type';
import { createAllocationConfig, getAllAllocationConfigs, updateAllocationConfig } from '../api/allocations';

export const useGetAllAllocationConfigs = (): UseQueryResult<[AllocationResponse]> => {
  return useQuery([AllocationConfigKey], () => getAllAllocationConfigs(), {
    select: (response: any) => response
  });
};

export const useCreateAllocationConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    const response = await createAllocationConfig(data);
    return response;
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([AllocationConfigKey, response.id]);
    },
  });
};

export const useUpdateAllocationConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateAllocationConfig(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([AllocationConfigKey]);
    },
  }); 
};