import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Box } from '../../atoms/Box';
import { Typography } from '../../atoms/Typography';

export const CapacityBox = styled(Box)`
  width: 100%;
  border: 2px solid #eeeeee;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const StructureCapacityAddGrid = styled(Grid)`
  margin-top: 4px;
`;

export const TableGridContainer = styled(Grid)`
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

export const ColorPickerGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

export const SwitchGrid = styled(Grid)`
  margin-top: 10px;
  justify-content: center;
  align-items: center;
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 10px;
`;
