import { CreatePartActionResponse } from '../@types/partAction.type';
import { HttpMethod, httpClient } from './http-client';


export const createPartAction = (partAction: CreatePartActionResponse) => {
  return httpClient('/partAction', HttpMethod.Post, partAction);
};

export const getPartActionByServiceId = (serviceId: number) => {
  return httpClient(`/partActionsByServiceId/${serviceId}`, HttpMethod.Get, null);
};

export const updatePartActionById = (actionId: number, partAction: CreatePartActionResponse) => {
  return httpClient(`/partAction/${actionId}`, HttpMethod.Put, partAction);
};

export const deletePartActionById = (id: number) => {
  return httpClient(`/partAction/${id}`, HttpMethod.Delete, null);
};

export const getAllPartActionTypes= () => {
  return httpClient('/partActionTypes', HttpMethod.Get, null);
};