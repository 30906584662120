import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../../components/atoms/TextField';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { NonSerialisedPartsUpdateSelectionProps } from './NonSerialisedPartsUpdateSelection.props';
import { ContentWrapperBox, HeadingWrapper, SelectionWrapper, StyledSelectMenu } from './NonSerialisedPartsUpdateSelection.styles';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { LinkedListProperties, NonSerialisedPartsUpdateSelectionDataType } from '../../../@types/bulkPartUpdate.type';
import { LINKED_LIST_DROPDOWN_OPTIONS, SAMPLE_NON_SERIALIZED_PARTS_UPDATE_SELECTION_DATA } from '../../../constants/part';

export const NonSerialisedPartsUpdateSelection: React.FC<NonSerialisedPartsUpdateSelectionProps> = ({
  nonSerialisedPartsUpdateSelectionValue
}) => {
  const [isCheckQty, setIsCheckQty] = useState<boolean>(false);
  const [updateSelectionValues, setUpdateSelectionValue] = useState<NonSerialisedPartsUpdateSelectionDataType>(SAMPLE_NON_SERIALIZED_PARTS_UPDATE_SELECTION_DATA);

  useEffect(() => {
    nonSerialisedPartsUpdateSelectionValue(updateSelectionValues);
  }, [updateSelectionValues]);

  const valueIntializer = (value: string) => {
    switch(value) {
      case LinkedListProperties.QuantityInStock:
        updateSelectionValues.quantityInStock = 0;
        setIsCheckQty(false);
        break;
    }
  };

  return (
    <PageContainer>
      <ContentWrapperBox>
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Update Selection
        </HeadingWrapper>
        <SelectionWrapper>
          <Stack direction="row" spacing={2}>
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={isCheckQty}
              disabled={updateSelectionValues.isActiveLinkedField}
              onChange={(event) => setIsCheckQty(event.target.checked)}
            />
            <TextField
              fullWidth
              disabled={!isCheckQty}
              type={TextFieldTypeProps.Number}
              label="QTY"
              value={updateSelectionValues.quantityInStock}
              inputProps={{ min:0 }}
              onChange={(value) => setUpdateSelectionValue({ ...updateSelectionValues, quantityInStock: parseInt(value) })}
            />
          </Stack>
          <Stack direction="row" spacing={2} ml={4}>
            <Checkbox
              color={CheckboxColorProps.Primary}
              checked={updateSelectionValues.isActiveLinkedField}
              onChange={(event) => setUpdateSelectionValue({ ...updateSelectionValues, isActiveLinkedField: event.target.checked, linkedFieldColumn: '' })}
            />
            <StyledSelectMenu
              id="linked-field-select"
              label="Linked Field"
              disabled={!updateSelectionValues.isActiveLinkedField}
              items={LINKED_LIST_DROPDOWN_OPTIONS}
              selectedValue={updateSelectionValues.linkedFieldColumn}
              onChange={(value) => {
                valueIntializer(value);
                setUpdateSelectionValue({ ...updateSelectionValues, linkedFieldColumn: value });
              }}
            />
          </Stack>
        </SelectionWrapper>
      </ContentWrapperBox>
    </PageContainer>
  );
};