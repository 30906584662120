import { styled } from '@mui/material/styles';
import { Box } from '../../../components/atoms/Box';
import { Typography } from '../../../components/atoms/Typography';

export const ContentWrapperBox = styled(Box)`
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
`;

export const HeadingWrapper = styled(Typography)`
  padding-bottom: 20px;
`;

export const SelectionWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;