export const SAMPLE_SERVICE_STATUS = {
  id: 0,
  code: '',
  name: '',
  isActive: false,
  isSystem: false,
  isClosed: false,
  isShortCodeExist: false,
  isPauseSla: false,
  blockStatuses: [],
  nextStatuses: []
};

export const SAMPLE_SERVICE_STATUS_HELPER = {
  nameHelperText: '',
  shortNameHelperText: ''
};

export const STATUS_CHANGE_DATA = {
  code: 'new',
  serviceTypes: []
};

export const SHORT_NAME_NOT_EXIST_HELPER_TEXT = 'Short name already exist in other service status';

export const NEW_STATUS_DROPDOWN_ELEMENT = {
  value: 'new',
  label: 'New'
};