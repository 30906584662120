import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';

export const StyledWrapper = styled('div')`
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  color: #c0bfc0;
`;
