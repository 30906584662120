import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Link } from '../../../components/atoms/Link';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TlcTemplatesList } from './TlcTemplatesList/TlcTemplatesList';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { isUserHasPermission } from '../../../configs/permissions';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const TLCTemplate: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  TabHandler(`List TLC Templates | ${PLATFORM_NAME}`);

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List TLC Templates
          </Typography>
          {isUserHasPermission(ActionPermissions.Part_Template_Tlc, permissions) && (
            <Link href="/parts/tlcTemplates/create">
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
             Create Template 
              </Button>
            </Link>
          )}
        </InlineGroup>
        <TlcTemplatesList/>
      </Wrapper>
    </PageContainer>
  );
};