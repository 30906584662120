import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ServiceTemplateResponse } from '../@types/service.type';
import { getServiceTemplates } from '../api/services';
import { ServiceTemplateKey } from '../configs/services';

export const useGetServiceTemplates = (): UseQueryResult<[ServiceTemplateResponse]> => {
  return useQuery([ServiceTemplateKey], () => getServiceTemplates(), {
    select: (response: any) => response,
    staleTime: 2 * 60 * 60 * 1000
  });
};