import React from 'react';
import { Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Switch } from '../../atoms/Switch';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { ColorProps, FormFieldType } from '../../../@types';
import { isEmptyString } from '../../../utils/common';
import { FormBuilderFieldOptionProps } from './FormBuilderFieldOption.props';
import { StyledTextField } from './FormBuilderFieldOption.styles';
import { FormControlLabel } from '../../atoms/FormControlLabel';

export const FormBuilderFieldOption: React.FC<FormBuilderFieldOptionProps> = ({
  showErrors,
  value,
  onChange,
  onRemove,
  type
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <StyledTextField
        label="Title"
        required={true}
        error={showErrors && isEmptyString(value.title)}
        value={value.title}
        onChange={(val) => onChange({
          ...value,
          title: val
        })}
      />
      <StyledTextField
        label="Value"
        required={true}
        error={showErrors && isEmptyString(value.value)}
        value={value.value}
        onChange={(val) => onChange({
          ...value,
          value: val
        })}
      />
      <FormControlLabel
        control={<Switch onChange={() => onChange({ ...value, isActive: !value.isActive, isDefault: !value.isActive === false ? false : value.isDefault })} checked={value.isActive} color={ColorProps.Success}/>}
        label="Enabled"
      />
      {type === FormFieldType.Dropdown &&
        <FormControlLabel
          control={<Switch disabled={!value.isActive} onChange={() => onChange({ ...value, isDefault: !value.isDefault })} checked={value.isDefault} color={ColorProps.Primary}/>}
          label="Default"
        />
      }
      <Button
        variant={ButtonVariantProps.Icon}
        onClick={() => onRemove(value.id)}
      >
        <CloseRounded fontSize="small" color="error" />
      </Button>
    </Stack>
  );
};
