import React, { useEffect, useState } from 'react';
import { DropdownItem, SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { TextField } from '../../../../../components/atoms/TextField';
import { useGetServiceBillingTypes } from '../../../../../queries/service-query';
import { useGetAllOutcomeReasons } from '../../../../../queries/outcome-reason-query';
import { useGetPartConditions } from '../../../../../queries/part-query';
import { ParameterNoUIDataFormParameterType, ParameterNoUIDataFormSLAOption, ParameterNoUIDataFormPartAttribute } from '../../../../../@types';
import { ParameterNoUIFormBuilderFieldFragmentProps } from './ParameterNoUIFormBuilderFieldFragment.props';
import { StyledStack } from './ParameterNoUIFormBuilderFieldFragment.styles';
import {
  PARAMETER_NO_UI_FORM_TYPE_PARAMETER_OPTIONS,
  PARAMETER_NO_UI_FORM_SLA_OPTIONS,
  PARAMETER_NO_UI_FORM_SLA_TYPES,
  PARAMETER_NO_UI_FORM_PART_ATTRIBUTES,
  PARAMETER_NO_UI_FORM_PART_MOVEMENT_TYPES
} from '../../../../../constants/form-builder';
import { getSLAOutcomeReasonName } from '../../../../../utils/form-builder';

export const ParameterNoUIFormBuilderFieldFragment: React.FC<ParameterNoUIFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const [billableTypes, setBillableTypes] = useState<Array<DropdownItem>>([]);
  const [outcomeReasons, setOutcomeReasons] = useState<Array<DropdownItem>>([]);
  const [partConditions, setPartConditions] = useState<Array<DropdownItem>>([]);
  
  const getServiceBillingTypes = useGetServiceBillingTypes();
  const getAllOutcomeReasons = useGetAllOutcomeReasons();
  const getPartConditions = useGetPartConditions();

  useEffect(() => {
    if (getServiceBillingTypes.data && preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.Billable) {
      const billableTypes: Array<DropdownItem> = getServiceBillingTypes.data?.map((billingType) => ({
        label: billingType.name,
        value: billingType.code
      }));
      setBillableTypes(billableTypes);
    }
  }, [getServiceBillingTypes.data, preSelectedData?.parameterType]);

  useEffect(() => {
    if (getAllOutcomeReasons.data && preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.SLA) {
      const outcomeReasons: Array<DropdownItem> = getAllOutcomeReasons.data?.filter((reason) => 
        reason.isActive && reason.outcomeTypeCode === preSelectedData?.slaData.option).map((reason) => ({
        label: reason.name,
        value: reason.id.toString()
      }));
      setOutcomeReasons(outcomeReasons);
    }
  }, [getAllOutcomeReasons.data, preSelectedData?.slaData.option]);

  useEffect(() => {
    if (getPartConditions.data && preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.Part) {
      setPartConditions(getPartConditions.data);
    }
  }, [getPartConditions.data, preSelectedData?.partData.attribute]);

  return (
    <StyledStack spacing={2}>
      <SelectMenu
        id="parameter-noUI-form-builder-field-fragment-parameter-type"
        label="Parameter Type"
        items={PARAMETER_NO_UI_FORM_TYPE_PARAMETER_OPTIONS}
        selectedValue={preSelectedData?.parameterType}
        onChange={(paramType) => onPreSelectedData({ 
          parameterType: paramType, 
          billableType: '',
          slaData: '' ,
          partData: ''
        })}
        validate={showErrors && !preSelectedData?.parameterType}
      />
      {preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.Billable &&
        <SelectMenu
          id="parameter-noUI-form-builder-field-fragment-billable-type"
          label="Billable Type"
          items={billableTypes}
          selectedValue={preSelectedData?.billableType}
          onChange={(billType) => onPreSelectedData({
            parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.Billable,
            billableType: billType,
            slaData: '',
            partData: ''
          })}
          validate={showErrors && !preSelectedData?.billableType}
        />
      }
      {preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.SLA &&
      <>
        <SelectMenu
          id="parameter-noUI-form-builder-field-fragment-sla-option"
          label="SLA Option"
          items={PARAMETER_NO_UI_FORM_SLA_OPTIONS}
          selectedValue={preSelectedData?.slaData.option}
          onChange={(slaOption) => onPreSelectedData({
            parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.SLA,
            billableType: '',
            slaData:{
              option: slaOption,
              reason: '',
              reasonName: '',
              notes: ''
            },
            partData: ''
          })}
          validate={showErrors && !preSelectedData?.slaData.option}
        />
        {preSelectedData?.slaData.option === ParameterNoUIDataFormSLAOption.Met.toLowerCase() &&
            <SelectMenu
              id="parameter-noUI-form-builder-field-fragment-sla-type"
              label="SLA Type"
              items={PARAMETER_NO_UI_FORM_SLA_TYPES}
              selectedValue={preSelectedData?.slaData.type}
              onChange={(slaType) => onPreSelectedData({
                parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.SLA,
                billableType: '',
                slaData:{
                  option: preSelectedData?.slaData.option || ParameterNoUIDataFormSLAOption.Met.toLowerCase(),
                  type: slaType
                },
                partData: ''
              })}
              validate={showErrors && !preSelectedData?.slaData.type}
            />
        }
        {(preSelectedData?.slaData.option === ParameterNoUIDataFormSLAOption.Reviewed.toLowerCase() ||
          preSelectedData?.slaData.option === ParameterNoUIDataFormSLAOption.Mitigated.toLowerCase()) &&
          <>
            <SelectMenu
              id="parameter-noUI-form-builder-field-fragment-sla-reason"
              label="Reason"
              items={outcomeReasons}
              selectedValue={preSelectedData?.slaData.reason}
              onChange={(outcomeReason) => onPreSelectedData({
                parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.SLA,
                billableType: '',
                slaData: {
                  option: preSelectedData?.slaData.option,
                  reason: outcomeReason,
                  reasonName: getAllOutcomeReasons.data && getSLAOutcomeReasonName(getAllOutcomeReasons.data,outcomeReason),
                  notes: preSelectedData?.slaData.notes
                },
                partData: ''
              })}
              validate={showErrors && !preSelectedData?.slaData.reason}
            />
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={5}
              value={preSelectedData?.slaData.notes}
              onChange={(note) => onPreSelectedData({
                parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.SLA,
                billableType: '',
                slaData: {
                  option: preSelectedData?.slaData.option,
                  reason: preSelectedData?.slaData.reason,
                  reasonName: preSelectedData?.slaData.reasonName,
                  notes: note
                },
                partData: ''
              })}
              error={showErrors && !preSelectedData?.slaData.notes}
            />
          </>
        }
      </>
      }
      {preSelectedData?.parameterType === ParameterNoUIDataFormParameterType.Part &&
       <>
         <SelectMenu
           id="parameter-noUI-form-builder-field-fragment-part-attribute"
           label="Attribute"
           items={PARAMETER_NO_UI_FORM_PART_ATTRIBUTES}
           selectedValue={preSelectedData?.partData.attribute}
           onChange={(partAttribute) => onPreSelectedData({
             parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.Part,
             billableType: '',
             slaData: '',
             partData: {
               attribute: partAttribute,
               condition: '',
               fleetTag: '',
               movementType: ''
             }
           })}
           validate={showErrors && !preSelectedData?.partData.attribute}
         />
         {preSelectedData?.partData.attribute === ParameterNoUIDataFormPartAttribute.Condition &&
           <SelectMenu
             id="parameter-noUI-form-builder-field-fragment-part-condition"
             label="Condition"
             items={partConditions}
             selectedValue={preSelectedData?.partData.condition}
             onChange={(partCondition) => onPreSelectedData({
               parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.Part,
               billableType: '',
               slaData: '',
               partData: {
                 attribute: preSelectedData?.partData.attribute || ParameterNoUIDataFormPartAttribute.Condition,
                 condition: partCondition,
                 fleetTag: '',
                 movementType: preSelectedData?.partData.movementType
               }
             })}
             validate={showErrors && !preSelectedData?.partData.condition}
           />
         }
         {preSelectedData?.partData.attribute === ParameterNoUIDataFormPartAttribute.FleetTag &&
           <TextField
             fullWidth
             label="Fleet Tag name"
             value={preSelectedData?.partData.fleetTag}
             onChange={(text) => onPreSelectedData({
               parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.Part,
               billableType: '',
               slaData: '',
               partData: {
                 attribute: preSelectedData?.partData.attribute || ParameterNoUIDataFormPartAttribute.FleetTag,
                 condition: '',
                 fleetTag: text,
                 movementType: preSelectedData?.partData.movementType
               }
             })}
             error={showErrors && !preSelectedData?.partData.fleetTag}
           />
         }
         {(preSelectedData?.partData.attribute === ParameterNoUIDataFormPartAttribute.Condition ||
          preSelectedData?.partData.attribute === ParameterNoUIDataFormPartAttribute.FleetTag) &&
           <SelectMenu
             id="parameter-noUI-form-builder-field-fragment-part-movement-type"
             label="Preferred Movement Type"
             items={PARAMETER_NO_UI_FORM_PART_MOVEMENT_TYPES}
             selectedValue={preSelectedData?.partData.movementType}
             onChange={(movementType) => onPreSelectedData({
               parameterType: preSelectedData.parameterType || ParameterNoUIDataFormParameterType.Part,
               billableType: '',
               slaData: '',
               partData: {
                 attribute: preSelectedData?.partData.attribute,
                 condition: preSelectedData?.partData.condition,
                 fleetTag: preSelectedData?.partData.fleetTag,
                 movementType: movementType
               }
             })}
             validate={showErrors && !preSelectedData?.partData.movementType}
           />
         }
       </>
      }
    </StyledStack>
  );
};