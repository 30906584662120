import React from 'react';
import { Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { TextField } from '../../atoms/TextField';
import { SelectMenu } from '../../atoms/SelectMenu';
import { 
  FORM_BUILDER_CONDITION_OPTIONS, 
  FORM_BUILDER_COMPARE_OPTIONS, 
  FORM_BUILDER_JOIN_OPTIONS 
} from '../../../constants/form-builder';
import { FormBuilderConditionProps } from './FormBuilderCondition.props';
import { StyledGrid } from './FormBuilderCondition.styles';

export const FormBuilderCondition: React.FC<FormBuilderConditionProps> = ({
  value,
  onChange,
  onRemove
}) => {
  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={4}>
        <SelectMenu
          label="Condition" 
          id={`condition-select-menu-${value.id}`}
          items={FORM_BUILDER_CONDITION_OPTIONS} 
          selectedValue={value.conditionOption}
          onChange={(val) => onChange({ ...value, conditionOption: val })}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectMenu
          label="Compare Option" 
          id={`compare-option-select-menu-${value.id}`}
          items={FORM_BUILDER_COMPARE_OPTIONS} 
          selectedValue={value.compareOption}
          onChange={(val) => onChange({ ...value, compareOption: val })}
        />
      </Grid>
      <Grid item xs={5}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" marginRight={1}>
          <TextField
            label="Value"
            value={value.value}
            onChange={(val) => onChange({ ...value, value: val })}
          />
          <Button
            variant={ButtonVariantProps.Icon}
            onClick={() => onRemove(value.id)}
          >
            <CloseRounded fontSize="small" color="error" />
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={2} paddingBottom={2}>
        <SelectMenu
          label="Join Option" 
          id={`join-option-select-menu-${value.id}`}
          items={FORM_BUILDER_JOIN_OPTIONS} 
          selectedValue={value.joinOption}
          onChange={(val) => onChange({ ...value, joinOption: val })}
        />
      </Grid>
      <Grid item xs={10} paddingBottom={2}></Grid>
    </StyledGrid>
  );
};
