import React from 'react';
import { Table } from '@mui/material';
import { SiteSchemaTableProps } from '.';
import { SiteSchemaRow } from '../../molecules/SiteSchemaRow';

export const SiteSchemaTable: React.FC<SiteSchemaTableProps> = ({
  hasEditPermissions,
  siteSchemaTableData,
  onEditClick
}) => {
  return (
    <Table size="small">
      {siteSchemaTableData.map((siteSchemaRowData, index) => 
        <SiteSchemaRow key={index} siteSchemaRowData={siteSchemaRowData} onEditClick={onEditClick} hasEditPermissions={hasEditPermissions}/>
      )}
    </Table>
  );
};