import React, { ChangeEvent, useEffect, useState } from 'react';
import { GridColDef, GridDensityTypes, GridFeatureModeConstant, GridRenderCellParams, GridRowClassNameParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import { Switch } from '../../../../components/atoms/Switch';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../../components/atoms/FormControlLabel';
import { ColorProps, DatagridSortingTypes } from '../../../../@types';
import { StyledTableWrapper } from './PartAccordianFragment.styles';
import { DataGrid } from '../../../../components/atoms/DataGrid';
import { Link } from '../../../../components/atoms/Link';
import { PartAccordianFragmentProps, StructurePartListType } from './PartsAccordianFragment.props';
import { formatDate } from '../../../../utils/common';
import { useGetAllPartsForStructures } from '../../../../queries/structure-query';

export const PartAccordianFragment: React.FC<PartAccordianFragmentProps> = ({
  nodeId
}) => {
  const [enableChildLocationParts, setEnableChildLocationParts] = useState<boolean>(false);
  const [partsData, setPartsData] = useState<StructurePartListType>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const getAllPartsForStructures = useGetAllPartsForStructures({
    currentPage: page, 
    limit: pageSize,
    total: 0
  }, nodeId, enableChildLocationParts);

  useEffect(() => {
    getAllPartsForStructures.data && setPartsData(getAllPartsForStructures.data);
  }, [getAllPartsForStructures.data]);

  const columns: GridColDef[] = [
    {
      field: 'serial1',
      headerName: 'Serial #1',
      minWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => params.row.id ? <Link href={`/parts/${params.row.id}`}>{params.row.serial1}</Link> : params.row.serial1,
    },
    {
      field: 'partTypeName',
      headerName: 'Part Type Name',
      minWidth: 220,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => params.row.partTypeId ? <Link href={`/configure/partTypes/${params.row.partTypeId}`}>{params.row.partTypeName}</Link> : params.row.partTypeName,
    },
    {
      field: 'partTypeCategory',
      headerName: 'Part Type Category',
      minWidth: 200,
    },
    {
      field: 'partConditionName',
      headerName: 'Condition',
      minWidth: 200,
    },
    {
      field: 'softwareName',
      headerName: 'Software',
      minWidth: 150,
    },
    {
      field: 'firmwareName',
      headerName: 'Firmware',
      minWidth: 150,
    },
    {
      field: 'hardwareName',
      headerName: 'Hardware',
      minWidth: 150,
    },
    {
      field: 'fleetTagName',
      headerName: 'Fleet Tag',
      minWidth: 150,
    },
    {
      field: 'qty',
      headerName: 'Qty',
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => params.row.qty > 0 && params.row.qty,
    },
    {
      field: 'lastUpdatedAt',
      headerName: 'Last Updated At',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt)
    }
  ];

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setEnableChildLocationParts(event.target.checked);
  };

  return (
    <Accordian title={
      <Grid container alignItems="center">
        <Grid item spacing={1} container display="flex">
          <Grid item>
            <Typography>Parts</Typography>
          </Grid>
          <Grid item marginLeft="auto" mr={1}>
            <FormControlLabel
              control={
                <Switch
                  color={ColorProps.Success}
                  checked={enableChildLocationParts}
                  onChange={handleToggleChange}
                  onClick={(e) => e.stopPropagation()} />
              }
              label="Include Child Records"
              labelPlacement={FormControlLabelPlacementProps.Start}
            />
          </Grid>
        </Grid>
      </Grid>
    }>
      <StyledTableWrapper>
        <DataGrid
          density={GridDensityTypes.Standard}
          columns={columns}
          rows={partsData?.data || []}
          getRowId={(row) => row?.index}
          rowCount={partsData?.total}
          loading={getAllPartsForStructures.isLoading}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          rowsPerPageOptions={[15]}
          pagination={true}
          page={page}
          pageSize={pageSize}
          paginationMode={GridFeatureModeConstant.server}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastUpdatedAt', sort: DatagridSortingTypes.Descending }],
            },
          }}
          getRowClassName={(params: GridRowClassNameParams) => `super-app-theme--${params.row.index}`}
          autoHeight={true}
        />
      </StyledTableWrapper>
    </Accordian>
  );
};