import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ContentWrapperBox, Footer, HeadingWrapper, StyledButton } from './NotificationRuleConfigModelFragment.styles';
import { NotificationRuleConfigModelFragmentProps } from './NotificationRuleConfigModelFragment.props';
import { TextField } from '../../../../components/atoms/TextField';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { TypographyVariantProps } from '../../../../components/atoms/Typography';
import { ButtonVariantProps } from '../../../../components/atoms/Button';
import { MultiSelectCheckmarksMenu } from '../../../../components/atoms/MultiSelectCheckmarksMenu';
import { ContractMultiSelector } from '../../../../components/molecules/ContractMultiSelector';
import { Checkbox, CheckboxColorProps } from '../../../../components/atoms/Checkbox';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { Modal } from '../../../../components/atoms/Modal';
import { NotificationConfigType, StructureTypeCategory, NotificationMessageType } from '../../../../configs/enums';
import { NodeType, PrioritySelectionObjectType } from '../../../../@types';
import { NotificationConfigObject, NotificationRuleListObject } from '../../../../@types/notification.type';
import { isDropDownEmpty, isEmptyString } from '../../../../utils/common';
import { getClientHierarchy } from '../../../../utils/clientContractHierarchy';
import { prioritiesWithAnyWildcard, isInputFieldEmpty } from '../../../../utils/services';
import { getDropdownItemsWithAnyWildCard } from '../../../../utils/array-utils';
import { getNotifcationTemplatesList, getAllServiceTypesStringArray } from '../../../../utils/notification';
import { useGetNodeChildrenByNodeType, useGetPrioritySelection, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../../queries/structure-query';
import { useCreateNotificationConfig, useUpdateNotificationConfig } from '../../../../queries/notification-config-query';
import { ANY_ITEM_ARRAY, ANY_KEYWORD } from '../../../../constants/common';
import { NOTIFICATION_EVENTS, NOTIFICATION_MESSAGE_TYPES, NOTIFICATION_CONTACT_RECORD_TYPES, SAMPLE_CONFIG_OBJECT, SAMPLE_PRIORITY_OBJECT } from '../../../../constants/notification.constant';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';

export const NotificationRulesConfigModelFragment: React.FC<NotificationRuleConfigModelFragmentProps> = ({
  open,
  closeModel,
  modelData,
  data
}) => {
  const anyItem = ANY_ITEM_ARRAY;

  const [openModel, setOpenModel] = useState(false);
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [clients, setClients] = useState<DropdownItem[]>(anyItem);
  const [contracts, setContracts] = useState<DropdownItem[]>(anyItem);
  const [availablePriorityList, setAvailablePriorityList] = useState<DropdownItem[]>([]);
  const [prioritySelectionObject, setPrioritySelectionObject] = useState<PrioritySelectionObjectType>(SAMPLE_PRIORITY_OBJECT);
  const [notificationConfigData, setNotificationConfigData] = useState<NotificationConfigObject>(SAMPLE_CONFIG_OBJECT);
  const [notificationRuleListData, setNotificationRuleListData] = useState<NotificationRuleListObject>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [validate, setValidate] = useState(false);

  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const getPrioritySelection = useGetPrioritySelection();
  const createNotificationConfig = useCreateNotificationConfig(notificationConfigData.category);
  const updateNotificationConfig = useUpdateNotificationConfig(notificationConfigData.category);

  useEffect(() => {
    if (open) {
      handleModelOpen();
    }
  }, [open]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      setClients(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive })));
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType && selectedParentId !== ANY_KEYWORD) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType == NodeType.Contract) {
        setContracts(anyItem.concat(getNodeChildrenByNodeType.data.map((item: any) => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    const clientList = prioritySelectionObject?.client.filter(value => value !== '');
    const contractList = prioritySelectionObject?.contract.filter(value => value !== '');
    const serviceTypesList = prioritySelectionObject?.serviceTypes.filter(value => value !== '');
    
    if (prioritySelectionObject?.client && prioritySelectionObject?.contract && prioritySelectionObject?.serviceTypes &&
      clientList.length && contractList.length && serviceTypesList.length) {
      getPriorities(prioritySelectionObject);
    }
  }, [prioritySelectionObject]);

  const handleModelOpen = () => {
    if (data) {
      setSelectedParentId(data.client);
      setQueryNodeType(NodeType.Contract);
      getNodeChildrenByNodeType.refetch();
      if (data.category === NotificationConfigType.Service && data.serviceType) {
        onChangePriorities({ 
          serviceTypes: data.serviceType[0] !== ANY_KEYWORD ? data.serviceType : getAllServiceTypesStringArray(modelData?.serviceActionProcessTypes?.serviceTypes),
          client: [data.client],
          contract: data.contract,
          brand: [ANY_KEYWORD]
        });
      }
      setNotificationConfigData(data);
    }
    setOpenModel(true);
  }; 

  const handleClose = () => {
    setOpenModel(false);
    setNotificationRuleListData({});
    setNotificationConfigData(SAMPLE_CONFIG_OBJECT);
    setPrioritySelectionObject(SAMPLE_PRIORITY_OBJECT);
    setAvailablePriorityList([]);
    setContracts(anyItem);
    setSelectedParentId('');
    setQueryNodeType(NodeType.Client);
    closeModel();
    setValidate(false);
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };

  const onChangePriorities = (prioritySelectionValue: PrioritySelectionObjectType) => {
    setPrioritySelectionObject(prioritySelectionValue);
  };

  const getPriorities = async (value?: object) => {
    const priorities: any = await getPrioritySelection.mutateAsync(value);
    setAvailablePriorityList(priorities ? priorities : []);
  };

  const isEmptyArray = (value: any) => {
    if (value?.filter((item: any )=> item !== '').length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const clientContractValidator = (client: string, contract: string[]) => {
    if (isInputFieldEmpty(client) || contract.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onSave = async() => {
    const isEmptyName = isEmptyString(notificationConfigData?.name);
    const isEmptyClientContract = isEmptyString(notificationConfigData?.client) || isEmptyArray(notificationConfigData?.contract);
    const isEmptyCategory = isDropDownEmpty(notificationConfigData?.category);
    const isEmptyServiceType = isEmptyArray(notificationConfigData?.serviceType) || !notificationConfigData?.serviceType;
    const isEmptyActionType = isEmptyArray(notificationConfigData?.actionType) || !notificationConfigData?.actionType;
    const isEmptyProcessType = isEmptyArray(notificationConfigData?.processType) || !notificationConfigData?.processType;
    const isEmptyPriority = isEmptyArray(notificationConfigData?.priority) || !notificationConfigData?.priority;
    const isEmptyRegion = isEmptyArray(notificationConfigData?.region) || !notificationConfigData?.region;
    const isEmptyStatus = isEmptyArray(notificationConfigData?.status) || !notificationConfigData?.status;
    const isEmptyShipmentType = isEmptyArray(notificationConfigData?.shipmentType) || !notificationConfigData?.shipmentType;
    const isEmptyCourier = isEmptyArray(notificationConfigData?.courier) || !notificationConfigData?.courier;
    const isEmptyShipmentStatus= isEmptyArray(notificationConfigData?.shipmentStatus) || !notificationConfigData?.shipmentStatus;
    const isEmptyEvent = isEmptyArray(notificationConfigData?.event);
    const isEmptyMessageType = isDropDownEmpty(notificationConfigData?.messageType);
    const isEmptyContactRecordType = isDropDownEmpty(notificationConfigData?.contactRecordType);
    const isEmptyTemplate = isDropDownEmpty(notificationConfigData?.template.toString());
    
    setNotificationRuleListData({ 
      isNameError: !!isEmptyName,
      isClientContractError: !!isEmptyClientContract,
      isServiceTypeError: !!isEmptyServiceType,
      isActionTypeError: !!isEmptyActionType,
      isProcessTypeError: !!isEmptyProcessType,
      isPriorityError: !!isEmptyPriority,
      isRegionError: !!isEmptyRegion,
      isStatusError: !!isEmptyStatus,
      isShipmentTypeError: !!isEmptyShipmentType,
      isCourierError: !!isEmptyCourier,
      isShipmentStatusError: !!isEmptyShipmentStatus,
      isEventError: !!isEmptyEvent,
    });

    if (isEmptyName || isEmptyClientContract || isEmptyCategory || isEmptyMessageType || isEmptyTemplate || isEmptyContactRecordType) {
      setValidate(true);
      return;
    }

    if (notificationConfigData?.category === NotificationConfigType.Service && (isEmptyServiceType || isEmptyActionType || isEmptyProcessType || isEmptyPriority || isEmptyRegion || isEmptyStatus)) {
      return;
    }

    if (notificationConfigData?.category === NotificationConfigType.Shipment && (isEmptyShipmentType || isEmptyCourier || isEmptyShipmentStatus)) {
      return;
    }
    
    if (notificationConfigData?.id === 0) {
      await createNotificationConfig.mutateAsync(notificationConfigData);
      setOpenToast(true);
      handleClose();
    } else {
      await updateNotificationConfig.mutateAsync(notificationConfigData);
      setOpenToast(true);
      handleClose();
    }
  };
  
  const notificationTypes: Array<DropdownItem> = [
    { value: NotificationConfigType.Service, label: NotificationConfigType.Service },
    { value: NotificationConfigType.Shipment, label: NotificationConfigType.Shipment }
  ];

  return (
    <>
      <Modal
        open={openModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ContentWrapperBox>
          <CloseIcon handleClose={handleClose} />
          <HeadingWrapper variant={TypographyVariantProps.H6}>
            Notification Config #{`${notificationConfigData?.id === 0 ? 'New' : notificationConfigData?.id}`}
          </HeadingWrapper>
          <Grid container>
            <Grid item mt={2} ml={2} mr={2} xs={12} md={12}>
              <TextField
                label="Name *"
                fullWidth
                error={!!notificationRuleListData?.isNameError}
                value={notificationConfigData?.name}
                onChange={(value) => {
                  setNotificationConfigData({ ...notificationConfigData, name: value });
                  setNotificationRuleListData({ ...notificationRuleListData, isNameError: isEmptyString(value) });
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item mt={2} ml={2} mr={2} xs={12} md={10}>
              <SelectionButton
                label="Client/Contract *"
                error={!!notificationRuleListData?.isClientContractError}
                value={notificationConfigData?.clientContractHierarchy || ''}
                onClick={() => {
                  setOpenParentSelector(true);
                  getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item mt={2} ml={2} mr={2} xs={12} md={6}>
              <SelectMenu
                id="category-select"
                label="Category *"
                required={true}
                disabled={notificationConfigData?.id !== 0 }
                items={notificationTypes}
                selectedValue={notificationConfigData?.category || '-1'}
                onChange={(value) => {
                  setNotificationConfigData({
                    id: notificationConfigData?.id || 0,
                    name: notificationConfigData?.name,
                    client: notificationConfigData?.client || '',
                    contract: notificationConfigData?.contract || [],
                    clientContractHierarchy: notificationConfigData?.clientContractHierarchy,
                    category: value,
                    event: [], 
                    messageType: '-1',
                    contactRecordType: '-1',
                    phoneNumbers: '',
                    emailAddresses: '',
                    considerTradingHours: false,
                    addServiceLog: true,
                    template: -1,
                  });
                  setNotificationRuleListData({ 
                    isNameError: notificationRuleListData?.isNameError,
                    isClientContractError: notificationRuleListData?.isClientContractError,
                  });
                }}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
          </Grid>

          {notificationConfigData?.category === NotificationConfigType.Service && (
            <>
              <Grid container>
                <Grid item xs={12} md={3.7} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Service Type *"
                    id="service-type-options"
                    error={!!notificationRuleListData?.isServiceTypeError}
                    selectedValue={notificationConfigData?.serviceType}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, serviceType: value ? value.split(',') : [], priority: [] });
                      onChangePriorities({ ...prioritySelectionObject, serviceTypes: value ? value.split(',') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isServiceTypeError: isEmptyArray(value ? value.split(',') : []) });
                    }} 
                    items={getDropdownItemsWithAnyWildCard(modelData?.serviceActionProcessTypes?.serviceTypes, notificationConfigData?.serviceType)}
                  />
                </Grid>
                <Grid item xs={12} md={3.7} mr={2} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Action Type *"
                    id="action-type-options"
                    error={!!notificationRuleListData?.isActionTypeError}
                    selectedValue={notificationConfigData?.actionType}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, actionType: value ? value.split(',') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isActionTypeError: isEmptyArray(value ? value.split(',') : []) });
                    }}
                    items={getDropdownItemsWithAnyWildCard(modelData?.serviceActionProcessTypes?.actionTypes, notificationConfigData?.actionType)}
                  />
                </Grid>
                <Grid item xs={12} md={3.7} mt={2}>
                  <MultiSelectCheckmarksMenu
                    label="Process Type *"
                    id="process-type-options"
                    error={!!notificationRuleListData?.isProcessTypeError}
                    selectedValue={notificationConfigData?.processType}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, processType: value ? value.split(',') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isProcessTypeError: isEmptyArray(value ? value.split(',') : []) });
                    }}
                    items={getDropdownItemsWithAnyWildCard(modelData?.serviceActionProcessTypes?.processTypes, notificationConfigData?.processType)} 
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={3.7} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Priority *"
                    id="priority-select-options"
                    error={!!notificationRuleListData?.isPriorityError}
                    selectedValue={notificationConfigData?.priority}
                    onChange={(value) => {                      
                      setNotificationConfigData({
                        ...notificationConfigData,
                        priority: value === '' ? [] : value.split(',')
                      });
                      setNotificationRuleListData({ ...notificationRuleListData, isPriorityError: isEmptyArray(value ? value.split(',') : []) });
                    }}
                    items={prioritiesWithAnyWildcard(availablePriorityList, notificationConfigData?.priority)}
                  />
                </Grid>
                <Grid item xs={12} md={3.7} mr={2} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Region *"
                    id="region-select"
                    error={!!notificationRuleListData?.isRegionError}
                    selectedValue={notificationConfigData?.region}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, region: value ? value.split(',') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isRegionError: isEmptyArray(value ? value.split(',') : []) });
                    }}
                    items={getDropdownItemsWithAnyWildCard(modelData?.regionTypes, notificationConfigData?.region)} 
                  />
                </Grid>
                <Grid item xs={12} md={3.7} mt={2}>
                  <MultiSelectCheckmarksMenu
                    label="Status *"
                    id="status-select"
                    error={!!notificationRuleListData?.isStatusError}
                    selectedValue={notificationConfigData?.status}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, status: value ? value.split(',') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isStatusError: isEmptyArray(value ? value.split(',') : []) });
                    }}
                    items={getDropdownItemsWithAnyWildCard(modelData?.serviceStatuses, notificationConfigData?.status)} 
                  />
                </Grid>
              </Grid>
            </>
          )}

          {notificationConfigData?.category === NotificationConfigType.Shipment && (
            <Grid container>
              <Grid item xs={12} md={3.7} mt={2} ml={2}>
                <MultiSelectCheckmarksMenu
                  label="Shipment Type *"
                  id="shipment-type-options"
                  error={!!notificationRuleListData?.isShipmentTypeError}
                  selectedValue={notificationConfigData?.shipmentType}
                  onChange={(value) => {
                    setNotificationConfigData({ ...notificationConfigData, shipmentType: value ? value.split(',') : [], priority: [] });
                    setNotificationRuleListData({ ...notificationRuleListData, isShipmentTypeError: isEmptyArray(value ? value.split(',') : []) });
                  }} 
                  items={getDropdownItemsWithAnyWildCard(modelData?.shipmentTypes, notificationConfigData?.shipmentType)}
                />
              </Grid>
              <Grid item xs={12} md={3.7} mt={2} mr={2} ml={2}>
                <MultiSelectCheckmarksMenu
                  label="Courier *"
                  id="courier-options"
                  error={!!notificationRuleListData?.isCourierError}
                  selectedValue={notificationConfigData?.courier}
                  onChange={(value) => {
                    setNotificationConfigData({ ...notificationConfigData, courier: value ? value.split(',') : [] });
                    setNotificationRuleListData({ ...notificationRuleListData, isCourierError: isEmptyArray(value ? value.split(',') : []) });
                  }}
                  items={getDropdownItemsWithAnyWildCard(modelData?.couriers, notificationConfigData?.courier)}
                />
              </Grid>
              <Grid item xs={12} md={3.7} mt={2}>
                <MultiSelectCheckmarksMenu
                  label="Shipment Status *"
                  id="shipment-status-options"
                  error={!!notificationRuleListData?.isShipmentStatusError}
                  selectedValue={notificationConfigData?.shipmentStatus}
                  onChange={(value) => {
                    setNotificationConfigData({ ...notificationConfigData, shipmentStatus: value ? value.split(',') : [] });
                    setNotificationRuleListData({ ...notificationRuleListData, isShipmentStatusError: isEmptyArray(value ? value.split(',') : []) });
                  }}
                  items={getDropdownItemsWithAnyWildCard(modelData?.shipmentStatuses, notificationConfigData?.shipmentStatus)} 
                />
              </Grid>
            </Grid>
          )}

          {notificationConfigData?.category !== '-1' && (
            <>
              <Grid container>
                <Grid item xs={12} md={6} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Event *"
                    id="event-select"
                    error={!!notificationRuleListData?.isEventError}
                    selectedValue={notificationConfigData?.event}
                    onChange={(value) => {
                      setNotificationConfigData({ ...notificationConfigData, event: value ? value.split(',').filter(item => item !== '') : [] });
                      setNotificationRuleListData({ ...notificationRuleListData, isEventError: isEmptyArray(value ? value.split(',') : []) });
                    } }
                    items={
                      notificationConfigData?.event?.some((event: string) => event === ANY_KEYWORD) ?
                        NOTIFICATION_EVENTS.filter((event: any) => event.value === ANY_KEYWORD) :
                        NOTIFICATION_EVENTS
                    } />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={3.5} mt={2} ml={2}>
                  <SelectMenu
                    id="message-type-select"
                    label="Message Type *"
                    required={true}
                    items={NOTIFICATION_MESSAGE_TYPES}
                    selectedValue={notificationConfigData?.messageType || '-1'}
                    onChange={(value) => {
                      setNotificationConfigData({
                        ...notificationConfigData,
                        messageType: value,
                        template: -1,
                        phoneNumbers: '',
                        emailAddresses: ''
                      });
                    }}
                    validate={validate}
                    optionalLabelEnabled={true}
                    helperTextEnabled={false}
                  />
                </Grid>
                <Grid item xs={12} md={7.8} mt={2} ml={2}>
                  <TextField
                    fullWidth
                    disabled={notificationConfigData.messageType === '-1'}
                    label={notificationConfigData?.messageType === NotificationMessageType.SMS ? 'Phone Numbers' : notificationConfigData?.messageType === NotificationMessageType.Email ? 'Email Addresses' : 'Select Message Type first'}
                    value={notificationConfigData?.messageType === NotificationMessageType.SMS ? notificationConfigData?.phoneNumbers : notificationConfigData?.emailAddresses}
                    onChange={(value) => {
                      setNotificationConfigData({ 
                        ...notificationConfigData, 
                        [notificationConfigData?.messageType === NotificationMessageType.SMS ? 'phoneNumbers' : 'emailAddresses']: value
                      });
                      setNotificationRuleListData({
                        ...notificationRuleListData,
                        [notificationConfigData?.messageType === NotificationMessageType.SMS ? 'isPhoneNumbersError' : 'isEmailAddressesError']: isEmptyString(value)
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={4} mt={2} ml={2}>
                  <SelectMenu
                    id="notification-template-select"
                    label="Notification Template *"
                    required={true}
                    disabled={!notificationConfigData.messageType}
                    items={getNotifcationTemplatesList(modelData?.notificationTemplates?.data, notificationConfigData.messageType)}
                    selectedValue={notificationConfigData?.template || -1}
                    onChange={(value) => {
                      setNotificationConfigData({
                        ...notificationConfigData,
                        template: value
                      });
                    }}
                    validate={validate}
                    optionalLabelEnabled={true}
                    helperTextEnabled={false}
                  />
                </Grid>
                <Grid item xs={12} md={7.3} mt={2} ml={2}>
                  <SelectMenu
                    id="contact-record-type-select"
                    label="Contact Record Type *"
                    required={true}
                    items={NOTIFICATION_CONTACT_RECORD_TYPES}
                    selectedValue={notificationConfigData?.contactRecordType || '-1'}
                    onChange={(value) => {
                      setNotificationConfigData({
                        ...notificationConfigData,
                        contactRecordType: value
                      });
                    }}
                    validate={validate}
                    optionalLabelEnabled={true}
                    helperTextEnabled={false}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={5} mt={2} ml={10}>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={notificationConfigData?.considerTradingHours}
                    onChange={(event) => {
                      setNotificationConfigData({ ...notificationConfigData, considerTradingHours: event.target.checked });
                    }}
                  /> Consider Trading Hours
                </Grid>
                <Grid item xs={12} md={5} mt={2}>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={notificationConfigData?.addServiceLog}
                    onChange={(event) => {
                      setNotificationConfigData({ ...notificationConfigData, addServiceLog: event.target.checked });
                    }}
                  /> Add Service Log
                </Grid>
              </Grid>
            </>
          )}

          <Grid container mb={2}>
            <Footer>
              <StyledButton variant={ButtonVariantProps.Secondary} onClick={handleClose}>Cancel</StyledButton>
              <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>Save</StyledButton>
            </Footer>
          </Grid>

          <ContractMultiSelector
            open={openParentSelector}
            client={notificationConfigData?.client}
            contract={notificationConfigData?.contract}
            clients={clients}
            contracts={contracts}
            onChange={(clientVal: string, contractVal: Array<string>) => {
              notificationConfigData?.contract.splice(0, notificationConfigData?.contract.length);
              setNotificationConfigData({ 
                ...notificationConfigData,
                client: clientVal,
                contract: contractVal.filter(value => value !== '')
              });
              onChangeNodeType(NodeType.Contract, clientVal);
            }}
            onSelect={() => {          
              if (notificationConfigData?.client && notificationConfigData?.contract.length > 0) {
                setNotificationConfigData({ 
                  ...notificationConfigData,
                  clientContractHierarchy: getClientHierarchy(clients, contracts, notificationConfigData?.client, notificationConfigData?.contract)
                });
                onChangePriorities({ 
                  serviceTypes: prioritySelectionObject?.serviceTypes,
                  client: [notificationConfigData?.client],
                  contract: notificationConfigData?.contract,
                  brand: [ANY_KEYWORD]
                });
                setNotificationRuleListData({
                  ...notificationRuleListData,
                  isClientContractError: clientContractValidator(notificationConfigData?.client, notificationConfigData?.contract)
                });
              }
              setOpenParentSelector(false);
            }}
            onClose={() => {
              setSelectedParentId('');
              setOpenParentSelector(false);
            }}
          />

        </ContentWrapperBox>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => { setOpenToast(false); }}
      />
    </>
  );
};