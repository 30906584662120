import React from 'react';
import { Grid, IconButton, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { SoftwareFirmwareHardware } from '../../molecules/SoftwareFirmwareHardware';
import { StyledGridContainer, StyledTableCell } from './SFHCommonPanel.styles';
import { SoftwareFirmwareHardwareData } from '../../../@types/partType.type';
import { SFHCommonPanelProps } from './SFHCommonPanel.props';
import { Typography } from '../../atoms/Typography';

export const SFHCommonPanel: React.FC<SFHCommonPanelProps> = ({
  header,
  sfhData,
  sfhFormData,
  addNewSFHForm,
  setSFHFormData,
  onSFHChange,
  onSFHSave
}) => {
  return (
    <StyledGridContainer container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography>{header}</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={(e) => addNewSFHForm(e)}>
            Add
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {sfhData?.map((sfhDataItem, index) =>
              <TableRow key={sfhDataItem.id}>
                <StyledTableCell data-testid={`Update-${header}-${index}`}>
                  <SoftwareFirmwareHardware
                    value={sfhDataItem}
                    onChange={(val: SoftwareFirmwareHardwareData) => {
                      const newData = sfhData.map((sfhItem) => sfhItem.id === val.id ? val : sfhItem);
                      onSFHChange(newData);
                    }}
                    renderNotes={true}
                    parentComponentTestId={`Update-${header}-${index}`}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant={ButtonVariantProps.Secondary}
                    color={ButtonColorProps.Success}
                    onClick={() => onSFHSave(sfhDataItem)}
                    data-testid={`Update-${header}-${index}-save`}
                  >
                    Save
                  </Button>
                </StyledTableCell>
              </TableRow>
            )}
            {sfhFormData.map((sfhFormDataItem, index) =>
              <TableRow key={index}>
                <StyledTableCell data-testid={`New-${header}-${index}`}>
                  <SoftwareFirmwareHardware
                    value={sfhFormDataItem}
                    onChange={(val: SoftwareFirmwareHardwareData) => {
                      const newData = sfhFormData.map((sfhFormItem, i) => index === i ? val : sfhFormItem);
                      setSFHFormData(newData);
                    }}
                    renderNotes={true}
                    parentComponentTestId={`New-${header}-${index}`}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    data-testid={`New-${header}-${index}-save`}
                    variant={ButtonVariantProps.Secondary}
                    color={ButtonColorProps.Success}
                    onClick={async () => {
                      const response: any = await onSFHSave(sfhFormDataItem);

                      if (response !== 'error') {
                        const newData = sfhFormData.filter((sfhFormItem, i) => i !== index);
                        setSFHFormData(newData);
                      } else {
                        return;
                      }
                    }} 
                  >
                    Save
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {sfhFormDataItem?.isNew && (
                    <IconButton color="error" onClick={() => {
                      const newData = sfhFormData.filter((sfhFormItem, i) => i !== index);
                      setSFHFormData(newData);
                    }}>
                      <CloseRounded />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGridContainer>
  );
};