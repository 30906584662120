import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider, Grid } from '@mui/material';
import AppRoutes from './routes';
import { Error } from './pages/Error';
import Routes from './routes';
import { lightTheme } from './configs/themes';
import { useAuthUser } from './queries/user-query';
import { Loader, LoaderColorProps } from './components/atoms/Loader';
import { getToken, getUser, setToken, setUser } from './utils/users';
import { initializeQZTrayCertificate } from './utils/print-label-utils';

const App = () => {
  const { user, isAuthenticated, error, getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const hasError = user?.email_verified === false || user?.blocked === true || error;
  const authQuery = useAuthUser(user);

  useEffect(() => {
    if (isAuthenticated) {
      getUserToken();
    }
  }, [isAuthenticated]);

  const getUserToken = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    if (token) {
      setToken(token);
      setUser(user);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeQZTrayCertificate();
  }, []);

  const token = getToken();

  useEffect(() => {
    if (token) {
      const userObj = getUser();
      userObj && authQuery.mutateAsync(userObj);
    }
  }, [token]);

  if (isLoading) {
    return (
      <Grid mt="200px" justifyContent="center" display="flex">
        <Loader color={LoaderColorProps.Success} />
      </Grid>
    );
  }

  if (hasError) {
    return <Error />;
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <Routes>
        <AppRoutes />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
