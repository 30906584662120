import { ServiceData } from './service-search.type';

export interface AllocationResponse {
  id?: number;
  name: string;
  selection: string;
  serviceType: string[];
  actionType: string[];
  processType: string[];
  regionType: string[];
  node?: NodeData;
  nodeId?: number;
  usage?: string;
  checkCapacity: boolean;
  nextServiceStatus?: string;
  isActive: boolean;
  notes: string;
  clientContract: NodeData[];
}

export interface NodeData {
  code: string;
  id: number;
  name: string;
  nodeTypeCode: string;
}

export interface ClientContractData {
  selectedNodeType: string;
  nodeIds: Array<number>;
}

export interface AllocationConfigData {
  id?: number;
  name?: string;
  selection?: string;
  serviceType?: string[];
  actionType?: string[];
  processType?: string[];
  regionType?: string[];
  nodeId?: number;
  node?: NodeData;
  usage?: string;
  checkCapacity?: boolean;
  nextServiceStatus?: string;
  isActive?: boolean;
  notes?: string;
  clientContract?: ClientContractData | NodeData[] | null;
}

export enum ServiceNodeAllocationType {
  Primary = 'primary',
  Secondary = 'secondary'
}

export interface ServiceNodeAllocation {
  node?: ServiceData;
  parentNode?: ServiceData;
  allocationType?: ServiceNodeAllocationType;
}

export interface AllocationSubmitData {
  nodeId: number;
  allocationTypeCode: ServiceNodeAllocationType;
}

export const AllocationConfigKey = 'allocation-config';
