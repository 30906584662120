export const intialAllocationConfigData = [
  {
    id: 1,
    selection: 'primary',
    serviceType: ['sup', 'ins'],
    actionType: ['technician'],
    processType: ['repair'],
    regionType: ['1'],
    node: {
      id: 101,
      name: 'Node1',
      code: 'SN1',
      nodeTypeCode: 'sibling'
    },
    usage: 'fallback',
    checkCapacity: true,
    nextServiceStatus: 'otw',
    isActive: true,
    clientContract: [{
      id: 100,
      name: 'Client1',
      code: 'CL1',
      nodeTypeCode: 'client'
    } , {
      id: 103,
      name: 'Contract1',
      code: 'CT1',
      nodeTypeCode: 'contract'
    } , {
      id: 104,
      name: 'Contract2',
      code: 'CT2',
      nodeTypeCode: 'contract'
    }],
    notes: 'Note'
  },
  {
    id: 2,
    selection: 'secondary',
    serviceType: ['mtn'],
    actionType: ['technician', 'logistics'],
    processType: ['repair', 'project'],
    regionType: ['any'],
    node: {
      id: 102,
      name: 'Node2',
      code: 'SN2',
      nodeTypeCode: 'sibling'
    },
    usage: 'override',
    checkCapacity: true,
    nextServiceStatus: 'ons',
    isActive: true,
    clientContract: [{
      id: 100,
      name: 'Client1',
      code: 'CL1',
      nodeTypeCode: 'client'
    }, {
      id: 101,
      name: 'Client2',
      code: 'CL2',
      nodeTypeCode: 'client'
    }],
    notes: 'Note'
  }
];

export const selectionData = [
  {
    value: 'primary',
    label: 'Primary'
  },
  {
    value: 'secondary',
    label: 'Secondary'
  }
];

export const usageData = [
  {
    value: 'fallback',
    label: 'Fallback'
  },
  {
    value: 'override',
    label: 'Override'
  }
];