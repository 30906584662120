import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, TableContainer, Table, TableBody, TableRow, IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { SupplierPricingPanelProps } from './SupplierPricingPanel.props';
import { StyledGridContainer, StyledTableCell } from './SupplierPricingPanel.styles';
import { Typography } from '../../atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { SalesRepairSupplierData } from '../../../@types';
import { SupplierPricing } from '../../molecules/SupplierPricing';

export const SupplierPricingPanel: React.FC<SupplierPricingPanelProps> = ({
  supplierPricingData,
  onSupplierPricingChange,
  onSupplierPricingSave,
  recallSearchNodeQueryForSupplierPricing,
}) => {
  const [supplierFormData, setSupplierFormData] = useState<SalesRepairSupplierData[]>([]);
  const [newSupplierFormDataId, setNewSupplierFormDataId] = useState(-1);

  useEffect(() => {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 2000);
      }
    }
  }, [window.location.hash]);

  const addNewFormForSupplierPricing = (event: SyntheticEvent) => {
    event.stopPropagation();

    setSupplierFormData([...supplierFormData, {
      nodeId: 0,
      nodeName: '',
      partTypeName: '',
      partTypeNumber: '',
      pricePerPackage: '',
      quantity: '',
      isDefault: false,
      isActive: true,
      isNew: true
    }]);

    setNewSupplierFormDataId(newSupplierFormDataId - 1);
  };
  
  return (
    <StyledGridContainer container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography id="supplierPricing">Supplier Pricing</Typography>
        </Grid>
        <Grid item mr={2}>
          <Button variant={ButtonVariantProps.Secondary} onClick={(e) => addNewFormForSupplierPricing(e)}>
            Add
          </Button>
        </Grid>
      </Grid>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {supplierPricingData?.map((supplierPricingDataItem, index) =>
              <TableRow key={index}>
                <StyledTableCell>
                  <SupplierPricing
                    value={supplierPricingDataItem}
                    onChange={(supplierData: SalesRepairSupplierData) => {
                      if (supplierData.isDefault) {
                        supplierPricingData.map((supplierPricingItem) => {
                          if (supplierPricingItem.id !== supplierData.id && supplierPricingItem.isDefault) {
                            supplierPricingItem.isDefault = false;
                          }
                        });
                      }
                      const newData = supplierPricingData.map((supplierPricingItem) => supplierPricingItem.id === supplierData.id ? supplierData : supplierPricingItem);
                      onSupplierPricingChange(newData);
                    }}
                    recallSearchNodeQuery={(val: string) => recallSearchNodeQueryForSupplierPricing(val)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={() => onSupplierPricingSave(supplierPricingDataItem)} >
                    Save
                  </Button>
                </StyledTableCell>
              </TableRow>
            )}
            {supplierFormData?.map((supplierFormDataItem, index) =>
              <TableRow key={index}>
                <StyledTableCell>
                  <SupplierPricing
                    value={supplierFormDataItem}
                    onChange={(val: SalesRepairSupplierData) => {
                      const newData = supplierFormData.map((supplierFormDataItem, i) => index === i ? val : supplierFormDataItem);
                      setSupplierFormData(newData);
                    }}
                    recallSearchNodeQuery={(val: string) => recallSearchNodeQueryForSupplierPricing(val)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Button variant={ButtonVariantProps.Secondary} color={ButtonColorProps.Success} onClick={async () => {
                    const response: any = await onSupplierPricingSave(supplierFormDataItem);

                    if (response !== 'error') {
                      const newData = supplierFormData.filter((supplierFormDataItem, i) => i !== index);
                      setSupplierFormData(newData);
                    }
                  }} >
                    Save
                  </Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {supplierFormDataItem?.isNew && (
                    <IconButton color="error" onClick={() => {
                      const newData = supplierPricingData.filter((supplierFormDataItem, i) => i !== index);
                      setSupplierFormData(newData);
                    }}>
                      <CloseRounded />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGridContainer>
  );
};