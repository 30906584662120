import React, { useState } from 'react';
import { EmailTextFieldProps } from './EmailTextField.props';
import { TextField } from '../../atoms/TextField';
import { isValidEmail } from '../../../utils/users';
import { isEmptyString } from '../../../utils/common';
import { EMPTY_FIELD_MESSAGE } from '../../../constants/common';
import { CHARACTER_LENGTH_MESSAGE, INVALID_EMAIL_MESSAGE } from '../../../constants/user.constant';

export const EmailTextField: React.FC<EmailTextFieldProps> = ({
  fullWidth,
  value,
  helperText,
  onChange
}) => {
  const [email, setEmail] = useState(value ? value : '');
  const [EmailHelperText, setEmailHelperText] = useState(helperText ? helperText : '');

  const handleEmailChange = (text: string) => {
    if (isEmptyString(text)) {
      setEmailHelperText('Email' + EMPTY_FIELD_MESSAGE);
    } else if (isValidEmail(text)) {
      setEmailHelperText(INVALID_EMAIL_MESSAGE);
    } else if (text.length > 64) {
      setEmailHelperText(CHARACTER_LENGTH_MESSAGE);
    } else {
      setEmailHelperText('');
    }
    setEmail(text);
    onChange && onChange(text);
  };

  return (
    <TextField
      fullWidth={fullWidth}
      label="Email"
      value={email}
      error={!!EmailHelperText}
      helperText={EmailHelperText}
      onChange={handleEmailChange}
    />
  );
};