import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TextField } from '../../../components/atoms/TextField';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { RoleBox, RoleTypography } from '../../Roles/AddRole/AddRole.styles';
import { MultiSelectCheckmarksMenu } from '../../../components/atoms/MultiSelectCheckmarksMenu';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { ActionPermissions, ColorProps, CustomPermission, InputCategoryProps, ServiceStatusHelper, ServiceStatusResponse } from '../../../@types';
import { nameValidation } from '../../../utils/services';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { useCreateServiceStatus, useGetServiceStatusByCode, useGetServiceStatusByShortName, useGetServiceStatuses, useUpdateServiceStatus } from '../../../queries/service-status-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { ServiceStatus } from './AddEditServiceStatus.props';
import { NAME_FIELD_HELPER_TEXT, SHORT_NAME_FIELD_HELPER_TEXT } from '../../../constants/common';
import { RoutePath } from '../../../@types/route.type';
import { SAMPLE_SERVICE_STATUS, SAMPLE_SERVICE_STATUS_HELPER, SHORT_NAME_NOT_EXIST_HELPER_TEXT } from '../../../constants/serviceStatus.constant';
import { PREFERREDPATHS, PROGRESSIONBLOCKS, getAvailableStatuses, isError } from '../../../utils/service-status';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const AddEditServiceStatus: React.FC = () => {
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  let isNew = searchParams.get('isNew') === 'true' && code === 'add';
  const navigate = useNavigate();

  const [serviceStatus, setServiceStatus] = useState<ServiceStatusResponse>(SAMPLE_SERVICE_STATUS);
  const [helperText, setHelperText] = useState<ServiceStatusHelper>(SAMPLE_SERVICE_STATUS_HELPER);

  const [availableStatusesList, setAvailableStatusesList] = useState<DropdownItem[]>([]);

  const [openToast, setOpenToast] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const createServiceStatus = useCreateServiceStatus(serviceStatus.code);
  const updateServiceStatus = useUpdateServiceStatus(code || '');
  const getAllServiceStatus = useGetServiceStatuses();
  const getServiceStatusByCode = useGetServiceStatusByCode(code || '');
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getServiceStatusByShortName = useGetServiceStatusByShortName(serviceStatus.code);

  TabHandler(isNew ? `Create Service Status | ${PLATFORM_NAME}` : `Service Status: ${code} | Service Statuses | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  // Get all service statuses
  useEffect(() => {
    if(getAllServiceStatus.data) {
      const availableStatusItems = getAvailableStatuses(getAllServiceStatus.data, code ?? '');
      setAvailableStatusesList(availableStatusItems);
    }
  }, [getAllServiceStatus.data]);

  useEffect(() => {
    !isNew && getServiceStatusByCode.refetch();
  }, [isNew]);
  
  // for GET to edit page
  useEffect(() => {
    if (!isNew && getServiceStatusByCode.data) {
      setServiceStatus(getServiceStatusByCode.data);
    }
  }, [getServiceStatusByCode.data, isNew]);

  // check short name code already exist or not
  useEffect(() => {
    if (isNew && getServiceStatusByShortName.data && serviceStatus.code) {
      setServiceStatus({ ...serviceStatus, isShortCodeExist: true });
      setHelperText({ ...helperText, shortNameHelperText: SHORT_NAME_NOT_EXIST_HELPER_TEXT });
    } else if (isNew && getServiceStatusByShortName.error) {
      setServiceStatus({ ...serviceStatus, isShortCodeExist: false });
      setHelperText({ ...helperText, shortNameHelperText: '' });
    }
  }, [getServiceStatusByShortName.data, getServiceStatusByShortName.error, isNew]);

  const onChangeName = (name: string) => {
    setHelperText({ ...helperText, nameHelperText: nameValidation(name, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT, 30) });
    setServiceStatus({ ...serviceStatus, name: name });
  };

  const onChangeShortName = async (shortName: string) => {
    setHelperText({ ...helperText, shortNameHelperText: nameValidation(shortName, InputCategoryProps.ShortName, SHORT_NAME_FIELD_HELPER_TEXT, 6) });
    setServiceStatus({ ...serviceStatus, code: shortName });
  };

  const onChangeProgressionBlockOptions = (data: string) => {
    setServiceStatus({ ...serviceStatus, blockStatuses: data === '' ? [] : data.split(',') });
  };

  const onChangePreferredPathOptions = (data: string) => {
    setServiceStatus({ ...serviceStatus, nextStatuses: data === '' ? [] : data.split(',') });
  };

  const handleIsClosedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceStatus({ ...serviceStatus, isClosed: event.target.checked });
  };

  const handleIsSystemStatus = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceStatus({ ...serviceStatus, isSystem: event.target.checked });
  };

  const handleIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceStatus({ ...serviceStatus, isActive: event.target.checked });
  };

  const handleIsPauseSla = (event: ChangeEvent<HTMLInputElement>) => {
    setServiceStatus({ ...serviceStatus, isPauseSla: event.target.checked });
  };

  const handleShortNameExist = async () => {
    await getServiceStatusByShortName.refetch();
  };

  const handleSave = async () => {
    const validateName = nameValidation(serviceStatus.name, InputCategoryProps.Name, NAME_FIELD_HELPER_TEXT, 30);
    const validateShortName = nameValidation(serviceStatus.code, InputCategoryProps.ShortName, SHORT_NAME_FIELD_HELPER_TEXT, 6);

    setHelperText({ 
      ...helperText,
      nameHelperText: validateName,
      shortNameHelperText: serviceStatus.isShortCodeExist ? SHORT_NAME_NOT_EXIST_HELPER_TEXT : validateShortName
    });

    if (isError(serviceStatus.isShortCodeExist, validateName, validateShortName)) {
      return;
    }
    
    if (isNew) {
      const serviceStatusResponse: any = await createServiceStatus.mutateAsync(serviceStatus);
      setOpenToast(true);
      navigate(`${RoutePath.UpdateServiceStatus}/${serviceStatusResponse.code}`);
    } else {
      await updateServiceStatus.mutateAsync(serviceStatus);
      setOpenToast(true);
    }
  };

  if (isNew && isUserHasPermission(ActionPermissions.Configure_Service_Status_Create, permissions)) {
    isNew = true;
  } else if (getServiceStatusByCode.data && isUserHasPermission(ActionPermissions.Configure_Service_Status_Edit, permissions)) {
    isNew = false;
  }

  if (!getServiceStatusByCode.data && !isNew) {
    return getServiceStatusByCode.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  } else if (isNew && !isUserHasPermission(ActionPermissions.Configure_Service_Status_Create, permissions)) {
    return <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <RoleTypography variant={TypographyVariantProps.H4} fontWeight={600}>
        {isNew ? ServiceStatus.AddServiceStatus : ServiceStatus.EditServiceStatus}
      </RoleTypography>
      <RoleBox>
        <Grid item xs={12} mb={2}>
          <TextField
            fullWidth
            required
            error={!!helperText.nameHelperText}
            helperText={helperText.nameHelperText}
            label="Name"
            value={serviceStatus.name}
            disabled={serviceStatus.isSystem}
            onChange={(name) => onChangeName(name)}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <TextField
            fullWidth
            required
            disabled={!isNew}
            error={isNew && (!!helperText.shortNameHelperText || serviceStatus.isShortCodeExist)}
            helperText={isNew ? helperText.shortNameHelperText : ''}
            label="ShortName"
            value={serviceStatus.code}
            onChange={(shortName) => onChangeShortName(shortName)}
            onBlur={handleShortNameExist}
          />
        </Grid>
        <Grid item xs={12} mb={2} ml={1}>
          <FormControlLabel
            control={
              <Switch onChange={handleIsClosedChange} checked={serviceStatus.isClosed} color={ColorProps.Success} />
            }
            label="Is Closed"
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <MultiSelectCheckmarksMenu
            id="progression-blocks"
            label="Progression Blocks"
            selectedValue={serviceStatus.blockStatuses}
            onChange={(data) => onChangeProgressionBlockOptions(data)}
            items={PROGRESSIONBLOCKS(availableStatusesList, serviceStatus.nextStatuses)} 
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <MultiSelectCheckmarksMenu 
            id="preferred-paths"
            label="Preferred Paths"
            selectedValue={serviceStatus.nextStatuses}
            onChange={(data) => onChangePreferredPathOptions(data)}
            items={PREFERREDPATHS(availableStatusesList, serviceStatus.blockStatuses)} 
          />
        </Grid>
        <Grid item xs={12} mb={2} ml={1}>
          <FormControlLabel
            control={
              <Switch onChange={handleIsSystemStatus} checked={serviceStatus.isSystem} color={ColorProps.Success} disabled/>
            }
            label="Is System Status"
          />
        </Grid>
        <Grid item xs={12} mb={2} ml={1}>
          <FormControlLabel
            control={
              <Switch onChange={handleIsPauseSla} checked={serviceStatus.isPauseSla} color={ColorProps.Success}/>
            }
            label="Pause SLA"
          />
        </Grid>
        <Grid item xs={12} ml={1}>
          <FormControlLabel
            control={
              <Switch onChange={handleIsActiveChange} checked={serviceStatus.isActive} color={ColorProps.Success} disabled={serviceStatus.isSystem}/>
            }
            label="Active"
          />
        </Grid>
        <Grid item container xs={12} spacing={2} ml={0} mt={4}>
          <Button variant={ButtonVariantProps.Primary} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </RoleBox>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        message="Successfully Saved"
        onClose={() => setOpenToast(false)}
      />
    </PageContainer>
  );
};