import { styled } from '@mui/material/styles';
import { Grid, TableCell } from '@mui/material';
import { TextField } from '../../atoms/TextField';

export const StyledGridContainer = styled(Grid)`
  padding-top: 5px;
`;

export const StyledTableCell = styled(TableCell)`
  border: 0;
`;

export const StyledGreyTextField = styled(TextField)`
  background-color: #DCDCDC;
  color: black;
`;