import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { formatDateOnly } from './common';
import { DateTypeNames } from '../@types/attendanceReport.type';
import { DAYS_OF_WEEK, MONTHS_OF_YEAR } from '../constants/attendanceReport';

dayjs.extend(weekday);

export const extractDateByCode = (date: string) => {
  const today = dayjs();

  const inputTextArray = date?.split('-');
  const number = inputTextArray.find((inputText: any) => /^\d+$/.test(inputText) === true);
  const dayId = inputTextArray.find((inputText: string) => DAYS_OF_WEEK.includes(inputText.toLowerCase()));
  const monthId = inputTextArray.find((inputText: string) => MONTHS_OF_YEAR.includes(inputText.toLowerCase()));

  switch (date) {
    case DateTypeNames.Now: {
      return formatDateOnly(today.toString());
    }
    case DateTypeNames.Today: {
      return formatDateOnly(today.startOf('day').toString());
    }
    case DateTypeNames.Yesterday: {
      return formatDateOnly(today.subtract(1, 'day').toString());
    }
    case DateTypeNames.First_Day_Of_This_Week: {
      return formatDateOnly(today.startOf('week').toString());
    }
    case DateTypeNames.Last_Day_Of_This_Week: {
      return formatDateOnly(today.endOf('week').toString());
    }
    case DateTypeNames.First_Day_Of_This_Month: {
      return formatDateOnly(today.startOf('month').toString());
    }
    case DateTypeNames.Last_Day_Of_This_Month: {
      return formatDateOnly(today.endOf('month').toString());
    }
    case DateTypeNames.First_Day_Of_This_Year: {
      return formatDateOnly(today.startOf('year').toString());
    }
    case DateTypeNames.Last_Day_Of_This_Year: {
      return formatDateOnly(today.endOf('year').toString());
    }
    case DateTypeNames.First_Day_Of_Last_Week: {
      const lastWeek = today.startOf('week').subtract(1, 'day');
      return formatDateOnly(lastWeek.startOf('week').toString());
    }
    case DateTypeNames.Last_Day_Of_Last_Week: {
      const lastWeek = today.startOf('week').subtract(1, 'day');
      return formatDateOnly(lastWeek.endOf('week').toString());
    }
    case DateTypeNames.First_Day_Of_Last_Month: {
      const lastMonth = today.startOf('month').subtract(1, 'day');
      return formatDateOnly(lastMonth.startOf('month').toString());
    }
    case DateTypeNames.Last_Day_Of_Last_Month: {
      const lastMonth = today.startOf('month').subtract(1, 'day');
      return formatDateOnly(lastMonth.endOf('month').toString());
    }
    case DateTypeNames.First_Day_Of_Last_Year: {
      const lastYear = today.startOf('year').subtract(1, 'day');
      return formatDateOnly(lastYear.startOf('year').toString());
    }
    case DateTypeNames.Last_Day_Of_Last_Year: {
      const lastYear = today.startOf('year').subtract(1, 'day');
      return formatDateOnly(lastYear.endOf('year').toString());
    }
    case `${number}-days-ago`: {
      return formatDateOnly(today.subtract(Number(number), 'day').toString());
    }
    case `${number}-weeks-ago`: {
      return formatDateOnly(today.subtract(Number(number), 'week').toString());
    }
    case `${number}-fortnights-ago`: {
      return formatDateOnly(today.subtract(Number(number) * 2, 'week').toString());
    }
    case `${number}-months-ago`: {
      return formatDateOnly(today.subtract(Number(number), 'month').toString());
    }
    case `${number}-years-ago`: {
      return formatDateOnly(today.subtract(Number(number), 'year').toString());
    }
    case `first-day-${number}-months-ago`: {
      const noOfMonthsAgo = today.subtract(Number(number), 'month');
      return formatDateOnly(noOfMonthsAgo.startOf('month').toString());
    }
    case `first-day-${number}-years-ago`: {
      const noOfMonthsAgo = today.subtract(Number(number), 'year');
      return formatDateOnly(noOfMonthsAgo.startOf('year').toString());
    }
    case `last-day-${number}-months-ago`: {
      const noOfMonthsAgo = today.subtract(Number(number), 'month');
      return formatDateOnly(noOfMonthsAgo.endOf('month').toString());
    }
    case `last-day-${number}-years-ago`: {
      const noOfMonthsAgo = today.subtract(Number(number), 'year');
      return formatDateOnly(noOfMonthsAgo.endOf('year').toString());
    }
    case `${dayId?.toLowerCase()}-this-week`: {
      const dayNumber = getDayNumber(dayId?.toLowerCase());
      return formatDateOnly(today.weekday(dayNumber).toString());
    }
    case `${dayId?.toLowerCase()}-last-week`: {
      const lastWeek = today.startOf('week').subtract(1, 'day');
      const dayNumber = getDayNumber(dayId?.toLowerCase());
      return formatDateOnly(lastWeek.weekday(dayNumber).toString());
    }
    case `${dayId?.toLowerCase()}-${number}-weeks-ago`: {
      const weeksAgo = today.subtract(Number(number), 'week');
      const dayNumber = getDayNumber(dayId?.toLowerCase());
      return formatDateOnly(weeksAgo.weekday(dayNumber).toString());
    }
    case `first-day-of-${monthId?.toLowerCase()}-this-year`: {
      const thisYear = today.startOf('year');
      const monthNumber = getMonthNumber(monthId?.toLowerCase());
      return formatDateOnly(thisYear.month(monthNumber).startOf('month').toString());
    }
    case `last-day-of-${monthId?.toLowerCase()}-last-year`: {
      const lastYear = today.startOf('year').subtract(1, 'day');
      const monthNumber = getMonthNumber(monthId?.toLowerCase());
      return formatDateOnly(lastYear.month(monthNumber).endOf('month').toString());
    }
    case `first-day-of-${monthId?.toLowerCase()}-${number}-years-ago`: {
      const yearsAgo = today.subtract(Number(number), 'year');
      const monthNumber = getMonthNumber(monthId?.toLowerCase());
      return formatDateOnly(yearsAgo.month(monthNumber).startOf('month').toString());
    }
    case `first-day-of-${monthId?.toLowerCase()}-${number}`: {
      const monthNumber = getMonthNumber(monthId?.toLowerCase());
      const yearWithMonth = today.year(Number(number)).month(monthNumber);
      return formatDateOnly(yearWithMonth.startOf('month').toString());
    }
    case `last-day-of-${monthId?.toLowerCase()}-${number}`: {
      const monthNumber = getMonthNumber(monthId?.toLowerCase());
      const yearWithMonth = today.year(Number(number)).month(monthNumber);
      return formatDateOnly(yearWithMonth.endOf('month').toString());
    }
    default:
      return date;
  }
};

const getDayNumber = (dayName?: string) => {
  switch (dayName) {
    case 'monday':
      return 0;
    case 'tuesday':
      return 1;
    case 'wednesday':
      return 2;
    case 'thursday':
      return 3;
    case 'friday':
      return 4;
    case 'saturday':
      return 5;
    case 'sunday':
      return 6;
    default:
      return 0;
  }
};

const getMonthNumber = (monthName?: string) => {
  switch (monthName) {
    case 'january':
      return 0;
    case 'february':
      return 1;
    case 'march':
      return 2;
    case 'april':
      return 3;
    case 'may':
      return 4;
    case 'june':
      return 5;
    case 'july':
      return 6;
    case 'august':
      return 7;
    case 'september':
      return 8;
    case 'october':
      return 9;
    case 'november':
      return 10;
    case 'december':
      return 11;
    default:
      return 0;
  }
};
