import React from 'react';
import { Stack } from '@mui/material';
import { TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { MobileDataGridItemEntryProps } from './MobileDataGridItemEntry.props';
import { Link } from 'react-router-dom';
import { StyledTypography } from './MobileDataGridItemEntry.styles';

export const MobileDataGridItemEntry: React.FC<MobileDataGridItemEntryProps> = ({
  data
}) => {
  return (
    <Stack>
      {data.isValueIcon ? 
        <img width={32} height={32} src={data.value?.toString()} />
        :

        (data.isValueLink && data.valueLinkUrl) ?
          <Link to={data.valueLinkUrl}>{data.value}</Link>
          :
          <StyledTypography
            variant={data.valueFontVariant || TypographyVariantProps.Subtitle2}
            fontWeight={data.valueFontWeight || TypographyFontWeightProps.Regular}
          >
            {data.value}
          </StyledTypography>
      }
    </Stack>
  );
};
