import React, { useEffect, useState } from 'react';
import { PopupDialog } from '../../../../../components/molecules/PopupDialog';
import { ManifestSerial } from '../../../../../components/organisms/ManifestSerial';
import { ManifestSerialsPopupProps } from './ManifestSerialsPopup.props';
import { useGetPartTypeManifestById } from '../../../../../queries/part-type-query';
import { ENTER_LABEL, TAB, TAB_LABEL } from '../../../../../constants/common';
import { isDropDownEmpty, parseExcelData } from '../../../../../utils/common';
import { ManifestSerialObject, SerialPopupObject } from '../../../../../@types/shipment.type';
import { useCreateFreshStockManifestShipment } from '../../../../../queries/shipment-query';
import { SAMPLE_MANIFEST_SERIAL_DETAIL } from '../../../../../constants/shipment';

export const ManifestSerialsPopup: React.FC<ManifestSerialsPopupProps> = ({
  openPopup,
  partTypeId,
  manifestSerialValue,
  rowDataItemId,
  index,
  serialisedManifestDataItem,
  isEdit,
  isPending,
  onChangeManifestSerial,
  closePopup
}) => {
  const [serialPopupItems, setSerialPopupItems] = useState<SerialPopupObject>({
    id: index,
    partTypeId: partTypeId,
    sfhOptions: {
      softwares: [],
      firmwares: [],
      hardwares: [],
    },
    fleetTagOptions: [],
    conditionOptions: [],
  });
  const [manifestSerial, setManifestSerial] = useState<ManifestSerialObject>(manifestSerialValue);
  const [validate, setValidate] = useState(false);

  const getPartTypeManifestById = useGetPartTypeManifestById(partTypeId);
  const createFreshStockManifestShipment = useCreateFreshStockManifestShipment();
  const chunkSize = 200;
  
  useEffect(() => {
    manifestSerialValue && setManifestSerial(manifestSerialValue);
  }, [manifestSerialValue]);

  useEffect(() => {
    partTypeId && getPartTypeManifestById.refetch();
  }, [partTypeId]);

  useEffect(() => {
    getPartTypeManifestById.data && setSerialPopupItems({ ...getPartTypeManifestById.data, id: index });
  }, [getPartTypeManifestById.data]);

  const onKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === TAB_LABEL) {
      event.preventDefault();

      const cursorPosition = event.target.selectionStart;
      const cursorEndPosition = event.target.selectionEnd;

      event.target.value =
        value.substring(0, cursorPosition) +
        TAB +
        value.substring(cursorEndPosition);

      event.target.selectionStart = cursorPosition + 1;
      event.target.selectionEnd = cursorPosition + 1;
    } else if (event.key === ENTER_LABEL) {
      event.preventDefault();

      if (manifestSerial?.smartInput && manifestSerial?.smartInput !== '') {
        const formatedManifestedData = parseExcelData(manifestSerial.smartInput || '') as Array<Array<string>>;
        if (formatedManifestedData.length > 0) {
          const currentManifestedData = formatedManifestedData?.map((item: Array<string>, index: number) => {
            return {
              id: index + 1,
              status: 'new',
              serial1: item[0],
              serial2: item[1],
              serial3: item[2]
            };
          });

          let filteredSerials = [];
          if (
            (currentManifestedData?.length > 0) &&
            (manifestSerial?.serialsDataSet && manifestSerial?.serialsDataSet?.length > 0)
          ) {
            const lastSerialId = manifestSerial.serialsDataSet[manifestSerial.serialsDataSet.length - 1]?.id || 0;
            let nextSerialId = lastSerialId + 1; // Initialize the next serial ID

            filteredSerials = [
              ...manifestSerial.serialsDataSet,
              ...currentManifestedData.map((item: any) => ({
                id: nextSerialId++, // Use the current value of nextSerialId and then increment it
                status: 'new',
                serial1: item['serial1'],
                serial2: item['serial2'],
                serial3: item['serial3'],
              })),
            ];

          } else {
            filteredSerials = currentManifestedData;
          }

          setManifestSerial({
            serialsDataSet: filteredSerials,
            smartInput: '',
            selectedSerials: [],
            rowId: rowDataItemId,
            firmwareVersion: manifestSerial.firmwareVersion === '-1' ? '' : manifestSerial.firmwareVersion,
            hardwareRevision: manifestSerial.hardwareRevision === '-1' ? '' : manifestSerial.hardwareRevision,
            softwareVersion: manifestSerial.softwareVersion === '-1' ? '' : manifestSerial.softwareVersion,
            fleetTag: manifestSerial.fleetTag === '-1' ? '' : manifestSerial.fleetTag,
            condition: manifestSerial.condition,
            id: index,
          });
        }
      }
    }
  };

  const onSave = async () => {
    if (isDropDownEmpty(manifestSerial.condition)) {
      setValidate(true);
      return;
    }

    onChangeManifestSerial({ ...manifestSerial, id: index });
    setManifestSerial(SAMPLE_MANIFEST_SERIAL_DETAIL);
    if (isEdit) {
      const responses: any[] = [];
      const serialsDataSet = manifestSerial.serialsDataSet || [];
      for (let i = 0;i < serialsDataSet?.length;i += chunkSize) {
        const chunk = serialsDataSet.slice(i, i + chunkSize);
        const response: any = await createFreshStockManifestShipment.mutateAsync({ ...serialisedManifestDataItem, serials: chunk });
        responses.push(response);
      }
      responses && responses.length > 0 && closePopup(false);
    }
    closePopup(false);
  };

  const onClose = (event?: any, reason?: string) => {
    if (reason === 'backdropClick') {
      closePopup(true);
    } else {
      setManifestSerial(SAMPLE_MANIFEST_SERIAL_DETAIL);
      closePopup(false);
    }
  };

  return (
    <PopupDialog
      maxWidth="1600px"
      title="Serials"
      isOpen={openPopup}
      onClose={onClose}
      onSave={onSave}
    >
      <ManifestSerial isEdit={isEdit} isPending={isPending} manifestedData={manifestSerial} serialPopupItems={serialPopupItems} validate={validate} onChange={setManifestSerial} onKeyDown={onKeyDown} />
    </PopupDialog>
  );
};