import { styled } from '@mui/material/styles';
import MaterialDrawer from '@mui/material/Drawer';
import { Typography } from '../../atoms/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputBase, InputAdornment, AccordionDetails, Icon, Box } from '@mui/material';

export const MaterialDrawerStyled = styled(MaterialDrawer)(({ hidden }) => {
  if (hidden) {
    return `
      display: none;
    `;
  } else {
    return `
      display: flex;
      align-items: center;
      justify-content: center;
    `;
  }
});

export const TypographyStryled = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Wrapper = styled('div')(({ theme }) => `
  height: 100%;
  background-color: ${theme.palette.background.default};
  display: flex;
  flex-direction: column;
`);

export const DrawerWrapper = styled('div')`
  padding-right: 16px;
  margin-bottom: auto;
`;
export const HeaderWrapper = styled('div')(({ theme }) => `
  display: flex;
  padding: 10px;
  padding-bottom: 0px;
  justify-content: center;
  color: ${theme.palette.common.white};
`);

export const HeaderWrapperChild = styled('div')`
  display: flex;
`;

export const HeaderImage = styled('img')`
  height: 90%;
  width: 90%;
`;

export const IconImage = styled('img')`
  height: 32px;
  width: 32px;
  margin: 10px;
`;

export const MenuImage = styled('img')`
  height: 16px;
  width: 16px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const MenuImageWrapper = styled('div')`
  height: 25px;
  width: 25px;
  opacity: 0.4;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const StyledInput = styled(InputBase)(({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-right: 16px;
  margin-left: 16px;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
  color: ${theme.palette.common.white};
  background: ${theme.palette.action.selected};
`);
export const AccordionSummeryInnerWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
`;
export const DrawerContentWrapper = styled('div')({
  marginTop: '20px'
});

export const AccordionInnerWrapper = styled('div')`
  padding-left: 5px;
  padding-top: 5px;
`;

export const ProfileImage = styled('img')`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

export const AdornmentWrapper = styled(InputAdornment)`
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
`;
export const AccordionDetailsStyled = styled(AccordionDetails)`
  min-height: 25px;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 5px;
  padding-top: 0;
`;
export const ExpandMoreIconStyled = styled(ExpandMoreIcon)`
  opacity: 0.4;
`;

export const MobileNavbar = styled('nav')`
  display: flex;
  justify-content: space-between;
`;

export const IconStyled = styled(Icon)`
  height: 32px;
  width: 32px;
  margin: 10px;
  cursor: pointer;
`;

export const StyledArrowBox = styled(Box)`
  position: absolute;
  right: 0;
  padding-right: 15px;
`;