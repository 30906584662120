import { ActionPermissions, RoutesEnum, ServiceStatusCode } from '../@types';

export const ROOT_NODE = {
  id: 1000,
  name: 'root',
  code: 'root',
  isGranted: false,
  children: []
};

// get the layouts according to user permissions
export const permittedLayouts = (menuData: any, permissions: any) => {
  
  // Split granted user permission code by '.' and then get the first keyword.
  // ex-: input => service.create | output => ['service']
  const activePermissionParentCodes: any = [
    ...new Set(permissions.map((filteredPermission: any) => (
      filteredPermission.split('.')[0]
    )))
  ];

  // Split granted user permission code by '.' and then get the first two keywords if it has more than one keyword.
  // ex-: input => configure.user | output => ['configure', 'user']
  const activePermissionChildCodes: any = [
    ...new Set(permissions.filter((permission: any) => permission.split('.').length > 1).map((filteredPermission: any) => (
      `${filteredPermission.split('.')[0]}.${filteredPermission.split('.')[1]}`
    )))
  ];

  // get the all permission codes only
  const activePermissionNestedChildCodes: any = [
    ...new Set(permissions.filter((permission: any) => permission.split('.').length > 2).map((filteredPermission: any) => (
      `${filteredPermission.split('.')[0]}.${filteredPermission.split('.')[1]}.${filteredPermission.split('.')[2]}`
    )))
  ];

  // compare menuData against filtered parent permission codes and get the common.
  // see data.js to understand the data view
  const filteredParentMenus = menuData.filter((menuItem: any) => {
    return activePermissionParentCodes.some((activePermissionParentCode: string) => menuItem.permission === activePermissionParentCode);
  });

  // compare filtered menuData first childs against child permission codes and get the common
  // see data.js to understand the data view
  const filteredChildMenus: any = filteredParentMenus.map((filteredParentMenu: any) => ({
    ...filteredParentMenu,
    ...(filteredParentMenu.subMenuItems && { 
      subMenuItems: filteredParentMenu.subMenuItems?.filter((subMenuItem: any) => {
        return activePermissionChildCodes.some((activePermissionChildCode: string) => subMenuItem.permission === activePermissionChildCode);
      }) 
    })
  }));

  // compare filtered menuData nested childs against nested child permission codes and get the common
  // see data.js to understand the data view
  const filteredNestedChildMenus: any = filteredChildMenus.map((filteredParentMenu: any) => ({
    ...filteredParentMenu,
    ...(filteredParentMenu.subMenuItems && { 
      subMenuItems: filteredParentMenu.subMenuItems?.map((nestedsubMenuItems: any) => ({
        ...nestedsubMenuItems,
        ...(nestedsubMenuItems.subMenuItems && {
          subMenuItems: nestedsubMenuItems.subMenuItems?.filter((nestedsubMenuItem: any) => {
            return activePermissionNestedChildCodes.some((activePermissionNestedChildCode: string) => nestedsubMenuItem.permission === activePermissionNestedChildCode);
          })
        })
      }))
    })
  }));

  const home = { id: 'home', name: 'Home', url: '/' };

  // add home routes as first element of the array
  filteredNestedChildMenus.unshift(home);

  return filteredNestedChildMenus;
};

// get the routes according to user permissions
export const permittedRoutes = (layoutes: any, routes: any) => {
  const layoutUrls: any = [];
  let url: any;

  // map filtered layoutes which is returned from const permittedLayouts
  layoutes.map((parent: any) => {

    // push urls from parent level
    parent.url.length && layoutUrls.push(parent.url);

    // push urls from child level
    parent.subMenuItems?.map((child: any) => {
      if (child.url.split('/').includes('create')) {
        url = child.url.replace('create', ':id');
        layoutUrls.push(url);
      } else if (child.url.split('/').includes('add')) {
        url = child.url.replace('add', ':id');
        layoutUrls.push(url);
      }
      else {
        layoutUrls.push(child.url);
      }

      // push urls from nested child level
      child.subMenuItems?.map((nestedChild: any) => {
        if (nestedChild.url.split('/').includes('create')) {      
          url = nestedChild.url.replace('create', ':id');
          layoutUrls.push(url);
        } else if (nestedChild.url.split('/').includes('add')) {      
          url = nestedChild.url.replace('add', ':id');
          layoutUrls.push(url);
        }
        else {
          layoutUrls.push(nestedChild.url);
        }
      });
    });
  });

  // push urls that not included in data.js
  layoutUrls.map((layoutUrl: any) => {
    if (layoutUrl === RoutesEnum.UserCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.UserCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.RoleCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.RoleCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.StructureTypeCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.StructureTypeCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.ClientAndContractCreateAndUpdateRoute) {
      layoutUrls.push('/configure/clients/:type/:id');
    }
    if (layoutUrl === RoutesEnum.ServiceTypeCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.ServiceTypeCreateAndUpdateRoute}/:code`);
    }
    if (layoutUrl === RoutesEnum.ServiceStatusCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.ServiceStatusCreateAndUpdateRoute}/:code`);
    }
    if (layoutUrl === RoutesEnum.PartTypeCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.PartTypeCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.PartsSearchRoute) {
      layoutUrls.push(`${RoutesEnum.PartUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.ShipmentUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.ShipmentUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.BatchReceiveCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.BatchReceiveCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.TLCTemplateCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.TLCTemplateCreateAndUpdateRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.ReceivedShipmentRoute) {
      layoutUrls.push(`${RoutesEnum.ReceivedShipmentRoute}/:id`);
    }
    if (layoutUrl === RoutesEnum.ServiceCreateAndUpdateRoute) {
      layoutUrls.push(`${RoutesEnum.ServiceCreateAndUpdateRoute}/:id`);
    }

    layoutUrls.push(`${RoutesEnum.StructureCreateAndUpdateRoute}/:id`);
    layoutUrls.push('/loader');
  });

  // compare filtered urls against route urls and get common
  const filteredRoutes: any = {
    ...routes,
    children: routes.children.filter((route: any)=> {
      return layoutUrls.some((layoutUrl: any) => layoutUrl == route.path);
    })
  };

  return filteredRoutes;
};

export const isUserHasPermission = (actionPermission: string, systemPermissions: any) => {
  const systemActionPermissions: any = [
    ...new Set(systemPermissions?.filter((permission: any) => permission.split('.').length > 1).map((filteredPermission: any) => (
      filteredPermission
    )))
  ];
  
  return systemActionPermissions.some((systemActionPermission: any) => systemActionPermission.includes(actionPermission));
};

export const isUserHasPartActionPermissions = (permissions: any, isCheckIn: boolean, serviceStatusCode: string) => {
  const isHaveAnyTimePermission = isUserHasPermission(ActionPermissions.Service_Edit_Actions_Part_Action_Anytime, permissions);
  const isHaveInProgressPermission = isUserHasPermission(ActionPermissions.Service_Edit_Actions_Part_Action_Inprogress, permissions);
  
  if (isHaveAnyTimePermission) {
    return true;
  }

  if (isHaveInProgressPermission) {
    const isOnSite = serviceStatusCode === ServiceStatusCode.OnSite;

    if (isOnSite || isCheckIn) {
      return true;
    }
  }

  return false;
};