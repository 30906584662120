import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { 
  Typography, 
  TypographyFontWeightProps, 
  TypographyVariantProps 
} from '../../../../../../components/atoms/Typography';
import { TextField } from '../../../../../../components/atoms/TextField';
import { isEmptyString } from '../../../../../../utils/common';
import { ModifyStructureFormFragmentProps } from './ModifyStructureFormFragment.props';

export const ModifyStructureFormFragment: React.FC<ModifyStructureFormFragmentProps> = ({
  preSelectedData,
  isError,
  service,
  value,
  onChange,
}) => {
  useEffect(() => {
    if (preSelectedData) {
      onChange({ 
        locationName: value?.locationName || '',
        modifyStructure: preSelectedData 
      });
    }
  }, [preSelectedData]);

  const getSubLocationStatus = (): boolean => {
    return !!(service.subLocationNodeId && service.subLocationNodeId > 0);
  };

  return (
    <Stack spacing={2}>
      <Typography
        variant={TypographyVariantProps.Body2}
        fontWeight={TypographyFontWeightProps.Bold}
      >
        Modify Structure
      </Typography>
      <TextField
        label="New Sub Location Name"
        value={getSubLocationStatus() ? 'Sub Location Exist' : value?.locationName}
        onChange={(val) => onChange({ 
          locationName: val,
          modifyStructure: preSelectedData
        })}
        error={isError && (!value?.locationName || isEmptyString(value?.locationName))}
        disabled={getSubLocationStatus()}
      />
    </Stack>
  );
};
