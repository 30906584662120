import React, { MouseEvent } from 'react';
import { SizeProps } from '../../../@types';
import { ToogleButtonGroupColor, ToogleButtonGroupProps, ToogleButtonTextAlignment } from './ToogleButtonGroup.props';
import { Stack, ToggleButtonGroup as MaterialToggleButtonGroup } from '@mui/material';
import { MainLabel, StyledToggleButton, SubLabel } from './ToogleButtonGroup.styles';
import { TypographyVariantProps } from '../Typography';

export const ToggleButtonGroup: React.FC<ToogleButtonGroupProps> = ({
  color = ToogleButtonGroupColor.Standard,
  value,
  exclusive,
  onChange,
  size = SizeProps.Small,
  items
}) => {
  return (
    <MaterialToggleButtonGroup
      color={color}
      value={value}
      exclusive={exclusive}
      onChange={(event: MouseEvent<HTMLElement>, alignment: any) => onChange && onChange(event, alignment)}
      size={size}
    >
      {items.map(({ value, icon, label, subLabel, disabled, onClick }) =>
        <StyledToggleButton
          key={value}
          value={value}
          disabled={disabled}
          onClick={() => onClick && onClick(value)}
        >
          <Stack alignItems={ToogleButtonTextAlignment.Center}>
            <Stack direction="row">
              {icon}
              <MainLabel>{label}</MainLabel>
            </Stack>
            <SubLabel variant={TypographyVariantProps.Subtitle2}>{subLabel}&nbsp;</SubLabel>
          </Stack>
        </StyledToggleButton>
      )}
    </MaterialToggleButtonGroup>
  );
};