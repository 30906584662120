import React, { ChangeEvent, useEffect, useState } from 'react';
import { GridColDef, GridDensityTypes, GridFeatureModeConstant, GridRenderCellParams, GridRowClassNameParams, GridValueGetterParams, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Grid } from '@mui/material';
import { Switch } from '../../../../components/atoms/Switch';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Typography } from '../../../../components/atoms/Typography';
import { ColorProps, DatagridSortingTypes } from '../../../../@types';
import { StyledTableWrapper } from './ShipmentAccordianFragment.styles';
import { ShipmentAccordianFragmentProps, StructureShipmentListType } from './ShipmentAccordianFragment.props';
import { formatDate } from '../../../../utils/common';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../../components/atoms/FormControlLabel';
import { useGetAllShipmentsForStructures } from '../../../../queries/structure-query';
import { NodeTypeCode, ShipmentStatusIsPending } from '../../../../configs/enums';
import { Link } from '../../../../components/atoms/Link';
import { DataGrid } from '../../../../components/atoms/DataGrid';

export const ShipmentAccordianFragment: React.FC<ShipmentAccordianFragmentProps> = ({
  nodeId
}) => {
  const [enableChildLocationShipments, setEnableChildLocationShipments] = useState<boolean>(false);
  const [shipmentsData, setShipmentsData] = useState<StructureShipmentListType>();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  
  const getAllShipmentsForStructures = useGetAllShipmentsForStructures({
    currentPage: page, 
    limit: pageSize,
    total: 0
  }, nodeId, enableChildLocationShipments);

  useEffect(() => {
    getAllShipmentsForStructures.data && setShipmentsData(getAllShipmentsForStructures.data);
  }, [getAllShipmentsForStructures.data]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Shipment Id',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => <Link href={`/shipments/${params.row.id}`}>{params.row.id}</Link>
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      minWidth: 120,
      valueGetter: (params: GridValueGetterParams) => params.row.createdBy
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => formatDate(params.row.createdAt)
    },
    {
      field: 'shipmentStatus',
      headerName: 'Shipment Status',
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => params.row.status
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => params.row.sourceNodeTypeCode !== NodeTypeCode.Sibling ? <Link href={`/structure/${params.row.sourceNodeId}`}>{params.row.source}</Link> : params.row.source
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => params.row.destinationNodeTypeCode !== NodeTypeCode.Sibling ?<Link href={`/structure/${params.row.destinationNodeId}`}>{params.row.destination}</Link> : params.row.destination
    },
    {
      field: 'qty',
      headerName: 'Total QTY Stock',
      minWidth: 250,
      valueGetter: (params: GridValueGetterParams) => params.row.qty
    }
  ];

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setEnableChildLocationShipments(event.target.checked);
  };

  return (
    <Accordian title={
      <Grid container alignItems="center">
        <Grid item spacing={1} alignItems="center" sm container display="flex">
          <Grid item>
            <Typography>Shipments</Typography>
          </Grid>
          <Grid item marginLeft="auto" mr={1}>
            <FormControlLabel
              control={
                <Switch
                  color={ColorProps.Success}
                  checked={enableChildLocationShipments}
                  onChange={handleToggleChange}
                  onClick={(e) => e.stopPropagation()} />
              }
              label="Include Child Records"
              labelPlacement={FormControlLabelPlacementProps.Start}
            />
          </Grid>
        </Grid>
      </Grid>
    }>
      <StyledTableWrapper>
        <DataGrid
          density={GridDensityTypes.Compact}
          columns={columns}
          rows={shipmentsData?.data || []}
          getRowId={(row) => row?.id}
          rowCount={shipmentsData?.total}
          loading={getAllShipmentsForStructures.isLoading}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          rowsPerPageOptions={[15]}
          pagination={true}
          page={page}
          pageSize={pageSize}
          paginationMode={GridFeatureModeConstant.server}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: '__tree_data_group__', sort: DatagridSortingTypes.Descending }],
            },
          }}
          getRowClassName={(params: GridRowClassNameParams) => `super-app-theme--${params.row.index}`}
          autoHeight={true}
          treeData={true}
          getTreeDataPath={(row) => [row.isPending ? ShipmentStatusIsPending.Pending : ShipmentStatusIsPending.Complete, `${row.relation} ${row.id}`]}
          groupingColDef={{
            hideDescendantCount: true,
            valueFormatter: (params: GridValueFormatterParams<string | number>) => params.value === ShipmentStatusIsPending.Pending || params.value === ShipmentStatusIsPending.Complete ? params.value : '',
            width: 200,
          }}
          isGroupExpandedByDefault={(node: any) => node.groupingKey === ShipmentStatusIsPending.Pending}
        />
      </StyledTableWrapper>
    </Accordian>
  );
};