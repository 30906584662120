import { QueryOption } from '../@types/common.type';
import { httpClient, HttpMethod } from './http-client';

export const getAllPartTypes = (queryOptions: QueryOption) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/partTypes?page=${page + 1}&limit=${pageSize}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });
  return httpClient(url, HttpMethod.Get, null);
};