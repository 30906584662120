import React, { SyntheticEvent, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Accordian } from '../../atoms/Accordian';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { Typography } from '../../atoms/Typography';
import { ModelProps } from '../../molecules/Model';
import { CoverageProps } from '../../organisms/Coverage';
import { FaultDefinitaionsProps } from '../../organisms/FaultDefinitions';
import { PriorityAndServiceProps } from '../../organisms/PriorityAndService';
import { FormListAccordianProps } from './FormListAccordian.props';

export const FormListAccordian: React.FC<FormListAccordianProps> = ({
  formData,
  title,
  component,
  onChange,
  onClickAdd
}) => {
  const [expanded, setExpanded] = useState(true);

  let Component: React.ElementType;
  
  const isPriorityAndService = (data: PriorityAndServiceProps[] | CoverageProps[] | ModelProps[] | FaultDefinitaionsProps[]) : data is PriorityAndServiceProps[] => {
    return (data as PriorityAndServiceProps[])[0].serviceTypes !== undefined;
  };

  const isCoverage = (data: PriorityAndServiceProps[] | CoverageProps[] | ModelProps[] | FaultDefinitaionsProps[]) : data is CoverageProps[] => {
    return (data as CoverageProps[])[0].serviceTypeItems !== undefined;
  };

  const isModel = (data: PriorityAndServiceProps[] | CoverageProps[] | ModelProps[] | FaultDefinitaionsProps[]) : data is ModelProps[] => {
    return (data as ModelProps[])[0].multiSelectData !== undefined;
  };

  const isFault = (data: PriorityAndServiceProps[] | CoverageProps[] | ModelProps[] | FaultDefinitaionsProps[]) : data is FaultDefinitaionsProps[] => {
    return (data as FaultDefinitaionsProps[])[0].faults !== undefined;
  };

  if(formData.length > 0){
    if(isPriorityAndService(formData)){
      Component = component as React.FC<PriorityAndServiceProps>;
    }else if(isCoverage(formData)){
      Component = component as React.FC<CoverageProps>;
    }else if(isModel(formData)){
      Component = component as React.FC<ModelProps>;
    }else if(isFault(formData)){
      if(formData[0].faults.length === 0){
        formData = [];
      }
      Component = component as React.FC<FaultDefinitaionsProps>;
    }else{
      Component = component as React.FC<any>;
    }
  }
  return (
    <Accordian 
      expanded={expanded} 
      onChange={(event: SyntheticEvent, newExpanded: boolean) => setExpanded(newExpanded)}
      title={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item mr={2}>
            <Button variant={ButtonVariantProps.Secondary} startIcon={<Add/>} 
              onClick={(e) => {
                setExpanded(true);
                onClickAdd && onClickAdd(e);
              }}>
                Add
            </Button>              
          </Grid>
        </Grid>
      }>
      <TableContainer component={Paper}>
        <Table aria-label="a dense table" size="small">
          <TableBody>
            {formData?.map((data,index) => {
              Object.assign(data, {
                onChange: (value: any) => {
                  onChange(value, index);
                }   
              });
              return <TableRow
                key={index}
                sx={{ '&:last-child td': { border: 0 } }}
              >
                <TableCell>
                  <Grid mb={0} alignItems="center">
                    <Component 
                      {...data}
                    />
                  </Grid>
                </TableCell>
              </TableRow>;
            }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Accordian>
  );
};