import React from 'react';
import { LinearProgressBarProps } from './LinearProgressBar.props';
import { PrecentageWrapperBox, ProgressWrapperBox, StyledLinearProgress, WrapperBox } from './LinearProgressBar.styles';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';

export const LinearProgressBar: React.FC<LinearProgressBarProps> = ({
  progress,
  showPrecentage
}) => {
  return (
    <WrapperBox>
      {showPrecentage && <PrecentageWrapperBox>
        <Typography
          variant={TypographyVariantProps.Body2}
        >{`${Math.round(progress)}%`}</Typography>
      </PrecentageWrapperBox>}
      <ProgressWrapperBox>
        <StyledLinearProgress variant="buffer" value={progress} />
      </ProgressWrapperBox>
    </WrapperBox>
  );
};