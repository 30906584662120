import React, { useState } from 'react';
import { Stack, Table, TableBody, TableContainer } from '@mui/material';
import { AddStockManifestRowItem, AddStockManifestRowItemValueType } from '../../../../../components/organisms/AddStockManifestRowItem';
import { AddStockManifestRowItemListFragmentProps } from './AddStockManifestRowItemListFragment.props';
import { Typography } from '../../../../../components/atoms/Typography';
import { SizeProps } from '../../../../../@types';
import { onCancelDataFilter, onChangeDataMapper, onChangeSerialisedDataMapper, rowNonSerialisedDataMapper, rowSerialisedDataMapper } from '../../../../../utils/shipment';
import { ManifestSerialsPopup } from '../ManifestSerialsPopup/ManifestSerialsPopup';
import { SAMPLE_MANIFEST_ATTRIBUTE_DETAIL } from '../../../../../constants/shipment';
import { useGetSerialValues } from '../../../../../queries/shipment-query';
import { ManifestAttributesPopup } from '../ManifestAttributesPopup/ManifestAttributesPopup';

export const AddStockManifestRowItemListFragment: React.FC<AddStockManifestRowItemListFragmentProps> = ({
  isPending,
  onSerialsClick,
  onPricesClick,
  onAttributesClick,
  onDelete,
  setSerializedManifestValues,
  serializedManifestValues,
  setNonSerializedManifestValues,
  nonSerializedManifestValues,
}) => {
  const [partTypeId, setPartTypeId] = useState<number>(0);
  const [index, setIndex] = useState<number>(0);

  const [nonSerialisedAttribute, setNonSerialisedEditAttribute] = useState<AddStockManifestRowItemValueType>(SAMPLE_MANIFEST_ATTRIBUTE_DETAIL);
  const [openNonSerialisedAttributes, setNonSerialisedAttributesOpen] = useState(false);

  const [serialisedSerial, setSerialisedEditSerial] = useState<AddStockManifestRowItemValueType>(SAMPLE_MANIFEST_ATTRIBUTE_DETAIL);
  const [openSerialisedSerials, setSerialisedSerialsOpen] = useState(false);
  const getSerialValues = useGetSerialValues();

  return (
    <Stack direction="column">
      <TableContainer>
        <Stack direction="column" marginTop={3} marginBottom={1}>
          <Typography>Serialized Part Type Manifest Items</Typography>
          <Table size={SizeProps.Small}>
            <TableBody>
              {serializedManifestValues.map((rowFormDataItem: AddStockManifestRowItemValueType, index) =>
                <AddStockManifestRowItem
                  key={index}
                  isNSPartType={false}
                  isRemovable={!rowFormDataItem.manifestGroupId }
                  value={rowFormDataItem}
                  isPending={isPending}
                  onChange={(val) => {
                    const updatedFormData = onChangeDataMapper(serializedManifestValues, val);
                    updatedFormData && setSerializedManifestValues([...updatedFormData]);
                  }}
                  onSerialsClick={async (e, partTypeId, id) => {
                    setPartTypeId(partTypeId);
                    setIndex(id);
                    if (!rowFormDataItem.manifestGroupId) {
                      setSerialisedEditSerial(rowFormDataItem);
                    }
                    else {
                      const values: any = await getSerialValues.mutateAsync(rowFormDataItem);
                      setSerialisedEditSerial({ ...rowFormDataItem, serials: values[0] });
                    }

                    setSerialisedSerialsOpen(true);
                  }}
                  onPricesClick={onPricesClick}
                  onAttributesClick={onAttributesClick}
                  onCancel={() => {
                    const filteredFormData = onCancelDataFilter(rowSerialisedDataMapper(serializedManifestValues), rowFormDataItem.id);
                    filteredFormData && setSerializedManifestValues(filteredFormData);
                  }}
                  onDelete={onDelete}
                />
              )}
              <ManifestSerialsPopup
                openPopup={openSerialisedSerials}
                rowDataItemId={serialisedSerial.serials.rowId || 0}
                manifestSerialValue={serialisedSerial.serials}
                serialisedManifestDataItem={serialisedSerial}
                partTypeId={partTypeId}
                isPending={isPending}
                index={index}
                isEdit={(serialisedSerial.manifestGroupId || 0) > 0}
                onChangeManifestSerial={(val) => {
                  const updatedFormData = onChangeSerialisedDataMapper(serializedManifestValues, val);
                  updatedFormData && setSerializedManifestValues([...updatedFormData || []]);
                }}
                closePopup={(val) => setSerialisedSerialsOpen(val)}
              />
            </TableBody>
          </Table>
        </Stack>
      </TableContainer>
      <TableContainer>
        <Stack direction="column" marginTop={3} marginBottom={1}>
          <Typography> Non Serialized Part Type Manifest Items</Typography>
          <Table size={SizeProps.Small}>
            <TableBody>
              {nonSerializedManifestValues.map((rowFormDataItem: AddStockManifestRowItemValueType, index) =>
                <AddStockManifestRowItem
                  key={index}
                  isNSPartType={true}
                  isRemovable={!rowFormDataItem.manifestId }
                  isEdit={false}
                  isPending={isPending}
                  value={rowFormDataItem}
                  onChange={(val) => {
                    const updatedFormData = onChangeDataMapper(nonSerializedManifestValues, val);
                    updatedFormData && setNonSerializedManifestValues([...updatedFormData]);
                  }}
                  onSerialsClick={onSerialsClick}
                  onPricesClick={onPricesClick}
                  onAttributesClick={(e, partTypeId, id) => {
                    setNonSerialisedEditAttribute(rowFormDataItem);
                    setPartTypeId(partTypeId);
                    setIndex(id);
                    setNonSerialisedAttributesOpen(true);
                  }}
                  onCancel={() => {
                    const filteredFormData = onCancelDataFilter(rowNonSerialisedDataMapper(nonSerializedManifestValues), rowFormDataItem.id);
                    filteredFormData && setNonSerializedManifestValues(filteredFormData);
                  }}
                  onDelete={onDelete}
                />
              )}
              <ManifestAttributesPopup
                isEdit={!!nonSerialisedAttribute?.manifestId}
                openPopup={openNonSerialisedAttributes}
                partTypeId={partTypeId}
                index={index}
                attribute={nonSerialisedAttribute}
                closePopup={(val) => setNonSerialisedAttributesOpen(val)}
                onChangeManifestAttribute={(val) => {
                  const updatedFormData = onChangeDataMapper(nonSerializedManifestValues, val);
                  updatedFormData && setNonSerializedManifestValues([...updatedFormData || []]);
                }}
              />
            </TableBody>
          </Table>
        </Stack>
      </TableContainer>
    </Stack>
  );
};