import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { InteractionsTemplateList } from './InteractionsTemplatesList';
import { AddEditInteractionsTemplateModal } from './AddEditInteractionsTemplateModal';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { TemplateRowDataType } from '../../../@types/interactions.type';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';

export const InteractionsTemplate: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>([]);
  const [openAddTemplate, setOpenAddTemplate] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<TemplateRowDataType>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const addTemplate = () => {
    setOpenAddTemplate(!openAddTemplate);
  };

  TabHandler(`Interactions Templates | ${PLATFORM_NAME}`);

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Interactions Templates
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Interactions_Edit_Templates, permissions) &&
            <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addTemplate()}>
              Create Template
            </Button>
          }
        </InlineGroup>
        <AddEditInteractionsTemplateModal
          openModal={openAddTemplate}
          templateData={templateData}
          handleClose={() => {
            setOpenAddTemplate(false);
            setTemplateData({});
          }}
          handleRefetch={(refetch: boolean) => setRefetch(refetch)}
        />
        {permissions.length &&
          <InteractionsTemplateList 
            handleOpen={(openModal: boolean, rowData: TemplateRowDataType) => {
              setTemplateData(rowData);
              setOpenAddTemplate(openModal);
            }}
            isRefetch = {refetch}
            setBackRefetch={(value: boolean) => setRefetch(value)}
            canEdit={isUserHasPermission(ActionPermissions.Configure_Interactions_Edit_Templates, permissions)}
          />
        }
      </Wrapper>
    </PageContainer>
  );
};