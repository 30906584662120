export enum LinkedListProperties {
  QuantityInStock = 'quantityInStock',
  PartTypeId = 'partTypeId',
  LocationId = 'locationId',
  ConditionCode = 'conditionCode',
  SoftwareVersionId = 'softwareVersionId',
  FirmwareVersionId = 'firmwareVersionId',
  HardwareRevisionId = 'hardwareRevisionId',
  FleetTagId = 'fleetTagId',
  Serial1 = 'serial1',
  Serial2 = 'serial2',
  Serial3 = 'serial3',
  Notes = 'notes',
  ParentPartId = 'parentPartId'
}

export interface NonSerialisedPartsUpdateSelectionDataType {
  quantityInStock?: number;
  isActiveLinkedField?: boolean;
  linkedFieldColumn?: string;
}