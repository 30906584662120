import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetServiceById } from '../../../queries/service-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { AddService } from '../AddService';
import { UpdateService } from '../UpdateService';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { NotFoundError } from '../../Error/NotFound';

export const AddEditService: React.FC = () => {
  const { id }: any = useParams();
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);
  const [isServiceExist, setIsServiceExist] = useState(false);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getServiceById = useGetServiceById(parseInt(id));

  useEffect(() => {
    getServiceById.data && setIsServiceExist(true);
  }, [getServiceById.data]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  if (id === 'create' && isUserHasPermission(ActionPermissions.Service_Create, permissions)) {
    return <AddService />;
  } else if (isServiceExist && isUserHasPermission(ActionPermissions.Service_Edit_View_Service, permissions)) {
    return <UpdateService />;
  } else if (!getServiceById.data) {
    return getServiceById.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  } else {
    return null;
  }
};