export enum TradingHourTypes {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
  IndividualDate = 'Individual Date',
  AllDays = 'All Days',
  PublicHoliday = 'Public Holiday'
}

export interface TradingHoursData {
  start: string;
  end: string;
  ph: boolean;
  note?: string;
  type: TradingHourTypes;
}

export interface TradingHoursProps {
  value: TradingHoursData;
  onChange: (value: TradingHoursData) => void;
  onDelete: () => void;
  disableElement?: boolean;
}