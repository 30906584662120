export const SAMPLE_NOTIFICATION_TEMPLATE = {
  id: 0,
  name: '',
  type: '-1',
  templateId: '',
  template: '',
  subject: '',
  isActive: false,
  createdBy: '',
  createdAt: ''
};

export const SAMPLE_NOTIFICATION_TEMPLATE_HELPER_TEXT = {
  nameHelperText: '',
  typeHelperText: ''
};

export const NOTIFICATION_TYPE_DROPDOWN_ITEMS = [
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
];