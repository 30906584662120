import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { 
  Typography, 
  TypographyFontWeightProps, 
  TypographyVariantProps 
} from '../../../../../../components/atoms/Typography';
import { CheckItem } from '../../../../../../components/molecules/CheckItem';
import { ServiceSearchFormData, ServiceSearchFormFragmentProps } from './ServiceSearchFormFragment.props';
import { useGetServiceSearchData } from '../../../../../../queries/interactions-query';
import { COLORS } from '../../../../../../configs/colors';
import { ServiceObject } from '../../../../../../@types';
import { Loader, LoaderColorProps } from '../../../../../../components/atoms/Loader';

export const ServiceSearchFormFragment: React.FC<ServiceSearchFormFragmentProps> = ({
  service,
  preSelectedData,
  value,
  onChange,
  isError
}) => {
  const getServiceSearchData = useGetServiceSearchData(service.id, preSelectedData || {});

  useEffect(() => {
    if (getServiceSearchData.data) {
      const services: Array<ServiceSearchFormData> = [];
      getServiceSearchData.data.forEach((service: any) => {
        const serviceData = {
          serviceId: service.id,
          isSelected: false
        };
        services.push(serviceData);
      });
      onChange(services);
    }
  }, [getServiceSearchData.data]);

  const getServiceValue = (serviceId: number) => {
    return value.findIndex((service) => service.serviceId === serviceId);
  };

  const selectService = (serviceId: number, isSelectedValue: boolean) => {
    const updatedServicesList = value.map((service) => {
      if (service.serviceId === serviceId) {
        service.isSelected = isSelectedValue;
      }
      return service;
    });
    onChange(updatedServicesList);
  };

  return (
    <Stack spacing={2}>
      <Typography
        variant={TypographyVariantProps.Body2}
        fontWeight={TypographyFontWeightProps.Bold}
      >
        Service Search
      </Typography>
      {getServiceSearchData.isFetching && <Loader color={LoaderColorProps.Primary} />}
      {!getServiceSearchData.isFetching && value && getServiceSearchData.data?.map((serviceData: ServiceObject, index: number) => 
        <Stack key={index}>
          <CheckItem
            label={`Service #${serviceData.id} - ${serviceData?.mainLocation}${serviceData?.subLocation ? ' / ' + serviceData.subLocation : ''}`}
            value={value[getServiceValue(serviceData.id)]?.isSelected}
            onChange={(value) => selectService(serviceData.id, value)}
          />
        </Stack>
      )}
      {!getServiceSearchData.isFetching && !getServiceSearchData.data?.length && <Typography variant={TypographyVariantProps.Body2}>No matching services found</Typography>}
      {isError && !value.some((service) => service.isSelected) &&
       <Stack mt={1} textAlign="right"><Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography></Stack>
      }
    </Stack>
  );
};