import { httpClient, HttpMethod } from './http-client';
import {
  CheckInObject,
  CheckOutObject,
  LinkObject,
  Technician,
} from '../@types/service.type';

export const getServiceCheckIn = (id: number): CheckInObject => {
  return httpClient(`/services/${id}/checkins/single`, HttpMethod.Get, null);
};

export const createServiceCheckIn = (data: CheckInObject): CheckInObject => {
  return httpClient('/checkin', HttpMethod.Post, data);
};

export const updateServiceCheckOut = (id: number, data: CheckOutObject): CheckInObject => {
  return httpClient(`/checkin/${id}/checkout`, HttpMethod.Put, data);
};

export const getServiceListByTechnician = (): LinkObject => {
  return httpClient('/checkin/sibling/services', HttpMethod.Get, null);
};

export const getTechnicianListByLocationNodeId = (locationNodeId: number): Technician => {
  return httpClient(`/checkin/technicians/${locationNodeId}`, HttpMethod.Get, null);
};

export const createBulkServiceCheckInQuery = (data: CheckInObject[]): CheckInObject[] => {
  return httpClient('/bulkcheckin', HttpMethod.Post, data);
};