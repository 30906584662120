import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const serviceColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Notification Configs',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Name',
        field: 'name',
        value: true
      },
      {
        title: 'Client/Contract',
        field: 'clientcontract',
        value: true
      },
      {
        title: 'Service Type',
        field: 'serviceType',
        value: true
      },
      {
        title: 'Action Type',
        field: 'actionType',
        value: true
      },
      {
        title: 'Process Type',
        field: 'processType',
        value: true
      },
      {
        title: 'Priority',
        field: 'priority',
        value: true
      },
      {
        title: 'Region',
        field: 'region',
        value: true
      },
      {
        title: 'Status',
        field: 'status',
        value: true
      },
      {
        title: 'Event',
        field: 'event',
        value: true
      },
      {
        title: 'Message Type',
        field: 'messageType',
        value: true
      },
      {
        title: 'Contact Record Type',
        field: 'contactRecordType',
        value: true
      },
      {
        title: 'Template',
        field: 'template',
        value: true
      },
      {
        title: 'Email Addresses',
        field: 'emailAddresses',
        value: true
      },
      {
        title: 'Phone Numbers',
        field: 'phoneNumbers',
        value: true
      },
      {
        title: 'Consider Traing Hours',
        field: 'considerTradingHours',
        value: true
      },
      {
        title: 'Add Service Log',
        field: 'addServiceLog',
        value: true
      },
      {
        title: 'Edit Option',
        field: 'action',
        value: true
      }
    ]
  }
];

export const shipmentColumnSelectionItems: Array<ColumnSelectionItem> = [
  {
    title: 'Notification Configs',
    value: [
      {
        title: 'ID',
        field: 'id',
        value: true
      },
      {
        title: 'Name',
        field: 'name',
        value: true
      },
      {
        title: 'Client/Contract',
        field: 'clientcontract',
        value: true
      },
      {
        title: 'Shipment Type',
        field: 'shipmentType',
        value: true
      },
      {
        title: 'Courier',
        field: 'courier',
        value: true
      },
      {
        title: 'Shipment Status',
        field: 'shipmentStatus',
        value: true
      },
      {
        title: 'Event',
        field: 'event',
        value: true
      },
      {
        title: 'Message Type',
        field: 'messageType',
        value: true
      },
      {
        title: 'Contact Record Type',
        field: 'contactRecordType',
        value: true
      },
      {
        title: 'Template',
        field: 'template',
        value: true
      },
      {
        title: 'Email Addresses',
        field: 'emailAddresses',
        value: true
      },
      {
        title: 'Phone Numbers',
        field: 'phoneNumbers',
        value: true
      },
      {
        title: 'Consider Traing Hours',
        field: 'considerTradingHours',
        value: true
      },
      {
        title: 'Add Service Log',
        field: 'addServiceLog',
        value: true
      },
      {
        title: 'Edit Option',
        field: 'action',
        value: true
      }
    ]
  }
];