import { TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';

export const Topic = styled(Typography)`
  font-size: 18px;
  line-height: 28.8px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border: 1px solid #eeeeee;
  border-radius: 5px;
  max-height: 49vh;
  overflow: auto;
`;