import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import { ActionPermissions, ClientContractData, CustomPermission, FaultDefinition, NodeType, ServiceStatusTransitionsType, SizeProps, Tag } from '../../../@types';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { ContactData } from '../../../components/molecules/Contact';
import { ContractBrandList, ContractBrandDataType, EditBrandDataType } from '../../../components/molecules/ContractBrandList';
import { Model, ModelProps } from '../../../components/molecules/Model';
import { TradingHourTypes } from '../../../components/molecules/TradingHours';
import { AddEditClientContract, AddEditClientContractData } from '../../../components/organisms/AddEditClientContract';
import { Coverage, CoverageProps, MultiSelectDropdownProps } from '../../../components/organisms/Coverage';
import { FaultDefinitaionsProps, FaultDefinitions } from '../../../components/organisms/FaultDefinitions';
import { PriorityAndService, PriorityAndServiceProps, PriorityServiceData } from '../../../components/organisms/PriorityAndService';
import { AddressDataType } from '../../../components/organisms/StructureAddress';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags, TagsProps } from '../../../components/organisms/Tags';
import { FormListAccordian } from '../../../components/templates/FormListAccordian';
import { Topic } from '../../../components/templates/FormListAccordian/FormListAccordian.styles';
import { ContactDataType, StructureAddressContactLayout } from '../../../components/templates/StructureAddressContactLayout';
import { EntityType, NodeAttributeType, PropertyType as PropertyTypeEnum, StructureTypeCategory } from '../../../configs/enums';
import { useCreateStructure, useGetNodeAttributesByCustomRange, useGetNodeByName, useGetNodeById, useGetNodeChildrenByNodeType, useUpdateStructure } from '../../../queries/structure-query';
import { getAllTags, useAddTags } from '../../../queries/tags-query';
import { contactType } from './AddClientData.data';
import { WrapperBox } from './AddEditClient.styles';
import { isEmptyString } from '../../../utils/common';
import { useGetAllServiceTypes } from '../../../queries/service-type-query';
import { DropdownItem } from '../../../components/atoms/SelectMenu';
import { defaultColors } from '../../../configs/colors';
import { useGetRegionTypes } from '../../../queries/regionTypes-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { NotFoundError } from '../../Error/NotFound';
import { SiteSchemaFragment } from './SiteSchemaFragment';
import { useGetPartTypesByClientContract } from '../../../queries/part-type-query';
import { SERVICE_CREATION_RULE_TYPES } from '../../../constants/structure';
import { FleetTagFragment } from './FleetTagFragment';
import { FleetTagDataset, FleetTagObject } from '../../../@types/fleet-tag.type';
import { ALL_KEYWORD, ALL_KEYWORD_CAPITAL } from '../../../constants/common';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { ServiceStatusTransitionsFragment } from './ServiceStatusTransitionsFragment';
import { sortStatusTransitionRules, validateStatusTransitionRule } from '../../../utils/structure';
import { AdditionalConfigFragment } from './AdditionalConfigFragment/AdditionalConfigFragment';
import { StructureAdditionalConfigData } from '../../../@types/structureAdditionalConfig.type';

export const AddEditClient: React.FC = () => {
  const { id } = useParams();
  const { type } = useParams();
  const isContract = type === 'contract';
  let isNew = id === 'create';
  const idInt = (id && !isNew) ? parseInt(id) : -1;
  const navigate = useNavigate();
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [contactForm, setContactForm] = useState<any[]>([]);
  const [addressForm, setAddressForm] = useState<any[]>([]);

  const [tags, setTags] = useState<TagsProps>({
    allTags: [...allTags],
    selectedTags: [...selectedTags],
    newTags: [...newTags],
    setNewTags: (val: Tag[]) => {
      setNewTags([...newTags, ...val]);
    },
    setSeletedTags: (val: (AutocompleteTagType | string)[]) => {
      setSelectedTags([...selectedTags, ...val]);
    }
  });

  const [clientValue, setClientValue] = useState<AddEditClientContractData>({
    name: '',
    shortName: '',
    enabled: false,
    logoPic: '',
    tags: { ...tags }
  });

  const [hasChildNodes, setHasChildNodes] = useState(false);
  const [defaultNode, setDefaultNode] = useState({
    name: '',
    parentNodeId: ''
  });
  const [contractValues, setContractValues] = useState<ContractBrandDataType[]>([]);
  const [contractFormValues, setContractFormValues] = useState<ContractBrandDataType[]>([]);
  const [editBrandValue, setEditBrandValue] = useState<EditBrandDataType[]>([]);
  const [addressData, setAddressData] = useState<AddressDataType[]>([]);
  const [editAddressData, setEditAddressData] = useState<AddressDataType>();
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [structureAddressDataset, setStructureAddressDataset] = useState<AddressDataType[]>([]);
  const [structureContactDataset, setStructureContactDataset] = useState<ContactDataType[]>([]);
  const [fleetTagState, setFleetTagState] = useState<FleetTagDataset>({
    data: [],
    values: [],
  });
  const [editContactData, setEditContactData] = useState<ContactData>();
  const [serviceTypeList, setServiceTypeList] = useState<DropdownItem[]>([]);
  const [prioritiesItemsList, setPrioritiesItemsList] = useState<MultiSelectDropdownProps[]>([]);
  const [parentNodeId, setParentNodeId] = useState<number>();
  const [activeServiceTypes, setActiveServiceTypes] = useState<DropdownItem[]>([]);
  const [regionTypeList, setRegionTypeList] = useState<DropdownItem[]>([]);
  const [logoPicDetails, setLogoPicDetails] = useState<FileList>();
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);
  const [parentPrioritiesItemsList, setParentPrioritiesItemsList] = useState<MultiSelectDropdownProps[]>([]);
  const [serviceStatusTransitionsRules, setServiceStatusTransitionsRules] = useState<ServiceStatusTransitionsType[]>([]);
  const [transitionRulesError, setTransitionRulesError] = useState(false);
  const [additonalConfig, setAdditionalConfig] = useState<StructureAdditionalConfigData>({ serviceTasksConfigs: [] });

  const regionTypesQuery = useGetRegionTypes();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  TabHandler(isNew ? `Add Client | ${PLATFORM_NAME}` : (isContract ? `Contract: ${id} | Contracts | ${PLATFORM_NAME}` : `Client: ${id} | Clients | ${PLATFORM_NAME}`));

  useEffect(() => {
    if (getUserPermissionsQuery.isSuccess && getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.isLoading, getUserPermissionsQuery.isSuccess]);

  const onClickPicture = async (fileList: FileList) => {
    setLogoPicDetails({ ...fileList });
    setClientValue({
      ...clientValue,
      logoPic: URL.createObjectURL(fileList[0])
    });
  };

  useEffect(() => {
    if (regionTypesQuery.data) {
      const regionTypesItems: DropdownItem[] = [];
      const wildCard = {
        value: 'any',
        label: 'Any'
      };
      regionTypesItems.push(wildCard);
      regionTypesQuery?.data?.map((item) => {
        const regionType = {
          value: item.code,
          label: item.name
        };
        regionTypesItems.push(regionType);
      });
      setRegionTypeList(regionTypesItems);
    }
  }, [regionTypesQuery.data]);

  const priorityConfStaticData = {
    serviceTypes: serviceTypeList,
    serviceCreationTypes: SERVICE_CREATION_RULE_TYPES,
    onChange: (data: PriorityServiceData) => {
      data;
    }
  };

  const [priorityConfValues, setPriorityConfValues] = useState<PriorityAndServiceProps[]>([]);

  const coverageStaticData = {
    serviceTypeItems: [
      { value: 'any', label: 'Any' }
    ],
    prioritiesItems: prioritiesItemsList,
    regionTypeItems: regionTypeList,
    onChange: (data: any) => {
      data;
    }
  };
  const [coverageValues, setCoverageValues] = useState<CoverageProps[]>([]);

  const [modelStaticData, setModelStaticData] = useState<ModelProps>({
    switchLabel: 'Enabled',
    multiSelectData: {
      size: SizeProps.Small,
      id: 'test-menu',
      label: 'Part Type Selection',
      items: [],
    },
  });

  const [modelValues, setModelValues] = useState<ModelProps[]>([]);

  const faultStaticData: any = {
    onChangeChildDefinitions: (childFaults: FaultDefinition[], parentIndex: number) => {
      setFaultValues(prev => {
        const element = prev[0].faults[parentIndex];
        element.childFaults = childFaults;

        return [...prev];
      });
    },
    onChangeHeaderDefinition: (parentFault: FaultDefinition, index: number) => {
      setFaultValues(prev => {
        prev[0].faults[index] = { ...parentFault, childFaults: prev[0].faults[index].childFaults };
        return [...prev];
      });
    }
  };

  const [faultValues, setFaultValues] = useState<FaultDefinitaionsProps[]>(
    [
      {
        ...faultStaticData,
        faults: []
      }
    ]
  );

  const [allClientData, setAllClientData] = useState({
    clientData: {
      name: clientValue.name,
      logoPic: logoPicDetails || '',
      code: clientValue.shortName,
      isActive: clientValue.enabled
    },
    structureAddressData: [],
    structureContactData: [...structureContactDataset],
    contractData: [...contractValues],
    fleetTagData: [...fleetTagState.values],
    priorityConfData: priorityConfValues.map((value) => value.serviceData),
    coverageData: coverageValues.map((value) => value.values),
    modelData: modelValues.map((value) => value.modelData),
    faultData: faultValues.map((value) => value.faults)
  });

  const [nodeAttributeTypes] = useState<NodeAttributeType[]>([NodeAttributeType.PriorityAndServices]);
  const [openToast, setOpenToast] = useState(false);
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [applicableNodeIds, setApplicableNodeIds] = useState<ClientContractData>({
    clientId: 0,
  });

  const addTagsQuery = useAddTags();
  const clientTagsQuery = getAllTags(EntityType.TYPE_CLIENTSCONTRACTS);
  const createStructure = useCreateStructure();
  const updateStructure = useUpdateStructure();
  const getAllServiceTypes = useGetAllServiceTypes();
  const getNodeData = useGetNodeById(id || '', StructureTypeCategory.System);
  const getClientDataInNodes = useGetNodeChildrenByNodeType(id ?? '', !isContract ? NodeType.Contract : NodeType.Brand);
  const getAttributesInNodeQuery = useGetNodeAttributesByCustomRange(id ?? '', nodeAttributeTypes);
  const getPartTypesByClientContract = useGetPartTypesByClientContract(applicableNodeIds.clientId, applicableNodeIds.contractId);
  const getNodeByClientNameQuery = useGetNodeByName(clientValue?.name || '', NodeType.Client);
  const getNodeByContractNameQuery = useGetNodeByName(clientValue?.name || '', NodeType.Contract, !isNew && defaultNode.parentNodeId ? parseInt(defaultNode.parentNodeId) : undefined);
  const getAttributesInParentNodeQuery = useGetNodeAttributesByCustomRange(defaultNode.parentNodeId ?? '', nodeAttributeTypes);

  useEffect(() => {
    if(getAttributesInNodeQuery.data && (getAttributesInNodeQuery.isSuccess || getAttributesInNodeQuery.isRefetching)) {
      const prioritiesItemsArray : DropdownItem[] = [];
      const wildCard = {
        value: 'any',
        label: 'Any'
      };
      prioritiesItemsArray.push(wildCard);
      getAttributesInNodeQuery?.data?.filter((active: any) => active.value.isActive && active.propertyTypeCode === NodeAttributeType.PriorityAndServices).map((item: any) => {
        const priorityItem = {
          value: item.id.toString(),
          label: item.value.shortName,
          serviceTypes: item.value.selectedServiceTypeValue
        };
        prioritiesItemsArray.push(priorityItem);
      });
      setPrioritiesItemsList(prioritiesItemsArray);
    }
  }, [getAttributesInNodeQuery.isSuccess, getAttributesInNodeQuery.isLoading, getAttributesInNodeQuery.isRefetching]);

  useEffect(() => {
    //For explicit service status transitions to retrieve client priorities if only contract priorities aren't available in node attributes
    if (defaultNode.parentNodeId && getNodeData.data?.nodeTypeCode === NodeType.Contract && getAttributesInNodeQuery.data?.length && 
        !getAttributesInNodeQuery.data?.filter((attribute: any) => attribute.propertyTypeCode === NodeAttributeType.PriorityAndServices)?.length
    ) {
      getAttributesInParentNodeQuery.refetch();
    }
  }, [getAttributesInNodeQuery.data, defaultNode]);

  useEffect(() => {
    if (getAttributesInParentNodeQuery.data) {
      const prioritiesItemsArray : DropdownItem[] = [];
      const wildCard = {
        value: 'any',
        label: 'Any'
      };
      prioritiesItemsArray.push(wildCard);
      getAttributesInParentNodeQuery?.data?.filter((active: any) => active.value.isActive && active.propertyTypeCode === NodeAttributeType.PriorityAndServices).map((item: any) => {
        const priorityItem = {
          value: item.id.toString(),
          label: item.value.shortName,
          serviceTypes: item.value.selectedServiceTypeValue
        };
        prioritiesItemsArray.push(priorityItem);
      });
      setParentPrioritiesItemsList(prioritiesItemsArray);
    }
  }, [getAttributesInParentNodeQuery.data]);

  useEffect(() => {
    if (getAllServiceTypes.data) {
      setActiveServiceTypes(getAllServiceTypes?.data.filter((type: any) => type.isActive).map((type: any) => ({ value: type.code, label: type.name })));

      const serviceTypeItems: DropdownItem[] = [];
      const wildCard = {
        value: ALL_KEYWORD,
        label: ALL_KEYWORD_CAPITAL
      };
      serviceTypeItems.push(wildCard);
      getAllServiceTypes?.data.map((item: any) => {
        if (item.code !== 'new') {
          const status = {
            value: item.code,
            label: item.name
          };
          serviceTypeItems.push(status);
        }
      });
      setServiceTypeList(serviceTypeItems);
    }
  }, [getAllServiceTypes.data]);

  useEffect(() => {
    setCoverageValues(prev => {
      return prev.map(item => {
        item.serviceTypeItems = [...coverageStaticData.serviceTypeItems, ...activeServiceTypes];
        return item;
      });
    });
  }, [activeServiceTypes]);

  useEffect(() => {
    if (!isNew && id) {
      getNodeData.refetch();
      getClientDataInNodes.refetch();
      getAttributesInNodeQuery.refetch();
    }
  }, [id]);

  useEffect(() => {
    if (applicableNodeIds.clientId > 0) {
      getPartTypesByClientContract.refetch();
    }
  }, [applicableNodeIds]);
  
  useEffect(() => {
    if (getPartTypesByClientContract.data) {
      const partTypeItems: DropdownItem[] = [];
      getPartTypesByClientContract.data.map((item) => {
        const partType = {
          value: item.id.toString(),
          label: item.name
        };
        partTypeItems.push(partType);
      });
      const dataForModels = {
        switchLabel: 'Enabled',
        multiSelectData: {
          size: SizeProps.Small,
          id: 'test-menu',
          label: 'Part Type Selection',
          items: partTypeItems,
        },
      };
      setModelStaticData(dataForModels);
      setModelValues(modelValues.map((value) => {
        return {
          ...value,
          ...dataForModels
        };
      }));
    }
  }, [getPartTypesByClientContract.data]);

  useEffect(() => {
    if (getClientDataInNodes.data) {
      setContractValues([]);
      if (getClientDataInNodes?.data.length > 0) {
        setHasChildNodes(true);
      }

      if (!isNew && id && !isContract) {
        getClientDataInNodes.data && getClientDataInNodes.data.map((contract: any) => {
          setContractValues(prev => [...prev, {
            id: contract.id,
            name: contract.name,
            logoPic: logoPicDetails || '',
            shortName: contract.code,
            isEnable: contract.isActive
          }]);
        });
        getClientDataInNodes.data.length === 0 && setContractFormValues([{
          name: '',
          shortName: '',
          isEnable: true
        }]);
      } else if (!isNew && id && isContract) {
        getClientDataInNodes.data && getClientDataInNodes.data.map((brand: any) => {
          setContractValues(prev => [...prev, {
            id: brand.id,
            name: brand.name,
            shortName: '',
            isEnable: brand.isActive
          }]);
        });
        getClientDataInNodes.data.length === 0 && setContractFormValues([{
          name: '',
          shortName: '',
          isEnable: true
        }]);
      }
    }
  }, [getClientDataInNodes.data]);

  useEffect(() => {
    if (!isNew && id) {
      if (getNodeData.isSuccess) {
        const data = getNodeData.data;
        setSelectedTags(mapSavedTags(data.tags));
        setParentNodeId(parseInt(data.parentNodeId));
        setApplicableNodeIds({
          clientId: isContract ? parseInt(data.parentNodeId) ?? -1 : parseInt(id ?? '-1'),
          contractId: isContract ? parseInt(id ?? '-1') : undefined
        });
        setClientValue({
          name: data.name,
          logoPic: data.logoPic,
          shortName: data.code,
          enabled: data.isActive,
          tags: {
            allTags: [...allTags],
            selectedTags: [...selectedTags],
            newTags: [...newTags],
            setNewTags: (val: Tag[]) => {
              setNewTags([...newTags, ...val]);
            },
            setSeletedTags: (val: (AutocompleteTagType | string)[]) => {
              setSelectedTags([...selectedTags, ...val]);
            }
          }
        });
        setDefaultNode({ name: data?.name || '', parentNodeId: data?.parentNodeId || '' });

        resetAttributeData();
        let faultData: FaultDefinitaionsProps[] = [
          {
            ...faultStaticData,
            faults: []
          }
        ];

        if (data.attributes && data.attributes.length > 0) {
          const attributes = data.attributes;
          const statusTransitionRules: ServiceStatusTransitionsType[] = [];

          attributes.forEach((attribute: any) => {
            const { id, propertyTypeCode, value } = attribute;

            if (propertyTypeCode === PropertyTypeEnum.Contact && value?.isActive) {
              setStructureContactDataset(prev => [...prev, { ...value, id: id }]);
            }

            if (propertyTypeCode === PropertyTypeEnum.FleetTag) {
              setFleetTagState(prevState => ({
                ...prevState,
                data: [...prevState.data, { ...value, id, identifier: id.toString() }]
              }));
            }
            if (propertyTypeCode === PropertyTypeEnum.Address && value?.isActive) {
              setStructureAddressDataset(prev => [...prev, {
                ...value,
                id: id,
                name: value.addressName
              }]);
            }

            if (propertyTypeCode === PropertyTypeEnum.PriorityAndService) {
              setPriorityConfValues(prev => [...prev, {
                ...priorityConfStaticData,
                serviceData: { ...value, id: id }
              }]);
            }

            if (propertyTypeCode === PropertyTypeEnum.Coverage) {
              setCoverageValues(prev => [...prev, {
                ...coverageStaticData,
                serviceTypeItems: [...coverageStaticData.serviceTypeItems, ...activeServiceTypes],
                values: { ...value, id: id }
              }]);
            }

            if (propertyTypeCode === PropertyTypeEnum.Model) {
              setModelValues(prev => [...prev, {
                ...modelStaticData,
                modelData: { ...value, id: id }
              }]);
            }

            if (propertyTypeCode === PropertyTypeEnum.FaultCategory) {
              const updated = [...faultData];
              updated[0].faults.push({
                ...value.faults,
                id: id,
                childFaults: attributes.reduce((accumulator: any[], currentValue) => {
                  if (currentValue.propertyTypeCode === PropertyTypeEnum.FaultCode && currentValue.value.categoryId === id) {
                    return [...accumulator, { ...currentValue.value, id: currentValue.id }];
                  }
                  return accumulator;
                }, [])
              });
              faultData = [...updated];
            }

            if (propertyTypeCode === PropertyTypeEnum.ServiceStatusTransitions) {
              statusTransitionRules.push({ ...value, id: id });
            }
            
            if(propertyTypeCode === PropertyTypeEnum.AdditionalConfigs) {
              setAdditionalConfig({ id, ...value });
            }
          });
          setServiceStatusTransitionsRules(sortStatusTransitionRules(statusTransitionRules)); // sort status transition rules by service type and status
        }

        setFaultValues([...faultData]);
      }
    }
  }, [getNodeData.data, serviceTypeList]);

  useEffect(() => {
    if (clientTagsQuery.data) {
      setAllTags(clientTagsQuery.data);
    }
  }, [clientTagsQuery.data]);

  useEffect(() => {
    setTags({
      ...tags,
      allTags: allTags,
      selectedTags: selectedTags,
      newTags: newTags
    });
  }, [selectedTags, newTags, allTags]);

  useEffect(() => {
    (tags.allTags?.length || tags.selectedTags?.length || tags.newTags?.length) && setClientValue({
      ...clientValue,
      tags: tags
    });
  }, [tags]);

  useEffect(() => {
    setAllClientData({
      ...allClientData,
      clientData: {
        name: clientValue.name,
        logoPic: logoPicDetails || '',
        code: clientValue.shortName,
        isActive: clientValue.enabled
      }
    });
  }, [clientValue]);

  const getAttributes = () => {
    const attributes: any[] = [];
    const priorityConfData = priorityConfValues.map((value) => value.serviceData);
    const coverageData = coverageValues.map((value) => value.values);
    const modelData = modelValues.map((value) => value.modelData);
    const faultData = faultValues[0].faults.map((value) => { return { faults: value }; });

    if (structureAddressDataset && structureAddressDataset.length) {
      structureAddressDataset.forEach(address => {
        attributes.push({
          ...address,
          addressName: address.name,
          name: 'Address',
          propertyTypeCode: PropertyTypeEnum.Address
        });
      });
    }

    if (structureContactDataset && structureContactDataset.length) {
      structureContactDataset.forEach(contact => {
        attributes.push({
          ...contact,
          name: 'Contact',
          propertyTypeCode: PropertyTypeEnum.Contact
        });
      });
    }

    if (priorityConfData && priorityConfData.length) {
      priorityConfData.filter(priority => priority?.shortName).forEach(priorityAndService => {
        attributes.push({
          ...priorityAndService,
          selectedServiceTypeValue: priorityAndService?.selectedServiceTypeValue?.filter((filteredData) => filteredData !== ALL_KEYWORD),
          name: 'Priority And Service',
          propertyTypeCode: PropertyTypeEnum.PriorityAndService
        });
      });
    }

    if (coverageData && coverageData.length) {
      coverageData.filter(coverage => coverage.selectedServiceTypes.length > 0).forEach(coverage => {
        attributes.push({
          ...coverage,
          name: 'Coverage',
          propertyTypeCode: PropertyTypeEnum.Coverage
        });
      });
    }

    if (modelData && modelData.length) {
      modelData.filter(model => model?.clientModelName).forEach(model => {
        const filteredPartypes = model?.partType.filter((item) => modelStaticData.multiSelectData.items.some((obj) => obj.value === item));
        attributes.push({
          ...model,
          partType: filteredPartypes,
          name: 'Model',
          propertyTypeCode: PropertyTypeEnum.Model
        });
      });
    }

    if (faultData && faultData.length) {
      faultData.filter(fault => fault.faults.name).forEach(fault => {
        attributes.push({
          ...fault,
          name: 'Faults',
          propertyTypeCode: PropertyTypeEnum.FaultCategory,
        });
      });
    }

    fleetTagState?.values?.filter(fleetTag => fleetTag.fleetTagName).forEach(fleetTag => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { identifier, ...fleetTagData } = fleetTag;
      attributes.push({
        ... fleetTagData,
        name: 'Fleet Tag',
        propertyTypeCode: PropertyTypeEnum.FleetTag,
      });
    });

    serviceStatusTransitionsRules?.forEach((transitionRule) => {
      attributes.push({
        ...transitionRule,
        name: 'Service Status Transitions',
        propertyTypeCode: PropertyTypeEnum.ServiceStatusTransitions,
      });
    });
    
    if(additonalConfig?.serviceTasksConfigs.length) {
      attributes.push({
        ...additonalConfig,
        name: 'Additional Configs',
        propertyTypeCode: PropertyTypeEnum.AdditionalConfigs
      });
    }

    return attributes;
  };

  const validated = () => {
    if (isEmptyString(clientValue?.name as string)) {
      setClientValue({
        ...clientValue,
        nameHelperText: 'Name is required'
      });
      return false;
    } else if (isEmptyString(clientValue?.shortName as string)) {
      setClientValue({
        ...clientValue,
        shortNameHelperText: 'Short name is required'
      });
      return false;
    } else if (clientValue?.name !== defaultNode.name &&
      ((!isContract && getNodeByClientNameQuery.data) || (isContract && getNodeByContractNameQuery.data))) {
      setClientValue({
        ...clientValue,
        nameHelperText: 'Name already exist'
      });
      return false;
    } else if (!validateStatusTransitionRule(serviceStatusTransitionsRules)) {
      setTransitionRulesError(true);
      return false;
    }
    return true;
  };

  const onHandleSave = async () => {
    setTransitionRulesError(false);
    setPriorityConfValues([...priorityConfValues]);
    setCoverageValues([...coverageValues]);
    setModelValues([...modelValues]);
    setFaultValues([...faultValues]);

    if (!validated()) return;

    setAllClientData({
      clientData: {
        name: clientValue.name,
        logoPic: logoPicDetails || '',
        code: clientValue.shortName,
        isActive: clientValue.enabled
      },
      structureAddressData: [],
      structureContactData: [...structureContactDataset],
      contractData: [...contractValues],
      fleetTagData: fleetTagState?.values || [],
      priorityConfData: priorityConfValues.map((value) => value.serviceData),
      coverageData: coverageValues.map((value) => value.values),
      modelData: modelValues.map((value) => value.modelData),
      faultData: faultValues.map((value) => value.faults)
    });

    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    setAllClientData({ ...allClientData });

    let filteredContractValues = [...contractValues];
    let edittedValues: ContractBrandDataType[] = [];
    
    if (hasChildNodes) {
      filteredContractValues = contractValues.filter((value) => getClientDataInNodes.data && !getClientDataInNodes.data.some((item: any) =>
        item.id === value.id
      ));
      edittedValues = contractValues.filter((value) => getClientDataInNodes.data && getClientDataInNodes.data.some((item: any) =>
        (item.name !== value.name || item.isActive !== value.isEnable) && item.id === value.id
      ));
    }

    if (isNew) {
      const client: any = await createStructure.mutateAsync({
        ...allClientData.clientData,
        parentNodeId: null,
        nodeTypeCode: 'client',
        attributes: getAttributes()
      });

      await addTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_CLIENTSCONTRACTS,
        entityId: client.id,
        freshTags,
        existingTags
      });

      navigate(`/configure/clients/client/${client.id}`);
    } else if (!isContract) {
      let savedContract: any;
      filteredContractValues.length > 0 && await Promise.all(filteredContractValues.map(async (contractData) => {
        const contract = {
          name: contractData.name,
          code: contractData.shortName,
          isActive: contractData.isEnable
        };
        savedContract = await createStructure.mutateAsync({
          ...contract,
          parentNodeId: id,
          nodeTypeCode: 'contract',
          attributes: []
        });
      }));

      edittedValues.length > 0 && await Promise.all(edittedValues.map(async (contractData) => {
        const brand = {
          name: contractData.name,
          code: contractData.name,
          isActive: contractData.isEnable
        };
        await updateStructure.mutateAsync({
          ...brand,
          id: contractData.id,
          parentNodeId: id,
          attributes: []
        });
      }));

      await updateStructure.mutateAsync({
        ...allClientData.clientData,
        isActive: !hasChildNodes && (savedContract && savedContract.id) ? true : clientValue.enabled,
        id: id,
        parentNodeId: null,
        attributes: getAttributes()
      });
      await addTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_CLIENTSCONTRACTS,
        entityId: id,
        freshTags,
        existingTags
      });

      getNodeData.refetch();

    } else if (isContract) {
      const contract: any = await updateStructure.mutateAsync({
        ...allClientData.clientData,
        id: id,
        parentNodeId: parentNodeId,
        attributes: getAttributes()
      });
      await addTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_CLIENTSCONTRACTS,
        entityId: contract.id,
        freshTags,
        existingTags
      });

      edittedValues.length > 0 && edittedValues.map(async (brandData) => {
        const brand = {
          name: brandData.name,
          code: brandData.name,
          isActive: brandData.isEnable
        };
        await updateStructure.mutateAsync({
          ...brand,
          id: brandData.id,
          parentNodeId: id,
          attributes: []
        });
      });

      filteredContractValues.length > 0 && filteredContractValues.map(async (brandData) => {
        const brand = {
          name: brandData.name,
          code: brandData.name,
          isActive: brandData.isEnable
        };
        await createStructure.mutateAsync({
          ...brand,
          parentNodeId: id,
          nodeTypeCode: 'brand',
          attributes: []
        });
      });
      getNodeData.refetch();
    }
    getClientDataInNodes.refetch();
    getAttributesInNodeQuery.refetch();
    setOpenToast(true);
  };

  const resetAttributeData = () => {
    setFaultValues([
      {
        ...faultStaticData,
        faults: []
      }
    ]);
    setStructureContactDataset([]);
    setFleetTagState({ data: [], values: [] });
    setStructureAddressDataset([]);
    setPriorityConfValues([]);
    setCoverageValues([]);
    setModelValues([]);
    setServiceStatusTransitionsRules([]);
  };

  const checkDuplicates = (value: ContractBrandDataType, currentIndex: number) => {
    return !contractValues?.some((children: any, index: number) => children.name === value.name && (currentIndex > -1 ? index !== currentIndex : true));
  };

  if (!isNew && getClientDataInNodes && getClientDataInNodes.isLoading) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }

  if (clientTagsQuery.isLoading) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }

  if (!isNew && getNodeData && getNodeData.isLoading) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }

  if (id === 'create' && isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_Create, permissions)) {
    isNew = true;
  } else if (getNodeData.data && isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_Edit, permissions)) {
    isNew = false;
  } else {
    return getNodeData.isFetching ? <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid> : <NotFoundError></NotFoundError>;
  }

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        {isNew ? 'Add Client' : (isContract ? `Update Contract: ${clientValue.name}` : `Update Client: ${clientValue.name}`)}
      </Typography>
      <WrapperBox>
        <Grid container spacing={isNew ? 0 : 2} xs={12}>
          <Grid item xs={isNew ? 12 : 4}>
            <AddEditClientContract
              value={{ ...clientValue }}
              isNew={isNew}
              disableToggle={!hasChildNodes}
              onChange={(data: AddEditClientContractData) => {
                setClientValue({ ...data });
              }}
              onClickPicture={onClickPicture}
            />
          </Grid>
          {isNew || <Grid item xs={8} >
            <ContractBrandList
              formItems={contractFormValues}
              gridItems={contractValues}
              isContract={!isContract}
              onChange={(value, index) => {
                const updated = [...contractFormValues];
                updated[index] = value;
                setContractFormValues(updated);
              }}
              onEditChange={(value, index) => {
                const updated = [...editBrandValue];
                updated[index] = value;
                setEditBrandValue(updated);
              }}
              handleIsEnableChange={(data, index) => {
                const updated = [...contractValues];
                updated[index] = data;
                setContractValues(updated);
              }}
              onClickAdd={() => {
                setContractFormValues([...contractFormValues, {
                  name: '',
                  shortName: '',
                  isEnable: true
                }]);
              }}
              editBrandDataValues={[...editBrandValue]}
              onClickBrandEdit={(index) => {
                const selectedValue: ContractBrandDataType[] = contractValues.filter((data, i) => i === index);
                setEditBrandValue([...editBrandValue, {
                  index: index,
                  value: selectedValue[0]
                }]);
              }}
              onClick={(index: number) => {
                if (editBrandValue?.some((data) => data.index === index)) {
                  const updated = [...contractValues];
                  updated[index] = editBrandValue?.filter((data) => data.index === index)[0].value;
                  if (!checkDuplicates(updated[index], index)) {
                    setShowWarningToast(true);
                    return;
                  }
                  setContractValues(updated);
                  let reducedArr = [...editBrandValue];
                  reducedArr = reducedArr.filter((data) => data.index !== index);
                  setEditBrandValue(reducedArr);
                } else if (checkDuplicates(contractFormValues[index], -1)) {
                  setContractValues([...contractValues, contractFormValues[index]]);
                  const reducedArr = [...contractFormValues];
                  reducedArr.splice(index, 1);
                  setContractFormValues(reducedArr);
                } else {
                  setShowWarningToast(true);
                }
              }} />
            <br />
            <StructureAddressContactLayout
              heading="Address"
              isAddress={true}
              renderNotes={false}
              contactType={contactType}
              addressDataset={structureAddressDataset ? structureAddressDataset : []}
              contactDataset={structureContactDataset}
              contactFormData={contactData}
              addressFormData={addressData}
              editAddressFormData={editAddressData}
              onClickAddressEdit={(event, index) => {
                const selectedValue: any = structureAddressDataset.splice(index, 1);
                setStructureAddressDataset(structureAddressDataset);
                setEditAddressData(selectedValue[0]);
              }}
              onClickAddressDelete={(event, index) => {
                const updatedData = [...structureAddressDataset];
                if (index > -1) updatedData[index].isActive = false;
                setStructureAddressDataset(updatedData);
              }}
              onStructureAddressChange={(value) => {
                setAddressData(value);
              }}
              handleAddressCreate={(index) => {
                if (index > -1) addressData[index].isActive = true;
                if (addressData?.[index]?.isManual) {
                  addressData[index] = { ...addressData[index], googleAddress: '' };
                }
                setStructureAddressDataset([...structureAddressDataset, addressData[index]]);
                addressData.splice(index, 1);
                setAddressData([...addressData]);
              }}
              addressForm={addressForm}
              setAddressForm={setAddressForm}
            />
            <StructureAddressContactLayout
              heading="Contact"
              isAddress={false}
              renderNotes={false}
              contactType={contactType}
              addressDataset={structureAddressDataset}
              contactDataset={structureContactDataset ? structureContactDataset : []}
              contactFormData={contactData}
              addressFormData={addressData}
              editContactFormData={editContactData}
              onClickContactEdit={(event, index) => {
                const selectedValue: any = structureContactDataset.splice(index, 1);
                setStructureContactDataset(structureContactDataset);
                setEditContactData(selectedValue[0]);
              }}
              onClickContactDelete={(event, index) => {
                const updatedData = [...structureContactDataset];
                if (index > -1) updatedData[index].isActive = false;
                setStructureContactDataset(updatedData);
              }}
              onStructureAddressChange={() => {'';
              }}
              onContactChange={(value) => {
                setContactData(value);
              }}
              handleContactCreate={(index) => {
                if (index > -1) contactData[index].isActive = true;
                setStructureContactDataset([...structureContactDataset, contactData[index]]);
                contactData.splice(index, 1);
                setContactData([...contactData]);
              }}
              contactForm={contactForm}
              setContactForm={setContactForm}
            />
            <AdditionalConfigFragment nodeId={idInt} additonalConfig={additonalConfig} setAdditonalConfig={setAdditionalConfig} />
          </Grid>}
        </Grid>
        {isNew || <>
          <Grid>
            <Topic variant={TypographyVariantProps.H6}>Additional Information</Topic>
          </Grid>
          <Grid my={1}>
            <FormListAccordian
              formData={priorityConfValues}
              title="Priority and Services Configuration"
              component={PriorityAndService}
              onChange={(data, index) => {
                const array = [...priorityConfValues];
                array[index].serviceData = data;
                setPriorityConfValues([...array]);
              }}
              onClickAdd={(event) => {
                event.stopPropagation();
                setPriorityConfValues([...priorityConfValues, {
                  ...priorityConfStaticData,
                  serviceData: {
                    shortName: '',
                    description: '',
                    color: defaultColors[0],
                    selectedServiceTypeValue: [],
                    selectedServiceCreationTypeValue: [],
                    isRestricted: false,
                    isActive: false
                  }
                }]);
              }}
            />
          </Grid>
          <Grid my={1}>
            <FormListAccordian
              formData={coverageValues}
              title="Coverage"
              component={Coverage}
              onChange={(data, index) => {
                const array = [...coverageValues];
                array[index].values = data;
                setCoverageValues([...array]);
              }}
              onClickAdd={(event) => {
                event.stopPropagation();
                setCoverageValues([...coverageValues, {
                  ...coverageStaticData,
                  serviceTypeItems: [...coverageStaticData.serviceTypeItems, ...activeServiceTypes],
                  values: {
                    selectedServiceTypes: [],
                    selectedPriorities: [],
                    selectedRegionTypes: [],
                    tradingHours: {
                      type: TradingHourTypes.AllDays,
                      start: '8:00am',
                      end: '5:00pm',
                      ph: false
                    },
                    checked: false
                  }
                }]);
              }}
            />
          </Grid>
          {isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_Edit, permissions) && 
          <Grid my={1}>
            <ServiceStatusTransitionsFragment
              serviceStatusTransitionsRules={serviceStatusTransitionsRules}
              prioritiesItemsList={getAttributesInNodeQuery?.data?.filter((attribute: any) => attribute.propertyTypeCode === NodeAttributeType.PriorityAndServices).length > 0 ? prioritiesItemsList : parentPrioritiesItemsList}
              onChange={(data) => setServiceStatusTransitionsRules(data)}
              error={transitionRulesError}
            />
          </Grid>
          }
          <Grid my={1}>
            <FormListAccordian
              formData={modelValues}
              title="Models"
              component={Model}
              onChange={(data, index) => {
                const array = [...modelValues];
                array[index].modelData = data;
                setModelValues([...array]);
              }}
              onClickAdd={(event) => {
                event.stopPropagation();
                setModelValues([...modelValues, {
                  ...modelStaticData,
                  modelData: {
                    clientModelName: '',
                    clientModelNumber: '',
                    clientModelCustomerId: '',
                    partType: [],
                    enabled: false
                  }
                }]);
              }}
            />
          </Grid>
          <Grid my={1}>
            <FormListAccordian
              formData={faultValues}
              title="Faults"
              component={FaultDefinitions}
              onChange={() => {
                faultValues;
              }}
              onClickAdd={(event) => {
                event.stopPropagation();
                faultValues[0].faults.push({
                  name: '',
                  customerId: '',
                  customerId2: '',
                  isActive: true,
                  childFaults: []
                });
                setFaultValues([...faultValues]);
              }}
            />
          </Grid>
          <Grid my={1}>
            <FleetTagFragment fleetTagDataset={fleetTagState?.data}
              onChange={(data: FleetTagObject[]) => setFleetTagState(prevState => ({ ...prevState, values: data }))}
            />
          </Grid>
          {isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_SiteSchema_View, permissions) && 
          <Grid my={1}>
            <SiteSchemaFragment nodeId={idInt} 
              hasEditPermissions={isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_SiteSchema_Edit, permissions)} 
              hasCreatePermissions={isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_SiteSchema_Create, permissions)}/>
          </Grid>
          }
        </>}
        <Grid item container xs={12} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button variant={ButtonVariantProps.Secondary}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Success} onClick={onHandleSave}>
              {isNew ? 'Create client' : 'Save edits'}
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={openToast}
          autoHideDuration={2000}
          message="Successfully Saved"
          onClose={() => { setOpenToast(false); }}
        />
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={8000} onClose={() => { setShowWarningToast(false); }}>
          <Alert onClose={() => { setShowWarningToast(false); }} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            <Box>Can not create {isContract ? 'brands' : 'contracts'} with same name</Box>
          </Alert>
        </Snackbar>
      </WrapperBox>
    </PageContainer>
  );
};
