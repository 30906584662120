import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { AddRole } from '../AddRole';
import { EditRole } from '../UpdateRole/EditRole';
import { Grid } from '@mui/material';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';

export const AddEditRole: React.FC = () => {
  const { id } = useParams();
  const [permissions, setPermissions] = useState<CustomPermission[] | null>(null);

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getUserPermissionsQuery.isSuccess && getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.isLoading, getUserPermissionsQuery.isSuccess]);

  if (!permissions) {
    return <Grid ml="570px"><Loader color={LoaderColorProps.Success} /></Grid>;
  }
  
  if(id === 'create' && isUserHasPermission(ActionPermissions.Configure_User_Roles_Create_General, permissions)) {
    return <AddRole />;
  } else if (isUserHasPermission(ActionPermissions.Configure_User_Roles_Edit_General, permissions)) {
    return <EditRole />;
  } else {
    return null;
  }
};