import { WarrantyData } from '../../../@types/partType.type';

export enum WarrantyTriggerTypes {
  Creation = 'creation',
  FirstInstallation = 'firstinstallation',
  None = 'none',
}

export interface WarrantyProps {
  value: WarrantyData;
  onChange: (value: WarrantyData) => void;
}