import { styled } from '@mui/material';
import { Typography } from '../../atoms/Typography';
import { DateTimePicker } from '../../atoms/DateTimePicker';

export const StyledTypography = styled(Typography)((props: { color: string; fontSize: string }) =>`
  width: 100%;
  color: ${props.color};
  font-size: ${props.fontSize};
  padding: 3px !important;
`);

export const StyledDateTimePicker = styled(DateTimePicker)`
  width: 100%;
  div {
    padding: 1px 2px !important;
  }
`;
