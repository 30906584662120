import React, { useEffect, useState } from 'react';
import { CustomPermission, Permission } from '../../../@types/permission.type';
import TreeModel from 'tree-model';
import { useParams } from 'react-router-dom';
import { ROOT_NODE } from '../../../configs/permissions';
import { PermissionView } from './PermissionView/PermissionView';
import { CustomPermissionsProps } from './CustomPermissions.props';
import { useGetRolePermissionsForUserEdit } from '../../../queries/roles-query';
import { useGetUserPermissions } from '../../../queries/user-query';

export const CustomPermissions: React.FC<CustomPermissionsProps> = ({
  roleId
}) => {
  const { id: userId } = useParams();
  const tree = new TreeModel();

  const [rolePermissions, setRolePermissions] = useState<Permission[] | null>(null);
  const [updatedRolePermissions, setUpdatedRolePermissions] = useState<Permission[] | null>(null);
  const [customPermissions, setCustomPermissions] = useState<CustomPermission[] | null>(null);

  const getRolePermissionsQurey = useGetRolePermissionsForUserEdit(Number(roleId));
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getUserPermissionsQuery = useGetUserPermissions(userId!);

  useEffect(() => {
    if (getRolePermissionsQurey.data) {
      setRolePermissions(getRolePermissionsQurey.data.children);
    }
  }, [getRolePermissionsQurey.isLoading, getRolePermissionsQurey.isSuccess]);

  useEffect(() => {
    if (getUserPermissionsQuery.data) {
      setCustomPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.isLoading, getUserPermissionsQuery.isSuccess]);

  useEffect(() => {
    if (rolePermissions && customPermissions) {
      const root = tree.parse({
        ...ROOT_NODE,
        children: structuredClone(rolePermissions)
      });

      // Update roles tree with selected custom permissions 
      root.walk((node) => {
        const permission = customPermissions.find(({ permissionId }) => permissionId === node.model.id);
        if (permission) {
          node.model.isGranted = permission.isGranted;
        }

        return true;
      });

      // Update parent's state by considering children states. Because when all children checked, we need to check parent as well
      root.walk({ strategy: 'post' }, (node) => {
        const children: Permission[] = node.model.children;

        if (children.length > 0 && children.every(child => child.isGranted)) {
          node.model.isGranted = true;
        }

        return true;
      });

      setUpdatedRolePermissions(root.model.children);
    }
  }, [rolePermissions, customPermissions, setUpdatedRolePermissions]);

  return <>
    {rolePermissions && customPermissions && updatedRolePermissions &&
      <PermissionView
        rolePermissions={rolePermissions}
        updatedRolePermissions={updatedRolePermissions}
      />
    }
  </>;
};