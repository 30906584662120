import { Ref } from 'react';
import { Tag } from '../../../@types';

export interface AutocompleteTagType {
  id: number;
  label: string;
  isSystem: boolean;
  color: string;
}

export enum AutoCompleteChangeReasons {
  CreateOption = 'createOption',
  RemoveOption = 'removeOption',
  selectOption = 'selectOption',
  Clear = 'clear',
  Blur = 'blur',
}

export interface TagsProps {
  selectedTags: (AutocompleteTagType | string)[];
  allTags: Tag[];
  setSeletedTags: (value: (AutocompleteTagType | string)[]) => void;
  newTags: Tag[];
  setNewTags: (value: Tag[]) => void;
  inputRef?: Ref<any>;
  label?: string;
  placeHolder?: string;
  disabled?: boolean;
}