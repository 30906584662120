import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { createOutcomeReview } from '../api/sla';
import { OutcomeReviewsKey } from '../configs/sla';

export const useCreateOutcomeReview = (serviceId: any): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createOutcomeReview(serviceId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([OutcomeReviewsKey, serviceId]);
    },
  });
};