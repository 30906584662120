import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { SLAConfigData } from '../@types/sla.type';
import { createSLAConfig, getAllSLAConfigs, updateSLAConfig } from '../api/sla';
import { SLAConfigsKey } from '../configs/sla';

export const useGetAllSLAConfigs = (): UseQueryResult<[SLAConfigData]> => {
  return useQuery([SLAConfigsKey], () => getAllSLAConfigs(), {
    select: (response: any) => response
  });
};

export const useCreateSLAConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const queryResult = await createSLAConfig(data);
    return queryResult;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLAConfigsKey]);
    },
  });
};

export const useUpdateSLAConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateSLAConfig(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SLAConfigsKey]);
    },
  }); 
};