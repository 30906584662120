import { TableCell, styled } from '@mui/material';

export const StyledTableCell = styled(TableCell)`
  border: 0;
`;

export const StyledTableCellPartType = styled(TableCell)`
  border: 0;
  min-width: 200px;
`;

export const StyledTableCellServiceType = styled(TableCell)`
  border: 0;
  min-width: 100px;
`;
