import React, { useState, useEffect } from 'react';
import { IconButton, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StyledScrollableWrapper } from './PartManifestSerial.styles';
import { PartManifestSerialProps } from '.';
import { Link } from '../../atoms/Link';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { BulkUpdatePartType, PartBulkData, PartManifestSerialList } from '../../../@types/part.type';
import { SerialisedPartsUpdateSelection } from '../../../pages/PartsBulkUpdate/SerialisedPartsUpdateSelection';
import { ListType } from '../../../@types';
import { serialisedColumnSelectionItems } from '../../../constants/partBulkUpdate.constant';

export const PartManifestSerial: React.FC<PartManifestSerialProps> = ({
  partManifestSerialData,
  isSerial,
  partsUpdateSelectionValue,
  onChange
}) => {
  const [partType, setPartType] = useState<BulkUpdatePartType>();
  const [filteredmanifestSerialData, setFilteredManifestSerialData] = useState<PartManifestSerialList[]>([]);
  const [partData, setPartData] = useState<Array<PartBulkData>>([]);

  const onClickDeactivateRow = (rowId: number) => {
    const updatedPartManifestSerialData = partManifestSerialData.map((row) => {
      if (row.id === rowId) {
        return { ...row, isActive: false };
      }
      return row;
    });
    onChange(updatedPartManifestSerialData);
  };

  useEffect(() => {
    if (partManifestSerialData && partManifestSerialData.length > 0) {
      const filteredPartManifestSerialData = partManifestSerialData.filter((item) => item.isActive === true);
      setFilteredManifestSerialData(filteredPartManifestSerialData);
    }
  }, [partManifestSerialData]);

  useEffect(() => {
    if (filteredmanifestSerialData && filteredmanifestSerialData.length > 0) {
      const isSinglePartTypePresents = filteredmanifestSerialData.every((part) => part.partTypeId === filteredmanifestSerialData[0].partTypeId);
      isSinglePartTypePresents ? 
        setPartType({
          partTypeId: filteredmanifestSerialData[0].partTypeId,
          partTypeName: filteredmanifestSerialData[0].partTypeName
        }) :
        setPartType({
          partTypeId: 0,
          partTypeName: ''
        });
    } else {
      setPartType({
        partTypeId: 0,
        partTypeName: ''
      });
    }

    const partLocationDetails: Array<PartBulkData> = [];

    filteredmanifestSerialData.map((filteredmanifestSerialItem) => {
      partLocationDetails.push({
        id: filteredmanifestSerialItem.id,
        partTypeId: filteredmanifestSerialItem.partTypeId,
        serial1: filteredmanifestSerialItem.serial1,
        serial2: filteredmanifestSerialItem.serial2,
        serial3: filteredmanifestSerialItem.serial3
      });
    });

    setPartData(partLocationDetails);
  }, [filteredmanifestSerialData]);

  const columns: Array<GridColDef> = [
    {
      field: '',
      headerName: '',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={() => onClickDeactivateRow(params.row.id)}
          color="error"
        >
          <CloseRounded />
        </IconButton>
      ),
    },
    { field: 'id', headerName: 'Asset ID', flex: 0.7 },
    { field: 'serial1', headerName: 'Serial 1', flex: 0.8 },
    { field: 'serial2', headerName: 'Serial 2', flex: 0.8 },
    { field: 'serial3', headerName: 'Serial 3', flex: 0.8 },
    { field: 'partTypeId', headerName: 'Part Type ID', flex: 1 },
    { field: 'partTypeName', headerName: 'Part Type Name', flex: 1 },
    {
      field: 'locationName', headerName: 'Structure Node (Location)', flex: 1.5,
      renderCell: (params: GridRenderCellParams) => params.row.locationTypeCode === 'node' ? <Link href={`/structure/${params.row.locationId}`}>{params.row.locationName}</Link> : <Link href={`/parts/${params.row.locationId}`}>{params.row.locationName}</Link>
    },
    { field: 'condition', headerName: 'Condition', flex: 0.8 },
    { field: 'software', headerName: 'Software', flex: 1 },
    { field: 'firmware', headerName: 'Firmware', flex: 1 },
    { field: 'hardwareRev', headerName: 'Hardware Rev.', flex: 1 },
    { field: 'fleetTagName', headerName: 'Fleet Tag Name', flex: 1 },
    { field: 'notes', headerName: 'Note', flex: 1 }
  ];

  return (
    <>
      <Stack direction="row" spacing={6} mt={4} justifyContent="center" alignItems="flex-start">
        <StyledScrollableWrapper>
          <CustomDataGrid
            columns={columns}
            rows={filteredmanifestSerialData}
            columnSelectionItems={serialisedColumnSelectionItems}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            exportFileName={ListType.PartBulkUpdateManifestList}
            justifyContent="flex-end"
            serialPartsManifestData={filteredmanifestSerialData.map((item: PartManifestSerialList) => {
              const manifestData = {
                id: item.id,
                serial1: item.serial1
              };
              return manifestData;
            })}
          />
        </StyledScrollableWrapper>
      </Stack>
      <SerialisedPartsUpdateSelection isOnlySerialized={isSerial} partType={partType} serialisedPartsUpdateSelectionValue={partsUpdateSelectionValue} partData={partData} />
    </>
  );
};