export interface VisibilityRule {
  id: number;
  name: string;
  description?: string;
}

export interface VisibilityRuleDropDownItems {
  value: number;
  label: string;
}

export const VisibilityRulesKey = 'visibility-rule';
