import React from 'react';
import { Stack, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { TLCManifestListProps } from './TLCManifestList.props';
import { TLCManifestRowItem, TLCManifestRowItemValueType } from '../../organisms/TLCManifestRowItem';
import { SizeProps } from '../../../@types';

export const TLCManifestList: React.FC<TLCManifestListProps> = ({
  manifestData,
  conditionsList,
  templateData,
  batchExecutionComplete,
  onChangeLocation,
  onChangeNote,
  onDelete
}) => {
  return (
    <Stack direction="column">
      <TableContainer>
        <Stack direction="column" marginTop={1} marginBottom={1}>
          <Typography fontSize={20} fontWeight={600} marginBottom={1}>TLC Manifest Items</Typography>
          <Table size={SizeProps.Small}>
            <TableBody>
              {manifestData?.map((manifestDataItemValue: TLCManifestRowItemValueType, index) => 
                <TLCManifestRowItem
                  key={index}
                  value={manifestDataItemValue}
                  manifestData={manifestData}
                  conditionsList={conditionsList}
                  templateData={templateData}
                  batchExecutionComplete={batchExecutionComplete}
                  onChangeLocation={(locationData, id) => onChangeLocation && onChangeLocation(locationData, id)}
                  onChangeNote={(note, id) => onChangeNote && onChangeNote(note, id)}
                  onDelete={(id) => onDelete && onDelete(id)}
                />
              )}
            </TableBody>
          </Table>
        </Stack>
      </TableContainer>
    </Stack>
  );
};