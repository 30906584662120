import React from 'react';
import { CSVDownloadButtonProps } from './CSVDownload.props';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { StyledLink } from './CSVDownload.styles';

export const CSVDownload: React.FC<CSVDownloadButtonProps> = ({
  isButton,
  label,
  icon,
  targetObject,
  fileName
}) => {
  const onDownload = () => {
    const csvData = Object.keys(targetObject).map((item) => item).join(',');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.csv`;
    link.click();
  };

  return (
    <>
      {isButton ? (
        <Button onClick={onDownload} variant={ButtonVariantProps.Primary}>
          {icon}{label}
        </Button>
      ) : (
        <StyledLink variant="inherit" color="primary" onClick={onDownload}>
          {label}
        </StyledLink>
      )}
    </>
  );
};
