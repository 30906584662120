import React, { useEffect, useState } from 'react';
import { useGetServiceActionProcessTypes } from '../../../../../queries/service-query';
import { ServiceCommonType } from '../../../../../@types';
import { ServiceSearchFormBuilderFieldFragmentProps } from './ServiceSearchFormBuilderFieldFragment.props';
import { StyledStack } from './ServiceSearchFormBuilderFieldFragment.styles';
import { CheckItem } from '../../../../../components/molecules/CheckItem';
import { MultiSelectCheckmarksMenu } from '../../../../../components/atoms/MultiSelectCheckmarksMenu';
import { SAMPLE_INTERACTIONS_SERVICE_COMMON_TYPE } from '../../../../../constants/interaction.constant';
import { actionTypesWithAnyWildcard, processTypesWithAnyWildcard, serviceTypesWithAnyWildcard } from '../../../../../utils/services';
import { convertToArray } from '../../../../../utils/common';
import { setDropDownList } from '../../../../../utils/interaction-rule';
import { ANY_KEYWORD } from '../../../../../constants/common';

export const ServiceSearchFormBuilderFieldFragment: React.FC<ServiceSearchFormBuilderFieldFragmentProps> = ({
  showErrors,
  preSelectedData,
  onPreSelectedData
}) => {
  const activeOnly = true;
  const [serviceCommonTypes, setServiceCommonTypes] = useState<ServiceCommonType>(SAMPLE_INTERACTIONS_SERVICE_COMMON_TYPE);
  
  const serviceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);

  useEffect(() => {
    serviceActionProcessTypesQuery.data && setServiceCommonTypes(serviceActionProcessTypesQuery.data);
  }, [serviceActionProcessTypesQuery.data]);

  return (
    <StyledStack spacing={2}>
      <MultiSelectCheckmarksMenu
        id="service-type-options"
        label="Service Types"
        required={true} 
        error={showErrors && !preSelectedData?.serviceTypes?.length}
        selectedValue={preSelectedData?.serviceTypes}
        onChange={(serviceTypes) => {
          if (convertToArray(serviceTypes).some((serviceType: string) => serviceType === ANY_KEYWORD)) {
            onPreSelectedData({ ...preSelectedData, serviceTypes: [ANY_KEYWORD] });
          } else {
            onPreSelectedData({ ...preSelectedData, serviceTypes: convertToArray(serviceTypes) });
          }
        }}
        items={setDropDownList(preSelectedData?.serviceTypes || [], serviceTypesWithAnyWildcard(serviceCommonTypes.serviceTypes))} 
      />
      <MultiSelectCheckmarksMenu
        id="action-type-options"
        label="Action Types"
        required={true}
        error={showErrors && !preSelectedData?.actionTypes?.length}
        selectedValue={preSelectedData?.actionTypes}
        onChange={(actionTypes) => {    
          if (convertToArray(actionTypes).some((actionType: string) => actionType === ANY_KEYWORD)) {
            onPreSelectedData({ ...preSelectedData, actionTypes: [ANY_KEYWORD] });
          } else {
            onPreSelectedData({ ...preSelectedData, actionTypes: convertToArray(actionTypes) });
          }
        }}
        items={setDropDownList(preSelectedData?.actionTypes || [], actionTypesWithAnyWildcard(serviceCommonTypes.actionTypes))}  
      />
      <MultiSelectCheckmarksMenu
        id="process-type-options"
        label="Process Types"
        required={true}
        error={showErrors && !preSelectedData?.processTypes?.length}
        selectedValue={preSelectedData?.processTypes}
        onChange={(processTypes) => {
          if (convertToArray(processTypes).some((processType: string) => processType === ANY_KEYWORD)) {
            onPreSelectedData({ ...preSelectedData, processTypes: [ANY_KEYWORD] });
          } else {
            onPreSelectedData({ ...preSelectedData, processTypes: convertToArray(processTypes) });
          }
        }}
        items={setDropDownList(preSelectedData?.processTypes || [], processTypesWithAnyWildcard(serviceCommonTypes.processTypes))} 
      />
      <CheckItem
        label={'Restrict to sublocation only'}
        value={preSelectedData?.restrictSublocation || false}
        onChange={(value) => onPreSelectedData({ ...preSelectedData, restrictSublocation: value })}
      />
    </StyledStack>
  );
};