import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MobileDataGridItemProps } from './MobileDataGridItem.props';
import { StyledMobileDataGridItemEntryWrapper, StyledStack } from './MobileDataGridItem.styles';
import { MobileDataGridItemEntry } from '../../molecules/MobileDataGridItemEntry';

export const MobileDataGridItem: React.FC<MobileDataGridItemProps> = ({
  data,
  enableSingleColumn
}) => {
  const navigate = useNavigate();
  return (
    <StyledStack enableSingleColumn={enableSingleColumn}>
      {data?.map((obj, index) => {
        if (obj.value) {
          return (
            <StyledMobileDataGridItemEntryWrapper key={index} gridData={obj} index={index} previousGridDataItem={data[index ? index - 1 : index]} onClick={() => navigate(obj.valueLinkUrl || '')} enableSingleColumn={enableSingleColumn}>
              <MobileDataGridItemEntry
                key={index}
                data={obj}
              />
            </StyledMobileDataGridItemEntryWrapper>
          );
        }
      })}
    </StyledStack>
  );
};