import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useGetUserSystemPermissions } from '../../queries/user-query';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { HorizontalAlignProps, InlineGroup } from '../../components/atoms/InlineGroup';
import { PageContainer } from '../../components/atoms/PageContainer';
import { RoleList } from './RoleList';
import { ActionPermissions, CustomPermission } from '../../@types';
import { isUserHasPermission } from '../../configs/permissions';
import { Link } from '../../components/atoms/Link';
import { Wrapper } from '../../styled/common.styles';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';

export const Roles: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  TabHandler(`List Roles | ${PLATFORM_NAME}`);
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List Roles
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_User_Roles_Create_General, permissions) && (
            <Link href="configure/users/roles/create">
              <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />}>
                Create Role
              </Button>
            </Link>
          )}
        </InlineGroup>
        {isUserHasPermission(ActionPermissions.Configure_User_Roles_Search_View, permissions) && (
          <RoleList />
        )}
      </Wrapper>
    </PageContainer>
  );
};