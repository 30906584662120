import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { MainPartEditFragmentProps } from './MainPartEditFragment.props';
import { MainPartEdit, PartEditPermissions } from '../../../../components/templates/MainPartEdit';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { HorizontalDevider, StyledContainer } from './MainPartEditFragment.styles';
import { DuplicatePartsDataType, ExceptionalPartUpdateObject, PartLocationObject, PartObject, SfhObject, UpdatePartLocationObject } from '../../../../@types/part.type';
import { ActionPermissions, ScreenSize, Tag } from '../../../../@types';
import { INIT_PART_EDIT_PERMISSIONS, SAMPLE_PART, SAMPLE_PART_LOCATION, SAMPLE_SFH, SAMPLE_UPDATED_PART_LOCATION } from '../../../../constants/part';
import { getAllTags, useAddTags } from '../../../../queries/tags-query';
import { EntityType } from '../../../../configs/enums';
import { AutocompleteTagType, getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags } from '../../../../components/organisms/Tags';
import { useGetDuplicatePartsBySerial1AndPartType, useGetPartLocationDataByPartId, useUpdateExceptionalPart, useUpdatePart } from '../../../../queries/part-query';
import { useGetOnePartType } from '../../../../queries/part-type-query';
import { PartType } from '../../../../@types/partType.type';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { PrintLabel } from '../../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../../@types/print-label.type';
import { DuplicatePartsFragment } from '../DuplicatePartsFragment';
import { PLATFORM_NAME } from '../../../../configs/common';
import { ExceptionPartEdit } from '../../../../components/templates/ExceptionPartEdit';
import { useMediaQuery } from '@material-ui/core';
import { PartChildrenList } from '../../../../components/molecules/PartChildrenList';
import { isDropDownEmpty } from '../../../../utils/common';
import { SAMPLE_PART_TYPE } from '../../../../constants/partType';

export const MainPartEditFragment: React.FC<MainPartEditFragmentProps> = ({
  data,
  conditions
}) => {
  const [part, setPart] = useState<PartObject>(SAMPLE_PART);
  const [partType, setPartType] = useState<PartType>(SAMPLE_PART_TYPE);
  const [partLocation, setPartLocation] = useState<PartLocationObject>(SAMPLE_PART_LOCATION);
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [partTypeId, setPartTypeId] = useState(0);
  const [partId, setPartId] = useState(0);
  const [updatedLocation, setUpdatedLocation] = useState<UpdatePartLocationObject>(SAMPLE_UPDATED_PART_LOCATION);
  const [partEditPermissions, setPartEditPermissions] = useState<PartEditPermissions>(INIT_PART_EDIT_PERMISSIONS);
  const [open, setOpen] = useState(false);
  const [sfhDetails, setSfhDetails] = useState<SfhObject>(SAMPLE_SFH);
  const [duplicateParts, setDuplicateParts] = useState<DuplicatePartsDataType[]>();
  const [isExceptionalPartVisible, setExceptionalPartVisibility] = useState<boolean>(false);
  const [validate, setValidate] = useState(false);

  const updateExceptionalPartQuery = useUpdateExceptionalPart();
  const updateTagsQuery = useAddTags();
  const userTagsQuery = getAllTags(EntityType.TYPE_SERIALIESEDPARTS);
  const updatePart = useUpdatePart(data.id);
  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();
  const getDuplicatePartsBySerial1AndPartType = useGetDuplicatePartsBySerial1AndPartType(data.id, data.serial1, data.partTypeId);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);


  useEffect(() => {
    userTagsQuery.data && setAllTags(userTagsQuery.data);
  }, [userTagsQuery.data]);

  useEffect(() => {
    if (data) {
      setPart(data);
      setPartId(data.id);
      setPartTypeId(data.partTypeId);
      setSelectedTags(mapSavedTags(data.tags));
      document.title = `Part: ${data.serial1} | Parts | ${PLATFORM_NAME}`;

      if (data.additionalData?.exception) {
        setExceptionalPartVisibility(true);
      }
    }
  }, [data]);

  const getOnePartType = useGetOnePartType(partTypeId);
  const getPartLocationDataByPartId = useGetPartLocationDataByPartId(part.id, updatedLocation);

  useEffect(() => {
    if (partType) {
      const hardwares = partType.hardwares?.map(hardware => ({
        value: hardware?.id?.toString() || '',
        label: hardware?.version || ''
      }));
      const softwares = partType.softwares?.map(software => ({
        value: software?.id?.toString() || '',
        label: software?.version || ''
      }));
      const firmwares = partType.firmwares?.map(firmware => ({
        value: firmware?.id?.toString() || '',
        label: firmware?.version || ''
      }));
      setSfhDetails({ hardwares, softwares, firmwares });
    }
  }, [partType]);
  
  useEffect(() => {
    partTypeId && getOnePartType.refetch();
  }, [partTypeId]);

  useEffect(() => {
    (partId || updatedLocation.locationId) && getPartLocationDataByPartId.refetch();
  }, [partId, updatedLocation.locationId]);

  useEffect(() => {
    getOnePartType.data && setPartType(getOnePartType.data);
  }, [getOnePartType.data]);

  useEffect(() => {
    getPartLocationDataByPartId.data && setPartLocation(getPartLocationDataByPartId.data);
  }, [getPartLocationDataByPartId.data]);

  useEffect(() => {
    getDuplicatePartsBySerial1AndPartType.data && setDuplicateParts(getDuplicatePartsBySerial1AndPartType.data);
  }, [getDuplicatePartsBySerial1AndPartType.data]);

  useEffect(() => {
    const permissions = getUserSystemPermissionsQuery.data;
    if (permissions) {
      const isSerialPermission = isUserHasPermission(ActionPermissions.Part_Serial, permissions);
      const isConditionPermission = isUserHasPermission(ActionPermissions.Part_Condition, permissions);
      const isLocationPermission = isUserHasPermission(ActionPermissions.Structure_Edit, permissions);
      const isClientHierarchyPermission = isUserHasPermission(ActionPermissions.Configure_Clients_or_Contracts_Edit, permissions);
      const isPartTypePermission = isUserHasPermission(ActionPermissions.Part_Change_Part_Type, permissions);
      const isPartMergePermission = isUserHasPermission(ActionPermissions.Parts_Merge, permissions);
      const isExceptionalPartAcceptancePermission = isUserHasPermission(ActionPermissions.Part_Exception, permissions);

      setPartEditPermissions({
        ...partEditPermissions,
        isSerial: isSerialPermission,
        isCondition: isConditionPermission,
        isLocation: isLocationPermission,
        isClientHierarchy: isClientHierarchyPermission,
        isPartType: isPartTypePermission,
        isPartMerge: isPartMergePermission,
        isExceptionalAcceptance: isExceptionalPartAcceptancePermission
      });
    }
  }, [getUserSystemPermissionsQuery.data, data]);

  const handlePartChange = async (part: PartObject) => {
    if (isDropDownEmpty(part.conditionCode)) {
      setValidate(true);
      return;
    }
    
    const existingTags = getSelectedExistingTagsMapped(selectedTags);
    const freshTags = getNewTagsMapped(newTags);

    await updatePart.mutateAsync({
      ...part,
      partTypeId: partTypeId, 
      locationId: updatedLocation.locationId, 
      locationTypeCode: updatedLocation.locationTypeCode,
      locationHierarchy: updatedLocation.locationHierarchy
    });

    await updateTagsQuery.mutateAsync({
      entityTypeId: EntityType.TYPE_SERIALIESEDPARTS,
      entityId: data.id,
      freshTags,
      existingTags
    });

    setPart(prevState => ({
      ...prevState,
      partTypeId,
      configChnageNotes: '',
    }));
    
    setOpen(true);
  };

  const handlePartTypeChange = async (val: any) => {
    setPartTypeId(val.partTypeId);
  };

  const handlePartLocationChange = async (val: UpdatePartLocationObject) => {
    const updatedPartLocationData = {
      id: val.id,
      locationId: val.locationId,
      locationHierarchy: val.locationHierarchy,
      locationTypeCode: val.locationTypeCode
    };

    setPartId(val.id);
    setUpdatedLocation(updatedPartLocationData);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExceptionalPartUpdate = async (data: ExceptionalPartUpdateObject) => {
    const response = await updateExceptionalPartQuery.mutateAsync(data);
    if (response) {
      setExceptionalPartVisibility(false);
    }
  };

  const action = (
    <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Secondary} onClick={handleClose}>
      X
    </Button>
  );

  return (
    <Grid container spacing={2} pr={1} pl={1} mt={1}>
      <Grid item container spacing={1} xs={12} style={{ whiteSpace: 'unset', wordBreak: 'break-all' }}>
        <Typography variant={TypographyVariantProps.H4}>Part: {data.serial1} - {data.partType.name}</Typography>
      </Grid>
      <MainPartEdit
        permissions={partEditPermissions}
        value={part}
        sfhDetails={sfhDetails}
        conditions={conditions}
        selectedTags={selectedTags}
        allTags={allTags}
        newTags={newTags}
        setNewTags={setNewTags}
        partType={partType}
        partLocation={partLocation}
        setSelectedTags={setSelectedTags}
        handlePartTypeChange={handlePartTypeChange}
        handlePartLocationChange={handlePartLocationChange}
        onChange={(val) => setPart({ ...val })}
        validate={validate}
      />
      {(partEditPermissions.isExceptionalAcceptance && isExceptionalPartVisible) &&
        <Grid item container spacing={1} xs={12} sm={6} md={4} mt={1}>
          <ExceptionPartEdit data={data} onRespond={handleExceptionalPartUpdate}></ExceptionPartEdit>
        </Grid>
      }
      <Grid item container spacing={1} xs={12}>
        <Stack direction="row" width="100%" justifyContent="end" mt={6} spacing={2} alignItems="center" mb={5}>
          <PrintLabel entityId={part.id} entityType={PrintLabelEntityTypes.Part} />
          {part &&
            <div>
              <Button variant={ButtonVariantProps.Primary} onClick={() => handlePartChange(part)}>
                Save
              </Button>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Part Updated!"
                action={action}
              />
            </div>
          }
        </Stack>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <HorizontalDevider />
      </Grid>
      {partEditPermissions.isPartMerge && duplicateParts?.length && duplicateParts?.length > 0 && !isMobileView ? <Grid item container spacing={1} xs={12}>
        <Grid item container spacing={1} xs={12}>
          <StyledContainer>
            <DuplicatePartsFragment duplicatePartsData={duplicateParts} mainPartId={data.id} />
          </StyledContainer>
        </Grid>
      </Grid> : null
      }
      <Grid item container spacing={1} xs={12}>
        <StyledContainer>
          <PartChildrenList partChildrenListData={part.partChildrens || []} />
        </StyledContainer>
      </Grid>
    </Grid>
  );
};