import React from 'react';
import { useEffect, useState } from 'react';
import { EstimatedTimeOfArrivalFragmentProps } from './EstimatedTimeOfArrivalFragment.props';
import { EstimatedTimeofArrivalForm } from '../../../../../../components/organisms/EstimatedTimeofArrivalForm';
import { useGetEstimatedTimeOfArrival } from '../../../../../../queries/service-query';
import { getDateTime } from '../../../../../../utils/date-time-utils';

export const EstimatedTimeOfArrivalFragment: React.FC<EstimatedTimeOfArrivalFragmentProps> = ({
  service,
  onChange,
  isError
}) => {
  const [origin, setOrigin] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const nodeId = service.mainLocationNodeId || 0;
  const getEstimatedTimeOfArrival = useGetEstimatedTimeOfArrival({ origin, date, nodeId, serviceId: service.id });

  useEffect(() => {
    origin && date && nodeId && getEstimatedTimeOfArrival.refetch();
  }, [origin, date, nodeId]);

  useEffect(() => {
    getEstimatedTimeOfArrival.data && setValue(getEstimatedTimeOfArrival.data);
  }, [getEstimatedTimeOfArrival.data]);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setOrigin(`${latitude} ${longitude}`);
          setDate(getDateTime());
          return;
        }
      );
    }
  }, [navigator.geolocation]);

  return (
    <EstimatedTimeofArrivalForm
      value={value}
      onChange={onChange}
      isError={isError}
    />
  );
};