import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Box } from '../../atoms/Box';
import { Snackbar } from '../../atoms/Snackbar';
import { AlertMessageProps } from './AlertMessage.props';
import { capitalizeFirstLetter } from '../../../utils/common';

export const AlertMessage: React.FC<AlertMessageProps> = ({
  alertMessage,
  alertTitle,
  onClose,
  open,
  verticalPosition,
  horizontalPosition
}) => {
  return (
    <Snackbar anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition, }} open={open} autoHideDuration={8000} onClose={onClose}>
      <Alert onClose={onClose} severity={alertTitle}>
        <AlertTitle>{capitalizeFirstLetter(alertTitle.toString())}</AlertTitle>
        <Box>{alertMessage}</Box>
      </Alert>
    </Snackbar>
  );
};