import { styled, Card, CardContent } from '@mui/material';
import { Button } from '../../../../../components/atoms/Button';

export const DefaultCard = styled(Card)`
  position: relative;
  width: 100%;
  min-width: 100%;
  padding: 8px;
`;

export const StyledCardContent = styled(CardContent)`
  background-color: white;
  padding: 15px;
  border: gray 2px solid;
  border-radius: 5px;
`;

export const CardTitle = styled('p')`
  position: absolute;
  top: -22px;
  background-color: white;
  padding: 2px;
  font-size: 18px;
`;

export const CloseWrapper = styled('div')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: red;
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 80px;
`;