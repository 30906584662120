import React from 'react';
import dayjs from 'dayjs';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { TimePickerProps } from './TimePicker.props';
import { TimeTextField } from './TimePicker.styles';
import { SizeProps } from '../../../@types';

export const TimePicker: React.FC<TimePickerProps> = ({
  label = 'Time',
  value,
  normalSize = false,
  onChange
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        label={label}
        value={dayjs(value, 'h:mma')}
        inputFormat="h:mma"
        disableMaskedInput={true}
        onChange={(text) => onChange(text?.format('h:mma') || '')}
        renderInput={(params) => <>{normalSize ? <TextField {...params} size={SizeProps.Small}/> : <TimeTextField {...params} size={SizeProps.Small}/>}</>}
      />
    </LocalizationProvider>
  );
};
