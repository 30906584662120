import React from 'react';
import { ModalProps } from './Modal.props';
import { StyledModal } from './Modal.styles';

export const Modal: React.FC<ModalProps> = ({
  open,
  children,
  onClose
}) => {
  return (
    <StyledModal open={open} onClose={(value: any) => onClose && onClose(value)}>
      {children}
    </StyledModal>
  );
};