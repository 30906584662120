import React, { useEffect, useState } from 'react';
import { FormControl, Grid } from '@mui/material';
import { PopupDialog } from '../../../../components/molecules/PopupDialog';
import { AddEditNotificationTemplateFragmentProps } from './AddEditNotificationTemplateFragment.props';
import { TextField } from '../../../../components/atoms/TextField';
import { NotificationTemplate, NotificationTemplateFields, NotificationTemplateHelperText, NotificationTemplateType } from '../../../../@types/notificationTamplate.type';
import { SAMPLE_NOTIFICATION_TEMPLATE, SAMPLE_NOTIFICATION_TEMPLATE_HELPER_TEXT, NOTIFICATION_TYPE_DROPDOWN_ITEMS } from '../../../../constants/notificationTemplate';
import { isDropDownEmpty, nameValidation } from '../../../../utils/common';
import { StyledSelectMenu } from './AddEditNotificationTemplateFragment.styles';
import { Box } from '../../../../components/atoms/Box';
import { RichTextEditor } from '../../../../components/atoms/RichTextEditor';
import { ColorProps } from '../../../../@types';
import { Typography } from '../../../../components/atoms/Typography';
import { Switch } from '../../../../components/atoms/Switch';
import { useCreateNotificationTemplate, useGetNotificationTemplate, useUpdateNotificationTemplate } from '../../../../queries/notification-template-query';
import { hasErrorInNotificationTemplate } from '../../../../utils/notificationTemplate';

export const AddEditNotificationTemplateFragment: React.FC<AddEditNotificationTemplateFragmentProps> = ({
  isOpen,
  isEdit,
  notificationTemplateId,
  sendGridTemplates,
  onClose,
}) => {
  const [notificationTemplateData, setNotificationTemplateData] = useState<NotificationTemplate>(SAMPLE_NOTIFICATION_TEMPLATE);
  const [helperText, setHelperText] = useState<NotificationTemplateHelperText>(SAMPLE_NOTIFICATION_TEMPLATE_HELPER_TEXT);
  const [validate, setValidate] = useState(false);

  const hasError = hasErrorInNotificationTemplate(notificationTemplateData.name) || 
    isDropDownEmpty(notificationTemplateData?.type) || 
    (notificationTemplateData?.type === NotificationTemplateType.Email && isDropDownEmpty(notificationTemplateData?.templateId));

  const getNotificationTemplate = useGetNotificationTemplate(notificationTemplateId || 0);
  const createNotificationTemplate = useCreateNotificationTemplate();
  const updateNotificationTemplate = useUpdateNotificationTemplate();

  useEffect(() => {
    isEdit && getNotificationTemplate.refetch();
  }, [isEdit]);

  useEffect(() => {
    getNotificationTemplate.data && setNotificationTemplateData(getNotificationTemplate.data);
  }, [getNotificationTemplate.data]);

  const handleSave = async () => {
    setHelperText({ 
      ...helperText, 
      nameHelperText: nameValidation(notificationTemplateData.name, NotificationTemplateFields.NameField),
    });
    
    if (hasError) {
      setValidate(true);
      return;
    }

    if (isEdit) {
      await updateNotificationTemplate.mutateAsync(notificationTemplateData).catch(async (error) => {
        if (error) return;
      });
    } else {
      await createNotificationTemplate.mutateAsync(notificationTemplateData).catch(async (error) => {
        if (error) return;
      });
    }

    onClose();
  };

  const onClear = () => {
    setNotificationTemplateData(SAMPLE_NOTIFICATION_TEMPLATE);
  };

  return (
    <PopupDialog 
      maxWidth="1200px"
      width="80%"
      title={`${isEdit ? 'Edit' : 'Add'} Template`} 
      isOpen={isOpen} 
      isClear={true}
      isEdit={isEdit}
      onClose={onClose} 
      onSave={handleSave}
      onClear={onClear}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Name *"
            fullWidth
            error={!!helperText.nameHelperText}
            helperText={helperText.nameHelperText}
            value={notificationTemplateData.name}
            onChange={(name) => {
              notificationTemplateData && setNotificationTemplateData({ ...notificationTemplateData, name });
              setHelperText({ ...helperText, nameHelperText: nameValidation(name, NotificationTemplateFields.NameField) });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledSelectMenu
            required={true}
            id="type"
            labelId="type"
            label="Type *"
            selectedValue={notificationTemplateData?.type}
            onChange={async (type) => {
              notificationTemplateData && setNotificationTemplateData({
                ...notificationTemplateData,
                type,
                template: '',
                templateId: type === NotificationTemplateType.Email ? notificationTemplateData.templateId : '',
                subject: type === NotificationTemplateType.Email ? notificationTemplateData.subject : ''
              });
            }}
            items={NOTIFICATION_TYPE_DROPDOWN_ITEMS}
            validate={validate}
            optionalLabelEnabled={true}
          />
        </Grid>
        {notificationTemplateData?.type === NotificationTemplateType.Email &&
          <>
            <Grid item xs={12} md={12}>
              <TextField
                label="Subject"
                fullWidth
                value={notificationTemplateData.subject}
                onChange={(subject) => {
                  notificationTemplateData && setNotificationTemplateData({ ...notificationTemplateData, subject });
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <StyledSelectMenu
                required={true}
                id="template"
                labelId="template"
                label="Template"
                selectedValue={notificationTemplateData?.templateId || '-1'}
                onChange={async (templateId) => {
                  notificationTemplateData && setNotificationTemplateData({
                    ...notificationTemplateData,
                    templateId: templateId === '-1' ? '' : templateId,
                  });
                }}
                items={sendGridTemplates}
                validate={validate}
                optionalLabelEnabled={true}
              />
            </Grid>
          </>
        }
        <Grid item xs={12} md={12}>
          {notificationTemplateData?.type === NotificationTemplateType.Email ?
            <Box>
              <FormControl fullWidth>
                <RichTextEditor
                  value={notificationTemplateData?.template || ''}
                  onValueChange={(template: string) => {
                    notificationTemplateData && setNotificationTemplateData({
                      ...notificationTemplateData,
                      template,
                    });
                  }}
                />
              </FormControl>
            </Box>
            :
            <TextField
              fullWidth
              value={notificationTemplateData?.template || ''}
              onChange={(template: string) => {
                notificationTemplateData && setNotificationTemplateData({
                  ...notificationTemplateData,
                  template,
                });
              }}
              multiline={true}
              rows={3}
            />
          }
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography>Active</Typography>
          <Switch
            color={ColorProps.Success}
            checked={notificationTemplateData?.isActive}
            onChange={() => {
              notificationTemplateData && setNotificationTemplateData({
                ...notificationTemplateData,
                isActive: !notificationTemplateData.isActive 
              });
            }}
          />
        </Grid>
      </Grid>
    </PopupDialog>
  );
};