import { httpClient, HttpMethod } from './http-client';
import { CustomPermission, QueryOption } from '../@types';
import { isNotArrayEmpty } from '../utils/array-utils';

export const getUsers = (params: QueryOption) => {
  const { pageSize, page, filterModel, sortModel } = params;
  let url = `/users?limit=${pageSize}&page=${page + 1}`;

  if (sortModel && isNotArrayEmpty(sortModel, 0)) {
    url += `&order_by=${sortModel[0]?.field}&order_direction=${sortModel[0]?.sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });
  return httpClient(url, HttpMethod.Get, null);
};

export const getUserById = (id: number) => {
  return httpClient(`/users/${id}`, HttpMethod.Get, null);
};

export const getUserByAuth0Id = (id: string) => {
  return httpClient(`/users/byAuth0Id/${id}`, HttpMethod.Get, null);
};

export const createUser = (user: any) => {
  return httpClient('/users', HttpMethod.Post, user);
};

export const updateUser = (id: number, user: any) => {
  return httpClient(`/users/${id}`, HttpMethod.Put, user);
};

export const updateUserEmailVerification = (id: number, user: any) => {
  return httpClient(`/users/${id}/verifyemail`, HttpMethod.Put, user);
};

export const getUserPermissions = (id: number | string) => {
  return httpClient(`/users/${id}/permissions`, HttpMethod.Get, null);
};

export const getUserSystemPermissions = () => {
  return httpClient('/getUserPermissions', HttpMethod.Get, null);
};

export const createUserPermissions = (id: string, userPermissions: CustomPermission[]) => {
  return httpClient(`/users/${id}/permissions`, HttpMethod.Post, { userPermissions });
};

export const createUserWhenLogin = (authUser: any) => {
  const user = {
    firstName: authUser?.given_name,
    lastName: authUser?.family_name,
    email: authUser?.email,
    contactEmail: authUser?.email,
    auth0Id : authUser?.sub,
    systemRoleId: 101,
    isActive: authUser?.blocked == true ? false : true,
    isLocked: false,
    isEmailVerified: authUser?.email_verified,
    structureId: 1,
    profilePic: authUser?.picture,
    timezoneId: 1,
    notes: 'Add note',
    lastActive: authUser?.updated_at,
  };

  return httpClient('/users/activedirectoryuser', HttpMethod.Post, user);
};

export const getLoggenInUserData = () => {
  return httpClient('/userLogin', HttpMethod.Get, null);
};