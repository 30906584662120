import { styled } from '@mui/material/styles';
import { Box } from '../../components/atoms/Box';
import { Typography } from '../../components/atoms/Typography';

export const ErrorBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400;
  width: 80%;
  background-color: #1d8c97;
  border-radius: 1ch;
  box-shadow: 0 0 10px 5px #363636;
  padding: 1ch;
`;

export const ErrorTypography = styled(Typography)`
  color: white
`;
