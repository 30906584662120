import React from 'react';
import { SelectMenu } from '../../atoms/SelectMenu';
import { StyledDiv } from './SelectionButton.styles';
import { SelectionButtonProps } from './SelectionButton.props';

export const SelectionButton: React.FC<SelectionButtonProps> = ({
  label,
  value,
  error,
  onClick,
  disabled = false,
  readOnly = true
}) => {
  return (
    <StyledDiv onClick={onClick}>
      <SelectMenu 
        id="selection-button"
        label={label}
        items={[{ value: value, label: value }]}
        selectedValue={value}
        validate={error}
        disabled={disabled}
        readOnly={readOnly}
      />
    </StyledDiv>
  );
};