import { Box, TableCell, styled } from '@mui/material';
import { Typography } from '../../../../components/atoms/Typography';

export const StyledTableCell = styled(TableCell)`
  border: 0;
`;

export const StyledSecondaryTableCell = styled(TableCell)`
  border: 0;
  vertical-align: top;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  padding-right: 5%
`;