import React from 'react';
import { Checkbox as MaterialCheckBox } from '@mui/material';
import { CheckboxProps, CheckboxColorProps } from './Checkbox.props';
import { SizeProps } from '../../../@types';

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  color = CheckboxColorProps.Default,
  size = SizeProps.Small,
  indeterminate = false,
  disabled = false,
  onChange
}) => {
  return (
    <MaterialCheckBox 
      checked={checked}
      color={color}
      size={size}
      disabled={disabled}
      indeterminate={indeterminate} 
      onChange={onChange} 
    />
  );
};