import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionPermissions, CustomPermission } from '../../@types';
import { useGetUserSystemPermissions } from '../../queries/user-query';
import { isUserHasPermission } from '../../configs/permissions';
import { AddEditStock } from './AddStock';
import { useGetOneShipment } from '../../queries/shipment-query';
import { Dispatch } from './Dispatch';
import { ShipmentTypes } from '../../configs/enums';

export const EditShipment: React.FC = () => {
  const { id }: any = useParams();
  const [permissions, setPermissions] = useState<Array<CustomPermission>>();
  const [shipmentTypeCode, setShipmentTypeCode] = useState<string>('');

  const getOneShipment = useGetOneShipment(id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getOneShipment.data) {
      setShipmentTypeCode(getOneShipment.data?.shipmentTypeCode);
    }
  }, [getOneShipment.data]);

  useEffect(() => {
    if (getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.data]);
  
  if(shipmentTypeCode === 'add-stock' && isUserHasPermission(ActionPermissions.Shipment_View_Add_Stock, permissions)) {
    return <AddEditStock />;
  } else if ((shipmentTypeCode === ShipmentTypes.Dispatch || shipmentTypeCode === ShipmentTypes.InternalTransfer) && isUserHasPermission(ActionPermissions.Shipment_View_Dispatch, permissions)) {
    return <Dispatch />;
  } else {
    return null;
  }
};