import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Root, OutletWrapper } from './MainLayout.styles';
import { Drawer } from '../../molecules/Drawer';
import { menuData } from '../../../configs/menu';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { permittedLayouts } from '../../../configs/permissions';
import { CustomPermission } from '../../../@types';
import { home } from '../../../routes/MainRoutes';

export const MainLayout = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isOutsideClick, setIsOutsideClick] = useState<boolean>(false);
  let layoutes: any[] = [home];

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    if (getUserPermissionsQuery.isSuccess && getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
  }, [getUserPermissionsQuery.isLoading, getUserPermissionsQuery.isSuccess]);

  if (permissions) {
    layoutes = permittedLayouts(menuData, permissions);
  }

  return (
    <Root>
      <Drawer menuData={layoutes} isOutsideClick={isOutsideClick} handleOutsideClick={setIsOutsideClick} />
      <OutletWrapper onClick={() => setIsOutsideClick(true)}>
        <Outlet />
      </OutletWrapper>
    </Root>
  );
};
