import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { AttachmentTypesResponse, AttendanceReportListResponse, CustomFilterSettings, QueryOption, ReportAccessData, ReportAccessResponse, ReportResponse, ReportScheduleEmailType, SavedReportDataType, SavedReportsListResponse } from '../@types';
import { createReport, createSavedReportAccess, createScheduleReport, deleteReportById, getAttachmentTypes, getAttendanceReports, getDeafultReportByReportType, getReportAccessByReportCode, getReportByCode, getReportScheduleDataByReportId, getSavedReportAccessByReportId, getSavedReportsByCreatedId, sendSavedReportTestEmail, updateBulkReports, updateReportAccessDataById, updateReportByCode, updateReportScheduleEmailByReprtId } from '../api/report';
import { AttendanceReportsKey, defaultReportsKey, GetAttachmentTypesKey, GetReportsKey, SavedReportsAccessKey, SavedReportsKey, ScheduleReportKey } from '../configs/reports';

export const useGetAllAttendanceReports = (queryOption: QueryOption, customFilter?: CustomFilterSettings): UseQueryResult<AttendanceReportListResponse> => {
  return useQuery([AttendanceReportsKey,queryOption], () => getAttendanceReports(queryOption, customFilter));
};

export const useGetSavedReports = (queryOption: QueryOption): UseQueryResult<SavedReportsListResponse> => {
  return useQuery([GetReportsKey], () => getSavedReportsByCreatedId(queryOption), { enabled: false });
};

export const useAddNewReport = (): UseMutationResult<ReportResponse> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createReport(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([SavedReportsKey]);
      await queryClient.invalidateQueries([SavedReportsKey, response.id]);
    },
  });
};

export const useGetReportByCode = (code: string): UseQueryResult<SavedReportDataType> => {
  return useQuery([SavedReportsKey, code], () => getReportByCode(code), { enabled: false });
};

export const useUpdateReportByCode = (code: string): UseMutationResult<ReportResponse> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateReportByCode(code, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SavedReportsKey, code]);
      await queryClient.invalidateQueries([SavedReportsKey]);
    },
  }); 
};

export const useAddNewSavedReportAccess = (): UseMutationResult<ReportAccessData> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createSavedReportAccess(data.reportId, data.data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([SavedReportsAccessKey]);
      await queryClient.invalidateQueries([SavedReportsAccessKey, response.id]);
    },
  });
};

export const useUpdateReportAccessDataById = (): UseMutationResult<ReportAccessData> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateReportAccessDataById(data.id, data.data);
  }, {
    onSuccess: async (data: any) => {
      await queryClient.invalidateQueries([SavedReportsAccessKey, data.id]);
      await queryClient.invalidateQueries([SavedReportsAccessKey]);
    },
  }); 
};

export const useGetShareAccessDataByReportId = (reportId: string): UseQueryResult<ReportAccessResponse> => {
  return useQuery([SavedReportsAccessKey, reportId], () => getSavedReportAccessByReportId(reportId), { enabled: false, cacheTime: 0 });
};

export const useGetReportAccessByReportCode = (code: string): UseQueryResult<ReportAccessData> => {
  return useQuery([SavedReportsAccessKey, code], () => getReportAccessByReportCode(code), { enabled: false, cacheTime: 0 });
};

export const useGetAttachmentTypes = (): UseQueryResult<AttachmentTypesResponse> => {
  return useQuery([GetAttachmentTypesKey], () => getAttachmentTypes());
};

export const useAddScheduleReport = (): UseMutationResult<ReportScheduleEmailType> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createScheduleReport(data);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([ScheduleReportKey]);
      await queryClient.invalidateQueries([ScheduleReportKey, response.id]);
    },
  });
};

export const useGetScheduleReportDataByReportId = (reportId: string): UseQueryResult<ReportScheduleEmailType> => {
  return useQuery([ScheduleReportKey, reportId], () => getReportScheduleDataByReportId(reportId), { enabled: false });
};

export const useUpdateReportScheduleEmailByReportId = (reportId: string): UseMutationResult<ReportScheduleEmailType> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateReportScheduleEmailByReprtId(reportId, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ScheduleReportKey, reportId]);
      await queryClient.invalidateQueries([ScheduleReportKey]);
    },
  }); 
};

export const useGetDefaultReportByReportType = (reportTypeName: string): UseQueryResult<SavedReportDataType[]> => {
  return useQuery([defaultReportsKey, reportTypeName], () => getDeafultReportByReportType(reportTypeName), { cacheTime: 0, enabled: false });
};

export const useUpdateBulkReports = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await updateBulkReports(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([defaultReportsKey]);
    },
  });
};

export const useDeleteReportAccessDataById = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (reportId: any) => {
    return await deleteReportById(reportId);
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([GetReportsKey, response.id]);
    },
  });
};

export const useSendSavedReportTestEmail = (): UseMutationResult<{ success: boolean; message: string }> => {
  return useMutation(async (data: any) => {
    return await sendSavedReportTestEmail(data);
  });
};