import React, { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { ServiceStatusResponse } from '../../../@types/service.type';
import { DataGrid } from '../../../components/atoms/DataGrid';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetServiceStatuses } from '../../../queries/service-status-query';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { GridContainer } from '../../../styled/common.styles';
import { Link } from '../../../components/atoms/Link';
import { getPropertyTypeValue } from '../../../utils/service-status';
import { CustomSearchBar } from '../../../components/molecules/CustomSearchBar';
// import { TextField, TextFieldVariantProps } from '../../../components/atoms/TextField';
// import { CustomSearchBar } from '../../../components/molecules/CustomSearchBar';

export const ServiceStatusList: React.FC = () => {
  const [serviceStatuses, setServiceStatuses] = useState<Array<ServiceStatusResponse>>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [search, setSearch] = useState<string>('');
  
  const { data, isLoading } = useGetServiceStatuses();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    setColumns([
      {
        field: 'name',
        headerName: 'Name',
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Configure_Service_Status_Edit, permissions) ?
            <Link href={`/configure/services/statuses/${params.row.code}`}>{params.row.name}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.name,
        width: 200
      },
      {
        field: 'shortName',
        headerName: 'Short Name',
        renderCell: (params: GridRenderCellParams) =>
          isUserHasPermission(ActionPermissions.Configure_Service_Status_Edit, permissions) ?
            <Link href={`/configure/services/statuses/${params.row.code}`}>{params.row.code}</Link> :
            params.row.name
        ,
        valueGetter: (params: GridValueGetterParams) => params.row.code,
        width: 200
      },
      {
        field: 'closed',
        headerName: 'Closed',
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(params.row.isClosed),
        width: 200
      },
      {
        field: 'systemStatus',
        headerName: 'System Status',
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(params.row.isSystem),
        width: 200
      },
      {
        field: 'active',
        headerName: 'Active',
        valueGetter: (params: GridValueGetterParams) => getPropertyTypeValue(params.row.isActive),
        width: 200
      }
    ]);
  }, [permissions]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    data && setServiceStatuses(data);
  }, [data]);

  const handleChange = (searchText: string) => {
    setSearch(searchText);
    if (data) {
      const filteredServiceStatuses = data?.filter((serviceStatus) => serviceStatus.code?.toLowerCase().includes(searchText.toLowerCase()) || serviceStatus.name?.toLowerCase().includes(searchText.toLowerCase()));
      setServiceStatuses(filteredServiceStatuses);
    }
  };

  return (
    <GridContainer>
      <DataGrid
        columns={columns}
        rows={serviceStatuses}
        getRowId={(row) => row?.code}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: true
          }
        }}
        getRowHeight={() => 'auto'}
        customToolbar={() => <CustomSearchBar value={search} handleChange={handleChange} />}
        loading={isLoading}
      />
    </GridContainer>
  );
};