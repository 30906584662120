import { NotificationConfigObject } from '../@types/notification.type';
import { httpClient, HttpMethod } from './http-client';
  
export const getNotificationConfigsByCateogry = (category: string, queryOptions: any) => {
  const { filterModel, page, pageSize, sortModel } = queryOptions;
  let url = `/notificationConfigs?page=${page + 1}&limit=${pageSize}&category=${category}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  return httpClient(url, HttpMethod.Get, null);
};

export const createNotificationConfig = (configData: NotificationConfigObject) => {
  return httpClient('/notificationConfigs', HttpMethod.Post, configData);
};
  
export const updateNotificationConfig = (id: number, configData: NotificationConfigObject) => {
  return httpClient(`/notificationConfigs/${id}`, HttpMethod.Put, configData);
};

export const getNotificationTemplates = () => {
  return httpClient('/notificationTemplates', HttpMethod.Get, null);
};