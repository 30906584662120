export const RETURN_MANIFEST = {
  source: '',
  sourceNodeId: NaN,
  destination: '',
  destinationNodeId: NaN,
  note: '',
  shipmentTypeCode: 'return',
  shipmentStatusCode: 'pending',
  serialisedManifest: [],
  nonSerialisedManifest: [],
  serialisedManifestManual: [],
  nonSerialisedManifestManual: []
};