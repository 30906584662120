import React, { useEffect, useState, ChangeEvent } from 'react';
import { GridColDef, GridDensityTypes, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams, GRID_TREE_DATA_GROUPING_FIELD, GridSelectionModel } from '@mui/x-data-grid-pro';
import { Grid, Stack } from '@mui/material';
import { PersonPinCircle } from '@mui/icons-material';
import { ServiceAccordianFragmentProps } from './ServiceAccordianFragment.props';
import { ActionPermissions, ColorProps, ServiceAccordianData, ServiceAccordianRelation } from '../../../../@types';
import { Accordian } from '../../../../components/atoms/Accordian';
import { DataGrid, GridAllignmentTypes } from '../../../../components/atoms/DataGrid';
import { Switch } from '../../../../components/atoms/Switch';
import { LoggedIcon, LogoImage, LogoImageBox, TableWrapper, CustomFooter } from './ServiceAccordianFragment.styles';
import { Typography } from '../../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { useGetServicesUnderNodeId } from '../../../../queries/service-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { Link } from '../../../../components/atoms/Link';
import { formatDate } from '../../../../utils/common';
import { ServiceStatusIsClosed } from '../../../../configs/enums';

export const ServiceAccordianFragment: React.FC<ServiceAccordianFragmentProps> = ({
  nodeId,
  permissions
}) => {
  const [serviceData, setServiceData] = useState<ServiceAccordianData[]>([]);
  const [includeChildRecords, setIncludeChildRecords] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const getServicesUnderNodeId = useGetServicesUnderNodeId(nodeId, includeChildRecords);

  useEffect(() => {
    if (getServicesUnderNodeId.data) {
      setServiceData(getServicesUnderNodeId.data);
    }
  }, [getServicesUnderNodeId.data]);

  useEffect(() => {
    getServicesUnderNodeId.refetch();
  }, [includeChildRecords]);

  const columns: GridColDef[] = [
    {
      field: 'relation',
      headerName: '',
      maxWidth: 50,
      align: GridAllignmentTypes.Center,
      renderCell: (params: GridRenderCellParams) => 
        params.row.relation === ServiceAccordianRelation.Logged ?
          <LoggedIcon /> :
          params.row.relation === ServiceAccordianRelation.Allocated ?
            <PersonPinCircle /> :
            ''
    },
    {
      field: 'id',
      headerName: 'Service ID',
      minWidth: 50,
      renderCell: (params: GridRenderCellParams) => 
        isUserHasPermission(ActionPermissions.Service_Edit_View_Service, permissions) ?
          <Link href={`/services/${params.row.id}`}>{params.row.id}</Link> :
          params.row.id
      ,
    },
    {
      field: 'subLocationName',
      headerName: 'Sub Location',
      minWidth: 100,
    },
    {
      field: 'clientHierarchy',
      headerName: 'Client/ Contract',
      filterable: false,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        if(params.row.logoPic && params.row.logoPic !== null) {
          return (
            <LogoImageBox>
              <LogoImage src={params.row.logoPic} alt={params.row.clientHierarchy} />
            </LogoImageBox>
          );
        } else {
          return params.row.clientHierarchy;
        }
      },
    },
    {
      field: 'priorityCode',
      headerName: 'Priority',
      minWidth: 50,
    },
    {
      field: 'serviceReference1',
      headerName: 'Service Reference #1',
      minWidth: 50,
    },
    {
      field: 'serviceStatus',
      headerName: 'Status',
      minWidth: 50,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceStatus?.name
    },    
    {
      field: 'createdAt',
      headerName: 'Created Date',
      minWidth: 175,
      valueGetter: (params: GridValueGetterParams) => params.row.createdAt && formatDate(params.row.createdAt)
    },
    {
      field: 'closedAt',
      headerName: 'Closed Date',
      minWidth: 175,
      valueGetter: (params: GridValueGetterParams) => params.row.closedAt && formatDate(params.row.closedAt)
    },
    {
      field: 'presentation',
      headerName: 'Presentation',
      minWidth: 175
    }
  ];
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setIncludeChildRecords(event.target.checked);
  };

  const handleSelectionChange = (newSelection: GridSelectionModel) => {
    setSelectionModel(newSelection);
  };

  return (
    <Accordian
      title={
        <Grid container alignItems="center">
          <Grid item spacing={1} alignItems="center" sm container display="flex">
            <Grid item>
              <Typography>{`Services (${serviceData.length})`}</Typography>
            </Grid>
            <Grid item marginLeft="auto">
              <Stack direction="row">
                <Switch
                  color={ColorProps.Success}
                  onChange={handleChange}
                  onClick={(e) => e.stopPropagation()}
                  checked={includeChildRecords}
                />
                <Typography>Include Child Records</Typography>
              </Stack>
            </Grid>
            <Grid item mr={2}>
              <Link href={`/services?locationId=${nodeId}&includeChildRecords=${includeChildRecords}`}>
                <Button variant={ButtonVariantProps.Secondary}>
                  Search All Services
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      }
    ><TableWrapper>
        <DataGrid
          loading={getServicesUnderNodeId.isLoading || getServicesUnderNodeId.isRefetching}
          density={GridDensityTypes.Compact}
          columns={columns}
          rows={serviceData}
          getRowId={(row) => `${row.relation} ${row.id}`}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
          columnVisibilityModel={{
            subLocationName: includeChildRecords,
          }}
          initialState={{
            sorting: {
              sortModel: [
                { field: 'createdAt', sort: 'desc' },
                { field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'desc' }
              ],
            },
          }}
          treeData={true}
          getTreeDataPath={(row) => [row.serviceStatus?.isClosed ? ServiceStatusIsClosed.Closed : ServiceStatusIsClosed.Open, `${row.relation} ${row.id}`]}
          defaultGroupingExpansionDepth={-1}
          groupingColDef={{
            hideDescendantCount: true,
            valueFormatter: (params: GridValueFormatterParams<string | number>) => params.value === ServiceStatusIsClosed.Open || params.value === ServiceStatusIsClosed.Closed ? params.value : '',
            width: 100,
          }}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionChange}
          hideFooter
          customFooter={() =>
            <CustomFooter rowsSelected={selectionModel.length}>
              {selectionModel.length ? <Stack ml="15px">{selectionModel.length} row{selectionModel.length > 1 ? 's' : ''} selected</Stack> : ''}
              <Stack mr="15px">Total Rows: {serviceData.length}</Stack>
            </CustomFooter>
          }
        />
      </TableWrapper>
    </Accordian>
  );
};
