import React, { useEffect, useState } from 'react';
import { Grid, Dialog, DialogContent, DialogActions, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../../components/atoms/Button';
import { TextField, TextFieldTypeProps } from '../../../../components/atoms/TextField';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { CheckItem } from '../../../../components/molecules/CheckItem';
import { SubFieldTypeNode } from '../../../../components/organisms/FormBuilderField';
import { FormBuilder } from '../../../../components/templates/FormBuilder';
import { ServiceCreationFormBuilderFieldFragment } from './ServiceCreationFormBuilderFieldFragment';
import { ModifyStructureFormBuilderFieldFragment } from './ModifyStructureFormBuilderFieldFragment';
import { ParameterFormBuilderFieldFragment } from './ParameterFormBuilderFieldFragment';
import { FormFieldType, FormObject, ParameterDataFormParameterType } from '../../../../@types';
import { EMPTY_FIELD_MESSAGE } from '../../../../constants/common';
import { InteractionTemplateFields } from '../../../../configs/enums';
import { TemplateCheckedFieldsDataType, TemplateRowDataType } from '../../../../@types/interactions.type';
import { useAddInteractionTemplate, useUpdateInteractionTemplate } from '../../../../queries/interactions-query';
import { isEmptyString } from '../../../../utils/common';
import { AddEditInteractionsTemplateModalProps } from './AddEditInteractionsTemplateModal.props';
import { StyledDialogTitle } from './AddEditInteractionsTemplateModal.styles';
import { ActionTriggerFormBuilderFieldFragment } from './ActionTriggersFormBuilderFieldFragment';
import { ParameterNoUIFormBuilderFieldFragment } from './ParameterNoUIFormBuilderFieldFragment';
import { checkSetParamNoUIFormErrorsAvailability } from '../../../../utils/form-builder';
import { GetParameterFormBuilderFieldFragment } from './GetParameterFormBuilderFieldFragment';
import { RootState } from '../../../../store';
import { ServiceSearchFormBuilderFieldFragment } from './ServiceSearchFormBuilderFieldFragment';

export const AddEditInteractionsTemplateModal: React.FC<AddEditInteractionsTemplateModalProps> = ({
  openModal,
  templateData,
  handleClose,
  handleRefetch
}) => {
  const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isSidebarOpen);
  
  const [templateName, setTemplateName] = useState('');
  const [displayOrder, setDisplayOrder] = useState<number>();
  const [displayLimit, setDisplayLimit] = useState<number>();
  const [nameRequiredError, setNameRequiredError] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [selectedTemplateCheckedFields, setSelectedTemplateCheckedFields] = useState<TemplateCheckedFieldsDataType[]>([]);
  const [formBuilderData, setFormBuilderData] = useState<FormObject>({
    id: 1,
    title: '',
    containers: [
      {
        id: 1,
        fields: [],
        conditions: []
      }
    ]
  });
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [displayOrderError, setDisplayOrderError] = useState(false);

  const addNewTemplateQuery = useAddInteractionTemplate();
  const updateTemplateQuery = useUpdateInteractionTemplate(templateData?.id);

  const checkBoxFields: TemplateCheckedFieldsDataType[] =[
    {
      key: 'mandatory',
      value: 'Mandatory',
      selected: false
    },
    {
      key: 'pending',
      value: 'Pending',
      selected: false
    },
    {
      key: 'editable',
      value: 'Editable',
      selected: false
    },
    {
      key: 'repeatable',
      value: 'Repeatable',
      selected: false
    },
    {
      key: 'downloadReport',
      value: 'Download Report',
      selected: false
    },
    {
      key: 'active',
      value: 'Active',
      selected: false
    }
  ];

  const subFieldTypeNodes: Array<SubFieldTypeNode> = [
    {
      formType: FormFieldType.ServiceCreation,
      node: ServiceCreationFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.ModifyStructure,
      node: ModifyStructureFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.ActionTrigger,
      node: ActionTriggerFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.SetParameter,
      node: ParameterFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.SetParameterNoUI,
      node: ParameterNoUIFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.GetParameter,
      node: GetParameterFormBuilderFieldFragment
    },
    {
      formType: FormFieldType.ServiceSearch,
      node: ServiceSearchFormBuilderFieldFragment
    }
  ];

  useEffect(() => { 
    setTemplateName(templateData?.name || '');
    setDisplayLimit(templateData?.displayLimit);
    setDisplayOrder(templateData?.displayOrder);
    setSelectedTemplateCheckedFields([{
      key: 'mandatory',
      value: 'Mandatory',
      selected: !!templateData?.isMandatory
    },
    {
      key: 'pending',
      value: 'Pending',
      selected: !!templateData?.isPending
    },
    {
      key: 'editable',
      value: 'Editable',
      selected: !!templateData?.isEditable
    },
    {
      key: 'repeatable',
      value: 'Repeatable',
      selected: !!templateData?.isRepeatable
    },
    {
      key: 'downloadReport',
      value: 'Download Report',
      selected: !!templateData?.isDownloadable
    },
    {
      key: 'active',
      value: 'Active',
      selected: !!templateData?.isActive
    }]);
    templateData?.templateContent && setFormBuilderData(templateData.templateContent);
  }, [templateData]);

  const onChangeCheckedFields = (key: string, selected: boolean) => {
    setSelectedTemplateCheckedFields(prevValue => {
      const field = prevValue.find(value => value.key === key);
      if (field) {
        field.selected = selected;
      }
      return [...prevValue];
    });
  };

  const handleOnSave = async() => {
    let isEmptyField = false;

    formBuilderData.containers.forEach(container => {
      if (container.isDecisionPrompt && isEmptyString(container.questionData?.questionText)) {
        isEmptyField = true;
        return;
      }

      container.fields.forEach(field => {
        if (field.type === FormFieldType.ServiceCreation && !field.preSelectedData) {
          isEmptyField = true;
          return;
        }

        if (isEmptyString(field.title)) {
          isEmptyField = true;
          return;
        }

        field.options?.forEach(option => {
          if (isEmptyString(option.title) || isEmptyString(option.value)) {
            isEmptyField = true;
            return;
          }
        });

        if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.Part &&
          (!field.preSelectedData?.partParameter?.partAttribute ||
          !field.preSelectedData?.partParameter?.movementType)) {
          isEmptyField = true;
        }
        
        if (field.type === FormFieldType.SetParameterNoUI) {
          isEmptyField = checkSetParamNoUIFormErrorsAvailability(field);
          return;
        }

        if (field.type === FormFieldType.GetParameter &&
            ((!field.preSelectedData?.fieldTitle || isEmptyString(field.preSelectedData?.fieldTitle)) ||
            !field.preSelectedData?.selectedParameter)) {
          isEmptyField = true;
          return;
        }

        if (field.type === FormFieldType.ServiceSearch) {
          isEmptyField = !field.preSelectedData?.serviceTypes?.length || !field.preSelectedData?.actionTypes?.length || !field.preSelectedData?.processTypes?.length;
          return;
        }

      });
    });

    if (isEmptyField) {
      setShowFormErrors(true);
      return;
    }

    const updatedTemplateData: TemplateRowDataType = {
      id: templateData?.id,
      name: templateName,
      displayLimit: displayLimit,
      displayOrder: displayOrder,
      isActive: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsActive)?.selected,
      isDownloadable: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsDownloadable)?.selected,
      isEditable: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsEditable)?.selected,
      isMandatory: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsMandotory)?.selected,
      isPending: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsPending)?.selected,
      isRepeatable: selectedTemplateCheckedFields.find(value => value.key === InteractionTemplateFields.IsRepeatable)?.selected,
      templateContent: {
        ...formBuilderData,
        title: templateName
      }
    };

    if (isEmptyString(templateName)) {
      setNameRequiredError(true);
    } else {
      setNameRequiredError(false);

      let response: any;

      if (updatedTemplateData.id) {
        response = await updateTemplateQuery.mutateAsync(updatedTemplateData);
      } else {
        response = await addNewTemplateQuery.mutateAsync(updatedTemplateData);
      }

      // Clear form builder data after saving
      setFormBuilderData({
        id: 1,
        title: '',
        containers: [
          {
            id: 1,
            fields: [],
            conditions: []
          }
        ]
      });

      setToastMessage(response?.message);
      setOpenToast(true);
      handleClose();
      handleRefetch(true);
    }
  };

  const handleOnCancel = () => {
    handleClose();
    setNameRequiredError(false);
    setTemplateName('');
    setDisplayLimit(0);
    setDisplayOrder(0);
    setSelectedTemplateCheckedFields(checkBoxFields);
    setFormBuilderData({
      id: 1,
      title: '',
      containers: [
        {
          id: 1,
          fields: [],
          conditions: []
        }
      ]
    });
  };

  return (
    <>
      <Dialog
        open={openModal}
        fullScreen
        sx={{ left: isSidebarOpen ? 255 : 0 }}
      >
        <StyledDialogTitle>
          {`${templateData?.name ? 'Update' : 'Create'}`} Template
        </StyledDialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={4}>
              <TextField
                label="Name *"  
                fullWidth
                value={templateName}
                onChange={(value: string) => {
                  setTemplateName(value);
                  setNameRequiredError(false);
                }}
                helperText={nameRequiredError? EMPTY_FIELD_MESSAGE : ''}
                error={nameRequiredError}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type={TextFieldTypeProps.Number}
                label="Template Display Order(1 to 99)"
                inputProps={{ min: 1 , max: 99 }}
                value={displayOrder?.toString()}
                error={displayOrderError}
                onChange={(displayOrder: string) => {
                  if (!displayOrder) {
                    setDisplayOrder(parseInt(displayOrder));
                    setDisplayOrderError(false);
                  } else if (parseInt(displayOrder) >= 1 && parseInt(displayOrder) <= 99) {
                    setDisplayOrder(parseInt(displayOrder));
                    setDisplayOrderError(false);
                  } else {
                    setDisplayOrderError(true);
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Display Limit"
                type={TextFieldTypeProps.Number}
                inputProps={{ min: 0 }}
                fullWidth
                value={displayLimit?.toString()}
                onChange={(value: string) => setDisplayLimit(parseInt(value))}
              />
            </Grid>
            {selectedTemplateCheckedFields.map((item, index) => (
              <Grid item xs={2} key={index}>
                <CheckItem
                  label={item.value}
                  value={item.selected}
                  onChange={(val) => onChangeCheckedFields(item.key, val)}
                /> 
              </Grid>
            ))}
            <Grid item xs={12}>
              <br/>
              <FormBuilder
                subFieldTypes={subFieldTypeNodes}
                showErrors={showFormErrors}
                value={formBuilderData}
                onChange={(val) => setFormBuilderData(val)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} width="100%">
            <Button
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Success}
              onClick={handleOnSave}
            >
              {`${templateData?.name ? 'Update' : 'Save'}`}
            </Button>
            <Button
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Error}
              onClick={handleOnCancel}
            >
            Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast} 
        autoHideDuration={4000} 
        message={toastMessage ? toastMessage : 'Successfully Saved'}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
};
