import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';

export const StyledTableWrapper = styled('div')`
  overflow: auto;
  width: 100%;
  & .super-app-theme--notes {
    background-color: #ffffff;
  };
  & .super-app-theme--uploads {
    background-color: #ffffff;
  };
  & .super-app-theme--part-actions-and-logistics-events {
    background-color: #ffffff;
  };
  & .super-app-theme--tasks {
    background-color: #ffffff;
  };
  & .super-app-theme--sla-events {
    background-color: #ffffff;
  };
  & .super-app-theme--service-events {
    background-color: #ffffff;
  };
  & .super-app-theme--notification-events {
    background-color: #ffffff;
  };
  & .super-app-theme--assignment-events {
    background-color: #ffffff;
  };
  & .super-app-theme--tbc-interactions-data {
    background-color: #ffffff;
  };
`;

export const StyledTypography = styled(Typography)`
  color: #c0bfc0;
`;
